import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { IconMap } from '../../../../../shared/sprite';
import { Button, Icon } from '../../../../../shared/ui';
import { FC, useState } from 'react';
import { cn } from '@distribute/frontend/utils';
import { TemplateCategory as TemplateCategoryType } from '@distribute/shared/types';
import { TemplateCategory } from './TemplateCategory';
import { TemplatesConfigBasicEnum } from './lib';

type GroupItem = {
  name: string;
  key: TemplatesConfigBasicEnum | TemplateCategoryType;
  templates: TemplateExtended[] | undefined;
};

type Props = {
  name: string;
  items: GroupItem[];
};
export const TemplateGroup: FC<Props> = ({ name, items }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="mb-6">
      <div className="flex items-center justify-between mb-3">
        <span className="font-semibold text-gray-900 text-md">{name}</span>
        <Button
          size="xs"
          variant="icon"
          color="transparent"
          className={cn({ 'rotate-180': isOpen })}
          onClick={() => {
            setIsOpen((prevIsOpen) => !prevIsOpen);
          }}
        >
          <Icon glyph={IconMap.ArrowDown} width={16} />
        </Button>
      </div>

      {isOpen &&
        items.map((item) => (
          <TemplateCategory
            key={item.key}
            name={item.name}
            templates={item.templates}
          />
        ))}
    </div>
  );
};
