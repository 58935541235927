import React, { useEffect, useState } from 'react';
import {
  TemplatesModalHeader,
  TemplatesNavigation,
  TemplatesSection,
} from './components';
import { useDispatch } from 'react-redux';
import { templatesModel } from '../model';
import {
  TemplatesConfigEnum,
  TemplatesModalContext,
  TemplatesSwitcherEnum,
} from '../lib';

type Props = {
  isHideCreateBlankPage?: boolean;
  onChoose(): void;
  handleCreatePageWithAI: () => void;
};

export const TemplatesModalInner: React.FC<Props> = ({
  isHideCreateBlankPage,
  onChoose,
  handleCreatePageWithAI,
}) => {
  const dispatch = useDispatch();

  const handleChoose = () => {
    dispatch(templatesModel.actions.setPreviewMode(false));
    dispatch(
      templatesModel.actions.setCurrentTemplatesFolder(TemplatesConfigEnum.ALL)
    );
    onChoose();
  };

  useEffect(() => {
    return () => {
      dispatch(templatesModel.actions.setTemplateModalOpen(false));
      dispatch(
        templatesModel.actions.setCurrentTemplatesFolder(
          TemplatesConfigEnum.ALL
        )
      );
    };
  }, [dispatch]);

  const [sortMethod, setSortMethod] = useState<TemplatesSwitcherEnum>(
    TemplatesSwitcherEnum.POPULAR
  );

  const [isSinglePageTemplate, setIsSinglePageTemplate] = useState(true);

  return (
    <TemplatesModalContext.Provider
      value={{
        isSinglePageTemplate,
        canSelectSingePageTemplate: true,
        canSelectMultiTabsTemplate: true,
      }}
    >
      <div className="flex flex-row overflow-hidden grow-1">
        <TemplatesNavigation onTemplateTypeChange={setIsSinglePageTemplate} />
        <div className="flex flex-col flex-grow">
          <TemplatesModalHeader
            sortMethod={sortMethod}
            setSortMethod={setSortMethod}
          />
          <TemplatesSection
            onChoose={handleChoose}
            isHideCreateBlankPage={isHideCreateBlankPage}
            sortMethod={sortMethod}
            handleCreatePageWithAI={handleCreatePageWithAI}
          />
        </div>
      </div>
    </TemplatesModalContext.Provider>
  );
};
