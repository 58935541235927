import { call, put } from 'redux-saga/effects';
import { callRecordingsApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { callRecordingSettingsModel } from '../index';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* connectZoom() {
  yield put(callRecordingSettingsModel.actions.setIsZoomConnecting(true));

  try {
    const { url }: RT<typeof callRecordingsApi.connectZoom> = yield call(
      callRecordingsApi.connectZoom,
      {
        redirectPath: 'team-settings/call-recordings', // TODO: Discuss with Natalia
      }
    );

    window.location.href = url;
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to connect Zoom')
      )
    );
  } finally {
    yield put(callRecordingSettingsModel.actions.setIsZoomConnecting(false));
  }
}

export function* disconnectZoom() {
  yield put(callRecordingSettingsModel.actions.setIsZoomConnecting(true));

  try {
    yield call(callRecordingsApi.disconnectZoom);
    yield call(callRecordingSettingsModel.sagas.loadCallRecordingSettings);
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to disconnect Zoom')
      )
    );
  } finally {
    yield put(callRecordingSettingsModel.actions.setIsZoomConnecting(false));
  }
}
