import { PropsWithChildren } from 'react';
import cn from 'classnames';

export const Container = ({
  children,
  style,
  isVisible,
}: PropsWithChildren & { style?: React.CSSProperties; isVisible: boolean }) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center h-full text-center relative',
        { hidden: !isVisible }
      )}
      style={style}
    >
      {children}
    </div>
  );
};
