import { RootState } from '../../../app';

export const selectIsUpdatingBrandInfo = ({ teamBrandSettings }: RootState) =>
  teamBrandSettings.isUpdatingBrandInfo;

export const selectIsUploadLogoLoading = ({ teamBrandSettings }: RootState) =>
  teamBrandSettings.isUploadLogoLoading;

export const selectUploadingLogoProgress = ({ teamBrandSettings }: RootState) =>
  teamBrandSettings.uploadingLogoProgress;

export const selectIsRemoveLogoLoading = ({ teamBrandSettings }: RootState) =>
  teamBrandSettings.isRemoveLogoLoading;
