import '../../../shared/styles/suggestion.css';

import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonSquare, Icon, Tooltip } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { JSONContent } from '@tiptap/core';
import { ChatBotMessageLoading } from './ChatBotMessageLoading';
import { generateHTML } from '@tiptap/html';

import { cn } from '@distribute/frontend/utils';

import {
  sharedMainEditorExtensions,
  DiffMarkExt,
  DiffExtension,
} from '@distribute/shared/generate-html';

import { previewExtensions } from '../config';

import { useSuggestionNavigation } from '../hooks/use-suggestion-navigation';
import { useTimer } from '../../../shared/hooks/useTimer';

type Props = {
  loading?: boolean;
  name: string;
  id: string;
  content: JSONContent;
};

export const ChatBotMessageSuggestion: FC<Props> = ({
  id,
  name,
  content,
  loading,
}) => {
  const [isActive, onActive] = useTimer();

  const ref = useRef<HTMLDivElement>(null);

  const { navigateTo } = useSuggestionNavigation();

  const [show, setShow] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (!loading && ref.current) {
      const { scrollHeight, clientHeight } = ref.current;
      setIsOverflowing(scrollHeight > clientHeight);
    }
  }, [loading]);

  const handleCopy = () => {
    onActive();
  };

  return (
    <div className="flex flex-col rounded-xl border border-e-gray-300">
      <div className="rounded-t-xl py-2 px-4 bg-gray-100 flex flex-none items-center gap-x-2">
        <Icon
          glyph={IconMap.OnePage}
          width={16}
          className="flex-shrink-0 text-gray-700"
        />
        <p className="text-sm font-medium text-gray-700 truncate">{name}</p>
        <div className="ml-auto flex flex-nowrap gap-x-2 items-center flex-shrink-0">
          <Tooltip
            sideOffset={4}
            hideArrow
            className="py-1.5"
            trigger={
              <ButtonSquare
                size="xs"
                variant="tertiary-gray"
                onClick={() => navigateTo(parseInt(id))}
              >
                <Icon
                  glyph={IconMap.ArrowCircleUpRight}
                  width={16}
                  className="-rotate-90"
                />
              </ButtonSquare>
            }
          >
            Go to
          </Tooltip>
          {/* <Tooltip
            keepOpenOnTrigger
            sideOffset={4}
            hideArrow
            className="py-1.5"
            trigger={
              <ButtonSquare
                size="xs"
                variant="tertiary-gray"
                onClick={handleCopy}
              >
                <Icon glyph={IconMap.Copy05} width={16} />
              </ButtonSquare>
            }
          >
            {isActive ? 'Copied!' : 'Copy'}
          </Tooltip> */}
        </div>
      </div>
      <div
        ref={ref}
        className={cn('relative overflow-hidden p-4', {
          'max-h-90': !show && !loading,
          '!pb-10': isOverflowing && !loading,
        })}
      >
        {loading ? (
          <div className="flex flex-col gap-y-5">
            <ChatBotMessageLoading />
            <ChatBotMessageLoading />
            <ChatBotMessageLoading />
          </div>
        ) : (
          <ChatBotMessageSuggestionContent content={content} />
        )}

        {isOverflowing && !loading && (
          <div
            className={cn(
              'rounded-b-xl h-10 grid place-items-center absolute bottom-0 left-0 right-0 bg-base-white/40 backdrop-blur-sm'
            )}
          >
            <button
              type="button"
              onClick={() => setShow((prev) => !prev)}
              className="flex flex-none gap-x-1 items-center rounded-md py-0.25 px-1 hover:bg-base-black/10 text-sm font-medium text-gray-700 hover:text-gray-800"
            >
              <Icon
                glyph={show ? IconMap.ChevronUp : IconMap.ChevronDown}
                width={16}
              />
              {show ? 'Show less' : 'Show more'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const ChatBotMessageSuggestionContent: FC<{
  content: JSONContent;
}> = ({ content }) => {
  const htmlContent = useMemo(() => {
    return generateHTML(content, [
      ...sharedMainEditorExtensions,
      ...previewExtensions,
      DiffMarkExt,
      DiffExtension,
    ]);
  }, [content]);

  return (
    <div
      className="chat-suggestion-content"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};
