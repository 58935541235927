import { PropsWithChildren } from 'react';
import { cn } from '@distribute/frontend/utils';

export const List = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <ul className={cn('list-disc text-left w-90', className)}>{children}</ul>
  );
};
