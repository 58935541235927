import { Schema } from 'prosemirror-model';
import { Editor } from '@tiptap/core';
import { DiffMarkExt, DiffExtension } from '@distribute/shared/generate-html';
import { getMainEditorExtensions } from '../../../entities/tiptap-editor/mainEditorExtensions';

export function getEditorSchema(): Schema {
  const tempEditor = new Editor({
    extensions: [...getMainEditorExtensions(true), DiffMarkExt, DiffExtension],
  });

  const schema = tempEditor.schema;
  tempEditor.destroy();

  return schema;
}

let cachedSchema: Schema | null = null;

export function getSchema(): Schema {
  if (!cachedSchema) {
    cachedSchema = getEditorSchema();
  }
  return cachedSchema;
}
