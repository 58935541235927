import { useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../../model';
import { PageCreationTypesEnum } from '../../../config/types';
import { CreateFromTemplate } from './CreateFromTemplate';
import { CreateFromScratch } from './CreateFromScratch';
import { CreateWithAI } from './CreateWithAI';
import { CreateTabWithAI } from './CreateTabWithAI';
import { useResetOutline } from '../useResetOutline';
import { useFillPreselectedDataIntoForm } from '../useFillPreselectedDataIntoForm';

export const CreatePageStepLeft = () => {
  const pageCreationType = useSelector(
    pageCreationFlowModel.selectors.selectPageCreationType
  );

  useResetOutline();
  useFillPreselectedDataIntoForm();

  switch (pageCreationType) {
    case PageCreationTypesEnum.FROM_SCRATCH:
      return <CreateFromScratch />;
    case PageCreationTypesEnum.FROM_TEMPLATE:
      return <CreateFromTemplate />;
    case PageCreationTypesEnum.WITH_AI:
      return <CreateWithAI />;
    case PageCreationTypesEnum.TAB:
      return <CreateTabWithAI />;
  }
};
