import { put, select } from 'redux-saga/effects';
import { pageCreationFlowModel } from '..';
import { aiModel } from '../../../../features/ai';
import { PageCreationDataSourcesEnum } from '../../config/types';
import { RT } from '../../../../shared/types';
import { authUserModel } from '../../../../entities/auth-user';
import { teamsModel } from '../../../../features/teams';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { CreateWithAIPageType } from '@distribute/shared/api-types/create-with-ai';
import { logger } from '../../../../shared/lib';
import { analytics } from '../../../../entities/analytics';
import { AnalyticsEvents } from '@distribute/shared/types';

export function* generateTab({
  payload: formData,
}: ReturnType<typeof pageCreationFlowModel.actions.generateTab>) {
  try {
    const user: RT<typeof authUserModel.selectors.selectUserWithError> =
      yield select(authUserModel.selectors.selectUserWithError);

    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const embeddedContext: RT<
      typeof pageCreationFlowModel.selectors.selectEmbeddedContext
    > = yield select(pageCreationFlowModel.selectors.selectEmbeddedContext);

    const contextRawData: RT<
      typeof pageCreationFlowModel.selectors.selectContextRawData
    > = yield select(pageCreationFlowModel.selectors.selectContextRawData);

    const data = {
      pageType: formData.pageType as CreateWithAIPageType,
      pageTypeDescription: formData.pageTypeDescription,
      userName: user.displayName,
      companyName: currentTeam.brandCompanyName ?? undefined,
      aboutCompany: currentTeam.brandCompanyInfo ?? undefined,
      pageTitle: formData.pageTitle,
      prospectName: formData.selectedCallerName,
      teamId: currentTeam.id,
      contextRawData: contextRawData ?? [],
      isTranscript:
        formData.selectedDataSource === PageCreationDataSourcesEnum.GONG,
      hasEmbeddings: !!embeddedContext,
      embeddedContext: embeddedContext ?? undefined,
      generatedOutline: formData.sections,
      isSeparateTabs: formData.isSeparateTabs,
      brandCompany: formData.brandCompany?.name ?? undefined,
      existingTabsContext: formData.existingTabsContext,
    };

    yield put(
      aiModel.actions.generatePageWithAI({
        ...data,
        cb: function* () {
          yield put(aiModel.actions.clearFlow());
        },
      })
    );

    analytics.track(AnalyticsEvents.TAB_GENERATED_WITH_AI, {
      pageId: formData.pageId,
      slug: formData.slug,
      tabName: formData.pageTitle,
      isUsingExistingTabsAsAContext: !!formData.existingTabsContext,
    });

    yield put(pageCreationFlowModel.actions.closePageCreationFlow());
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to create tab with AI')
      )
    );
  }
}
