import { call, delay, select } from 'redux-saga/effects';
import { callRecordingSettingsModel } from '../index';

import { RT } from '../../../../shared/types';

export function* watchCalendarConnection() {
  let calendar: RT<
    typeof callRecordingSettingsModel.selectors.selectMeetingCalendar
  > = yield select(callRecordingSettingsModel.selectors.selectMeetingCalendar);

  while (calendar?.status === 'connecting') {
    yield delay(5000); // 5 seconds delay
    yield call(callRecordingSettingsModel.sagas.loadCallRecordingSettings);
    calendar = yield select(
      callRecordingSettingsModel.selectors.selectMeetingCalendar
    );
  }
}
