import React, { FC, useMemo, useState } from 'react';
import { GetCallDetailResponse } from '@distribute/shared/api-types/call';
import { Dropdown, Icon, Avatar, CopyDownload } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { downloadFile } from '../../../shared/lib';
import { analytics } from '../../../entities/analytics';
import { AnalyticsEvents } from '@distribute/shared/types';
import {
  formatTranscriptTime,
  getTranscriptText,
} from '@distribute/shared/utils';

type CallTranscriptProps = {
  call: GetCallDetailResponse;
};

const DEFAULT_SPEAKER_ID = 'all';

export const CallTranscript: React.FC<CallTranscriptProps> = ({ call }) => {
  const [selectedSpeakerId, setSelectedSpeakerId] =
    useState<string>(DEFAULT_SPEAKER_ID);
  const [copied, setCopied] = useState(false);

  const speakerItems = useMemo(
    () => [
      { label: 'All speakers', id: DEFAULT_SPEAKER_ID },
      ...call.attendees.map((speaker) => ({
        label: (
          <div className="flex items-center gap-3 focus:outline-none">
            <Avatar size="xs" src={undefined} displayName={speaker.name} />
            <span className="truncate">{speaker.name}</span>
          </div>
        ),
        headerLabel: speaker.name,
        id: `${speaker.id}`,
      })),
    ],
    [call.attendees]
  );

  const transcriptsToShow = useMemo(() => {
    if (selectedSpeakerId === DEFAULT_SPEAKER_ID) {
      return call.transcripts;
    }

    return call.transcripts.filter(
      (transcript) => `${transcript.speaker_id}` === selectedSpeakerId
    );
  }, [selectedSpeakerId, call]);

  const handleDownloadTextClick = () => {
    const text = getTranscriptText(transcriptsToShow);
    const type = 'text/plain';
    const filename = `call-transcript-(${call.name}).txt`;
    const blob = new Blob([text], { type });
    downloadFile(blob, { 'content-type': type }, filename);
  };
  const handleCopyTextClick = () => {
    const text = getTranscriptText(transcriptsToShow);
    navigator.clipboard.writeText(text);
    setCopied(true);
    analytics.track(AnalyticsEvents.CALL_TRANSCRIPT_COPIED, {
      callId: call.id,
    });
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const isTranscriptsReady = call.transcripts.length > 0;

  return isTranscriptsReady ? (
    <>
      <div className="sticky z-10 bg-base-white top-41 pb-6 pt-4">
        {/*<div className="w-60 relative shrink-0">*/}
        {/*  <Icon*/}
        {/*    glyph={IconMap.SearchLg}*/}
        {/*    width={20}*/}
        {/*    className="absolute left-3.5 top-2.5 z-10 text-gray-500"*/}
        {/*  />*/}
        {/*  <Input*/}
        {/*    type="text"*/}
        {/*    placeholder="Search"*/}
        {/*    value=""*/}
        {/*    onChange={() => null}*/}
        {/*    heightSize="md"*/}
        {/*    className="!pl-10.5 w-80"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="flex justify-between gap-4 bg-base-white">
          <Dropdown
            align="start"
            sideOffset={4}
            triggerStyles="!w-fit-content min-w-48 max-w-96"
            listStyles="shadow-lg min-w-48 max-w-96"
            itemStyles="min-w-44"
            checkmarkStyles="!ml-2"
            items={speakerItems}
            currentItemId={selectedSpeakerId ?? DEFAULT_SPEAKER_ID}
            onItemChange={(id) => setSelectedSpeakerId(id)}
          />
          <CopyDownload
            copied={copied}
            onCopy={handleCopyTextClick}
            onDownload={handleDownloadTextClick}
          />
        </div>
      </div>
      <Transcripts transcripts={transcriptsToShow} key={selectedSpeakerId} />
    </>
  ) : (
    <div className="pb-6 flex flex-col items-center justify-center gap-1.5 text-gray-700 h-90">
      <span className="border border-gray-200 rounded-lg p-3 shadow-xs">
        <Icon glyph={IconMap.Hourglass03} width={24} />
      </span>
      <h2 className="text-md text-gray-900 font-semibold">
        Preparing your transcripts
      </h2>
    </div>
  );
};

type TranscriptsProps = {
  transcripts: GetCallDetailResponse['transcripts'];
};

const Transcripts: FC<TranscriptsProps> = ({ transcripts }) => (
  <ul className="flex flex-col gap-6">
    {transcripts.map((transcript) => (
      <li
        className="flex gap-4"
        key={`${transcript.words[0]?.start_timestamp}-${transcript.speaker_id}`}
      >
        <Avatar displayName={transcript.speaker} />
        <div>
          <p className="flex gap-1.5 items-center text-gray-500">
            <span className="font-semibold text-gray-600 text-sm">
              {transcript.speaker}
            </span>
            <Icon glyph={IconMap.Dot} width={5} />
            <span className="text-xs">
              {formatTranscriptTime(transcript.words[0]?.start_timestamp)}
            </span>
          </p>
          <p className="text-gray-900 text-md">
            {transcript.words.map((word) => word.text).join(' ')}
          </p>
        </div>
      </li>
    ))}
  </ul>
);
