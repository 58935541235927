import { cn } from '@distribute/frontend/utils';

export const Description = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p
      className={cn('text-sm font-normal text-gray-600 max-w-90', className)}
      {...props}
    >
      {children}
    </p>
  );
};
