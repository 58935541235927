import React from 'react';
import classNames from 'classnames';

import { Icon } from '../../../../../../shared/ui';
import { IconMap } from '../../../../../../shared/sprite';
import { useDispatch } from 'react-redux';
import {
  ActiveConversionPanel,
  editorLeftSidebarModel,
  LeftSidebarOptions,
} from '../../../../../../features/editor-left-sidebar';

type Props = {
  isSubscribeBarOpen: boolean;
};

export const PopUpConfiguration: React.FC<Props> = ({ isSubscribeBarOpen }) => {
  const dispatch = useDispatch();
  const handleOpenPopUpSettings = () => {
    dispatch(
      editorLeftSidebarModel.actions.setSelectedOption(
        LeftSidebarOptions.CONVERSIONS
      )
    );
    dispatch(
      editorLeftSidebarModel.actions.setActiveConversionPanel(
        ActiveConversionPanel.ALERT_BAR
      )
    );
  };

  return (
    <div
      onClick={handleOpenPopUpSettings}
      className={classNames(
        'bg-primary-600 pl-0.5 text-base-white w-9 h-9 items-center justify-center cursor-pointer rounded-full border-none hover:bg-primary-700 z-50 absolute top-14 -right-4 group-hover:flex hidden',
        {
          '!flex': isSubscribeBarOpen,
        }
      )}
    >
      <Icon glyph={IconMap.Edit2Icon} width={20} />
    </div>
  );
};
