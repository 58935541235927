import { Button } from '../../../../../shared/ui';
import { FC } from 'react';

type Props = {
  clearSearchQuery: () => void;
};

export const NoElementsFoundScreen: FC<Props> = ({ clearSearchQuery }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <p className="mb-1 font-semibold text-gray-900 text-md">
        No elements found
      </p>
      <p className="mb-6 text-sm font-normal text-center text-gray-600 max-w-56">
        We cannot find the component you need.
      </p>
      <Button variant="text" color="secondary" onClick={clearSearchQuery}>
        Clear search
      </Button>
    </div>
  );
};
