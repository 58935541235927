import { call, put, select } from 'redux-saga/effects';
import { callApi } from '../../../../shared/api';
import { callModel } from '../index';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { createNotification, snackbarModel } from '../../../snackbar';
import { navigate } from '../../../../processes/navigation';
import { CALL_RECORDINGS_ROUTE } from '../../../../entities/history';

export function* updateCallItem({
  payload: { id, data, isFromList = false },
}: ReturnType<typeof callModel.actions.updateCallItem>) {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  yield put(callModel.actions.setCallEditingProgress(true));

  try {
    yield call(callApi.updateCallItem, id, data, { teamId: team.id });

    if (isFromList) {
      const calls: RT<typeof callModel.selectors.selectCalls> = yield select(
        callModel.selectors.selectCalls
      );
      yield put(
        callModel.actions.setCalls(
          calls.map((call) => {
            if (call.id === id) {
              return {
                ...call,
                ...data,
              };
            }
            return call;
          })
        )
      );
    } else {
      const currentCall: RT<
        typeof callModel.selectors.selectCurrentCallWithError
      > = yield select(callModel.selectors.selectCurrentCallWithError);
      yield put(
        callModel.actions.setCurrentCall({
          ...currentCall,
          ...data,
        })
      );
    }
    yield put(callModel.actions.setEditingCallItem(undefined));
    yield put(callModel.actions.setTogglingCallItem(undefined));
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update call recording')
      )
    );
  } finally {
    yield put(callModel.actions.setCallEditingProgress(false));
  }
}

export function* deleteCallItem({
  payload: { id, isFromList },
}: ReturnType<typeof callModel.actions.deleteCallItem>) {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  yield put(callModel.actions.setCallDeletingProgress(true));

  try {
    yield call(callApi.deleteItem, id, { teamId: team.id });

    if (isFromList) {
      const calls: RT<typeof callModel.selectors.selectCalls> = yield select(
        callModel.selectors.selectCalls
      );
      yield put(
        callModel.actions.setCalls(calls.filter((call) => call.id !== id))
      );
    } else {
      yield put(callModel.actions.setCurrentCall(undefined));
      yield put(navigate({ to: CALL_RECORDINGS_ROUTE }));
    }
    yield put(callModel.actions.setDeletingCallItem(undefined));
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to remove call recording')
      )
    );
  } finally {
    yield put(callModel.actions.setCallDeletingProgress(false));
  }
}

export function* setPublicPlayback() {
  const currentCall: RT<typeof callModel.selectors.selectCurrentCallWithError> =
    yield select(callModel.selectors.selectCurrentCallWithError);
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  yield put(callModel.actions.setIsAssetPrivacyLoading(true));

  try {
    const muxAsset: RT<typeof callApi.setPublicPlayback> = yield call(
      callApi.setPublicPlayback,
      currentCall.id,
      { teamId: team.id }
    );

    yield put(
      callModel.actions.setCurrentCall({
        ...currentCall,
        muxAsset,
      })
    );
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to set call recording public.')
      )
    );
  } finally {
    yield put(callModel.actions.setIsAssetPrivacyLoading(false));
  }
}
