import { put, select, call } from 'redux-saga/effects';
import { teamsModel } from '../../../teams';
import { RT } from '../../../../shared/types';
import { callApi } from '../../../../shared/api';
import { createNotification, snackbarModel } from '../../../snackbar';
import { callModel } from '..';

export function* loadGroupedCalls() {
  yield put(callModel.actions.setIsGroupedCallsLoading(true));

  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  try {
    const data: RT<typeof callApi.getGroupedCalls> = yield call(
      callApi.getGroupedCalls,
      {
        teamId: team.id,
      }
    );

    yield put(callModel.actions.setGroupedCalls(data));
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to load grouped call recordings')
      )
    );
  } finally {
    yield put(callModel.actions.setIsGroupedCallsLoading(false));
  }
}
