import React from 'react';
import { Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

export const EmptyState = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-4 w-88">
      <span className="flex items-center justify-center w-12 h-12 border border-gray-200 rounded-lg shadow-xs">
        <Icon glyph={IconMap.HeadPhones02} />
      </span>
      <div className="text-center">
        <h3 className="text-gray-900 text-md font-semibold">
          No call recordings found
        </h3>
        <p className="text-gray-600 text-sm font-normal">
          Recordings from your calendar meetings or manually created ones will
          appear here.
        </p>
      </div>
    </div>
  );
};
