import {
  AUTH_ROUTE,
  COMPLETE_PASSWORD_RESET_ROUTE,
  EDITOR_PAGE_ROUTE,
  EXPIRED_INVITATION_ROUTE,
  INDEX_ROUTE,
  LEGAL_PRIVACY_POLICY_ROUTE,
  LEGAL_TERMS_OF_USE_ROUTE,
  LOGIN_ROUTE,
  NEW_EDITOR_PAGE_ROUTE,
  PUBLIC_TEMPLATE_ROUTE,
  QUICK_RECORDING_ROUTE,
  REGISTER_APP_SUMO_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  UI_COMPONENTS_PAGE,
  redirect,
} from '../../../entities/history';
import { completeResetPasswordWorker } from '../../../pages/create-password';

import { resetPasswordWorker } from '../../../pages/forgot-password';
import { signInWorker } from '../../../pages/sign-in';
import { signUpWorker } from '../../../pages/sign-up';
import { signUpAppSumoWorker } from '../../../pages/sign-up-app-sumo';
import { recordDetailsWorker } from '../../../pages/recordings/model';

import { publicTemplateWorker } from '../../../pages/public-template/model';
import { nil } from '../../../shared/lib';
import { sharedRouteHandlers } from './sharedRouteHandlers';
import { RouteHandler } from './types';
import { publicEditorPageSaga } from '../../../pages/editor';
import { publicNewEditorPageSaga } from '../../../pages/new-editor';

export const publicRouteHandlers: RouteHandler[] = [
  { route: AUTH_ROUTE, saga: nil },
  { route: INDEX_ROUTE, saga: redirect.toLogin },
  { route: REGISTER_ROUTE, saga: signUpWorker },
  { route: REGISTER_APP_SUMO_ROUTE, saga: signUpAppSumoWorker },
  { route: LOGIN_ROUTE, saga: signInWorker },
  { route: RESET_PASSWORD_ROUTE, saga: resetPasswordWorker },
  { route: COMPLETE_PASSWORD_RESET_ROUTE, saga: completeResetPasswordWorker },
  { route: LEGAL_PRIVACY_POLICY_ROUTE, saga: nil },
  { route: LEGAL_TERMS_OF_USE_ROUTE, saga: nil },
  { route: UI_COMPONENTS_PAGE, saga: nil },
  { route: PUBLIC_TEMPLATE_ROUTE, saga: publicTemplateWorker },
  { route: QUICK_RECORDING_ROUTE, saga: recordDetailsWorker(true) },
  { route: EDITOR_PAGE_ROUTE, saga: publicEditorPageSaga },
  { route: NEW_EDITOR_PAGE_ROUTE, saga: publicNewEditorPageSaga },
  { route: EXPIRED_INVITATION_ROUTE, saga: nil },
  ...sharedRouteHandlers,
];
