export const getDateTime = (date: string | Date) => {
  const dateObj = new Date(date);

  return new Intl.DateTimeFormat('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
    .format(dateObj)
    .toUpperCase();
};
