import React, { FC, useId, useMemo } from 'react';
import { Label } from '@radix-ui/react-label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectPlaceholder,
  SelectTrigger,
} from '../../../shared/ui/select/SelectV2';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { foldersModel, useSplitFoldersToPrivateShared } from '../../folders';
import { pagesModel } from '../model';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Page } from '@distribute/shared/types';
import { Emoji } from 'emoji-picker-react';

export type ChooseFolderForm = {
  folderId: string;
  pageId: string;
};

type SelectPageFormProps = {
  formId?: string;
  onSubmit: (data: ChooseFolderForm) => void;
};

const defaultValue = 'none';
const errorMessage = 'Select the page from list.';

const validationSchema = object().shape({
  folderId: string().notOneOf([defaultValue]).required(''),
  pageId: string()
    .notOneOf([defaultValue], errorMessage)
    .required(errorMessage),
});

export const SelectPageForm: React.FC<SelectPageFormProps> = ({
  formId = 'insert-to-page-form',
  onSubmit,
}) => {
  const folderSelectId = useId();
  const pageSelectId = useId();

  const folders = useSelector(foldersModel.selectors.selectFoldersToEditPage);
  const pages = useSelector(pagesModel.selectors.selectPagesToEdit);

  const { privateFolders, sharedFolders } =
    useSplitFoldersToPrivateShared(folders);

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm<ChooseFolderForm>({
    resolver: yupResolver(validationSchema),
  });

  const {
    field: { value: valuePageId, onChange: onChangePageId },
  } = useController({
    control,
    defaultValue,
    name: 'pageId',
    rules: { required: true },
  });

  const {
    field: { value: valueFolderId, onChange: onChangeFolderId },
  } = useController({
    control,
    defaultValue: folders[0]?.id || defaultValue,
    name: 'folderId',
    rules: { required: true },
  });

  const currentFolder = useMemo(
    () => folders.find((i) => i.id === valueFolderId),
    [folders, valueFolderId]
  );

  const filteredPages = useMemo(
    () => pages.filter((i) => i.folderId === currentFolder?.id),
    [pages, currentFolder]
  );

  const currentPage = useMemo(
    () => filteredPages.find((i) => i.id === valuePageId),
    [filteredPages, valuePageId]
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-y-4"
      id={formId}
    >
      <div className="flex flex-col gap-y-1.5">
        <Label
          className="text-sm font-medium text-gray-700"
          htmlFor={folderSelectId}
        >
          Folder
        </Label>
        <Select
          value={currentFolder?.id}
          onValueChange={(value) => {
            resetField('pageId');
            onChangeFolderId(value);
          }}
        >
          <SelectTrigger id={folderSelectId}>
            {currentFolder?.title}
          </SelectTrigger>
          <SelectContent
            side="bottom"
            align="end"
            sideOffset={4}
            containerId={formId}
          >
            {privateFolders.length > 0 && (
              <>
                <p className="select-none text-gray-500 uppercase text-xs font-semibold px-2.5 pt-3 pb-1">
                  private
                </p>
                {privateFolders.map((f) => (
                  <SelectItem value={f.id} key={f.id}>
                    {f.title}
                  </SelectItem>
                ))}
              </>
            )}
            {sharedFolders.length > 0 && (
              <>
                <p className="select-none text-gray-500 uppercase text-xs font-semibold px-2.5 pt-3 pb-1">
                  shared
                </p>
                {sharedFolders.map((f) => (
                  <SelectItem value={f.id} key={f.id}>
                    {f.title}
                  </SelectItem>
                ))}
              </>
            )}
          </SelectContent>
        </Select>
      </div>
      <div className="flex flex-col gap-y-1.5">
        <Label
          className="text-sm font-medium text-gray-700"
          htmlFor={pageSelectId}
        >
          Page
        </Label>
        <Select
          value={valuePageId}
          onValueChange={(value) => value && onChangePageId(value)}
        >
          <SelectTrigger
            id={pageSelectId}
            className={classNames({ '!border-error-600': !!errors.pageId })}
          >
            {currentPage ? (
              <PageItem page={currentPage} />
            ) : (
              <SelectPlaceholder value="Select" />
            )}
          </SelectTrigger>
          <SelectContent
            side="bottom"
            align="end"
            sideOffset={4}
            containerId={formId}
          >
            {filteredPages.map((p) => (
              <SelectItem value={p.id} key={p.id}>
                <PageItem page={p} />
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {!!errors.pageId && (
          <p className="text-sm text-error-600">{errors.pageId.message}</p>
        )}
      </div>
    </form>
  );
};

const PageItem: FC<{ page: Page }> = ({ page }) => {
  const {
    content: { brandLogo, icon },
  } = page;

  return (
    <div className="flex items-center gap-x-2 min-w-0">
      <div className="w-6 h-6 border-[0.5px] rounded border-light-7 flex items-center justify-center overflow-hidden">
        {brandLogo ? (
          <img src={brandLogo} className="block w-6 h-6" alt="page icon" />
        ) : (
          <Emoji unified={icon} size={16} />
        )}
      </div>
      <span className="truncate">{page.content.title || 'Untitled'}</span>
    </div>
  );
};
