import React, { useEffect } from 'react';
import { CreatePageStepLeftWrapper } from './components/CreatePageStepLeftWrapper';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Tooltip,
} from '../../../../../shared/ui';
import { pageCreationFlowModel } from '../../../model';
import { useDispatch, useSelector } from 'react-redux';
import { useCreatePageStepContext } from '../context';
import { useFormContext } from 'react-hook-form';
import {
  ActiveAIStepType,
  CreatePageFormSchemaType,
} from '../../../config/types';
import { IconMap } from '../../../../../shared/sprite';
import cn from 'classnames';
import {
  PageType,
  PageTypeTrigger,
  Source,
  SourceTrigger,
} from './AccordionItems';
import { useTiptapEditor } from '../../../../../entities/tiptap-editor';
import { pagesModel } from '../../../../../features/pages';
import { DocumentContentItem, Page } from '@distribute/shared/types';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { useCollaboration } from '../../../../../entities/collaboration';
import { generateHTML } from '@tiptap/core';
import { aiModel } from '../../../../../features/ai';

const Footer = () => {
  const dispatch = useDispatch();
  const { editor } = useTiptapEditor();

  const { isPageTypeDone, isSourceDone } = useCreatePageStepContext();

  const { watch, setValue } = useFormContext<CreatePageFormSchemaType>();
  const formData = watch();

  const isGeneratingOutline = useSelector(
    pageCreationFlowModel.selectors.selectIsGenerateOutlineLoading
  );

  const wasOutlineGenerationStarted = useSelector(
    pageCreationFlowModel.selectors.selectWasOutlineGenerationStarted
  );

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPage
  ) as Page;

  const {
    isCollaborationEnabled,
    tabs: collaborativeTabs,
    updateTab,
  } = useCollaboration();

  const currentPageContentId = useSelector(
    pagesModel.selectors.selectCurrentContentId
  ) as number;

  const currentTab = isCollaborationEnabled
    ? (collaborativeTabs.find(
        (el) => el.id === currentPageContentId
      ) as DocumentContentItem)
    : currentPage.content.contentItems.find(
        (tab) => tab.id === currentPageContentId
      );

  useEffect(() => {
    if (!currentTab?.name) {
      return;
    }

    setValue('pageTitle', currentTab.name);
  }, [currentTab?.name, setValue, currentTab]);

  const getTabsContent = () => {
    if (!editor) return '';

    return currentPage.content.contentItems
      .map((el) =>
        generateHTML(el.contentJson, editor.extensionManager.extensions)
      )
      .join('\n');
  };

  const handleGenerateOutline = () => {
    dispatch(
      pageCreationFlowModel.actions.setActiveAIStep(ActiveAIStepType.OUTLINE)
    );
    dispatch(
      pageCreationFlowModel.actions.generateOutline({
        ...formData,
        existingTabsContext: formData.isUsingTabsAsAContext
          ? getTabsContent()
          : '',
      })
    );
  };

  const handleGenerateTab = () => {
    if (!currentTab) {
      dispatch(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', 'Failed to find active tab')
        )
      );
      return;
    }

    dispatch(aiModel.actions.setIsStructuredResponse(true));

    if (isCollaborationEnabled) {
      updateTab(currentTab.id, { name: formData.pageTitle });
    } else {
      dispatch(
        pagesModel.actions.updatePageContentItem({
          pageId: currentPage.id,
          contentItemId: currentTab.id,
          data: { name: formData.pageTitle },
        })
      );
    }

    dispatch(
      pageCreationFlowModel.actions.generateTab({
        ...formData,
        pageId: currentPage.id,
        slug: currentPage.pageSlug,
        existingTabsContext: formData.isUsingTabsAsAContext
          ? getTabsContent()
          : '',
      })
    );
    editor?.commands.renderAI();
  };

  if (wasOutlineGenerationStarted) {
    return (
      <div className="flex gap-4">
        <Button
          variant="text"
          disabled={isGeneratingOutline}
          color="secondary"
          size="xl"
          className="w-full"
          onClick={handleGenerateOutline}
        >
          Regenerate outline
        </Button>
        <Tooltip
          triggerClassNames="w-full"
          trigger={
            <Button
              variant="icon-text"
              disabled={isGeneratingOutline}
              color="primary"
              size="xl"
              className="w-full"
              iconLeftName={IconMap.Star06}
              iconLeftWidth={20}
              onClick={handleGenerateTab}
            >
              Generate
            </Button>
          }
          sideOffset={4}
        >
          <p className="px-2 py-1 font-semibold text-xs">
            Generate tab with current outline
          </p>
        </Tooltip>
      </div>
    );
  }

  return (
    <Tooltip
      className={cn({ hidden: isPageTypeDone && isSourceDone })}
      trigger={
        <Button
          variant="text"
          disabled={!(isPageTypeDone && isSourceDone)}
          color="primary"
          size="xl"
          className="w-full"
          loading={isGeneratingOutline}
          onClick={handleGenerateOutline}
        >
          Generate outline
        </Button>
      }
      sideOffset={4}
    >
      <p className="px-2 py-1 font-semibold text-xs">
        Please, finish all previous steps to generate tab outline
      </p>
    </Tooltip>
  );
};

export const CreateTabWithAI: React.FC = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector(
    pageCreationFlowModel.selectors.selectActiveAIStep
  );
  const setActiveStep = (step: ActiveAIStepType | null) => {
    dispatch(pageCreationFlowModel.actions.setActiveAIStep(step));
  };

  const { isPageTypeDone, isSourceDone } = useCreatePageStepContext();

  const handleValueChange = (value: string | undefined) => {
    setActiveStep((value ?? null) as ActiveAIStepType | null);
  };

  return (
    <CreatePageStepLeftWrapper footer={<Footer />}>
      <div className="flex-1 overflow-y-auto h-full">
        <Accordion
          value={activeStep?.toString()}
          onValueChange={handleValueChange}
          type="single"
          collapsible
          className="gap-4 flex flex-col"
        >
          <AccordionItem value="type">
            <AccordionTrigger
              className={cn({
                '[&[data-state=closed]]:py-3': isPageTypeDone,
              })}
            >
              <PageTypeTrigger
                isOpened={activeStep === ActiveAIStepType.TYPE}
                isDone={isPageTypeDone}
              />
            </AccordionTrigger>
            <AccordionContent>
              <PageType
                onNext={() => setActiveStep(ActiveAIStepType.SOURCE)}
                isDone={isPageTypeDone}
              />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="source">
            <AccordionTrigger
              className={cn({
                '[&[data-state=closed]]:py-3': isSourceDone,
              })}
            >
              <SourceTrigger
                isOpened={activeStep === ActiveAIStepType.SOURCE}
                isDone={isSourceDone}
              />
            </AccordionTrigger>
            <AccordionContent>
              <Source
                onNext={() => {
                  setActiveStep(ActiveAIStepType.OUTLINE);
                }}
                isDone={isSourceDone}
                isGeneratingNewTab
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </CreatePageStepLeftWrapper>
  );
};
