export const urlWith = (url: string, params: Record<string, string>) => {
  return Object.keys(params).length ? `${url}${setParams(params)}` : url;
};

export const urlWithProtocol = (url: string) =>
  /^https?:\/\/.+/.test(url) ? url : 'https://' + url;

export const setParams = (data: { [key: string]: string }) => {
  return `?${new URLSearchParams(data).toString()}`;
};
export const getParams = (text: string) => new URLSearchParams(text);
