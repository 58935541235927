import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { tasksModel } from '..';
import { tasksApi } from '../../../../../src/shared/api';
import { TasklistWithTasks } from '@distribute/shared/types';

export function* duplicateTask({
  payload: { successCallback, tasklistId, ...payloadData },
}: ReturnType<typeof tasksModel.actions.duplicateTask>) {
  const tasklists: Record<string, TasklistWithTasks> = yield select(
    tasksModel.selectors.selectTasklists
  );

  try {
    const { taskId, documentContentId } = payloadData;

    const updatedTasklist: TasklistWithTasks = yield call(
      tasksApi.duplicateTask,
      taskId,
      documentContentId
    );

    yield put(
      tasksModel.actions.setTasklists({
        ...tasklists,
        [tasklistId]: updatedTasklist,
      })
    );

    successCallback(updatedTasklist);
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to duplicate task')
      )
    );
    yield put(tasksModel.actions.setTasklists(tasklists));
  }
}
