import { Editor } from '@tiptap/core';
import { Node as TiptapNode } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import tippy, { Instance, Props } from 'tippy.js';

const shouldNotDiveNodes = new Set([
  'heading',
  'paragraph',
  'table',
  'blockquote',
  'callout',
  'timeline',
  'timelineItem',
  'horizontalRule',
  'resizeableFigure',
  'button',
  'codeBlock',
  'listItem',
  'toggleListItem',
  'todoListItem',
  'embedContentBlock',
  'snippet',
]);

export const addButtonHoveringBlockPluginKey =
  new PluginKey<HoveringBlockPluginState>('addButtonHoveringBlock');

export interface HoveringBlockPluginProps {
  editor: Editor;
  element: HTMLElement;
  tags: string[];
  tippyOptions?: Partial<Props>;
  hoveringBlockWidth: number;
}

export type HoveringBlockViewProps = HoveringBlockPluginProps;

export type HoveringBlockPluginState = {
  hoveredBlockNode: TiptapNode | null;
  hoveredBlockPosition: number | null;
};

const HOVERING_BUTTONS_HEIGHT = 36;
const HOVERING_BUTTONS_TOP_OFFSET = 3;
export const DIVIDER_TOP_OFFSET = -15;

export const getHoveringBlockTopOffset = (lineHeight?: number | null) => {
  if (lineHeight === 1) {
    return DIVIDER_TOP_OFFSET;
  }
  if (!lineHeight || lineHeight < HOVERING_BUTTONS_HEIGHT) {
    return 0;
  }
  return (
    (lineHeight - HOVERING_BUTTONS_HEIGHT) / 2 + HOVERING_BUTTONS_TOP_OFFSET
  );
};

export class HoveringBlockView {
  public editor: Editor;
  public element: HTMLElement;
  public tippy: Instance | undefined;
  public tippyOptions?: Partial<Props>;
  public hoveringBlockWidth: number;

  private blockPosition: number | null;
  private nodeLineHeight: number | null;
  private tippyMouseLeaveHandler: () => void;
  private scrollPageHandler: () => void;

  constructor({
    editor,
    element,
    tippyOptions = {},
    hoveringBlockWidth,
  }: HoveringBlockViewProps) {
    this.editor = editor;
    this.element = element;
    this.tippyOptions = tippyOptions;
    this.blockPosition = null;
    this.nodeLineHeight = null;
    this.tippyMouseLeaveHandler = this.hide.bind(this);
    this.scrollPageHandler = this.updateTippyPosition.bind(this);
    this.hoveringBlockWidth = hoveringBlockWidth;

    this.element.remove();
    this.element.style.visibility = 'visible';
  }

  createTooltip() {
    const { element: editorElement } = this.editor.options;
    const editorIsAttached = !!editorElement.parentElement;

    if (this.tippy || !editorIsAttached) {
      return;
    }

    this.tippy = tippy(editorElement, {
      duration: 0,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
      hideOnClick: 'toggle',
      offset: [0, 0],
      popperOptions: {
        modifiers: [{ name: 'flip', enabled: false }],
      },
      zIndex: 10,
      ...this.tippyOptions,
    });
  }

  show(blockPosition: number, lineHeight: number, blockHeight?: number) {
    if (!this.editor.isEditable) {
      return;
    }

    this.element.style.height = blockHeight ? `${blockHeight}px` : 'auto';
    this.blockPosition = blockPosition;
    this.nodeLineHeight = lineHeight;
    this.createTooltip();
    this.tippy?.setProps({
      getReferenceClientRect: () =>
        this.getBlockCoordinates(blockPosition, lineHeight),
    });
    this.tippy?.show();
    this.tippy?.popper.addEventListener(
      'mouseleave',
      this.tippyMouseLeaveHandler
    );
    document.addEventListener('scroll', this.scrollPageHandler);
  }

  hide() {
    this.tippy?.hide();
    this.tippy?.popper.removeEventListener(
      'mouseleave',
      this.tippyMouseLeaveHandler
    );
    document.removeEventListener('scroll', this.scrollPageHandler);
  }

  updateTippyPosition() {
    if (!this.editor.isEditable) {
      this.hide();
      return;
    }

    if (this.blockPosition !== null) {
      this.tippy?.setProps({
        getReferenceClientRect: () =>
          this.getBlockCoordinates(
            this.blockPosition as number,
            this.nodeLineHeight
          ),
      });
    }
  }

  private getBlockCoordinates(
    blockPosition: number,
    lineHeight: number | null
  ) {
    const paddingLeft = Number.parseInt(
      getComputedStyle(this.editor.view.dom).paddingLeft
    );
    let left = this.editor.view.dom.getBoundingClientRect().left + paddingLeft;
    const coordinates = this.editor.view.coordsAtPos(blockPosition);
    const node = this.editor.state.doc.nodeAt(blockPosition);

    const resolvedPosition = this.editor.state.doc.resolve(blockPosition);

    let isInsideColumn = false;
    let columnElement: Element | null = null;

    const path = (
      resolvedPosition as unknown as { path: (number | TiptapNode)[] }
    ).path;

    let depth = 0;
    path.forEach((i) => {
      if (typeof i === 'number') {
        return;
      }

      if (i.type.name === 'column') {
        depth += 1;
        isInsideColumn = true;

        const columnPosition = resolvedPosition.posAtIndex(0, depth + 1);
        columnElement = this.editor.view.domAtPos(columnPosition, 0)
          .node as Element;
      }
    }, this);

    if (isInsideColumn && columnElement) {
      left = (columnElement as Element).getBoundingClientRect().left;
    }

    if (node && node.type.name === 'column') {
      const domNode = this.editor.view.domAtPos(blockPosition + 1, 0);

      left = (domNode.node as Element).getBoundingClientRect().left;
    }
    const topOffset = getHoveringBlockTopOffset(lineHeight);

    return new DOMRect(
      left - this.hoveringBlockWidth,
      coordinates.top + topOffset,
      0,
      0
    );
  }
}

export const HoveringBlockPlugin = (options: HoveringBlockPluginProps) => {
  const hoveringBlockView = new HoveringBlockView(options);
  let lastPosition: number | null = null;

  function getBlockNode(view: EditorView, position: number, tags: string[]) {
    if (!view.dom.pmViewDesc) {
      return null;
    }
    const getChildrenBlockNode = (
      blockView: typeof view.dom.pmViewDesc
    ): typeof view.dom.pmViewDesc | null => {
      let blockNode: typeof view.dom.pmViewDesc | null = null;
      if (!blockView.children) {
        return null;
      }

      blockNode =
        blockView.children.find((i) => {
          return i.posAtStart <= position && i.posAtEnd >= position;
        }) || null;

      if (!blockNode) return blockView;

      const shouldCheckChildren =
        !shouldNotDiveNodes.has(blockNode.node?.type.name ?? '') &&
        blockNode.children.length > 0;

      if (shouldCheckChildren) {
        return getChildrenBlockNode(blockNode);
      }

      return blockNode;
    };
    return getChildrenBlockNode(view.dom.pmViewDesc);
  }

  return new Plugin<HoveringBlockPluginState>({
    key: addButtonHoveringBlockPluginKey,
    state: {
      init() {
        return { hoveredBlockNode: null, hoveredBlockPosition: null };
      },
      apply(tr, state) {
        const newPluginState = tr.getMeta(addButtonHoveringBlockPluginKey);

        if (newPluginState) {
          return newPluginState;
        }

        return state;
      },
    },

    props: {
      handleDOMEvents: {
        mousemove(view, event) {
          if (!options.editor.isEditable) {
            if (hoveringBlockView.tippy?.state.isShown) {
              hoveringBlockView.hide();
            }
            return;
          }

          const nodePosition = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });

          if (nodePosition) {
            const blockPosition =
              nodePosition.inside < 0 ? nodePosition.pos + 1 : nodePosition.pos;

            const blockNode =
              getBlockNode(view, blockPosition, options.tags) ||
              view.dom.pmViewDesc?.children.find(
                (i) => i.posAtEnd >= blockPosition
              );

            if (
              blockNode &&
              blockNode.node?.type.name &&
              options.tags.includes(blockNode.node.type.name)
            ) {
              let position = view.posAtDOM(blockNode.dom, 0);
              view.state.doc.nodesBetween(position, position, (node, pos) => {
                if (options.tags.includes(node.type.name)) {
                  position = pos;
                }
              });

              if (position === lastPosition) return;

              lastPosition = position;

              view.dispatch(
                view.state.tr.setMeta(addButtonHoveringBlockPluginKey, {
                  hoveredBlockNode: blockNode.node,
                  hoveredBlockPosition: position,
                })
              );
              let lineHeight;
              try {
                lineHeight = Number.parseInt(
                  document.defaultView
                    ?.getComputedStyle(blockNode.dom as HTMLElement, null)
                    .getPropertyValue('line-height') ?? ''
                );
                hoveringBlockView.show(
                  position,
                  blockNode.node.type.name === 'horizontalRule'
                    ? 1
                    : lineHeight ?? 0,
                  blockNode.contentDOM?.offsetHeight
                );
                // eslint-disable-next-line no-empty
              } catch {}
            }
          }
        },
        mouseout: (view, event) => {
          if (!options.editor.isEditable) {
            if (hoveringBlockView.tippy?.state.isShown) {
              hoveringBlockView.hide();
            }
            return;
          }

          if (
            !hoveringBlockView.tippy ||
            hoveringBlockView.tippy.popper.contains(event.relatedTarget as Node)
          ) {
            lastPosition = null;
            return;
          }

          const nodePosition = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });
          const state = addButtonHoveringBlockPluginKey.getState(view.state);

          if (
            state?.hoveredBlockPosition &&
            state.hoveredBlockNode &&
            nodePosition &&
            nodePosition.pos >= state.hoveredBlockPosition &&
            nodePosition.pos <=
              state.hoveredBlockPosition + state.hoveredBlockNode.nodeSize
          ) {
            return;
          }

          hoveringBlockView.hide();
          lastPosition = null;
          view.dispatch(
            view.state.tr.setMeta(addButtonHoveringBlockPluginKey, {
              hoveredBlock: null,
              hoveredBlockPosition: null,
            })
          );
        },
      },
    },
  });
};
