import { FC } from 'react';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { CalloutNodeView } from './Callout.renderer';
import { CalloutExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    callout: {
      insertCallout: (options?: { emoji?: string }) => ReturnType;
      toggleCallout: (options?: { emoji?: string }) => ReturnType;
    };
  }
}

export const Callout = CalloutExt.extend({
  addCommands() {
    return {
      toggleCallout:
        () =>
        ({ commands }) => {
          return commands.toggleWrap(this.name);
        },
      insertCallout:
        (options?: { emoji?: string }) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              emoji: options?.emoji || '💡',
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(CalloutNodeView as FC);
  },
});
