import { useTeamPermissions } from '../../../../../features/teams';
import { chatModel } from '../../../../../features/chat';
import {
  editorRightSidebarModel,
  RightSidebarOption,
} from '../../../../../features/editor-right-sidebar';
import { IconMap } from '../../../../../shared/sprite';
import { Icon, Tooltip } from '../../../../../shared/ui';
import { useDispatch } from 'react-redux';

const AiButton = () => {
  const dispatch = useDispatch();
  return (
    <div className="flex h-12 w-12 overflow-hidden relative rounded-lg group cursor-pointer bg-gray-200 hover:bg-gradient-orange-to-purple hover:shadow-sm hover:p-0.5 p-0.375">
      <button
        type="button"
        className="flex-grow-1 rounded-md bg-base-white flex flex-nowrap items-center gap-x-3 text-md text-gray-700 font-medium group-hover:p-2.5 p-2.625 group-hover:text-gray-900"
        onClick={() => {
          dispatch(chatModel.actions.setIsFocusInput(true));
          dispatch(
            editorRightSidebarModel.actions.setSelectedOption(
              RightSidebarOption.AI_BOT
            )
          );
        }}
      >
        <Icon glyph={IconMap.AIStarsColor} width={24} />
      </button>
    </div>
  );
};

export const CreateAiButton = () => {
  const { isGuest } = useTeamPermissions();
  if (isGuest) return null;

  return (
    <Tooltip hideArrow className="max-w-22 text-center" trigger={<AiButton />}>
      <p>Ask</p>
      <p>AI assistant</p>
    </Tooltip>
  );
};
