import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Editor } from '@tiptap/react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Command, CommandGroup } from '../types';
import { MenuButton } from './MenuButton';
import { pagesModel } from '../../../../features/pages';
import { MenuSwitcherRadioButton } from './MenuSwitcherRadioButton';
import { IconMap } from '../../../../shared/sprite';
import { snippetsModel } from '../../../../features/snippets';
import { snippetMenuIconByType } from '../../../../features/snippets/ui/components/lib/config';
import { Icon, Tooltip } from '../../../../shared/ui';
import { SnippetPreview } from '../../../../features/snippets/ui/components';
import { SLASH_MENU_GROUPS } from '../../../../pages/editor/lib/commands';
import { Snippet } from '@distribute/shared/types';
import { useSnippetsPermissions } from '../../../../features/teams';
import { getSlashMenuWithoutTasks } from '../../../../../src/pages/snippets/ui/snippet-editor/lib/helpers';

export interface MenuListProps {
  editor?: Editor;
  items: CommandGroup[];
  command: (command: Command) => void;
  isHideSwitcher?: boolean;
  query?: string;
}

export enum MenuSwitcherOptions {
  COMPONENTS = 'components',
  SNIPPETS = 'snippets',
}

const menuOptions = [
  {
    name: 'Elements',
    value: MenuSwitcherOptions.COMPONENTS,
    iconGlyph: IconMap.Grid02,
  },
  {
    name: 'Snippets',
    value: MenuSwitcherOptions.SNIPPETS,
    iconGlyph: IconMap.Snippets,
  },
];

export const MenuList = React.forwardRef((props: MenuListProps, ref) => {
  const { query = '' } = props;

  const dispatch = useDispatch();

  const location = useLocation();

  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedCommandIndex, setSelectedCommandIndex] = useState(0);
  const [selectedTeamCommandIndex, setSelectedTeamCommandIndex] = useState(0);

  const menuListRef = useRef<HTMLDivElement>(null);

  const [switcherOption, setSwitcherOption] = useState<MenuSwitcherOptions>(
    MenuSwitcherOptions.COMPONENTS
  );

  const [isArrowPriority, setIsArrowPriority] = useState(false);

  const personalSnippets = useSelector(
    snippetsModel.selectors.selectPersonalSnippets
  );

  const recentSnippets = useSelector(
    snippetsModel.selectors.selectRecentSnippets
  );

  const snippetCategories = useSelector(
    snippetsModel.selectors.selectCategories
  );

  const availableSnippets = useSelector(
    snippetsModel.selectors.selectAvailableSnippets
  );

  const { isCanCreatePersonalSnippets } = useSnippetsPermissions();

  const isSnippetEditor = useMemo(() => {
    return location.pathname.includes('snippet');
  }, [location.pathname]);

  const snippetCommands = useMemo(() => {
    const recentSnippetsGroup =
      recentSnippets.length > 0
        ? [
            {
              label: 'Recent',
              categories: [
                {
                  groupLabel: '',
                  items: recentSnippets.map((snippet) => {
                    return {
                      name: snippet.name || 'Untitled',
                      icon: (
                        <Icon
                          glyph={snippetMenuIconByType[snippet.type]}
                          width={20}
                        />
                      ),
                      key: snippet.id,
                      aliases: [snippet.name],
                      action: (editor: Editor) => {
                        dispatch(
                          snippetsModel.actions.updateSnippetUsedCount({
                            snippetId: snippet.id,
                          })
                        );
                        editor
                          .chain()
                          .insertSnippet({
                            id: snippet.id,
                            type: snippet.type,
                            content: snippet.content,
                          })
                          .focus()
                          .run();
                      },
                      actionPreview: {
                        name: snippet.name,
                        description: snippet.description,
                        icon: (
                          <Icon
                            glyph={snippetMenuIconByType[snippet.type]}
                            width={20}
                          />
                        ),
                        cover: <SnippetPreview snippet={snippet} />,
                      },
                    };
                  }),
                },
              ],
            },
          ]
        : [];

    const snippetCategoriesWithSnippets = snippetCategories.filter(
      (c) => c.snippets.length > 0
    );

    const teamSnippetsGroup =
      snippetCategoriesWithSnippets.length > 0
        ? [
            {
              label: 'Team',
              categories: snippetCategories
                .filter((c) => c.snippets.length > 0)
                .map((category) => {
                  return {
                    groupLabel: category.name,
                    items: category.snippets.map((snippet) => {
                      return {
                        name: snippet.name || 'Untitled',
                        icon: (
                          <Icon
                            glyph={snippetMenuIconByType[snippet.type]}
                            width={20}
                            className="min-w-5"
                          />
                        ),
                        key: snippet.id,
                        aliases: [snippet.name],
                        action: (editor: Editor) => {
                          dispatch(
                            snippetsModel.actions.updateSnippetUsedCount({
                              snippetId: snippet.id,
                            })
                          );
                          editor
                            .chain()
                            .insertSnippet({
                              id: snippet.id,
                              type: snippet.type,
                              content: snippet.content,
                            })
                            .focus()
                            .run();
                        },
                        actionPreview: {
                          name: snippet.name,
                          description: snippet.description,
                          icon: (
                            <Icon
                              glyph={snippetMenuIconByType[snippet.type]}
                              width={20}
                              className="min-w-5"
                            />
                          ),
                          cover: <SnippetPreview snippet={snippet} />,
                        },
                      };
                    }),
                  };
                }),
            },
          ]
        : [];

    const personalSnippetsGroup =
      personalSnippets.length > 0 && isCanCreatePersonalSnippets
        ? [
            {
              label: 'Personal',
              categories: [
                {
                  groupLabel: '',
                  items: personalSnippets.map((snippet) => {
                    return {
                      name: snippet.name || 'Untitled',
                      icon: (
                        <Icon
                          glyph={snippetMenuIconByType[snippet.type]}
                          width={20}
                          className="min-w-5"
                        />
                      ),
                      key: snippet.id,
                      aliases: [snippet.name],
                      action: (editor: Editor) => {
                        dispatch(
                          snippetsModel.actions.updateSnippetUsedCount({
                            snippetId: snippet.id,
                          })
                        );
                        editor
                          .chain()
                          .insertSnippet({
                            id: snippet.id,
                            type: snippet.type,
                            content: snippet.content,
                          })
                          .focus()
                          .run();
                      },
                      actionPreview: {
                        name: snippet.name,
                        description: snippet.description,
                        icon: (
                          <Icon
                            glyph={snippetMenuIconByType[snippet.type]}
                            width={20}
                            className="min-w-5"
                          />
                        ),
                        cover: <SnippetPreview snippet={snippet} />,
                      },
                    };
                  }),
                },
              ],
            },
          ]
        : [];

    return [
      {
        label: '',
        categories: [
          {
            groupLabel: '',
            items: [
              {
                name: 'See all snippets',
                icon: <Icon glyph={IconMap.ArrowCircleUpRight} width={20} />,
                key: 'see-all-snippets',
                aliases: ['see-all-snippets'],
                action: (editor: Editor) => {
                  editor.commands.renderSnippetsModal();
                },
                actionPreview: undefined,
              },
            ],
          },
        ],
      },
      ...recentSnippetsGroup,
      ...teamSnippetsGroup,
      ...personalSnippetsGroup,
    ];
  }, [snippetCategories, personalSnippets]);

  const commandSnippetsArray = useMemo(() => {
    const res: Snippet[] = [];

    snippetCategories.forEach((c) => {
      res.push(...c.snippets);
    });

    return res;
  }, [snippetCategories]);

  const snippetsCommands = isSnippetEditor
    ? []
    : [
        {
          label: 'Snippets',
          items: [
            ...[...personalSnippets, ...commandSnippetsArray].map((snippet) => {
              return {
                name: snippet.name || 'Untitled',
                icon: (
                  <Icon
                    glyph={snippetMenuIconByType[snippet.type]}
                    width={20}
                    className="min-w-5"
                  />
                ),
                key: snippet.id,
                aliases: [snippet.name],
                action: (editor: Editor) => {
                  editor
                    .chain()
                    .insertSnippet({
                      id: snippet.id,
                      type: snippet.type,
                      content: snippet.content,
                    })
                    .focus()
                    .run();
                },
                actionPreview: {
                  name: snippet.name,
                  description: snippet.description,
                  icon: (
                    <Icon
                      glyph={snippetMenuIconByType[snippet.type]}
                      width={20}
                      className="min-w-5"
                    />
                  ),
                  cover: <SnippetPreview snippet={snippet} />,
                },
              };
            }),
          ],
        },
      ];

  const commandsForSearch: CommandGroup[] = [
    ...(isSnippetEditor ? getSlashMenuWithoutTasks() : SLASH_MENU_GROUPS),
    ...snippetsCommands,
  ];

  const visibleCommandsBySearch: CommandGroup[] = useMemo(() => {
    return commandsForSearch
      .map((group) => {
        return {
          label: group.label,
          items: group.items.filter((item: Command) =>
            item.aliases.some((alias) =>
              alias.toLowerCase().includes(query.toLowerCase())
            )
          ),
        };
      })
      .filter((c) => c.items.length > 0);
  }, [query]);

  // Anytime the groups change, i.e. the user types to narrow it down, we want to
  // reset the current selection to the first menu item
  useEffect(() => {
    setSelectedGroupIndex(0);
    setSelectedCommandIndex(0);
    setSelectedTeamCommandIndex(0);
  }, [props.items, switcherOption]);

  useEffect(() => {
    props.editor?.chain().focus();
  }, [switcherOption]);

  useEffect(() => {
    dispatch(pagesModel.actions.setActionPreview(null));

    return () => {
      dispatch(pagesModel.actions.setActionPreview(null));
    };
  }, []);

  const selectItem = (groupIndex: number, commandIndex: number) => {
    const command = query.length
      ? visibleCommandsBySearch[groupIndex].items[commandIndex]
      : props.items[groupIndex].items[commandIndex];
    props.command(command);
    dispatch(pagesModel.actions.setActionPreview(null));
  };

  const selectSnippetItem = (
    groupIndex: number,
    teamCommandIndex: number,
    commandIndex: number
  ) => {
    const command =
      snippetCommands[groupIndex].categories[teamCommandIndex].items[
        commandIndex
      ];
    props.command(command);
    dispatch(pagesModel.actions.setActionPreview(null));
  };

  const turnOffArrowPriority = () => {
    setIsArrowPriority(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', turnOffArrowPriority);

    return () => {
      document.removeEventListener('mousemove', turnOffArrowPriority);
    };
  });

  const handleComponentsArrowDown = () => {
    setIsArrowPriority(true);

    if (!props.items.length) {
      return false;
    }

    let newGroupIndex = selectedGroupIndex;
    let newCommandIndex = selectedCommandIndex + 1;

    if (newCommandIndex > props.items[newGroupIndex].items.length - 1) {
      newGroupIndex = newGroupIndex + 1;
      newCommandIndex = 0;
    }

    if (newGroupIndex >= props.items.length) {
      newGroupIndex = 0;
    }

    setSelectedGroupIndex(newGroupIndex);
    setSelectedCommandIndex(newCommandIndex);

    return true;
  };

  const handleSnippetsArrowDown = () => {
    setIsArrowPriority(true);

    let newGroupIndex = selectedGroupIndex;
    let newTeamCommandIndex = selectedTeamCommandIndex;
    let newCommandIndex = selectedCommandIndex + 1;

    if (
      newCommandIndex >
      snippetCommands[newGroupIndex].categories[newTeamCommandIndex].items
        .length -
        1
    ) {
      newTeamCommandIndex += 1;

      if (
        newTeamCommandIndex >
        snippetCommands[newGroupIndex].categories.length - 1
      ) {
        newGroupIndex += 1;
        newTeamCommandIndex = 0;
      }

      newCommandIndex = 0;
    }

    if (newGroupIndex >= snippetCommands.length) {
      newGroupIndex = 0;
    }

    setSelectedGroupIndex(newGroupIndex);
    setSelectedCommandIndex(newCommandIndex);
    setSelectedTeamCommandIndex(newTeamCommandIndex);

    return true;
  };

  const handleSearchArrowDown = () => {
    setIsArrowPriority(true);

    let newGroupIndex = selectedGroupIndex;
    let newCommandIndex = selectedCommandIndex + 1;

    if (
      newCommandIndex >
      visibleCommandsBySearch[newGroupIndex].items.length - 1
    ) {
      newGroupIndex = newGroupIndex + 1;
      newCommandIndex = 0;
    }

    if (newGroupIndex >= visibleCommandsBySearch.length) {
      newGroupIndex = 0;
    }

    setSelectedGroupIndex(newGroupIndex);
    setSelectedCommandIndex(newCommandIndex);
    return true;
  };

  const handleComponentsArrowUp = () => {
    setIsArrowPriority(true);

    if (!props.items.length) {
      return false;
    }

    let newGroupIndex = selectedGroupIndex;
    let newCommandIndex = selectedCommandIndex - 1;

    if (newCommandIndex < 0) {
      newGroupIndex = newGroupIndex - 1;
    }

    if (newGroupIndex < 0) {
      newGroupIndex = props.items.length - 1;
    }

    if (newCommandIndex < 0) {
      newCommandIndex = props.items[newGroupIndex].items.length - 1;
    }

    setSelectedGroupIndex(newGroupIndex);
    setSelectedCommandIndex(newCommandIndex);
    return true;
  };

  const handleSnippetsArrowUp = () => {
    setIsArrowPriority(true);
    let newGroupIndex = selectedGroupIndex;
    let newTeamCommandIndex = selectedTeamCommandIndex;
    let newCommandIndex = selectedCommandIndex - 1;

    if (newCommandIndex < 0) {
      newTeamCommandIndex -= 1;

      if (newTeamCommandIndex < 0) {
        newGroupIndex -= 1;
        newTeamCommandIndex =
          snippetCommands[newGroupIndex].categories.length - 1;
      }

      newCommandIndex =
        snippetCommands[newGroupIndex].categories[newTeamCommandIndex].items
          .length - 1;
    }

    if (newGroupIndex < 0) {
      newGroupIndex = snippetCommands.length - 1;
    }

    setSelectedGroupIndex(newGroupIndex);
    setSelectedCommandIndex(newCommandIndex);
    setSelectedTeamCommandIndex(newTeamCommandIndex);

    return true;
  };

  const handleSearchArrowUp = () => {
    let newGroupIndex = selectedGroupIndex;
    let newCommandIndex = selectedCommandIndex - 1;

    if (newCommandIndex < 0) {
      newGroupIndex = newGroupIndex - 1;
    }

    if (newGroupIndex < 0) {
      newGroupIndex = visibleCommandsBySearch.length - 1;
    }

    if (newCommandIndex < 0) {
      newCommandIndex = visibleCommandsBySearch[newGroupIndex].items.length - 1;
    }

    setSelectedGroupIndex(newGroupIndex);
    setSelectedCommandIndex(newCommandIndex);
    return true;
  };

  const handleComponentsEnter = () => {
    if (!props.items.length) {
      return false;
    }

    if (
      selectedGroupIndex === -1 ||
      selectedCommandIndex === -1 ||
      !props.items[selectedGroupIndex].items[selectedCommandIndex] ||
      props.items[selectedGroupIndex].items[selectedCommandIndex].disabled
    ) {
      return true;
    }

    selectItem(selectedGroupIndex, selectedCommandIndex);
    return true;
  };

  const handleSnippetsEnter = () => {
    if (
      selectedGroupIndex === -1 ||
      selectedCommandIndex === -1 ||
      selectedTeamCommandIndex === -1 ||
      !snippetCommands[selectedGroupIndex].categories[selectedTeamCommandIndex]
        .items[selectedCommandIndex]
    ) {
      return true;
    }

    selectSnippetItem(
      selectedGroupIndex,
      selectedTeamCommandIndex,
      selectedCommandIndex
    );
    return true;
  };

  const handleSearchEnter = () => {
    if (
      selectedGroupIndex === -1 ||
      selectedCommandIndex === -1 ||
      !visibleCommandsBySearch[selectedGroupIndex].items[selectedCommandIndex]
    ) {
      return true;
    }

    selectItem(selectedGroupIndex, selectedCommandIndex);
    return true;
  };

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      setIsArrowPriority(true);
      if (event.key === 'ArrowRight') {
        if (!props.isHideSwitcher) {
          setSwitcherOption(MenuSwitcherOptions.SNIPPETS);
        }
        return true;
      }
      if (event.key === 'ArrowLeft') {
        setSwitcherOption(MenuSwitcherOptions.COMPONENTS);
        return true;
      }
      if (event.key === 'ArrowDown') {
        return query.length > 0
          ? handleSearchArrowDown()
          : switcherOption === MenuSwitcherOptions.COMPONENTS
          ? handleComponentsArrowDown()
          : handleSnippetsArrowDown();
      }

      if (event.key === 'ArrowUp') {
        return query.length > 0
          ? handleSearchArrowUp()
          : switcherOption === MenuSwitcherOptions.COMPONENTS
          ? handleComponentsArrowUp()
          : handleSnippetsArrowUp();
      }

      if (event.key === 'Enter') {
        setIsArrowPriority(false);
        return query.length > 0
          ? handleSearchEnter()
          : switcherOption === MenuSwitcherOptions.COMPONENTS
          ? handleComponentsEnter()
          : handleSnippetsEnter();
      }

      return false;
    },
  }));

  useEffect(() => {
    const commandItem = query.length
      ? visibleCommandsBySearch[selectedGroupIndex]?.items[selectedCommandIndex]
      : switcherOption === MenuSwitcherOptions.COMPONENTS
      ? props.items[selectedGroupIndex].items[selectedCommandIndex]
      : snippetCommands[selectedGroupIndex].categories[selectedTeamCommandIndex]
          .items[selectedCommandIndex];

    if (!commandItem?.actionPreview) {
      dispatch(pagesModel.actions.setActionPreview(null));
      return;
    }
    const menuPosition = menuListRef.current?.getBoundingClientRect();

    const button = menuListRef.current?.querySelector(
      `.command-${commandItem.key}`
    );

    if (button) {
      const buttonPosition = button.getBoundingClientRect();
      const root = document.getElementById('root');

      const position = {
        left: menuPosition?.left ?? 0,
        right: (menuPosition?.right ?? 0) + 12, // 12 - is menu indent
        top: buttonPosition.top,
        height: buttonPosition.height,
        scrollTop: root?.scrollTop ?? 0,
        scrollLeft: root?.scrollLeft ?? 0,
      };

      dispatch(
        pagesModel.actions.setActionPreview({
          actionPreview: commandItem.actionPreview,
          position,
        })
      );
    }
  }, [
    selectedGroupIndex,
    selectedCommandIndex,
    selectedTeamCommandIndex,
    props.items,
    dispatch,
  ]);

  const handleMouseEnter = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      command: Command
    ) => {
      if (isArrowPriority) {
        return;
      }
      const menuPosition = menuListRef.current?.getBoundingClientRect();
      const button = event.target as HTMLButtonElement;
      const buttonPosition = button.getBoundingClientRect();
      const root = document.getElementById('root');

      if (!command.actionPreview) {
        dispatch(pagesModel.actions.setActionPreview(null));
        return;
      }

      const position = {
        left: menuPosition?.left ?? 0,
        right: (menuPosition?.right ?? 0) + 12, // 12 - is menu indent
        top: buttonPosition.top,
        height: buttonPosition.height,
        scrollTop: root?.scrollTop ?? 0,
        scrollLeft: root?.scrollLeft ?? 0,
      };
      dispatch(
        pagesModel.actions.setActionPreview({
          actionPreview: command.actionPreview,
          position,
        })
      );
    },
    [dispatch, isArrowPriority]
  );

  const handleMouseLeave = useCallback(() => {
    if (isArrowPriority) {
      return;
    }
    dispatch(pagesModel.actions.setActionPreview(null));
  }, [dispatch, isArrowPriority]);

  useEffect(() => {
    dispatch(pagesModel.actions.setActionPreview(null));
  }, [query.length, dispatch]);

  const isDisplaySwitcher =
    !props.isHideSwitcher &&
    !query.length &&
    availableSnippets.length > 0 &&
    !isSnippetEditor;

  if (
    (query.length > 0 &&
      !visibleCommandsBySearch.length &&
      query.includes(' ')) ||
    (!props.items.length && !snippetCommands.length)
  ) {
    return null;
  }

  return (
    <div
      ref={menuListRef}
      className="overflow-hidden border border-gray-200 rounded-lg shadow-lg max-h-100 min-w-80 bg-base-white"
    >
      {isDisplaySwitcher && (
        <div className="sticky top-0 w-full pb-1 bg-base-white">
          <div className="w-[318px] bg-base-white rounded-lg">
            <div className="px-2 pt-3">
              <Tooltip
                className="px-3 py-2"
                hideArrow
                trigger={
                  <div className="flex items-center p-1 gap-0.5 bg-base-black-8 rounded-xl">
                    {menuOptions.map((option) => (
                      <MenuSwitcherRadioButton
                        key={option.value}
                        iconGlyph={option.iconGlyph}
                        name={option.name}
                        value={option.value}
                        onChange={() => {
                          setSwitcherOption(option.value);
                        }}
                        checked={option.value === switcherOption}
                      />
                    ))}
                  </div>
                }
              >
                <p className="text-xs font-semibold text-center text-base-white">
                  Use{' '}
                  <Icon
                    glyph={IconMap.ArrowsLeftRight}
                    className="inline text-base-white-70"
                  />{' '}
                  to switch
                </p>
                <p className="text-xs font-semibold text-center text-base-white">
                  with keyboard
                </p>
              </Tooltip>
            </div>
          </div>
        </div>
      )}

      {!query.length && (
        <div className="overflow-auto max-h-85">
          {switcherOption === MenuSwitcherOptions.COMPONENTS &&
            props.items.map((group, groupIndex: number) => (
              <div
                className="px-3 pb-2 first:pt-3"
                key={`${groupIndex}-wrapper`}
              >
                {group.label && (
                  <div className="px-2 text-xs font-bold text-gray-500 uppercase">
                    {group.label}
                  </div>
                )}
                <div>
                  {group.items.map((command: Command, commandIndex: number) => (
                    <MenuButton
                      isArrowPriority={isArrowPriority}
                      onMouseEnter={handleMouseEnter}
                      key={command.key}
                      className={`command-${command.key}`}
                      command={command}
                      active={
                        groupIndex === selectedGroupIndex &&
                        commandIndex === selectedCommandIndex
                      }
                      onMouseLeave={handleMouseLeave}
                      onClick={() => {
                        selectItem(groupIndex, commandIndex);
                      }}
                    />
                  ))}
                </div>
              </div>
            ))}

          {switcherOption === MenuSwitcherOptions.SNIPPETS && (
            <div className="p-3">
              {snippetCommands.map((group, groupIndex) => (
                <div className="first:mb-2" key={`${groupIndex}-snippets`}>
                  {group.label && (
                    <div className="px-1 py-1 text-xs font-bold text-gray-500 uppercase">
                      {group.label}
                    </div>
                  )}
                  {group.categories.map((category, categoryIndex) => (
                    <div key={`${categoryIndex}-category`}>
                      {category.groupLabel && (
                        <span className="inline-block p-1 text-xs font-medium text-gray-700 truncate max-w-74">
                          {category.groupLabel}
                        </span>
                      )}
                      {category.items.map((item, itemIndex) => (
                        <MenuButton
                          isArrowPriority={isArrowPriority}
                          onMouseEnter={handleMouseEnter}
                          key={item.key}
                          className={`command-${item.key}`}
                          command={item}
                          onClick={() => {
                            selectSnippetItem(
                              groupIndex,
                              categoryIndex,
                              itemIndex
                            );
                          }}
                          onMouseLeave={handleMouseLeave}
                          active={
                            selectedGroupIndex === groupIndex &&
                            selectedCommandIndex === itemIndex &&
                            selectedTeamCommandIndex === categoryIndex
                          }
                        />
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {query.length > 0 &&
        (visibleCommandsBySearch.length > 0
          ? visibleCommandsBySearch.map((group, groupIndex: number) => (
              <div
                className={cn('px-3 pb-2 first:pt-3', {
                  'border-t pt-3': group.label === 'Snippets',
                })}
                key={`${groupIndex}-wrapper`}
              >
                {group.label && (
                  <div className="px-2 text-xs font-bold text-gray-500 uppercase">
                    {group.label}
                  </div>
                )}
                <div>
                  {group.items.map((command: Command, commandIndex: number) => (
                    <MenuButton
                      isArrowPriority={isArrowPriority}
                      onMouseEnter={handleMouseEnter}
                      key={command.key}
                      className={`command-${command.key}`}
                      command={command}
                      active={
                        groupIndex === selectedGroupIndex &&
                        commandIndex === selectedCommandIndex
                      }
                      onMouseLeave={handleMouseLeave}
                      onClick={() => {
                        selectItem(groupIndex, commandIndex);
                      }}
                    />
                  ))}
                </div>
              </div>
            ))
          : !query.includes(' ') && (
              <p className="p-3 text-sm font-medium">No results</p>
            ))}
    </div>
  );
});
