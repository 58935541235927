import { JSONContent } from '@tiptap/core';

// Define a constant for marking empty nodes.
const EMPTY = '\uE001';

/**
 * Recursively traverse the ProseMirror document and mark nodes as empty.
 * A node is considered empty if:
 *   1) It does not have a non-empty "content" array, and
 *   2) It is not a text node with non-empty text.
 *
 * @param {JSONContent} doc - The ProseMirror JSON document.
 * @returns {JSONContent} The document with empty nodes marked.
 */

function markEmptyNodes(doc: JSONContent): JSONContent {
  function traverse(node: JSONContent): void {
    // For text nodes with non-empty text, consider them non-empty.
    if (node.type === 'text' && node.text && node.text !== '') {
      return;
    }
    // If the node does not have a non-empty content array, mark it.
    if (
      !node.content ||
      (Array.isArray(node.content) && node.content.length === 0)
    ) {
      node[EMPTY] = true;
    } else {
      // Otherwise, recursively check each child.
      for (const child of node.content) {
        traverse(child);
      }
    }
  }
  traverse(doc);
  return doc;
}

export { markEmptyNodes, EMPTY };
