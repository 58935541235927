import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconMap } from '../../../shared/sprite';
import { ButtonSquare, Icon } from '../../../shared/ui';

import TextareaAutosize from 'react-textarea-autosize';

import {
  getContentItems,
  useCollaboration,
} from '../../../entities/collaboration';
import { generateXmlJSX } from '@distribute/shared/generate-html';
import { chatModel } from '../model';
import { pagesModel } from '../../pages';
import { authUserModel } from '../../../entities/auth-user';
import { subscriptionModel } from '../../subscription';
import { teamsModel } from '../../teams';
import { useSubscriptionLimits } from '../../subscription/hooks';
import { checkAIExceed } from '../../subscription/lib';

const maxLength = 2000;

export const ChatBotInput: FC = () => {
  const isFocusInput = useSelector(chatModel.selectors.selectIsFocusInput);

  const { provider, title } = useCollaboration();
  const [value, setValue] = useState('');

  const dispatch = useDispatch();

  const length = value.trim().length;

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );
  const currentTabId = useSelector(
    pagesModel.selectors.selectCurrentContentIdWithError
  );

  const { maxAIMonthlyResponsesCount } = useSubscriptionLimits();
  const { aiMonthlyResponsesCount: count } = useSelector(
    teamsModel.selectors.selectUserTeamMemberDataWithError
  );

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const handleSubmit = () => {
    if (checkAIExceed(maxAIMonthlyResponsesCount, count)) {
      dispatch(
        subscriptionModel.actions.togglePaywall({ isShow: true, source: 'ai' })
      );
      return;
    }

    if (!provider) return;

    const tabs = getContentItems(provider);

    const pageContent = generateXmlJSX({
      pageTitle: title,
      tabs,
      isBranded: currentPage.isBranded,
      currentTabId,
      userName: currentUser.displayName,
    });

    dispatch(
      chatModel.actions.generateMessage({
        prompt: value.trim(),
        pageContent,
        tabs: tabs.reduce((acc, value) => ({ ...acc, [value.id]: value }), {}),
      })
    );

    setValue('');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };
  useEffect(() => {
    dispatch(chatModel.actions.setIsFocusInput(false));
  }, [dispatch]);

  return (
    <div className="relative flex flex-col">
      <TextareaAutosize
        autoFocus={isFocusInput}
        maxLength={maxLength}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Ask anything..."
        className="p-3 pt-3 px-3 pb-14 border rounded-xl resize-none placeholder:text-gray-500 text-gray-900 focus:ring-0 focus:border-primary-600 focus:outline-none text-ellipsis disabled:bg-gray-50 disabled:text-gray-500 min-h-23 max-h-100"
      />
      <div className="absolute bottom-3 right-3 flex items-center gap-x-3 text-xs text-gray-600">
        <span className="text-xs">
          {length}/{maxLength}
        </span>
        <ButtonSquare
          variant="primary"
          className="w-8 h-8 rounded-lg"
          disabled={length === 0}
          onClick={handleSubmit}
        >
          <Icon glyph={IconMap.ArrowRight} width={20} className="-rotate-90" />
        </ButtonSquare>
      </div>
    </div>
  );
};
