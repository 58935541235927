import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { getDateTime } from '../lib';
import { Toggle, Icon, Tooltip } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { callRecordingsModel } from '../model';
import { callRecordingSettingsModel } from '../../call-recording-settings';
import { GetMeetingEventResponse } from '@distribute/shared/api-types/call-recorder';
import { MeetingCalendarEvent } from '@distribute/shared/types';
import { MeetingPlatforms } from '../config';
import { MeetingEventType } from '@distribute/shared/types';
import { MeetingEventCardUsers } from './MeetingEventCardUsers';
import {
  checkIsPrivateEvent,
  checkBotShouldRecord,
} from '@distribute/shared/utils';

type Props = {
  event: GetMeetingEventResponse;
};

export const MeetingEventCard: FC<Props> = ({ event }) => {
  const { name, startTime, endTime, meetingPlatform, type } = event;

  const dispatch = useDispatch();
  const progressIds = useSelector(
    callRecordingsModel.selectors.selectProgressMeetingEventIds
  );
  const teamSettings = useSelector(
    callRecordingSettingsModel.selectors
      .selectTeamCallRecordingSettingsWithError
  );
  const userSettings = useSelector(
    callRecordingSettingsModel.selectors
      .selectUserCallRecordingSettingsWithError
  );

  const [isEventPrivate, setIsEventPrivate] = useState(() =>
    checkIsPrivateEvent({
      event: event as MeetingCalendarEvent,
      teamSettings,
    })
  );
  const [shouldRecordEvent, setShouldRecordEvent] = useState(() =>
    checkBotShouldRecord({
      event: event as MeetingCalendarEvent,
      userSettings,
    })
  );

  const toggleEventRecording = () => {
    const newValue = !shouldRecordEvent;
    setShouldRecordEvent(newValue);
    dispatch(
      callRecordingsModel.actions.toggleMeetingEventRecording({
        eventId: event.id,
        type,
        cb: (err: unknown) => {
          if (err) {
            setShouldRecordEvent(!newValue);
          }
        },
      })
    );
  };

  const toggleEventVisibility = () => {
    const newValue = !isEventPrivate;
    setIsEventPrivate(!isEventPrivate);
    dispatch(
      callRecordingsModel.actions.toggleMeetingEventVisibility({
        eventId: event.id,
        type,
        cb: (err: unknown) => {
          if (err) {
            setIsEventPrivate(!newValue);
          }
        },
      })
    );
  };

  const platform =
    MeetingPlatforms[meetingPlatform ?? 'googleMeet'] ??
    MeetingPlatforms.googleMeet;
  const isProgress = progressIds.includes(event.id);
  const now = Date.now();
  const isLiveCall =
    new Date(startTime).getTime() <= now && new Date(endTime).getTime() >= now;

  const users =
    event.type === MeetingEventType.CALENDAR
      ? event.eventData.attendees
          .map((attendee) => attendee.email)
          .filter(Boolean)
      : [];

  return (
    <div className="group/event bg-gray-100 rounded-lg p-4">
      <header className="flex justify-between pb-3 items-start gap-1 text-gray-900">
        <div className="max-w-[73%] max-w">
          <h4 className="text-sm font-semibold truncate mb-1">{name}</h4>
          <p className="flex items-center gap-1.5 text-xs text-gray-500">
            {isLiveCall && (
              <span className="flex items-center gap-1.5 border-1.5 border-error-600 rounded-full text-error-600 px-1.25">
                <Icon glyph={IconMap.Dot} width={8} />
                <span className="text-error-700">Live</span>
              </span>
            )}
            {`${getDateTime(startTime)} - ${getDateTime(endTime)}`}
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <Tooltip
            hideArrow
            triggerClassNames="h-5 invisible group-hover/event:visible"
            trigger={
              <button
                disabled={isProgress}
                className="outline-none"
                onClick={toggleEventVisibility}
              >
                <Icon
                  glyph={isEventPrivate ? IconMap.Users02 : IconMap.Lock01}
                  width={20}
                />
              </button>
            }
          >
            {isEventPrivate ? 'Make public' : 'Make private'}
          </Tooltip>
          <Toggle
            checked={shouldRecordEvent}
            onChange={toggleEventRecording}
            disabled={isProgress}
          />
        </div>
      </header>
      <div
        className={cn('flex items-center gap-2 text-xs text-gray-500', {
          'justify-between': users.length > 1,
          'justify-end': users.length <= 1,
        })}
      >
        {users.length > 1 && <MeetingEventCardUsers users={users} />}
        <div className="flex items-center gap-2">
          <Icon glyph={platform.icon} width={20} />
          <span>{platform.name}</span>
        </div>
      </div>
    </div>
  );
};
