import React, { useState } from 'react';
import cn from 'classnames';
import { Modal, Button } from '../../../shared/ui';

const steps = [
  {
    title: 'Seamless Call Capture',
    description:
      'Effortlessly record all your client calls by simply connecting your calendar. Never miss a detail with automatic call recordings integrated into your workflow.',
    image: '',
    nextButton: 'Next',
    coverPath: '../../../assets/images/callRecordings/walkthrough-step-1.png',
  },
  {
    title: 'AI-Powered Call Summary',
    description:
      'Unlock valuable insights with AI-generated summaries of your recorded calls. Gain quick access to key points and actionable items.',
    nextButton: 'Next',
    coverPath: '../../../assets/images/callRecordings/walkthrough-step-2.png',
  },
  {
    title: 'Instant Follow-Up Magic',
    description:
      'Swiftly create personalized follow-up pages right after your calls. Keep the momentum going with tailored content for each client.',
    nextButton: 'Get Started',
    coverPath: '../../../assets/images/callRecordings/walkthrough-step-3.png',
  },
];

type WalkthroughPopupProps = {
  onClose: () => void;
};

export const WalkthroughPopup: React.FC<WalkthroughPopupProps> = ({
  onClose,
}) => {
  const [stepIndex, setStepIndex] = useState(0);
  const currentStep = steps[stepIndex];
  const isFirstStep = stepIndex === 0;

  const handleBackClick = () => {
    setStepIndex((prev) => prev - 1);
  };
  const handleNextClick = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
    } else {
      onClose();
    }
  };
  const handleClose = () => onClose();

  return (
    <Modal
      isOpen
      isActionButtonsAlignEnd
      className="w-140 flex flex-col gap-5"
      onClose={handleClose}
      isShowCancelButton={false}
    >
      <div className="text-center">
        <img src={currentStep.coverPath} alt={currentStep.title} />
        <h1 className="text-xl text-gray-900 font-semibold">
          {currentStep.title}
        </h1>
        <p className="text-sm text-gray-600">{currentStep.description}</p>
      </div>
      <footer className="grid grid-cols-3">
        {!isFirstStep && (
          <Button
            className="w-18 justify-self-start"
            color="secondary"
            variant="text"
            type="button"
            onClick={handleBackClick}
          >
            Back
          </Button>
        )}
        <div className="flex justify-center items-center col-start-2 justify-self-center">
          {steps.map((_, index) => (
            <span
              key={index}
              className={cn('w-2 h-2 mx-0.75 rounded-full', {
                'bg-primary-600': index === stepIndex,
                'bg-gray-300': index !== stepIndex,
              })}
            ></span>
          ))}
        </div>
        <Button
          className="w-fit-content justify-self-end"
          color="primary"
          variant="text"
          type="button"
          onClick={handleNextClick}
        >
          {currentStep.nextButton}
        </Button>
      </footer>
    </Modal>
  );
};
