import { useDispatch, useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../model';
import { callModel } from '../../../../features/call';
import { useFormContext } from 'react-hook-form';
import { CreatePageFormSchemaType } from '../../config/types';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { useEffect, useMemo } from 'react';

export function useFillPreselectedDataIntoForm() {
  const dispatch = useDispatch();
  const { setValue } = useFormContext<CreatePageFormSchemaType>();

  const preselectedCallId = useSelector(
    pageCreationFlowModel.selectors.selectPreselectedCallId
  );

  const groupedCalls = useSelector(callModel.selectors.selectGroupedCalls);
  const allCalls = useMemo(
    () => [...groupedCalls.personal, ...groupedCalls.team],
    [groupedCalls.personal, groupedCalls.team]
  );

  useEffect(() => {
    if (preselectedCallId && allCalls.length) {
      const call = allCalls.find((el) => el.id === preselectedCallId);

      if (!call) {
        dispatch(pageCreationFlowModel.actions.setPreselectedCallId(null));
        dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', 'Failed to find call recording')
          )
        );
        return;
      }

      setValue('selectedCallRecordings', [call]);
      dispatch(pageCreationFlowModel.actions.setPreselectedCallId(null));
    }
  }, [allCalls, dispatch, preselectedCallId, setValue]);
}
