import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Input } from '../../../../../../shared/ui';
import { ActionTypeAlertBar } from '@distribute/shared/types';
import { conversionKitModel } from '../../../../../conversion-kit';
import { IconMap } from '../../../../../../shared/sprite';
import { object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RichTextEditor } from '../../../../../../entities/tiptap-editor';
import { LINK_VERIFICATION_REGEX } from '../../../../../../shared/constants';
import { useCollaboration } from '../../../../../../entities/collaboration';
import { editorLeftSidebarModel } from '../../../../model';
import { SidebarTabLayout } from '../../shared/SidebarTabLayout';
import { TabsSwitcher } from '../../shared';
import { ALERT_BAR_TABS } from '../config';
import { Label } from '../shared';

const DESCRIPTION_MAX_LENGTH = 170;
const BUTTON_LABEL_MAX_LENGTH = 40;

type AlertBarForm = {
  buttonLabelLinkType: string;
  buttonLabelPopupType: string;
  buttonUrl: string;
};

export const AlertBarPanel: React.FC = () => {
  const dispatch = useDispatch();

  const updateAlertBarIsLoading = useSelector(
    conversionKitModel.selectors.selectUpdateAlertBarIsLoading
  );

  const handleClose = () => {
    dispatch(editorLeftSidebarModel.actions.setActiveConversionPanel(null));
    dispatch(conversionKitModel.actions.setAlertBarEdited(undefined));
  };

  const { alertBarData, updateAlertBarField, updatePopupField } =
    useCollaboration();

  const {
    isActive,
    buttonLabelLinkType,
    buttonLabelPopupType,
    actionType,
    buttonUrl,
    description,
  } = alertBarData;

  const validationSchema = useMemo(
    () =>
      object().shape({
        buttonLabelPopupType: string().when([], {
          is: () => actionType === ActionTypeAlertBar.POPUP,
          then: string().max(BUTTON_LABEL_MAX_LENGTH),
          otherwise: string().nullable(),
        }),
        buttonLabelLinkType: string().when([], {
          is: () => actionType === ActionTypeAlertBar.LINK,
          then: string().max(BUTTON_LABEL_MAX_LENGTH),
          otherwise: string().nullable(),
        }),
        buttonUrl: string().when([], {
          is: () => actionType === ActionTypeAlertBar.LINK,
          then: string().matches(LINK_VERIFICATION_REGEX).required(),
          otherwise: string().nullable(),
        }),
      }),
    [actionType]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
  } = useForm<AlertBarForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({
        buttonLabelLinkType: buttonLabelLinkType,
        buttonLabelPopupType: buttonLabelPopupType,
        buttonUrl: buttonUrl,
      }),
      [buttonLabelLinkType, buttonLabelPopupType, buttonUrl]
    ),
  });

  const descriptionEditorRef = useRef<ElementRef<typeof RichTextEditor>>(null);
  const [isDescriptionError, setIsDescriptionError] = useState<boolean>(false);

  const {
    field: { onChange: changeButtonLabelLinkType },
  } = useController({
    control,
    name: 'buttonLabelLinkType',
  });

  const {
    field: { onChange: changeButtonLabelPopupType },
  } = useController({
    control,
    name: 'buttonLabelPopupType',
  });

  const {
    field: { onChange: changeButtonUrl },
  } = useController({
    control,
    name: 'buttonUrl',
  });

  const handleButtonLabelChange = (value: string) => {
    updateAlertBarField('buttonLabelLinkType', value);
    updateAlertBarField('buttonLabelPopupType', value);
    changeButtonLabelLinkType(value);
    changeButtonLabelPopupType(value);
  };

  const handleButtonUrlChange = (value: string) => {
    updateAlertBarField('buttonUrl', value);
    changeButtonUrl(value);
  };

  useEffect(() => {
    if (description) {
      setIsDescriptionError(false);
    }
  }, [description]);

  const onChangeTab = (tab: ActionTypeAlertBar) => {
    clearErrors();
    updateAlertBarField('actionType', tab);
  };

  const handleFormSubmit = () => {
    if (!description) {
      setIsDescriptionError(true);
      descriptionEditorRef.current?.scrollIntoViewAndFocus();
      return;
    }
    updateAlertBarField('buttonLabelLinkType', buttonLabelLinkType || 'Submit');
    updateAlertBarField(
      'buttonLabelPopupType',
      buttonLabelPopupType || 'Submit'
    );
    updateAlertBarField('isActive', true);
    if (isActive && actionType === ActionTypeAlertBar.POPUP) {
      updatePopupField('isActive', true);
    }
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full h-full">
      <SidebarTabLayout
        headerComponent={
          <div>
            <div className="flex justify-between w-full mb-4">
              <Button color="secondary" variant="icon" onClick={handleClose}>
                <Icon glyph={IconMap.ArrowLeft} width={20} />
              </Button>

              <Button
                variant="text"
                color="primary"
                type="submit"
                loading={updateAlertBarIsLoading}
              >
                Save
              </Button>
            </div>
            <h2 className="mb-4 text-2xl font-semibold text-gray-900">
              Alert Bar
            </h2>
            <TabsSwitcher
              tabs={ALERT_BAR_TABS}
              selectedTab={actionType}
              setSelectedTab={(value) =>
                onChangeTab(value as ActionTypeAlertBar)
              }
            />
          </div>
        }
      >
        <div>
          <div>
            <p className="mb-4 font-semibold text-gray-800">Components</p>

            <Label
              icon={IconMap.Type01}
              text="Bar Text"
              isRequired
              className="mb-3"
            />
            <RichTextEditor
              ref={descriptionEditorRef}
              content={description}
              placeholder="Add text…"
              onUpdate={(content) =>
                updateAlertBarField('description', content)
              }
              maxLength={DESCRIPTION_MAX_LENGTH}
              isError={isDescriptionError}
            />

            <hr className="my-6 border-gray-200" />
            <p className="mb-4 font-semibold text-gray-800">Button</p>
            {actionType === ActionTypeAlertBar.LINK && (
              <>
                <Label
                  icon={IconMap.TypeSquare}
                  text="Label"
                  className="mb-3"
                />
                <Input
                  {...register('buttonLabelLinkType')}
                  className="p-3"
                  type="text"
                  placeholder="Submit"
                  maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                  isError={!!errors.buttonLabelLinkType}
                  value={buttonLabelLinkType}
                  onChange={({ target: { value } }) =>
                    handleButtonLabelChange(value)
                  }
                />

                <Label
                  icon={IconMap.Link}
                  text="URL"
                  isRequired
                  className="mt-6 mb-3"
                />
                <Input
                  {...register('buttonUrl')}
                  className="p-3"
                  type="text"
                  placeholder="e.g. https://example.com/link"
                  isError={!!errors.buttonUrl}
                  value={buttonUrl}
                  onChange={({ target: { value } }) =>
                    handleButtonUrlChange(value)
                  }
                />
              </>
            )}

            {actionType === ActionTypeAlertBar.POPUP && (
              <>
                <Label
                  icon={IconMap.TypeSquare}
                  text="Label"
                  className="my-3"
                />
                <Input
                  {...register('buttonLabelPopupType')}
                  className="p-3"
                  type="text"
                  placeholder="Submit"
                  maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                  isError={!!errors.buttonLabelPopupType}
                  value={buttonLabelPopupType}
                  onChange={({ target: { value } }) =>
                    handleButtonLabelChange(value)
                  }
                />
              </>
            )}
          </div>
        </div>
      </SidebarTabLayout>
    </form>
  );
};
