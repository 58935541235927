import React, { useCallback, useMemo } from 'react';
import { BubbleMenu } from '@tiptap/react';
import { Node } from '@tiptap/pm/model';
import { Rect } from '@popperjs/core';
import { Editor } from '@tiptap/core';

import {
  BubbleMenuIconButton,
  BubbleMenuToolbar,
} from '../../../../entities/tiptap-editor';
import { IconMap } from '../../../../shared/sprite';
import EmojiPicker, {
  EmojiClickData,
  EmojiStyle,
  SkinTones,
  Theme,
} from 'emoji-picker-react';
import {
  RichTextEditorDropdownItemType,
  RichTextEditorDropdownWithTooltip,
} from './rich-text-editor-dropdown';
import { categories } from '../../../../features/icon-picker';
import { ColorListPicker } from '../../../../shared/ui/color-list-picker';
import { DEFAULT_EDITOR_CALLOUT_COLORS_CONFIG } from '../../extensions/LineActionMenu/config/colors';
import { getParentWithScroll } from '../../../../shared/lib/getParentWithScroll';
import { useTiptapEditor } from '../../lib';
import { LEFT_SIDEBAR_ID } from '../../../../features/editor-left-sidebar/ui/components/lib';

type OffsetProps = {
  popper: Rect;
  reference: Rect;
  placement: string;
};

function getCalloutNode(editor: Editor) {
  const { from, to } = editor.state.selection;
  let node = null;
  let position = null;

  editor.state.doc.nodesBetween(from, to, (n, pos) => {
    if (n.type.name === 'callout') {
      node = n;
      position = pos;
    }
  });

  return { node, position };
}

export const TiptapCalloutBubbleMenu = () => {
  const { editor } = useTiptapEditor();
  const selection = editor?.state.selection;
  const isCalloutActive = editor?.isActive('callout');
  const node = useMemo((): Node | null => {
    if (!editor || !isCalloutActive) return null;
    return getCalloutNode(editor).node;
  }, [editor, isCalloutActive, selection]);
  const handleEmojiChange = useCallback(
    (emoji: EmojiClickData) => {
      editor
        ?.chain()
        .focus()
        .updateAttributes('callout', {
          emoji: emoji.emoji,
        })
        .run();
    },
    [editor]
  );

  // Click on input should prevent close dropdown
  const handleEmojiInputClick = (event: React.PointerEvent<HTMLDivElement>) => {
    if ((event.target as HTMLElement).tagName === 'INPUT')
      event.stopPropagation();
  };

  const calculateOffset = useCallback(
    ({ popper, reference }: OffsetProps): [number, number] => {
      if (!editor) return [0, 0];
      const position = getCalloutNode(editor).position ?? 0;

      const domNode = editor.view.nodeDOM(position);
      const rect = (domNode as HTMLElement)?.getBoundingClientRect();
      const leftSidebar = document.getElementById(LEFT_SIDEBAR_ID);

      // Calculate the center of the block
      const blockCenter = rect.width / 2;

      // Get the width of the BubbleMenu
      const bubbleMenuWidthCenter = popper.width / 2;

      // Calculate the offset to place the BubbleMenu in the center of the block
      const offsetCenter = blockCenter - bubbleMenuWidthCenter;
      const offset =
        rect.left +
        offsetCenter -
        reference.x -
        (leftSidebar?.clientWidth ?? 0);

      const scrolledParent = getParentWithScroll(
        editor.view.dom as HTMLElement
      );
      const scrollTop = scrolledParent?.scrollTop ?? 0;
      const offsetTop =
        reference.y - (rect.top + scrollTop - popper.height - 10);

      return [offset, offsetTop];
    },
    [editor]
  );

  const iconItems: RichTextEditorDropdownItemType[] = useMemo(() => {
    return [
      {
        id: '1',
        value: (
          <div onClick={handleEmojiInputClick}>
            <EmojiPicker
              lazyLoadEmojis
              theme={Theme.LIGHT}
              skinTonesDisabled
              previewConfig={{
                showPreview: false,
              }}
              defaultSkinTone={SkinTones.NEUTRAL}
              emojiVersion="2.0"
              emojiStyle={EmojiStyle.APPLE}
              onEmojiClick={handleEmojiChange}
              width={336}
              height={336}
              categories={categories}
            />
          </div>
        ),
        onClick: () => null,
      },
    ];
  }, [handleEmojiChange]);
  const bgItems: RichTextEditorDropdownItemType[] = useMemo(() => {
    return [
      {
        id: '1',
        value: (
          <ColorListPicker
            currentColor={node?.attrs.bgColor}
            colorList={DEFAULT_EDITOR_CALLOUT_COLORS_CONFIG}
            onSetColor={(color) => {
              editor
                ?.chain()
                .focus()
                .updateAttributes('callout', {
                  bgColor: color,
                })
                .run();
            }}
            onReset={() => {
              editor
                ?.chain()
                .focus()
                .updateAttributes('callout', {
                  bgColor: DEFAULT_EDITOR_CALLOUT_COLORS_CONFIG[0].value,
                })
                .run();
            }}
          />
        ),
        onClick: () => null,
      },
    ];
  }, [node, editor]);

  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu
      tippyOptions={{
        duration: 200,
        placement: 'top-start',
        maxWidth: 'none',
        offset: calculateOffset,
      }}
      editor={editor}
      shouldShow={() =>
        editor.isEditable &&
        editor.isActive('callout') &&
        editor.state.selection.empty
      }
      updateDelay={0}
    >
      <BubbleMenuToolbar>
        <RichTextEditorDropdownWithTooltip
          tooltip="Icon"
          isShowIconForSelectedItem
          items={iconItems}
          selectedItemId=""
          dropdownStyles="min-w-50 -left-2 !p-0"
          itemStyles="!p-0 -mx-1.5"
          triggerComponent={
            <span className="min-w-5 text-sm text-center">
              {node?.attrs.emoji}
            </span>
          }
        />
        <hr className="block w-px h-6 border-none bg-base-black-8 rounded mx-1"></hr>
        <RichTextEditorDropdownWithTooltip
          tooltip="Background color"
          isShowIconForSelectedItem
          items={bgItems}
          selectedItemId=""
          dropdownStyles="!p-4 !w-[178px]"
          itemStyles="!p-0 -mx-1.5 hover:!bg-base-white"
          triggerComponent={
            <span
              style={{ backgroundColor: node?.attrs.bgColor }}
              className="min-w-5 min-h-5 rounded-full cursor-pointer border border-base-black-8"
            ></span>
          }
        />
        <hr className="block w-px h-6 border-none bg-base-black-8 rounded mx-1"></hr>
        <BubbleMenuIconButton
          iconName={IconMap.Trash}
          tooltip="Delete callout"
          onClick={() => editor.commands.deleteNode('callout')}
        />
      </BubbleMenuToolbar>
    </BubbleMenu>
  );
};
