import { FC } from 'react';
import { Tab } from './TabsSwitcher';
import { cn } from '@distribute/frontend/utils';
import { Icon } from '../../../../../../../src/shared/ui';

type Props = {
  tab: Tab;
  handleClick: () => void;
  isSelected?: boolean;
};
export const TabButton: FC<Props> = ({ tab, handleClick, isSelected }) => {
  return (
    <button
      className={cn(
        `flex gap-1.5 p-1.5 rounded-md justify-center items-start text-sm w-full`,
        {
          'bg-base-white shadow-sm text-gray-700': isSelected,
          'text-gray-500': !isSelected,
          'hover:bg-base-white hover:shadow-sm hover:text-gray-700':
            !tab.disabled,
        }
      )}
      onClick={handleClick}
      disabled={tab.disabled}
      type="button"
    >
      {tab.icon && <Icon glyph={tab.icon} width={20} />}
      <span>{tab.name}</span>
    </button>
  );
};
