import React from 'react';
import {
  EmailMismatchModal,
  TeamSettingsForm,
  UserSettingsForm,
} from './components';

export const CallRecordingSettings = () => {
  return (
    <div className="pb-14.5">
      <h1 className="text-display-sm font-display font-medium text-gray-900 mb-6 md:hidden">
        Call Recording Settings
      </h1>
      <div>
        <TeamSettingsForm />
      </div>
      <div>
        <UserSettingsForm />
      </div>
      <EmailMismatchModal />
    </div>
  );
};
