import React, { useCallback, useMemo } from 'react';
import { BubbleMenu } from '@tiptap/react';
import { Node } from '@tiptap/pm/model';
import { Rect } from '@popperjs/core';
import { Editor } from '@tiptap/core';
import Calendar from 'react-calendar';
import './Calendar.css';
import {
  BubbleMenuIconButton,
  BubbleMenuToolbar,
} from '../../../../entities/tiptap-editor';
import { IconMap } from '../../../../shared/sprite';
import EmojiPicker, {
  EmojiClickData,
  EmojiStyle,
  SkinTones,
  Theme,
} from 'emoji-picker-react';
import {
  RichTextEditorDropdownItemType,
  RichTextEditorDropdownWithTooltip,
} from './rich-text-editor-dropdown';
import { categories } from '../../../../features/icon-picker';
import { getParentWithScroll } from '../../../../shared/lib/getParentWithScroll';
import { useTiptapEditor } from '../../lib';
import { Button, Icon, Toggle, Tooltip } from '../../../../shared/ui';
import { LEFT_SIDEBAR_ID } from '../../../../features/editor-left-sidebar/ui/components/lib';

type OffsetProps = {
  popper: Rect;
  reference: Rect;
  placement: string;
};

function getTimelineItemNode(editor: Editor) {
  const { from, to } = editor.state.selection;
  let node = null;
  let position = null;

  editor.state.doc.nodesBetween(from, to, (n, pos) => {
    if (n.type.name === 'timelineItem') {
      node = n;
      position = pos;
    }
  });

  return { node, position };
}

export const TiptapTimelineBubbleMenu = () => {
  const { editor } = useTiptapEditor();
  const selection = editor?.state.selection;
  const isTimelineItemAlive = editor?.isActive('timelineItem');
  const node = useMemo((): Node | null => {
    if (!editor || !isTimelineItemAlive) return null;
    return getTimelineItemNode(editor).node;
  }, [editor, isTimelineItemAlive, selection]);

  const handleEmojiChange = useCallback(
    (emoji: EmojiClickData) => {
      editor
        ?.chain()
        .focus()
        .updateAttributes(
          'timelineItem',
          node?.attrs.isDone
            ? {
                readyIcon: emoji.emoji,
              }
            : {
                progressIcon: emoji.emoji,
              }
        )
        .run();
    },
    [editor, node?.attrs.isDone]
  );

  const handleIsDoneChange = useCallback(
    (value: boolean) => {
      editor
        ?.chain()
        .focus()
        .updateAttributes('timelineItem', { isDone: value })
        .run();
    },
    [editor]
  );

  const handleDueDateChange = useCallback(
    (value: Date | null | (Date | null)[]) => {
      editor
        ?.chain()
        .focus()
        .updateAttributes('timelineItem', {
          dueDate: value?.toString() ?? '',
        })
        .run();
    },
    [editor]
  );

  const handleClearDate = useCallback(() => {
    editor
      ?.chain()
      .focus()
      .updateAttributes('timelineItem', {
        dueDate: '',
      })
      .run();
  }, [editor]);

  // Click on input should prevent close dropdown
  const handleEmojiInputClick = (event: React.PointerEvent<HTMLDivElement>) => {
    if ((event.target as HTMLElement).tagName === 'INPUT')
      event.stopPropagation();
  };

  const calculateOffset = useCallback(
    ({ popper, reference }: OffsetProps): [number, number] => {
      if (!editor) return [0, 0];
      const position = getTimelineItemNode(editor).position ?? 0;

      const domNode = editor.view.nodeDOM(position);
      const rect = (domNode as HTMLElement)?.getBoundingClientRect();
      const leftSidebar = document.getElementById(LEFT_SIDEBAR_ID);

      // Calculate the center of the block
      const blockCenter = rect.width / 2;

      // Get the width of the BubbleMenu
      const bubbleMenuWidthCenter = popper.width / 2;

      // Calculate the offset to place the BubbleMenu in the center of the block
      const offsetCenter = blockCenter - bubbleMenuWidthCenter;
      const offset =
        rect.left +
        offsetCenter -
        reference.x -
        (leftSidebar?.clientWidth ?? 0);

      const scrolledParent = getParentWithScroll(
        editor.view.dom as HTMLElement
      );
      const scrollTop = scrolledParent?.scrollTop ?? 0;
      const offsetTop =
        reference.y - (rect.top + scrollTop - popper.height - 10);

      return [offset, offsetTop];
    },
    [editor]
  );

  const iconItems: RichTextEditorDropdownItemType[] = useMemo(() => {
    return [
      {
        id: '1',
        value: (
          <div onClick={handleEmojiInputClick}>
            <EmojiPicker
              lazyLoadEmojis
              theme={Theme.LIGHT}
              skinTonesDisabled
              previewConfig={{
                showPreview: false,
              }}
              defaultSkinTone={SkinTones.NEUTRAL}
              emojiVersion="2.0"
              emojiStyle={EmojiStyle.APPLE}
              onEmojiClick={handleEmojiChange}
              width={336}
              height={336}
              categories={categories}
            />
          </div>
        ),
        onClick: () => null,
      },
    ];
  }, [handleEmojiChange]);

  const currentDueDate = node?.attrs.dueDate ?? new Date();

  const dateItems: RichTextEditorDropdownItemType[] = useMemo(() => {
    return [
      {
        id: '2',
        value: (
          <div
            className="px-6 pt-5 pb-4 w-82 bg-base-white rounded-xl"
            onClick={handleEmojiInputClick}
          >
            <Calendar
              allowPartialRange={false}
              value={currentDueDate}
              onChange={handleDueDateChange}
            />
            <div className="mt-5 shadow-xs">
              <Button
                fullWidth
                variant="text"
                onClick={handleClearDate}
                color="secondary"
                disabled={!node?.attrs.dueDate}
              >
                Clear date
              </Button>
            </div>
          </div>
        ),
        onClick: () => null,
      },
    ];
  }, [handleEmojiChange, currentDueDate]);

  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu
      tippyOptions={{
        duration: 200,
        placement: 'top-start',
        maxWidth: 'none',
        offset: calculateOffset,
      }}
      editor={editor}
      shouldShow={() =>
        editor.isEditable &&
        editor.isActive('timelineItem') &&
        editor.state.selection.empty
      }
      updateDelay={0}
    >
      <BubbleMenuToolbar>
        <Tooltip
          trigger={
            <div className="flex items-center gap-1 p-1 rounded-md hover:bg-base-black-4">
              <span className="text-sm font-medium text-gray-700">Done</span>
              <Toggle
                checked={node?.attrs.isDone}
                onChange={handleIsDoneChange}
              />
            </div>
          }
        >
          Change status
        </Tooltip>

        <hr className="block w-px h-6 border-none bg-base-black-8 rounded mr-1 ml-0.5"></hr>

        <RichTextEditorDropdownWithTooltip
          tooltip="Swap icon"
          isShowIconForSelectedItem
          items={iconItems}
          selectedItemId=""
          dropdownStyles="min-w-50 -left-2 !p-0"
          itemStyles="!p-0 -mx-1.5"
          triggerComponent={
            <span className="text-xl leading-5 text-center min-w-5">
              {node?.attrs.isDone
                ? node?.attrs.readyIcon
                : node?.attrs.progressIcon}
            </span>
          }
        />
        <hr className="block w-px h-6 mx-1 border-none rounded bg-base-black-8"></hr>

        <RichTextEditorDropdownWithTooltip
          isHideArrow
          tooltip="Due date"
          isShowIconForSelectedItem
          items={dateItems}
          selectedItemId=""
          dropdownStyles="min-w-50 -left-2 !p-0"
          itemStyles="!p-0 -mx-1.5"
          triggerComponent={<Icon glyph={IconMap.Calendar} width={20} />}
          isPreventClose
        />
        <hr className="block w-px h-6 mx-1 border-none rounded bg-base-black-8"></hr>

        <BubbleMenuIconButton
          iconName={IconMap.Trash}
          tooltip="Delete"
          onClick={() => editor.commands.deleteNode('timelineItem')}
        />
      </BubbleMenuToolbar>
    </BubbleMenu>
  );
};
