import { foldersModel } from '../../../features/folders';
import { pagesModel } from '../../../features/pages';

import { debounce, fork, takeEvery } from 'redux-saga/effects';
import { templatesModel } from '../../../features/templates';
import { workspaceModel } from '../../../features/workspace';
import { conversionKitModel } from '../../../features/conversion-kit';
import { gongModel } from '../../../entities/gong';
import { clearbitModel } from '../../../entities/clearbit';

export function* workspaceWorker() {
  yield takeEvery(
    foldersModel.actions.createFolder,
    foldersModel.sagas.createFolder
  );
  yield takeEvery(
    foldersModel.actions.reorderFolder,
    foldersModel.sagas.reorderFolder
  );
  yield takeEvery(
    foldersModel.actions.deleteFolder,
    foldersModel.sagas.deleteFolder
  );
  yield takeEvery(
    foldersModel.actions.renameFolder,
    foldersModel.sagas.renameFolder
  );
  yield takeEvery(
    foldersModel.actions.updateTeamMemberPermission,
    foldersModel.sagas.updateTeamMemberPermission
  );
  yield takeEvery(
    foldersModel.actions.createUserPermission,
    foldersModel.sagas.createUserPermission
  );
  yield takeEvery(
    foldersModel.actions.updateUserPermission,
    foldersModel.sagas.updateUserPermission
  );
  yield takeEvery(
    foldersModel.actions.deleteUserPermission,
    foldersModel.sagas.deleteUserPermission
  );
  yield takeEvery(pagesModel.actions.deletePage, pagesModel.sagas.deletePage);
  yield takeEvery(pagesModel.actions.renamePage, pagesModel.sagas.renamePage);
  yield takeEvery(
    pagesModel.actions.updateTeamMemberPermission,
    pagesModel.sagas.updateTeamMemberPermission
  );
  yield takeEvery(
    pagesModel.actions.changePagePublicAccess,
    pagesModel.sagas.changePagePublicAccess
  );
  yield takeEvery(
    pagesModel.actions.createUserPermission,
    pagesModel.sagas.createUserPermission
  );
  yield takeEvery(
    pagesModel.actions.updateUserPermission,
    pagesModel.sagas.updateUserPermission
  );
  yield takeEvery(
    pagesModel.actions.deleteUserPermission,
    pagesModel.sagas.deleteUserPermission
  );
  yield takeEvery(
    pagesModel.actions.resendUserPermissionInvitation,
    pagesModel.sagas.resendUserPermissionInvitation
  );
  yield takeEvery(
    pagesModel.actions.movePageToFolder,
    pagesModel.sagas.movePageToFolder
  );
  yield takeEvery(
    pagesModel.actions.duplicatePage,
    pagesModel.sagas.duplicatePage
  );
  yield takeEvery(
    pagesModel.actions.setCurrentPage,
    pagesModel.sagas.updatePages
  );
  yield takeEvery(
    templatesModel.actions.savePageAsTemplate,
    templatesModel.sagas.savePageAsTemplate
  );
  yield takeEvery(
    templatesModel.actions.deleteTemplate,
    templatesModel.sagas.deleteTemplate
  );
  yield takeEvery(
    templatesModel.actions.duplicateTemplate,
    templatesModel.sagas.duplicateTemplate
  );
  yield takeEvery(
    templatesModel.actions.updateTemplateFromSettings,
    templatesModel.sagas.updateTemplateFromSettings
  );
  yield takeEvery(
    templatesModel.actions.changeCoverImageUrl,
    templatesModel.sagas.changeTemplateCoverImage
  );
  yield takeEvery(
    templatesModel.actions.createBlankTemplate,
    templatesModel.sagas.createBlankTemplate
  );

  yield takeEvery(
    workspaceModel.actions.closeWalkthroughModal,
    workspaceModel.sagas.closeWalkthroughModal
  );

  yield takeEvery(
    workspaceModel.actions.closeMobileWalkthroughModal,
    workspaceModel.sagas.closeMobileWalkthroughModal
  );

  yield takeEvery(
    workspaceModel.actions.setConversionToolsData,
    workspaceModel.sagas.setConversionToolsData
  );

  yield fork(conversionKitModel.sagas.updateConversionsWorker);

  yield takeEvery(pagesModel.actions.updatePage, pagesModel.sagas.updatePage);

  yield takeEvery(
    pagesModel.actions.changePublicStatus,
    pagesModel.sagas.changePublicStatus
  );

  yield takeEvery(
    pagesModel.actions.requestToEditPage,
    pagesModel.sagas.requestToEditPage
  );

  yield takeEvery(
    gongModel.actions.connectToGong,
    gongModel.sagas.connectToGong
  );

  yield takeEvery(
    gongModel.actions.disconnectFromGong,
    gongModel.sagas.disconnectFromGong
  );

  yield takeEvery(
    gongModel.actions.getCallTranscripts,
    gongModel.sagas.getCallTranscripts
  );

  yield takeEvery(
    gongModel.actions.generateGongApiKey,
    gongModel.sagas.generateGongApiKey
  );

  yield takeEvery(
    gongModel.actions.createGongTeamSecrets,
    gongModel.sagas.createGongTeamSecrets
  );

  yield takeEvery(
    gongModel.actions.deleteGongTeamSecrets,
    gongModel.sagas.deleteGongTeamSecrets
  );

  yield takeEvery(
    [
      pagesModel.actions.createPageContentItem,
      pagesModel.actions.duplicatePageContentItem,
      pagesModel.actions.deletePageContentItem,
      pagesModel.actions.updatePageContentItem,
      pagesModel.actions.bulkUpdatePageContentItems,
    ],
    pagesModel.sagas.changePageContentItem
  );

  yield takeEvery(
    pagesModel.actions.setCurrentContentItem,
    pagesModel.sagas.setCurrentContentItem
  );

  yield debounce(
    400,
    clearbitModel.actions.searchCompanies,
    clearbitModel.sagas.searchCompanies
  );
}
