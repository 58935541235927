import React, { useState } from 'react';
import classNames from 'classnames';

import { formatInitials } from '@distribute/shared/utils';

export type Size = '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | '2xl';

type Props = {
  src?: string;
  displayName: string;
  size?: Size;
  theme?:
    | 'primary'
    | 'secondary-rect'
    | 'secondary-circle'
    | 'primary-rect'
    | 'rect';
  className?: string;
  isUserAvatar?: boolean;
  hideBorder?: boolean;
};
export const Avatar: React.FC<Props> = ({
  src,
  size = 'md',
  displayName,
  theme = 'primary',
  className,
  isUserAvatar = false,
  hideBorder,
}) => {
  const [isAvailable, setIsAvailable] = useState<boolean>(true);

  const initials = formatInitials(displayName, isUserAvatar);

  return (
    <span
      className={classNames(
        'flex-shrink-0 overflow-hidden flex items-center justify-center',
        {
          'w-4 h-4': size === '3xs',
          'w-5 h-5': size === '2xs',
          'w-6 h-6': size === 'xs',
          'w-9 h-9': size === 'sm',
          'w-10 h-10': size === 'md',
          'w-12 h-12': size === 'lg',
          'w-16 h-16': size === '2xl',
          'bg-gray-100 rounded-full': theme === 'primary',
          'bg-primary-700 rounded-xl': theme === 'secondary-rect',
          'rounded-lg border border-light-7 !text-gray-500 !font-semibold bg-gray-100':
            theme === 'rect',
          'bg-base-white border border-[rgba(0,0,0,0.04)] rounded-xl':
            theme === 'primary-rect',
          'bg-primary-700 rounded-full': theme === 'secondary-circle',
          '!bg-base-white border border-gray-300': src,
          'rounded-xl':
            size !== 'xs' &&
            (theme === 'primary-rect' || theme === 'secondary-rect'),
          '!rounded-[4px]':
            size === 'xs' &&
            (theme === 'primary-rect' || theme === 'secondary-rect'),
          'border-none': hideBorder,
        },
        className
      )}
    >
      {src && isAvailable ? (
        <img
          onError={() => {
            setIsAvailable(false);
          }}
          className="object-cover block w-full h-full"
          alt="User profile avatar"
          src={src}
        />
      ) : (
        <span
          className={classNames('text-center font-medium', {
            'text-xxs': size === '2xs' || size === '3xs',
            'text-xs': size === 'xs',
            'text-base': size === 'md',
            'text-display-xs': size === '2xl',
            'text-gray-600': theme === 'primary',
            'text-base-white':
              theme === 'secondary-rect' || theme === 'secondary-circle',
            'text-gray-500 font-semibold': theme === 'rect',
          })}
        >
          {initials}
        </span>
      )}
    </span>
  );
};
