import React, { CSSProperties, useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { useSetDocumentContentStyles } from '../../../../shared/hooks/useSetDocumentContentStyles';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { EditorContent, useEditor } from '@tiptap/react';
import { getMainEditorExtensions } from '../../../../entities/tiptap-editor/mainEditorExtensions';
import { FeatureFlags } from '../../../../entities/feature-flag';
import { EditorHtmlContent } from '../../../../pages/editor/ui/components/EditorHtmlContent';
import { environment } from '../../../../environments/environment';
import { EditorTabsPreview } from './EditorTabsPreview';

type Props = {
  template: TemplateExtended;
  className?: string;
  style?: CSSProperties;
  isFullPreview?: boolean;
};

export const TemplatePreviewRaw: React.FC<Props> = ({
  template,
  className,
  style,
  isFullPreview = false,
}) => {
  const { content } = template;
  const [currentTab, setCurrentTab] = useState(content.contentItems[0]);
  const data = currentTab?.contentJson;
  const htmlContent = currentTab?.htmlContent;

  const reviewRef = useRef<HTMLDivElement>(null);

  useSetDocumentContentStyles(content, reviewRef);

  const readonlyEditor = useEditor(
    {
      extensions: getMainEditorExtensions(),
      editorProps: {
        attributes: { class: 'main-editor main-editor-readonly' },
      },
      content: data,
      editable: false,
    },
    [content.id]
  );

  const isHtmlContentFlow = environment.featureFlags[FeatureFlags.HTML_CONTENT];

  useEffect(() => {
    setCurrentTab(content.contentItems[0]);
  }, [content.contentItems]);

  return (
    <div
      style={style}
      ref={reviewRef}
      className={cn('overflow-y-auto overflow-x-hidden', className, {
        'pt-12': !isFullPreview,
      })}
    >
      <div
        className={cn('m-auto h-full', {
          'w-212': !isFullPreview,
          'w-180 max1080:w-[630px] md:w-full': isFullPreview,
        })}
      >
        {isFullPreview && (
          <h1 className="text-display-lg font-semibold text-gray-900 mb-10">
            {content.title || 'Untitled'}
          </h1>
        )}

        {isFullPreview && !template.isSinglePage ? (
          <EditorTabsPreview
            tabs={content.contentItems}
            currentTab={currentTab}
            onChangeTab={(tab) => setCurrentTab(tab)}
          />
        ) : null}

        <div
          className={cn({
            'pb-12': !isFullPreview,
          })}
        >
          {isHtmlContentFlow ? (
            <EditorHtmlContent htmlContent={htmlContent || ''} />
          ) : (
            <EditorContent editor={readonlyEditor} />
          )}
        </div>
      </div>
    </div>
  );
};
