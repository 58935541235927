import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { tasksModel } from '..';
import { tasksApi } from '../../../../../src/shared/api';
import { TasklistWithTasks } from '@distribute/shared/types';

export function* reorderTasks({
  payload: {
    tasklistId,
    documentContentId,
    destinationIndex,
    sourceIndex,
    successCallback,
    updateTasklist,
  },
}: ReturnType<typeof tasksModel.actions.reorderTasks>) {
  const tasklists: Record<string, TasklistWithTasks> = yield select(
    tasksModel.selectors.selectTasklists
  );

  try {
    const tasklist = tasklists[tasklistId];
    const reorderedTasks = [...tasklist.tasks];
    const sourceTask = reorderedTasks.splice(sourceIndex - 1, 1);

    reorderedTasks.splice(destinationIndex - 1, 0, ...sourceTask);

    const tasksWithNewOrders = reorderedTasks.map((task, index) => ({
      id: task.id,
      order: index + 1,
    }));

    const newTasks = tasklist.tasks
      .map((task) => {
        return {
          ...task,
          order: tasksWithNewOrders.find((t) => t.id === task.id)?.order || 0,
        };
      })
      .sort((a, b) => a.order - b.order);

    const newTasksWithCorrectOrders = newTasks.map((t, i) => {
      return { ...t, order: i };
    });

    const newTasklists = {
      ...tasklists,
      [tasklistId]: {
        ...tasklists[tasklistId],
        tasks: newTasksWithCorrectOrders,
      },
    };

    updateTasklist(newTasklists[tasklistId]);

    yield call(tasksApi.reorderTasks, tasklistId, documentContentId, {
      tasks: newTasks,
    });

    yield put(tasksModel.actions.setTasklists(newTasklists));

    successCallback();
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to reorder tasks')
      )
    );

    updateTasklist(tasklists[tasklistId]);
  }
}
