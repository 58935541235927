import { PropsWithChildren, useEffect, useState } from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  Button,
  ButtonWithTooltip,
  Icon,
  Toggle,
  Tooltip,
} from '../../../../../../shared/ui';
import { IconMap } from '../../../../../../shared/sprite';
import { cn } from '@distribute/frontend/utils';
import {
  CreatePageFormSchemaType,
  PageCreationTypesEnum,
} from '../../../../config/types';
import { useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../../../model';

const OutlineCard = ({
  children,
  isDragging = false,
  isError = false,
}: PropsWithChildren<{ isDragging?: boolean; isError?: boolean }>) => {
  return (
    <div
      className={cn(
        'w-full border-1.5 border-gray-200 group-hover:bg-gradient-orange-to-purple group-hover:border-0 group-hover:p-0.375 rounded-xl group-focus-within:!border-primary-600 group-focus-within:!border-1.5 group-focus-within:!p-0',
        isDragging && 'shadow-lg',
        isError && 'border-error-500'
      )}
    >
      <div className="bg-base-white px-6 py-4 rounded-[11px]">{children}</div>
    </div>
  );
};

type InsertOutlineItemLineProps = {
  isDragging: boolean;
  insert: () => void;
};

const InsertOutlineItemLine = (props: InsertOutlineItemLineProps) => {
  const { isDragging, insert } = props;

  return (
    <div
      className={cn(
        'absolute z-10 -top-1.5 left-0 transform translate-y-1/2 w-full h-0.5 bg-primary-500 rounded-xl opacity-0 hover:opacity-100',
        isDragging && '!hidden'
      )}
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Tooltip
          trigger={
            <button
              className="h-7 w-7 rounded-full bg-base-white border-gray-100 border shadow-sm flex items-center justify-center text-primary-600 !outline-none"
              onClick={insert}
            >
              <Icon glyph={IconMap.Plus} className="w-4 h-4" />
            </button>
          }
        >
          Add section
        </Tooltip>
      </div>
    </div>
  );
};

export const OutlineBoard = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [activeOutlineInput, setActiveOutlineInput] = useState<string | null>(
    null
  );

  const { control, formState, register, setValue, watch } =
    useFormContext<CreatePageFormSchemaType>();

  const { field } = useController({
    control,
    name: 'isSeparateTabs',
  });

  const { fields, append, remove, move, insert } = useFieldArray({
    control,
    name: 'sections',
  });

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false);

    if (!result.destination) return;
    move(result.source.index, result.destination.index);
  };

  const onBeforeDragStart = () => {
    setIsDragging(true);
  };

  const generatedOutline = useSelector(
    pageCreationFlowModel.selectors.selectGeneratedOutline
  );

  const pageCreationType = useSelector(
    pageCreationFlowModel.selectors.selectPageCreationType
  );

  useEffect(() => {
    setValue('sections', generatedOutline);
  }, [generatedOutline, setValue]);

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex flex-col gap-4 overflow-hidden">
        <div className="group">
          <OutlineCard isError={!!formState.errors.pageTitle}>
            <div className="text-left">
              <p className="text-base-black text-sm opacity-40 uppercase">
                {pageCreationType === PageCreationTypesEnum.TAB
                  ? 'Tab title'
                  : 'Title'}
              </p>
              <input
                value={watch('pageTitle')}
                onChange={({ target: { value } }) =>
                  setValue('pageTitle', value)
                }
                className="w-full text-xl font-semibold outline-none"
                placeholder="Untitled"
              />
            </div>
          </OutlineCard>
        </div>

        <div className="flex flex-col overflow-scroll pb-4 mb-4">
          <DragDropContext
            onDragEnd={onDragEnd}
            onBeforeDragStart={onBeforeDragStart}
          >
            <Droppable droppableId="steps">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-col gap-2"
                >
                  {fields.map((field, index) => (
                    <div className="relative" key={field.id}>
                      {index > 0 && (
                        <InsertOutlineItemLine
                          isDragging={isDragging}
                          insert={() => insert(index, { title: '' })}
                        />
                      )}
                      <Draggable
                        key={field.id}
                        draggableId={field.id}
                        index={index}
                        isDragDisabled={fields.length === 1}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="flex items-center group relative"
                          >
                            <div
                              className="flex-1 relative"
                              {...provided.dragHandleProps}
                            >
                              <OutlineCard
                                isDragging={snapshot.isDragging}
                                isError={
                                  !!formState.errors.sections?.[index]?.title
                                }
                              >
                                <div className="flex items-center gap-2">
                                  <span
                                    className={cn(
                                      'text-gray-700 text-end w-6 h-6',
                                      {
                                        'group-hover:hidden':
                                          fields.length !== 1 &&
                                          activeOutlineInput !== field.id,
                                      }
                                    )}
                                  >
                                    {index + 1}.
                                  </span>
                                  <Icon
                                    glyph={IconMap.Drag}
                                    className={cn('text-gray-400 hidden', {
                                      'group-hover:block':
                                        fields.length !== 1 &&
                                        activeOutlineInput !== field.id,
                                    })}
                                    width={24}
                                  />
                                  <input
                                    {...register(`sections.${index}.title`)}
                                    className="flex-1 outline-none pr-4"
                                    placeholder="Enter section title..."
                                    onFocus={() =>
                                      setActiveOutlineInput(field.id)
                                    }
                                    onBlur={() => setActiveOutlineInput(null)}
                                  />
                                  <div
                                    className={cn(
                                      'absolute mt-px right-1.5 top-1.5 hidden',
                                      {
                                        'group-hover:block':
                                          activeOutlineInput !== field.id,
                                      }
                                    )}
                                  >
                                    <ButtonWithTooltip
                                      title="Delete section"
                                      variant="icon"
                                      color="transparent"
                                      onClick={() => remove(index)}
                                      className="text-gray-400 hover:text-red-500"
                                    >
                                      <Icon
                                        glyph={IconMap.Trash}
                                        className="w-5 h-5"
                                      />
                                    </ButtonWithTooltip>
                                  </div>
                                </div>
                              </OutlineCard>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div
            className={cn('flex justify-center mt-6', { 'mt-4': isDragging })}
          >
            <Button
              variant="icon-text"
              color="secondary"
              iconLeftName={IconMap.PlusCircle}
              iconLeftWidth={20}
              onClick={() => append({ title: '' })}
            >
              Add Section
            </Button>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-auto">
        <div className="flex items-center gap-2">
          {pageCreationType !== PageCreationTypesEnum.TAB && (
            <>
              <Toggle
                checked={field.value}
                onChange={() => field.onChange(!field.value)}
              />
              <p className="text-sm font-medium text-gray-700">
                Place each section in a separate tab
              </p>
            </>
          )}
        </div>
        <p className="text-sm text-gray-700">
          <span className="font-semibold">{fields.length} sections</span> added
        </p>
      </div>
    </div>
  );
};
