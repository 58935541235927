import { TypeClaim } from '@mux/mux-node/util/jwt-types';

export enum MuxAssetType {
  // Extend it later for the existing record and narration type
  CALL = 'call',
}

export type MuxAssetPlaybackId = {
  id: string;
  policy: 'public' | 'signed' | 'drm';
};

export type MuxAssetData = {
  playbackIds: MuxAssetPlaybackId[];
  duration: number;
  aspectRatio: string;
};

export enum MuxAssetStatus {
  PREPARING = 'preparing',
  READY = 'ready',
  ERRORED = 'errored',
}

// Current status of any static renditions (mp4s)
export enum MuxAssetStatusStatic {
  PREPARING = 'preparing',
  READY = 'ready',
  ERRORED = 'errored',
  DELETED = 'deleted',
  DISABLED = 'disabled',
}

export type MuxAsset = {
  id: string;
  type: MuxAssetType | null;
  data: MuxAssetData;
  status: MuxAssetStatus;
  statusStatic: MuxAssetStatusStatic | null;
  createdAt: Date;
  updatedAt: Date;
};

export type MuxAssetPlaybackTokens = Partial<
  Record<keyof typeof TypeClaim, string>
>;

export type MuxAssetPlayback = {
  playback:
    | {
        id: string;
        policy: 'public';
      }
    | {
        id: string;
        policy: 'signed';
        tokens: MuxAssetPlaybackTokens;
      };
};
