export const formatDate = (inputString: string) => {
  const date = new Date(inputString);
  const formatter = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  return formatter.format(date).split('/').join('.');
};

export const formatDateShort = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

export const formatDateDiff = (date: Date): string => {
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds
  const oneWeek = 7 * oneDay;
  const oneMonth = 30 * oneDay;
  const oneYear = 365 * oneDay;

  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  const givenDate = date;
  givenDate.setHours(0);
  givenDate.setMinutes(0);
  givenDate.setSeconds(0);
  givenDate.setMilliseconds(0);

  const timeDiff = givenDate.getTime() - today.getTime();

  const diffDays = Math.round(timeDiff / oneDay);
  const diffWeeks = Math.round(timeDiff / oneWeek);
  const diffMonths = Math.round(timeDiff / oneMonth);
  const diffYears = Math.round(timeDiff / oneYear);

  if (diffDays === 0) {
    return 'today';
  } else if (diffDays === -1) {
    return 'yesterday';
  } else if (diffDays === 1) {
    return 'tomorrow';
  } else if (Math.abs(diffDays) < 7) {
    return diffDays < 0
      ? `${Math.abs(diffDays)} days ago`
      : `in ${diffDays} days`;
  } else if (Math.abs(diffWeeks) < 4) {
    return diffWeeks < 0
      ? `${Math.abs(diffWeeks)} week(s) ago`
      : `in ${diffWeeks} week(s)`;
  } else if (Math.abs(diffMonths) < 12) {
    return diffMonths < 0
      ? `${Math.abs(diffMonths)} month(s) ago`
      : `in ${diffMonths} month(s)`;
  } else {
    return diffYears < 0
      ? `${Math.abs(diffYears)} year(s) ago`
      : `in ${diffYears} year(s)`;
  }
};

export const minutesToMilliseconds = (minutes: number) => minutes * 60 * 1000;
