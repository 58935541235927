import React, { useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DragStart,
  DragUpdate,
} from 'react-beautiful-dnd';
import { mergeRefs } from 'react-merge-refs';

import { useScrollIntoView } from '@distribute/frontend/utils';
import { IconMap } from '../../../../shared/sprite';
import { Icon, Tooltip } from '../../../../shared/ui';
import { EditorTabContent } from './EditorTabContent';
import { SkeletonItem } from '../../../../shared/ui';
import { useNativeScroll } from '../../../../shared/hooks/useNativeScroll';
import type { DocumentContentItem } from '@distribute/shared/types';

export enum TabState {
  DEFAULT = 'default',
  HIGHLIGHTED = 'highlighted',
  DISABLED = 'disabled',
}

type TabStatesMap = Record<number, TabState>;

type EditorTabsProps = {
  isReadOnly: boolean;
  currentTab: DocumentContentItem;
  tabs: DocumentContentItem[];
  openedTabId?: number;
  onCreateNewTab: (cb: (err?: unknown) => void) => void;
  onChangeTab: (tab: DocumentContentItem, isOpen?: boolean) => void;
  onTabUpdate: (
    data: Partial<DocumentContentItem>,
    cb?: (err?: unknown) => void
  ) => void;
  onTabDelete: (itemId: number) => void;
  onTabDuplicate: (itemId: number, cb?: (err: unknown) => void) => void;
  onLoadFromTemplate: () => void;
  onSaveAsTemplate: (itemId: number) => void;
  onUpdateOrder: (
    tabs: Partial<DocumentContentItem>[],
    cb?: (err?: unknown) => void
  ) => void;
  onToggleDropdown: (isOpen: boolean) => void;
  isTemplateMode: boolean;
  tabStates?: TabStatesMap;
};

export const EditorTabs: React.FC<EditorTabsProps> = ({
  isReadOnly,
  tabs,
  currentTab,
  openedTabId,
  onCreateNewTab,
  onChangeTab,
  onTabUpdate,
  onTabDelete,
  onTabDuplicate,
  onLoadFromTemplate,
  onSaveAsTemplate,
  onUpdateOrder,
  onToggleDropdown,
  isTemplateMode,
  tabStates = {},
}) => {
  const [lTabs, setLTabs] = useState(tabs);
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tabsWrapperRef = useRef<HTMLDivElement | null>(null);
  const tabsLengthRef = useRef(tabs.length);
  const {
    isStartScrolling,
    isEndScrolling,
    setScrollPosition,
    recalculateScroll,
  } = useNativeScroll(containerRef);

  const [drInIndex, setDrInIndex] = useState<number | null>(null);
  const [drOverIndex, setDrOverIndex] = useState<number | null>(null);

  const { scrollToTop } = useScrollIntoView(containerRef, false);

  const getScrollCorrection = (tab: DocumentContentItem) => {
    const index = tabs.findIndex((t) => t.id === tab.id);
    const containerElement = containerRef.current as HTMLDivElement;
    const currentElement = tabsWrapperRef.current?.children[
      index
    ] as HTMLElement;
    const containerPosition = containerElement?.getBoundingClientRect();
    const currentPosition = currentElement?.getBoundingClientRect();

    if (!containerPosition || !currentPosition) return 0;

    if (currentPosition.left < containerPosition.left) {
      return -1;
    }

    if (currentPosition.right >= containerPosition.right) {
      return 1;
    }

    return 0;
  };

  const handlePrevClick = () => {
    setScroll(-1);
  };
  const handleNextClick = () => {
    setScroll(1);
  };
  const handleNewPageClick = useCallback(() => {
    setLoadingIndex(tabs.length - 1);
    onCreateNewTab(() => {
      setLoadingIndex(null);
    });
  }, [tabs, onCreateNewTab]);

  const handleTabDuplicate = useCallback(
    (id: number) => {
      const currentTabIndex = tabs.findIndex((tab) => tab.id === id) ?? null;
      setLoadingIndex(currentTabIndex);
      onTabDuplicate(id, () => {
        setLoadingIndex(null);
      });
    },
    [tabs, onTabDuplicate]
  );

  const handleToggleTabDropdown = useCallback(
    (isOpened: boolean) => {
      setIsDropdownOpen(isOpened);
      onToggleDropdown(isOpened);
    },
    [setIsDropdownOpen, onToggleDropdown]
  );

  const handleTabClick = useCallback(
    (tab: DocumentContentItem, isOpen?: boolean) => {
      onChangeTab(tab, isOpen);
      const scrollCorrection = getScrollCorrection(tab);
      setScroll(scrollCorrection);
    },
    [onChangeTab, tabs]
  );

  const handleTabNameUpdate = useCallback(
    (data: { id: number; name: string }) => {
      setLTabs((prevTabs) =>
        prevTabs.map((tab) => ({
          ...tab,
          name: tab.id === data.id ? data.name : tab.name,
        }))
      );
      onTabUpdate({ name: data.name }, (err?: unknown) => {
        if (err) setLTabs(tabs);
      });
    },
    [tabs, onTabUpdate]
  );

  const handleVisibilityUpdate = useCallback(
    (data: { id: number; isVisible: boolean }) => {
      setLTabs((prevTabs) =>
        prevTabs.map((tab) => ({
          ...tab,
          isVisible: tab.id === data.id ? data.isVisible : tab.isVisible,
        }))
      );
      onTabUpdate({ isVisible: data.isVisible }, (err?: unknown) => {
        if (err) setLTabs(tabs);
      });
    },
    [tabs, onTabUpdate]
  );

  const setScroll = (index: number) => {
    const containerElement = containerRef.current as HTMLDivElement;
    const currentScroll = containerElement.scrollLeft;
    const currentElementIndex = [
      ...(tabsWrapperRef.current?.children ?? []),
    ].findIndex(
      (element) =>
        element.getBoundingClientRect().right >=
        containerElement.getBoundingClientRect().left
    );
    const nextIndex = Math.max(
      0,
      Math.min(currentElementIndex + index, tabs.length - 1)
    );
    const currentElement = tabsWrapperRef.current?.children[
      nextIndex
    ] as HTMLElement;
    const scrollMax =
      currentElement.getBoundingClientRect().left -
      containerElement?.getBoundingClientRect().left;

    setScrollPosition(scrollMax + currentScroll);
  };

  const isEqual =
    containerRef.current?.scrollWidth === containerRef.current?.clientWidth;

  useEffect(() => {
    if (tabs.length < tabsLengthRef.current) {
      setScrollPosition(0);
    }
    tabsLengthRef.current = tabs.length;
    setLTabs(tabs);
  }, [tabs, setScrollPosition]);

  useEffect(() => {
    recalculateScroll();
  }, [lTabs, recalculateScroll]);

  useEffect(() => {
    scrollToTop();
  }, [currentTab.id, scrollToTop]);

  const reorder = (list: DocumentContentItem[], start: number, end: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(start, 1);
    result.splice(end, 0, removed);

    return result.map(({ order, ...rest }, index) => ({
      order: index,
      ...rest,
    }));
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;

      setDrInIndex(null);
      setDrOverIndex(null);

      if (!destination) return;

      const destinationIndex = destination.index;
      const sourceIndex = source.index;

      if (destinationIndex === sourceIndex) return;
      const newTabs = reorder(lTabs, sourceIndex, destinationIndex);

      setLTabs(newTabs);

      onUpdateOrder(newTabs, (err?: unknown) => {
        if (err) {
          setLTabs(tabs);
        }
      });
    },
    [lTabs, onUpdateOrder, tabs]
  );

  const onDragStart = useCallback((result: DragStart) => {
    const { source } = result;
    setDrInIndex(source.index);
  }, []);

  const onDragUpdate = useCallback((result: DragUpdate) => {
    setDrOverIndex(result.destination?.index ?? null);
  }, []);

  const isCreateDisabled = loadingIndex !== null;
  const canSetVisibility =
    lTabs.length > 1 && lTabs.filter((tab) => tab.isVisible).length > 1;
  const isDraggableDisabled = isReadOnly || lTabs.length <= 1;

  useEffect(() => {
    const currentTabIndex = tabs.findIndex((tab) => tab.id === currentTab.id);

    if (currentTabIndex !== -1 && currentTabIndex !== 0) {
      setTimeout(() => {
        setScroll(currentTabIndex);
      }, 0);
    }
  }, []);

  const isTabDisabled = useCallback(
    (tabId: number) => {
      return tabStates[tabId] === TabState.DISABLED;
    },
    [tabStates]
  );

  const isTabHighlighted = useCallback(
    (tabId: number) => {
      return tabStates[tabId] === TabState.HIGHLIGHTED;
    },
    [tabStates]
  );

  return (
    <div className="relative border-b border-gray-300 mb-6">
      <div
        className={classNames(
          'flex gap-5 mr-14 text-gray-500 font-semibold scrollbar-hide',
          {
            'overflow-scroll': !isDropdownOpen,
            'overflow-hidden': isDropdownOpen,
          }
        )}
        ref={containerRef}
      >
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
        >
          <Droppable
            droppableId="droppable"
            direction="horizontal"
            ignoreContainerClipping
          >
            {(provided) => (
              <div
                className="flex gap-5 items-end"
                ref={mergeRefs([provided.innerRef, tabsWrapperRef])}
                {...provided.droppableProps}
              >
                {lTabs.map((tab, index) => (
                  <Draggable
                    key={tab.id}
                    isDragDisabled={
                      isDraggableDisabled || isTabDisabled(tab.id)
                    }
                    draggableId={tab.id.toString()}
                    index={index}
                  >
                    {(drProvided, snapshot) => (
                      <>
                        {drInIndex !== null &&
                        !snapshot.isDragging &&
                        index === drOverIndex &&
                        drInIndex > drOverIndex ? (
                          <span className="h-6 w-0.5 mb-2 rounded-md bg-primary-500 shadow-drag" />
                        ) : null}
                        <div
                          ref={drProvided.innerRef}
                          {...drProvided.draggableProps}
                          {...drProvided.dragHandleProps}
                          style={{
                            ...drProvided.draggableProps.style,
                            transform: snapshot.isDragging
                              ? drProvided.draggableProps.style?.transform
                              : 'unset',
                          }}
                        >
                          <Tooltip
                            className={classNames({
                              hidden: isTabDisabled(tab.id),
                            })}
                            isOpen={
                              tab.id === currentTab.id ? false : undefined
                            }
                            trigger={
                              <EditorTabContent
                                isReadOnly={isReadOnly || isTabDisabled(tab.id)}
                                isHighlighted={isTabHighlighted(tab.id)}
                                isOpen={tab.id === openedTabId}
                                isSelected={
                                  tab.id === currentTab.id &&
                                  !snapshot.isDragging
                                }
                                tab={tab}
                                onToggleDropdown={handleToggleTabDropdown}
                                canDelete={tabs.length > 1}
                                canSetVisibility={canSetVisibility}
                                onTabSelect={
                                  isTabDisabled(tab.id)
                                    ? undefined
                                    : handleTabClick
                                }
                                onNameUpdate={handleTabNameUpdate}
                                onTabDelete={onTabDelete}
                                onTabDuplicate={handleTabDuplicate}
                                onVisibleUpdate={handleVisibilityUpdate}
                                onLoadFromTemplate={onLoadFromTemplate}
                                onSaveAsTemplate={onSaveAsTemplate}
                                isTemplateMode={isTemplateMode}
                              />
                            }
                          >
                            <span className="block text-center">
                              Click to select
                            </span>

                            {!(isReadOnly || isTabDisabled(tab.id)) && (
                              <span className="block text-center">
                                Drag to change order
                              </span>
                            )}
                          </Tooltip>
                        </div>
                        {snapshot.isDragging ? (
                          <EditorTabContent
                            isReadOnly={isReadOnly || isTabDisabled(tab.id)}
                            isOpen={false}
                            isSelected={tab.id === currentTab.id}
                            tab={tab}
                            onToggleDropdown={handleToggleTabDropdown}
                            canDelete={false}
                            onTabSelect={
                              isTabDisabled(tab.id) ? undefined : handleTabClick
                            }
                            onNameUpdate={handleTabNameUpdate}
                            onTabDelete={onTabDelete}
                            onTabDuplicate={onTabDuplicate}
                            onVisibleUpdate={handleVisibilityUpdate}
                            onLoadFromTemplate={onLoadFromTemplate}
                            onSaveAsTemplate={onSaveAsTemplate}
                            isTemplateMode={isTemplateMode}
                          />
                        ) : null}
                        {drInIndex !== null &&
                        !snapshot.isDragging &&
                        index === drOverIndex &&
                        drInIndex < drOverIndex ? (
                          <span className="h-6 w-0.5 mb-2 rounded-md bg-primary-500 shadow-drag" />
                        ) : null}
                        {loadingIndex === index ? (
                          <SkeletonItem
                            className="rounded-md mb-2"
                            height="24px"
                            width="62px"
                          />
                        ) : null}
                      </>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <button
          disabled={isCreateDisabled || isReadOnly}
          className="pb-2 hover:text-gray-900 whitespace-nowrap border-0 outline-0 flex gap-1 items-center disabled:cursor-not-allowed disabled:text-gray-400"
          onClick={handleNewPageClick}
        >
          <Icon glyph={IconMap.Plus} width={20} className="shrink-0" />
          New Tab
        </button>
      </div>
      {!isEqual && (
        <div className="flex absolute right-0 top-0.5 text-gray-600">
          <button
            disabled={isStartScrolling}
            className={classNames('border-0 outline-0 rounded-md', {
              'hover:text-gray-700 hover:bg-base-black/10': !isStartScrolling,
              'text-gray-400': isStartScrolling,
            })}
            onClick={handlePrevClick}
          >
            <Icon glyph={IconMap.ChevronLeft} width={24} className="shrink-0" />
          </button>
          <button
            disabled={isEndScrolling}
            className={classNames('border-0 outline-0 rounded-md', {
              'hover:text-gray-700 hover:bg-base-black/10': !isEndScrolling,
              'text-gray-400': isEndScrolling,
            })}
            onClick={handleNextClick}
          >
            <Icon
              glyph={IconMap.ChevronRight}
              width={24}
              className="shrink-0"
            />
          </button>
        </div>
      )}
    </div>
  );
};
