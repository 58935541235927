// TODO: separate handlers

import { MouseEvent } from 'react';
import { deleteTaskIdQueryParam } from './showTaskInfo';

export const handleHtmlContentElementClick = (
  event: MouseEvent<HTMLDivElement>
) => {
  const targetEl = event.target as HTMLElement;

  // toggle-list handler
  const toggleTarget = targetEl.closest('.toggle-item__button');

  if (toggleTarget) {
    const arrow = event.target as HTMLElement;

    const content = toggleTarget.parentElement?.children[1]
      .children[1] as HTMLElement;

    if (content) {
      if (content.style.display === 'block') {
        content.style.display = 'none';
        arrow.style.transform = 'rotate(0)';
      } else {
        content.style.display = 'block';
        arrow.style.transform = 'rotate(90deg)';
      }
    } else {
      if (arrow.style.transform === 'rotate(90deg)') {
        arrow.style.transform = 'rotate(0)';
      } else {
        arrow.style.transform = 'rotate(90deg)';
      }
    }
  }

  // code-block handler
  const copyCodeTarget = targetEl.closest('.code-block__copy');

  if (copyCodeTarget) {
    const textContent = copyCodeTarget.attributes.getNamedItem('data-text');

    if (textContent) {
      navigator.clipboard.writeText(textContent.value);

      copyCodeTarget.children[2]?.classList.remove(
        'code-block__copy-tooltip--hidden'
      );

      setTimeout(() => {
        copyCodeTarget.children[2]?.classList.add(
          'code-block__copy-tooltip--hidden'
        );
      }, 500);
    }
  }

  // tasklist handler
  const tasklistToggle = targetEl.closest('.custom-tasklist__toggle-btn');

  if (tasklistToggle) {
    const arrowButton = event.target as HTMLElement;

    if (arrowButton) {
      if (arrowButton.classList.contains('custom-tasklist__toggle--rotated')) {
        arrowButton.classList.remove('custom-tasklist__toggle--rotated');
      } else {
        arrowButton.classList.add('custom-tasklist__toggle--rotated');
      }
    }

    const tasklistContent =
      arrowButton.parentElement?.parentElement?.parentElement?.parentElement
        ?.children[2];
    const tasklistHr =
      arrowButton.parentElement?.parentElement?.parentElement?.parentElement
        ?.children[1];

    if (tasklistContent) {
      if (
        tasklistContent.classList.contains('custom-tasklist__content--hidden')
      ) {
        tasklistContent.classList.remove('custom-tasklist__content--hidden');
      } else {
        tasklistContent.classList.add('custom-tasklist__content--hidden');
      }
    }

    if (tasklistHr) {
      if (tasklistHr.classList.contains('custom-tasklist__hr--hidden')) {
        tasklistHr.classList.remove('custom-tasklist__hr--hidden');
      } else {
        tasklistHr.classList.add('custom-tasklist__hr--hidden');
      }
    }
  }

  const expandTaskBtn = targetEl.closest('.custom-tasklist-item__expand-btn');

  if (expandTaskBtn) {
    const expandBtn = event.target as HTMLElement;

    if (expandBtn) {
      const taskModal =
        expandBtn.parentElement?.parentElement?.parentElement?.children[2];

      if (taskModal) {
        taskModal.classList.remove('task-modal-wrapper--hidden');
      }
    }
  }

  const closeTaskBtn = targetEl.closest('.task-modal__close-btn');

  if (closeTaskBtn) {
    const closeBtn = event.target as HTMLElement;

    if (closeBtn) {
      const taskModal =
        closeBtn.parentElement?.parentElement?.parentElement?.parentElement;

      if (taskModal) {
        taskModal.classList.add('task-modal-wrapper--hidden');
        deleteTaskIdQueryParam();
      }
    }
  }

  const taskModal = targetEl.closest('.task-modal-wrapper');
  const taskModalContent = targetEl.closest('.task-modal-content');

  if (taskModal && !taskModalContent) {
    taskModal.classList.add('task-modal-wrapper--hidden');
    deleteTaskIdQueryParam();
  }

  // contact card

  const copyContactTarget = targetEl.closest('.contact-card__copy-button');

  if (copyContactTarget) {
    const contactLink = copyContactTarget.attributes.getNamedItem('data-text');

    if (contactLink) {
      navigator.clipboard.writeText(
        contactLink.value.replace('mailto:', '').replace('tel:', '')
      );
    }
  }
};
