import { FC } from 'react';
import { recordingsModel } from '../../../model';
import { useSelector } from 'react-redux';
import { SelectableInput } from '../../../../../shared/ui';
import { DEFAULT_NAME } from '../../../config';

export const RecordTitle: FC = () => {
  const record = useSelector(recordingsModel.selectors.selectRecord);

  return (
    <SelectableInput
      variant="text"
      readonly
      valueReadonly={record.name || DEFAULT_NAME}
      value={record.name}
      placeholder="Untitled"
      classNames="w-full"
      tooltipText="Rename recording"
    />
  );
};
