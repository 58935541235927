import { FC, useState } from 'react';
import cn from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { IconMap } from '../../../shared/sprite';
import { Icon, Avatar } from '../../../shared/ui';

type Props = {
  users: string[];
};

export const MeetingEventCardUsers: FC<Props> = ({ users }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <DropdownMenu.Root open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenu.Trigger className="flex gap-1.5 items-center bg-base-white p-1 pr-1.5 rounded-full focus:outline-none shadow-xs font-medium">
        <Icon glyph={IconMap.Lead} width={16} />
        {users.length} people
        <Icon
          className={cn('text-gray-700 transition-all', {
            'rotate-180': isDropdownOpen,
          })}
          glyph={IconMap.ArrowDown}
          width={16}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="start"
        sideOffset={4}
        className="bg-base-white border border-gray-300 rounded-md shadow-lg w-64 p-3 text-gray-500 z-10 max-h-80 overflow-y-auto"
      >
        {users.map((user) => (
          <DropdownMenu.Item
            key={user}
            className="flex items-center gap-3 py-1.25 focus:outline-none"
          >
            <Avatar src={undefined} displayName={user} />
            <span className="truncate">{user}</span>
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
