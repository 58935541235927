import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';
import {
  GetCallResponse,
  GetCallDetailResponse,
  GetGroupedCallsResponse,
} from '@distribute/shared/api-types/call';

import {
  DeleteCallItemAction,
  GenerateSummaryAction,
  UpdateCallItemAction,
  SetPublicPlaybackAction,
  CreateOrInsertToPageFlowsEnum,
  ChooseFolderOrPageAction,
} from './types';
import { CallSummaryType } from '@distribute/shared/types';

type State = {
  isListLoading: boolean;
  isCallCountLoading: boolean;
  calls: GetCallResponse[];
  groupedCalls: GetGroupedCallsResponse;
  isGroupedCallsLoading: boolean;
  total: {
    userCalls: number;
    teamCalls: number;
  };
  isCurrentCallLoading: boolean;
  isSummaryLoading: boolean;
  currentCall?: GetCallDetailResponse;
  editingCallItem?: GetCallResponse;
  deletingCallItem?: GetCallResponse;
  togglingCallItem?: GetCallResponse;
  isCallEditingProgress: boolean;
  isCallDeletingProgress: boolean;
  listOptions: {
    teamOnly?: boolean;
    search?: string;
    sortOrder?: 'ASC' | 'DESC';
    sortBy?: 'name' | 'createdAt';
    filterByMember?: string;
  };
  listMeta: {
    totalItems?: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages?: number;
    currentPage: number;
  };
  currentSummaryType: CallSummaryType;
  pageCreationFlow?: CreateOrInsertToPageFlowsEnum;
  isAssetPrivacyModalOpen: boolean;
  isAssetPrivacyLoading: boolean;
  isChoseFolderOrPageModalOpen: boolean;
  isInsertToPageLoading: boolean;
};

const initialState: State = {
  isListLoading: false,
  isCallCountLoading: false,
  calls: [],
  groupedCalls: {
    personal: [],
    team: [],
  },
  isGroupedCallsLoading: false,
  total: {
    userCalls: 0,
    teamCalls: 0,
  },
  isCurrentCallLoading: true,
  isSummaryLoading: false,
  currentCall: undefined,
  editingCallItem: undefined,
  deletingCallItem: undefined,
  togglingCallItem: undefined,
  isCallEditingProgress: false,
  isCallDeletingProgress: false,
  listOptions: {
    teamOnly: undefined,
    search: undefined,
    sortOrder: undefined,
    sortBy: 'createdAt',
    filterByMember: undefined,
  },
  listMeta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    currentPage: 1,
  },
  currentSummaryType: CallSummaryType.GENERAL,
  pageCreationFlow: undefined,
  isAssetPrivacyModalOpen: false,
  isAssetPrivacyLoading: false,
  isChoseFolderOrPageModalOpen: false,
  isInsertToPageLoading: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'call',
  initialState,
  reducers: {
    setIsListLoading: (
      state,
      { payload }: PayloadAction<State['isListLoading']>
    ) => ({
      ...state,
      isListLoading: payload,
    }),
    setIsTotalLoading: (
      state,
      { payload }: PayloadAction<State['isCallCountLoading']>
    ) => ({
      ...state,
      isCallCountLoading: payload,
    }),
    setListOptions: (
      state,
      { payload }: PayloadAction<State['listOptions']>
    ) => ({
      ...state,
      listOptions: {
        ...state.listOptions,
        ...payload,
      },
    }),
    setListMeta: (state, { payload }: PayloadAction<State['listMeta']>) => ({
      ...state,
      listMeta: payload,
    }),
    setCalls: (state, { payload }: PayloadAction<State['calls']>) => ({
      ...state,
      calls: payload,
    }),
    setTotal: (state, { payload }: PayloadAction<State['total']>) => ({
      ...state,
      total: payload,
    }),
    setCurrentCall: (
      state,
      { payload }: PayloadAction<State['currentCall']>
    ) => ({
      ...state,
      currentCall: payload,
    }),
    setIsCurrentCallLoading: (
      state,
      { payload }: PayloadAction<State['isCurrentCallLoading']>
    ) => ({
      ...state,
      isCurrentCallLoading: payload,
    }),
    setIsSummaryLoading: (
      state,
      { payload }: PayloadAction<State['isSummaryLoading']>
    ) => ({
      ...state,
      isSummaryLoading: payload,
    }),
    setEditingCallItem: (
      state,
      { payload }: PayloadAction<State['editingCallItem']>
    ) => ({
      ...state,
      editingCallItem: payload,
    }),
    setDeletingCallItem: (
      state,
      { payload }: PayloadAction<State['deletingCallItem']>
    ) => ({
      ...state,
      deletingCallItem: payload,
    }),
    setTogglingCallItem: (
      state,
      { payload }: PayloadAction<State['togglingCallItem']>
    ) => ({
      ...state,
      togglingCallItem: payload,
    }),
    setCallEditingProgress: (
      state,
      { payload }: PayloadAction<State['isCallEditingProgress']>
    ) => ({
      ...state,
      isCallEditingProgress: payload,
    }),
    setCallDeletingProgress: (
      state,
      { payload }: PayloadAction<State['isCallDeletingProgress']>
    ) => ({
      ...state,
      isCallDeletingProgress: payload,
    }),
    setGroupedCalls: (
      state,
      { payload: groupedCalls }: PayloadAction<GetGroupedCallsResponse>
    ) => ({ ...state, groupedCalls }),
    setIsGroupedCallsLoading: (
      state,
      { payload: isGroupedCallsLoading }: PayloadAction<boolean>
    ) => ({ ...state, isGroupedCallsLoading }),
    resetCurrentCall: (state) => ({
      ...state,
      currentCall: initialState.currentCall,
      isCurrentCallLoading: initialState.isCurrentCallLoading,
    }),
    setCurrentSummaryType: (
      state,
      { payload: currentSummaryType }: PayloadAction<CallSummaryType>
    ) => ({ ...state, currentSummaryType }),
    setPageCreationFlow: (
      state,
      {
        payload: pageCreationFlow,
      }: PayloadAction<CreateOrInsertToPageFlowsEnum>
    ) => ({
      ...state,
      pageCreationFlow,
    }),
    setIsAssetPrivacyModalOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      isAssetPrivacyModalOpen: payload,
    }),
    setIsAssetPrivacyLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isAssetPrivacyLoading: payload,
    }),
    setIsChooseFolderOrPageModalOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      isChoseFolderOrPageModalOpen: payload,
    }),
    setIsInsertToPageLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isInsertToPageLoading: payload,
    }),
    reset: () => ({
      ...initialState,
    }),
    resetOptions: (state) => ({
      ...state,
      calls: [],
      listOptions: initialState.listOptions,
      listMeta: initialState.listMeta,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  loadCalls: createAction('call/loadCalls'),
  loadGroupedCalls: createAction('call/loadGroupedCalls'),
  searchCalls: createAction('call/searchCalls'),
  deleteCallItem: createAction<DeleteCallItemAction>('call/deleteCallItem'),
  updateCallItem: createAction<UpdateCallItemAction>('call/updateCallItem'),
  getCallsCount: createAction('call/getCallsCount'),
  updateCallItemWithDebounce: createAction<UpdateCallItemAction>(
    'call/updateCallItemWithDebounce'
  ),
  generateSummary: createAction<GenerateSummaryAction>('call/generateSummary'),
  setPublicPlayback: createAction<SetPublicPlaybackAction>(
    'call/setPublicPlayback'
  ),
  chooseFolderOrPage: createAction<ChooseFolderOrPageAction>(
    'call/chooseFolderOrPage'
  ),
};
