import { pagesModel } from '../../../features/pages';
import { pageSettingsModel } from '../../../features/page-settings';
import { conversionKitModel } from '../../../features/conversion-kit';

import { fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { aiModel } from '../../../features/ai';
import { editorSidebarModel } from '../../../features/editor-sidebar';
import { socialModel } from '../../../entities/social-auth';
import { snippetsModel } from '../../../features/snippets';
import { tasksModel } from '../../../features/tasks';
import { editorLeftSidebarModel } from '../../../features/editor-left-sidebar';
import { viewersModel } from '../../../features/viewers';
import { analyticsModel } from '../../../features/analytics';
import { editorRightSidebarModel } from '../../../features/editor-right-sidebar';
import { closeRightSidebar, closeLeftSidebar } from './sagas';

export function* newEditorPageWorker() {
  yield fork(pageSettingsModel.sagas.watchUploadSeoConfigurationImage);
  yield fork(conversionKitModel.sagas.watchUploadCTAImage);
  yield fork(conversionKitModel.sagas.watchUploadPopUpImage);
  yield fork(aiModel.sagas.editorAiWorker);
  yield fork(aiModel.sagas.generatePageWorker);
  yield fork(snippetsModel.sagas.snippetsWorker);
  yield fork(tasksModel.sagas.tasksWorker);

  yield takeEvery(
    pagesModel.actions.changePageIcon,
    pagesModel.sagas.changePageIcon
  );
  yield takeEvery(
    pagesModel.actions.changeCoverImageUrl,
    pagesModel.sagas.changePageCoverImage
  );
  yield takeEvery(
    pagesModel.actions.changeBrandLogo,
    pagesModel.sagas.changePageBrandLogo
  );
  yield takeEvery(
    pagesModel.actions.deletePageBrandLogo,
    pagesModel.sagas.deletePageBrandLogo
  );
  yield takeEvery(
    pagesModel.actions.deletePageCoverImage,
    pagesModel.sagas.deletePageCoverImage
  );
  yield takeEvery(
    pagesModel.actions.changePageContent,
    pagesModel.sagas.changePageContent
  );
  yield takeEvery(
    pagesModel.actions.changeBrandColor,
    pagesModel.sagas.changeBrandColor
  );
  yield takeEvery(
    pagesModel.actions.changePageStyle,
    pagesModel.sagas.changePageStyle
  );
  yield takeEvery(pagesModel.actions.updatePage, pagesModel.sagas.updatePage);
  yield takeEvery(
    pagesModel.actions.updatePageFromTemplate,
    pagesModel.sagas.updatePageFromTemplate
  );
  yield takeEvery(
    pageSettingsModel.actions.updateSeoConfiguration,
    pageSettingsModel.sagas.updateSeoConfiguration
  );
  yield takeEvery(
    pageSettingsModel.actions.changeSeoConfigurationsImage,
    pageSettingsModel.sagas.changeSeoConfigurationsImage
  );
  yield takeEvery(
    pageSettingsModel.actions.removeSeoConfigurationsImage,
    pageSettingsModel.sagas.removeSeoConfigurationsImage
  );
  yield takeEvery(
    editorSidebarModel.actions.setActiveConversionSettingsPanel,
    editorSidebarModel.sagas.onActiveConversionPanelOpen
  );
  yield takeEvery(
    editorLeftSidebarModel.actions.setActiveConversionPanel,
    editorLeftSidebarModel.sagas.onActiveConversionPanel
  );
  yield takeEvery(
    editorSidebarModel.actions.updatePersonalizedCover,
    editorSidebarModel.sagas.updatePersonalizedCover
  );
  yield takeEvery(
    editorSidebarModel.actions.generatePersonalizedLink,
    editorSidebarModel.sagas.generatePersonalizedLink
  );
  yield takeEvery(
    socialModel.actions.connectToLinkedin,
    socialModel.sagas.connectToLinkedin
  );
  yield takeEvery(
    socialModel.actions.shareLinkedinPost,
    socialModel.sagas.shareLinkedinPost
  );
  yield takeEvery(
    socialModel.actions.revokeLinkedinToken,
    socialModel.sagas.revokeLinkedinToken
  );
  yield takeEvery(
    socialModel.actions.fetchLinkedinProfileData,
    socialModel.sagas.fetchLinkedinProfileData
  );
  yield takeEvery(
    socialModel.actions.uploadPostCover,
    socialModel.sagas.uploadPostCover
  );
  yield takeEvery(
    socialModel.actions.connectToTwitter,
    socialModel.sagas.connectToTwitter
  );
  yield takeEvery(
    socialModel.actions.shareTwitterPost,
    socialModel.sagas.shareTwitterPost
  );
  yield takeEvery(
    socialModel.actions.revokeTwitterToken,
    socialModel.sagas.revokeTwitterToken
  );

  yield takeEvery(
    snippetsModel.actions.updateSnippetUsedCount,
    snippetsModel.sagas.updateSnippetUsedCount
  );

  yield takeEvery(
    pagesModel.actions.setContentNavigation,
    pagesModel.sagas.setContentNavigation
  );

  yield takeEvery(
    pagesModel.actions.setCurrentContentItem,
    pagesModel.sagas.setCurrentContentItem
  );

  //analytics
  yield takeEvery(
    viewersModel.actions.onChangeRange,
    viewersModel.sagas.onChangeRange
  );
  yield takeEvery(
    viewersModel.actions.onChangeIsShowAnonymous,
    viewersModel.sagas.onChangeIsShowAnonymous
  );
  yield takeEvery(
    viewersModel.actions.onChangeCategory,
    viewersModel.sagas.onChangeCategory
  );
  yield takeLatest(
    viewersModel.actions.getViewersData,
    viewersModel.sagas.getViewersData
  );
  yield takeLatest(
    viewersModel.actions.exportCsv,
    viewersModel.sagas.exportCsv
  );

  yield takeEvery(
    analyticsModel.actions.resetAnalytics,
    analyticsModel.sagas.resetAnalyticsData
  );

  yield takeEvery(
    editorRightSidebarModel.actions.setSelectedOption,
    closeLeftSidebar
  );

  yield takeEvery(
    editorLeftSidebarModel.actions.setSelectedOption,
    closeRightSidebar
  );
}
