import React, { forwardRef, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { Icon } from './Icon';
import { IconMap } from '../sprite';

type Size = 'sm' | 'md';

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  labelElement?: React.ReactNode;
  uiSize?: Size;
  uiType?: 'radio' | 'check';
} & InputHTMLAttributes<HTMLInputElement>;

const sizesByElement: Record<
  'input' | 'dot' | 'checkIcon',
  Record<Size, string>
> = {
  input: {
    sm: 'w-4 h-4',
    md: 'w-5 h-5',
  },
  dot: {
    sm: 'w-1.5 h-1.5 ',
    md: 'h-2 w-2',
  },
  checkIcon: {
    sm: 'w-2.5 h-2.5 ',
    md: 'h-3.5 w-3.5',
  },
};

export const RadioButton = forwardRef<HTMLInputElement, Props>(
  function RadioButton(
    {
      name,
      value,
      onChange,
      label,
      labelElement,
      defaultChecked,
      disabled,
      className,
      uiSize = 'sm',
      uiType = 'radio',
    },
    ref
  ) {
    return (
      <label className="flex flex-row gap-x-2 items-center cursor-pointer">
        <span className="relative flex items-center justify-center">
          <input
            type="radio"
            name={name}
            disabled={disabled}
            value={value}
            onChange={onChange}
            defaultChecked={defaultChecked}
            ref={ref}
            className={cn(
              `border rounded-full border-gray-300 appearance-none focus:outline-none hover:bg-primary-100 hover:border-primary-600 focus:border-primary-600 cursor-pointer`,
              sizesByElement.input[uiSize],
              {
                'bg-gray-100 border-gray-300': disabled,
                'bg-primary-50 border-primary-600': defaultChecked,
              }
            )}
          />
          {uiType === 'radio' && (
            <span
              className={cn(
                'rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none',
                sizesByElement.dot[uiSize],
                {
                  'bg-transparent': !defaultChecked,
                  'bg-primary-600': defaultChecked,
                  'bg-gray-300': disabled && defaultChecked,
                }
              )}
            />
          )}
          {uiType === 'check' && (
            <div
              className={cn(
                'absolute top-0 left-0 flex justify-center items-center text-base-white w-full h-full rounded-full',
                {
                  hidden: !defaultChecked,
                  'bg-primary-600 hover:bg-primary-700': defaultChecked,
                  '!bg-gray-300': disabled,
                }
              )}
            >
              <Icon
                glyph={IconMap.Check}
                className={sizesByElement.checkIcon[uiSize]}
              />
            </div>
          )}
        </span>
        {label && (
          <span className={cn('text-sm font-medium text-gray-700', className)}>
            {label}
          </span>
        )}
        {!label && labelElement ? labelElement : null}
      </label>
    );
  }
);
