import { IconMap } from '../../../../../../shared/sprite';
import {
  Button,
  Icon,
  Input,
  SelectCard,
  Tooltip,
} from '../../../../../../shared/ui';
import { Trigger } from './Trigger';
import { CreateWithAIPageType } from '@distribute/shared/api-types/create-with-ai';
import { useFormContext } from 'react-hook-form';
import {
  CreatePageFormSchemaType,
  OTHER_PLACEHOLDER,
  PAGE_TYPES,
} from '../../../../config/types';
import { cn } from '@distribute/frontend/utils';

export const PageTypeTrigger = ({
  isOpened,
  isDone,
}: {
  isOpened: boolean;
  isDone: boolean;
}) => {
  const { watch } = useFormContext<CreatePageFormSchemaType>();

  const pageType = watch('pageType');

  return (
    <Trigger
      title="Type of page"
      isDone={isDone}
      description={isDone && pageType ? [pageType] : undefined}
      isOpened={isOpened}
    />
  );
};

type Props = {
  onNext: () => void;
  isDone: boolean;
};

export const PageType = ({ onNext, isDone }: Props) => {
  const { watch, setValue, register } =
    useFormContext<CreatePageFormSchemaType>();

  const selected = watch('pageType');

  const setSelected = (value: CreateWithAIPageType) => {
    setValue('pageType', value);
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="grid grid-cols-2 gap-4">
        {PAGE_TYPES.map(({ name, icon }) => (
          <SelectCard
            key={name}
            name="PageType"
            iconName={icon}
            selected={selected === name}
            onClick={() => setSelected(name)}
          >
            {name}
          </SelectCard>
        ))}
      </div>
      {selected === CreateWithAIPageType.OTHER && (
        <Input
          {...register('pageTypeDescription')}
          type="text"
          label="Describe what page you’d like to create *"
          isTextArea
          textAreaRows={3}
          placeholder={OTHER_PLACEHOLDER}
          className="py-3 px-4"
        />
      )}
      <div className="flex justify-end">
        <Tooltip
          className={cn({ hidden: isDone })}
          trigger={
            <Button
              disabled={!isDone}
              onClick={onNext}
              variant="text"
              color="primary"
              size="md"
            >
              <span className="flex items-center gap-1.5">
                <span>Next</span> <Icon glyph={IconMap.ArrowRight} width={24} />
              </span>
            </Button>
          }
          align="end"
          sideOffset={4}
        >
          <p className="px-2 py-1 font-semibold text-xs w-36">
            {selected === CreateWithAIPageType.OTHER
              ? 'Describe your page type to continue.'
              : 'Select type of page to continue.'}
          </p>
        </Tooltip>
      </div>
    </div>
  );
};
