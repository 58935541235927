import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { callModel } from '../model';
import { Modal, Button } from '../../../shared/ui';

export const CallAssetPrivacyModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(callModel.selectors.selectIsAssetPrivacyModalOpen);
  const isLoading = useSelector(
    callModel.selectors.selectIsAssetPrivacyLoading
  );
  const currentCall = useSelector(
    callModel.selectors.selectCurrentCallWithError
  );

  const onClose = () => {
    dispatch(callModel.actions.setIsAssetPrivacyModalOpen(false));
  };

  const handleConfirm = () => {
    dispatch(callModel.actions.setPublicPlayback({ callId: currentCall.id }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Make call recording public"
      className="w-100"
      actionButton={
        <Button
          onClick={handleConfirm}
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          loading={isLoading}
        >
          Apply
        </Button>
      }
    >
      <p className="text-sm text-gray-600">
        If you want to embed a video on your page, the call recording needs to
        be public so that your team and viewers can access it. Are you sure you
        want to make this recording public?
      </p>
    </Modal>
  );
};
