import { Button } from '../../../../src/shared/ui';
import { FC } from 'react';
import Calendar from 'react-calendar';

type Props = {
  dueDate?: Date | null;
  onDueDateChange: (value: Date | null | (Date | null)[]) => void;
};

export const DueDateCalendar: FC<Props> = ({ dueDate, onDueDateChange }) => {
  return (
    <>
      <Calendar
        allowPartialRange={false}
        value={dueDate}
        onChange={onDueDateChange}
      />
      <div className="mt-5 shadow-xs">
        <Button
          fullWidth
          variant="text"
          onClick={() => {
            onDueDateChange(null);
          }}
          color="secondary"
          disabled={!dueDate}
        >
          Clear date
        </Button>
      </div>
    </>
  );
};
