import axios from 'axios';

import { auth, COLLABORATION_REST_API_URL } from '../../config';
import { JSONContent } from '@tiptap/core';

const collaborationInstance = axios.create({
  baseURL: `${COLLABORATION_REST_API_URL}/parties/main`,
  timeout: 60000,
});

collaborationInstance.interceptors.request.use(async (config) => {
  const token = await auth.currentUser?.getIdToken();
  if (!token) {
    return config;
  }

  (config.headers as Record<string, string>)[
    'Authorization'
  ] = `Bearer ${token}`;

  return config;
});

type AddContentJsonToFirstPageTabProps = {
  pageId: string;
  teamId: number;
  content: JSONContent[];
};

export const collaborationApi = {
  async addContentJsonToFirstPageTab({
    pageId,
    teamId,
    content,
  }: AddContentJsonToFirstPageTabProps) {
    const { data } = await collaborationInstance.post(
      `${pageId}/add-content-json`,
      content,
      { params: { teamId } }
    );

    return data;
  },
};
