import { ContactCardForm } from './ContactCardForm';
import { EditorElements } from './EditorElements';
import { useSelector } from 'react-redux';
import { editorLeftSidebarModel } from '../../../model';

export const ElementsTab = () => {
  const isContactCardSelected = useSelector(
    editorLeftSidebarModel.selectors.selectContactCardEditorOpen
  );

  return isContactCardSelected ? <ContactCardForm /> : <EditorElements />;
};
