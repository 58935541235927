import { RootState } from '../../../app';

export const selectMeetingEvents = ({ callRecordings }: RootState) =>
  callRecordings.meetingEvents;
export const selectProgressMeetingEventIds = ({ callRecordings }: RootState) =>
  callRecordings.progressMeetingEventIds;
export const selectIsLoading = ({ callRecordings }: RootState) =>
  callRecordings.isLoading;

export const selectIsJoinCallModalOpen = ({ callRecordings }: RootState) =>
  callRecordings.isJoinCallModalOpen;

export const selectIsJoinCallInProgress = ({ callRecordings }: RootState) =>
  callRecordings.isJoinCallInProgress;
