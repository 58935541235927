import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { IconMap } from '../../../shared/sprite';
import { Button, Link } from '../../../shared/ui';
import { callRecordingSettingsModel } from '../../call-recording-settings';
import { callRecordingsModel } from '../model';

type Size = 'small' | 'large';

type Props = {
  size: Size;
};

export const ConnectCalendarState = ({ size = 'large' }: Props) => {
  const dispatch = useDispatch();
  const isCalendarConnecting = useSelector(
    callRecordingSettingsModel.selectors.selectIsCalendarConnecting
  );

  const handleCalendarConnect = () => {
    dispatch(callRecordingSettingsModel.actions.connectToGoogleCalendar());
  };

  const handleJoinCallManually = () => {
    dispatch(callRecordingsModel.actions.setIsJoinCallOpenModal(true));
  };

  return (
    <section className="flex flex-col items-center gap-6 max-w-128.5">
      <div
        className={cn(
          'flex justify-center bg-pattern-decorative bg-center bg-no-repeat w-full',
          {
            'py-32': size === 'large',
            'py-7': size === 'small',
          }
        )}
      >
        <div
          className={cn(
            'flex flex-col gap-2 px-4.5 py-4 bg-base-white rounded-2xl border border-gray-200',
            {
              'max-w-66': size === 'large',
              'max-w-49': size === 'small',
            }
          )}
        >
          <h3
            className={cn('text-gray-900 font-semibold', {
              'text-sm': size === 'small',
              'text-lg': size === 'large',
            })}
          >
            Upcoming meetings
          </h3>
          <div className="flex flex-col gap-1.5">
            <img
              src="./../../assets/images/upcoming-meetings-zoom.svg"
              alt="Upcoming meetings"
            />
            <img
              src="./../../assets/images/upcoming-meetings-google.svg"
              alt="Upcoming meetings"
            />
          </div>
        </div>
      </div>
      <div
        className={cn(
          'flex flex-col justify-center items-center gap-4 text-center',
          {
            '-mt-32': size === 'large',
            '-mt-7': size === 'small',
          }
        )}
      >
        <div>
          <h3
            className={cn('text-gray-900 font-semibold', {
              'text-md': size === 'small',
              'text-lg': size === 'large',
            })}
          >
            Connect your work calendar <br /> to unlock all Distribute Call
            Recorder feature access.
          </h3>
          <p className="text-gray-600 text-sm font-normal">
            Choose a calendar you use for in your team.
          </p>
        </div>
        <div className="flex flex-col gap-4 text-center text-gray-600">
          <Button
            loading={isCalendarConnecting}
            variant="icon-text"
            color="secondary"
            className={cn('flex gap-3 w-full', {
              'text-sm': size === 'small',
              'text-md': size === 'large',
            })}
            iconLeftName={IconMap.Google}
            onClick={handleCalendarConnect}
          >
            Continue with Google
          </Button>
          {size === 'large' ? (
            <>
              or
              <p>
                <Link size="large" onClick={handleJoinCallManually}>
                  Join Call Manually
                </Link>{' '}
                directly with a link.
              </p>
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
};
