import { call, delay, select } from 'redux-saga/effects';

import { RT } from '../../../../shared/types';
import { callModel } from '../';
import { refreshCallDetailsData } from './refreshCallDetailsData';

export function* watchCallDetails() {
  while (true) {
    const currentCall: RT<
      typeof callModel.selectors.selectCurrentCallWithError
    > = yield select(callModel.selectors.selectCurrentCallWithError);

    if (currentCall.isReady) {
      yield delay(5000);
      continue;
    }

    yield call(refreshCallDetailsData);
    yield delay(5000);
  }
}
