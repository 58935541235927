import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import {
  EDITOR_PAGE_ROUTE,
  SNIPPET_EDITOR_ROUTE,
  TEMPLATE_EDITOR_PAGE_ROUTE,
  CALL_PAGE_ROUTE,
  NEW_EDITOR_PAGE_ROUTE,
} from '../entities/history';

import Wrapper from '../entities/loading-wrapper';
import { Loader } from '../shared/ui';
import { AppLayout, SettingsTypes } from '../widgets/app-layout';

import { filter } from 'lodash/collection';
import { get } from 'lodash/object';
import { useSelector } from 'react-redux';
import favicon from '../favicon.svg';
import { pagesModel } from '../features/pages';
import { logger } from '../shared/lib';
import { routes } from './config';
import {
  GENERAL_ROUTES,
  SETTINGS_ROUTES,
  TEAM_SETTINGS_ROUTES,
} from './config/routes';
import { Editor } from './editor';
import { SnippetEditor } from './snippets/ui/snippet-editor/SnippetEditor';
import { CollaborationConnectionProvider } from '../entities/collaboration';
import { Narration } from '../features/narration';
import { CallPage } from './call';
import { NewEditor } from './new-editor';
import { SuggestionsEditorProvider } from '../entities/suggestions-editor';

const { PUBLIC_ROUTES, MAIN_LAYOUT_ROUTES } = routes;

export const Routing: React.FC = () => {
  const currentPage = useSelector(pagesModel.selectors.selectCurrentPage);

  return (
    <>
      <Helmet
        title="Distribute"
        titleTemplate="%s - Distribute"
        defaultTitle="Distribute"
        onChangeClientState={(newState, addedTags) => {
          try {
            const newFaviconElems = filter(get(addedTags, 'linkTags', []), {
              rel: 'icon',
            });
            if (!newFaviconElems.length) {
              return;
            }

            const newFaviconHref = currentPage
              ? `https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${currentPage.content.icon}.png`
              : newFaviconElems[newFaviconElems.length - 1].href;
            const existingFavicons = document.querySelectorAll(
              `link[rel="icon"][data-rh]:not([href="${newFaviconHref}"])`
            );
            if (!existingFavicons.length) {
              return;
            }
            existingFavicons.forEach((e) => e?.parentNode?.removeChild(e));
            const newFaviconElem = document.querySelector(
              `link[rel="icon"][data-rh][href="${newFaviconHref}"]`
            );
            if (newFaviconElem) {
              const parentElem = newFaviconElem.parentNode;
              parentElem?.removeChild(newFaviconElem);
              parentElem?.appendChild(newFaviconElem);
            }
          } catch (e) {
            logger.error(e);
          }
        }}
      >
        <link id="favicon" rel="icon" href={favicon} type="image/x-icon" />
      </Helmet>
      <Switch>
        {Object.entries(PUBLIC_ROUTES).map(([path, render]) => (
          <Route key={path} path={path} render={render} />
        ))}

        <Route path={Object.keys(MAIN_LAYOUT_ROUTES)}>
          <Wrapper>
            <AppLayout>
              <Switch>
                {Object.entries(MAIN_LAYOUT_ROUTES).map(([path, render]) => (
                  <Route key={path} path={path} render={render} />
                ))}
              </Switch>
            </AppLayout>
          </Wrapper>
        </Route>

        <Route path={Object.keys(TEAM_SETTINGS_ROUTES)}>
          <Wrapper>
            <AppLayout settings={SettingsTypes.TEAM_SETTINGS}>
              <Switch>
                {Object.entries(TEAM_SETTINGS_ROUTES).map(([path, render]) => (
                  <Route key={path} path={path} render={render} />
                ))}
              </Switch>
            </AppLayout>
          </Wrapper>
        </Route>

        <Route path={Object.keys(SETTINGS_ROUTES)}>
          <Wrapper>
            <AppLayout settings={SettingsTypes.PROFILE_SETTINGS}>
              <Switch>
                {Object.entries(SETTINGS_ROUTES).map(([path, render]) => (
                  <Route key={path} path={path} render={render} />
                ))}
              </Switch>
            </AppLayout>
          </Wrapper>
        </Route>

        <Route
          path={EDITOR_PAGE_ROUTE}
          render={() => (
            <Wrapper isEditor>
              <CollaborationConnectionProvider>
                <SuggestionsEditorProvider>
                  <NewEditor isTemplateMode={false} />
                </SuggestionsEditorProvider>
                <Narration isNewEditor />
              </CollaborationConnectionProvider>
            </Wrapper>
          )}
        />

        <Route
          path={NEW_EDITOR_PAGE_ROUTE}
          render={() => (
            <Wrapper isEditor>
              <CollaborationConnectionProvider>
                <Editor isTemplateMode={false} />
                <Narration />
              </CollaborationConnectionProvider>
            </Wrapper>
          )}
        />

        <Route
          path={TEMPLATE_EDITOR_PAGE_ROUTE}
          render={() => (
            <Wrapper isTemplateEditor>
              <Editor isTemplateMode={true} />
            </Wrapper>
          )}
        />
        <Route
          path={SNIPPET_EDITOR_ROUTE}
          render={() => (
            <Wrapper>
              <SnippetEditor />
            </Wrapper>
          )}
        />

        <Route
          path={CALL_PAGE_ROUTE}
          render={() => (
            <Wrapper>
              <CallPage />
            </Wrapper>
          )}
        />

        <Route path={Object.keys(GENERAL_ROUTES)}>
          <Wrapper>
            <Switch>
              {Object.entries(GENERAL_ROUTES).map(([path, render]) => (
                <Route key={path} path={path} render={render} />
              ))}
            </Switch>
          </Wrapper>
        </Route>

        <Route path="*" component={Loader} />
      </Switch>
    </>
  );
};

export { ErrorPage } from './error-page';
