import React, { useState } from 'react';
import cn from 'classnames';

import { Badge, CopyDownload, Dropdown, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { getSummaryText } from '../lib';
import { downloadFile } from '../../../shared/lib';
import { analytics } from '../../../entities/analytics';
import {
  AnalyticsEvents,
  CallSummary,
  CallSummaryStatus,
  CallSummaryType,
  CompletedCallSummary,
} from '@distribute/shared/types';

import { useDispatch, useSelector } from 'react-redux';

import { CallSummaryGeneral } from './CallSummaryGeneral';
import { CallSummarySales } from './CallSummarySales';
import { callModel } from '../model';

type CallSummariesProps = {
  summaries: CallSummary[];
  id: string;
  name: string;
};

const summaryTypeLabels: Record<
  string,
  {
    id: CallSummaryType;
    title: string;
    description: string;
    isComingSoon: boolean;
  }
> = {
  [CallSummaryType.GENERAL]: {
    id: CallSummaryType.GENERAL,
    title: 'General summary',
    description: "Capture any call's insights and key takeaways.",
    isComingSoon: false,
  },
  [CallSummaryType.SALES]: {
    id: CallSummaryType.SALES,
    title: 'Sales',
    description: "Unpack a prospect's needs, challenges, and buying journey.",
    isComingSoon: false,
  },
  [CallSummaryType.QA]: {
    id: CallSummaryType.QA,
    title: 'Q&A',
    description: 'Recap questions with answers.',
    isComingSoon: true,
  },

  [CallSummaryType.CUSTOMER_SUCCESS]: {
    id: CallSummaryType.CUSTOMER_SUCCESS,
    title: 'Customer Success',
    description: 'Experiences, challenges, goals, and Q&A.',
    isComingSoon: true,
  },
} as const;

export const CallSummaries: React.FC<CallSummariesProps> = ({
  summaries,
  name,
  id,
}) => {
  const dispatch = useDispatch();
  const isSummaryLoading = useSelector(
    callModel.selectors.selectIsSummaryLoading
  );

  const currentSummaryType = useSelector(
    callModel.selectors.selectCurrentSummaryType
  );
  const [copied, setCopied] = useState(false);

  const handleDownloadTextClick = (summary: CompletedCallSummary) => {
    const text = getSummaryText(summary);
    const type = 'text/plain';
    const filename = `call-summary-${summary.type}-(${name}).txt`;
    const blob = new Blob([text], { type });
    downloadFile(blob, { 'content-type': type }, filename);
  };

  const handleCopyTextClick = async (summary: CompletedCallSummary) => {
    const text = getSummaryText(summary);
    await navigator.clipboard.writeText(text);
    setCopied(true);

    analytics.track(AnalyticsEvents.CALL_SUMMARY_COPIED, { callId: id });
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const onChangeSummary = (type: CallSummaryType) => {
    if (summaries.some((s) => s.type === type)) {
      return;
    }

    dispatch(callModel.actions.generateSummary({ type }));
  };

  const summary = summaries.find(
    (s) =>
      s.type === currentSummaryType && s.status === CallSummaryStatus.COMPLETED
  ) as CompletedCallSummary;

  const summaryItems = Object.values(summaryTypeLabels).map((summary) => ({
    label: (
      <div className="">
        <span
          className={cn('flex items-center gap-1.5', {
            'text-gray-700': !summary.isComingSoon,
          })}
        >
          {summary.title}
          {summary.isComingSoon && (
            <Badge theme="disabled" size="sm" className="!font-normal">
              Soon
            </Badge>
          )}
        </span>
        <span
          className={cn('text-xs', {
            'text-gray-500': !summary.isComingSoon,
          })}
        >
          {summary.description}
        </span>
      </div>
    ),
    id: summary.id,
    headerLabel: summary.title,
    onClick: () =>
      dispatch(callModel.actions.setCurrentSummaryType(summary.id)),
    disabled: summary.isComingSoon,
  }));

  return (
    <div>
      <div className="flex items-center justify-between sticky z-10 bg-base-white top-41 pb-6 pt-4">
        <Dropdown
          currentItemId={currentSummaryType}
          triggerStyles={cn('!w-50', {
            'cursor-progress': isSummaryLoading,
          })}
          align="start"
          listStyles="shadow-lg"
          itemStyles="py-1.5"
          isModalMode={false}
          items={summaryItems}
          isDisabled={isSummaryLoading}
          checkmarkStyles="pl-2"
          onItemChange={(type) => onChangeSummary(type as CallSummaryType)}
        />
        {summary && (
          <CopyDownload
            copied={copied}
            onCopy={() => handleCopyTextClick(summary)}
            onDownload={() => handleDownloadTextClick(summary)}
          />
        )}
      </div>
      {!summary ? (
        <div className="flex flex-col items-center justify-center gap-1.5 text-gray-700 h-90">
          <span className="border border-gray-200 rounded-lg p-3 shadow-xs">
            <Icon glyph={IconMap.Hourglass03} width={24} />
          </span>
          <h2 className="text-md text-gray-900 font-semibold">
            Preparing your summary
          </h2>
          <p className="text-sm text-gray-600">
            Your recording is being analyzed.
          </p>
        </div>
      ) : (
        <>
          {summary.type === CallSummaryType.GENERAL && (
            <CallSummaryGeneral content={summary.content} />
          )}
          {summary.type === CallSummaryType.SALES && (
            <CallSummarySales content={summary.content} />
          )}
        </>
      )}
    </div>
  );
};
