export enum MUX_VIDEO_TYPE {
  RECORD = 'record',
  NARRATION = 'narration',
  CALL = 'call',
}

export type MuxMetadata = {
  duration: number;
  aspectRatio: string;
};
