import { useSelector } from 'react-redux';
import { authUserModel } from '../../../entities/auth-user';
import { FC } from 'react';

export const ChatBotMessageWelcome: FC = () => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  return (
    <p className="text-md text-gray-900 whitespace-pre-wrap">{`Hi, ${currentUser.displayName} 👋\nReady to assist you with anything you need, from answering questions to providing edits and suggestions. Let's get started!`}</p>
  );
};
