export const AUTH_ROUTE = '/auth';
export const INDEX_ROUTE = '/';
export const LOADING_ROUTE = '/loading';

export const NOT_FOUND_ROUTE = '/404';
export const CLOSE_WINDOW_ROUTE = '/close';

export const REGISTER_APP_SUMO_ROUTE = '/register-app-sumo';
export const SUCCESSFULLY_CREATED_ACCOUNT_ROUTE =
  '/successfully-created-account';

export const REGISTER_ROUTE = '/register';
export const LOGIN_ROUTE = '/login';
export const VERIFY_EMAIL_ROUTE = '/verify-email';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const ONBOARDING_ROUTE = '/onboarding';
export const COMPLETE_PASSWORD_RESET_ROUTE = '/complete-reset-password';
export const EXPIRED_INVITATION_ROUTE = '/expired-invitation';
export const WORKSPACE_ROUTE = '/workspace';
export const PAGES_OVERVIEW_ROUTE = '/overview';
export const WORKSPACE_FOLDER_ROUTE = '/workspace/:sequenceNumber';
export const WORKSPACE_ANALYTICS_ROUTE = '/workspace/:sequenceNumber/analytics';
export const WORKSPACE_LEADS_ROUTE = '/workspace/:sequenceNumber/leads';
export const WORKSPACE_RECENT_VIEWERS_ROUTE =
  '/workspace/:sequenceNumber/recent-viewers';

export const EDITOR_PAGE_ROUTE = '/editor/:sequenceNumber';
export const TEMPLATE_EDITOR_PAGE_ROUTE = '/template/:sequenceNumber';
export const SNIPPET_EDITOR_ROUTE = '/snippet/:sequenceNumber';

export const NEW_EDITOR_PAGE_ROUTE = '/new-editor/:sequenceNumber';

export const SETTINGS_ROUTE = '/settings';
export const SETTINGS_PROFILE_ROUTE = '/settings/profile';
export const SETTINGS_INTEGRATIONS_ROUTE = '/settings/integrations';
export const SETTINGS_SUPERUSER_ROUTE = '/settings/superuser';

export const TEAM_SETTINGS_ROUTE = '/team-settings';
export const TEAM_SETTINGS_INFO_ROUTE = '/team-settings/info';
export const TEAM_SETTINGS_BRAND_ROUTE = '/team-settings/brand';
export const TEAM_SETTINGS_CALL_RECORDINGS = '/team-settings/call-recordings';
export const TEAM_SETTINGS_STYLES_ROUTE = '/team-settings/styles';
export const TEAM_SETTINGS_MEMBERS_ROUTE = '/team-settings/members';
export const TEAM_SETTINGS_SUBSCRIPTION_ROUTE = '/team-settings/subscription';
export const TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE =
  '/team-settings/permissions-control';
export const TEAM_LEFT_ROUTE = '/team-left';
export const CONFIRM_DELETE_TEAM_ROUTE = '/confirm-delete-team/:teamId/:hash';
export const ACCEPT_TEAM_INVITATION_ROUTE = '/accept-team-invitation/:inviteId';
export const ACCEPT_PAGE_INVITATION_ROUTE =
  '/accept-page-invitation/:pageInviteId/:teamInviteId';
export const ACCEPT_REQUEST_TO_EDIT_PAGE_ROUTE =
  '/accept-request-to-edit-page/:requestId';
export const DECLINE_REQUEST_TO_EDIT_PAGE_ROUTE =
  '/decline-request-to-edit-page/:requestId';

export const LEGAL_PRIVACY_POLICY_ROUTE = '/legal/privacy-policy';
export const LEGAL_TERMS_OF_USE_ROUTE = '/legal/terms-of-use';

export const UI_COMPONENTS_PAGE = '/components';

export const PUBLIC_TEMPLATE_ROUTE = '/public-template/:pageId';

export const LINKEDIN_CALLBACK_ROUTE = '/linkedin-callback';
export const TWITTER_CALLBACK_ROUTE = '/twitter-callback';
export const GETTING_STARTED_ROUTE = '/getting-started';

export const TEMPLATES_ROUTE = '/templates';
export const TEMPLATES_APPLY_ROUTE = '/templates/apply/:templateId';
export const TEMPLATES_PERSONAL_ROUTE = '/templates/personal';
export const TEMPLATES_TEAM_ROUTE = '/templates/team';
export const TEMPLATES_DRAFTS_ROUTE = '/templates/drafts';

export const SNIPPETS_ROUTE = '/snippets';
export const SNIPPETS_PERSONAL_ROUTE = '/snippets/personal';
export const SNIPPETS_TEAM_ROUTE = '/snippets/team';
export const SNIPPETS_DRAFTS_ROUTE = '/snippets/drafts';

export const RECORDINGS_ROUTE = '/recordings';
export const QUICK_RECORDINGS_ROUTE = '/quick-recordings';
export const QUICK_RECORDINGS_MY_ROUTE = '/quick-recordings/my';
export const QUICK_RECORDINGS_SHARED_ROUTE = '/quick-recordings/shared';
export const QUICK_RECORDING_ROUTE = '/quick-recording/:prefix';

export const CALL_RECORDINGS_ROUTE = '/call-recordings';
export const CALL_PAGE_ROUTE = '/call/:id';

const IFRAME_ROUTE = '/iframe';
const TEMPLATES_IFRAME_ROUTE = `${IFRAME_ROUTE}/templates-iframe`;
export const TEMPLATES_IFRAME_TEMPLATES_ROUTE = `${TEMPLATES_IFRAME_ROUTE}/templates`;
export const TEMPLATES_IFRAME_PREVIEW_ROUTE = `${TEMPLATES_IFRAME_ROUTE}/preview/:id`;
export const TEMPLATES_IFRAME_MENU_ROUTE = `${TEMPLATES_IFRAME_ROUTE}/menu`;
export const TEMPLATES_IFRAME_DEMO_ROUTE = `${TEMPLATES_IFRAME_ROUTE}/demo`;
