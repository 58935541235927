import React, { FC, KeyboardEvent, useRef, useState, ChangeEvent } from 'react';
import classNames from 'classnames';
import { IconMap } from '../../../../../../../shared/sprite';
import { Icon, RenderList, TimeAgo } from '../../../../../../../shared/ui';
import { useOnClickOutside } from '../../../../../../../shared/hooks/useClickOutside';
import { contains } from '../../../../../../../utils/string';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useSelector } from 'react-redux';
import { callModel } from '../../../../../../../features/call';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CreatePageFormSchemaType } from '../../../../../../../processes/page-creation-flow/config/types';
import { CallBasicInfo } from '@distribute/shared/api-types/call';

export const CallRecordingsPicker: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setOpen] = useState(false);

  const inputElement = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLUListElement>(null);

  const { control, watch } = useFormContext<CreatePageFormSchemaType>();

  const { append: appendCall, remove: removeCall } = useFieldArray({
    control,
    name: 'selectedCallRecordings',
  });

  const selectedCalls = watch('selectedCallRecordings') as CallBasicInfo[];

  const groupedCalls = useSelector(callModel.selectors.selectGroupedCalls);

  const filteredPersonalCalls = groupedCalls.personal.filter(
    (el) =>
      contains(el.name, searchValue) &&
      !selectedCalls.some((item) => item.id === el.id)
  );

  const filteredTeamCalls = groupedCalls.team.filter(
    (el) =>
      contains(el.name, searchValue) &&
      !selectedCalls.some((item) => item.id === el.id)
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (![' ', ','].includes(value)) {
      setSearchValue(value);
    }
  };

  const handleFocusInput = () => {
    inputElement.current?.focus();
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (['Backspace', 'Delete'].includes(e.code) && !searchValue.trim()) {
      removeCall(selectedCalls.length - 1);
    }
  };

  const handleSelect = (item: CallBasicInfo) => {
    appendCall(item);
    setSearchValue('');
    handleFocusInput();
  };

  const handleDeleteTag = (id: string) => {
    const index = selectedCalls.findIndex((el) => el.id === id);
    if (index === -1) return;
    removeCall(index);
  };

  useOnClickOutside(containerRef, () => setOpen(false), undefined, [
    contentRef,
  ]);

  const handleToggleDropdown = (isOpen: boolean) => {
    if (!isOpen && inputElement.current !== document.activeElement) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <div className="relative">
      <DropdownMenu.Root
        modal={false}
        open={isOpen}
        onOpenChange={handleToggleDropdown}
      >
        <DropdownMenu.Trigger className="w-full cursor-text">
          <div
            onClick={handleFocusInput}
            className={classNames(
              'group/input flex flex-wrap items-center gap-1.5 border border-gray-300 rounded-lg bg-base-white pl-2 py-[5px] min-h-10 overflow-auto relative',
              { '!border-primary-600': isOpen }
            )}
            data-container
            ref={containerRef}
          >
            {selectedCalls.map(({ id, name }) => (
              <div
                key={id}
                className={classNames(
                  'flex gap-1.5 items-center bg-base-white border border-gray-300 rounded-md px-1.5 py-0.5 text-sm font-medium text-gray-700 overflow-hidden'
                )}
              >
                <Icon glyph={IconMap.HeadPhones02} width={14} />
                <span className="text-gray-900 text-sm font-medium truncate">
                  {name}
                </span>
                <Icon
                  glyph={IconMap.XClose}
                  width={14}
                  className="shrink-0 text-gray-400 cursor-pointer"
                  onClick={() => handleDeleteTag(id)}
                />
              </div>
            ))}
            <input
              ref={inputElement}
              value={searchValue}
              size={searchValue.length + 10}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              className="text-md text-gray-900 py-1 outline-none ml-1"
            />
            {!searchValue && !selectedCalls.length && (
              <p className="absolute left-4 top-3 -mt-px text-gray-500 pointer-events-none">
                Start typing call recording name ...
              </p>
            )}
          </div>
        </DropdownMenu.Trigger>

        {isOpen && (
          <ul
            ref={contentRef}
            className="bg-base-white rounded-lg border border-gray-200 shadow-lg py-1 px-1.5 absolute z-40 w-full max-h-60 top-[calc(100%+4px)] left-0 overflow-scroll"
          >
            {!filteredPersonalCalls.length && !filteredTeamCalls.length && (
              <p className="px-2.5 py-2 text-gray-500 font-medium text-sm">
                No results
              </p>
            )}
            <RenderList list={filteredPersonalCalls}>
              <p className="uppercase text-xs font-bold text-gray-500 pt-2 pl-2">
                Your calls
              </p>
              {filteredPersonalCalls.map((item) => (
                <CallItem item={item} handleClick={() => handleSelect(item)} />
              ))}
            </RenderList>

            <RenderList list={filteredTeamCalls}>
              <p className="uppercase text-xs font-bold text-gray-500 pt-2 pl-2">
                Team calls
              </p>
              {filteredTeamCalls.map((item) => (
                <CallItem item={item} handleClick={() => handleSelect(item)} />
              ))}
            </RenderList>
          </ul>
        )}
      </DropdownMenu.Root>
    </div>
  );
};

type CallItemProps = {
  item: CallBasicInfo;
  handleClick: () => void;
};

const CallItem: React.FC<CallItemProps> = ({ item, handleClick }) => {
  return (
    <li
      key={item.id}
      className="py-2.5 pl-2 pr-2.5 rounded-md hover:bg-gray-50 cursor-pointer flex items-center gap-2"
      onClick={handleClick}
    >
      <Icon glyph={IconMap.HeadPhones02} width={16} />
      <p className="text-gray-700 font-medium text-sm">{item.name}</p>
      <TimeAgo
        datetime={item.createdAt}
        locale="my"
        className="ml-auto text-sm text-gray-500"
      />
    </li>
  );
};
