import { useDispatch } from 'react-redux';
import { Button, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { subscriptionModel } from '../../subscription';
import { useSubscriptionLimits } from '../../subscription/hooks';
import { GetCallDetailResponse } from '@distribute/shared/api-types/call';
import { CallSummaries } from './CallSummaries';

type CallSummaryProps = {
  call: GetCallDetailResponse;
};

export const CallSummaryView: React.FC<CallSummaryProps> = ({ call }) => {
  const dispatch = useDispatch();

  const handleUpgradeClick = () => {
    dispatch(subscriptionModel.actions.setIsShowPaywallModal(true));
  };

  const { summaries } = call;
  const { isCallSummaryEnabled } = useSubscriptionLimits();

  if (!isCallSummaryEnabled) {
    return (
      <div className="mt-6 flex flex-col items-center justify-center gap-1.5 text-gray-700 h-90">
        <h2 className="text-md text-gray-900 font-semibold">
          You are on free plan
        </h2>
        <p className="text-sm text-gray-600 pb-6">
          Please upgrade to see you call summary.
        </p>
        <Button variant="text" color="primary" onClick={handleUpgradeClick}>
          Upgrade
        </Button>
      </div>
    );
  }

  if (summaries.length <= 0) {
    return (
      <div className="mt-6 flex flex-col items-center justify-center gap-1.5 text-gray-700 h-90">
        <span className="border border-gray-200 rounded-lg p-3 shadow-xs">
          <Icon glyph={IconMap.Hourglass03} width={24} />
        </span>
        <h2 className="text-md text-gray-900 font-semibold">
          Preparing your summary
        </h2>
        <p className="text-sm text-gray-600">
          Your recording is being analyzed.
        </p>
      </div>
    );
  }

  return (
    <CallSummaries summaries={call.summaries} name={call.name} id={call.id} />
  );
};
