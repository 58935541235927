import { cn } from '@distribute/frontend/utils';
import { IconMap } from '../../../../../../shared/sprite';
import { Icon } from '../../../../../../shared/ui';

type TriggerProps = {
  title: string;
  isDone: boolean;
  description?: string[];
  isOpened: boolean;
};
export const Trigger = ({
  title,
  isDone,
  description,
  isOpened,
}: TriggerProps) => {
  return (
    <div className="text-left flex gap-3">
      <div>
        <Icon
          glyph={isDone ? IconMap.CheckCircleBroken : IconMap.PlaceholderDashed}
          className={cn('text-gray-400', isDone && 'text-primary-600')}
          width={24}
        />
      </div>
      <div>
        <h3 className="text-base font-semibold ">{title}</h3>
        {description && !isOpened && (
          <p className="capitalize text-sm text-gray-500 font-normal mt-1">
            {description.join(' • ')}
          </p>
        )}
      </div>
    </div>
  );
};
