import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Page } from '@distribute/shared/types';
import { EditorHeader, HeaderTabs } from './EditorHeader';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { cn } from '@distribute/frontend/utils';
import { useCollaboration } from '../../../../entities/collaboration';
import { Analytics } from './analytics-tab';
import { CreateTab } from './create-tab';

type Props = {
  isPreviewMode: boolean;
  children: React.ReactNode;
  currentPageOrTemplate: Page | TemplateExtended;
  isTemplateMode: boolean;
  isCanEdit: boolean;
  isSupportMultiTabs: boolean;
};

export const EditorWrapper: React.FC<Props> = ({
  isPreviewMode,
  children,
  currentPageOrTemplate,
  isTemplateMode,
}) => {
  const faviconHref = `https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${currentPageOrTemplate?.content.icon}.png`;

  const { isCollaborationEnabled, title } = useCollaboration();

  const pageTitle =
    isCollaborationEnabled && !isTemplateMode
      ? title
      : currentPageOrTemplate.content.title;

  const [selectedHeaderTab, setSelectedHeaderTab] = useState<string>(
    HeaderTabs.CREATE
  );

  return (
    <div className="relative flex flex-col h-screen overflow-y-hidden">
      <Helmet
        titleTemplate={`${pageTitle ? pageTitle : 'Untitled'} - Distribute`}
      >
        {!isTemplateMode && (currentPageOrTemplate as Page).isBranded ? null : (
          <link
            id="favicon"
            rel="icon"
            href={faviconHref}
            type="image/x-icon"
          />
        )}
      </Helmet>

      <EditorHeader
        currentPageOrTemplate={currentPageOrTemplate}
        isTemplateMode={isTemplateMode}
        selectedHeaderTab={selectedHeaderTab}
        setSelectedHeaderTab={setSelectedHeaderTab}
      />
      <div
        className={cn(
          'flex gap-2 bg-gray-100 h-[calc(100vh-52px)] p-2 border-t border-base-black-4',
          {
            'justify-center': selectedHeaderTab === HeaderTabs.ANALYTICS,
          }
        )}
      >
        {isPreviewMode ? (
          children
        ) : selectedHeaderTab === HeaderTabs.CREATE ? (
          <CreateTab>{children}</CreateTab>
        ) : (
          <Analytics />
        )}
      </div>
    </div>
  );
};
