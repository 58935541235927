import { ChatStructuredData } from '@distribute/shared/types';
import { chatsApi } from '../../../../shared/api/axios/chat';
import { RT } from '../../../../shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsModel } from '../../../teams';
import { call, put, select } from 'redux-saga/effects';

export function* saveChatMessage({
  data,
  structuredData,
  chatId,
}: {
  data: string;
  structuredData: ChatStructuredData[];
  chatId: string;
}) {
  try {
    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    yield call(chatsApi.saveChatMessage, {
      id: chatId,
      teamId: currentTeam.id,
      data,
      structuredData,
    });
  } catch (error) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to save chat message')
      )
    );
  }
}
