import {
  ChatStructuredSuggestionData as _ChatStructuredSuggestionData,
  ChatStructuredCommentData,
} from '@distribute/shared/types';

import { CUSTOM_TAG } from './config';
import { parseComment, parseTab } from './utils';

interface DOMParserInterface {
  parseFromString(text: string, mimeType: string): Document;
}

interface DocumentInterface {
  createTreeWalker(
    root: Node,
    whatToShow: number,
    filter: NodeFilter | null,
    entityReferenceExpansion?: boolean
  ): TreeWalker;
}

export type ChatStructuredSuggestionData = Omit<
  _ChatStructuredSuggestionData,
  'contentDiff'
>;

export type ParseChatStructuredData =
  | ChatStructuredCommentData
  | ChatStructuredSuggestionData;

export type ParseChatResponseResult = {
  data: ParseChatStructuredData[] | null;
  errors: Error[];
  warnings: string[];
};

/**
 * Parses the chat response text and returns structured data.
 * This function is designed to handle both complete and partial streaming data.
 */
export const parseChatResponse = (
  text: string,
  domParser: DOMParserInterface = new DOMParser()
): ParseChatResponseResult => {
  const items: ParseChatStructuredData[] = [];
  const errors: Error[] = [];
  const warnings: string[] = [];

  try {
    // Use the provided DOM parser to parse the HTML-like structure
    const doc = domParser.parseFromString(text, 'text/html');

    // Create a TreeWalker to traverse the DOM in document order
    const treeWalker = (doc as unknown as DocumentInterface).createTreeWalker(
      doc.documentElement,
      NodeFilter.SHOW_ELEMENT,
      {
        acceptNode: (node) =>
          [CUSTOM_TAG.COMMENT, CUSTOM_TAG.TAB].includes(
            node.nodeName.toUpperCase()
          )
            ? NodeFilter.FILTER_ACCEPT
            : NodeFilter.FILTER_SKIP,
      }
    );

    // Traverse the DOM tree in document order
    let currentNode = treeWalker.nextNode();

    while (currentNode) {
      if (!(currentNode instanceof Element)) {
        // Move to the next node
        currentNode = treeWalker.nextNode();
        continue;
      }

      const nodeName = currentNode.nodeName.toUpperCase();

      if (nodeName === CUSTOM_TAG.COMMENT) {
        const item = parseComment(currentNode);
        items.push(item);
      } else if (nodeName === CUSTOM_TAG.TAB) {
        const item = parseTab(currentNode);
        if (item.data) {
          items.push(item.data);
        }
        if (item.error) {
          errors.push(item.error);
        }
        if (item.warning) {
          warnings.push(item.warning);
        }
      }

      // Move to the next node
      currentNode = treeWalker.nextNode();
    }

    return {
      data: items,
      errors,
      warnings: [],
    };
  } catch (e: unknown) {
    return {
      data: null,
      errors: [e as Error],
      warnings: [],
    };
  }
};
