import { JSONContent } from '@tiptap/core';
import { hasOperation } from './utils';

/**
 * Result interface for reorganization operations
 */
interface ReorganizeResult {
  node: JSONContent;
  changed: boolean;
}

/**
 * Recursively reorganize a node by moving operation attributes up to parent nodes
 * when all children have the same operation type.
 *
 * @param {JSONContent} node - The current node.
 * @returns {ReorganizeResult} - The reorganized node and a flag indicating change.
 */
function reorganizeWithAttrs(node: JSONContent): ReorganizeResult {
  let changed = false;

  // If there's no content or it's not an array, return the node as is.
  if (!node.content || !Array.isArray(node.content)) {
    return { node, changed };
  }

  // Process each child and update content.
  const newContent: JSONContent[] = [];
  for (const child of node.content) {
    const result = reorganizeWithAttrs(child);
    newContent.push(result.node);
    if (result.changed) {
      changed = true;
    }
  }
  node.content = newContent;

  // don't wrap the whole document
  if (node.type === 'doc') return { node, changed };

  // Check if all children are "insert" or all are "delete".
  const allInsert =
    node.content.length > 0 &&
    node.content.every((child) => hasOperation(child, 'insert'));

  const allDelete =
    node.content.length > 0 &&
    node.content.every((child) => hasOperation(child, 'delete'));

  // Check if some children are "insert" or some are "delete" or some are "edit".
  const someInsert =
    node.content.length > 0 &&
    node.content.some((child) => hasOperation(child, 'insert'));

  const someDelete =
    node.content.length > 0 &&
    node.content.some((child) => hasOperation(child, 'delete'));

  const someEdit =
    node.content.length > 0 &&
    node.content.some((child) => child.attrs?.op === 'edit');

  if (allInsert || allDelete) {
    // Use the type from the children (they're all the same)
    const op = (node.content[0].attrs?.op ||
      node.content[0].marks?.find(({ type }) => type === 'diff')?.attrs?.op) as
      | 'insert'
      | 'delete';

    // Remove marking attr on children
    for (const child of node.content) {
      if (child.attrs) {
        delete child.attrs.op;
        if (Object.keys(child.attrs).length === 0) delete child.attrs;
      }
      if (child.marks) {
        child.marks = child.marks.filter(
          ({ type, attrs }) => !(type === 'diff' && attrs?.op === op)
        );
        if (child.marks.length === 0) delete child.marks;
      }
    }

    node.attrs = { ...node.attrs, op };

    changed = true;
  } else if (someInsert || someDelete || someEdit) {
    node.attrs = { ...node.attrs, op: 'edit' };
  }

  return { node, changed };
}

const reorganize = reorganizeWithAttrs;

/**
 * Apply the reorganization repeatedly until no changes occur.
 *
 * @param {JSONContent} root - The root of the tree.
 * @returns {JSONContent} - The fully reorganized tree.
 */
function reorganizeTree(root: JSONContent): JSONContent {
  let result = reorganize(root);
  while (result.changed) {
    result = reorganize(result.node);
  }
  return result.node;
}

export { reorganizeTree };
