import { Description } from './Description';
import { Title } from './Title';
import cn from 'classnames';

type Props = {
  title: string;
  description: string;
  imageSrc: string;
  isVisible: boolean;
};

export const PageAppearance = ({
  title,
  description,
  imageSrc,
  isVisible,
}: Props) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center gap-4 h-full text-center',
        { hidden: !isVisible }
      )}
      style={{
        background:
          'url(../../../assets/images/grid-background.svg) no-repeat center / contain',
      }}
    >
      <div>
        <Title className="mb-1">{title}</Title>
        <Description>{description}</Description>
      </div>
      <img
        className="w-full max-w-130"
        src={imageSrc}
        alt={`${title} appearance`}
      />
    </div>
  );
};
