import React from 'react';
import { Input, Select } from '../../../shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { foldersModel, getFolderPermissions } from '../../folders';
import { Folder } from '@distribute/shared/types';
import { pagesModel } from '../model';
import { authUserModel } from '../../../entities/auth-user';
import { useTeamPermissions } from '../../teams';

type ChooseFolderForm = {
  folderId: string;
  folderName?: string;
};

type Props = {
  formId?: string;
  onSubmit: (data: ChooseFolderForm) => void;
};

const validationSchema = object().shape({
  folderId: string().required('Folder id is required'),
  folderName: string().when('folderId', {
    is: 'newFolder',
    then: string().required('Folder Name is required'),
  }),
});

export const SelectFolderForm: React.FC<Props> = ({
  onSubmit,
  formId = 'select-folder-form',
}) => {
  const isLoading = useSelector(pagesModel.selectors.selectCreatePageIsLoading);

  const folders: Folder[] = useSelector(foldersModel.selectors.selectFolders);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ChooseFolderForm>({
    resolver: yupResolver(validationSchema),
  });

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const teamPermissions = useTeamPermissions();

  const filteredFolders = folders.filter((folder) => {
    const folderPermissions = getFolderPermissions(
      currentUser,
      folder,
      teamPermissions
    );
    return folderPermissions.isCanCreatePages;
  });

  const foldersList = [
    ...filteredFolders,
    { title: 'New Folder...', id: 'newFolder' },
  ];

  const {
    field: { value, onChange },
  } = useController({
    control,
    defaultValue: foldersList[0].id,
    name: 'folderId',
    rules: { required: true },
  });

  const currentValue = foldersList.find((i) => i.id === value);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formId}>
      <Select
        label="Folder"
        value={value}
        onChange={onChange}
        items={foldersList}
        currentValue={currentValue}
      />
      {value === 'newFolder' && (
        <div className="mt-3">
          <Input
            label="Folder Name"
            {...register('folderName')}
            isError={!!errors.folderName}
            messageText={errors.folderName?.message}
            type="text"
          />
        </div>
      )}
    </form>
  );
};
