import { RootState } from '../../../app';

export const selectIsLoading = ({ ai }: RootState) => ai.isLoading;
export const selectError = ({ ai }: RootState) => ai.error;
export const selectResponses = ({ ai }: RootState) => ai.responses;
export const selectIsAIInputRendered = ({ ai }: RootState) =>
  ai.isAIInputRendered;
export const selectIsGeneratingMultiTabs = ({ ai }: RootState) =>
  ai.isGeneratingMultiTabs;
export const selectIsStructuredResponse = ({ ai }: RootState) =>
  ai.isStructuredResponse;
