import { createAction } from '@reduxjs/toolkit';
import {
  RedirectToWorkspaceFolderAction,
  RedirectToEditorPageAction,
  RedirectToTemplateEditorPageAction,
  RedirectFromWorkspaceWithBackUrl,
  RedirectToCallPageAction,
} from './types';

export const redirectActions = {
  toRegisterClick: createAction<void>('redirect/toRegisterClick'),
  toLoginClick: createAction<void>('redirect/toLoginClick'),
  toSignup: createAction<void>('redirect/toSignup'),
  toOnboardingClick: createAction<void>('redirect/toOnboardingClick'),
  toWorkspace: createAction<void>('redirect/toWorkspace'),
  toPagesOverview: createAction<void>('redirect/toPagesOverview'),
  toWorkspaceFolder: createAction<RedirectToWorkspaceFolderAction>(
    'redirect/toWorkspaceFolder'
  ),
  toEditorPage: createAction<RedirectToEditorPageAction>(
    'redirect/toEditorPage'
  ),
  toTemplateEditorPage: createAction<RedirectToTemplateEditorPageAction>(
    'redirect/toTemplateEditorPage'
  ),
  toSubscriptionsClick: createAction<void>('redirect/toSubscriptionsClick'),
  toSettingsClick: createAction<void>('redirect/toSettingsClick'),
  toEmailVerificationClick: createAction<void>(
    'redirect/toEmailVerificationClick'
  ),
  toWorkspaceByBackUrl: createAction<void>('redirect/toWorkspaceByBackUrl'),
  fromWorkspaceWithBackUrl: createAction<RedirectFromWorkspaceWithBackUrl>(
    'redirect/fromWorkspaceWithBackUrl'
  ),
  toCallPage: createAction<RedirectToCallPageAction>('redirect/toCallPage'),
};
