import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getParams } from '@distribute/shared/utils';

import { navigate } from '../../../processes/navigation';

import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../model';
import { Button, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { pageCreationFlowModel } from '../../../processes/page-creation-flow';

export const CreatePageButtons: React.FC = () => {
  const { search, pathname } = useLocation();

  const dispatch = useDispatch();

  const isLoading = useSelector(pagesModel.selectors.selectCreatePageIsLoading);
  const isNewPageFlowLoading = useSelector(
    pageCreationFlowModel.selectors.selectCreatePageIsLoading
  );

  const handleCreateNewPage = () => {
    dispatch(pageCreationFlowModel.actions.openPageCreationFlow({}));
  };

  const handleBrowseTemplates = () => {
    dispatch(
      pageCreationFlowModel.actions.openPageCreationFlow({ mode: 'template' })
    );
  };

  useEffect(() => {
    if (getParams(search).get('create-page') === '1') {
      handleCreateNewPage();
      dispatch(navigate({ to: pathname, isKeepQueryParams: false }));
    }

    //eslint-disable-next-line
  }, [search, pathname]);

  return (
    <div className="flex items-center gap-4 sm:hidden">
      <Button
        variant="text"
        color="secondary"
        size="md"
        onClick={handleBrowseTemplates}
        disabled={isLoading}
        className="font-semibold shadow-xs"
      >
        Browse Templates
      </Button>
      <Button
        variant="text"
        color="primary"
        size="md"
        className="font-semibold shadow-xs"
        onClick={handleCreateNewPage}
        loading={isNewPageFlowLoading}
      >
        <Icon glyph={IconMap.Plus} width={20} className="mr-1.5" />
        Create new page
      </Button>
    </div>
  );
};
