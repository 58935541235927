import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { conversionKitModel } from '../../../../../conversion-kit';
import { BlockingMethodType } from '@distribute/shared/types';
import { boolean, object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LabelToggle } from '../../../../../../shared/ui/LabelToggle';
import { IconMap } from '../../../../../../shared/sprite';
import { Button, Input, PanelRadioGroup } from '../../../../../../shared/ui';
import { Icon } from '../../../../../../shared/ui';
import { RichTextEditor } from '../../../../../../entities/tiptap-editor';
import { useCollaboration } from '../../../../../../entities/collaboration';
import { Label } from '../shared';
import { editorLeftSidebarModel } from '../../../../model';
import { SidebarTabLayout } from '../../shared/SidebarTabLayout';

const TITLE_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 200;
const BUTTON_LABEL_MAX_LENGTH = 60;

export type GatedContentType = {
  title: string;
  description: string;
  isFormName: boolean;
  isFormPhone: boolean;
  buttonLabelFormType: string;
  blockingMethod: BlockingMethodType;
};

const validationSchema = object().shape({
  title: string().max(TITLE_MAX_LENGTH).required(),
  description: string().max(DESCRIPTION_MAX_LENGTH),
  isFormName: boolean(),
  isFormPhone: boolean(),
  buttonLabelFormType: string(),
  blockingMethod: string()
    .oneOf([BlockingMethodType.OBSCURATION, BlockingMethodType.REMOVAL])
    .required(),
});

const blockingMethodOptions = [
  {
    title: 'Removal',
    description: 'Page content are hidden on page.',
    id: BlockingMethodType.REMOVAL,
  },
  {
    title: 'Obscuration',
    description: 'Page content are visible but blurred.',
    id: BlockingMethodType.OBSCURATION,
  },
];

export const GatedContentPanel: React.FC = () => {
  const dispatch = useDispatch();

  const updateGatedContentIsLoading = useSelector(
    conversionKitModel.selectors.selectUpdateCTAIsLoading
  );

  const handleClose = () => {
    dispatch(editorLeftSidebarModel.actions.setActiveConversionPanel(null));
    dispatch(conversionKitModel.actions.setGatedContentEdited(undefined));
  };

  const { gatedContentData, updateGatedContentField } = useCollaboration();

  const {
    title,
    description,
    isFormName,
    isFormPhone,
    buttonLabelFormType,
    pagePercentage,
    blockingMethod,
    isDescriptionEnabled,
  } = gatedContentData;

  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContentWithError
  );
  const gatedContentEdited = useSelector(
    conversionKitModel.selectors.selectGatedContentEdited
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<GatedContentType>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({
        title,
        isFormName,
        isFormPhone,
        buttonLabelFormType,
        blockingMethod,
      }),
      [blockingMethod, buttonLabelFormType, isFormName, isFormPhone, title]
    ),
  });

  const {
    field: { onChange: onChangeTitle },
  } = useController({
    control,
    name: 'title',
  });

  const handleChangeTitle = (value: string) => {
    onChangeTitle(value);
    updateGatedContentField('title', value);
  };

  const {
    field: { onChange: onChangeIsFormName },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormName',
  });

  const handleChangeIsFormName = (value: boolean) => {
    onChangeIsFormName(value);
    updateGatedContentField('isFormName', value);
  };

  const {
    field: { onChange: onChangeIsFormPhone },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormPhone',
  });

  const handleChangeIsFormPhone = (value: boolean) => {
    onChangeIsFormPhone(value);
    updateGatedContentField('isFormPhone', value);
  };

  const {
    field: { onChange: onChangeButtonLabelFormType },
  } = useController({
    control,
    name: 'buttonLabelFormType',
  });

  const handleChangeButtonLabelFormType = (value: string) => {
    onChangeButtonLabelFormType(value);
    updateGatedContentField('buttonLabelFormType', value);
  };

  const {
    field: { onChange: onChangeBlockingMethod },
  } = useController({
    control,
    name: 'blockingMethod',
  });

  const handleChangeBlockingMethod = (value: BlockingMethodType) => {
    onChangeBlockingMethod(value);
    updateGatedContentField('blockingMethod', value);
  };

  useEffect(() => {
    dispatch(
      conversionKitModel.actions.setGatedContentEdited({
        ...gatedContent,
        title: title ?? '',
        description,
        isFormName: isFormName ?? false,
        isFormPhone: isFormPhone ?? false,
        buttonLabelFormType: buttonLabelFormType ?? '',
        isDescriptionEnabled: isDescriptionEnabled ?? false,
        blockingMethod: blockingMethod ?? BlockingMethodType.OBSCURATION,
        pagePercentage:
          gatedContentEdited?.pagePercentage || gatedContent.pagePercentage,
      })
    );
  }, [
    dispatch,
    gatedContent,
    title,
    description,
    isFormName,
    isFormPhone,
    buttonLabelFormType,
    isDescriptionEnabled,
    blockingMethod,
    gatedContentEdited?.pagePercentage,
  ]);

  const handleFormSubmit = () => {
    updateGatedContentField('isActive', true);
    updateGatedContentField(
      'description',
      isDescriptionEnabled ? description ?? '' : ''
    );
    updateGatedContentField(
      'buttonLabelFormType',
      buttonLabelFormType || 'Submit'
    );
    updateGatedContentField('pagePercentage', Math.round(pagePercentage || 0));
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full h-full">
      <SidebarTabLayout
        headerComponent={
          <div>
            <div className="flex justify-between w-full mb-4">
              <Button color="secondary" variant="icon" onClick={handleClose}>
                <Icon glyph={IconMap.ArrowLeft} width={20} />
              </Button>

              <Button
                variant="text"
                color="primary"
                type="submit"
                loading={updateGatedContentIsLoading}
              >
                Save
              </Button>
            </div>
            <h2 className="text-2xl font-semibold text-gray-900">
              Gated Content
            </h2>
          </div>
        }
      >
        <p className="mb-4 font-semibold text-gray-800">Components</p>

        <LabelToggle
          icon={IconMap.Type01}
          text="Title"
          isRequired
          checked={true}
          onChange={() => null}
          className="mt-6 mb-3"
          disabled
        />
        <Input
          className="p-3"
          heightSize="md"
          type="text"
          placeholder="Add title"
          maxCharacters={TITLE_MAX_LENGTH}
          isError={!!errors.title}
          value={title}
          onChange={({ target: { value } }) => handleChangeTitle(value)}
        />
        <LabelToggle
          icon={IconMap.AlignLeft}
          text="Description"
          checked={isDescriptionEnabled}
          onChange={() =>
            updateGatedContentField(
              'isDescriptionEnabled',
              !isDescriptionEnabled
            )
          }
          className="mt-6 mb-3"
        />
        {isDescriptionEnabled && (
          <RichTextEditor
            content={description ?? ''}
            placeholder="Add text…"
            onUpdate={(value) => updateGatedContentField('description', value)}
            maxLength={DESCRIPTION_MAX_LENGTH}
          />
        )}
        <hr className="my-6 border-gray-200" />

        <p className="mb-4 font-semibold text-gray-800">Form Fields</p>
        <LabelToggle
          icon={IconMap.User03}
          text="Name"
          checked={isFormName}
          onChange={handleChangeIsFormName}
          className="mb-3"
        />
        <LabelToggle
          icon={IconMap.EmailSymbol}
          text="Email"
          checked={true}
          onChange={() => null}
          disabled
          className="mb-3"
        />
        <LabelToggle
          icon={IconMap.Phone}
          text="Phone"
          checked={isFormPhone}
          onChange={handleChangeIsFormPhone}
          className="mb-3"
        />
        <hr className="my-6 border-gray-200" />
        <p className="mb-4 font-semibold text-gray-800">Button</p>
        <Label icon={IconMap.TypeSquare} text="Label" className="mb-3" />
        <Input
          maxCharacters={BUTTON_LABEL_MAX_LENGTH}
          className="p-3"
          type="text"
          placeholder="Submit"
          {...register('buttonLabelFormType')}
          isError={!!errors.buttonLabelFormType}
          value={buttonLabelFormType}
          heightSize="md"
          onChange={({ target: { value } }) =>
            handleChangeButtonLabelFormType(value)
          }
        />
        <p className="mt-6 mb-4 font-semibold text-gray-800">Blocking method</p>
        <PanelRadioGroup<BlockingMethodType>
          options={blockingMethodOptions}
          activeId={blockingMethod}
          onChange={handleChangeBlockingMethod}
          name={'blocking-method'}
        />
      </SidebarTabLayout>
    </form>
  );
};
