import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { snippetsModel } from '../../../../features/snippets';
import { SnippetsConfigBasicEnum } from './lib/config';
import { SearchInput } from '../../../../shared/ui';

export const SnippetsSearch: React.FC = () => {
  const dispatch = useDispatch();

  const searchQuery = useSelector(
    snippetsModel.selectors.selectModalSearchQuery
  );
  const currentFolder = useSelector(
    snippetsModel.selectors.selectCurrentModalFolder
  );

  const handleSearchQueryChange = (value: string) => {
    dispatch(snippetsModel.actions.setModalSearchQuery(value));
    if (currentFolder !== SnippetsConfigBasicEnum.ALL) {
      dispatch(
        snippetsModel.actions.setCurrentModalFolder(SnippetsConfigBasicEnum.ALL)
      );
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.blur();
  }, []);

  return (
    <SearchInput
      value={searchQuery}
      onChange={handleSearchQueryChange}
      placeholder="Search snippets"
    />
  );
};
