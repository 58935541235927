import { useDispatch, useSelector } from 'react-redux';
import { chatModel } from '../model';
import { Button, Modal } from '../../../shared/ui';

export const ChatDeleteModal: React.FC = () => {
  const dispatch = useDispatch();

  const isChatDeleting = useSelector(chatModel.selectors.selectIsChatDeleting);
  const isChatDeleteModalOpen = useSelector(
    chatModel.selectors.selectIsChatDeleteModalOpen
  );

  const onClose = () => {
    dispatch(chatModel.actions.setIsChatDeleteModalOpen(false));
  };

  const onConfirm = () => {
    dispatch(chatModel.actions.deleteChatMessages());
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isChatDeleteModalOpen}
      title="Delete chat?"
      actionButton={
        <Button
          onClick={onConfirm}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          loading={isChatDeleting}
        >
          Delete
        </Button>
      }
    >
      <p className="text-sm font-normal text-gray-700 pt-1">
        Are you sure you want to delete this chat? This cannot be undone.
      </p>
    </Modal>
  );
};
