import { FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../../processes/page-creation-flow';

type Props = {
  renderButton: (onClick: () => void, isLoading: boolean) => ReactNode;
};

export const CreatePage: FC<Props> = ({ renderButton }) => {
  const dispatch = useDispatch();

  const isCreatePageLoading = useSelector(
    pageCreationFlowModel.selectors.selectCreatePageIsLoading
  );

  const openCreatePageModal = () => {
    dispatch(pageCreationFlowModel.actions.openPageCreationFlow({}));
  };

  return <>{renderButton(openCreatePageModal, isCreatePageLoading)}</>;
};
