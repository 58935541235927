import React, { ReactNode } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@distribute/frontend/utils';

const buttonVariants = cva(
  'grid place-items-center focus-visible:outline-none disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        primary:
          'bg-primary-700 text-base-white hover:bg-primary-800 disabled:bg-gray-200',
        'secondary-gray': 'hover:bg-gray-50 border border-gray-200',
        'tertiary-gray':
          'text-gray-600 hover:bg-base-black/[0.08] hover:text-gray-700',
      },
      size: {
        xs: 'h-6 w-6 rounded-md',
        sm: 'h-9 w-9 rounded-lg',
        md: 'h-10 w-10 rounded-lg',
      },
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  children: ReactNode;
}

export const ButtonSquare = ({
  className,
  variant,
  size,
  children,
  ...props
}: ButtonProps) => {
  return (
    <button
      type="button"
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    >
      {children}
    </button>
  );
};
