export const formatInitials = (name: string | null, isUser = true) => {
  if (!name) return '';

  const [firstName, lastName] = name.split(' ');

  if (isUser && lastName) {
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }

  return firstName[0].toUpperCase();
};
