import { useSelector } from 'react-redux';
import { Container } from '../components';
import { OutlineBoard } from './OutlineBoard';
import { Skeleton } from './Skeleton';
import { pageCreationFlowModel } from '../../../../model';

export const CreateOutline = ({ isVisible }: { isVisible: boolean }) => {
  const isLoading = useSelector(
    pageCreationFlowModel.selectors.selectIsGenerateOutlineLoading
  );
  return (
    <Container
      isVisible={isVisible}
      style={{
        background:
          'url(../../../assets/images/grid-background.svg) no-repeat center / contain',
      }}
    >
      <div className="flex flex-col max-w-151 w-full h-full my-10 gap-4 overflow-hidden">
        <p className="text-center text-sm text-gray-600">
          After you happy with outline click “Generate” button.
        </p>
        {isLoading ? <Skeleton /> : <OutlineBoard />}
      </div>
    </Container>
  );
};
