import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { tasksModel } from '..';
import { tasksApi } from '../../../../../src/shared/api';
import {
  TaskAssigneeExtended,
  TasklistWithTasks,
} from '@distribute/shared/types';

export function* setTaskAssignee({
  payload: { successCallback, failedCallback, tasklistId, ...payloadData },
}: ReturnType<typeof tasksModel.actions.setTaskAssignee>) {
  const tasklists: Record<string, TasklistWithTasks> = yield select(
    tasksModel.selectors.selectTasklists
  );

  try {
    const { taskId, documentContentId, ...rest } = payloadData;

    const assignee: TaskAssigneeExtended = yield call(
      tasksApi.setTaskAssignee,
      taskId,
      documentContentId,
      rest
    );

    const tasklist = tasklists[tasklistId];
    const taskIndex = tasklist.tasks.findIndex((t) => t.id === taskId);
    const tasks = [...tasklist.tasks];
    const updatedTask = { ...tasklist.tasks[taskIndex], assignee };
    tasks.splice(taskIndex, 1, updatedTask);

    const updatedTasklist = { ...tasklists[tasklistId], tasks };

    const newTasklists = {
      ...tasklists,
      [tasklistId]: updatedTasklist,
    };

    yield put(tasksModel.actions.setTasklists(newTasklists));

    successCallback(assignee);
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to set task assignee')
      )
    );

    yield put(tasksModel.actions.setTasklists(tasklists));

    failedCallback();
  }
}
