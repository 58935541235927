import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import {
  ContactCardAttrs,
  deserializeContacts,
  serializeContacts,
} from '@distribute/shared/generate-html';
import { ContactLink } from './lib/ContactLink';
import { Avatar } from '../../../../shared/ui';
import { useSelector } from 'react-redux';
import { editorLeftSidebarModel } from '../../../../features/editor-left-sidebar/model';
export type ContactCardItemNodeType = NodeViewProps['node'] & {
  attrs: ContactCardAttrs;
};

export type ContactCardItemNodeViewProps = NodeViewProps & {
  node: ContactCardItemNodeType;
};

export const ContactCardNodeView = ({
  node,
  getPos,
  updateAttributes,
}: ContactCardItemNodeViewProps) => {
  const data = useSelector(
    editorLeftSidebarModel.selectors.selectContactCardEditedData
  );

  const pos = useSelector(
    editorLeftSidebarModel.selectors.selectContactCardEditedPos
  );

  // If the contact card is being edited, use the edited data.
  // But only if position of the node is the same as the edited position.
  const contactData = pos !== getPos() ? node.attrs : data ?? node.attrs;

  const contacts = useMemo(
    () =>
      deserializeContacts(
        data?.serializedContacts || node.attrs.serializedContacts
      ),
    [data?.serializedContacts, node.attrs.serializedContacts]
  );

  // Set ref to save if the current node is being edited.
  const isCurrentNodeEditing = useRef(pos === getPos());
  const lastEditedData = useRef(contactData);

  useEffect(() => {
    // If the node is not edited anymore - save the edited data.
    if (pos !== getPos() && isCurrentNodeEditing.current) {
      const data = lastEditedData.current;
      const contacts = deserializeContacts(data.serializedContacts).filter(
        (contact) => contact.value
      );

      updateAttributes({
        ...data,
        serializedContacts: serializeContacts(contacts),
      });

      isCurrentNodeEditing.current = false;
    } else if (pos === getPos()) {
      // If the node is being edited - set the ref to true.
      isCurrentNodeEditing.current = true;
    }

    lastEditedData.current = contactData;
  }, [pos, getPos, contactData, updateAttributes]);

  return (
    <NodeViewWrapper className="!border-none">
      <div
        data-type="contactCard"
        className="relative border rounded-2xl select-none border-gray-300 flex flex-row sm:flex-col overflow-hidden text-gray-900"
      >
        <div
          className={cn('flex-1 p-8', {
            hidden: !contactData.isDescriptionBlockVisible,
          })}
        >
          <p className="whitespace-pre-wrap !m-0 sm:text-base text-lg">
            {contactData.description}
          </p>
        </div>
        <div className="flex-1 p-8 bg-gray-100 flex flex-col gap-y-8 overflow-hidden">
          <div className="flex flex-row gap-x-3">
            <Avatar
              displayName={contactData.name || contactData.initialName}
              src={contactData.photoUrl}
              className={cn('h-14 w-14 border border-light-6', {
                hidden: !contactData.isPhotoBlockVisible,
              })}
              isUserAvatar={true}
            />
            <div className="flex flex-col flex-1 justify-center">
              <span className="sm:text-base text-lg font-semibold">
                {contactData.name}
              </span>
              <span
                className={cn('sm:text-base text-lg text-gray-600', {
                  hidden: !contactData.jobTitle && !contactData.company,
                })}
              >
                {[contactData.jobTitle, contactData.company]
                  .filter(Boolean)
                  .join(' • ')}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-3">
            {contacts.map((contact) => (
              <ContactLink {...contact} type={contact.type} key={contact.id} />
            ))}
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};
