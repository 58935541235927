import { selectWithError } from '../../../shared/lib';
import { RootState } from '../../../app';

export const selectCalls = ({ call }: RootState) => call.calls;
export const selectCallListOptions = ({ call }: RootState) => call.listOptions;
export const selectCallListMeta = ({ call }: RootState) => call.listMeta;
export const selectIsCallListLoading = ({ call }: RootState) =>
  call.isListLoading;

export const selectEditingCallItem = ({ call }: RootState) =>
  call.editingCallItem;
export const selectDeletingCallItem = ({ call }: RootState) =>
  call.deletingCallItem;
export const selectTogglingCallItem = ({ call }: RootState) =>
  call.togglingCallItem;

export const selectEditingCallItemWithError = selectWithError(
  selectEditingCallItem,
  'editingCallItem'
);

export const selectDeletingCallItemWithError = selectWithError(
  selectDeletingCallItem,
  'deletingCallItem'
);

export const selectTogglingCallItemWithError = selectWithError(
  selectTogglingCallItem,
  'togglingCallItem'
);

export const selectIsCallEditingProgress = ({ call }: RootState) =>
  call.isCallEditingProgress;
export const selectIsCallDeletingProgress = ({ call }: RootState) =>
  call.isCallDeletingProgress;

export const selectCurrentCall = ({ call }: RootState) => call.currentCall;
export const selectCurrentCallWithError = selectWithError(
  selectCurrentCall,
  'currentCall'
);
export const selectIsCurrentCallLoading = ({ call }: RootState) =>
  call.isCurrentCallLoading;

export const selectIsSummaryLoading = ({ call }: RootState) =>
  call.isSummaryLoading;

export const selectTotal = ({ call }: RootState) => call.total;

export const selectIsCallCountLoading = ({ call }: RootState) =>
  call.isCallCountLoading;

export const selectGroupedCalls = ({ call }: RootState) => call.groupedCalls;

export const selectIsGroupedCallsLoading = ({ call }: RootState) =>
  call.isGroupedCallsLoading;

export const selectCurrentSummaryType = ({ call }: RootState) =>
  call.currentSummaryType;
export const selectPageCreationFlow = ({ call }: RootState) =>
  call.pageCreationFlow;
export const selectIsAssetPrivacyModalOpen = ({ call }: RootState) =>
  call.isAssetPrivacyModalOpen;
export const selectIsAssetPrivacyLoading = ({ call }: RootState) =>
  call.isAssetPrivacyLoading;
export const selectIsChooseFolderOrPageModalOpen = ({ call }: RootState) =>
  call.isChoseFolderOrPageModalOpen;
export const selectIsInsertToPageLoading = ({ call }: RootState) =>
  call.isInsertToPageLoading;
