import { call, put } from 'redux-saga/effects';
import { linkParsingApi } from '../../../../shared/api';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { pageCreationFlowModel } from '..';

export function* parseLinkTextContent({
  payload: { link, cb },
}: ReturnType<typeof pageCreationFlowModel.actions.parseLinkTextContent>) {
  try {
    const text: string = yield call(linkParsingApi.parseLink, link);
    cb(text);
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to parse link ${link}`)
      )
    );
    cb(null);
  }
}
