import {
  GetCustomTokenResponseType,
  GetUserResponseType,
  GoogleSignUpRequestType,
  SendResetPasswordEmailType,
  SendVerificationEmailType,
  SignUpRequestType,
  UpdateLastSelectedTeamRequestType,
  UpdateLastSelectedTeamResponseType,
  UpdateUserRequestType,
  UpdateUserResponseType,
  LogInAsSuperUserRequestType,
  CreateUserFromGmailResponseType,
} from '@distribute/shared/api-types/auth';
import { getUploadProgress } from '../../lib';
import { api } from './instance';
export const authApi = {
  onRegister(data: SignUpRequestType) {
    return api.post('users/sign-up', data);
  },

  onAppsumoRegister(data: SignUpRequestType) {
    return api.post('users/appsumo-sign-up', data);
  },

  async onGoogleRegister(
    data: GoogleSignUpRequestType
  ): Promise<CreateUserFromGmailResponseType> {
    const res = await api.post('users/google-sign-up', data);
    return res.data;
  },

  async createCustomToken() {
    return api
      .post<GetCustomTokenResponseType>('users/create-custom-token')
      .then((res) => res.data);
  },

  async updateProfile(
    data: UpdateUserRequestType
  ): Promise<UpdateUserResponseType> {
    const res = await api.put('users/me', data);
    return res.data;
  },

  async getUserData() {
    const { data } = await api.get<GetUserResponseType>('users/me');
    return data;
  },

  async updateProfilePhoto(
    file: File,
    onUploadProgress: (progress: number) => void
  ) {
    const { data: responseData } = await api.put(
      'users/me/photo-url',
      { photoUrl: file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (value) => {
          onUploadProgress(getUploadProgress(value));
        },
      }
    );
    return responseData;
  },

  async removePhoto() {
    const { data } = await api.delete('users/me/photo-url');
    return data;
  },

  async sendEmailVerification(data: SendVerificationEmailType) {
    return api.post('users/send-verification-email', data);
  },

  async sendResetPassword(data: SendResetPasswordEmailType) {
    return api.post('users/send-reset-password-email', data);
  },

  async updateLastSelectedTeam(data: UpdateLastSelectedTeamRequestType) {
    const { data: responseData } =
      await api.post<UpdateLastSelectedTeamResponseType>(
        'users/me/last-selected-team',
        data
      );

    return responseData;
  },

  async loginAsSuperuser(data: LogInAsSuperUserRequestType) {
    const { data: responseData } =
      await api.post<UpdateLastSelectedTeamResponseType>(
        'users/superuser/login',
        data
      );

    return responseData;
  },
};
