import { call, put, select } from 'redux-saga/effects';
import {
  GettingStartedStep,
  TeamFiltered,
  UserToTeam,
} from '@distribute/shared/types';
import { teamsModel } from '../../../teams';
import { teamsApi } from '../../../../shared/api';

export function* onGettingStartedStepCompleted(step: GettingStartedStep) {
  const currentTeam: TeamFiltered = yield select(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const teams: TeamFiltered[] = yield select(teamsModel.selectors.selectTeams);
  const isGettingStartedStepAlreadyCompleted =
    currentTeam.currentMember.gettingStartedCompletedSteps.includes(step);

  if (isGettingStartedStepAlreadyCompleted) {
    return;
  }

  const userToTeam: UserToTeam = yield call(
    teamsApi.updateGettingStartedStep,
    currentTeam.id,
    { step }
  );

  yield put(
    teamsModel.actions.setCurrentTeam({
      ...currentTeam,
      currentMember: userToTeam,
    })
  );
  yield put(
    teamsModel.actions.setTeams(
      teams.map((team) =>
        team.id === currentTeam.id
          ? {
              ...team,
              currentMember: userToTeam,
            }
          : team
      )
    )
  );
}
