import {
  ActionTypeAlertBar,
  ActionTypeCTA,
  ActionTypePopUp,
  CalendarProvider,
} from '@distribute/shared/types';
import { IconMap } from '../../../../../shared/sprite';
import { Icon } from '../../../../../shared/ui';

export const ALERT_BAR_TABS = [
  {
    id: ActionTypeAlertBar.LINK,
    name: 'Open a Link',
    icon: IconMap.Link,
  },
  {
    id: ActionTypeAlertBar.POPUP,
    name: 'Open a Pop-Up',
    icon: IconMap.FlexAlignBottom,
  },
];

export const POP_UP_TABS = [
  {
    id: ActionTypePopUp.FORM,
    name: 'Form',
    icon: IconMap.Rows01,
  },
  {
    id: ActionTypePopUp.LINK,
    name: 'Open a Link',
    icon: IconMap.Link,
  },
  {
    id: ActionTypePopUp.CALENDLY,
    name: 'Calendar',
    icon: IconMap.CalendarPlus01,
  },
];

export const CTA_TABS = [
  {
    id: ActionTypeCTA.FORM,
    name: 'Form',
    icon: IconMap.Rows01,
  },
  {
    id: ActionTypeCTA.LINK,
    name: 'Link',
    icon: IconMap.Link,
  },
  {
    id: ActionTypeCTA.POPUP,
    name: 'Pop-up',
    icon: IconMap.FlexAlignBottom,
  },
  {
    id: ActionTypeCTA.CALENDLY,
    name: 'Calendar',
    icon: IconMap.CalendarPlus01,
  },
];

export const CALENDAR_PROVIDERS = [
  {
    id: CalendarProvider.CALENDLY,
    label: (
      <div className="flex items-center gap-x-2">
        <Icon glyph={IconMap.CalendlyLogoSm} width={20} />
        Calendly
      </div>
    ),
  },
  {
    id: CalendarProvider.CHILI_PIPER,
    label: (
      <div className="flex items-center gap-x-2">
        <Icon glyph={IconMap.ChiliPiperLogoSm} width={20} />
        Chili Piper
      </div>
    ),
  },
];

export const CALENDLY_HOW_TO_GET_LINK_URL =
  'https://help.calendly.com/hc/en-us/articles/223193448-Sharing-your-scheduling-link#sharing-your-scheduling-link-0-0';
export const CHILI_PIPER_HOW_TO_GET_LINK_URL =
  'https://help.chilipiper.com/hc/en-us/articles/360056354434-How-to-Use-Booking-Links';
