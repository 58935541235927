import { put } from 'redux-saga/effects';
import { gatedContentModel } from '../../../gated-content-block';
import { subscribePopupModel } from '../../../subscribe-popup';
import { squeezePageModel } from '../../../squeeze-page';
import { ActiveConversionPanel, editorLeftSidebarModel } from '..';

export function* onActiveConversionPanel({
  payload,
}: ReturnType<typeof editorLeftSidebarModel.actions.setActiveConversionPanel>) {
  yield put(gatedContentModel.actions.setIsModalOpen(false));
  yield put(subscribePopupModel.actions.setIsModalOpen(false));
  yield put(squeezePageModel.actions.setIsOpen(false));

  if (payload === ActiveConversionPanel.POP_UP) {
    yield put(subscribePopupModel.actions.setIsModalOpen(true));
    return;
  }

  if (payload === ActiveConversionPanel.GATED_CONTENT) {
    yield put(gatedContentModel.actions.setIsModalOpen(true));
    return;
  }

  if (payload === ActiveConversionPanel.SQUEEZE_PAGE) {
    yield put(squeezePageModel.actions.setIsOpen(true));
    return;
  }
}
