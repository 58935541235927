import { FC } from 'react';
import cn from 'classnames';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Dropdown, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import {
  RecordCardDescription,
  RecordCardDuration,
  RecordCardPoster,
} from './RecordDetails';
import { RecordCategory } from './types';
import { styles } from './config';
import { VideoRecordType } from '@distribute/shared/api-types/video-record';
import { useRecordDropdownItems, useRecordOpen } from '../../hooks';

import { getMuxImage } from '@distribute/shared/utils';

type Props = {
  record: VideoRecordType;
};

export const RecordMyCard: FC<Props> = ({ record }) => {
  const { handleOpen } = useRecordOpen(record.prefix);

  const { metadata, playbackId } = record;

  const recordDropdownItems = useRecordDropdownItems({
    record,
    isShowCopyLink: true,
    isShowRename: true,
  });

  return (
    <div
      className={cn(styles.base, 'h-64 group cursor-pointer')}
      tabIndex={0}
      onClick={handleOpen}
      onKeyDown={(e) => e.key === 'Enter' && handleOpen()}
    >
      <div
        className="absolute top-5 right-5 z-[5] invisible group-hover:visible"
        onClick={(e) => e.stopPropagation()}
      >
        <Dropdown
          isModalMode={false}
          listStyles="shadow-lg"
          triggerComponent={
            <DropdownMenu.Trigger
              className={cn(
                'flex items-center justify-center w-9 h-9 rounded-md focus:outline-none',
                'text-gray-700 bg-base-white/[0.75] backdrop-blur-sm',
                'border border-base-black/[0.08]'
              )}
            >
              <Icon glyph={IconMap.DotsVertical} width={20} />
            </DropdownMenu.Trigger>
          }
          items={recordDropdownItems}
        />
      </div>
      <div className={cn(styles.poster, 'border border-base-black/[0.08]')}>
        <RecordCardDuration duration={(metadata?.mux?.duration || 0) * 1000} />
        <RecordCardPoster posterUrl={getMuxImage(playbackId || '')} />
      </div>
      <RecordCardDescription category={RecordCategory.MY} record={record} />
    </div>
  );
};
