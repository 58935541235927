import { editorLeftSidebarModel, LeftSidebarOptions } from '../model';
import { useDispatch, useSelector } from 'react-redux';
import { cn } from '@distribute/frontend/utils';
import { EditorSidebarButton } from '../../../../src/shared/ui';
import { FC } from 'react';
import { LEFT_SIDEBAR_ID, sidebarOptions } from './components/lib';
import { EditorLeftSidebarContent } from './EditorLeftSidebarContent';
import { useCollaboration } from '../../../../src/entities/collaboration';
import {
  ActionTypeAlertBar,
  ActionTypeCTA,
  ActionTypePopUp,
} from '@distribute/shared/types';
import { CreateAiButton } from './components/create-with-ai';

export const EditorLeftSidebar: FC = () => {
  const dispatch = useDispatch();

  const selectedOption = useSelector(
    editorLeftSidebarModel.selectors.selectSidebarOption
  );

  const {
    ctaData,
    popUpData,
    gatedContentData,
    squeezePageData,
    alertBarData,
  } = useCollaboration();

  const isAnyConversionActive =
    ctaData.isActive ||
    popUpData.isActive ||
    gatedContentData.isActive ||
    squeezePageData.isActive ||
    alertBarData.isActive;

  const isAlertBarPopUpWarning =
    alertBarData.actionType === ActionTypeAlertBar.POPUP &&
    (popUpData.actionType === ActionTypePopUp.CALENDLY
      ? !popUpData.calendarSchedulingLink
      : !popUpData.title);

  const isPopUpWarning =
    popUpData.isActive &&
    !popUpData.isTriggerExit &&
    !popUpData.isTriggerTime &&
    (!alertBarData.isActive ||
      alertBarData.actionType !== ActionTypeAlertBar.POPUP) &&
    (!ctaData.isActive || ctaData.actionType !== ActionTypeCTA.POPUP);

  const isCTAWarning =
    ctaData.actionType === ActionTypeCTA.POPUP &&
    (popUpData.actionType === ActionTypePopUp.CALENDLY
      ? !popUpData.calendarSchedulingLink
      : !popUpData.title);

  const isConversionsWarning =
    isAlertBarPopUpWarning || isPopUpWarning || isCTAWarning;

  return (
    <div
      className={cn('h-[calc(100vh-68px)]', {
        'rounded-2xl bg-base-white flex': !!selectedOption,
      })}
      id={LEFT_SIDEBAR_ID}
    >
      <div className="flex flex-col items-center justify-between h-full pb-4 overflow-y-auto min-w-20">
        <div className="flex flex-col gap-2 px-2 pt-2 pb-4">
          {sidebarOptions.map((option) => (
            <EditorSidebarButton
              key={option.id}
              title={option.name}
              isSelected={option.id === selectedOption}
              icon={option.icon}
              disabled={option.disabled}
              isActiveIcon={
                option.id === LeftSidebarOptions.CONVERSIONS &&
                isAnyConversionActive
              }
              isWarningIcon={
                option.id === LeftSidebarOptions.CONVERSIONS &&
                isConversionsWarning
              }
              onClick={() => {
                dispatch(
                  editorLeftSidebarModel.actions.setSelectedOption(
                    selectedOption === option.id ? null : option.id
                  )
                );
              }}
            />
          ))}
        </div>
        <CreateAiButton />
      </div>

      {!!selectedOption && <EditorLeftSidebarContent />}
    </div>
  );
};
