import { call, put, select, take } from 'redux-saga/effects';
import { history } from '../config';
import { navigate } from '../../../processes/navigation';

import { Folder, User, UserOnboardingStatus } from '@distribute/shared/types';
import { generatePath } from 'react-router-dom';
import {
  foldersModel,
  splitFoldersToPrivateShared,
} from '../../../features/folders';
import { authUserModel } from '../../auth-user';
import {
  ACCEPT_PAGE_INVITATION_ROUTE,
  ACCEPT_REQUEST_TO_EDIT_PAGE_ROUTE,
  ACCEPT_TEAM_INVITATION_ROUTE,
  COMPLETE_PASSWORD_RESET_ROUTE,
  EDITOR_PAGE_ROUTE,
  INDEX_ROUTE,
  LOADING_ROUTE,
  LOGIN_ROUTE,
  NOT_FOUND_ROUTE,
  ONBOARDING_ROUTE,
  PUBLIC_TEMPLATE_ROUTE,
  QUICK_RECORDINGS_MY_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  SETTINGS_ROUTE,
  SUCCESSFULLY_CREATED_ACCOUNT_ROUTE,
  TEAM_LEFT_ROUTE,
  TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
  TEMPLATE_EDITOR_PAGE_ROUTE,
  VERIFY_EMAIL_ROUTE,
  WORKSPACE_FOLDER_ROUTE,
  WORKSPACE_ROUTE,
  PAGES_OVERVIEW_ROUTE,
  CALL_PAGE_ROUTE,
} from '../config';
import { RedirectToPublicTemplates } from './types';

export function* toRoot() {
  yield put(navigate({ to: INDEX_ROUTE }));
}

export function* to404() {
  yield put(navigate({ to: NOT_FOUND_ROUTE }));
}

export function* toLogin(force = false) {
  yield put(navigate({ to: LOGIN_ROUTE, force }));
}

export function* toSignup(force = false) {
  yield put(navigate({ to: REGISTER_ROUTE, force }));
}

export function* toWorkspace() {
  yield put(navigate({ to: WORKSPACE_ROUTE }));
}

export function* toPagesOverview() {
  yield put(navigate({ to: PAGES_OVERVIEW_ROUTE }));
}

export function* toCustomRedirect({ url }: { url: string }) {
  yield put(navigate({ to: url }));
}

export function* toWorkspaceFolder({
  sequenceNumber,
}: {
  sequenceNumber: string;
}) {
  const path = generatePath(WORKSPACE_FOLDER_ROUTE, { sequenceNumber });
  yield put(navigate({ to: path }));
}

export function* toWorkspaceDefaultFolder() {
  let currentUser: User | undefined = yield select(
    authUserModel.selectors.selectUser
  );

  if (!currentUser) {
    const userAction: ReturnType<typeof authUserModel.actions.setDBUser> =
      yield take(authUserModel.actions.setDBUser);
    currentUser = userAction.payload;
  }

  if (currentUser?.onboardingStatus !== UserOnboardingStatus.FINISHED) {
    return;
  }
  const foldersStatus: string = yield select(
    foldersModel.selectors.selectFoldersStatus
  );

  if (foldersStatus === 'never') {
    yield take(foldersModel.actions.setFolders);
  }

  const folders: Folder[] = yield select(foldersModel.selectors.selectFolders);

  const { privateFolders, sharedFolders } = splitFoldersToPrivateShared(
    folders,
    currentUser as User
  );

  if (sharedFolders.length) {
    yield call(toWorkspaceFolder, {
      sequenceNumber: sharedFolders[0].sequenceNumber,
    });
    return;
  }
  if (privateFolders.length) {
    yield call(toWorkspaceFolder, {
      sequenceNumber: privateFolders[0].sequenceNumber,
    });
    return;
  }
  yield put(navigate({ to: WORKSPACE_ROUTE }));
}

export function* toEditorPage({
  sequenceNumber,
  queryParams,
  isKeepQueryParams,
}: {
  sequenceNumber: string;
  queryParams?: Record<string, string>;
  isKeepQueryParams?: boolean;
}) {
  let path = generatePath(EDITOR_PAGE_ROUTE, { sequenceNumber });

  if (queryParams) {
    path += `?${new URLSearchParams(queryParams).toString()}`;
  }
  yield put(
    navigate({
      to: path,
      isKeepQueryParams,
    })
  );
}

export function* toTemplateEditorPage({
  sequenceNumber,
}: {
  sequenceNumber: string;
}) {
  const path = generatePath(TEMPLATE_EDITOR_PAGE_ROUTE, { sequenceNumber });
  yield put(navigate({ to: path }));
}

export function* toRegister() {
  yield put(navigate({ to: REGISTER_ROUTE }));
}

export function* toSuccessfullyCreatedAppsumoAccount() {
  yield put(navigate({ to: SUCCESSFULLY_CREATED_ACCOUNT_ROUTE }));
}

export function* toEmailVerification() {
  yield put(navigate({ to: VERIFY_EMAIL_ROUTE }));
}

export function* toOnboarding() {
  yield put(navigate({ to: ONBOARDING_ROUTE }));
}

export function* toResetPassword() {
  yield put(navigate({ to: RESET_PASSWORD_ROUTE }));
}

export function* toSettings() {
  yield put(navigate({ to: SETTINGS_ROUTE }));
}

export function* toSubscription() {
  yield put(navigate({ to: TEAM_SETTINGS_SUBSCRIPTION_ROUTE }));
}

export function* toCompletePassword(code: string) {
  yield put(navigate({ to: COMPLETE_PASSWORD_RESET_ROUTE, state: code }));
}

export function* toLoading() {
  yield put(navigate({ to: LOADING_ROUTE }));
}

export function* toTeamLeft() {
  yield put(navigate({ to: TEAM_LEFT_ROUTE }));
}

export function* toAcceptTeamInvitation({ inviteId }: { inviteId: string }) {
  const path = generatePath(ACCEPT_TEAM_INVITATION_ROUTE, { inviteId });
  yield put(navigate({ to: path }));
}

export function* toAcceptPageInvitation({
  pageInviteId,
  teamInviteId,
}: {
  pageInviteId: string;
  teamInviteId?: string;
}) {
  let path = generatePath(ACCEPT_PAGE_INVITATION_ROUTE, {
    pageInviteId,
    teamInviteId: teamInviteId ?? '',
  });
  if (teamInviteId) {
    path += `?teamInviteId=${teamInviteId}`;
  }
  yield put(navigate({ to: path }));
}

export function* toAcceptRequestToEditPage({
  requestId,
}: {
  requestId: string;
}) {
  const path = generatePath(ACCEPT_REQUEST_TO_EDIT_PAGE_ROUTE, { requestId });
  yield put(navigate({ to: path }));
}

export function* toPublicTemplates({
  pageId,
  queryParams,
  isKeepQueryParams,
}: RedirectToPublicTemplates) {
  let path = generatePath(PUBLIC_TEMPLATE_ROUTE, { pageId });

  if (queryParams) {
    path += `?${new URLSearchParams(queryParams).toString()}`;
  }

  yield put(navigate({ to: path, isKeepQueryParams }));
}

export function* fromWorkspaceWithBackUrl({ path }: { path: string }) {
  const backUrl = encodeURIComponent(history.location.pathname);
  const params = new URLSearchParams(window.location.search);
  const backUrlFromParams = params.get('backUrl');
  yield put(
    navigate({
      to: backUrlFromParams ? path : `${path}?backUrl=${backUrl}`,
      isKeepQueryParams: !!backUrlFromParams,
    })
  );
}

export function* toWorkspaceByBackUrl() {
  const params = new URLSearchParams(window.location.search);
  const backUrl = params.get('backUrl');
  yield put(
    navigate({
      to: backUrl ? decodeURIComponent(backUrl) : WORKSPACE_ROUTE,
      isKeepQueryParams: false,
    })
  );
}

export function* toRecordsMy() {
  yield put(navigate({ to: QUICK_RECORDINGS_MY_ROUTE }));
}

export function* toCallPage({ id }: { id: string }) {
  const path = generatePath(CALL_PAGE_ROUTE, { id });

  yield put(
    navigate({
      to: path,
    })
  );
}
