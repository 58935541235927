import cn from 'classnames';
import { FC } from 'react';

import { IconMap } from '../sprite';
import { Icon } from './Icon';

const styles = {
  sm: { header: 'text-md', text: 'text-sm', icon: 24 },
  md: { header: 'text-xl', text: 'text-md', icon: 32 },
} as const;

type RecordProps = {
  size?: keyof typeof styles;
  className?: string;
};

export const RecordFailed: FC<RecordProps> = ({
  className = '',
  size = 'sm',
}) => {
  return (
    <div
      className={cn(
        'w-full h-full bg-gray-500 flex flex-col items-center justify-center sm:gap-y-2 gap-y-4 text-center',
        className
      )}
    >
      <Icon
        glyph={IconMap.AlertTriangle}
        width={styles[size].icon}
        className="text-base-white"
      />
      <div className="flex flex-col gap-y-1">
        <h5
          className={cn('text-base-white font-semibold', styles[size].header)}
        >
          Failed to process
        </h5>
        <p className={cn('text-gray-300', styles[size].text)}>
          Try to record again
        </p>
      </div>
    </div>
  );
};

export const RecordLoading: FC<RecordProps> = ({
  className = '',
  size = 'sm',
}) => {
  return (
    <div
      className={cn(
        'w-full h-full bg-gray-100 flex flex-col items-center justify-center sm:gap-y-2 gap-y-4 text-center',
        className
      )}
    >
      <Icon
        glyph={IconMap.FilmArrow}
        width={styles[size].icon}
        className="text-gray-600"
      />
      <div className="flex flex-col gap-y-1">
        <h5 className={cn('text-gray-700 font-semibold', styles[size].header)}>
          Uploading...
        </h5>
        <p className={cn('text-sm text-gray-400', styles[size].text)}>
          Recording will be available soon
        </p>
      </div>
    </div>
  );
};

type RecordProgressProps = {
  loading: boolean;
  size?: keyof typeof styles;
};

export const RecordState: FC<RecordProgressProps> = ({
  size = 'sm',
  loading,
}) => {
  return loading ? <RecordLoading size={size} /> : <RecordFailed size={size} />;
};
