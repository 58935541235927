import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CallHeader,
  DeleteCallItemModal,
  CallDetails,
  TogglePrivacyCallItemModal,
} from '../../../features/call';
import { callModel } from '../../../features/call';
import { Loader } from '../../../shared/ui';

export const CallPage = () => {
  const dispatch = useDispatch();
  const call = useSelector(callModel.selectors.selectCurrentCall);
  const isLoading = useSelector(callModel.selectors.selectIsCurrentCallLoading);

  const deletingCallItem = useSelector(
    callModel.selectors.selectDeletingCallItem
  );
  const togglingCallItem = useSelector(
    callModel.selectors.selectTogglingCallItem
  );

  useEffect(() => {
    return () => {
      dispatch(callModel.actions.resetCurrentCall());
    };
  }, [dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  if (!call) {
    return <Loader />;
  }

  return (
    <div className="h-full">
      <CallHeader call={call} />
      <CallDetails call={call} />
      {deletingCallItem && <DeleteCallItemModal />}
      {togglingCallItem && <TogglePrivacyCallItemModal />}
    </div>
  );
};
