import { FormProvider, useForm } from 'react-hook-form';
import { CreatePageStepProvider } from './context';
import { CreatePageStepLeft } from './CreatePageStepLeft';
import { Sidebar } from './Sidebar';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createPageFormSchema,
  CreatePageFormSchemaType,
  LinkTypeForAIStatusEnum,
  PageCreationDataSourcesEnum,
  TranscriptsFilterTypesEnum,
} from '../../config/types';
import { useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../model';
import { teamsModel } from '../../../../features/teams';
import { gongModel } from '../../../../entities/gong';
import { callModel } from '../../../../features/call';

export const CreatePageStep = () => {
  const preselectedGongCallTranscriptId = useSelector(
    pageCreationFlowModel.selectors.selectPreselectedGongCallTranscriptId
  );

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const isPersonalGongAccountConnected = useSelector(
    gongModel.selectors.selectIsConnected
  );

  const isTeamGongAccountConnected = currentTeam.isGongConnected;

  const isGongConnected =
    isPersonalGongAccountConnected || isTeamGongAccountConnected;

  const groupedCalls = useSelector(callModel.selectors.selectGroupedCalls);

  const methods = useForm<CreatePageFormSchemaType>({
    resolver: yupResolver(createPageFormSchema),
    defaultValues: {
      isBranded: true,
      brandCompany: null,
      pageTitle: '',
      pageType: undefined,
      selectedTranscripts: preselectedGongCallTranscriptId
        ? [preselectedGongCallTranscriptId]
        : [],
      selectedCallerName: '',
      transcriptsFilter: preselectedGongCallTranscriptId
        ? TranscriptsFilterTypesEnum.SPECIFIC
        : undefined,
      selectedDataSource:
        groupedCalls.personal.length || groupedCalls.team.length
          ? PageCreationDataSourcesEnum.CALL
          : isGongConnected
          ? PageCreationDataSourcesEnum.GONG
          : PageCreationDataSourcesEnum.TEXT,
      text: '',
      links: [
        { url: '', data: null, status: LinkTypeForAIStatusEnum.NOT_PARSED_YET },
      ],
      sections: [],
      isSeparateTabs: false,
      isUsingTabsAsAContext: false,
      selectedCallRecordings: [],
    },
  });

  return (
    <FormProvider {...methods}>
      <CreatePageStepProvider>
        <div className="grid grid-cols-12 h-full w-full gap-4 p-4">
          <div className="col-span-5 h-[min(768px,100vh-72px)]">
            <CreatePageStepLeft />
          </div>
          <div className="col-span-7 rounded-xl bg-gray-100 min-h-0">
            <Sidebar />
          </div>
        </div>
      </CreatePageStepProvider>
    </FormProvider>
  );
};
