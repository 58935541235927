import { RequireEmailToViewConversionItem } from '../../../../../../src/widgets/editor-sidebar/ui/components/conversion-tab/RequireEmailToViewConversionItem';
import { useCollaboration } from '../../../../../../src/entities/collaboration';
import { IconMap } from '../../../../../../src/shared/sprite';
import { ChangeSlugForm } from './components';
import { ConversionLabelToggle } from '../../../../../pages/new-editor/ui/components/ConversionLabelToggle';

export const GeneralSettingTab = () => {
  const {
    isViewersCanDuplicateAsTemplate,
    updateIsViewersCanDuplicateAsTemplate,
    isDistributeBadgeRemoved,
    updateIsDistributeBadgeRemoved,
  } = useCollaboration();

  const handleChangeIsViewersCanDuplicateAsTemplate = (value: boolean) => {
    updateIsViewersCanDuplicateAsTemplate(value);
  };

  const handleChangeIsDistributeBadgeRemoved = (value: boolean) => {
    updateIsDistributeBadgeRemoved(value);
  };

  return (
    <div>
      <p className="mb-3 font-semibold text-gray-500 uppercase text-xs">
        Access Settings
      </p>
      <RequireEmailToViewConversionItem />
      <hr className="my-5 border-gray-200" />

      <div className="mb-5">
        <p className="mb-3 font-semibold text-gray-500 uppercase text-xs">
          Page Settings
        </p>
        <ChangeSlugForm />
      </div>
      <div>
        <p className="mb-3 font-semibold text-gray-500 uppercase text-xs">
          Other options
        </p>
        <div className="flex flex-col gap-4">
          <ConversionLabelToggle
            icon={IconMap.Copy07}
            text="Allow viewers duplicate page"
            checked={isViewersCanDuplicateAsTemplate}
            onChange={handleChangeIsViewersCanDuplicateAsTemplate}
          />
          <ConversionLabelToggle
            icon={IconMap.Star}
            text="Remove “Powered by Distribute” badge"
            checked={isDistributeBadgeRemoved}
            onChange={handleChangeIsDistributeBadgeRemoved}
            availableForPaidPlan
          />
        </div>
      </div>
    </div>
  );
};
