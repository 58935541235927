export enum CreateWithAIPageType {
  BUSINESS_CASE = 'Business case',
  CALL_FOLLOW_UP = 'Call follow-up',
  BATTLE_CARD = 'Battle card',
  MUTUAL_ACTION_PLAN = 'Mutual action plan',
  CUSTOMER_ONBOARDING = 'Customer onboarding',
  QBR = 'QBR',
  OTHER = 'Other',
}

export type ContextType = {
  id?: string;
  namespace?: string;
};

export type GeneratedOutlineType = { title: string }[];

export type CreateWithAIRequestType = {
  pageType: CreateWithAIPageType;
  userName: string;
  companyName?: string;
  aboutCompany?: string;
  pageTitle?: string;
  prospectName?: string;
  pageTypeDescription?: string;
  isTranscript: boolean;
  hasEmbeddings: boolean;
  contextRawData: string[];
  teamId: number;
  embeddedContext?: ContextType;
  generatedOutline?: GeneratedOutlineType;
  isSeparateTabs?: boolean;
  brandCompany?: string;
  existingTabsContext?: string;
};

export type GenerateOutlineResponseType = {
  sections: { title: string }[];
};
