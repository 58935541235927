import { Mark, mergeAttributes } from '@tiptap/core';

/**
 * Custom TipTap mark extension for displaying diff operations
 * This will render inserted and deleted text with different styling
 */
export const DiffMarkExt = Mark.create({
  name: 'diff',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      op: {
        default: null,
        parseHTML: (element) => element.getAttribute('op'),
        renderHTML: (attributes) => {
          if (!attributes.op) return {};

          return {
            op: attributes.op,
            class: `diff-mark diff-${attributes.op}`,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-operation]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});
