import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { tasksModel } from '..';
import { tasksApi } from '../../../../../src/shared/api';
import { Task, TasklistWithTasks } from '@distribute/shared/types';

export function* addTask({
  payload: { successCallback, tasklistId, documentContentId },
}: ReturnType<typeof tasksModel.actions.addTask>) {
  const tasklists: Record<string, TasklistWithTasks> = yield select(
    tasksModel.selectors.selectTasklists
  );

  try {
    const newTask: Task = yield call(
      tasksApi.createTask,
      tasklistId,
      documentContentId
    );
    const newTasklist = {
      ...tasklists[tasklistId],
      tasks: [...tasklists[tasklistId].tasks, newTask],
    };
    yield put(
      tasksModel.actions.setTasklists({
        ...tasklists,
        [tasklistId]: newTasklist,
      })
    );

    successCallback(newTasklist);
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to add a task')
      )
    );
  }
}
