import { object, string } from 'yup';
import { Button, Icon, Input } from '../../../../../../shared/ui';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFullPageUrl, getPageUrl } from '../../../../../../shared/lib';
import { teamsModel } from '../../../../../teams';
import { customDomainsModel } from '../../../../../custom-domains';
import { pagesApi } from '../../../../../../shared/api';
import { useCollaboration } from '../../../../../../entities/collaboration';
import { createNotification, snackbarModel } from '../../../../../snackbar';
import { createPageQueryRandomValue } from '../../../../../pages/lib';
import { IconMap } from '../../../../../../shared/sprite';
import { SettingItemWrapper } from './SettingItemWrapper';

const RESERVED_WORDS = ['admin', 'login', 'signin', 'signup'];

const schema = object().shape({
  slug: string()
    .required('This is required field.')
    .max(50, 'Slug must be less than 50 characters')
    .matches(
      /^[a-z0-9-]+$/,
      'Please input slug in right format described above.'
    )
    .test(
      'no-reserved-words',
      'Slug cannot contain reserved words like "admin" or "login"',
      (value) => {
        if (!value) return true;
        return !RESERVED_WORDS.some((word) => value.includes(word));
      }
    ),
});

type FormValues = {
  slug: string;
};

export const ChangeSlugForm = () => {
  const dispatch = useDispatch();

  const { seoConfigurationData, updateSeoConfigurationField } =
    useCollaboration();

  const { id: currentTeamId, domain: currentTeamDomain } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const customDomain = useSelector(
    customDomainsModel.selectors.selectVerifiedCustomDomain
  );

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      slug: seoConfigurationData.pageSlug,
    },
    mode: 'onSubmit',
  });

  const slugValue = watch('slug');

  const pageUrl = getPageUrl({
    slug: slugValue,
    domain: currentTeamDomain,
    customDomain,
  });

  const fullPageUrl = getFullPageUrl({
    slug: seoConfigurationData.pageSlug,
    domain: currentTeamDomain,
    customDomain,
  });

  const handleFormSubmit = async (data: FormValues) => {
    try {
      setIsLoading(true);
      const checkUniqueData = await pagesApi.checkPageSlug({
        pageSlug: data.slug,
        teamId: currentTeamId,
      });

      if (!checkUniqueData.isUnique) {
        setError('slug', {
          type: 'manual',
          message: 'This slug is already taken. Please choose another one.',
        });
        return;
      }

      updateSeoConfigurationField('pageSlug', data.slug);
      setIsEditFormOpen(false);
    } catch (e) {
      dispatch(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', `Failed to update page slug`)
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(
      `${fullPageUrl}?${createPageQueryRandomValue()}`
    );

    dispatch(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'The link has been copied to your clipboard'
        )
      )
    );
  };

  const messageText = errors.slug ? errors.slug.message : pageUrl;

  return (
    <SettingItemWrapper
      title="Page slug"
      titleButton={
        <Button
          className="h-6 !p-0"
          color={isEditFormOpen ? 'link-destructive' : 'link'}
          variant="text"
          type="button"
          onClick={() => {
            setIsEditFormOpen((prevIsFormOpen) => !prevIsFormOpen);
            isEditFormOpen && reset();
          }}
        >
          {isEditFormOpen ? 'Cancel' : 'Edit'}
        </Button>
      }
    >
      {isEditFormOpen ? (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <p className="mb-4 text-sm text-gray-500">
            Ensure your page slug is unique, under 50 characters, and uses only
            lowercase letters, numbers, and hyphens. Avoid spaces, special
            characters, and reserved words like “admin” or “login.”
          </p>
          <Input
            {...register('slug')}
            type="text"
            isError={!!errors.slug}
            messageText={messageText}
          />
          <Button
            color="primary"
            variant="text"
            type="submit"
            loading={isLoading}
            className="mt-4"
          >
            Save
          </Button>
        </form>
      ) : (
        <div className="flex gap-1.5 items-center">
          <Icon
            glyph={IconMap.Globe03}
            className="text-gray-500 min-w-3.5"
            width={14}
          />
          <span className="text-sm text-gray-600 truncate" onClick={onCopy}>
            {fullPageUrl}
          </span>
        </div>
      )}
    </SettingItemWrapper>
  );
};
