import { call, select, put } from 'redux-saga/effects';
import { callApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { callModel } from '../index';

export function* getCallsCount() {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  yield put(callModel.actions.setIsTotalLoading(true));

  try {
    const callsCount: RT<typeof callApi.getCallsCount> = yield call(
      callApi.getCallsCount,
      { teamId: team.id }
    );
    yield put(callModel.actions.setTotal(callsCount));
  } catch (error) {
    // TODO: handle error
    console.log('error', error);
  } finally {
    yield put(callModel.actions.setIsTotalLoading(false));
  }
}
