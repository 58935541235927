import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import * as Y from 'yjs';
import YPartyKitProvider from 'y-partykit/provider';
import {
  AlertBar,
  CollaborativeCTA,
  CollaborativeGatedContent,
  CollaborativePopUp,
  CollaborativeSeoConfiguration,
  CollaborativeSqueezePage,
  DocumentContentItem,
  PagePublicAccessPermission,
  PageTeamMembersPermission,
  RequireEmailToView,
} from '@distribute/shared/types';
import { isEmpty, merge } from '@distribute/shared/yjs';
import { TiptapTransformer } from '@hocuspocus/transformer';
import { sharedMainEditorExtensions } from '@distribute/shared/generate-html';
import { environment } from '../../../environments/environment';
import { FeatureFlags } from '../../feature-flag';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../../../features/pages';
import { JSONContent } from '@tiptap/react';

type CollaborationContextType = {
  isCollaborationEnabled: boolean;
  isPageSaved: boolean;

  doc: Y.Doc | null;
  provider: YPartyKitProvider | null;
  title: string;
  published?: boolean;
  publicAccess?: PagePublicAccessPermission;
  teamMembersPermission: PageTeamMembersPermission;
  isViewersCanDuplicateAsTemplate: boolean;
  isDistributeBadgeRemoved: boolean;
  icon: string;
  brandLogo: string | null;
  coverImageUrl: string | null;

  updateTitle: (value: string) => void;
  updatePublished: (value: boolean) => void;
  updatePublicAccess: (value: PagePublicAccessPermission) => void;
  updateTeamMembersPermission: (value: PageTeamMembersPermission) => void;
  updateIsViewersCanDuplicateAsTemplate: (value: boolean) => void;
  updateIsDistributeBadgeRemoved: (value: boolean) => void;
  updateIcon: (value: string) => void;
  updateBrandLogo: (value: string | null) => void;
  updateCoverImageUrl: (value: string | null) => void;

  tabs: Partial<DocumentContentItem>[];
  addTab: (tab: DocumentContentItem) => void;
  updateTab: (tabId: number, tab: Partial<DocumentContentItem>) => void;
  setTabs: (value: Partial<DocumentContentItem>[]) => void;
  setTabsWithContent: (value: DocumentContentItem[]) => void;
  deleteTab: (tabId: number) => void;

  alertBarData: AlertBar;
  popUpData: CollaborativePopUp;
  ctaData: CollaborativeCTA;
  requireEmailData: RequireEmailToView;
  gatedContentData: CollaborativeGatedContent;
  squeezePageData: CollaborativeSqueezePage;
  seoConfigurationData: CollaborativeSeoConfiguration;

  updateRequireEmailField: <T extends keyof RequireEmailToView>(
    field: T,
    value: RequireEmailToView[T]
  ) => void;

  updateAlertBarField: <T extends keyof AlertBar>(
    field: T,
    value: AlertBar[T]
  ) => void;

  updatePopupField: <T extends keyof CollaborativePopUp>(
    field: T,
    value: CollaborativePopUp[T]
  ) => void;

  updateCTAField: <T extends keyof CollaborativeCTA>(
    field: T,
    value: CollaborativeCTA[T]
  ) => void;

  updateGatedContentField: <T extends keyof CollaborativeGatedContent>(
    field: T,
    value: CollaborativeGatedContent[T]
  ) => void;

  updateSqueezePageField: <T extends keyof CollaborativeSqueezePage>(
    field: T,
    value: CollaborativeSqueezePage[T]
  ) => void;

  updateSeoConfigurationField: <T extends keyof CollaborativeSeoConfiguration>(
    field: T,
    value: CollaborativeSeoConfiguration[T]
  ) => void;
};

type CollaborationProviderProps = {
  provider: YPartyKitProvider;
  isPageSaved: boolean;
  children: React.ReactNode;
};

export const CollaborationContext = createContext<CollaborationContextType>({
  isCollaborationEnabled:
    environment.featureFlags[FeatureFlags.DOCUMENT_COLLABORATION],
  isPageSaved: true,

  doc: new Y.Doc(),
  provider: null,
  title: '',
  teamMembersPermission: PageTeamMembersPermission.INVITE_ONLY,
  isViewersCanDuplicateAsTemplate: false,
  isDistributeBadgeRemoved: false,
  icon: '',
  brandLogo: null,
  coverImageUrl: null,

  updateTitle: () => null,
  updatePublished: () => null,
  updatePublicAccess: () => null,
  updateTeamMembersPermission: () => null,
  updateIsViewersCanDuplicateAsTemplate: () => null,
  updateIsDistributeBadgeRemoved: () => null,
  updateIcon: () => null,
  updateBrandLogo: () => null,
  updateCoverImageUrl: () => null,

  tabs: [],
  addTab: () => null,
  updateTab: () => null,
  setTabs: () => null,
  setTabsWithContent: () => null,
  deleteTab: () => null,

  requireEmailData: {} as RequireEmailToView,
  updateRequireEmailField: () => null,

  alertBarData: {} as AlertBar,
  updateAlertBarField: () => null,

  popUpData: {} as CollaborativePopUp,
  updatePopupField: () => null,

  ctaData: {} as CollaborativeCTA,
  updateCTAField: () => null,

  squeezePageData: {} as CollaborativeSqueezePage,
  updateSqueezePageField: () => null,

  gatedContentData: {} as CollaborativeGatedContent,
  updateGatedContentField: () => null,

  seoConfigurationData: {} as CollaborativeSeoConfiguration,
  updateSeoConfigurationField: () => null,
});

export const CollaborationProvider: React.FC<CollaborationProviderProps> = ({
  children,
  provider,
  isPageSaved,
}) => {
  const isCollaborationEnabled =
    environment.featureFlags[FeatureFlags.DOCUMENT_COLLABORATION];
  const settings = provider.doc.getMap('settings');
  const requireEmailToView = provider.doc.getMap('requireEmailToView');
  const alertBar = provider.doc.getMap('alertBar');
  const popUp = provider.doc.getMap('popUp');
  const cta = provider.doc.getMap('cta');
  const gatedContent = provider.doc.getMap('gatedContent');
  const squeezePage = provider.doc.getMap('squeezePage');
  const seoConfiguration = provider.doc.getMap('seoConfiguration');
  const tabsSettings = provider.doc.getMap('tabsSettings');

  const [title, setTitle] = useState(settings.get('title') as string);

  const [alertBarData, setAlertBarData] = useState(
    alertBar.toJSON() as AlertBar
  );
  const [popUpData, setPopUpData] = useState(
    popUp.toJSON() as CollaborativePopUp
  );
  const [requireEmailData, setRequireEmailData] = useState(
    requireEmailToView.toJSON() as RequireEmailToView
  );
  const [ctaData, setCTAData] = useState(cta.toJSON() as CollaborativeCTA);
  const [gatedContentData, setGatedContentData] = useState(
    gatedContent.toJSON() as CollaborativeGatedContent
  );
  const [squeezePageData, setSqueezePageData] = useState(
    squeezePage.toJSON() as CollaborativeSqueezePage
  );

  const [seoConfigurationData, setSeoConfigurationData] = useState(
    seoConfiguration.toJSON() as CollaborativeSeoConfiguration
  );

  const updateAlertBarField = <T extends keyof AlertBar>(
    field: T,
    value: AlertBar[T]
  ) => {
    alertBar.set(field, value);
    setAlertBarData((prev) => ({ ...prev, [field]: value }));
  };

  const updatePopupField = <T extends keyof CollaborativePopUp>(
    field: T,
    value: CollaborativePopUp[T]
  ) => {
    popUp.set(field, value);
    setPopUpData((prev) => ({ ...prev, [field]: value }));
  };

  const updateRequireEmailField = <T extends keyof RequireEmailToView>(
    field: T,
    value: RequireEmailToView[T]
  ) => {
    requireEmailToView.set(field, value);
    setRequireEmailData((prev) => ({ ...prev, [field]: value }));
  };

  const updateCTAField = <T extends keyof CollaborativeCTA>(
    field: T,
    value: CollaborativeCTA[T]
  ) => {
    cta.set(field, value);
    setCTAData((prev) => ({ ...prev, [field]: value }));
  };

  const updateGatedContentField = <T extends keyof CollaborativeGatedContent>(
    field: T,
    value: CollaborativeGatedContent[T]
  ) => {
    gatedContent.set(field, value);
    setGatedContentData((prev) => ({ ...prev, [field]: value }));
  };

  const updateSqueezePageField = <T extends keyof CollaborativeSqueezePage>(
    field: T,
    value: CollaborativeSqueezePage[T]
  ) => {
    squeezePage.set(field, value);
    setSqueezePageData((prev) => ({ ...prev, [field]: value }));
  };

  const updateSeoConfigurationField = <
    T extends keyof CollaborativeSeoConfiguration
  >(
    field: T,
    value: CollaborativeSeoConfiguration[T]
  ) => {
    seoConfiguration.set(field, value);
    setSeoConfigurationData((prev) => ({ ...prev, [field]: value }));
  };

  const updateTitle = (value: string) => {
    setTitle(value);
    settings.set('title', value);
  };

  const [published, setPublished] = useState(
    settings.get('published') as boolean
  );

  const updatePublished = (value: boolean) => {
    setPublished(value);
    settings.set('published', value);
  };

  const [publicAccess, setPublicAccess] = useState(
    settings.get('publicAccess') as PagePublicAccessPermission
  );

  const updatePublicAccess = (value: PagePublicAccessPermission) => {
    setPublicAccess(value);
    settings.set('publicAccess', value);
  };

  const [teamMembersPermission, setTeamMembersPermission] = useState(
    settings.get('teamMembersPermission') as PageTeamMembersPermission
  );

  const updateTeamMembersPermission = (value: PageTeamMembersPermission) => {
    setTeamMembersPermission(value);
    settings.set('teamMembersPermission', value);
  };

  const [isViewersCanDuplicateAsTemplate, setIsViewersCanDuplicateAsTemplate] =
    useState(settings.get('isViewersCanDuplicateAsTemplate') as boolean);

  const updateIsViewersCanDuplicateAsTemplate = (value: boolean) => {
    setIsViewersCanDuplicateAsTemplate(value);
    settings.set('isViewersCanDuplicateAsTemplate', value);
  };

  const [isDistributeBadgeRemoved, setIsDistributeBadgeRemoved] = useState(
    settings.get('isDistributeBadgeRemoved') as boolean
  );

  const updateIsDistributeBadgeRemoved = (value: boolean) => {
    setIsDistributeBadgeRemoved(value);
    settings.set('isDistributeBadgeRemoved', value);
  };

  const [icon, setIcon] = useState(settings.get('icon') as string);

  const updateIcon = (value: string) => {
    setIcon(value);
    settings.set('icon', value);
  };

  const [brandLogo, setBrandLogo] = useState(
    settings.get('brandLogo') as string | null
  );

  const updateBrandLogo = (value: string | null) => {
    setBrandLogo(value);
    settings.set('brandLogo', value);
  };

  const [coverImageUrl, setCoverImageUrl] = useState(
    settings.get('coverImageUrl') as string | null
  );

  const updateCoverImageUrl = (value: string | null) => {
    setCoverImageUrl(value);
    settings.set('coverImageUrl', value);
  };

  const [tabs, setCurrentTabs] = useState<Partial<DocumentContentItem>[]>(
    () => {
      const tabs = tabsSettings.get('tabs') as Partial<DocumentContentItem>[];
      const tabIds = tabs.map((item) => item.id?.toString() ?? '');

      const xmlFragments = TiptapTransformer.fromYdoc(provider.doc, tabIds);
      const tabsContent = Object.entries(xmlFragments);

      const tabsWithContentJson = tabs.map(({ id, ...rest }) => {
        const contentJson = tabsContent.find(
          ([key]) => key === id?.toString()
        )?.[1] as JSONContent;

        return {
          id,
          ...rest,
          contentJson,
        };
      });
      return tabsWithContentJson;
    }
  );

  const setTabs = (newTabs: Partial<DocumentContentItem>[]) => {
    tabsSettings.set(
      'tabs',
      newTabs.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    );
  };

  const setTabsWithContent = (newTabs: DocumentContentItem[]) => {
    setTabs(newTabs.map(({ contentJson: _, ...filteredTab }) => filteredTab));

    newTabs.forEach((item) => {
      const { contentJson, ...filteredTab } = item;

      if (isEmpty(provider.doc, filteredTab.id.toString())) {
        const tab = TiptapTransformer.toYdoc(
          contentJson,
          filteredTab.id.toString(),
          sharedMainEditorExtensions
        );
        merge(provider.doc, tab);
      }
    });
  };

  const addTab = (tab: DocumentContentItem) => {
    const { contentJson, ...filteredNewTab } = tab;
    setTabs([...tabs, filteredNewTab]);

    if (isEmpty(provider.doc, filteredNewTab.id.toString())) {
      const tab = TiptapTransformer.toYdoc(
        contentJson,
        filteredNewTab.id.toString(),
        sharedMainEditorExtensions
      );
      merge(provider.doc, tab);
    }
  };

  const updateTab = (
    tabId: number,
    tabContent: Partial<DocumentContentItem>
  ) => {
    const updatedTabs = tabs.map((tab) => {
      if (tab.id === tabId) {
        return { ...tab, ...tabContent };
      } else {
        return tab;
      }
    });
    setTabs(updatedTabs);
  };

  const deleteTab = (tabId: number) => {
    provider.doc.share.delete(tabId.toString());
    const newTabs = tabs.filter(({ id }) => id !== tabId);
    setTabs(newTabs);
  };

  const dispatch = useDispatch();

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  useEffect(() => {
    if (
      !isCollaborationEnabled ||
      (currentPage.content.title === title &&
        currentPage.pageSlug === seoConfigurationData.pageSlug &&
        currentPage.content.icon === icon &&
        currentPage.published === published &&
        currentPage.content.brandLogo === brandLogo)
    ) {
      return;
    }
    dispatch(
      pagesModel.actions.setCurrentPage({
        ...currentPage,
        pageSlug: seoConfigurationData.pageSlug,
        published,
        content: { ...currentPage.content, title: title, icon, brandLogo },
      })
    );
  }, [
    brandLogo,
    currentPage,
    dispatch,
    icon,
    isCollaborationEnabled,
    published,
    seoConfigurationData.pageSlug,
    title,
  ]);

  useEffect(() => {
    const updateSettings = () => {
      const title = settings.get('title') as string;
      const published = settings.get('published') as boolean;
      const publicAccess = settings.get(
        'publicAccess'
      ) as PagePublicAccessPermission;
      const teamMembersPermission = settings.get(
        'teamMembersPermission'
      ) as PageTeamMembersPermission;
      const isViewersCanDuplicateAsTemplate = settings.get(
        'isViewersCanDuplicateAsTemplate'
      ) as boolean;
      const isDistributeBadgeRemoved = settings.get(
        'isDistributeBadgeRemoved'
      ) as boolean;
      const icon = settings.get('icon') as string;
      const brandLogo = settings.get('brandLogo') as string | null;
      const coverImageUrl = settings.get('coverImageUrl') as string | null;
      setTitle(title);
      setPublished(published);
      setPublicAccess(publicAccess);
      setTeamMembersPermission(teamMembersPermission);
      setIsViewersCanDuplicateAsTemplate(isViewersCanDuplicateAsTemplate);
      setIsDistributeBadgeRemoved(isDistributeBadgeRemoved);
      setIcon(icon);
      setBrandLogo(brandLogo);
      setCoverImageUrl(coverImageUrl);
    };

    const updateTabs = () => {
      const tabs = tabsSettings.get('tabs') as Partial<DocumentContentItem>[];
      setCurrentTabs(tabs);
    };

    const updateAlertBar = () => {
      const alertBarData = alertBar.toJSON() as AlertBar;
      setAlertBarData(alertBarData);
    };

    const updatePopUp = () => {
      const popUpData = popUp.toJSON() as CollaborativePopUp;
      setPopUpData(popUpData);
    };

    const updateRequireEmailToView = () => {
      const requireEmailData =
        requireEmailToView.toJSON() as RequireEmailToView;
      setRequireEmailData(requireEmailData);
    };

    const updateCTA = () => {
      const ctaData = cta.toJSON() as CollaborativeCTA;
      setCTAData(ctaData);
    };

    const updateGatedContent = () => {
      const gatedContentData =
        gatedContent.toJSON() as CollaborativeGatedContent;
      setGatedContentData(gatedContentData);
    };

    const updateSqueezePage = () => {
      const squeezePageData = squeezePage.toJSON() as CollaborativeSqueezePage;
      setSqueezePageData(squeezePageData);
    };

    const updateSeoConfiguration = () => {
      const seoConfigurationData =
        seoConfiguration.toJSON() as CollaborativeSeoConfiguration;
      setSeoConfigurationData(seoConfigurationData);
    };

    const fragmentObserversMap = new Map();

    const observeFragment = (tabId: string) => {
      const xmlFragments = TiptapTransformer.fromYdoc(provider.doc, [tabId]);
      const contentJson = Object.values(xmlFragments)[0] as JSONContent;

      const newTabs = tabs.map((tab) => {
        if (tab.id?.toString() === tabId) {
          return { ...tab, contentJson };
        }
        return tab;
      });
      setCurrentTabs(newTabs);
    };

    settings.observe(updateSettings);
    tabsSettings.observe(updateTabs);
    requireEmailToView.observe(updateRequireEmailToView);
    alertBar.observe(updateAlertBar);
    popUp.observe(updatePopUp);
    cta.observe(updateCTA);
    gatedContent.observe(updateGatedContent);
    squeezePage.observe(updateSqueezePage);
    seoConfiguration.observe(updateSeoConfiguration);

    tabs.forEach((tab) => {
      const tabId = tab.id?.toString() ?? '';
      const observer = () => observeFragment(tabId);
      provider.doc.getXmlFragment(tabId).observeDeep(observer);
      fragmentObserversMap.set(tabId, observer);
    });

    return () => {
      settings.unobserve(updateSettings);
      tabsSettings.unobserve(updateTabs);
      requireEmailToView.unobserve(updateRequireEmailToView);
      alertBar.unobserve(updateAlertBar);
      popUp.unobserve(updatePopUp);
      cta.unobserve(updateCTA);
      gatedContent.unobserve(updateGatedContent);
      squeezePage.unobserve(updateSqueezePage);
      seoConfiguration.unobserve(updateSeoConfiguration);

      tabs.forEach((tab) => {
        const tabId = tab.id?.toString();
        const observer = fragmentObserversMap.get(tabId);
        provider.doc.getXmlFragment(tabId).unobserveDeep(observer);
      });
    };
  });

  return (
    <CollaborationContext.Provider
      value={{
        isCollaborationEnabled,
        isPageSaved,

        provider,
        doc: provider.doc,
        title,
        published,
        publicAccess,
        teamMembersPermission,
        isViewersCanDuplicateAsTemplate,
        isDistributeBadgeRemoved,
        icon,
        brandLogo,
        coverImageUrl,

        updateTitle,
        updatePublished,
        updatePublicAccess,
        updateTeamMembersPermission,
        updateIsViewersCanDuplicateAsTemplate,
        updateIsDistributeBadgeRemoved,
        updateIcon,
        updateBrandLogo,
        updateCoverImageUrl,

        tabs,
        addTab,
        updateTab,
        setTabs,
        setTabsWithContent,
        deleteTab,

        alertBarData,
        updateAlertBarField,

        popUpData,
        updatePopupField,

        requireEmailData,
        updateRequireEmailField,

        ctaData,
        updateCTAField,

        gatedContentData,
        updateGatedContentField,

        squeezePageData,
        updateSqueezePageField,

        seoConfigurationData,
        updateSeoConfigurationField,
      }}
    >
      {children}
    </CollaborationContext.Provider>
  );
};

export const useCollaboration = () => {
  const context = useContext(CollaborationContext);
  if (context === null) {
    throw new Error(
      'useCollaboration must be used within a CollaborationProvider'
    );
  }
  return context;
};
