import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';
import { RightSidebarOption } from './types';

type State = {
  selectedOption: RightSidebarOption | null;
};

const initialState: State = {
  selectedOption: null,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'editorRightSidebar',
  initialState,
  reducers: {
    setSelectedOption: (
      state,
      { payload: selectedOption }: PayloadAction<State['selectedOption']>
    ) => ({
      ...state,
      selectedOption,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = { ...reducerActions };
