import { cn } from '@distribute/frontend/utils';
import { FC, ReactNode } from 'react';

type Props = {
  title: string;
  titleButton?: ReactNode;
  children: ReactNode;
  isHideBorder?: boolean;
};

export const SettingItemWrapper: FC<Props> = ({
  title,
  titleButton,
  children,
  isHideBorder,
}) => {
  return (
    <div className={cn('pb-5', { 'border-b border-gray-200': !isHideBorder })}>
      <div className="flex items-center justify-between mb-2">
        <p className="font-semibold text-gray-900 text-md">{title}</p>
        {!!titleButton && titleButton}
      </div>
      {children}
    </div>
  );
};
