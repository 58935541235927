import { select, put, race, take, call } from 'redux-saga/effects';
import { callModel } from '../index';
import { RT } from '../../../../shared/types';
import { CreateOrInsertToPageFlowsEnum } from '../types';
import { pageCreationFlowModel } from '../../../../processes/page-creation-flow';
import {
  PageCreationFlowStepsEnum,
  PageCreationTypesEnum,
} from '../../../../processes/page-creation-flow/config/types';
import { DEFAULT_RATIO } from '@distribute/shared/utils';
import { JSONContentBuilder } from '@distribute/shared/tiptap/json-content';
import { pagesModel } from '../../../pages';
import { teamsModel } from '../../../teams';
import { collaborationApi } from '../../../../shared/api/axios/collaboration';
import { redirect } from '../../../../entities/history';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';

export function* createPageFlow({
  payload: type,
}: ReturnType<typeof callModel.actions.setPageCreationFlow>) {
  const currentCall: RT<typeof callModel.selectors.selectCurrentCall> =
    yield select(callModel.selectors.selectCurrentCall);

  if (!currentCall) return;

  if (
    currentCall.muxAsset.playback.policy === 'signed' &&
    type !== CreateOrInsertToPageFlowsEnum.PAGE_WITH_AI
  ) {
    yield put(callModel.actions.setIsAssetPrivacyModalOpen(true));

    const { cancel } = yield race({
      apply: take(callModel.actions.setCurrentCall),
      cancel: take(callModel.actions.setIsAssetPrivacyModalOpen),
    });

    if (cancel) return;
    yield put(callModel.actions.setIsAssetPrivacyModalOpen(false));
  }

  if (type === CreateOrInsertToPageFlowsEnum.PAGE_WITH_AI) {
    yield call(createPageWithAI, currentCall.id);
    return;
  }

  yield put(callModel.actions.setIsChooseFolderOrPageModalOpen(true));

  const { cancel, choose } = yield race({
    choose: take(callModel.actions.chooseFolderOrPage),
    cancel: take(callModel.actions.setIsChooseFolderOrPageModalOpen),
  });
  const { pageId, ...folderData } = choose.payload;

  if (cancel) return;

  if (!pageId) {
    yield put(callModel.actions.setIsChooseFolderOrPageModalOpen(false));
    yield put(pageCreationFlowModel.actions.setChosenFolderData(folderData));

    switch (type) {
      case CreateOrInsertToPageFlowsEnum.VIDEO_ONLY:
        yield call(createPageWithVideoOnly);
        break;
      case CreateOrInsertToPageFlowsEnum.SUMMARY_AND_VIDEO:
        yield call(createPageWithSummaryAndVideo);
        break;
      default:
        break;
    }
  } else {
    yield call(
      insertToPage,
      pageId,
      type === CreateOrInsertToPageFlowsEnum.SUMMARY_AND_VIDEO
    );
    yield put(callModel.actions.setIsChooseFolderOrPageModalOpen(false));
  }
}

function* createPageWithAI(callId: string) {
  yield put(
    pageCreationFlowModel.actions.openPageCreationFlow({
      callId,
    })
  );
  yield put(
    pageCreationFlowModel.actions.setPageCreationType(
      PageCreationTypesEnum.WITH_AI
    )
  );
  yield put(
    pageCreationFlowModel.actions.setCurrentStep(
      PageCreationFlowStepsEnum.CREATE_PAGE
    )
  );
}

function* createPageWithVideoOnly() {
  yield put(pageCreationFlowModel.actions.openPageCreationFlow({}));
  yield put(
    pageCreationFlowModel.actions.setCurrentStep(
      PageCreationFlowStepsEnum.CREATE_PAGE
    )
  );
}

function* createPageWithSummaryAndVideo() {
  yield put(pageCreationFlowModel.actions.openPageCreationFlow({}));
}

function* insertToPage(pageId: string, withSummary = false) {
  const currentCall: RT<typeof callModel.selectors.selectCurrentCallWithError> =
    yield select(callModel.selectors.selectCurrentCallWithError);
  const currentSummaryType: RT<
    typeof callModel.selectors.selectCurrentSummaryType
  > = yield select(callModel.selectors.selectCurrentSummaryType);
  const currentTeam: RT<
    typeof teamsModel.selectors.selectCurrentTeamWithError
  > = yield select(teamsModel.selectors.selectCurrentTeamWithError);
  const pages: RT<typeof pagesModel.selectors.selectPages> = yield select(
    pagesModel.selectors.selectPages
  );
  const page = pages.find((page) => page.id === pageId);

  if (!page) return;

  const { muxAsset, summaries } = currentCall;
  const { playback, data } = muxAsset;

  const content = JSONContentBuilder.videoRecord({
    attrs: {
      playbackId: playback.id,
      aspectRatio: data.aspectRatio || DEFAULT_RATIO,
      prefix: currentCall.id,
    },
  }).asArray();

  const summary = summaries.find(
    (summary) => summary.type === currentSummaryType
  );

  if (withSummary && summary?.content) {
    const summaryContent = JSONContentBuilder.callSummary(
      currentSummaryType,
      summary.content
    );
    content.push(...summaryContent);
  }

  yield put(callModel.actions.setIsInsertToPageLoading(true));

  try {
    yield call(collaborationApi.addContentJsonToFirstPageTab, {
      pageId: page.id,
      teamId: currentTeam.id,
      content,
    });

    yield call(redirect.toEditorPage, {
      sequenceNumber: page.sequenceNumber,
    });
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to insert to page')
      )
    );
  } finally {
    yield put(callModel.actions.setIsInsertToPageLoading(false));
  }
}
