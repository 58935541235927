import { generateXmlJSX } from '@distribute/shared/generate-html';
import {
  getContentItems,
  useCollaboration,
} from '../../../entities/collaboration';
import { chatModel } from '../model';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../../pages';
import { authUserModel } from '../../../entities/auth-user';
import { analytics } from '../../../entities/analytics';
import { AnalyticsEvents } from '@distribute/shared/types';

export const useSend = () => {
  const dispatch = useDispatch();
  const { provider, title } = useCollaboration();

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );
  const currentTabId = useSelector(
    pagesModel.selectors.selectCurrentContentIdWithError
  );

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const send = (prompt: string) => {
    if (!provider) return;

    const tabs = getContentItems(provider);
    const pageContent = generateXmlJSX({
      pageTitle: title,
      tabs,
      isBranded: currentPage.isBranded,
      currentTabId,
      userName: currentUser.displayName,
    });

    analytics.track(AnalyticsEvents.AGENT_MESSAGE_SENT, {
      pageId: currentPage.id,
      prompt,
    });

    dispatch(
      chatModel.actions.generateMessage({
        prompt,
        pageContent,
        tabs: tabs.reduce((acc, value) => ({ ...acc, [value.id]: value }), {}),
      })
    );
  };

  return { send };
};
