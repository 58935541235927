import React, { useMemo } from 'react';
import {
  Tabs,
  TabContent,
  Icon,
  Input,
  Avatar,
  Dropdown,
  Loader,
} from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { useSelector, useDispatch } from 'react-redux';
import { callModel } from '../model';
import { EmptyState } from '../../call-recordings';
import { teamsModel } from '../../teams';
import { CallItem } from './CallItem';
import { Pagination } from '../../../pages/pages-overview/ui/components/Pagination';
import { EditCallItemModal } from './EditCallItemModal';
import { DeleteCallItemModal } from './DeleteCallItemModal';
import { TogglePrivacyCallItemModal } from './TogglePrivacyCallItemModal';

const tabNames = {
  myCalls: 'my-calls',
  teamCalls: 'team-calls',
};
const tabs: TabContent[] = [
  {
    name: tabNames.myCalls,
    title: 'My Calls',
    icon: IconMap.Lead,
  },
  {
    name: tabNames.teamCalls,
    title: 'Team Calls',
    icon: IconMap.Building,
  },
];

const DEFAULT_OWNER_ID = 'all';
const sortItems = [
  { label: 'Last created', id: 'createdAt' },
  { label: 'Name', id: 'name' },
];

export const CallList = () => {
  const dispatch = useDispatch();

  const callList = useSelector(callModel.selectors.selectCalls);
  const callListOptions = useSelector(
    callModel.selectors.selectCallListOptions
  );
  const callListMeta = useSelector(callModel.selectors.selectCallListMeta);
  const currentTeamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );
  const isCallListLoading = useSelector(
    callModel.selectors.selectIsCallListLoading
  );
  const editingCallItem = useSelector(
    callModel.selectors.selectEditingCallItem
  );
  const deletingCallItem = useSelector(
    callModel.selectors.selectDeletingCallItem
  );
  const togglingCallItem = useSelector(
    callModel.selectors.selectTogglingCallItem
  );
  const selectedTab = callListOptions.teamOnly ? tabs[1] : tabs[0];

  const owners = useMemo(
    () => [
      {
        label: 'All owners',
        id: DEFAULT_OWNER_ID,
      },
      ...currentTeamMembers
        .filter((member) => member.user)
        .map((member) => ({
          label: (
            <div className="flex flex-row gap-x-3 w-full">
              <Avatar
                displayName={member.user?.displayName ?? ''}
                src={member.user?.photoUrl}
                isUserAvatar
                size="xs"
              />
              <p className="font-medium text-s text-gray-700 truncate">
                {member.user?.displayName}
              </p>
              <p className="text-gray-600 text-s truncate">
                {member.user?.email}
              </p>
            </div>
          ),
          headerLabel: member.user?.displayName,
          id: member.user?.id as string,
        })),
    ],
    [currentTeamMembers]
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      callModel.actions.setListOptions({
        ...callListOptions,
        search: e.target.value || undefined,
      })
    );
    dispatch(callModel.actions.searchCalls());
  };

  const handleChangePage = (page: number) => {
    dispatch(
      callModel.actions.setListMeta({
        ...callListMeta,
        currentPage: page,
      })
    );
    dispatch(callModel.actions.loadCalls());
  };

  const handleOwnerChange = (ownerId: string) => {
    const userId = ownerId === DEFAULT_OWNER_ID ? undefined : ownerId;
    dispatch(
      callModel.actions.setListOptions({
        ...callListOptions,
        filterByMember: userId,
      })
    );
    dispatch(callModel.actions.loadCalls());
  };

  const handleSortChange = (sortId: string) => {
    dispatch(
      callModel.actions.setListOptions({
        ...callListOptions,
        sortBy: sortId as 'name' | 'createdAt',
      })
    );
    dispatch(callModel.actions.loadCalls());
  };

  const handleTabChange = (tab: TabContent) => {
    dispatch(callModel.actions.resetOptions());
    dispatch(
      callModel.actions.setListOptions({
        teamOnly: tab.name === 'team-calls' ? true : undefined,
      })
    );
    dispatch(callModel.actions.loadCalls());
  };

  return (
    <Tabs tabs={tabs} selectedTab={selectedTab} onSelectTab={handleTabChange}>
      <div className="flex justify-between w-full gap-4 flex-wrap items-baseline">
        <div className="flex items-center relative gap-4">
          <Icon
            glyph={IconMap.SearchLg}
            width={20}
            className="absolute left-3.5 top-2.5 z-10 text-gray-500"
          />
          <Input
            type="text"
            placeholder="Search"
            value={callListOptions.search ?? ''}
            onChange={onSearchChange}
            heightSize="md"
            className="!pl-10.5 min-w-61"
          />
          <span className="text-sm font-normal text-gray-700 whitespace-nowrap">
            {`${callListMeta.totalItems} ${
              callListMeta.totalItems === 1 ? 'call' : 'calls'
            }`}
          </span>
        </div>
        <div className="flex flex-1 gap-4 flex-wrap justify-end lg:justify-start min-w-49">
          {selectedTab.name !== tabNames.myCalls && (
            <Dropdown
              align="start"
              listStyles="shadow-lg min-w-48 max-w-96"
              items={owners}
              currentItemId={callListOptions.filterByMember || DEFAULT_OWNER_ID}
              itemStyles="min-w-44"
              triggerStyles="w-48 max-w-48 text-sm !text-gray-700 shrink-0"
              onItemChange={handleOwnerChange}
            />
          )}
          <Dropdown
            listStyles="shadow-lg"
            items={sortItems}
            currentItemId={callListOptions.sortBy}
            itemStyles="w-44 max-w-48"
            triggerStyles="w-48 text-sm !text-gray-700 max-w-48 shrink-0"
            onItemChange={handleSortChange}
          />
        </div>
      </div>
      {isCallListLoading ? (
        <div className="flex-1">
          <Loader />
        </div>
      ) : (
        <section className="flex flex-wrap gap-5 py-6">
          {callList.map((call) => (
            <CallItem key={call.id} item={call} />
          ))}
        </section>
      )}

      {callList.length === 0 && !isCallListLoading && (
        <div className="h-full flex items-center justify-center">
          <EmptyState />
        </div>
      )}
      <Pagination
        isHideIfOnlyOnePage
        className="px-6 py-3.5 border-t border-t-solid border-t-gray-200"
        currentPage={callListMeta.currentPage}
        lastPage={callListMeta.totalPages ?? 0}
        maxLength={5} // max number of pages to show
        setCurrentPage={handleChangePage}
      />
      {editingCallItem && <EditCallItemModal />}
      {deletingCallItem && <DeleteCallItemModal isFromList />}
      {togglingCallItem && <TogglePrivacyCallItemModal isFromList />}
    </Tabs>
  );
};
