import { put, select } from 'redux-saga/effects';
import {
  editorLeftSidebarModel,
  LeftSidebarOptions,
} from '../../../../features/editor-left-sidebar';
import { editorRightSidebarModel } from '../../../../features/editor-right-sidebar';

export function* closeRightSidebar() {
  const leftSidebarOption: LeftSidebarOptions = yield select(
    editorLeftSidebarModel.selectors.selectSidebarOption
  );

  if (leftSidebarOption) {
    yield put(editorRightSidebarModel.actions.setSelectedOption(null));
  }
}
