import { call, put, select } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import { messages } from '../../config';
import { recordingsModel } from '..';
import { redirect } from '../../../../entities/history';
import { collaborationApi } from '../../../../shared/api/axios/collaboration';
import { teamsModel } from '../../../teams';
import { RT } from '../../../../shared/types';
import { DEFAULT_RATIO } from '@distribute/shared/utils';
import { JSONContentBuilder } from '@distribute/shared/tiptap/json-content';

export function* insertVideoToPage(
  action: ReturnType<typeof actions.insertVideoToPage>
) {
  const { payload } = action;
  const { cb, page, record } = payload;

  try {
    yield put(recordingsModel.actions.setIsRecordInsertToPageLoading(true));

    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    yield call(collaborationApi.addContentJsonToFirstPageTab, {
      pageId: page.id,
      teamId: currentTeam.id,
      content: JSONContentBuilder.videoRecord({
        attrs: {
          prefix: record.prefix,
          playbackId: record.playbackId || '',
          aspectRatio: record.metadata.mux.aspectRatio || DEFAULT_RATIO,
        },
      }).asArray(),
    });

    yield call(redirect.toEditorPage, {
      sequenceNumber: page.sequenceNumber,
    });
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', messages.record.insertVideoToPageError)
      )
    );
  } finally {
    cb?.();
    yield put(recordingsModel.actions.setIsRecordInsertToPageLoading(false));
  }
}
