import React from 'react';
import { IconMap } from '../../../../shared/sprite';
import { useDispatch, useSelector } from 'react-redux';
import { EditorPreviewModeEnum } from '../../../../features/pages/model/types';
import { pagesModel } from '../../../../features/pages';
import { TabsSwitcher } from '../../../../features/editor-left-sidebar/ui/components/shared';

const previewOptions = [
  {
    id: EditorPreviewModeEnum.DESKTOP,
    name: 'Desktop',
    icon: IconMap.Monitor03,
  },
  {
    id: EditorPreviewModeEnum.TABLET,
    name: 'Tablet',
    icon: IconMap.Tablet01,
  },
  {
    id: EditorPreviewModeEnum.MOBILE,
    name: 'Mobile',
    icon: IconMap.Phone02,
  },
];

export const PreviewBar: React.FC = () => {
  const dispatch = useDispatch();
  const activePreview = useSelector(
    pagesModel.selectors.selectEditorPreviewMode
  );
  const setActivePreview = (mode: string) => {
    dispatch(
      pagesModel.actions.setEditorPreviewMode(mode as EditorPreviewModeEnum)
    );
  };

  return (
    <div className="flex justify-center flex-1 py-3 shrink-0">
      <div className="w-70">
        <TabsSwitcher
          tabs={previewOptions}
          selectedTab={activePreview}
          setSelectedTab={(id) => setActivePreview(id)}
        />
      </div>
    </div>
  );
};
