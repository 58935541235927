import { FC, useContext, useMemo } from 'react';
import context from './context';
import {
  addNewEmptyContact,
  ContactType,
  contactTypes,
  deserializeContacts,
  serializeContacts,
} from '@distribute/shared/generate-html';
import { Icon } from '../../../../../../shared/ui';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from '../../../../../../shared/ui/select/SelectV2';
import { IconMap } from '../../../../../../shared/sprite';
import ContactInput, { contactConfigTypeConfig } from './ContactInput';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

const Contacts: FC = () => {
  const { form } = useContext(context);

  const state = form.watch();

  const contacts = useMemo(
    () => deserializeContacts(state.serializedContacts),
    [state.serializedContacts]
  );

  const onAddEmptyContact = (contactType: string) => {
    form.setValue(
      'serializedContacts',
      addNewEmptyContact(state.serializedContacts, contactType as ContactType)
    );
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return;
    }

    const [removed] = contacts.splice(result.source.index, 1);
    contacts.splice(result.destination.index, 0, removed);

    contacts.forEach((contact, index) => {
      contact.order = index;
    });

    form.setValue('serializedContacts', serializeContacts(contacts));
  };

  return (
    <div className="flex flex-col gap-y-4">
      <span className="font-semibold">Contacts</span>
      <DragDropContext onDragEnd={onDragEnd} enableDefaultSensors={true}>
        <Droppable droppableId="contacts" direction="vertical">
          {(provided) => (
            <div
              className="flex flex-col gap-y-2"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {contacts.map((contact) => (
                <ContactInput
                  {...contact}
                  isDragDisabled={contacts.length === 1}
                  key={contact.id}
                />
              ))}
              {provided.placeholder}
              <Select value=" " onValueChange={onAddEmptyContact}>
                <SelectTrigger showArrow={false} className="w-min h-auto">
                  <span className="text-sm flex flex-row flex-nowrap gap-x-1">
                    <Icon glyph={IconMap.Plus} width={20} /> Add contact item
                  </span>
                </SelectTrigger>
                <SelectContent>
                  {contactTypes.map((type) => (
                    <SelectItem key={type} value={type}>
                      <span className="flex items-center gap-x-2">
                        <Icon
                          glyph={contactConfigTypeConfig[type].icon}
                          width={20}
                        />
                        {contactConfigTypeConfig[type].title}
                      </span>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Contacts;
