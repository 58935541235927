import { useMemo, useState } from 'react';

import { Button, ButtonSquare, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { useSelector } from 'react-redux';
import { chatModel } from '../model';
import {
  ChatResponseType,
  ChatStructuredSuggestionData,
} from '@distribute/shared/types';
import { useSuggestionNavigation } from '../hooks/use-suggestion-navigation';
import { useEditorSuggestion } from '../../../entities/suggestions-editor';

export const ChatBotApplySuggestionsBlock = () => {
  const [show, setShow] = useState(false);
  const { navigateTo } = useSuggestionNavigation();

  const conversation = useSelector(
    chatModel.selectors.selectConversationWithError
  );

  const { onAccept, onReject } = useEditorSuggestion();

  const tabs = useMemo(
    () =>
      conversation.structuredResponse.filter(
        (response) =>
          response.type === ChatResponseType.Suggestion && response.changed
      ),
    [conversation]
  ) as ChatStructuredSuggestionData[];

  //prettier-ignore
  const message = `(${tabs.length} ${tabs.length > 1 ? 'tabs' : 'tab'} changed)`;

  return (
    <div className="flex overflow-hidden relative w-full rounded-xl bg-gradient-orange-to-purple p-0.5">
      <div className="flex flex-col gap-y-3 w-full bg-base-white p-2.75 rounded-[10px]">
        <div className="flex flex-nowrap gap-x-2 items-center">
          <ButtonSquare
            size="xs"
            variant="tertiary-gray"
            onClick={() => setShow((prev) => !prev)}
            className="self-start flex-shrink-0"
          >
            <Icon
              glyph={show ? IconMap.ChevronUp : IconMap.ChevronDown}
              width={16}
            />
          </ButtonSquare>
          <div className="flex flex-col gap-y-1 overflow-hidden">
            <p className="text-md font-medium text-gray-700 truncate">
              Page updated{' '}
              <span className="text-sm text-gray-500 font-normal">
                {message}
              </span>
            </p>
            {show && (
              <div className="flex flex-col items-start">
                {tabs.map((t) => (
                  <button
                    key={t.id}
                    className="py-0.5 px-1 flex flex-nowrap gap-x-1 items-center text-sm font-medium text-gray-700 rounded-md hover:text-gray-800 hover:bg-base-black/10 w-full overflow-hidden"
                    type="button"
                    onClick={() => navigateTo(parseInt(t.id))}
                  >
                    <Icon
                      width={16}
                      glyph={IconMap.ArrowCircleUp}
                      className="-rotate-45 flex-shrink-0"
                    />
                    <span className="truncate min-w-0">{t.name}</span>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-nowrap gap-x-2 items-center justify-end">
          <Button
            variant="text"
            color="secondary-destructive"
            size="sm"
            className="!h-8 !font-semibold"
            onClick={onReject}
          >
            Reject all
          </Button>
          <Button
            variant="text"
            color="primary"
            size="sm"
            className="!h-8 !font-semibold"
            onClick={onAccept}
          >
            Accept all
          </Button>
        </div>
      </div>
    </div>
  );
};
