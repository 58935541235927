import YPartyKitProvider from 'y-partykit/provider';
import { DocumentContentItem } from '@distribute/shared/types';
import { TiptapTransformer } from '@hocuspocus/transformer';
import { JSONContent } from '@tiptap/core';

export const getContentItems = (provider: YPartyKitProvider) => {
  const tabsSettings = provider.doc.getMap('tabsSettings');
  const tabs = tabsSettings.get('tabs') as DocumentContentItem[];

  const tabIds = tabs
    .map((item) => item.id?.toString())
    .filter(Boolean) as string[];

  const xmlFragments = TiptapTransformer.fromYdoc(provider.doc, tabIds);
  const tabsContent = Object.entries(xmlFragments);

  const contentItems = tabs.map(({ id, ...rest }) => {
    const contentJson = tabsContent.find(
      ([key]) => key === id?.toString()
    )?.[1] as JSONContent;

    return {
      id: +id,
      ...rest,
      contentJson,
    };
  });

  return contentItems as DocumentContentItem[];
};
