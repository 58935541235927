import { select, call, put, all, fork, takeEvery } from 'redux-saga/effects';
import { Folder, Page } from '@distribute/shared/types';
import { matchParams } from '../../../shared/lib';
import { pagesModel } from '../../../features/pages';
import { authUserModel, getUserFromFb } from '../../../entities/auth-user';
import { FirebaseUser } from '../../../shared/types';
import { foldersModel } from '../../../features/folders';
import { setPageSettingsData } from '../../../features/page-settings';
import {
  setAlertBarData,
  setCTAData,
  setPopUpData,
  setGatedContentData,
  setSqueezePageData,
  setRequireEmailToViewData,
} from '../../../features/conversion-kit';
import { narrationModel, setNarrationData } from '../../../features/narration';
import { chatModel } from '../../../features/chat';
import { analyticsModel } from '../../../../src/features/analytics';
import { getRangeByPeriod } from '../../../../src/shared/ui/date-range-picker';

import { newEditorPageWorker } from './newEditorPageWorker';
import { getQueryParam } from '../../../shared/lib';
import { QUERY_PARAM_CURRENT_OPENED_TAB } from '../../../features/pages';
import { setTasklists } from '../../../features/tasks/model/sagas';
import { hideGleapBlock } from '../../../entities/gleap';

export function* newEditorPageSaga(location: Location, route: string) {
  yield fork(newEditorPageWorker);
  yield fork(narrationModel.sagas.narrationWorker);
  yield call(hideGleapBlock);

  const { pathname } = location;
  const params = matchParams(pathname, route);

  const user: FirebaseUser = yield getUserFromFb();
  const token: string = yield user.getIdToken();

  const pages: Page[] = yield select(pagesModel.selectors.selectPages);
  const page = pages.find((i) => params?.sequenceNumber === i.sequenceNumber);
  if (!page) {
    yield call(
      pagesModel.sagas.redirectToPublicPage,
      params?.sequenceNumber ?? ''
    );
    return;
  }

  //analytics
  yield takeEvery(
    analyticsModel.actions.onChangeRange,
    analyticsModel.sagas.onChangeRange
  );
  yield put(analyticsModel.actions.setDateRangePeriod('month'));
  yield put(analyticsModel.actions.setDateRange(getRangeByPeriod('month')));
  yield fork(analyticsModel.sagas.setAnalyticsData, page);

  yield put(pagesModel.actions.setCurrentPage(page));

  yield fork(chatModel.sagas.chatWorker, page.id);

  const openedContentItemId = getQueryParam(QUERY_PARAM_CURRENT_OPENED_TAB);
  let currentContent = page.content.contentItems[0];
  if (openedContentItemId) {
    currentContent =
      page.content.contentItems.find(
        ({ id }) => id === Number(openedContentItemId)
      ) ?? page.content.contentItems[0];
  }

  yield put(
    pagesModel.actions.setCurrentContentItem({ contentItem: currentContent })
  );

  yield put(pagesModel.actions.setPageEditorDataIsLoading(true));
  yield all([
    call(setPageSettingsData, page.content.id),
    call(setAlertBarData, page.content.id),
    call(setPopUpData, page.content.id),
    call(setCTAData, page.content.id),
    call(setGatedContentData, page.content.id),
    call(setSqueezePageData, page.content.id),
    call(setRequireEmailToViewData, page.content.id),
    call(setNarrationData, page.id),
    call(setTasklists, page.content.id),
  ]);
  yield put(pagesModel.actions.setPageEditorDataIsLoading(false));

  const folders: Folder[] = yield select(foldersModel.selectors.selectFolders);
  const currentFolder = folders.find((i) => page?.folderId === i.id);
  yield put(foldersModel.actions.setCurrentFolder(currentFolder));
  yield put(authUserModel.actions.setAuthToken(token));
}
