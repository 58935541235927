import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';
import {
  ChatConversation,
  ConversationState,
  GenerateMessageAction,
  GetChatsAction,
  UpvoteMessageAction,
} from './types';
import {
  GetChatResponse,
  GetChatsResponse,
} from '@distribute/shared/api-types/chat';

type State = {
  isChatLoading: boolean;
  isChatDeleting: boolean;
  isChatDeleteModalOpen: boolean;
  chats: GetChatsResponse[];
  currentChat?: GetChatResponse | null;
  conversation: ChatConversation | null;
  conversationState: ConversationState;
  loadingMessageIds: string[];
  isFocusInput: boolean;
};

const initialState: State = {
  isChatLoading: false,
  isChatDeleting: false,
  isChatDeleteModalOpen: false,
  chats: [],
  currentChat: undefined,
  conversation: null,
  conversationState: ConversationState.Idle,
  loadingMessageIds: [],
  isFocusInput: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessageLoading: (
      state,
      {
        payload: { id, loading },
      }: PayloadAction<{ id: string; loading: boolean }>
    ) => {
      if (loading) {
        state.loadingMessageIds.push(id);
      } else {
        state.loadingMessageIds = state.loadingMessageIds.filter(
          (_id) => _id !== id
        );
      }
    },
    updateMessage: (
      state,
      {
        payload: { id, upvote },
      }: PayloadAction<{ id: string; upvote: boolean }>
    ) => {
      if (!state.currentChat) return;

      state.currentChat.messages = state.currentChat.messages.map((m) =>
        m.id === id ? { ...m, upvote } : m
      );
    },
    setIsChatLoading: (
      state,
      { payload: isChatLoading }: PayloadAction<State['isChatLoading']>
    ) => ({ ...state, isChatLoading }),
    setIsChatDeleting: (
      state,
      { payload: isChatDeleting }: PayloadAction<State['isChatDeleting']>
    ) => ({ ...state, isChatDeleting }),
    setIsChatDeleteModalOpen: (
      state,
      {
        payload: isChatDeleteModalOpen,
      }: PayloadAction<State['isChatDeleteModalOpen']>
    ) => ({ ...state, isChatDeleteModalOpen }),
    setChats: (state, { payload: chats }: PayloadAction<State['chats']>) => ({
      ...state,
      chats,
    }),
    setCurrentChat: (
      state,
      { payload: currentChat }: PayloadAction<State['currentChat']>
    ) => ({ ...state, currentChat }),
    setConversation: (
      state,
      { payload: conversation }: PayloadAction<State['conversation']>
    ) => ({ ...state, conversation }),
    clearConversation: (state) => ({
      ...state,
      conversation: initialState.conversation,
    }),
    setConversationResponse: (
      state,
      {
        payload: { response, structuredResponse },
      }: PayloadAction<{
        response: ChatConversation['response'];
        structuredResponse: ChatConversation['structuredResponse'] | null;
      }>
    ) => {
      if (!state.conversation) return;

      state.conversation.response = response;

      if (structuredResponse) {
        state.conversation.structuredResponse = structuredResponse;
      }
    },
    setConversationState: (
      state,
      { payload: conversationState }: PayloadAction<State['conversationState']>
    ) => ({ ...state, conversationState }),
    setIsFocusInput: (
      state,
      { payload: isFocusInput }: PayloadAction<State['isFocusInput']>
    ) => ({
      ...state,
      isFocusInput,
    }),
    clearFlow: () => initialState,
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  getChats: createAction<GetChatsAction>('chat/getChats'),
  generateMessage: createAction<GenerateMessageAction>('chat/generateMessage'),
  deleteChatMessages: createAction('chat/deleteChatMessages'),
  stopGenerateMessage: createAction('chat/stopGenerateMessage'),
  applySuggestions: createAction('chat/applySuggestions'),
  rejectSuggestions: createAction('chat/rejectSuggestions'),
  upvote: createAction<UpvoteMessageAction>('chat/upvote'),
};
