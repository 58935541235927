import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TasklistWithTasks } from '@distribute/shared/types';
import { Icon } from '../../../../src/shared/ui';
import { IconMap } from '../../../../src/shared/sprite';
import { TaskItem } from './TaskItem';
import { useDispatch, useSelector } from 'react-redux';
import { authUserModel } from '../../../../src/entities/auth-user';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { tasksModel } from '../model';
import { cn } from '@distribute/frontend/utils';
import ReactTextareaAutosize from 'react-textarea-autosize';

type Props = {
  updateAttributes: (attributes: Record<string, any>) => void;
  insertNewTasklist: () => void;
  updatedAt: string;
  updatedBy: string;
  documentContentId: number;
  id: string;
  isDisabled: boolean;
};

type UpdateTasklistInput = {
  title?: string;
  isOpen?: boolean;
};

export const CustomTaskList: FC<Props> = ({
  insertNewTasklist,
  updateAttributes,
  updatedAt,
  updatedBy,
  documentContentId,
  id,
  isDisabled = false,
}) => {
  const originalTasklist = useSelector(tasksModel.selectors.selectTasklists)[
    id
  ];

  const dispatch = useDispatch();

  const [tasklist, setTasklist] = useState(originalTasklist);

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const changeAttributeForDataUpdate = useCallback(() => {
    updateAttributes({
      updatedAt: new Date().toString(),
      updatedBy: currentUser.id,
    });
  }, [updateAttributes, currentUser.id]);

  const handleTasklistUpdate = (data: UpdateTasklistInput) => {
    setTasklist((prevTasklist) => {
      return { ...prevTasklist, ...data };
    });

    dispatch(
      tasksModel.actions.updateTasklist({
        ...data,
        tasklistId: tasklist.id,
        documentContentId,
        successCallback: changeAttributeForDataUpdate,
        failedCallback: () => {
          setTasklist(originalTasklist);
        },
      })
    );
  };

  const addNewTask = () => {
    dispatch(
      tasksModel.actions.addTask({
        tasklistId: tasklist.id,
        documentContentId,
        successCallback: (newTasklist: TasklistWithTasks) => {
          setTasklist(newTasklist);
          changeAttributeForDataUpdate();
        },
      })
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    dispatch(
      tasksModel.actions.reorderTasks({
        tasklistId: tasklist.id,
        documentContentId,
        sourceIndex,
        destinationIndex,
        updateTasklist: (newTasklist: TasklistWithTasks) => {
          setTasklist(newTasklist);
        },
        successCallback: () => {
          changeAttributeForDataUpdate();
        },
      })
    );
  };

  const completedString = useMemo(() => {
    const completedTasksAmount = originalTasklist?.tasks.filter(
      (t) => t.isDone
    ).length;
    const allTasksAmount = originalTasklist?.tasks.length;

    return `${completedTasksAmount}/${allTasksAmount} completed`;
  }, [originalTasklist]);

  useEffect(() => {
    if (updatedBy !== currentUser.id) {
      dispatch(
        tasksModel.actions.updateTasklistDataFromDb({
          tasklistId: tasklist.id,
          successCallback: (newTasklist) => {
            setTasklist(newTasklist);
          },
        })
      );
    }
  }, [
    updatedAt,
    updatedBy,
    setTasklist,
    currentUser.id,
    dispatch,
    tasklist.id,
  ]);

  return (
    <div className="my-10">
      <div data-type="customTaskList" data-id={tasklist.id}>
        <div
          className={cn('flex items-start justify-between w-full gap-2', {
            'mb-6': tasklist.isOpen,
          })}
        >
          <div className="flex flex-grow gap-2">
            <button
              className="rounded-1.25 hover:bg-gray-100 text-gray-900 pointer-events-auto w-6 h-6 flex justify-center items-center mt-1.5"
              onClick={() => {
                handleTasklistUpdate({ isOpen: !tasklist.isOpen });
              }}
            >
              <Icon
                glyph={IconMap.ToggleIcon}
                width={20}
                className={cn('text-gray-700', {
                  'rotate-90': tasklist.isOpen,
                })}
              />
            </button>
            <ReactTextareaAutosize
              disabled={isDisabled}
              placeholder="Task list name"
              className="flex-grow text-3xl font-bold text-gray-900 border-none outline-none resize-none placeholder:text-gray-400"
              maxLength={200}
              minRows={1}
              maxRows={6}
              value={tasklist.title}
              onChange={(e) => {
                handleTasklistUpdate({ title: e.target.value });
              }}
            />
          </div>
          <span className="mt-1 text-lg text-gray-500">{completedString}</span>
        </div>

        <div
          className={cn({
            hidden: !tasklist.isOpen,
            block: tasklist.isOpen,
          })}
        >
          <div className="w-full h-px mb-6 bg-gray-200" />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`droppableTasks-${tasklist.id}`}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {tasklist.tasks.map((task, index) => (
                    <Draggable
                      isDragDisabled={tasklist.tasks.length === 1 || isDisabled}
                      key={task.id}
                      draggableId={`${task.id}`}
                      index={index + 1}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            key={task.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                          >
                            <TaskItem
                              key={task.id}
                              task={task}
                              changeAttributeForDataUpdate={
                                changeAttributeForDataUpdate
                              }
                              tasklistId={tasklist.id}
                              documentContentId={documentContentId}
                              setTasklist={setTasklist}
                              isDragging={snapshot.isDragging}
                              isDisabled={isDisabled}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className="flex justify-between">
            <button
              disabled={isDisabled}
              contentEditable={false}
              className="flex items-center gap-1.5 px-5"
              onClick={addNewTask}
            >
              <Icon glyph={IconMap.Plus} width={20} className="text-gray-600" />
              <span className="text-sm font-semibold text-gray-600">
                New task
              </span>
            </button>

            <button
              disabled={isDisabled}
              contentEditable={false}
              className="flex items-center gap-1.5"
              onClick={insertNewTasklist}
            >
              <Icon glyph={IconMap.Plus} width={24} className="text-gray-600" />
              <span className="text-sm font-semibold text-gray-600">
                Another task list
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
