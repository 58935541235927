import React from 'react';
import { useDispatch } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { IconMap } from '../../../../../shared/sprite';
import {
  ActionTypeAlertBar,
  ActionTypeCTA,
  ActionTypePopUp,
} from '@distribute/shared/types';
import { EditorSidebarConversionItem } from './';
import { useCollaboration } from '../../../../../entities/collaboration';
import { ActiveConversionPanel, editorLeftSidebarModel } from '../../../model';

export const EditorSidebarConversions: React.FC = () => {
  const dispatch = useDispatch();

  const {
    alertBarData,
    popUpData,
    ctaData,
    gatedContentData,
    updateAlertBarField,
    updateCTAField,
    updatePopupField,
    updateGatedContentField,
    squeezePageData,
    updateSqueezePageField,
  } = useCollaboration();

  const {
    isActive: isAlertBarActive,
    actionType: alertBarActionType,
    description: alertBarDescription,
  } = alertBarData;
  const {
    isActive: isPopUpActive,
    actionType: popUpActionType,
    title: popUpTitle,
    calendarSchedulingLink: popUpCalendarSchedulingLink,
    isTriggerExit: popUpIsTriggerExit,
    isTriggerTime: popUpIsTriggerTime,
    buttonLabelFormType: popUpButtonLabelFormType,
  } = popUpData;
  const {
    isActive: isCTAActive,
    buttonLabelFormType: CTAButtonLabelFormType,
    actionType: CTAActionType,
  } = ctaData;
  const {
    isActive: isGatedContentActive,
    buttonLabelFormType: gatedContentButtonLabelFormType,
  } = gatedContentData;
  const { isActive: isSqueezePageActive, buttonLabel: squeezePageButtonLabel } =
    squeezePageData;

  const toggleAlertBarStatus = () => {
    updateAlertBarField('isActive', !isAlertBarActive);
  };

  const togglePopUpStatus = () => {
    updatePopupField('isActive', !isPopUpActive);
  };

  const toggleCTAStatus = () => {
    updateCTAField('isActive', !isCTAActive);
  };

  const toggleGatedContentStatus = () => {
    updateGatedContentField('isActive', !isGatedContentActive);
  };

  const toggleSqueezePageStatus = () => {
    updateSqueezePageField('isActive', !isSqueezePageActive);
  };

  const isAlertBarPopUpWarning =
    alertBarActionType === ActionTypeAlertBar.POPUP &&
    (popUpActionType === ActionTypePopUp.CALENDLY
      ? !popUpCalendarSchedulingLink
      : !popUpTitle);

  const isPopUpWarning =
    isPopUpActive &&
    !popUpIsTriggerExit &&
    !popUpIsTriggerTime &&
    (!isAlertBarActive || alertBarActionType !== ActionTypeAlertBar.POPUP) &&
    (!isCTAActive || CTAActionType !== ActionTypeCTA.POPUP);

  const isCTAWarning =
    CTAActionType === ActionTypeCTA.POPUP &&
    (popUpActionType === ActionTypePopUp.CALENDLY
      ? !popUpCalendarSchedulingLink
      : !popUpTitle);

  return (
    <div className="flex flex-col flex-grow-1 gap-3">
      <EditorSidebarConversionItem
        title="Alert Bar"
        description="Bar at the top of the page."
        icon={IconMap.AlertBarIcon}
        onClick={() => {
          dispatch(
            editorLeftSidebarModel.actions.setActiveConversionPanel(
              ActiveConversionPanel.ALERT_BAR
            )
          );

          setTimeout(() => {
            const alertBarBlock =
              document.getElementsByClassName('alert-bar-block')[0];
            if (alertBarBlock) {
              alertBarBlock.scrollIntoView({ behavior: 'smooth' });
            }
          }, 0);
        }}
        shouldDisplayToggle={!!alertBarDescription}
        isActive={isAlertBarActive}
        handleToggle={toggleAlertBarStatus}
        isSetupPopupWarning={isAlertBarPopUpWarning}
      />
      <EditorSidebarConversionItem
        title="Pop-up"
        description="Timed or mouse-activated modal."
        icon={IconMap.PopupIcon}
        onClick={() =>
          dispatch(
            editorLeftSidebarModel.actions.setActiveConversionPanel(
              ActiveConversionPanel.POP_UP
            )
          )
        }
        shouldDisplayToggle={popUpButtonLabelFormType !== null}
        isActive={isPopUpActive}
        handleToggle={togglePopUpStatus}
        onWarningClick={() =>
          dispatch(
            editorSidebarModel.actions.setSelectedPopupSection('triggers')
          )
        }
        isPopupWarning={isPopUpWarning}
      />
      <EditorSidebarConversionItem
        title="CTA Section"
        description="Section at the end of the page."
        icon={IconMap.ActionBannerIcon}
        onClick={() => {
          dispatch(
            editorLeftSidebarModel.actions.setActiveConversionPanel(
              ActiveConversionPanel.CTA
            )
          );

          setTimeout(() => {
            const ctaBlock = document.getElementsByClassName('cta-block')[0];
            if (ctaBlock) {
              ctaBlock.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
          }, 0);
        }}
        shouldDisplayToggle={CTAButtonLabelFormType !== null}
        isActive={isCTAActive}
        handleToggle={toggleCTAStatus}
        isSetupPopupWarning={isCTAWarning}
      />
      <EditorSidebarConversionItem
        title="Gated Content"
        description="Content is hidden from view."
        icon={IconMap.GatedContentIcon}
        onClick={() =>
          dispatch(
            editorLeftSidebarModel.actions.setActiveConversionPanel(
              ActiveConversionPanel.GATED_CONTENT
            )
          )
        }
        shouldDisplayToggle={gatedContentButtonLabelFormType !== null}
        isActive={isGatedContentActive}
        handleToggle={toggleGatedContentStatus}
      />
      <EditorSidebarConversionItem
        title="Squeeze Page"
        description="Page is hidden from view."
        icon={IconMap.SqueezePageIcon}
        onClick={() =>
          dispatch(
            editorLeftSidebarModel.actions.setActiveConversionPanel(
              ActiveConversionPanel.SQUEEZE_PAGE
            )
          )
        }
        shouldDisplayToggle={squeezePageButtonLabel !== null}
        isActive={isSqueezePageActive}
        handleToggle={toggleSqueezePageStatus}
      />
    </div>
  );
};
