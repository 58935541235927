import {
  MeetingEmailNotification,
  MeetingJoinPreference,
} from '@distribute/shared/types';

export const joinPreferenceOptions = [
  {
    name: 'join-all',
    value: MeetingJoinPreference.JOIN_ALL,
    label: 'Join all meetings in calendar',
  },
  {
    name: 'join-organized',
    value: MeetingJoinPreference.JOIN_ORGANIZED,
    label: 'Join only meetings I organize',
  },
  {
    name: 'join-external',
    value: MeetingJoinPreference.JOIN_EXTERNAL,
    label: 'Join only external meetings',
  },
  {
    name: 'no-auto-join',
    value: MeetingJoinPreference.NO_AUTO_JOIN,
    label: 'Don’t auto-join any meetings',
  },
] as const;

export const emailNotificationOptions = [
  {
    name: 'send-all',
    value: MeetingEmailNotification.ALL_PARTICIPANTS,
    label: 'Send email to all participants',
  },
  {
    name: 'send-internal',
    value: MeetingEmailNotification.INTERNAL_PARTICIPANTS,
    label: 'Send email to internal participants',
  },
  {
    name: 'send-me',
    value: MeetingEmailNotification.ONLY_ME,
    label: 'Send email only to me',
  },
  {
    name: 'no-email',
    value: MeetingEmailNotification.NO_EMAIL,
    label: 'Don’t send email at all',
  },
] as const;
