import { TranscriptsFilterTypesEnum } from '../../../../../../page-creation-flow/config/types';

export const transcriptsFilterList = [
  { id: TranscriptsFilterTypesEnum.SPECIFIC, title: 'specific calls' },
  { id: TranscriptsFilterTypesEnum.LAST_CALL, title: 'only last call' },
  { id: TranscriptsFilterTypesEnum.LAST_MONTH, title: 'for last month' },
  { id: TranscriptsFilterTypesEnum.LAST_3_MONTHS, title: 'for last 3 months' },
  { id: TranscriptsFilterTypesEnum.LAST_6_MONTHS, title: 'for last 6 months' },
  { id: TranscriptsFilterTypesEnum.LAST_YEAR, title: 'for last year' },
  {
    id: TranscriptsFilterTypesEnum.ALL_AVAILABLE,
    title: 'all available calls',
  },
];
