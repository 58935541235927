import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { createContext, useContext } from 'react';

export type TemplatesTabContextType = {
  handleUseTemplateClick: (template: TemplateExtended) => void;
  handlePreviewTemplate: (template: TemplateExtended) => void;
};

export const TemplatesTabContext =
  createContext<TemplatesTabContextType | null>(null);

export const useTemplatesTabContext = () => {
  const context = useContext(TemplatesTabContext);

  if (context === null) {
    throw new Error(
      'useTemplatesTabContext must be used within a TemplatesTabProvider'
    );
  }

  return context;
};
