import { Node } from '@tiptap/react';

export const CustomTaskListExt = Node.create({
  name: 'customTaskList',
  group: 'block',
  content: '',
  isolating: true,
  selectable: false,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      updatedAt: {
        default: '',
      },
      updatedBy: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['div', { class: 'tasklist-content' }, `{{ ${node.attrs.id} }}`];
  },
});
