import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypeAlertBar, AlertBar } from '@distribute/shared/types';
import {
  ActiveConversionPanel,
  editorLeftSidebarModel,
  LeftSidebarOptions,
} from '../../../../../../../src/features/editor-left-sidebar';
import { conversionKitModel } from '../../../../../../../src/features/conversion-kit';
import { PagePermissions } from '../../../../../../../src/features/pages/lib/getPagePermissions';
import { narrationModel } from '../../../../../../../src/features/narration';
import { subscribePopupModel } from '../../../../../../../src/features/subscribe-popup';
import { pagesModel } from '../../../../../../../src/features/pages';
import { useCollaboration } from '../../../../../../../src/entities/collaboration';
import { Button, Icon, LinkAsButton } from '../../../../../../../src/shared/ui';
import { parseHtml } from '../../../../../../../src/shared/lib/parseHtml';
import { IconMap } from '../../../../../../../src/shared/sprite';

type Props = {
  pagePermissions: PagePermissions;
  isTemplateMode: boolean;
  alertBar: AlertBar;
};

export const AlertBarComponent: React.FC<Props> = ({
  pagePermissions,
  isTemplateMode,
}) => {
  const dispatch = useDispatch();
  const isAnyConversionPanelOpen = Boolean(
    useSelector(editorLeftSidebarModel.selectors.selectActiveConversionPanel)
  );

  const isLeftSidebarOpen = Boolean(
    useSelector(editorLeftSidebarModel.selectors.selectSidebarOption)
  );

  const popUp = useSelector(conversionKitModel.selectors.selectPopUpWithError);

  const isNarrationRecordingSessionActive = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionActive
  );

  const setIsSubscribePopupOpen = useCallback(() => {
    dispatch(subscribePopupModel.actions.setIsModalOpen(true));
  }, [dispatch]);

  const isEditorPreview = useSelector(
    pagesModel.selectors.selectIsEditorPreview
  );

  const handleOpenAlertBarSettings = () => {
    dispatch(
      editorLeftSidebarModel.actions.setSelectedOption(
        LeftSidebarOptions.CONVERSIONS
      )
    );
    dispatch(
      editorLeftSidebarModel.actions.setActiveConversionPanel(
        ActiveConversionPanel.ALERT_BAR
      )
    );
  };

  const activeConversionPanel = useSelector(
    editorLeftSidebarModel.selectors.selectActiveConversionPanel
  );

  const isAlertBarPanelOpen =
    activeConversionPanel === ActiveConversionPanel.ALERT_BAR;

  const {
    alertBarData: collaborationAlertBarData,
    isCollaborationEnabled,
    popUpData,
  } = useCollaboration();

  const isSoloMode = isTemplateMode || !isCollaborationEnabled;

  const isActive = collaborationAlertBarData.isActive;
  const description = collaborationAlertBarData.description;
  const actionType = collaborationAlertBarData.actionType;
  const buttonUrl = collaborationAlertBarData.buttonUrl;
  const buttonLabelLinkType = collaborationAlertBarData.buttonLabelLinkType;
  const buttonLabelPopupType = collaborationAlertBarData.buttonLabelPopupType;

  const isPopUpActive = isSoloMode ? popUp.isActive : popUpData.isActive;

  if (!isActive && !isAlertBarPanelOpen) {
    return null;
  }

  return (
    <div className="flex justify-center border-2 border-transparent group bg-brand-900 hover:border-primary-600 alert-bar-block">
      <div
        className={classNames(
          'max-w-212 w-full text-brand-text md:px-8 sm:px-4',
          {
            'px-6': isLeftSidebarOpen,
          }
        )}
      >
        <div className="flex items-center justify-between md:gap-1 py-1.5 font-paragraph">
          {description ? (
            parseHtml(description)
          ) : (
            <p className="opacity-50">Text</p>
          )}
          <div className="relative">
            {actionType === ActionTypeAlertBar.LINK && (
              <LinkAsButton
                size="md"
                color="brand"
                fontBold
                href={buttonUrl}
                target="_blank"
              >
                {buttonLabelLinkType || 'Submit'}
              </LinkAsButton>
            )}

            {actionType === ActionTypeAlertBar.POPUP && (
              <Button
                size="md"
                color="brand"
                variant="text"
                fontBold
                onClick={isPopUpActive ? setIsSubscribePopupOpen : undefined}
              >
                {buttonLabelPopupType || 'Submit'}
              </Button>
            )}
            {pagePermissions?.isCanEditConversionTools &&
              !isAnyConversionPanelOpen && (
                <div
                  onClick={handleOpenAlertBarSettings}
                  className={classNames(
                    'bg-primary-600 pl-0.5 text-base-white w-9 h-9 items-center justify-center rounded-full border-none hover:bg-primary-700 cursor-pointer absolute -bottom-6 hidden md:group-hover:hidden z-10',
                    {
                      'group-hover:flex': !isEditorPreview,
                      '!hidden': isNarrationRecordingSessionActive,
                      '-right-5': isLeftSidebarOpen,
                      '-right-9': !isLeftSidebarOpen,
                    }
                  )}
                >
                  <Icon glyph={IconMap.Edit2Icon} width={20} />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
