import { IconMap } from '../../../../../../shared/sprite';
import { Icon, Tooltip } from '../../../../../../shared/ui';
import { Trigger } from './Trigger';
import { Button } from '../../../../../../shared/ui';
import { PageAppearance } from '../components/PageAppearance';
import { useFormContext } from 'react-hook-form';
import { CreatePageFormSchemaType } from '../../../../config/types';
import cn from 'classnames';

export const AppearanceTrigger = ({
  isOpened,
  isDone,
}: {
  isOpened: boolean;
  isDone: boolean;
}) => {
  const { watch } = useFormContext<CreatePageFormSchemaType>();

  const isBranded = watch('isBranded');
  const brandCompany = watch('brandCompany');

  return (
    <Trigger
      title="Page Appearance"
      isDone={isDone}
      description={
        isDone
          ? [
              isBranded ? 'Branded page' : 'General page',
              brandCompany?.name ?? '',
            ].filter(Boolean)
          : []
      }
      isOpened={isOpened}
    />
  );
};

type Props = {
  onNext: () => void;
  isDone: boolean;
};

export const Appearance = ({ onNext, isDone }: Props) => {
  return (
    <div className="relative flex flex-col gap-5">
      <PageAppearance />
      <div className="flex justify-end">
        <Tooltip
          className={cn({ hidden: isDone })}
          trigger={
            <Button
              disabled={!isDone}
              onClick={onNext}
              variant="text"
              color="primary"
              size="md"
            >
              Next <Icon glyph={IconMap.ArrowRight} width={24} />
            </Button>
          }
          align="end"
          sideOffset={4}
        >
          <p className="px-2 py-1 font-semibold text-xs w-36">
            Please input company name to continue.
          </p>
        </Tooltip>
      </div>
    </div>
  );
};
