import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { FC } from 'react';
import { TemplateItem } from './TemplateItem';

type Props = {
  name: string;
  templates: TemplateExtended[] | undefined;
};
export const TemplateCategory: FC<Props> = ({ name, templates }) => {
  return (
    <div className="mb-5">
      <p className="mb-3 text-sm font-bold text-gray-500 uppercase">{name}</p>
      {templates?.length && (
        <div className="grid flex-grow grid-cols-2 gap-3 overflow-x-hidden overflow-y-auto place-content-start">
          {templates.map((template) => (
            <TemplateItem template={template} key={template.id} />
          ))}
        </div>
      )}
    </div>
  );
};
