import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { foldersModel } from '../../features/folders';
import { pagesModel } from '../../features/pages';
import { teamsModel } from '../../features/teams';
import { templatesModel } from '../../features/templates';
import { authUserModel } from '../auth-user';
import { publicTemplatesModel } from '../../features/public-template';

import { Loader } from '../../shared/ui';

import { LeftTeamModal } from '../../features/team-info-settings';
import { InstallExtensionModal } from '../../features/extension';
import {
  PaywallModal,
  PlanReachedMembersLimitModal,
} from '../../features/subscription';

type WrapperProps = {
  children: React.ReactNode;
  isEditor?: boolean;
  isTemplateEditor?: boolean;
};

const notLoadingStatuses = ['never', 'pending'];

const Wrapper: FC<WrapperProps> = ({
  children,
  isEditor = false,
  isTemplateEditor = false,
}) => {
  const user = useSelector(authUserModel.selectors.selectUser);
  const firebaseUser = useSelector(authUserModel.selectors.selectFBUser);
  const authToken = useSelector(authUserModel.selectors.selectAuthToken);
  const teamsStatus = useSelector(teamsModel.selectors.selectTeamsStatus);
  const foldersStatus = useSelector(foldersModel.selectors.selectFoldersStatus);
  const pagesStatus = useSelector(pagesModel.selectors.selectPagesStatus);
  const currentPage = useSelector(pagesModel.selectors.selectCurrentPage);
  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  );
  const currentTeam = useSelector(teamsModel.selectors.selectCurrentTeam);
  const isEditorPageDataLoading = useSelector(
    pagesModel.selectors.selectPageEditorDataIsLoading
  );
  const isDuplicatingPage = useSelector(
    publicTemplatesModel.selectors.selectIsDuplicatingPage
  );

  if (!user || !firebaseUser) {
    return <Loader />;
  }

  if (isEditor && (!authToken || !currentPage || isEditorPageDataLoading)) {
    return <Loader />;
  }

  if (isTemplateEditor && (!authToken || !currentTemplate)) {
    return <Loader />;
  }

  if (notLoadingStatuses.includes(teamsStatus)) {
    return <Loader />;
  }

  if (notLoadingStatuses.includes(foldersStatus)) {
    return <Loader />;
  }

  if (notLoadingStatuses.includes(pagesStatus)) {
    return <Loader />;
  }

  if (isDuplicatingPage) {
    return <Loader />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      {children}
      {currentTeam && (
        <>
          <PaywallModal />
          <PlanReachedMembersLimitModal />
        </>
      )}
      <LeftTeamModal />
      <InstallExtensionModal />
    </>
  );
};

export default Wrapper;
