import { TaskAssigneeExtended } from '@distribute/shared/types';
import { Avatar } from '../../../../src/shared/ui';
import { FC } from 'react';

type Props = {
  assignee: TaskAssigneeExtended;
  isExtended?: boolean;
};

export const AssigneeTrigger: FC<Props> = ({ isExtended, assignee }) => {
  return assignee.user ? (
    <div className="flex items-center gap-2">
      <Avatar
        displayName={assignee?.user?.displayName}
        src={assignee?.user?.photoUrl}
        size="xs"
        isUserAvatar
        className="border-light-7 border-[0.5px] !bg-base-white"
      />
      {isExtended && assignee?.user?.displayName && (
        <span className="text-md text-primary-900">
          {assignee?.user?.displayName || ''}
        </span>
      )}
    </div>
  ) : (
    <div className="flex items-center gap-2">
      <div className="flex items-center justify-center w-6 h-6 text-xs text-gray-500 border border-gray-300 rounded-full">
        {assignee.email[0].toUpperCase()}
      </div>
      {isExtended && (
        <span className="text-md text-primary-900">{assignee.email}</span>
      )}
    </div>
  );
};
