import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { TemplateItem } from '../../../features/templates/ui/components/TemplateItem';
import { Avatar, Dropdown, SearchInput } from '../../../shared/ui';
import {
  getFilteredTemplates,
  getSortedTemplates,
  getFilteredTemplatesByType,
} from '../lib';
import { NoTemplatesFound } from './NoTemplatesFound';
import { TemplatesTabEnum } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { teamsModel } from '../../../features/teams';
import { authUserModel } from '../../../entities/auth-user';
import { templatesModel } from '../../../features/templates';
import { FilterByTypeState, SortState } from '../config';

type Props = {
  templates: TemplateExtended[];
  onChoose(): void;
  type: TemplatesTabEnum;
  handleCreateTemplate({ hasMultiTabs }: { hasMultiTabs: boolean }): void;
};

export const TemplatesPageList: React.FC<Props> = ({
  templates,
  onChoose,
  type,
  handleCreateTemplate,
}) => {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const teamUsers = useSelector(teamsModel.selectors.selectCurrentTeamMembers);

  const { id: currentUserId } = useSelector(
    authUserModel.selectors.selectUserWithError
  );

  const [currentFilterByTypeId, setCurrentFilterByTypeId] = useState<string>(
    FilterByTypeState.ALL_TEMPLATES
  );

  const mockFilterDropdownItems = useMemo(() => {
    const usersWithTemplates = teamUsers
      .filter((u) => u.user?.id && u.user?.displayName)
      .filter((teamUser) =>
        templates.find((t) => t.owner?.id === teamUser.user?.id)
      );

    return [
      {
        label: 'All owners',
        onClick: () => {
          setCurrentFilter('all-owners');
        },
        id: 'all-owners',
      },
      ...usersWithTemplates.map((u, index) => {
        return {
          label: (
            <div className="flex items-center gap-3">
              <Avatar
                isUserAvatar
                displayName={u.user?.displayName || ''}
                src={u.user?.photoUrl ?? undefined}
                size="xs"
              />
              <div className="flex flex-col min-w-0 text-gray-700 group-hover:text-gray-800">
                <span className="text-sm truncate">
                  {u.user?.displayName}{' '}
                  {u.user?.id === currentUserId ? '(you)' : ''}
                </span>
              </div>
            </div>
          ),
          onClick: () => {
            setCurrentFilter(u.user?.id || 'all-owners');
          },
          id: u.user?.id || `${index}`,
        };
      }),
    ];
  }, [teamUsers, templates, currentUserId]);

  const handleChangePagesFilter = useCallback(
    (id: string) => {
      setCurrentSortDropdownItemId((prevState) => {
        return {
          ...prevState,
          [type]: id,
        };
      });
    },
    [type]
  );

  const mockFilterByTypeItems = useMemo(() => {
    return [
      {
        label: 'All templates',
        onClick: () => {
          setCurrentFilterByTypeId(FilterByTypeState.ALL_TEMPLATES);
        },
        id: FilterByTypeState.ALL_TEMPLATES,
      },
      {
        label: 'Single-tab',
        onClick: () => {
          setCurrentFilterByTypeId(FilterByTypeState.ONE_PAGE);
        },
        id: FilterByTypeState.ONE_PAGE,
      },
      {
        label: 'Multi-tab',
        onClick: () => {
          setCurrentFilterByTypeId(FilterByTypeState.MULTI_TABS);
        },
        id: FilterByTypeState.MULTI_TABS,
      },
    ];
  }, [setCurrentFilterByTypeId]);

  const mockSortDropdownItems = useMemo(() => {
    return [
      {
        label: 'Last Created',
        onClick: () => {
          handleChangePagesFilter('last-created');
        },
        id: SortState.LAST_CREATED,
      },
      {
        label: 'Most Popular',
        onClick: () => {
          handleChangePagesFilter('most-popular');
        },
        id: SortState.MOST_POPULAR,
      },
      {
        label: 'Name',
        onClick: () => {
          handleChangePagesFilter('name');
        },
        id: SortState.NAME,
      },
    ];
  }, [handleChangePagesFilter]);

  const [currentFilter, setCurrentFilter] = useState<string>(
    mockFilterDropdownItems[0].id
  );

  const [currentSortDropdownItemId, setCurrentSortDropdownItemId] = useState({
    personal: mockSortDropdownItems[0].id,
    team: mockSortDropdownItems[0].id,
    drafts: mockSortDropdownItems[0].id,
  });

  const currentTemplatesToShow = useMemo(() => {
    const currentSortCondition = mockSortDropdownItems.find(
      (i) => i.id === currentSortDropdownItemId[type]
    )?.id;

    const filteredTemplates =
      type === TemplatesTabEnum.TEAM
        ? getFilteredTemplates(templates, currentFilter)
        : [...templates];
    const filteredTemplatesByType = getFilteredTemplatesByType(
      filteredTemplates,
      currentFilterByTypeId
    );
    const sortedTemplates = getSortedTemplates(
      filteredTemplatesByType,
      currentSortCondition
    );

    return sortedTemplates.filter((template) =>
      new RegExp(searchQuery, 'ig').test(template.name)
    );
  }, [
    currentFilter,
    currentSortDropdownItemId,
    templates,
    searchQuery,
    type,
    mockSortDropdownItems,
    currentFilterByTypeId,
  ]);

  const templatesModalOpen = useSelector(
    templatesModel.selectors.selectTemplatesModalOpen
  );

  useEffect(() => {
    if (!templatesModalOpen) {
      dispatch(
        templatesModel.actions.setRenderedTemplates(
          currentTemplatesToShow || []
        )
      );
    }
  }, [currentTemplatesToShow, templatesModalOpen, dispatch]);

  if (!templates.length) {
    return (
      <NoTemplatesFound
        type={type}
        handleCreateTemplate={handleCreateTemplate}
      />
    );
  }

  return (
    <div className="flex flex-col flex-grow pt-8">
      <div className="flex items-center justify-between gap-4 max1330:flex-col max1330:items-start md:flex-row md:items-center max850:flex-col max850:items-start">
        <div className="flex items-center gap-4">
          <div className="sm:max-w-full max-w-80 w-full">
            <SearchInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search"
            />
          </div>
          <div className="text-sm font-normal text-gray-700 sm:hidden whitespace-nowrap">
            {currentTemplatesToShow.length}{' '}
            {currentTemplatesToShow.length === 1 ? 'template' : 'templates'}
          </div>
        </div>
        <div className="flex items-center gap-4">
          {!!templates.length && (
            <div className="w-40 hover:bg-gray-50">
              <Dropdown
                listStyles="shadow-lg"
                items={mockFilterByTypeItems}
                currentItemId={currentFilterByTypeId}
                itemStyles="min-w-37.5 sm:min-w-full"
                triggerStyles="min-w-41 sm:min-w-full !text-sm !font-semibold !text-gray-800 shadow-xs"
                key={type}
                arrowStyle="text-gray-800"
              />
            </div>
          )}
          {!!templates.length && type === TemplatesTabEnum.TEAM && (
            <div className="w-40 hover:bg-gray-50">
              <Dropdown
                listStyles="shadow-lg"
                items={mockFilterDropdownItems}
                currentItemId={currentFilter}
                itemStyles="min-w-48.5 sm:min-w-full"
                triggerStyles="min-w-41 sm:min-w-full !text-sm !font-semibold !text-gray-800 shadow-xs"
                arrowStyle="text-gray-800"
              />
            </div>
          )}
          {!!templates.length && (
            <div className="w-40 hover:bg-gray-50">
              <Dropdown
                listStyles="shadow-lg"
                items={mockSortDropdownItems}
                currentItemId={currentSortDropdownItemId[type]}
                itemStyles="min-w-37.5 sm:min-w-full"
                triggerStyles="min-w-41 sm:min-w-full !text-sm !font-semibold !text-gray-800 shadow-xs"
                key={type}
                arrowStyle="text-gray-800"
              />
            </div>
          )}
        </div>
      </div>
      {searchQuery && !currentTemplatesToShow.length ? (
        <NoTemplatesFound
          type="search"
          handleCreateTemplate={handleCreateTemplate}
        />
      ) : (
        <div className="flex flex-wrap 2xl:grid flex-grow gap-6 py-8 overflow-x-hidden overflow-y-auto 2xl:grid-cols-3 max1280:grid-cols-2 sm:grid-cols-1 place-content-start">
          {currentTemplatesToShow.map((template, i) => (
            <TemplateItem
              template={template}
              key={template.id}
              onChoose={onChoose}
              searchQuery={''}
              index={i}
              isTemplatesPage
            />
          ))}
        </div>
      )}
    </div>
  );
};
