import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Icon, SelectableInput, Button } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { GetCallDetailResponse } from '@distribute/shared/api-types/call';
import { navigate } from '../../../processes/navigation';
import { CALL_RECORDINGS_ROUTE } from '../../../entities/history';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { callModel } from '../model';
import {
  checkIsMuxMP4Ready,
  getMuxVideoDownloadByPolicy,
} from '@distribute/shared/utils';
import { authUserModel } from '../../../entities/auth-user';
import { DEFAULT_NAME } from '../../recordings/config';
import { CreateOrInsertToPageFlowsEnum } from '../model/types';
import { PageCreationFlow } from '../../../processes/page-creation-flow';
import { useSubscriptionLimits } from '../../subscription/hooks';
import { CallAssetPrivacyModal } from './CallAssetPrivacyModal';
import { ChooseFolderOrPageModal } from '../../pages';
import { ChoosePageOrFolderData } from '../model/types';

type CallHeaderProps = {
  call: GetCallDetailResponse;
};

export const CallHeader: React.FC<CallHeaderProps> = ({ call }) => {
  const { isPrivate, name, userToTeam, muxAsset, summaries } = call;
  const dispatch = useDispatch();
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const isChooseFolderOrPageModalOpen = useSelector(
    callModel.selectors.selectIsChooseFolderOrPageModalOpen
  );
  const isInsertToPageLoading = useSelector(
    callModel.selectors.selectIsInsertToPageLoading
  );
  const { isCallSummaryEnabled } = useSubscriptionLimits();

  const owner = userToTeam.user;
  const isOwner = currentUser.id === owner.id;

  const handleBack = () => {
    dispatch(
      navigate({
        to: CALL_RECORDINGS_ROUTE,
      })
    );
  };

  const toggleVisibility = () => {
    if (!isPrivate) {
      dispatch(callModel.actions.setTogglingCallItem(call));
    } else {
      dispatch(
        callModel.actions.updateCallItem({
          id: call.id,
          data: { isPrivate: !isPrivate },
        })
      );
    }
  };
  const handleDownloadVideo = () => {
    const url = getMuxVideoDownloadByPolicy(call.muxAsset);
    window.open(url, '_blank');
  };

  const handleDelete = () => {
    dispatch(callModel.actions.setDeletingCallItem(call));
  };

  const handleNameUpdate = (name: string) => {
    dispatch(
      callModel.actions.updateCallItemWithDebounce({
        id: call.id,
        data: { name },
      })
    );
  };

  const handleChoose = (data: ChoosePageOrFolderData) => {
    dispatch(callModel.actions.chooseFolderOrPage(data));
  };

  const handleModaleClose = () => {
    dispatch(callModel.actions.setIsChooseFolderOrPageModalOpen(false));
  };

  const callControls = [
    {
      label: isPrivate ? 'Make public' : 'Make private',
      id: 'public',
      iconName: IconMap.Lock01,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: toggleVisibility,
      hidden: !isOwner,
    },
    {
      label: 'Download video',
      id: 'download',
      isSeparatedFromTop: isOwner,
      iconName: IconMap.Download02,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: handleDownloadVideo,
      disabled: !checkIsMuxMP4Ready(muxAsset),
    },
    {
      label: 'Delete',
      id: 'delete',
      iconName: IconMap.Trash,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: handleDelete,
      hidden: !isOwner,
    },
  ].filter((control) => !control.hidden);
  const createPageControls = [
    {
      label: 'Create Page with Summary & Video',
      id: CreateOrInsertToPageFlowsEnum.SUMMARY_AND_VIDEO,
      iconName: IconMap.VideoAndSummary,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: () =>
        dispatch(
          callModel.actions.setPageCreationFlow(
            CreateOrInsertToPageFlowsEnum.SUMMARY_AND_VIDEO
          )
        ),
      hidden: !isCallSummaryEnabled || summaries.length <= 0,
    },
    {
      label: 'Create Page with AI',
      id: CreateOrInsertToPageFlowsEnum.PAGE_WITH_AI,
      iconName: IconMap.AIStars,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: () =>
        dispatch(
          callModel.actions.setPageCreationFlow(
            CreateOrInsertToPageFlowsEnum.PAGE_WITH_AI
          )
        ),
    },
    {
      label: 'Add video only to page',
      id: CreateOrInsertToPageFlowsEnum.VIDEO_ONLY,
      iconName: IconMap.FilmArrow,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: () =>
        dispatch(
          callModel.actions.setPageCreationFlow(
            CreateOrInsertToPageFlowsEnum.VIDEO_ONLY
          )
        ),
    },
  ].filter((control) => !control.hidden);

  return (
    <header className="flex justify-between items-center px-4 py-3 sm:py-2 bg-base-white border-b border-gray-200 transition-all h-16 sticky top-0 z-20">
      <PageCreationFlow />
      <CallAssetPrivacyModal />
      {isChooseFolderOrPageModalOpen && (
        <ChooseFolderOrPageModal
          isOpen={isChooseFolderOrPageModalOpen}
          onClose={handleModaleClose}
          onChoose={handleChoose}
          loading={isInsertToPageLoading}
        />
      )}
      <div className="flex items-center gap-3 flex-1">
        <button type="button" onClick={handleBack}>
          <Icon glyph={IconMap.ChevronLeft} width={20} />
        </button>
        <span className="border-r border-base-black/10 h-5" />
        <SelectableInput
          variant="title"
          readonly={!isOwner}
          valueReadonly={name || DEFAULT_NAME}
          value={name}
          onChange={handleNameUpdate}
          placeholder="Untitled"
          tooltipText="Rename call"
          classNames="w-full max-w-120"
        />
      </div>
      <div className="flex items-center justify-between gap-2">
        <Dropdown
          align="start"
          listStyles="shadow-lg"
          itemStyles="hover:text-gray-800"
          isModalMode={false}
          triggerComponent={
            <DropdownMenu.Trigger
              className="flex items-center justify-center w-10 h-10
                border border-gray-200 rounded-lg focus:outline-none hover:border-gray-300
                bg-base-white/70 hover:bg-base-white"
            >
              <Icon glyph={IconMap.DotsVertical} width={20} />
            </DropdownMenu.Trigger>
          }
          items={callControls}
        />
        <Dropdown
          align="end"
          items={createPageControls}
          listStyles="shadow-lg"
          itemStyles="hover:text-gray-800"
          isModalMode={false}
          triggerComponent={
            <DropdownMenu.Trigger>
              <Button
                size="md"
                variant="icon-text"
                color="primary"
                iconLeftName={IconMap.Plus}
              >
                Create page
              </Button>
            </DropdownMenu.Trigger>
          }
        />
      </div>
    </header>
  );
};
