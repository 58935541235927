import { FC } from 'react';
import { Icon, SpriteGlyph } from './Icon';
import { cn } from '@distribute/frontend/utils';
import { Tooltip } from './Tooltip';

type Props = {
  onClick: () => void;
  isSelected: boolean;
  icon: SpriteGlyph;
  title: string;
  isActiveIcon?: boolean;
  isWarningIcon?: boolean;
  disabled?: boolean;
};

const ButtonComponent: FC<Props> = ({
  onClick,
  isSelected,
  icon,
  title,
  isActiveIcon,
  isWarningIcon,
  disabled,
}) => {
  return (
    <button
      className="relative flex flex-col items-center gap-1 pt-2 pb-1 group"
      disabled={disabled}
      onClick={onClick}
    >
      <span
        className={cn('rounded-md w-7 h-7 flex justify-center items-center', {
          'bg-base-black-4 group-hover:bg-base-black-8 text-gray-500 group-hover:text-gray-600':
            !isSelected,
          'bg-primary-50 text-primary-600': isSelected,
        })}
      >
        <Icon glyph={icon} width={20} />
      </span>

      <span
        className={cn('text-xs font-semibold', {
          'text-gray-700 group-hover:text-gray-800': !isSelected,
          'text-primary-700': isSelected,
        })}
      >
        {title}
      </span>
      {(isActiveIcon || isWarningIcon) && (
        <div
          className={cn('absolute top-1 right-3.5 w-2.5 h-2.5 rounded-full', {
            'bg-warning-500': isWarningIcon,
            'bg-success-500': !isWarningIcon,
          })}
        />
      )}
    </button>
  );
};

export const EditorSidebarButton: FC<Props> = (props) => {
  return props.disabled ? (
    <Tooltip
      triggerClassNames="flex justify-center"
      hideArrow
      trigger={<ButtonComponent {...props} />}
    >
      Coming soon!
    </Tooltip>
  ) : (
    <ButtonComponent {...props} />
  );
};
