import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetCallDetailResponse } from '@distribute/shared/api-types/call';
import { Player } from '@distribute/frontend/ui/player';
import {
  checkIsMuxLoading,
  checkIsMuxReady,
  getMuxAssetImageByPolicy,
  getMuxAssetStreamByPolicy,
} from '@distribute/shared/utils';
import {
  Avatar,
  Icon,
  RecordState,
  SelectableInput,
  TabContent,
  Tabs,
  TimeAgo,
} from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

import { DEFAULT_NAME } from '../../recordings/config';
import { authUserModel } from '../../../entities/auth-user';
import { callModel } from '../model';

import { CallSummaryView } from './CallSummaryView';
import { CallTranscript } from './CallTranscript';
import { MeetingPlatforms } from '../../call-recordings/config';

type CallDetailsProps = {
  call: GetCallDetailResponse;
};

enum Tab {
  SUMMARY = 'summary',
  TRANSCRIPT = 'transcript',
}

const tabs: TabContent[] = [
  { name: Tab.SUMMARY, title: 'Summary', icon: IconMap.Document01 },
  { name: Tab.TRANSCRIPT, title: 'Transcript', icon: IconMap.Microphone02 },
];

export const CallDetails: React.FC<CallDetailsProps> = ({ call }) => {
  const { muxAsset, userToTeam, isPrivate, createdAt, platform, attendees } =
    call;

  const dispatch = useDispatch();
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const [selectedTab, setSelectedTab] = useState<TabContent>(tabs[0]);

  const owner = userToTeam.user;
  const isOwner = currentUser.id === owner.id;

  const currentPlatform =
    MeetingPlatforms[platform] ?? MeetingPlatforms.googleMeet;

  const handleNameUpdate = (name: string) => {
    dispatch(
      callModel.actions.updateCallItemWithDebounce({
        id: call.id,
        data: { name },
      })
    );
  };

  return (
    <div className="h-[calc(100vh-64px)] px-4 overflow-y-auto">
      <div className="flex flex-col max-w-5xl mx-auto w-full min-h-full">
        <header className="flex flex-col gap-2 sticky top-0 bg-base-white py-6 z-10">
          <SelectableInput
            variant="titleXl"
            readonly={!isOwner}
            valueReadonly={call.name || DEFAULT_NAME}
            value={call.name}
            onChange={handleNameUpdate}
            placeholder="Untitled"
            classNames="w-full truncate"
          />
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-2">
              {owner && (
                <div className="flex gap-2 items-center text-sm text-gray-600">
                  <Avatar
                    src={owner.photoUrl}
                    displayName={owner.displayName}
                    size="sm"
                  />
                  <span className="font-medium">{owner.displayName}</span>
                  {!isPrivate && (
                    <>
                      <Icon glyph={IconMap.Dot} width={5} />
                      <p className="flex items-center gap-1">
                        <Icon glyph={IconMap.Users01} width={16} />
                        Team access
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <span className="shadow-xs">
                <Icon glyph={currentPlatform.icon} width={20} />
              </span>
              <span className="text-sm text-gray-600">
                {currentPlatform.name}
              </span>
              <Icon glyph={IconMap.Dot} width={5} />
              <TimeAgo datetime={createdAt} locale="my" />
            </div>
          </div>
        </header>
        <section className="flex gap-6 flex-1">
          <div className="flex flex-col gap-6 max-w-160 w-full mb-8">
            <div className="border border-base-black/[0.08] rounded-lg overflow-hidden">
              {checkIsMuxReady(muxAsset) ? (
                <Player
                  url={getMuxAssetStreamByPolicy(muxAsset)}
                  posterUrl={getMuxAssetImageByPolicy(muxAsset)}
                  aspectRatio={muxAsset.data.aspectRatio}
                  removeDownloadOverlay
                />
              ) : (
                <div className="aspect-video">
                  <RecordState
                    loading={checkIsMuxLoading(muxAsset)}
                    size="md"
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <Tabs
                className="!mb-0 bg-base-white sticky z-10 top-33 !h-8"
                tabs={tabs}
                onSelectTab={setSelectedTab}
                selectedTab={selectedTab}
              >
                {selectedTab.name === Tab.SUMMARY && (
                  <CallSummaryView call={call} />
                )}

                {selectedTab.name === Tab.TRANSCRIPT && (
                  <CallTranscript call={call} />
                )}
              </Tabs>
            </div>
          </div>
          <aside className="w-90 relative">
            <div className="bg-gray-100 rounded-lg p-5 sticky top-33">
              <h3 className="flex items-center gap-1.5 text-lg font-semibold">
                Participants
                <span className="border border-gray-200 bg-gray-50 rounded-full px-2 py-0.5 text-gray-700 text-xs font-medium">
                  {attendees.length}
                </span>
              </h3>
              {attendees.length > 0 && (
                <ul>
                  {attendees.map((attendee) => (
                    <li
                      key={attendee.id}
                      className="flex items-center gap-2 mt-3"
                    >
                      <Avatar
                        className="border border-base-black/[0.08]"
                        src={undefined}
                        displayName={attendee.name}
                        size="sm"
                      />
                      <p className="text-sm text-gray-900 font-medium truncate">
                        {attendee.name}
                      </p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </aside>
        </section>
      </div>
    </div>
  );
};
