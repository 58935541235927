import { RT } from '../../../../shared/types';
import { put, call, select } from 'redux-saga/effects';
import { callRecordingsModel } from '../index';
import { createNotification, snackbarModel } from '../../../snackbar';
import { callRecordingsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';

export function* addManuallyJoinCall({
  payload,
}: RT<typeof callRecordingsModel.actions.addManuallyJoinCall>) {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  yield put(callRecordingsModel.actions.setIsJoinCallProgress(true));

  try {
    yield call(callRecordingsApi.addManuallyJoinCall, payload, {
      teamId: team.id,
    });
    yield put(callRecordingsModel.actions.setIsJoinCallOpenModal(false));
    // refresh meeting events
    yield call(callRecordingsModel.sagas.loadMeetingEvents);
  } catch (error) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to add manually call')
      )
    );
  } finally {
    yield put(callRecordingsModel.actions.setIsJoinCallProgress(false));
  }
}
