import { processDataStream } from 'ai';
import { api } from './instance';

import {
  GenerateChatMessageRequest,
  GetChatMessageResponse,
  GetChatResponse,
  GetChatsResponse,
  UpdateChatMessageRequest,
} from '@distribute/shared/api-types/chat';

import { AIError } from '../../../features/ai/lib';
import { API_URL, auth } from '../../config';
import { ChatStructuredData } from '@distribute/shared/types';

export const chatsApi = {
  async getChats({ entityId, teamId }: { entityId: string; teamId: number }) {
    const { data } = await api.get<GetChatsResponse[]>('chats', {
      params: { teamId, entityId },
    });

    return data;
  },

  async createChat({ entityId, teamId }: { entityId: string; teamId: number }) {
    const { data } = await api.post<GetChatResponse>(
      'chats',
      { entityId },
      { params: { teamId } }
    );

    return data;
  },

  async getChat({ id, teamId }: { id: string; teamId: number }) {
    const { data } = await api.get<GetChatResponse>(`chats/${id}`, {
      params: { teamId },
    });

    return data;
  },

  async saveChatMessage({
    id,
    teamId,
    data,
    structuredData,
  }: {
    id: string;
    teamId: number;
    data: string;
    structuredData: ChatStructuredData[];
  }) {
    const { data: responseData } = await api.post<GetChatResponse>(
      `chats/${id}/message`,
      { data, structuredData },
      { params: { teamId } }
    );

    return responseData;
  },

  async deleteChatMessages({ id, teamId }: { id: string; teamId: number }) {
    await api.delete(`chats/${id}/message`, {
      params: { teamId },
    });
  },

  async updateChatMessage({
    id,
    messageId,
    teamId,
    data,
  }: {
    id: string;
    messageId: string;
    teamId: number;
    data: UpdateChatMessageRequest;
  }) {
    const { data: responseData } = await api.patch<GetChatMessageResponse>(
      `chats/${id}/message/${messageId}`,
      data,
      { params: { teamId } }
    );

    return responseData;
  },

  async generateMessage({
    data,
    teamId,
    chatId,
    signal,
    onFulfilled,
  }: {
    data: GenerateChatMessageRequest;
    teamId: number;
    chatId: string;
    signal: AbortSignal;
    onFulfilled: (text: string) => void;
  }) {
    const token = await auth.currentUser?.getIdToken();

    const response = await fetch(`${API_URL}/chats/${chatId}/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...data, teamId }),
      signal,
    });

    if (!response.body) {
      throw new Error('Response is empty');
    }

    await processDataStream({
      stream: response.body,
      onTextPart(value) {
        onFulfilled(value);
      },
      onErrorPart(value) {
        throw new AIError(value);
      },
    });
  },
};
