import { call, put, select } from 'redux-saga/effects';
import { teamsModel } from '../../../teams';
import { RT } from '../../../../shared/types';
import { chatsApi } from '../../../../shared/api/axios/chat';
import { chatModel } from '..';
import { createNotification, snackbarModel } from '../../../snackbar';
import { loadChatData } from './loadChatData';

export function* deleteChatMessages() {
  try {
    const currentChat: RT<
      typeof chatModel.selectors.selectCurrentChatWithError
    > = yield select(chatModel.selectors.selectCurrentChatWithError);
    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    if (!currentChat) {
      return;
    }

    yield put(chatModel.actions.setIsChatDeleting(true));

    yield call(chatsApi.deleteChatMessages, {
      id: currentChat.id,
      teamId: currentTeam.id,
    });
    yield call(loadChatData, currentChat.entityId);
  } catch (error) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to delete chat')
      )
    );
  } finally {
    yield put(chatModel.actions.setIsChatDeleting(false));
    yield put(chatModel.actions.setIsChatDeleteModalOpen(false));
  }
}
