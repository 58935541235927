import { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { chatModel } from '../model';
import { ChatMessageType } from '@distribute/shared/types';

import { ChatBotConversation } from './ChatBotConversation';

import { ChatBotMessageWelcome } from './ChatBotMessageWelcome';
import { ChatBotMessageRequest } from './ChatBotMessageRequest';
import { ChatBotMessageResponse } from './ChatBotMessageResponse';

import { ConversationState } from '../model/types';

import { useScroll } from '../hooks';

export const ChatBotMessages: FC = () => {
  const { ref, handleContentChange } = useScroll({
    scrollOnMount: true,
    throttleMs: 150,
  });

  const currentChat = useSelector(
    chatModel.selectors.selectCurrentChatWithError
  );

  const conversation = useSelector(chatModel.selectors.selectConversation);
  const conversationState = useSelector(
    chatModel.selectors.selectConversationState
  );

  useEffect(() => {
    if (!conversation) return;

    handleContentChange();
  }, [conversation, handleContentChange]);

  if (!currentChat) return null;

  return (
    <div
      className="-mr-5 pr-5 py-3 flex flex-col gap-y-6 flex-grow-1 overflow-y-auto"
      ref={ref}
    >
      <ChatBotMessageWelcome />
      {currentChat.messages.map((message) =>
        message.content.type === ChatMessageType.REQUEST ? (
          <ChatBotMessageRequest text={message.content.data} key={message.id} />
        ) : (
          <ChatBotMessageResponse
            items={message.content.structuredData}
            message={message}
            id={message.id}
            key={message.id}
          />
        )
      )}
      {conversationState === ConversationState.Streaming && (
        <ChatBotConversation />
      )}
    </div>
  );
};
