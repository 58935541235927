// Now using generateJson from @tiptap/react
import { generateJSON, JSONContent } from '@tiptap/react';

import { JSONContentFactory } from '@distribute/shared/tiptap/json-content';
import { ChatResponseType } from '@distribute/shared/types';
import { xmlExtensions } from '@distribute/shared/generate-html';
import { CUSTOM_TAG } from './config';

export const parseComment = (node: Element) => {
  return {
    type: ChatResponseType.Comment,
    text: node.textContent?.trim() ?? '',
  } as const;
};

type ParseTabResult =
  | {
      data: {
        type: ChatResponseType.Suggestion;
        id: string;
        changed: boolean;
        content: JSONContent;
        name: string;
      };
      error: null;
      warning?: string;
    }
  | {
      data: null;
      error: Error;
      warning?: string;
    };

export const parseTab = (node: Element): ParseTabResult => {
  const id = node.getAttribute('id') ?? '';
  const name = node.getAttribute('name') ?? '';

  const childrenNames = Array.from(node.children).map((child) =>
    child.nodeName.toUpperCase()
  );
  const hasNotModifiedChild = childrenNames.some(
    (name) => name === CUSTOM_TAG.NOTMODIFIED
  );

  const notModified = hasNotModifiedChild || node.children.length === 0;

  if (notModified) {
    return {
      data: {
        type: ChatResponseType.Suggestion,
        id,
        changed: false,
        content: JSONContentFactory.DEFAULT(),
        name,
      },
      warning:
        childrenNames.length > 1
          ? `Node contains unsupported nodes: ${childrenNames.filter(
              (n) => n !== CUSTOM_TAG.NOTMODIFIED
            )}`
          : '',
      error: null,
    } as const;
  }

  try {
    const content = generateJSON(node.innerHTML, xmlExtensions);

    return {
      data: {
        type: ChatResponseType.Suggestion,
        name,
        id,
        content,
        changed: true,
      },
      error: null,
    } as const;
  } catch (e: unknown) {
    return {
      data: null,
      error: e as Error,
    };
  }
};
