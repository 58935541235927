import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconMap } from '../../../shared/sprite';
import { Button, Icon } from '../../../shared/ui';
import { navigate } from '../../../processes/navigation';
import { TEAM_SETTINGS_CALL_RECORDINGS } from '../../../entities/history';
import { authUserModel } from '../../../entities/auth-user';

export const MeetingEmptyState = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const handleNavigateToSettings = () => {
    dispatch(navigate({ to: TEAM_SETTINGS_CALL_RECORDINGS }));
  };
  return (
    <div className="flex flex-col items-center gap-4 justify-center h-full px-3">
      <span className="text-gray-700 p-3 border border-gray-200 rounded-lg shadow-xs">
        <Icon className="bla" glyph={IconMap.Calendar} width={24} />
      </span>
      <div className="flex flex-col gap-1 text-sm text-gray-600 text-center mb-3">
        <h3 className="text-md text-gray-900 font-semibold">
          No meetings found
        </h3>
        <p>
          We couldn’t load meetings for the next 7 days from{' '}
          <span className="font-semibold">{currentUser.email}</span>&apos;s
          calendar.
        </p>
        <p>
          Your Distribute account and calendar may be out of sync, or you can
          use call recording settings to connect a different calendar.
        </p>
      </div>
      <div className="text-gray-700">
        <Button
          onClick={handleNavigateToSettings}
          variant="icon-text"
          color="secondary"
          iconLeftName={IconMap.Settings}
        >
          Settings
        </Button>
      </div>
    </div>
  );
};
