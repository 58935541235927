import { FC } from 'react';
import { useSubscriptionLimits } from '../../subscription/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { teamsModel } from '../../teams';
import { redirectActions } from '../../../entities/history';

export const ChatBotLabel: FC = () => {
  const dispatch = useDispatch();

  const { maxAIMonthlyResponsesCount } = useSubscriptionLimits();
  const { aiMonthlyResponsesCount: count } = useSelector(
    teamsModel.selectors.selectUserTeamMemberDataWithError
  );

  const onClick = () => dispatch(redirectActions.toSubscriptionsClick());

  if (isFinite(maxAIMonthlyResponsesCount)) {
    const message = count
      ? `${count} AI ${count > 1 ? 'responses' : 'response'} left.`
      : 'All free AI responses have been used';

    return (
      <div className="flex flex-nowrap gap-x-1 items-center justify-center">
        <p className="text-sm font-medium text-gray-600">{message}</p>
        <span
          className="text-primary-700 font-semibold cursor-pointer text-sm"
          onClick={onClick}
        >
          Go Unlimited
        </span>
      </div>
    );
  }

  return null;
};
