/* Path to the files:

1. https://stream.mux.com/{PLAYBACK_ID}.m3u8 - video
2. https://image.mux.com/{PLAYBACK_ID}/thumbnail.png - image
3. https://image.mux.com/{PLAYBACK_ID}/animated.[webp|gif] - gif

*/

import {
  MuxAsset,
  MuxAssetPlayback,
  MuxAssetStatus,
  MuxAssetStatusStatic,
} from '@distribute/shared/types';

const streamPath = 'https://stream.mux.com';
const imagePath = 'https://image.mux.com';

export const getMuxVideoDownload = (id: string) => {
  const name = `call-${new Date().toLocaleDateString()}`;
  return `${streamPath}/${id}/capped-1080p.mp4?download=${name}`;
};

export const getMuxVideoDownloadPrivate = (id: string, token: string) => {
  const downloadLink = getMuxVideoDownload(id);
  return `${downloadLink}&token=${token}`;
};

export const getMuxStream = (id: string) => {
  return [streamPath, `${id}.m3u8?min_resolution=720p`].join('/');
};

export const getMuxStreamPrivate = (id: string, token: string) => {
  return [streamPath, `${id}.m3u8?token=${token}`].join('/');
};

export const getMuxImage = (id: string) => {
  return [imagePath, id, 'thumbnail.png'].join('/');
};

export const getMuxPrivateImage = (id: string, token: string) => {
  const basePath = getMuxImage(id);
  return `${basePath}?token=${token}`;
};

export const getMuxPreview = (id: string) => {
  return [imagePath, id, 'animated.gif?width=640&end=4'].join('/');
};

export const parseRatio = (text?: string) =>
  text?.replace(':', '/') || DEFAULT_RATIO;

export const getMuxAssetImageByPolicy = ({ playback }: MuxAssetPlayback) => {
  if (playback.policy === 'public') {
    return getMuxImage(playback.id);
  }

  return getMuxPrivateImage(playback.id, playback.tokens.thumbnail as string);
};

export const getMuxAssetStreamByPolicy = ({ playback }: MuxAssetPlayback) => {
  if (playback.policy === 'public') {
    return getMuxStream(playback.id);
  }

  return getMuxStreamPrivate(playback.id, playback.tokens.video as string);
};

export const getMuxVideoDownloadByPolicy = ({ playback }: MuxAssetPlayback) => {
  if (playback.policy === 'public') {
    return getMuxVideoDownload(playback.id);
  }
  return getMuxVideoDownloadPrivate(
    playback.id,
    playback.tokens.video as string
  );
};

export const checkIsMuxLoading = (muxAsset: MuxAsset) =>
  muxAsset.status === MuxAssetStatus.PREPARING;

export const checkIsMuxReady = (muxAsset: MuxAsset) =>
  muxAsset.status === MuxAssetStatus.READY;

export const checkIsMuxFinal = (muxAsset: MuxAsset) =>
  [MuxAssetStatus.READY, MuxAssetStatus.ERRORED].includes(muxAsset.status);

export const checkIsMuxMP4Ready = (muxAsset: MuxAsset) =>
  muxAsset.statusStatic === MuxAssetStatusStatic.READY;

export const checkIsMuxMP4Final = (muxAsset: MuxAsset) => {
  if (!muxAsset.statusStatic) return false;

  return [MuxAssetStatusStatic.READY, MuxAssetStatusStatic.ERRORED].includes(
    muxAsset.statusStatic
  );
};

export const DEFAULT_RATIO = '16/9';
