import { SpriteGlyph } from '../../../../../../shared/ui/Icon';
import { Icon, Toggle } from '../../../../../../shared/ui';
import cn from 'classnames';

type BehaviorSettingsItemProps = {
  title: string;
  checked: boolean;
  setChecked: (val: boolean) => void;
  text: string;
  children?: React.ReactNode;
  content: boolean;
  icon: SpriteGlyph;
  isDisabled?: boolean;
};

export const BehaviorSettingsItem: React.FC<BehaviorSettingsItemProps> = ({
  title,
  checked,
  setChecked,
  text,
  children,
  content,
  icon,
  isDisabled,
}) => (
  <div className="flex flex-col justify-between p-3 border rounded-lg border-gray-200 mb-4 bg-base-white">
    <div className="flex justify-between mb-2">
      <div className="flex gap-2 items-center">
        <Icon width={20} glyph={icon} className="text-gray-700" />
        <p className="text-gray-900 text-md font-medium">{title}</p>
      </div>

      <Toggle checked={checked} onChange={setChecked} disabled={isDisabled} />
    </div>
    <p
      className={cn('max-w-100 text-s text-gray-600', {
        'pb-3': checked && content,
      })}
    >
      {text}
    </p>
    {checked && content && (
      <div className="flex flex-col gap-3 border-t pt-3 border-gray-200">
        {children}
      </div>
    )}
  </div>
);
