import {
  MeetingCalendarEvent,
  MeetingJoinPreference,
  MeetingsVisibility,
  TeamCallSettings,
  UserCallSettings,
  CallTranscripts,
} from '@distribute/shared/types';

type CheckIsPrivateEvent = {
  event: MeetingCalendarEvent;
  teamSettings: TeamCallSettings;
};

export const checkIsPrivateEvent = ({
  event,
  teamSettings,
}: CheckIsPrivateEvent): boolean => {
  if (event.isPrivate !== null) {
    return event.isPrivate;
  }

  if (teamSettings.meetingsVisibility === MeetingsVisibility.SHARE_ALL) {
    return false;
  }

  if (
    teamSettings.meetingsVisibility === MeetingsVisibility.SHARE_EXTERNAL &&
    event.isExternal
  ) {
    return false;
  }

  if (
    teamSettings.meetingsVisibility === MeetingsVisibility.SHARE_INTERNAL &&
    !event.isExternal
  ) {
    return false;
  }

  // MeetingsVisibility.SHARE_NONE
  return true;
};

type CheckBotShouldRecord = {
  event: MeetingCalendarEvent;
  userSettings: UserCallSettings;
};

export const checkBotShouldRecord = ({
  event,
  userSettings,
}: CheckBotShouldRecord): boolean => {
  if (event.shouldRecord !== null) {
    return event.shouldRecord;
  }

  if (
    userSettings.joinPreference === MeetingJoinPreference.JOIN_ORGANIZED &&
    event.isOrganizedByUser
  ) {
    return true;
  }

  if (userSettings.joinPreference === MeetingJoinPreference.JOIN_ALL) {
    return true;
  }

  if (
    userSettings.joinPreference === MeetingJoinPreference.JOIN_EXTERNAL &&
    event.isExternal
  ) {
    return true;
  }

  // MeetingJoinPreference.NO_AUTO_JOIN
  return false;
};

export const checkIsValidZoomUrl = (url: string) => {
  try {
    const _url = new URL(url);
    const validPaths = [
      'j',
      'w',
      'my',
      'rec',
      'meeting',
      'webinar',
      'start',
      'join',
    ];
    const pathSegments = _url.pathname.split('/').filter(Boolean);

    return (
      _url.protocol === 'https:' &&
      _url.hostname.endsWith('zoom.us') &&
      pathSegments.length > 0 &&
      validPaths.includes(pathSegments[0])
    );
  } catch {
    return false;
  }
};

export const checkIsValidGoogleMeetUrl = (url: string) => {
  try {
    const _url = new URL(url);
    const meetCodePattern = /^[a-z]{3}-[a-z]{4}-[a-z]{3}$/;
    const pathSegments = _url.pathname.split('/').filter(Boolean);

    return (
      _url.protocol === 'https:' &&
      _url.hostname === 'meet.google.com' &&
      pathSegments.length === 1 &&
      meetCodePattern.test(pathSegments[0])
    );
  } catch {
    return false;
  }
};

export const getMeetingPlatform = (meetingUrl: string) => {
  const isValidZoomUrl = checkIsValidZoomUrl(meetingUrl);
  const isValidGoogleMeetUrl = checkIsValidGoogleMeetUrl(meetingUrl);

  if (isValidZoomUrl) return 'zoom';
  if (isValidGoogleMeetUrl) return 'googleMeet';

  throw new Error('Invalid meeting URL');
};

export const getCallRecordingsFeatureFlag = (
  featureFlag: boolean,
  email: string
) => {
  const allowedEmailDomains = ['@flexum.io', '@distribute.so'];

  if (allowedEmailDomains.some((eDomain) => email.endsWith(eDomain))) {
    return true;
  }

  // zoom review
  if (email === 'distribute.user@gmail.com') {
    return true;
  }

  return featureFlag;
};

export const formatTranscriptTime = (secs: number) => {
  const truncted = Math.trunc(secs);

  const hours = Math.floor(truncted / 3600);
  const minutes = Math.floor(truncted / 60) % 60;
  const seconds = truncted % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

export const getTranscriptText = (transcripts: CallTranscripts) => {
  return transcripts.reduce((acc, next) => {
    const text = next.words.map((w) => w.text).join(' ');
    const timestamp = next.words?.[0]?.start_timestamp ?? 0;

    return `${acc}${next.speaker}:\n${formatTranscriptTime(
      timestamp
    )}\n${text}\n\n`;
  }, '');
};
