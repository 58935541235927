import { actions, reducer } from './reducer';
import * as selectors from './selectors';
import * as sagas from './sagas';

export const pageCreationFlowModel = {
  actions,
  reducer,
  selectors,
  sagas,
};
