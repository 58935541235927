import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { tasksModel } from '..';
import { tasksApi } from '../../../../../src/shared/api';
import { TasklistWithTasks } from '@distribute/shared/types';

export function* updateTasklist({
  payload: { successCallback, failedCallback, ...payloadData },
}: ReturnType<typeof tasksModel.actions.updateTasklist>) {
  try {
    const { tasklistId, documentContentId, ...rest } = payloadData;

    const tasklists: Record<string, TasklistWithTasks> = yield select(
      tasksModel.selectors.selectTasklists
    );

    const newTasklists = {
      ...tasklists,
      [tasklistId]: { ...tasklists[tasklistId], ...rest },
    };

    yield put(tasksModel.actions.setTasklists(newTasklists));

    yield call(tasksApi.updateTasklist, tasklistId, documentContentId, rest);

    successCallback();
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update tasklist')
      )
    );

    failedCallback();
  }
}
