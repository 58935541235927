import React from 'react';
import { ElementItemProps } from './types';

export const ElementPreviewCard = ({
  actionPreview,
}: Pick<ElementItemProps, 'actionPreview'>) => {
  if (!actionPreview) return null;

  return (
    <div className="w-70">
      <div className="pb-3">
        {React.isValidElement(actionPreview.cover) ? (
          actionPreview.cover
        ) : (
          <img
            src={`../../../../assets/images/actionPreviewCover/${actionPreview.cover}`}
            width="256px"
            height="164px"
            alt={actionPreview.name}
          />
        )}
      </div>
      <p className="flex items-center gap-2 mb-1 text-gray-600">
        {actionPreview.icon}
        <span className="text-base font-semibold text-gray-900 line-clamp-2 max-w-51">
          {actionPreview.name}
        </span>
      </p>
      <p className="text-sm font-normal text-gray-700 line-clamp-2">
        {actionPreview.description}
      </p>
    </div>
  );
};
