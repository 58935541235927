import { TableOptions } from '@tiptap/extension-table';

import './CustomTable.css';
import { generateHTML } from '@tiptap/react';
import { Fragment, Node } from '@tiptap/pm/model';
import { CellSelection } from '@tiptap/pm/tables';
import { TableSelector } from './TableSelector';
import { CustomTableExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customTable: {
      renderTableSizeSelector: (onDestroy?: () => void) => ReturnType;
      setSelectedTable: (position: number) => ReturnType;
      deleteRowAndRefocus: () => ReturnType;
      copyTable: (onSuccess: () => void) => ReturnType;
    };
  }
}

export const CustomTable = CustomTableExt.extend<TableOptions>({
  addNodeView() {
    return ({ HTMLAttributes }) => {
      const tableWrapperElement = document.createElement('div');
      const tableElement = document.createElement('table');
      const tableBodyElement = document.createElement('tbody');

      tableWrapperElement.classList.add('custom-table-wrapper');

      tableWrapperElement.appendChild(tableElement);
      tableElement.appendChild(tableBodyElement);

      if (HTMLAttributes.selected) {
        tableWrapperElement.classList.add('custom-table-wrapper_selected');
      }

      if (HTMLAttributes.class) {
        const classNames = HTMLAttributes.class.split(' ');
        tableWrapperElement.classList.add(...classNames);
      }

      return {
        dom: tableWrapperElement,
        contentDOM: tableBodyElement,
      };
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setSelectedTable:
        (position) =>
        ({ commands }) => {
          commands.focus(position + 1);
          return commands.setNodeSelection(position);
        },
      deleteRowAndRefocus:
        () =>
        ({ state, commands, tr, dispatch }) => {
          let lastRowNode: Node | null = null;
          let currentRowNode: Node | null = null;
          let tablePosition: number | null = null;

          state.tr.selection.ranges.forEach((range) => {
            const from = range.$from.pos;
            const to = range.$to.pos;

            state.doc.nodesBetween(from, to, (node, pos) => {
              if (node.type.name === 'table') {
                tablePosition = pos + 2;
                lastRowNode = node.lastChild;
              }
              if (node.type.name === 'tableRow') {
                currentRowNode = node;
              }
            });
          });

          commands.deleteRow();

          if (dispatch) {
            dispatch(tr.setMeta('isNeedUpdateTableMenuPosition', true));
          }

          if (
            lastRowNode &&
            currentRowNode &&
            tablePosition !== null &&
            lastRowNode === currentRowNode
          ) {
            commands.focus(tablePosition as number);
          }

          return true;
        },
      copyTable:
        (onSuccess) =>
        ({ state, tr, view, editor }) => {
          const { selection } = tr;

          if (selection instanceof CellSelection) {
            let tableNode: Node | null = null;
            const tableRowNodes: Node[] = [];
            const tableCellNodes: Node[] = [];

            state.tr.selection.ranges.forEach((range) => {
              const from = range.$from.pos;
              const to = range.$to.pos;

              state.doc.nodesBetween(from, to, (node) => {
                if (node.type.name === 'table') {
                  tableNode = node;
                }

                if (node.type.name === 'tableRow') {
                  if (!tableRowNodes.includes(node)) {
                    tableRowNodes.push(node);
                  }
                }

                if (
                  node.type.name === 'tableCell' ||
                  node.type.name === 'tableHeader'
                ) {
                  if (!tableCellNodes.includes(node)) {
                    tableCellNodes.push(node);
                  }
                }
              });
            });

            if (tableNode) {
              const tableRowNodesNew: Node[] = [];

              (tableNode as Node).content.forEach((rowNode) => {
                if (!tableRowNodes.includes(rowNode)) return;

                const content: Node[] = [];

                rowNode.content.forEach((cellNode) => {
                  if (tableCellNodes.includes(cellNode)) {
                    content.push(cellNode);
                  }
                });
                tableRowNodesNew.push(rowNode.copy(Fragment.from(content)));
              });

              const tableNodeNew = (tableNode as Node).copy(
                Fragment.from(tableRowNodesNew)
              );
              const docNode = view.state.doc.copy(Fragment.from(tableNodeNew));
              const tableHTML = generateHTML(
                (docNode as Node).toJSON(),
                editor.extensionManager.extensions
              );
              const tableWrapperElement = document.createElement('div');

              tableWrapperElement.innerHTML = tableHTML;

              navigator.clipboard
                .write([
                  new ClipboardItem({
                    'text/html': new Blob([tableHTML], { type: 'text/html' }),
                    'text/plain': new Blob(
                      [
                        (tableWrapperElement.firstChild as HTMLElement)
                          .innerText,
                      ],
                      { type: 'text/plain' }
                    ),
                  }),
                ])
                .then(onSuccess);
            }
          } else {
            let tableNode: Node | null = null;

            state.tr.selection.ranges.forEach((range) => {
              const from = range.$from.pos;
              const to = range.$to.pos;

              state.doc.nodesBetween(from, to, (node) => {
                if (node.type.name === 'table') {
                  tableNode = node;
                }
              });
            });

            const tableViewDesc = view.dom.pmViewDesc?.children.find(
              (item) => item.node === tableNode
            );

            if (tableViewDesc) {
              navigator.clipboard
                .write([
                  new ClipboardItem({
                    'text/html': new Blob(
                      [(tableViewDesc.dom as HTMLElement).innerHTML],
                      { type: 'text/html' }
                    ),
                    'text/plain': new Blob(
                      [(tableViewDesc.dom as HTMLElement).innerText],
                      { type: 'text/plain' }
                    ),
                  }),
                ])
                .then(onSuccess);
            }
          }

          return true;
        },
      renderTableSizeSelector:
        (onDestroy) =>
        ({ editor }) => {
          const { destroyComponent } =
            editor.extensionManager.commands.renderReactComponentWithTippy(
              TableSelector,
              {
                onClose: () => {
                  destroyComponent();
                  onDestroy?.();
                },
                onSelect: (rows: number, cols: number) => {
                  destroyComponent();
                  onDestroy?.();
                  editor.chain().insertTable({ rows, cols }).focus().run();
                },
              }
            );

          return true;
        },
    };
  },
});
