//eslint-disable-next-line
export function findObjectChanges<T extends Record<string, any>>(
  objectA: T,
  objectB: T
): { changes: Partial<T>; hasChanges: boolean } {
  const changes: Partial<T> = {};

  for (const [key, value] of Object.entries(objectA)) {
    if (value !== objectB[key]) {
      changes[key as keyof T] = objectB[key];
    }
  }

  return { changes, hasChanges: !!Object.keys(changes).length };
}
