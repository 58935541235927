import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { tasksModel } from '..';
import { tasksApi } from '../../../../../src/shared/api';
import { TasklistWithTasks } from '@distribute/shared/types';

export function* updateTasklistDataFromDb({
  payload: { successCallback, tasklistId },
}: ReturnType<typeof tasksModel.actions.updateTasklistDataFromDb>) {
  try {
    const tasklist: TasklistWithTasks = yield call(
      tasksApi.getTasklistData,
      tasklistId
    );

    const tasklists: Record<string, TasklistWithTasks> = yield select(
      tasksModel.selectors.selectTasklists
    );

    const newTasklists = { ...tasklists, [tasklistId]: tasklist };

    yield put(tasksModel.actions.setTasklists(newTasklists));

    successCallback(tasklist);
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update tasklist')
      )
    );
  }
}
