import { IconMap } from '../../../../../../../shared/sprite';
import { Button, Icon } from '../../../../../../../shared/ui';
import { getFileSizeString } from '../../../../../../../utils/files';
import React from 'react';

import {
  DOCX_FILE_FORMAT,
  DOC_FILE_FORMAT,
  PDF_FILE_FORMAT,
  TXT_FILE_FORMAT,
} from '@distribute/shared/types';
import { FileTypeForAI } from '../../../../../../page-creation-flow/config/types';

type Props = {
  file: FileTypeForAI;
  handleDelete(): void;
};

export const FileElement: React.FC<Props> = ({
  file: { name, size, type },
  handleDelete,
}) => {
  return (
    <div className="border border-gray-200 rounded-xl bg-base-white p-4 flex gap-3 relative mt-2">
      <div className="w-10 h-10 relative shrink-0">
        <Icon glyph={IconMap.FileDocument} className="ml-1" />
        <p className="absolute w-full text-center bottom-1.5 text-xxs font-bold text-gray-700">
          {type === TXT_FILE_FORMAT && 'TXT'}
          {type === DOC_FILE_FORMAT && 'DOC'}
          {type === DOCX_FILE_FORMAT && 'DOCX'}
          {type === PDF_FILE_FORMAT && 'PDF'}
        </p>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-700">{name}</p>
        <p className="text-sm text-gray-600">{getFileSizeString(size)}</p>
      </div>
      <Button
        variant="icon"
        color="transparent-with-actions"
        className="!absolute top-2 right-2"
        onClick={handleDelete}
      >
        <Icon glyph={IconMap.Delete} width={20} className="text-gray-600" />
      </Button>
    </div>
  );
};
