import React from 'react';
import { SelectCard } from './SelectCard';
import { IconMap } from '../../../../../../shared/sprite';
import { useFormContext } from 'react-hook-form';
import { CreatePageFormSchemaType } from '../../../../config/types';

export const PageBrandingOptions: React.FC = () => {
  const { watch, setValue } = useFormContext<CreatePageFormSchemaType>();

  const isBranded = watch('isBranded');

  const handleChangeIsBranded = (value: boolean) => {
    setValue('isBranded', value);
  };

  return (
    <div className="flex gap-4">
      <SelectCard
        isActive={isBranded}
        title="Branded page"
        icon={IconMap.BoldSquare}
        handleSelect={() => handleChangeIsBranded(true)}
      />
      <SelectCard
        isActive={!isBranded}
        title="General page"
        icon={IconMap.Letter}
        handleSelect={() => handleChangeIsBranded(false)}
      />
    </div>
  );
};
