import { useMemo, useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { TEMPLATES_ROUTE } from '../../../entities/history';
import { TemplatesPageList } from './TemplatesPageList';
import { useDispatch, useSelector } from 'react-redux';
import {
  templatesModel,
  CreateTemplateButton,
  SelectEventType,
} from '../../../features/templates';
import {
  TemplateCreationPhase,
  TemplateSharingAccess,
} from '@distribute/shared/types';
import { Button, Icon, Tabs } from '../../../shared/ui';
import { navigate } from '../../../processes/navigation';
import { IconMap } from '../../../shared/sprite';
import { NoCustomTemplates } from './NoCustomTemplates';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

import {
  PageCreationFlow,
  pageCreationFlowModel,
} from '../../../processes/page-creation-flow';
import { PageCreationFlowStepsEnum } from '../../../processes/page-creation-flow/config/types';

export enum TemplatesTabEnum {
  TEAM = 'team',
  PERSONAL = 'personal',
  DRAFTS = 'drafts',
}

type Props = {
  templateId?: number;
};

type TemplatesPageTab = {
  name: TemplatesTabEnum;
  title: string;
  component: JSX.Element;
};

export const TemplatesPage = (props?: Props) => {
  const { templateId } = props || {};
  const dispatch = useDispatch();

  const handleClickBrowseTemplates = () => {
    dispatch(
      pageCreationFlowModel.actions.openPageCreationFlow({ mode: 'template' })
    );
  };

  const isLoading = useSelector(
    pageCreationFlowModel.selectors.selectCreatePageIsLoading
  );

  const handleClickChooseTemplate = useCallback(() => {
    dispatch(pageCreationFlowModel.actions.openPageCreationFlow({}));
    dispatch(
      pageCreationFlowModel.actions.setCurrentStep(
        PageCreationFlowStepsEnum.CREATE_PAGE
      )
    );
  }, [dispatch]);

  const handleCreateTemplate = useCallback(
    ({ hasMultiTabs }: SelectEventType) => {
      dispatch(
        templatesModel.actions.createBlankTemplate({
          sharingAccess: TemplateSharingAccess.PERSONAL,
          isSinglePage: !hasMultiTabs,
        })
      );
    },
    [dispatch]
  );

  const createBlankTemplateIsLoading = useSelector(
    templatesModel.selectors.selectCreateBlankTemplateIsLoading
  );

  const templates = useSelector(templatesModel.selectors.selectTemplates);

  const customTemplates = useMemo(() => {
    const customTemplatesStartValue: {
      personal: TemplateExtended[];
      team: TemplateExtended[];
      drafts: TemplateExtended[];
    } = { personal: [], team: [], drafts: [] };

    return templates.reduce(
      (resTemplates, currTemplate) => {
        if (currTemplate.category !== null) {
          return resTemplates;
        }

        if (
          currTemplate.creationPhase === TemplateCreationPhase.DRAFT &&
          currTemplate.isOwner
        ) {
          resTemplates.drafts.push(currTemplate);
        } else if (
          currTemplate.sharingAccess === TemplateSharingAccess.PERSONAL
        ) {
          resTemplates.personal.push(currTemplate);
        } else if (currTemplate.sharingAccess === TemplateSharingAccess.TEAM) {
          resTemplates.team.push(currTemplate);
        }

        return resTemplates;
      },
      { ...customTemplatesStartValue }
    );
  }, [templates]);

  const tabs: TemplatesPageTab[] = useMemo(
    () => [
      {
        name: TemplatesTabEnum.TEAM,
        title: 'Team',
        icon: IconMap.TeamAccess,
        component: (
          <TemplatesPageList
            onChoose={handleClickChooseTemplate}
            templates={customTemplates.team}
            type={TemplatesTabEnum.TEAM}
            handleCreateTemplate={handleCreateTemplate}
          />
        ),
      },
      {
        name: TemplatesTabEnum.PERSONAL,
        title: 'Personal',
        icon: IconMap.EyeCrossedBold,
        component: (
          <TemplatesPageList
            onChoose={handleClickChooseTemplate}
            templates={customTemplates.personal}
            type={TemplatesTabEnum.PERSONAL}
            handleCreateTemplate={handleCreateTemplate}
          />
        ),
      },
      {
        name: TemplatesTabEnum.DRAFTS,
        title: 'Drafts',
        icon: IconMap.DraftPencil,
        component: (
          <TemplatesPageList
            onChoose={handleClickChooseTemplate}
            templates={customTemplates.drafts}
            type={TemplatesTabEnum.DRAFTS}
            handleCreateTemplate={handleCreateTemplate}
          />
        ),
      },
    ],
    [customTemplates, handleClickChooseTemplate, handleCreateTemplate]
  );

  const location = useLocation();

  const currentLocationTabName = location.pathname.split('/')[2];

  const initialTab =
    tabs.find((tab) => tab.name === currentLocationTabName) || tabs[0];

  const [selectedTab, setSelectedTab] = useState(initialTab);

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [initialTab]);

  const allCustomTemplates = useMemo(
    () => [
      ...customTemplates.team,
      ...customTemplates.personal,
      ...customTemplates.drafts,
    ],
    [customTemplates.team, customTemplates.personal, customTemplates.drafts]
  );

  useEffect(() => {
    if (templateId && allCustomTemplates.length) {
      const template = allCustomTemplates.find(
        (template) => template.id === templateId
      );
      if (template) {
        dispatch(templatesModel.actions.setCurrentTemplate(template));
        handleClickChooseTemplate();
      }
    }
  }, [allCustomTemplates, dispatch, handleClickChooseTemplate, templateId]);

  return (
    <>
      <Helmet titleTemplate="Templates - Distribute" />
      <PageCreationFlow />
      <div className="flex flex-col min-w-0 flex-grow-1">
        <div className="flex items-center justify-between gap-4 mb-6">
          <h1 className="font-medium text-gray-900 text-display-sm font-display">
            Templates
          </h1>
          <div
            className={cn('flex items-center gap-4 sm:hidden', {
              hidden: !allCustomTemplates.length,
            })}
          >
            <Button
              variant="text"
              color="secondary"
              size="md"
              onClick={handleClickBrowseTemplates}
              loading={isLoading}
              className="font-semibold shadow-xs"
            >
              Browse Templates
            </Button>
            <CreateTemplateButton
              listStyles="w-41"
              onSelect={handleCreateTemplate}
            >
              <Button
                variant="text"
                color="primary"
                size="md"
                className="font-semibold shadow-xs"
                loading={createBlankTemplateIsLoading}
              >
                <Icon
                  glyph={IconMap.TemplatesPlus}
                  width={20}
                  className="mr-1.5"
                />
                Create template
              </Button>
            </CreateTemplateButton>
          </div>
        </div>
        {allCustomTemplates.length ? (
          <Tabs
            selectedTab={selectedTab}
            tabs={tabs}
            onSelectTab={(tab) =>
              dispatch(navigate({ to: `${TEMPLATES_ROUTE}/${tab.name}` }))
            }
            className="!mb-0"
            tabClassName="px-2 pb-3 !pt-0"
          >
            <BrowserRouter basename={TEMPLATES_ROUTE}>
              <Switch>
                {tabs.map((tab) => {
                  return (
                    <Route
                      path={`${TEMPLATES_ROUTE}/${tab.name}`}
                      key={tab.name}
                      render={() => tab.component}
                    />
                  );
                })}
                <Route
                  path={TEMPLATES_ROUTE}
                  render={() => (
                    <TemplatesPageList
                      onChoose={handleClickChooseTemplate}
                      templates={customTemplates.team}
                      type={TemplatesTabEnum.TEAM}
                      handleCreateTemplate={handleCreateTemplate}
                    />
                  )}
                />
              </Switch>
            </BrowserRouter>
          </Tabs>
        ) : (
          <NoCustomTemplates
            handleCreateTemplate={handleCreateTemplate}
            handleBrowseTemplates={handleClickBrowseTemplates}
          />
        )}
      </div>
    </>
  );
};
