import { isDevelopment, isStagingEnvironment } from '../../shared/config';

import {
  COMPLETE_PASSWORD_RESET_ROUTE,
  CONFIRM_DELETE_TEAM_ROUTE,
  GETTING_STARTED_ROUTE,
  LEGAL_PRIVACY_POLICY_ROUTE,
  LEGAL_TERMS_OF_USE_ROUTE,
  LOGIN_ROUTE,
  NOT_FOUND_ROUTE,
  ONBOARDING_ROUTE,
  PUBLIC_TEMPLATE_ROUTE,
  QUICK_RECORDINGS_MY_ROUTE,
  QUICK_RECORDINGS_ROUTE,
  QUICK_RECORDINGS_SHARED_ROUTE,
  QUICK_RECORDING_ROUTE,
  RECORDINGS_ROUTE,
  REGISTER_APP_SUMO_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  SETTINGS_INTEGRATIONS_ROUTE,
  SETTINGS_PROFILE_ROUTE,
  SETTINGS_ROUTE,
  SNIPPETS_DRAFTS_ROUTE,
  SNIPPETS_PERSONAL_ROUTE,
  SNIPPETS_ROUTE,
  SNIPPETS_TEAM_ROUTE,
  SUCCESSFULLY_CREATED_ACCOUNT_ROUTE,
  TEAM_LEFT_ROUTE,
  TEAM_SETTINGS_BRAND_ROUTE,
  TEAM_SETTINGS_CALL_RECORDINGS,
  TEAM_SETTINGS_INFO_ROUTE,
  TEAM_SETTINGS_MEMBERS_ROUTE,
  TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE,
  TEAM_SETTINGS_ROUTE,
  TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
  TEMPLATES_APPLY_ROUTE,
  TEMPLATES_DRAFTS_ROUTE,
  TEMPLATES_IFRAME_DEMO_ROUTE,
  TEMPLATES_IFRAME_MENU_ROUTE,
  TEMPLATES_IFRAME_PREVIEW_ROUTE,
  TEMPLATES_IFRAME_TEMPLATES_ROUTE,
  TEMPLATES_PERSONAL_ROUTE,
  TEMPLATES_ROUTE,
  TEMPLATES_TEAM_ROUTE,
  UI_COMPONENTS_PAGE,
  VERIFY_EMAIL_ROUTE,
  WORKSPACE_ANALYTICS_ROUTE,
  WORKSPACE_FOLDER_ROUTE,
  WORKSPACE_LEADS_ROUTE,
  WORKSPACE_RECENT_VIEWERS_ROUTE,
  WORKSPACE_ROUTE,
  SETTINGS_SUPERUSER_ROUTE,
  TEAM_SETTINGS_STYLES_ROUTE,
  PAGES_OVERVIEW_ROUTE,
  EXPIRED_INVITATION_ROUTE,
  CALL_RECORDINGS_ROUTE,
} from '../../entities/history';

import { ForgotPassword } from '../forgot-password';

import { NotFound } from '../not-found';
import { Onboarding } from '../onboarding';
import { SignIn } from '../sign-in';
import { SignUp } from '../sign-up';

import { Analytics } from '../analytics';
import { CreatePassword } from '../create-password';
import { SettingsPage } from '../settings';
import { WorkspacePage } from '../workspace';
import { PagesOverview } from '../pages-overview';

import { ConfirmDeleteTeam } from '../confirm-delete-team';
import { EmailVerification } from '../email-verification';
import { GettingStartedPage } from '../getting-started';
import { PrivacyPolicy } from '../privacy-policy';
import { PublicTemplatePage } from '../public-template';
import {
  RecordingBlankPage,
  RecordingDetailsPage,
  RecordingsPage,
} from '../recordings';
import { SignUpAppSumo } from '../sign-up-app-sumo';
import { SuccessfullyCreatedAccount } from '../successfully-created-account';
import { TeamLeft } from '../team-left';
import { TeamSettingsPage } from '../team-settings';
import { TemplatesApplyPage, TemplatesPage } from '../templates';
import { TemplatesIframeContentPage } from '../templates-iframe';
import { TemplatesIframeMenuContentPage } from '../templates-iframe/menu';
import { TemplatesIframePreviewContentPage } from '../templates-iframe/preview';
import { TermsOfUse } from '../terms-of-use';
import { ComponentsPage } from '../ui-components';
import { TemplatesIframeDemoPage } from '../templates-iframe/demo';
import { SnippetsPage } from '../snippets';
import { ExpiredInvitationPage } from '../expired-invitation';
import { CallRecordingsPage } from '../call-recordings';

export const GENERAL_ROUTES = {
  [ONBOARDING_ROUTE]: () => <Onboarding />,
  [TEAM_LEFT_ROUTE]: () => <TeamLeft />,
  [CONFIRM_DELETE_TEAM_ROUTE]: () => <ConfirmDeleteTeam />,
  [PUBLIC_TEMPLATE_ROUTE]: () => <PublicTemplatePage />,
  [QUICK_RECORDING_ROUTE]: () => <RecordingDetailsPage />,
};

export const MAIN_LAYOUT_ROUTES = {
  [WORKSPACE_ANALYTICS_ROUTE]: () => <Analytics />,
  [WORKSPACE_LEADS_ROUTE]: () => <Analytics />,
  [WORKSPACE_RECENT_VIEWERS_ROUTE]: () => <Analytics />,
  [WORKSPACE_ROUTE]: () => <WorkspacePage />,
  [PAGES_OVERVIEW_ROUTE]: () => <PagesOverview />,
  [WORKSPACE_FOLDER_ROUTE]: () => <WorkspacePage />,
  [GETTING_STARTED_ROUTE]: () => <GettingStartedPage />,
  [TEMPLATES_APPLY_ROUTE]: () => <TemplatesApplyPage />,
  [TEMPLATES_ROUTE]: () => <TemplatesPage />,
  [TEMPLATES_PERSONAL_ROUTE]: () => <TemplatesPage />,
  [TEMPLATES_TEAM_ROUTE]: () => <TemplatesPage />,
  [TEMPLATES_DRAFTS_ROUTE]: () => <TemplatesPage />,
  [SNIPPETS_ROUTE]: () => <SnippetsPage />,
  [SNIPPETS_PERSONAL_ROUTE]: () => <SnippetsPage />,
  [SNIPPETS_TEAM_ROUTE]: () => <SnippetsPage />,
  [SNIPPETS_DRAFTS_ROUTE]: () => <SnippetsPage />,
  [QUICK_RECORDINGS_ROUTE]: () => <RecordingsPage />,
  [QUICK_RECORDINGS_MY_ROUTE]: () => <RecordingsPage />,
  [QUICK_RECORDINGS_SHARED_ROUTE]: () => <RecordingsPage />,
  [CALL_RECORDINGS_ROUTE]: () => <CallRecordingsPage />,
};

export const TEAM_SETTINGS_ROUTES = {
  [TEAM_SETTINGS_INFO_ROUTE]: () => <TeamSettingsPage />,
  [TEAM_SETTINGS_BRAND_ROUTE]: () => <TeamSettingsPage />,
  [TEAM_SETTINGS_CALL_RECORDINGS]: () => <TeamSettingsPage />,
  [TEAM_SETTINGS_STYLES_ROUTE]: () => <TeamSettingsPage />,
  [TEAM_SETTINGS_MEMBERS_ROUTE]: () => <TeamSettingsPage />,
  [TEAM_SETTINGS_SUBSCRIPTION_ROUTE]: () => <TeamSettingsPage />,
  [TEAM_SETTINGS_ROUTE]: () => <TeamSettingsPage />,
  [TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE]: () => <TeamSettingsPage />,
};

export const SETTINGS_ROUTES = {
  [SETTINGS_PROFILE_ROUTE]: () => <SettingsPage />,
  [SETTINGS_INTEGRATIONS_ROUTE]: () => <SettingsPage />,
  [SETTINGS_ROUTE]: () => <SettingsPage />,
  [SETTINGS_SUPERUSER_ROUTE]: () => <SettingsPage />,
};

export const PUBLIC_ROUTES = {
  [REGISTER_ROUTE]: () => <SignUp />,
  [REGISTER_APP_SUMO_ROUTE]: () => <SignUpAppSumo />,
  [SUCCESSFULLY_CREATED_ACCOUNT_ROUTE]: () => <SuccessfullyCreatedAccount />,
  [LOGIN_ROUTE]: () => <SignIn />,
  [VERIFY_EMAIL_ROUTE]: () => <EmailVerification />,
  [RESET_PASSWORD_ROUTE]: () => <ForgotPassword />,
  [COMPLETE_PASSWORD_RESET_ROUTE]: () => <CreatePassword />,
  [LEGAL_PRIVACY_POLICY_ROUTE]: () => <PrivacyPolicy />,
  [LEGAL_TERMS_OF_USE_ROUTE]: () => <TermsOfUse />,
  [RECORDINGS_ROUTE]: () => <RecordingBlankPage />,
  [QUICK_RECORDING_ROUTE]: () => <RecordingDetailsPage />,
  [EXPIRED_INVITATION_ROUTE]: () => <ExpiredInvitationPage />,
  [NOT_FOUND_ROUTE]: () => <NotFound />,
  [TEMPLATES_IFRAME_TEMPLATES_ROUTE]: () => <TemplatesIframeContentPage />,
  [TEMPLATES_IFRAME_PREVIEW_ROUTE]: () => <TemplatesIframePreviewContentPage />,
  [TEMPLATES_IFRAME_MENU_ROUTE]: () => <TemplatesIframeMenuContentPage />,
  [TEMPLATES_IFRAME_DEMO_ROUTE]: () => <TemplatesIframeDemoPage />,
  [UI_COMPONENTS_PAGE]: () =>
    isDevelopment || isStagingEnvironment ? <ComponentsPage /> : <NotFound />,
};
