import {
  GenerateCallSummaryRequest,
  UpdateCallRequest,
} from '@distribute/shared/api-types/call';

export type DeleteCallItemAction = {
  id: string;
  isFromList?: boolean;
};

export type UpdateCallItemAction = {
  id: string;
  data: UpdateCallRequest;
  isFromList?: boolean;
};

export type SetPublicPlaybackAction = {
  callId: string;
};

export type ChoosePageOrFolderData = {
  folderId: string;
  folderName?: string;
  pageId?: string;
};

export enum CreateOrInsertToPageFlowsEnum {
  SUMMARY_AND_VIDEO = 'summary-and-video',
  PAGE_WITH_AI = 'page-with-ai',
  VIDEO_ONLY = 'video-only',
}

export type GenerateSummaryAction = GenerateCallSummaryRequest;
export type ChooseFolderOrPageAction = ChoosePageOrFolderData;
