import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { tasksModel } from '..';
import { tasksApi } from '../../../../../src/shared/api';
import { TasklistWithTasks } from '@distribute/shared/types';

export function* removeTaskAssignee({
  payload: { successCallback, failedCallback, tasklistId, ...payloadData },
}: ReturnType<typeof tasksModel.actions.removeTaskAssignee>) {
  const tasklists: Record<string, TasklistWithTasks> = yield select(
    tasksModel.selectors.selectTasklists
  );

  try {
    const { taskId, documentContentId } = payloadData;

    const tasklist = tasklists[tasklistId];
    const taskIndex = tasklist.tasks.findIndex((t) => t.id === taskId);
    const tasks = [...tasklist.tasks];
    const updatedTask = { ...tasklist.tasks[taskIndex], assignee: undefined };
    tasks.splice(taskIndex, 1, updatedTask);

    const updatedTasklist = { ...tasklists[tasklistId], tasks };

    const newTasklists = {
      ...tasklists,
      [tasklistId]: updatedTasklist,
    };

    yield put(tasksModel.actions.setTasklists(newTasklists));

    yield call(tasksApi.removeTaskAssignee, taskId, documentContentId);

    successCallback();
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to remove task assignee')
      )
    );

    yield put(tasksModel.actions.setTasklists(tasklists));

    failedCallback();
  }
}
