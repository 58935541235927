import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '../../../shared/ui';
import { teamsModel, useTeamPermissions } from '../../../features/teams';
import { IconMap } from '../../../shared/sprite';
import {
  TEAM_SETTINGS_BRAND_ROUTE,
  TEAM_SETTINGS_INFO_ROUTE,
  TEAM_SETTINGS_MEMBERS_ROUTE,
  TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE,
  TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
  TEAM_SETTINGS_STYLES_ROUTE,
  TEAM_SETTINGS_CALL_RECORDINGS,
} from '../../../entities/history';
import classNames from 'classnames';
import { matchPath, useLocation } from 'react-router-dom';
import { redirectActions } from '../../../entities/history';
import { navigate } from '../../../processes/navigation';
import { environment } from '../../../environments/environment';
import { FeatureFlags } from '../../../entities/feature-flag';
import { getCallRecordingsFeatureFlag } from '@distribute/shared/utils';
import { authUserModel } from '../../../entities/auth-user';

export const TeamSettingsNavigation: FC = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const { isCanManageSubscription, isOwner } = useTeamPermissions();

  const navigationInfo = [
    {
      id: 'workspace-settings',
      name: 'Workspace Settings',
      icon: IconMap.WorkspaceSettings,
      route: TEAM_SETTINGS_INFO_ROUTE,
      isHide: false,
    },
    {
      id: 'permissions-control',
      name: 'Permissions Control',
      icon: IconMap.Shield02,
      route: TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE,
      isHide: !isOwner || !environment.featureFlags[FeatureFlags.SNIPPETS],
    },
    {
      id: 'team-brand',
      name: 'Company information',
      icon: IconMap.BookClosed,
      route: TEAM_SETTINGS_BRAND_ROUTE,
      isHide: false,
    },
    {
      id: 'call-recordings',
      name: 'Call Recording Settings',
      icon: IconMap.HeadPhones02,
      route: TEAM_SETTINGS_CALL_RECORDINGS,
      isHide: !getCallRecordingsFeatureFlag(
        environment.featureFlags[FeatureFlags.CALL_RECORDING],
        currentUser.email
      ),
    },
    {
      id: 'team-styles',
      name: 'Styles',
      icon: IconMap.Palette,
      route: TEAM_SETTINGS_STYLES_ROUTE,
      isHide: false,
    },
    {
      id: 'members',
      name: 'Members',
      icon: IconMap.Members,
      route: TEAM_SETTINGS_MEMBERS_ROUTE,
      isHide: false,
    },
    {
      id: 'plans-billing',
      name: 'Plans & Billing',
      icon: IconMap.Award,
      route: TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
      isHide: !isCanManageSubscription,
    },
  ].filter((i) => !i.isHide);

  const currentRouteId =
    navigationInfo.find((navItem) =>
      matchPath(location.pathname, { path: navItem.route })
    )?.id || navigationInfo[0].id;

  return (
    <div className="px-4 py-5">
      <Button
        color="link-gray"
        variant="icon-text"
        size="sm"
        onClick={() => {
          dispatch(redirectActions.toWorkspaceByBackUrl());
        }}
        className="!px-3 !font-semibold md:hidden"
      >
        <Icon glyph={IconMap.BackArrow} className="mr-2" />
        Back
      </Button>

      <div className="my-4 text-lg font-bold text-gray-900 px-3 md:mt-18 truncate">
        {currentTeam.name}
      </div>
      {navigationInfo.map((navItem) => (
        <button
          key={navItem.id}
          className={classNames(
            'flex justify-start items-center w-full rounded-md px-3 py-2 group cursor-pointer group text-left whitespace-nowrap transition-colors focus:outline-none flex-row hover:cursor-pointer text-gray-700 gap-3 text-sm',
            {
              'hover:bg-light-3 focus:bg-light-5':
                currentRouteId !== navItem.id,
              'bg-gray-200 hover:bg-gray-200': currentRouteId === navItem.id,
            }
          )}
          onClick={() => {
            dispatch(
              navigate({
                to: navItem.route,
                isKeepQueryParams: true,
              })
            );
          }}
        >
          <Icon
            glyph={navItem.icon}
            width={20}
            className={classNames('text-gray-500 group-hover:text-gray-600', {
              'text-gray-600': currentRouteId === navItem.id,
            })}
          />
          {navItem.name}
        </button>
      ))}
    </div>
  );
};
