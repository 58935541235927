import {
  CreateTasklistResponseType,
  GetTasklistResponseType,
  GetTasklistsResponseType,
  SetTaskAssigneeResponseType,
  Task,
  UpdateTaskResponseType,
  UpdateTasklistResponseType,
} from '@distribute/shared/types';
import { api } from './instance';
import {
  CreateTasklistsByIdsRequestType,
  ReorderTasksRequestType,
  SetTaskAssigneeRequestType,
  UpdateTaskRequestType,
  UpdateTasklistRequestType,
} from '@distribute/shared/api-types/tasks';

export const tasksApi = {
  async createTask(tasklistId: string, documentContentId: number) {
    const { data: responseData } = await api.post<Task>(
      `tasks/${tasklistId}/${documentContentId}`
    );
    return responseData;
  },

  async deleteTask(taskId: string, documentContentId: number) {
    const { data } = await api.delete<GetTasklistResponseType>(
      `tasks/${taskId}/${documentContentId}`
    );
    return data;
  },

  async createTasklist(documentContentId: number) {
    const { data: responseData } = await api.post<CreateTasklistResponseType>(
      `tasks/list/new/${documentContentId}`
    );
    return responseData;
  },

  async getTasklistData(tasklistId: string) {
    const { data } = await api.get<GetTasklistResponseType>(
      `tasks/list/${tasklistId}`
    );

    return data;
  },

  async getTasklistsData(contentId: number) {
    const { data } = await api.get<GetTasklistsResponseType>(
      `tasks/lists/${contentId}`
    );

    return data;
  },

  async updateTask(
    taskId: string,
    documentContentId: number,
    data: UpdateTaskRequestType
  ) {
    const { data: responseData } = await api.patch<UpdateTaskResponseType>(
      `tasks/${taskId}/${documentContentId}`,
      data
    );
    return responseData;
  },

  async duplicateTask(taskId: string, documentContentId: number) {
    const { data: responseData } = await api.post<UpdateTasklistResponseType>(
      `tasks/duplicate/${taskId}/${documentContentId}`
    );
    return responseData;
  },

  async duplicateTasklist(tasklistId: string, documentContentId: number) {
    const { data: responseData } = await api.post<CreateTasklistResponseType>(
      `tasks/lists/duplicate/${tasklistId}/${documentContentId}`
    );
    return responseData;
  },

  async updateTasklist(
    tasklistId: string,
    documentContentId: number,
    data: UpdateTasklistRequestType
  ) {
    const { data: responseData } = await api.patch<UpdateTasklistResponseType>(
      `tasks/list/${tasklistId}/${documentContentId}`,
      data
    );
    return responseData;
  },

  async setTaskAssignee(
    taskId: string,
    documentContentId: number,
    data: SetTaskAssigneeRequestType
  ) {
    const { data: responseData } = await api.post<SetTaskAssigneeResponseType>(
      `tasks/assignee/${taskId}/${documentContentId}`,
      data
    );

    return responseData;
  },

  async removeTaskAssignee(taskId: string, documentContentId: number) {
    const { data: responseData } = await api.delete<UpdateTaskResponseType>(
      `tasks/assignee/${taskId}/${documentContentId}`
    );

    return responseData;
  },

  async reorderTasks(
    tasklistId: string,
    documentContentId: number,
    data: ReorderTasksRequestType
  ) {
    const { data: responseData } = await api.patch<UpdateTasklistResponseType>(
      `tasks/reorder/${tasklistId}/${documentContentId}`,
      data
    );
    return responseData;
  },

  async createTasklistsByIds(
    documentContentId: number,
    data: CreateTasklistsByIdsRequestType
  ) {
    const { data: responseData } = await api.post<string[]>(
      `tasks/lists/new/${documentContentId}`,
      data
    );
    return responseData;
  },
};
