import { SidebarTabLayout } from '../shared/SidebarTabLayout';

import { Button } from '../../../../../shared/ui/Button';
import { IconMap } from '../../../../../shared/sprite';
import { Icon } from '../../../../../shared/ui/Icon';
import ContactCardEditor from './contact-card-editor-tab/ContactCardEditor';
import { useDispatch } from 'react-redux';
import { editorLeftSidebarModel } from '../../../model';

export const ContactCardForm = () => {
  const dispatch = useDispatch();
  const handleCloseContactCardForm = () => {
    dispatch(editorLeftSidebarModel.actions.setContactCardEditorOpen(false));
  };

  const handleClose = () => {
    dispatch(editorLeftSidebarModel.actions.setContactCardEditorOpen(false));
    dispatch(editorLeftSidebarModel.actions.setSelectedOption(null));
  };

  return (
    <SidebarTabLayout
      headerComponent={
        <div className="flex justify-between">
          <Button
            size="md"
            color="secondary"
            variant="icon"
            onClick={handleCloseContactCardForm}
          >
            <Icon glyph={IconMap.ArrowLeft} width={20} />
          </Button>
          <Button
            onClick={handleClose}
            variant="text"
            color="secondary"
            size="md"
          >
            Close
          </Button>
        </div>
      }
    >
      <ContactCardEditor />
    </SidebarTabLayout>
  );
};
