import { useDispatch } from 'react-redux';
import { AnalyticsTab } from '../../../../../features/analytics';
import { viewersModel, ViewersTab } from '../../../../../features/viewers';
import { ToggleAnonymousUsers } from '../../../../../features/viewers/ui/components';
import { IconMap } from '../../../../../shared/sprite';
import { TabContent, Tabs } from '../../../../../shared/ui';
import { useCallback, useEffect, useState } from 'react';
import { ViewerCategory } from '@distribute/shared/api-types/viewers';

const tabs: TabContent[] = [
  {
    name: 'recent-viewers',
    title: 'Recent viewers',
    component: <ViewersTab key="recent-viewers" />,
    icon: IconMap.RecentViewers,
  },
  {
    name: 'leads',
    title: 'Leads',
    component: <ViewersTab key="leads" />,
    icon: IconMap.Lead,
  },
  {
    name: 'analytics',
    title: 'Analytics',
    component: <AnalyticsTab />,
    icon: IconMap.PieChartFilled,
  },
];

export const Analytics = () => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const resetCategoryState = useCallback(() => {
    dispatch(viewersModel.actions.setSearch(''));
    dispatch(viewersModel.actions.setPage(1));
    dispatch(viewersModel.actions.setRange({}));
    dispatch(viewersModel.actions.setDetails({}));
  }, [dispatch]);

  const handleSelectTab = (tab: TabContent) => {
    if (tab.name !== 'analytics') {
      const isLead = tab.name === 'leads';
      dispatch(
        viewersModel.actions.setCategory(
          isLead ? ViewerCategory.LEAD : ViewerCategory.RECENT_VIEWER
        )
      );
      dispatch(viewersModel.actions.setIsDisplayAnonymousToggle(!isLead));
      if (!isLead) {
        dispatch(viewersModel.actions.setIsShowAnonymous(true));
      }
      dispatch(viewersModel.actions.getViewersData({}));
      resetCategoryState();
    } else {
      dispatch(viewersModel.actions.setIsDisplayAnonymousToggle(false));
    }
    setCurrentTab(tab);
  };

  useEffect(() => {
    dispatch(viewersModel.actions.setCategory(ViewerCategory.RECENT_VIEWER));
    dispatch(viewersModel.actions.setIsDisplayAnonymousToggle(true));
    dispatch(viewersModel.actions.setIsShowAnonymous(true));
    resetCategoryState();
    dispatch(viewersModel.actions.getViewersData({}));
  }, [dispatch, resetCategoryState]);

  return (
    <div className="flex justify-center w-full py-8 overflow-y-auto bg-base-white rounded-2xl">
      <div className="flex flex-col flex-1 w-full max-w-264">
        <Tabs
          selectedTab={currentTab}
          tabs={tabs}
          onSelectTab={handleSelectTab}
          className="!mb-6"
          CustomComponent={<ToggleAnonymousUsers />}
        >
          {currentTab.component}
        </Tabs>
      </div>
    </div>
  );
};
