import { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { cn } from '@distribute/frontend/utils';

import { ButtonSquare, Icon, Tooltip } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

import { ChatResponseType, ChatStructuredData } from '@distribute/shared/types';

import { ChatBotMessageComment } from './ChatBotMessageComment';
import { ChatBotMessageSuggestion } from './ChatBotMessageSuggestion';
import { chatModel } from '../model';
import { GetChatMessageResponse } from '@distribute/shared/api-types/chat';

import { useTimer } from '../../../shared/hooks/useTimer';

type Props = {
  message?: GetChatMessageResponse;
  items: ChatStructuredData[];
  loading?: boolean;
  id: string;
};

export const ChatBotMessageResponse: FC<Props> = ({
  message,
  items,
  loading,
  id,
}) => {
  const [isActive, onActive] = useTimer();

  const loadingMessageIds = useSelector(
    chatModel.selectors.selectLoadingMessageIds
  );

  const dispatch = useDispatch();

  const handleVote = (upvote: boolean) => {
    if (!message) return;

    dispatch(
      chatModel.actions.upvote({
        chatId: message.chatId,
        messageId: message.id,
        data: { upvote },
      })
    );
  };

  const handleCopy = () => {
    onActive();
  };

  const messageLoading = !!loadingMessageIds.some((_id) => _id === message?.id);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col gap-y-4">
        {items.map((item, idx) => {
          const messageId = `${id}-${idx}`;

          if (item.type === ChatResponseType.Comment) {
            return <ChatBotMessageComment text={item.text} key={messageId} />;
          }

          if (item.type === ChatResponseType.Suggestion) {
            return item.changed ? (
              <ChatBotMessageSuggestion
                loading={loading}
                key={messageId}
                id={item.id}
                name={item.name}
                content={item.contentDiff}
              />
            ) : null;
          }

          return null;
        })}
      </div>
      <div className="flex flex-nowrap justify-between gap-x-4">
        <div className="flex flex-nowrap items-center gap-x-1.5 flex-shrink-0">
          {message && (
            <>
              <Tooltip
                sideOffset={4}
                hideArrow
                className="py-1.5"
                trigger={
                  <ButtonSquare
                    disabled={messageLoading}
                    size="xs"
                    variant="tertiary-gray"
                    className={cn('disabled:cursor-default', {
                      'text-success-600 hover:text-success-700':
                        message && message.upvote,
                    })}
                    onClick={() => handleVote(true)}
                  >
                    <Icon glyph={IconMap.ThumbsUp} width={16} />
                  </ButtonSquare>
                }
              >
                Helpful
              </Tooltip>
              <Tooltip
                sideOffset={4}
                hideArrow
                className="py-1.5"
                trigger={
                  <ButtonSquare
                    disabled={messageLoading}
                    size="xs"
                    variant="tertiary-gray"
                    className={cn('disabled:cursor-default', {
                      'text-error-600 hover:text-error-700':
                        message && message.upvote !== null && !message.upvote,
                    })}
                    onClick={() => handleVote(false)}
                  >
                    <Icon glyph={IconMap.ThumbsDown} width={16} />
                  </ButtonSquare>
                }
              >
                Not helpful
              </Tooltip>
            </>
          )}
        </div>
        {/* <Tooltip
          keepOpenOnTrigger
          sideOffset={4}
          hideArrow
          className="py-1.5"
          trigger={
            <ButtonSquare
              size="xs"
              variant="tertiary-gray"
              onClick={handleCopy}
            >
              <Icon glyph={IconMap.Copy05} width={16} />
            </ButtonSquare>
          }
        >
          {isActive ? 'Copied!' : 'Copy'}
        </Tooltip> */}
      </div>
    </div>
  );
};
