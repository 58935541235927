import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { TEAM_SETTINGS_ROUTE } from '../../../entities/history';
import { Subscription } from '../../../features/subscription';
import { useTeamPermissions } from '../../../features/teams';
import { TeamInfoSettings } from '../../../features/team-info-settings';
import { TeamMembers } from '../../../features/team-members';
import { TeamBrandSettings } from '../../../features/team-brand-settings';
import { TeamBrandStyles } from '../../../features/team-brand-styles';
import { TeamPermissionsControl } from '../../../features/team-permission-control';
import { CallRecordingSettings } from '../../../features/call-recording-settings';
import { environment } from '../../../environments/environment';
import { FeatureFlags } from '../../../entities/feature-flag';
import { getCallRecordingsFeatureFlag } from '@distribute/shared/utils';
import { useSelector } from 'react-redux';
import { authUserModel } from '../../../entities/auth-user';

type TeamSettingsTab = {
  name: string;
  title: string;
  component: JSX.Element;
  isHide?: boolean;
};

export const TeamSettingsPage = () => {
  const { isCanManageSubscription, isOwner } = useTeamPermissions();
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const tabs: TeamSettingsTab[] = useMemo(
    () =>
      [
        {
          name: 'info',
          title: 'Team',
          component: <TeamInfoSettings />,
        },
        {
          name: 'permissions-control',
          title: 'Permission Control',
          component: <TeamPermissionsControl />,
          isHide: !environment.featureFlags[FeatureFlags.SNIPPETS] || !isOwner,
        },
        {
          name: 'brand',
          title: 'Brand',
          component: <TeamBrandSettings />,
        },
        {
          name: 'styles',
          title: 'Styles',
          component: <TeamBrandStyles />,
        },
        {
          name: 'members',
          title: 'Members',
          component: <TeamMembers />,
        },
        {
          name: 'subscription',
          title: 'Plans & Billing',
          component: <Subscription />,
          isHide: !isCanManageSubscription,
        },
        {
          name: 'call-recordings',
          title: 'Call Recording Settings',
          component: <CallRecordingSettings />,
          isHide: !getCallRecordingsFeatureFlag(
            environment.featureFlags[FeatureFlags.CALL_RECORDING],
            currentUser.email
          ),
        },
      ].filter((i) => !i.isHide),
    [isCanManageSubscription, isOwner, currentUser.email]
  );

  return (
    <>
      <Helmet titleTemplate="Team Settings - Distribute" />
      <div className="flex flex-col flex-grow-1 min-w-0 md:-mt-8">
        <Switch>
          {tabs.map((tab) => {
            return (
              <Route
                path={`${TEAM_SETTINGS_ROUTE}/${tab.name}`}
                key={tab.name}
                render={() => tab.component}
              />
            );
          })}
          <Route path={TEAM_SETTINGS_ROUTE} render={() => tabs[0].component} />
        </Switch>
      </div>
    </>
  );
};
