import { JSONContent } from '@tiptap/react';

type Node = { [key: string]: any };

export function findCustomTaskLists(data: Node | Node[]): Node[] {
  const taskLists = [];

  if (Array.isArray(data)) {
    for (const item of data) {
      taskLists.push(...findCustomTaskLists(item));
    }
  } else if (typeof data === 'object' && data !== null) {
    if (data.type === 'customTaskList') {
      taskLists.push(data);
    }
    if (Array.isArray(data.content)) {
      taskLists.push(...findCustomTaskLists(data.content));
    }
  }

  return taskLists;
}

export const replaceTaskLists = (
  contentJson: JSONContent,
  newTasklistsIds: string[]
): JSONContent => {
  let newIndex = 0;

  const replaceRecursive = (content: JSONContent[]): JSONContent[] => {
    return content.map((item) => {
      if (item.type === 'customTaskList') {
        if (newIndex < newTasklistsIds.length) {
          const newId = newTasklistsIds[newIndex++];
          return {
            type: 'customTaskList',
            attrs: {
              id: newId,
            },
          };
        }
      }

      if (item.content && Array.isArray(item.content)) {
        item.content = replaceRecursive(item.content);
      }

      return item;
    });
  };

  contentJson.content = replaceRecursive(contentJson.content || []);
  return contentJson;
};

export const generateContentWithNewTasklists = (
  contentJson: JSONContent,
  newTasklistsIds: string[]
): JSONContent => {
  let newIndex = 0;

  const replaceRecursive = (content: JSONContent[]): JSONContent[] => {
    return content.map((item) => {
      const newItem = JSON.parse(JSON.stringify(item));

      if (newItem.type === 'customTaskList') {
        if (newIndex < newTasklistsIds.length) {
          const newId = newTasklistsIds[newIndex++];
          newItem.attrs = {
            id: newId,
          };
        }
      }

      if (newItem.content && Array.isArray(newItem.content)) {
        newItem.content = replaceRecursive(newItem.content);
      }

      return newItem;
    });
  };

  const newContentJson = JSON.parse(JSON.stringify(contentJson));
  newContentJson.content = replaceRecursive(newContentJson.content || []);

  return newContentJson;
};
