import { processDataStream } from 'ai';

import { AICompletionRequestType } from '@distribute/shared/api-types/ai';
import { API_URL, auth } from '../../config';
import { AIError } from '../../../features/ai/lib';
import {
  CreateWithAIRequestType,
  GenerateOutlineResponseType,
} from '@distribute/shared/api-types/create-with-ai';
import { api } from '../axios/instance';

import { logger } from '../../lib';

export const aiApi = {
  async getCompletion(
    data: AICompletionRequestType,
    abortSignal: AbortSignal,
    onFulfilled: (text: string) => void
  ) {
    try {
      const token = await auth.currentUser?.getIdToken();

      const response = await fetch(`${API_URL}/ai/completion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        signal: abortSignal,
      });

      if (!response.body) {
        throw new Error('Response is empty');
      }

      await processDataStream({
        stream: response.body,
        onTextPart(value) {
          onFulfilled(value);
        },
        onErrorPart(value) {
          throw new AIError(value);
        },
      });
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        return;
      }

      logger.error(error);
      throw error;
    }
  },

  async generatePageOutline(data: CreateWithAIRequestType) {
    const { data: responseData } = await api.post<GenerateOutlineResponseType>(
      `${API_URL}/create-with-ai/outline`,
      data,
      { params: { teamId: data.teamId } }
    );

    return responseData;
  },

  async generatePageContent(
    data: CreateWithAIRequestType,
    abortSignal: AbortSignal,
    onFulfilled: (text: string) => void
  ) {
    try {
      const token = await auth.currentUser?.getIdToken();

      const response = await fetch(
        `${API_URL}/create-with-ai?teamId=${data.teamId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
          signal: abortSignal,
        }
      );

      if (!response.body) {
        throw new Error('Response is empty');
      }

      await processDataStream({
        stream: response.body,
        onTextPart(value) {
          onFulfilled(value);
        },
        onErrorPart(value) {
          throw new AIError(value);
        },
      });
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        return;
      }
      throw error;
    }
  },
};
