import { createContext, useContext, useEffect, useMemo } from 'react';
import { Editor, EditorOptions, useEditor } from '@tiptap/react';
import { history } from '@tiptap/pm/history';
import YPartyKitProvider from 'y-partykit/provider';
import { useCollaboration } from '../../collaboration';

export type TiptapEditorContextValue = {
  editor: Editor | null;
  isEditorReady: boolean;
};

export const TiptapEditorContext = createContext<TiptapEditorContextValue>({
  editor: null,
  isEditorReady: false,
});

export type TiptapEditorProviderProps = {
  children: React.ReactNode;
  options?: Partial<EditorOptions>;
  preventClearHistory?: boolean;
  provider?: YPartyKitProvider;
  tabId?: number;
  canUseCollaboration?: boolean;
  isSuggestionMode?: boolean;
};

export const TiptapEditorProvider = ({
  children,
  options,
  preventClearHistory,
  tabId,
  canUseCollaboration = true,
  isSuggestionMode = false,
}: TiptapEditorProviderProps) => {
  const editorOptions: Partial<EditorOptions> = useMemo(() => {
    return {
      ...options,
      extensions: options?.extensions || [],
      content: options?.content || undefined,
      editorProps: {
        ...options?.editorProps,
        handleDOMEvents: {
          ...options?.editorProps?.handleDOMEvents,

          dragstart: (_, e) => {
            const target = e.target as HTMLElement;
            const parent = target.parentElement as HTMLElement;
            const isTimelineElement =
              parent.classList.contains('node-timelineItem');

            if (!isTimelineElement) {
              e.preventDefault();
            }
          },
        },
      },
    };
  }, [options]);

  const { isCollaborationEnabled } = useCollaboration();
  const isCollaborationMode = canUseCollaboration && isCollaborationEnabled;
  const editorDeps = isCollaborationMode
    ? [tabId, isSuggestionMode]
    : [isSuggestionMode];
  const editor = useEditor(editorOptions, editorDeps);

  // Clear selection highlighting when the user closes the browser during AI writing.
  // Clear selection table when the user closes the browser during table updating.
  useEffect(() => {
    editor?.commands.unsetHighlightSelection();
    editor?.commands.unsetTableSelection();
  }, [editor]);

  useEffect(() => {
    if (!editor) return;

    if (!isCollaborationMode && options?.content && !preventClearHistory) {
      setTimeout(() => {
        editor.commands.setContent(options?.content ?? {}, false);
        editor.unregisterPlugin('history');
        editor.registerPlugin(history());
      }, 0);
    }
    // Deps should be triggered only when tab id was changed
  }, [editor, tabId, isSuggestionMode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TiptapEditorContext.Provider value={{ editor, isEditorReady: !!editor }}>
      {children}
    </TiptapEditorContext.Provider>
  );
};

export const TiptapEditorConsumer = TiptapEditorContext.Consumer;

export const useTiptapEditor = () => useContext(TiptapEditorContext);
