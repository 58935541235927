import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Icon,
} from '../../../../../shared/ui';

import { IconMap } from '../../../../../shared/sprite';
import React, { useState } from 'react';
import { ElementItemProps } from './types';
import { ElementPreviewCard } from './PreviewCard';

export const ElementItem = ({
  name,
  id,
  icon,
  actionPreview,
  action,
}: ElementItemProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (e: React.DragEvent<HTMLLIElement>) => {
    setIsDragging(true);
    const dragIcon = e.currentTarget.cloneNode(true) as HTMLElement;
    dragIcon.style.opacity = '0.9';
    dragIcon.style.width = e.currentTarget.offsetWidth + 'px';
    dragIcon.style.border = '1px solid rgba(0, 0, 0, 0.04)';
    dragIcon.style.boxShadow = '0 4px 6px -2px rgba(16, 24, 40, 0.03)';
    dragIcon.style.borderRadius = '8px';
    document.body.appendChild(dragIcon);
    e.dataTransfer.setDragImage(dragIcon, 0, 0);
    setTimeout(() => document.body.removeChild(dragIcon), 0);

    // Add custom data that can be used by the editor
    e.dataTransfer.setData(
      'application/x-element',
      JSON.stringify({
        key: id,
        isElementsSidebarItem: true,
      })
    );
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <li
          draggable
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          className="group cursor-grab flex items-center gap-2 mb-0.5 p-2 rounded-lg hover:bg-base-black-4 w-full"
          onClick={action}
        >
          <span className="group-hover:text-gray-800 text-gray-600">
            {icon}
          </span>
          <p className="group-hover:text-gray-800 text-gray-700">{name}</p>
          <span className="ml-auto hidden group-hover:block">
            <Icon glyph={IconMap.Drag} />
          </span>
        </li>
      </HoverCardTrigger>

      <HoverCardContent
        hidden={isDragging}
        side="right"
        sideOffset={8}
        className="w-70"
      >
        <ElementPreviewCard actionPreview={actionPreview} />
      </HoverCardContent>
    </HoverCard>
  );
};
