import { IconMap } from '../../../../../../shared/sprite';
import { Icon } from '../../../../../../shared/ui';

type Props = {
  text: string;
  handleClick: (text: string) => void;
};

export const OtherTypeWrapper: React.FC<Props> = ({ text, handleClick }) => {
  return (
    <div
      className="w-100 p-0.375 bg-gray-200 rounded-xl group relative cursor-pointer"
      onClick={() => handleClick(text)}
    >
      <div className="w-full h-full rounded-xl absolute z-10 top-0 left-0 bg-gradient-orange-to-purple opacity-0 group-hover:opacity-100 transition-opacity"></div>
      <div className="p-4 pr-12 rounded-xl bg-base-white">
        <p className="text-base-black/60 text-sm text-left">{text}</p>
      </div>

      <div className="p-4 flex gap-3 rounded-xl absolute left-0.375 top-0.375 z-20 w-[calc(100%-3px)] h-[calc(100%-3px)] bg-base-white">
        <p className="text-base-black/60 text-sm text-left">{text}</p>
        <div className="w-6 h-6 relative">
          <Icon glyph={IconMap.Stars01Gray} width={20} className="shrink-0" />
          <Icon
            glyph={IconMap.Stars01Colored}
            width={20}
            className="shrink-0 absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity"
          />
        </div>
      </div>
    </div>
  );
};
