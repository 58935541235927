import React from 'react';
import { useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../../../model';
import { PageCreationTypesEnum } from '../../../../config/types';

type Props = {
  footer: React.ReactNode;
  children: React.ReactNode;
};

const PAGE_CREATION_TYPE_TO_NAME = {
  [PageCreationTypesEnum.FROM_SCRATCH]: 'Create page',
  [PageCreationTypesEnum.FROM_TEMPLATE]: 'Create page',
  [PageCreationTypesEnum.WITH_AI]: 'Create page with AI',
  [PageCreationTypesEnum.TAB]: 'Create tab with AI',
};

export const CreatePageStepLeftWrapper: React.FC<Props> = ({
  footer,
  children,
}) => {
  const pageCreationType = useSelector(
    pageCreationFlowModel.selectors.selectPageCreationType
  );

  return (
    <div className="pt-4 px-4 flex flex-col min-h-full h-full">
      <header className="sticky top-0 pb-6">
        <h1 className="font-display text-display-sm font-semibold text-gray-900 mb-0.5">
          {PAGE_CREATION_TYPE_TO_NAME[pageCreationType]}
        </h1>
        <p className="text-sm text-gray-600">
          What page type do you want to create today?
        </p>
      </header>
      <main className="flex-1 overflow-y-auto">{children}</main>
      <footer className="sticky bottom-0">{footer}</footer>
    </div>
  );
};
