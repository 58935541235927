import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  callRecordingSettingsModel,
  joinPreferenceOptions,
} from '../../call-recording-settings';
import { callRecordingsModel } from '../model';
import { IconMap } from '../../../shared/sprite';
import { Icon, Tooltip } from '../../../shared/ui';
import { MeetingJoinPreference } from '@distribute/shared/types';
import { MeetingEventsState } from './MeetingEventsState';

export const MeetingEventsSidebar = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const userSettings = useSelector(
    callRecordingSettingsModel.selectors
      .selectUserCallRecordingSettingsWithError
  );
  const [joinPreference, setJoinPreference] = useState(
    userSettings.joinPreference
  );

  const currentJoinPreference = useMemo(
    () => joinPreferenceOptions.find((item) => item.value === joinPreference),
    [joinPreference]
  );

  const handleChangeJoinPreference = (value: MeetingJoinPreference) => {
    setJoinPreference(value);
    dispatch(
      callRecordingsModel.actions.updateJoinPreference({
        joinPreference: value,
      })
    );
  };

  if (!isOpen) {
    return (
      <div
        className="absolute right-0 flex gap-1.5 bg-gray-100 p-1 h-fit rounded-md text-gray-700 font-semibold cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <Icon glyph={IconMap.ArrowOpenClose} width={24} />
        Upcoming meetings
      </div>
    );
  }

  return (
    <aside className="flex flex-col text-gray-900 w-80 border border-gray-200 shadow-lg rounded-xl p-4">
      <div className="flex flex-col gap-2.75 border-b border-gray-200 pb-4 mb-4">
        <header className="flex justify-between">
          <h3 className="text-lg font-semibold">Upcoming meetings</h3>
          <Tooltip
            hideArrow
            trigger={
              <Icon
                onClick={() => setIsOpen(false)}
                className="rotate-180 cursor-pointer"
                glyph={IconMap.ArrowOpenClose}
                width={24}
              />
            }
          >
            Hide
          </Tooltip>
        </header>
        <DropdownMenu.Root
          open={isDropdownOpen}
          onOpenChange={setIsDropdownOpen}
        >
          <DropdownMenu.Trigger className="w-full bg-gray-100 px-2 py-1.5 rounded-lg focus:outline-none mb-1 text-gray-600 hover:bg-gray-200">
            <div className="flex justify-between items-center">
              <span className="flex items-center gap-2 text-sm">
                <Icon glyph={IconMap.ArrowCalendar} width={16} />
                {currentJoinPreference?.label}
              </span>
              <Icon
                className={cn('transition-all', {
                  'rotate-180': isDropdownOpen,
                })}
                glyph={IconMap.ArrowDown}
                width={16}
              />
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            sideOffset={4}
            className="bg-base-white border border-gray-300 rounded-md shadow-lg w-72 px-1.5 py-0.25 text-gray-700 z-10"
          >
            {joinPreferenceOptions.map((item) => (
              <DropdownMenu.Item
                className="flex justify-between items-center rounded-md text-sm font-medium focus:outline-none py-2.25 px-2.5 hover:bg-base-black/[0.04] hover:text-gray-800"
                key={item.name}
                onClick={() => handleChangeJoinPreference(item.value)}
              >
                {item.label}
                {userSettings?.joinPreference === item.value ? (
                  <Icon glyph={IconMap.GreenCheckMark} />
                ) : null}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
      <MeetingEventsState />
    </aside>
  );
};
