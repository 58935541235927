import { JSONContent } from '@tiptap/core';

export enum ChatMessageType {
  REQUEST = 'request',
  RESPONSE = 'response',
}

export enum ChatResponseType {
  Comment = 'comment',
  Suggestion = 'suggestion',
}

export type ChatStructuredCommentData = {
  type: ChatResponseType.Comment;
  text: string;
};

export type ChatStructuredSuggestionData = {
  type: ChatResponseType.Suggestion;
  name: string;
  id: string;
  content: JSONContent;
  contentDiff: JSONContent;
  changed: boolean;
};

export type ChatStructuredData =
  | ChatStructuredCommentData
  | ChatStructuredSuggestionData;

export type ChatMessageContent =
  | { type: ChatMessageType.REQUEST; data: string }
  | {
      type: ChatMessageType.RESPONSE;
      data: string;
      structuredData: ChatStructuredData[];
    };

export type ChatMessage = {
  id: string;
  content: ChatMessageContent;
  upvote: boolean | null;
  chatId: string;
  createdAt: Date;
  updatedAt: Date;
};

export type Chat = {
  id: string;
  userToTeamId: number;
  entityId: string;
  createdAt: Date;
  updatedAt: Date;
};
