import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { tasksModel } from '..';
import { tasksApi } from '../../../../../src/shared/api';
import { Page, TasklistWithTasks, Template } from '@distribute/shared/types';
import { pagesModel } from '../../../pages';
import { templatesModel } from '../../../templates';

export function* duplicateTasklist({
  payload: { successCallback, tasklistId },
}: ReturnType<typeof tasksModel.actions.duplicateTasklist>) {
  const page: Page = yield select(pagesModel.selectors.selectCurrentPage);
  const template: Template = yield select(
    templatesModel.selectors.selectCurrentTemplate
  );

  const documentContentId = page?.content.id || template?.content.id;

  if (!documentContentId) {
    return;
  }

  const tasklists: Record<string, TasklistWithTasks> = yield select(
    tasksModel.selectors.selectTasklists
  );

  try {
    const tasklist: TasklistWithTasks = yield call(
      tasksApi.duplicateTasklist,
      tasklistId,
      documentContentId
    );

    yield put(
      tasksModel.actions.setTasklists({ ...tasklists, [tasklist.id]: tasklist })
    );

    successCallback(tasklist.id);
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to duplicate tasklist')
      )
    );
  }
}
