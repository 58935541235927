import { put, call, select } from 'redux-saga/effects';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { callModel } from '../index';
import { callApi } from '../../../../shared/api';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* fetchCalls() {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);
  const callListMeta: RT<typeof callModel.selectors.selectCallListMeta> =
    yield select(callModel.selectors.selectCallListMeta);
  const callListOptions: RT<typeof callModel.selectors.selectCallListOptions> =
    yield select(callModel.selectors.selectCallListOptions);

  yield put(callModel.actions.setIsListLoading(true));

  try {
    const { items, meta }: RT<typeof callApi.getCalls> = yield call(
      callApi.getCalls,
      {
        teamId: team.id,
        page: callListMeta.currentPage,
        limit: callListMeta.itemsPerPage,
        ...callListOptions,
      }
    );
    yield put(callModel.actions.setCalls(items));
    yield put(callModel.actions.setListMeta(meta));
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to load call recordings')
      )
    );
  } finally {
    yield put(callModel.actions.setIsListLoading(false));
  }
}

export function* loadCalls() {
  yield call(fetchCalls);
}
