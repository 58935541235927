import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { chatModel } from '../';

import { loadChatData } from './loadChatData';

import { conversationWorker } from './conversationWorker';
import { actions } from '../reducer';
import { upvote } from './upvote';
import { deleteChatMessages } from './deleteChatMessages';

import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { authUserModel } from '../../../../entities/auth-user';
import { getTeamPermissions } from '../../../teams/lib';

export function* chatWorker(entityId: string) {
  yield put(chatModel.actions.clearFlow());

  const members: RT<typeof teamsModel.selectors.selectCurrentTeamMembers> =
    yield select(teamsModel.selectors.selectCurrentTeamMembers);
  const currentUser: RT<typeof authUserModel.selectors.selectUserWithError> =
    yield select(authUserModel.selectors.selectUserWithError);

  const { isGuest } = getTeamPermissions(currentUser, members);

  if (isGuest) return;

  yield fork(conversationWorker);
  yield takeEvery(actions.upvote, upvote);
  yield takeEvery(actions.deleteChatMessages, deleteChatMessages);

  yield call(loadChatData, entityId);
}
