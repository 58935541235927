import { call, takeEvery, debounce, put, fork } from 'redux-saga/effects';
import { loadCallDetails } from '../../../features/call/model/sagas';
import { matchParams, getQueryParams } from '../../../shared/lib';
import { callModel } from '../../../features/call';
import { CreateOrInsertToPageFlowsEnum } from '../../../features/call/model/types';
import { history } from '../../../entities/history';

export function* callPageWorker(location: Location, route: string) {
  const { pathname } = location;
  const params: { id: string } | null = matchParams(pathname, route);
  const searchParams = getQueryParams();
  const shouldOpenCreateAIFlow = searchParams.get('create-with-ai');

  if (params?.id) {
    yield call(loadCallDetails, params.id);
    yield put(callModel.actions.setIsCurrentCallLoading(false));
  }

  yield takeEvery(
    callModel.actions.generateSummary,
    callModel.sagas.generateSummary
  );

  yield takeEvery(
    callModel.actions.updateCallItem,
    callModel.sagas.updateCallItem
  );

  yield takeEvery(
    callModel.actions.deleteCallItem,
    callModel.sagas.deleteCallItem
  );

  yield takeEvery(
    callModel.actions.setPublicPlayback,
    callModel.sagas.setPublicPlayback
  );

  yield takeEvery(
    callModel.actions.setPageCreationFlow,
    callModel.sagas.createPageFlow
  );

  yield debounce(
    300,
    callModel.actions.updateCallItemWithDebounce,
    callModel.sagas.updateCallItem
  );

  yield fork(callModel.sagas.watchCallDetails);

  if (shouldOpenCreateAIFlow) {
    yield put(
      callModel.actions.setPageCreationFlow(
        CreateOrInsertToPageFlowsEnum.PAGE_WITH_AI
      )
    );

    searchParams.delete('create-with-ai');
    history.replace({ search: searchParams.toString() });
  }
}
