import {
  CallSummaryGeneralContent,
  CallSummarySalesContent,
  CallSummaryType,
  CompletedCallSummary,
} from '@distribute/shared/types';

const formatSection = (
  title: string,
  content: string | null | undefined
): string => {
  if (!content) return '';
  return `${title}\n${content}\n\n`;
};

const formatBulletList = (items: string[]): string => {
  return items.map((item) => `• ${item}`).join('\n');
};

const formatAssignedItem = (item: {
  text: string;
  assignedTo?: string | null;
  timeline?: string;
}): string => {
  let result = `• ${item.text}`;
  if (item.assignedTo) result += ` - Assigned to: ${item.assignedTo}`;
  if (item.timeline) result += ` (Due: ${item.timeline})`;
  return result;
};

const getCallSummaryGeneralText = (
  content: CallSummaryGeneralContent
): string => {
  const { meetingPurpose, takeaways, topics, nextSteps, actionItems } = content;

  const sections = [
    formatSection('Meeting Purpose', meetingPurpose),
    formatSection(
      'Key Takeaways',
      formatBulletList(takeaways.map((t) => `${t.text}.`))
    ),
    formatSection(
      'Topics',
      topics
        .map(
          ({ subject, items }) =>
            `${subject}\n${items
              .map((item) => `• ${item.text}: ${item.details}.`)
              .join('\n')}`
        )
        .join('\n\n')
    ),
    formatSection('Next Steps', nextSteps.map(formatAssignedItem).join('\n')),
    formatSection(
      'Action Items',
      (actionItems ?? []).map(formatAssignedItem).join('\n')
    ),
  ];

  return sections.join('').trim();
};

const getCallSummarySalesText = (content: CallSummarySalesContent): string => {
  const {
    prospect,
    callContext,
    seats,
    budget,
    currentState,
    painPoints,
    specificRequirements,
    techStack,
    objections,
    otherSolutions,
    timeline,
    decisionMaker,
    procurementProcess,
    nextSteps,
    questionsWeAsked,
    questionsTheyAsked,
    actionItems,
  } = content;

  const prospectDetails = [
    `• Company: ${prospect.company}`,
    `• Contacts: ${prospect.contacts}`,
    `• Description: ${prospect.description}`,
  ].join('\n');

  const sections = [
    formatSection('Deal Information\n\nProspect Details', prospectDetails),
    formatSection('Call Context', `${callContext}.`),
    formatSection('Seats', `${seats}.`),
    formatSection('Budget', `${budget}.`),
    formatSection('Current State', `${currentState}.`),
    formatSection(
      'Pain Points',
      formatBulletList(painPoints.map((p) => `${p}.`))
    ),
    formatSection(
      'Specific Requirements',
      formatBulletList(specificRequirements.map((r) => `${r}.`))
    ),
    formatSection('Tech Stack', formatBulletList(techStack)),
    formatSection(
      'Objections',
      objections
        .map(
          (obj, idx) =>
            `${idx + 1}. Concern: ${obj.objection}\n   • Status: ${
              obj.status
            }\n   • Resolution: ${obj.resolution}.`
        )
        .join('\n')
    ),
    formatSection(
      'Competitive Landscape',
      `Other solutions being evaluated:\n${formatBulletList(otherSolutions)}`
    ),
    formatSection('Timeline', `${timeline}.`),
    formatSection(
      'Decision Maker',
      `1. Primary: ${decisionMaker.primary}\n2. Other stakeholders: ${decisionMaker.otherStakeholders}`
    ),
    formatSection('Procurement Process', `${procurementProcess}.`),
    formatSection(
      'Next Steps',
      formatBulletList(nextSteps.map((s) => `${s}.`))
    ),
    formatSection(
      'Q&A\n\n Questions We Asked',
      questionsWeAsked
        .map((q, idx) => `${idx + 1}. ${q.question}\n   • ${q.response}.`)
        .join('\n')
    ),
    formatSection(
      'Questions They Asked',
      questionsTheyAsked
        .map((q, idx) => `${idx + 1}. ${q.question}\n   • ${q.response}.`)
        .join('\n')
    ),
    formatSection(
      'Action Items',
      actionItems.map(formatAssignedItem).join('\n')
    ),
  ];

  return sections.join('').trim();
};

export function getSummaryText(callSummary: CompletedCallSummary) {
  switch (callSummary.type) {
    case CallSummaryType.GENERAL:
      return getCallSummaryGeneralText(callSummary.content);
    case CallSummaryType.SALES:
      return getCallSummarySalesText(callSummary.content);
    case CallSummaryType.SALES_BANT:
    case CallSummaryType.SALES_MEDDPICC:
    case CallSummaryType.SALES_SANDLER:
    case CallSummaryType.QA:
    case CallSummaryType.CUSTOMER_SUCCESS:
    case CallSummaryType.DEMO:
      return '';
    default:
      return '';
  }
}
