import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import HardBreak from '@tiptap/extension-hard-break';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import { Attribute, mergeAttributes } from '@tiptap/core';
import Blockquote from '@tiptap/extension-blockquote';
import { generateHTML } from '@tiptap/html';
import { DocumentContentItem } from '@distribute/shared/types';
import { ExtendedHorizontalRuleExt } from './extensions/HorizontalRule';
import {
  ToggleListExt,
  ToggleListItemContentExt,
  ToggleListItemExt,
} from './extensions/ToggleList';
import { TimelineExt, TimelineItemExt } from './extensions/Timeline';
import { CustomTaskListExt } from './extensions/TaskList';
import { ContactCardExt } from './extensions/ContactCard';
import { CustomLinkExt } from './extensions/Link';
import { CalloutExt } from './extensions/Callout';
import { SnippetBlockExt } from './extensions/SnippetBlock';
import { CustomTableExt } from './extensions/Table';
import { CustomTableCellExt } from './extensions/TableCell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { FilePreviewExt } from './extensions/FilePreview';
import { ImageExt } from './extensions/Image';
import { VideoExt } from './extensions/Video';
import { VideoRecordExt } from './extensions/VideoRecord';
import { ResizeableFigureExt } from './extensions/ResizeableFigure';
import { IframeExt } from './extensions/Iframe';
import { YoutubeExt } from './extensions/YoutubeEmbed';
import { VimeoExt } from './extensions/VimeoEmbed';

import { ColumnBlockExt, ColumnExt } from './extensions/MultipleColumns';
import { TodoListExt, TodoListItemExt } from './extensions/TodoList';
import { ButtonExt } from './extensions/Button';
import {
  EmbedContentBlockExt,
  EmbedContentExt,
} from './extensions/EmbedContent';
import { CodeBlockExt } from './extensions/CodeBlock';

/**
 * Ensures all attribute objects have an explicit 'default: undefined' property.
 *
 * This is necessary for correct attribute comparison between documents during diff operations.
 * Without explicit undefined defaults, object equality checks may produce incorrect results
 * when comparing attributes that implicitly vs explicitly have undefined defaults.
 *
 * @param attributes - Record of attribute key-value pairs to process
 * @returns A new record with all attributes having explicit undefined defaults
 */
const withUndefinedDefaults = (attributes: Record<string, Attribute>) => {
  return Object.entries(attributes).reduce<Record<string, Attribute>>(
    (result, [key, value]) => {
      result[key] = {
        ...value,
        default: undefined,
      };

      return result;
    },
    {}
  );
};

// Reusable helper function for boolean attributes
const createBooleanAttribute = (name: string) => {
  return {
    default: undefined,
    parseHTML: (element: HTMLElement) => {
      const value = element.getAttribute(name);

      if (value === 'true') return true;
      if (value === 'false') return false;

      return;
    },
    renderHTML: (attrs: Record<string, any>) => {
      if (typeof attrs[name] === 'boolean') {
        const bool = `${attrs[name]}`;
        return { [name]: bool };
      }

      return;
    },
  } as Attribute;
};

// Reusable helper function for serialized attributes
const createSerializedAttribute = (name: string) => {
  return {
    default: undefined,
    parseHTML: (element: HTMLElement) => {
      const value = element.getAttribute(name);

      if (value) {
        try {
          return JSON.parse(value);
        } catch (error) {
          console.error(`Error parsing JSON attribute ${name}:`, error);
          return;
        }
      }
      return;
    },
    renderHTML: (attrs: Record<string, any>) => {
      if (typeof attrs[name] === 'object') {
        return { [name]: JSON.stringify(attrs[name]) };
      }

      return;
    },
  } as Attribute;
};

const XmlParagraph = Paragraph.extend({
  parseHTML() {
    return [
      {
        tag: 'Paragraph',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'Paragraph',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlHardBreak = HardBreak.extend({
  parseHTML() {
    return [
      {
        tag: 'HardBreak',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'HardBreak',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
});

const XmlHeading = Heading.extend({
  parseHTML() {
    return [{ tag: 'Heading' }];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      `Heading`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        level: node.attrs.level,
      }),
      0,
    ];
  },
}).configure({ levels: [1, 2, 3] });

const XmlBold = Bold.extend({
  parseHTML() {
    return [
      {
        tag: 'Bold',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'Bold',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlItalic = Italic.extend({
  parseHTML() {
    return [
      {
        tag: 'Italic',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'Italic',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlStrike = Strike.extend({
  parseHTML() {
    return [
      {
        tag: 'Strike',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'Strike',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlUnderline = Underline.extend({
  parseHTML() {
    return [
      {
        tag: 'Underline',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'Underline',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlBulletList = BulletList.extend({
  parseHTML() {
    return [
      {
        tag: 'BulletList',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'BulletList',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlOrderedList = OrderedList.extend({
  parseHTML() {
    return [
      {
        tag: 'OrderedList',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'OrderedList',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlListItem = ListItem.extend({
  parseHTML() {
    return [
      {
        tag: 'ListItem',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'ListItem',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlTextStyle = TextStyle.extend({
  parseHTML() {
    return [
      {
        tag: 'TextStyle',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'TextStyle',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlHighlight = Highlight.extend({
  parseHTML() {
    return [{ tag: 'Highlight' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'Highlight',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlBlockquote = Blockquote.extend({
  parseHTML() {
    return [{ tag: 'Blockquote' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'Blockquote',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlExtendedHorizontalRule = ExtendedHorizontalRuleExt.extend({
  parseHTML() {
    return [{ tag: 'HorizontalRule' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'HorizontalRule',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
});

const XmlToggleList = ToggleListExt.extend({
  parseHTML() {
    return [{ tag: 'ToggleList' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'ToggleList',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlToggleListItem = ToggleListItemExt.extend({
  parseHTML() {
    return [{ tag: 'ToggleListItem' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'ToggleListItem',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlToggleListItemContent = ToggleListItemContentExt.extend({
  parseHTML() {
    return [{ tag: 'ToggleListItemContent' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['ToggleListItemContent', HTMLAttributes, 0];
  },
});

const XmlTimeline = TimelineExt.extend({
  parseHTML() {
    return [{ tag: 'Timeline' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['Timeline', HTMLAttributes, 0];
  },
});

const XmlTimelineItem = TimelineItemExt.extend({
  addAttributes() {
    const parentAttributes = this.parent?.() ?? {};

    return withUndefinedDefaults({
      ...parentAttributes,
      isDone: createBooleanAttribute('isDone'),
    });
  },

  parseHTML() {
    return [{ tag: 'TimelineItem' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['TimelineItem', HTMLAttributes, 0];
  },
});

const XmlCustomTaskList = CustomTaskListExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'CustomTaskList' }];
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['CustomTaskList', HTMLAttributes, `{{ ${node.attrs.id} }}`];
  },
});

const XmlContactCard = ContactCardExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'ContactCard' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'ContactCard',
      mergeAttributes(this.options?.HTMLAttributes, HTMLAttributes),
    ];
  },
});

const XmlCustomLink = CustomLinkExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'CustomLink' }];
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['CustomLink', HTMLAttributes, node.attrs.text];
  },
});

const XmlCallout = CalloutExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'Callout' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['Callout', HTMLAttributes, 0];
  },
});

const XmlSnippetBlock = SnippetBlockExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'SnippetBlock' }];
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['SnippetBlock', HTMLAttributes, `{{ ${node.attrs.id} }}`];
  },
});

const XmlCustomTable = CustomTableExt.extend({
  addAttributes() {
    return withUndefinedDefaults({
      ...this.parent?.(),
      selected: createBooleanAttribute('selected'),
    });
  },

  parseHTML() {
    return [{ tag: 'CustomTable' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'CustomTable',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlCustomTableCell = CustomTableCellExt.extend({
  addAttributes() {
    return withUndefinedDefaults({
      ...this.parent?.(),
      tableCellAlign: { default: undefined },
      tableCellVerticalAlign: { default: undefined },
    });
  },

  parseHTML() {
    return [{ tag: 'TableCell' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'TableCell',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlTableHeader = TableHeader.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'TableHeader' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'TableHeader',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlTableRow = TableRow.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'TableRow' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'TableRow',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlFilePreview = FilePreviewExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'FilePreview' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['FilePreview', HTMLAttributes, `${HTMLAttributes.fileName}`];
  },
});

const XmlImage = ImageExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [
      {
        tag: 'CustomImage',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['CustomImage', { ...HTMLAttributes }];
  },
});

const XmlVideo = VideoExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'CustomVideo' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['CustomVideo', HTMLAttributes];
  },
});

const XmlVideoRecord = VideoRecordExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'VideoRecord' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['VideoRecord', HTMLAttributes];
  },
});

const XmlResizeableFigure = ResizeableFigureExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'ResizeableFigure' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['ResizeableFigure', HTMLAttributes, 0];
  },
});

const XmlIframe = IframeExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'Iframe' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['Iframe', HTMLAttributes];
  },
});

const XmlYoutube = YoutubeExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'Youtube' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'Youtube',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
});

const XmlVimeo = VimeoExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'Vimeo' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'Vimeo',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
});

const XmlColumnBlock = ColumnBlockExt.extend({
  parseHTML() {
    return [{ tag: 'ColumnBlock' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['ColumnBlock', HTMLAttributes, 0];
  },
});

const XmlColumn = ColumnExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'Column' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['Column', HTMLAttributes, 0];
  },
});

const XmlTodoListItem = TodoListItemExt.extend({
  parseHTML() {
    return [{ tag: 'TodoListItem' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'TodoListItem',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlTodoList = TodoListExt.extend({
  parseHTML() {
    return [{ tag: 'TodoList' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'TodoList',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const XmlButton = ButtonExt.extend({
  addAttributes() {
    const parentAttributes = this.parent?.() ?? {};

    return withUndefinedDefaults({
      ...parentAttributes,
      edited: createBooleanAttribute('edited'),
      errors: createSerializedAttribute('errors'),
    });
  },

  parseHTML() {
    return [
      {
        tag: 'Button',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'Button',
      mergeAttributes(HTMLAttributes, {
        errors: JSON.stringify(HTMLAttributes.errors),
      }),
      0,
    ];
  },
});

const XmlEmbedContentBlock = EmbedContentBlockExt.extend({
  parseHTML() {
    return [{ tag: 'EmbedContentBlock' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['EmbedContentBlock', HTMLAttributes, 0];
  },
});

const XmlEmbedContent = EmbedContentExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'EmbedContent' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['EmbedContent', HTMLAttributes];
  },
});

const XmlCodeBlock = CodeBlockExt.extend({
  addAttributes() {
    return withUndefinedDefaults(this.parent?.() ?? {});
  },

  parseHTML() {
    return [{ tag: 'CodeBlock' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'CodeBlock',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

export const xmlExtensions = [
  Document,
  Text,
  Color,
  XmlParagraph,
  XmlHardBreak,
  XmlHeading.configure({ levels: [1, 2, 3] }),
  XmlBold,
  XmlItalic,
  XmlStrike,
  XmlUnderline,
  XmlBulletList,
  XmlOrderedList,
  XmlListItem,
  XmlTextStyle,
  XmlHighlight.configure({
    multicolor: true,
    HTMLAttributes: {
      class: 'highlighted',
    },
  }),
  XmlBlockquote,
  TextAlign.configure({
    types: ['heading', 'paragraph', 'listItem'],
  }),
  XmlExtendedHorizontalRule,
  XmlToggleList,
  XmlToggleListItem,
  XmlToggleListItemContent,
  XmlTimeline,
  XmlTimelineItem,
  XmlCustomTaskList,
  XmlContactCard,
  XmlCustomLink,
  XmlCallout,
  XmlSnippetBlock,
  XmlCustomTable,
  XmlCustomTableCell,
  XmlTableHeader,
  XmlTableRow,
  XmlFilePreview,
  XmlImage,
  XmlVideo,
  XmlVideoRecord,
  XmlResizeableFigure,
  XmlIframe,
  XmlYoutube.configure({ controls: true, allowFullscreen: true }),
  XmlVimeo.configure({ controls: true, allowFullscreen: true }),
  XmlColumnBlock,
  XmlColumn,
  XmlTodoListItem.configure({
    nested: false,
  }),
  XmlTodoList,
  XmlButton,
  XmlEmbedContentBlock,
  XmlEmbedContent,
  XmlCodeBlock,
];

type GenerateXmlJSXProps = {
  pageTitle: string;
  tabs: DocumentContentItem[];
  isBranded: boolean;
  currentTabId: number;
  userName: string;
};

export const generateXmlJSX = ({
  pageTitle,
  tabs,
  currentTabId,
  isBranded,
  userName,
}: GenerateXmlJSXProps) => {
  const pageContent = tabs.map((content) => {
    return `
    <Tab id='${content.id}' name='${content.name}'>
      ${generateHTML(content.contentJson, xmlExtensions)}
    </Tab>
  `;
  });
  const metadata = `
  <Metadata>
    <CurrentTabId>${currentTabId}</CurrentTabId>
    <IsBranded>${isBranded}</IsBranded>
    <UserName>${userName}</UserName>
  </Metadata>
  `;
  const pageWrapped = `
  <PageTitle>${pageTitle}</PageTitle>
  ${pageContent.join('')}
  ${metadata}
  `;
  return pageWrapped;
};
