import { JSONContent } from '@tiptap/core';
import { hasOperation } from './utils';

function restructureTextContent(node: JSONContent) {
  const deletedContent: JSONContent[] = [];
  const insertedContent: JSONContent[] = [];

  if (!Array.isArray(node.content)) {
    return [];
  }

  // Process each child in a single loop
  for (const child of node.content) {
    // Process for deletedContent
    if (hasOperation(child, 'delete')) {
      deletedContent.push(child);
    } else if (!hasOperation(child, 'insert')) {
      deletedContent.push({
        ...child,
        marks: [
          ...(child.marks ?? []),
          { type: 'diff', attrs: { op: 'delete' } },
        ],
      });
    }
    // Process for insertedContent
    if (hasOperation(child, 'insert')) {
      insertedContent.push(child);
    } else if (!hasOperation(child, 'delete')) {
      insertedContent.push({
        ...child,
        marks: [
          ...(child.marks ?? []),
          { type: 'diff', attrs: { op: 'insert' } },
        ],
      });
    }
  }
  return [...deletedContent, ...insertedContent];
}

const MAX_WORD_THRESHOLD = 3;

export function consolidateTextChanges(doc: JSONContent, threshold: number) {
  function traverse(node: JSONContent) {
    // Skip non-content nodes or leaf nodes
    if (!node.content || !Array.isArray(node.content)) {
      return;
    }

    // Check if all children are text nodes
    const allTextNodes = node.content.every((child) => child.type === 'text');

    // Only process if all children are text nodes
    if (allTextNodes && node.content.length > 1) {
      const insertNodes = node.content.filter((child) =>
        hasOperation(child, 'insert')
      );
      const deleteNodes = node.content.filter((child) =>
        hasOperation(child, 'delete')
      );

      const insertOpTotal = insertNodes.length; // Number of insertions
      const deleteOpTotal = deleteNodes.length; // Number of deletions
      const allOpTotal = node.content.length; // Total number of operations

      const insertOpCharTotal = insertNodes.reduce(
        (acc, value) => acc + (value?.text?.length ?? 0),
        0
      ); // Sum of characters in insertions
      const deleteOpCharTotal = deleteNodes.reduce(
        (acc, value) => acc + (value?.text?.length ?? 0),
        0
      ); // Sum of characters in deletions
      const allOpCharTotal = node.content.reduce(
        (acc, value) => acc + (value?.text?.length ?? 0),
        0
      ); // Total sum of characters

      if (insertOpTotal <= MAX_WORD_THRESHOLD && deleteOpTotal === 0) {
        // Do nothing
      } else if (deleteOpTotal <= MAX_WORD_THRESHOLD && insertOpTotal === 0) {
        // Do nothing
      } else if (
        (insertOpCharTotal + deleteOpCharTotal) / allOpCharTotal >= threshold ||
        (insertOpTotal + deleteOpTotal) / allOpTotal >= threshold
      ) {
        node.content = restructureTextContent(node);
      }
    } else {
      for (const child of node.content) {
        traverse(child);
      }
    }
  }

  traverse(doc);
  return doc;
}
