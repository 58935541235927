import { UserPermissionToPageRole } from '@distribute/shared/types';
import { FC, useCallback, useMemo, useState } from 'react';
import { pagesModel, usePagePermissions } from '../../pages';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Dropdown } from '../../../../src/shared/ui';
import { teamsModel } from '../../teams';

type Props = {
  email: string;
  onClose: () => void;
};
export const SharePageModalContent: FC<Props> = ({ email, onClose }) => {
  const dispatch = useDispatch();
  const page = useSelector(pagesModel.selectors.selectCurrentPageWithError);

  const currentTeamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );

  const userPermission = page.userPermissionToPages.find(
    (u) => u.externalUserEmail === email || u.user?.email === email
  );
  const pagePermissions = usePagePermissions(page);

  const [role, setRole] = useState(UserPermissionToPageRole.EDITOR);

  const handleUpdateUserPermission = useCallback(
    (role: UserPermissionToPageRole, userPermissionId: number) => {
      dispatch(
        pagesModel.actions.updateUserPermission({
          role,
          userPermissionId,
          pageId: page.id,
        })
      );
    },
    [dispatch, page.id]
  );

  const handleCreateUserPermission = useCallback(
    (role: UserPermissionToPageRole) => {
      dispatch(
        pagesModel.actions.createUserPermission({
          pageId: page.id,
          role,
          userEmails: [email],
          cb: () => {
            return;
          },
        })
      );
    },
    [dispatch, page.id, email]
  );

  const handleSharePage = useCallback(() => {
    if (userPermission) {
      handleUpdateUserPermission(role, userPermission.id);
      onClose();
      return;
    }

    handleCreateUserPermission(role);
    onClose();
  }, [
    userPermission,
    role,
    handleCreateUserPermission,
    handleUpdateUserPermission,
    onClose,
  ]);

  const isUserCanBeGrantedOwnerRights = useMemo(() => {
    return currentTeamMembers.some((m) => m.user?.email === email);
  }, [currentTeamMembers, email]);

  const options = useMemo(
    () =>
      [
        {
          id: UserPermissionToPageRole.OWNER,
          label: (
            <div>
              <p className="text-sm font-medium text-gray-700 mb-0.5">
                Owner (full access)
              </p>
              <p className="text-xs text-gray-500">
                Can edit, share, manage, view analytics, etc.
              </p>
            </div>
          ),
          headerLabel: 'Owner',
          isShow:
            pagePermissions.isCanTransferOwnership &&
            isUserCanBeGrantedOwnerRights,
          onClick: () => {
            setRole(UserPermissionToPageRole.OWNER);
          },
        },
        {
          id: UserPermissionToPageRole.EDITOR,
          label: (
            <div>
              <p className="text-sm font-medium text-gray-700 mb-0.5">Edit</p>
              <p className="text-xs text-gray-500">
                Can view, share, edit, but not manage.
              </p>
            </div>
          ),
          headerLabel: 'Can edit',
          isShow: true,
          onClick: () => {
            setRole(UserPermissionToPageRole.EDITOR);
          },
        },
        {
          id: UserPermissionToPageRole.VIEWER,
          label: (
            <div>
              <p className="text-sm font-medium text-gray-700 mb-0.5">View</p>
              <p className="text-xs text-gray-500">Can view only</p>
            </div>
          ),
          headerLabel: 'Can view',
          isShow: true,
          onClick: () => {
            setRole(UserPermissionToPageRole.VIEWER);
          },
        },
      ].filter((i) => i.isShow),
    [pagePermissions.isCanTransferOwnership, isUserCanBeGrantedOwnerRights]
  );

  return (
    <div>
      <p className="mb-4 text-sm text-gray-700">
        Assignee will only have access to the public page. Would you like to
        grant this user editor permissions?
      </p>

      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <Avatar
            displayName={userPermission?.user?.displayName || email}
            src={userPermission?.user?.photoUrl}
            size="md"
            isUserAvatar={!!userPermission?.user?.photoUrl}
            className="border-light-7 border-[0.5px] !bg-base-white"
          />

          <div className="text-sm text-gray-700 font-medium mb-0.5">
            {userPermission?.user && <p>{userPermission?.user.displayName}</p>}
            <p className="text-xs text-gray-500">{email}</p>
          </div>
        </div>

        <div>
          <Dropdown
            isModalMode={false}
            listStyles="shadow-lg w-90"
            items={options}
            currentItemId={role}
            triggerStyles="!w-auto !h-10 shrink-0 border-0 pr-0 !gap-2 !text-sm !font-semibold !text-gray-600"
          />
        </div>
      </div>

      <p className="mb-8 text-sm text-gray-700">
        This page will be shared according to permissions.
      </p>

      <div className="flex items-center justify-between gap-3">
        <Button
          type="button"
          variant="text"
          color="secondary"
          onClick={onClose}
          fullWidth
        >
          Don't share
        </Button>

        <Button
          type="button"
          color="primary"
          variant="text"
          onClick={handleSharePage}
          fullWidth
        >
          Share
        </Button>
      </div>
    </div>
  );
};
