import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { callRecordingsModel } from '../model';

import { Button, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

import { MeetingEventCard } from './MeetingEventCard';

import { groupByDate } from '../lib';

const INITIAL_VISIBLE_DAYS = 2;

export const MeetingEvents = () => {
  const events = useSelector(callRecordingsModel.selectors.selectMeetingEvents);

  const [isLoadedMore, setIsLoadedMore] = useState(false);

  const groupedEventsByDate = useMemo(() => groupByDate(events), [events]);

  const shouldLoadMore =
    Object.keys(groupedEventsByDate).length > INITIAL_VISIBLE_DAYS &&
    !isLoadedMore;
  const shouldShowLess =
    Object.keys(groupedEventsByDate).length > INITIAL_VISIBLE_DAYS &&
    isLoadedMore;

  const takeDays = shouldLoadMore
    ? INITIAL_VISIBLE_DAYS
    : Object.keys(groupedEventsByDate).length;

  return (
    <div className="flex flex-col gap-6 relative overflow-y-auto">
      <div className="flex flex-col gap-6">
        {Object.keys(groupedEventsByDate)
          .slice(0, takeDays)
          .map((date) => (
            <div key={date} className="text-gray-500">
              <p className="text-xs font-bold uppercase mb-3">{date}</p>
              <div className="flex flex-col gap-1.5">
                {groupedEventsByDate[date].map((event) => (
                  <MeetingEventCard key={event.id} event={event} />
                ))}
              </div>
            </div>
          ))}
      </div>

      {shouldLoadMore && (
        <Button
          className="w-full flex gap-1.5"
          variant="text"
          color="secondary"
          onClick={() => setIsLoadedMore(true)}
        >
          Show next 7 days
          <Icon className="-rotate-90" glyph={IconMap.BackArrow} />
        </Button>
      )}
      {shouldShowLess && (
        <Button
          className="w-full flex gap-1.5 sticky bottom-0"
          variant="text"
          color="secondary"
          onClick={() => setIsLoadedMore(false)}
        >
          Show less
          <Icon className="rotate-90" glyph={IconMap.BackArrow} />
        </Button>
      )}
    </div>
  );
};
