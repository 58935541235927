import { select } from 'redux-saga/effects';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';

export function* connectToMsCalendar() {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  // TODO: implement connection to microsoft calendar
}

export function* disconnectMsCalendar() {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  // TODO: implement disconnection from microsoft calendar
}
