import { conversionKitModel } from '../../../features/conversion-kit';
import { gatedContentModel } from '../../../features/gated-content-block';
import React from 'react';
import { useSelector } from 'react-redux';
import { GatedContentType } from '../../../features/editor-left-sidebar/ui/components/conversions/gated-content';
import classNames from 'classnames';

type Props = {
  children: (JSX.Element | false)[] | JSX.Element;
};

export const NewEditorsPageWrapper: React.FC<Props> = ({ children }) => {
  const isGatedContentShows = useSelector(
    gatedContentModel.selectors.selectGatedContentIsModalOpen
  );

  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContent
  ) as GatedContentType;
  const gatedContentEdited = useSelector(
    conversionKitModel.selectors.selectGatedContentEdited
  );

  const isRelativePosition =
    (gatedContentEdited || gatedContent) && isGatedContentShows;
  return (
    <div
      className={classNames('transition-all z-0 w-full h-full', {
        relative: isRelativePosition,
      })}
    >
      {children}
    </div>
  );
};
