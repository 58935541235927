import { PropsWithChildren } from 'react';
import { Button, Icon, Modal } from '../../../shared/ui';
import { cn } from '@distribute/frontend/utils';
import { IconMap } from '../../../shared/sprite';
import { PageCreationFlowStepsEnum } from '../config/types';
import { useDispatch, useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../model';

type Props = {
  currentStep: PageCreationFlowStepsEnum;
};

export const PageCreationFlowModal = ({
  children,
  currentStep,
}: PropsWithChildren<Props>) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(
    pageCreationFlowModel.selectors.selectPageCreationFlowModalOpen
  );

  const closeModal = () => {
    dispatch(pageCreationFlowModel.actions.closePageCreationFlow());
  };

  return (
    <Modal
      isOpen={isOpen}
      isShowCancelButton={false}
      clearPadding
      onClose={closeModal}
      className={cn(
        'w-320 h-[min(800px,100vh-40px)] flex flex-col md:w-182 md:min-w-182 relative'
      )}
    >
      <div
        className={cn('absolute z-10 top-6 right-6', {
          'top-4 right-4': currentStep === PageCreationFlowStepsEnum.TEMPLATES,
          'top-4 right-2':
            currentStep === PageCreationFlowStepsEnum.TEMPLATES_PREVIEW,
        })}
      >
        <Button
          variant="icon"
          color="transparent"
          onClick={closeModal}
          size="md"
        >
          <Icon
            glyph={IconMap.XClose}
            className="text-gray-700 shrink-0"
            width={20}
            onClick={closeModal}
          />
        </Button>
      </div>
      {children}
    </Modal>
  );
};
