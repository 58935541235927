import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { tasksModel } from '..';
import { tasksApi } from '../../../../../src/shared/api';
import { TasklistWithTasks } from '@distribute/shared/types';

export function* createTasklist({
  payload: { successCallback, documentContentId },
}: ReturnType<typeof tasksModel.actions.createTasklist>) {
  const tasklists: Record<string, TasklistWithTasks> = yield select(
    tasksModel.selectors.selectTasklists
  );

  try {
    const tasklist: TasklistWithTasks = yield call(
      tasksApi.createTasklist,
      documentContentId
    );

    yield put(
      tasksModel.actions.setTasklists({ ...tasklists, [tasklist.id]: tasklist })
    );

    successCallback(tasklist.id);
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to create tasklist')
      )
    );
  }
}
