import { api } from './instance';
import {
  GetCallRecorderSettingsResponse,
  GetTeamCallSettingsResponse,
  GetUserCallSettingsResponse,
  ConnectOAuthGoogleCalendarResponse,
  GetMeetingEventResponse,
  UpdateTeamCallSettingsRequest,
  UpdateUserCallSettingsRequest,
  ConnectOAuthZoomResponse,
  ToggleEventRequest,
  JoinManuallyCallRequest,
} from '@distribute/shared/api-types/call-recorder';

export const callRecordingsApi = {
  async getSettings(query: { teamId: number }) {
    const { data: responseData } = await api.get('call-recorder/settings', {
      params: query,
    });
    return responseData as GetCallRecorderSettingsResponse;
  },

  async updateTeamSettings(
    data: UpdateTeamCallSettingsRequest,
    query: { teamId: number }
  ) {
    const { data: responseData } = await api.patch(
      'call-recorder/team-settings',
      data,
      { params: query }
    );
    return responseData as GetTeamCallSettingsResponse;
  },

  async updateUserSettings(
    data: UpdateUserCallSettingsRequest,
    query: { teamId: number }
  ) {
    const { data: responseData } = await api.patch(
      'call-recorder/user-settings',
      data,
      { params: query }
    );
    return responseData as GetUserCallSettingsResponse;
  },

  async connectToGoogleCalendar(query: {
    teamId: number;
    redirectPath: string;
  }) {
    const { data: responseData } = await api.get(
      'call-recorder/integrations/google-calendar/connect',
      { params: query, withCredentials: true }
    );
    return responseData as ConnectOAuthGoogleCalendarResponse;
  },

  async disconnectGoogleCalendar(query: { teamId: number }) {
    const { data: responseData } = await api.delete(
      'call-recorder/integrations/google-calendar',
      { params: query }
    );
    return responseData;
  },

  async connectZoom(query: { redirectPath: string }) {
    const { data: responseData } = await api.get(
      'call-recorder/integrations/zoom/connect',
      { params: query, withCredentials: true }
    );
    return responseData as ConnectOAuthZoomResponse;
  },

  async disconnectZoom() {
    const { data: responseData } = await api.delete(
      'call-recorder/integrations/zoom'
    );
    return responseData;
  },

  async getEvents(query: { teamId: number }) {
    const { data: responseData } = await api.get('call-recorder/events', {
      params: query,
    });
    return responseData as GetMeetingEventResponse[];
  },

  async toggleCalendarEventRecording(
    id: string,
    data: ToggleEventRequest,
    query: { teamId: number }
  ) {
    const { data: responseData } = await api.post(
      `call-recorder/events/${id}/toggle-event`,
      data,
      { params: query }
    );
    return responseData as GetMeetingEventResponse;
  },

  async toggleCalendarEventVisibility(
    id: string,
    data: ToggleEventRequest,
    query: { teamId: number }
  ) {
    const { data: responseData } = await api.post(
      `call-recorder/events/${id}/toggle-private`,
      data,
      { params: query }
    );
    return responseData as GetMeetingEventResponse;
  },

  async addManuallyJoinCall(
    data: JoinManuallyCallRequest,
    query: { teamId: number }
  ) {
    const { data: responseData } = await api.post('call-recorder/join', data, {
      params: query,
    });

    return responseData;
  },
};
