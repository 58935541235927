import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChangeLogoAction,
  DeleteLogoAction,
  UpdateBrandInfoAction,
} from './types';
import { globalActions } from '../../../app/model/actions';

type State = {
  isUpdatingBrandInfo: boolean;
  isUploadLogoLoading: boolean;
  uploadingLogoProgress: number;
  isRemoveLogoLoading: boolean;
};
const initialState: State = {
  isUpdatingBrandInfo: false,
  isUploadLogoLoading: false,
  uploadingLogoProgress: 0,
  isRemoveLogoLoading: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'teamBrandSettings',
  initialState,
  reducers: {
    setIsUpdatingBrandInfo: (
      state,
      { payload: isUpdatingBrandInfo }: PayloadAction<boolean>
    ) => ({ ...state, isUpdatingBrandInfo }),
    setIsUploadLogoLoading: (
      state,
      { payload: isUploadLogoLoading }: PayloadAction<boolean>
    ) => ({ ...state, isUploadLogoLoading }),

    setUploadLogoProgress: (
      state,
      { payload: uploadingLogoProgress }: PayloadAction<number>
    ) => ({ ...state, uploadingLogoProgress }),

    setIsRemoveLogoLoading: (
      state,
      { payload: isRemoveLogoLoading }: PayloadAction<boolean>
    ) => ({ ...state, isRemoveLogoLoading }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  changeLogo: createAction<ChangeLogoAction>('teamBrandSettings/changeLogo'),
  cancelUploadingFile: createAction('teamBrandSettings/cancelUploadingFile'),
  deleteLogo: createAction<DeleteLogoAction>('teamBrandSettings/deleteLogo'),
  updateTeamBrandInfo: createAction<UpdateBrandInfoAction>(
    'teamBrandSettings/updateTeamBrandInfo'
  ),
};
