import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionOptions,
  LineActionMenuItem,
} from '../extensions/LineActionMenu/lib/types';
import type { DocumentContentItem } from '@distribute/shared/types';
import { JSONContentFactory } from '@distribute/shared/tiptap/json-content';
import { Editor } from '@tiptap/react';
import { Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { pagesModel } from '../../../features/pages';
import type { Page } from '@distribute/shared/types';
import { SHARED_ACTIONS } from '../extensions/LineActionMenu/lib/lineActionMenuGroups';

export const useTransformTabsToMenu = (
  tabs: DocumentContentItem[],
  currentTab: DocumentContentItem
): LineActionMenuItem[] => {
  const dispatch = useDispatch();
  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPage
  ) as Page;

  return useMemo(() => {
    if (!currentTab) return [];

    return [
      {
        name: 'Move to',
        key: 'moveTo',
        icon: <Icon glyph={IconMap.Copy01} width={16} height={16} />,
        isHiddenForEmptyNode: true,
        subItems: [
          ...tabs
            .filter((tab) => tab.id !== currentTab.id)
            .map((tab) => ({
              name: tab.name,
              key: String(tab.id),
              action: (function (tab) {
                return (editor: Editor, options: ActionOptions) => {
                  const content = options.node.toJSON();
                  const { content: tabContent = [] } = tab.contentJson ?? {};
                  const contentJson = JSONContentFactory.createDoc([
                    ...tabContent,
                    content,
                  ]);

                  dispatch(
                    pagesModel.actions.changePageContent({
                      contentItemId: tab.id,
                      pageId: currentPage.id,
                      contentJson,
                    })
                  );
                  SHARED_ACTIONS.delete(editor, options);
                  dispatch(
                    pagesModel.actions.setContentNavigation({
                      tab,
                      direction: 'forward',
                    })
                  );
                };
              })(tab),
            })),
          {
            name: 'New tab',
            key: 'newTab',
            isSeparatedFromTop: true,
            action: (editor: Editor, options: ActionOptions) => {
              const content = options.node.toJSON();
              const data = JSONContentFactory.createDoc([content]);
              dispatch(
                pagesModel.actions.createPageContentItem({
                  pageId: currentPage.id,
                  data: {
                    contentJson: data,
                  },
                  callback: (err?: unknown) => {
                    if (!err) {
                      SHARED_ACTIONS.delete(editor, options);
                      dispatch(
                        pagesModel.actions.setContentNavigation({
                          tab: {
                            ...currentTab,
                            name: 'New tab',
                          },
                          direction: 'back',
                        })
                      );
                    }
                  },
                })
              );
            },
          },
        ],
      },
    ];
  }, [dispatch, tabs, currentTab, currentPage?.id]);
};
