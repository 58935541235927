import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClearbitCompanyInfo } from '../../../shared/api';
import { SearchCompaniesAction } from './types';

type State = {
  companies: ClearbitCompanyInfo[];
  isLoading: boolean;
};

const initialState: State = {
  companies: [],
  isLoading: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'clearbit',
  initialState,
  reducers: {
    setCompanies: (
      state,
      { payload: companies }: PayloadAction<ClearbitCompanyInfo[]>
    ) => ({ ...state, companies }),
    setIsLoading: (state, { payload: isLoading }: PayloadAction<boolean>) => ({
      ...state,
      isLoading,
    }),
  },
});

export const actions = {
  ...reducerActions,
  searchCompanies: createAction<SearchCompaniesAction>(
    'clearbit/searchCompanies'
  ),
};
