import { call, put, select } from 'redux-saga/effects';
import { pagesModel } from '..';
import { pagesApi } from '../../../../shared/api/';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { Folder, Page } from '@distribute/shared/types';
import { foldersModel } from '../../../folders';
import { redirect } from '../../../../entities/history';
import { getFolder } from './getFolder';
import { handleError } from '../../../../utils/handleError';

export function* movePageToFolder({
  payload: { folderId, pageId, callback, folderName },
}: ReturnType<typeof pagesModel.actions.movePageToFolder>) {
  try {
    yield put(actions.setMovePageToFolderIsLoading(true));

    const currentFolderId: string = yield getFolder(folderId, folderName);

    yield pagesApi.movePageToFolder(pageId, { folderId: currentFolderId });

    const pages: Page[] = yield select(pagesModel.selectors.selectPages);
    const changedPages = pages.map((i) => {
      if (i.id === pageId) {
        return { ...i, folderId: currentFolderId };
      }
      return i;
    });

    yield put(pagesModel.actions.setPages(changedPages));

    const page: Page = yield select(pagesModel.selectors.selectCurrentPage);
    const newFolder: Folder = yield select(
      foldersModel.selectors.selectCurrentFolder
    );
    if (!page) {
      yield call(redirect.toWorkspaceFolder, {
        sequenceNumber: newFolder.sequenceNumber,
      });
    }
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'Page has been moved to folder successfully'
        )
      )
    );
    callback();
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', handleError(e, 'Page was not moved!'))
      )
    );
  } finally {
    yield put(actions.setMovePageToFolderIsLoading(false));
  }
}
