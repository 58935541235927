import objectHash from 'hash-sum';
import { Operation, Token } from './types';

/**
 * Computes the diff operations between two arrays of tokens using the Myers diff algorithm.
 * Tokens are compared using a hash (via objectHash) to check equality.
 *
 * @param {Token[]} tokensA_ - Array of tokens from the original document.
 * @param {Token[]} tokensB_ - Array of tokens from the new document.
 * @returns {Operation[]} Array of operations: each is { op: 'equal'|'delete'|'insert', token }
 */
function computeMyersDiff(tokensA_: Token[], tokensB_: Token[]): Operation[] {
  const tokensA = tokensA_.slice();
  const tokensB = tokensB_.slice();
  const N = tokensA.length;
  const M = tokensB.length;
  const hashA = tokensA.map((token) => objectHash(token));
  const hashB = tokensB.map((token) => objectHash(token));
  const max = N + M;
  const offset = max; // so that k=0 is at index `offset`

  // Initialize V array for k in [-max, max] with -Infinity.
  let V = new Array(2 * max + 1).fill(-Infinity);
  V[offset] = 0; // starting at k = 0
  const trace: number[][] = [];

  for (let d = 0; d <= max; d++) {
    // Make a copy of V for this d.
    const Vd = V.slice();
    for (let k = -d; k <= d; k += 2) {
      const idx = k + offset;
      let x: number;
      // Special-case d === 0.
      if (d === 0) {
        x = 0;
      } else if (k === -d) {
        // Must come from the k+1 (down move)
        x = V[idx + 1];
      } else if (k === d) {
        // Must come from the k-1 (right move)
        x = V[idx - 1] + 1;
      } else {
        // Choose the greater of the two possibilities.
        const left = V[idx - 1] + 1;
        const down = V[idx + 1];
        x = left > down ? left : down;
      }
      let y = x - k;
      // Follow matching (snake) along diagonal.
      while (x < N && y < M && hashA[x] === hashB[y]) {
        x++;
        y++;
      }
      Vd[idx] = x;
      // Check if we've reached the end.
      if (x >= N && y >= M) {
        trace.push(Vd.slice());
        return backtrack(trace, tokensA, tokensB, offset, hashA, hashB);
      }
    }
    trace.push(Vd.slice());
    V = Vd;
  }
  throw new Error('Diff not found');
}

/**
 * Backtracks through the trace to build the edit script.
 *
 * @param {number[][]} trace - Array of V arrays computed during the forward phase.
 * @param {Token[]} tokensA - Original token array.
 * @param {Token[]} tokensB - New token array.
 * @param {number} offset - The offset used to map k to array indices.
 * @param {string[]} hashA - Precomputed hashes for tokensA.
 * @param {string[]} hashB - Precomputed hashes for tokensB.
 * @returns {Operation[]} Array of operations.
 */
function backtrack(
  trace: number[][],
  tokensA: Token[],
  tokensB: Token[],
  offset: number,
  hashA: string[],
  hashB: string[]
): Operation[] {
  let x = tokensA.length;
  let y = tokensB.length;
  const script: Operation[] = [];
  for (let d = trace.length - 1; d >= 1; d--) {
    const V = trace[d];
    const k = x - y;
    const idx = k + offset;
    const Vprev = trace[d - 1];
    let prevK: number;
    let op: 'insert' | 'delete';

    // Decide which predecessor was used.
    if (k === -d || (k !== d && Vprev[idx - 1] < Vprev[idx + 1])) {
      prevK = k + 1;
      op = 'insert';
    } else {
      prevK = k - 1;
      op = 'delete';
    }

    const prevIdx = prevK + offset;
    const prevX = Vprev[prevIdx];
    const prevY = prevX - prevK;

    // Walk back along the matching snake.
    while (x > prevX && y > prevY) {
      script.push({ op: 'equal', token: tokensA[x - 1] });
      x--;
      y--;
    }

    // Record the insertion or deletion.
    if (d > 0) {
      if (op === 'insert') {
        script.push({ op: 'insert', token: tokensB[y - 1] });
        y--;
      } else {
        script.push({ op: 'delete', token: tokensA[x - 1] });
        x--;
      }
    }
  }

  // Process any remaining equal tokens at the start.
  while (x > 0 && y > 0 && hashA[x - 1] === hashB[y - 1]) {
    script.push({ op: 'equal', token: tokensA[x - 1] });
    x--;
    y--;
  }

  script.reverse();
  return script;
}

const computeLCS = computeMyersDiff;

export { computeLCS };
