import { SLASH_MENU_GROUPS } from '../../../../../pages/editor/lib/commands';

export const getSlashMenuWithoutTasks = () => {
  const filteredCommands = [...SLASH_MENU_GROUPS];

  const group = SLASH_MENU_GROUPS.find((group) =>
    group.items.some((c) => c.name === 'Tasks')
  );

  if (group) {
    const index = SLASH_MENU_GROUPS.indexOf(group);
    const groupFiltered = {
      ...group,
      items: group.items.filter((i) => i.name !== 'Tasks'),
    };

    filteredCommands.splice(index, 1, groupFiltered);
  }

  return filteredCommands;
};
