import { FC } from 'react';

import cn from 'classnames';

import { Player } from '@distribute/frontend/ui/player';
import { useWindowWidth } from '@distribute/frontend/utils';

import { VideoRecordDetailType } from '@distribute/shared/api-types/video-record';

import { checkIsRecordInProgress } from '../../lib';
import { RecordInProgressStatus } from '../recordings/RecordUploadingStatus';

type Props = {
  url: string;
  posterUrl: string;
  status: VideoRecordDetailType['status'];
  statusConvert: VideoRecordDetailType['statusConvert'];
  aspectRatio: string;
};

export const RecordPlayer: FC<Props> = ({
  url,
  posterUrl,
  status,
  statusConvert,
  aspectRatio,
}) => {
  const { isMobile } = useWindowWidth();
  const isRecordUploading = checkIsRecordInProgress({ status, statusConvert });

  return (
    <div
      style={{ aspectRatio }}
      className={cn('overflow-hidden', {
        'rounded-lg border border-base-black/[0.08]': !isMobile,
      })}
    >
      {isRecordUploading ? (
        <RecordInProgressStatus
          status={status}
          statusConvert={statusConvert}
          size="md"
        />
      ) : (
        <Player
          url={url}
          posterUrl={posterUrl}
          aspectRatio={aspectRatio}
          hotKeys
        />
      )}
    </div>
  );
};
