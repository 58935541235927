import { BubbleMenu } from '@tiptap/react';
import {
  BubbleMenuDivider,
  BubbleMenuIconButton,
  BubbleMenuToolbar,
  useTiptapEditor,
} from '../../../../entities/tiptap-editor';
import { IconMap } from '../../../../shared/sprite';

type Props = {
  disableTextStyling?: boolean;
};

export const TiptapTextBubbleMenu: React.FC<Props> = ({
  disableTextStyling,
}) => {
  const { editor } = useTiptapEditor();

  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu
      tippyOptions={{
        duration: 200,
        placement: 'top-start',
        interactive: true,
        maxWidth: 'none',
      }}
      editor={editor}
      shouldShow={() =>
        editor.isEditable &&
        !editor.state.selection.empty &&
        editor.state.selection.$anchor.node().isTextblock
      }
      updateDelay={0}
    >
      <BubbleMenuToolbar>
        {!disableTextStyling && (
          <BubbleMenuIconButton
            active={editor.isActive('bold')}
            iconName={IconMap.Bold}
            onClick={() => editor.chain().focus().toggleBold().run()}
            tooltip="Bold"
            size="sm"
          />
        )}
        {!disableTextStyling && (
          <BubbleMenuIconButton
            active={editor.isActive('italic')}
            iconName={IconMap.Italic}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            tooltip="Italic"
            size="sm"
          />
        )}
        {!disableTextStyling && (
          <BubbleMenuIconButton
            active={editor.isActive('underline')}
            iconName={IconMap.Underline}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            tooltip="Underline"
            size="sm"
          />
        )}
        {!disableTextStyling && (
          <BubbleMenuIconButton
            active={editor.isActive('strike')}
            iconName={IconMap.Strikethrough}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            tooltip="Strikethrough"
            size="sm"
          />
        )}
        {!disableTextStyling && <BubbleMenuDivider />}
        <BubbleMenuIconButton
          active={editor.isActive('customLink')}
          iconName={IconMap.Link}
          onClick={() => {
            if (editor.isActive('customLink')) {
              return editor.chain().unsetCustomLink().run();
            }
            return editor.chain().setCustomLink().focus().run();
          }}
          tooltip="Link"
          size="sm"
        />
      </BubbleMenuToolbar>
    </BubbleMenu>
  );
};
