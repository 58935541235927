import { Editor } from '@tiptap/react';
import { useTiptapEditor } from '../../../../../../entities/tiptap-editor';
import { FC, useEffect, useMemo } from 'react';
import { ContactCardItemNodeType } from '../../../../../../entities/tiptap-editor/extensions/ContactCard/ContactCard.renderer';
import { ContactCardAttrs } from '@distribute/shared/generate-html';

import Header from './Header';
import { useForm } from 'react-hook-form';

import UserInformation from './UserInformation';
import Context from './context';
import Description from './Description';
import Contacts from './Contacts';
import { editorLeftSidebarModel } from '../../../../model';
import { useDispatch } from 'react-redux';
function getContactCardNode(editor: Editor | null): {
  node: ContactCardItemNodeType | null;
  pos: number;
} {
  if (!editor) {
    throw new Error('No editor found');
  }

  const { from, to } = editor.state.selection;
  let node = null;
  let pos = 0;

  editor.state.doc.nodesBetween(from, to, (n, position) => {
    if (n.type.name === 'contactCard') {
      node = n;
      pos = position;
    }
  });

  return { node, pos };
}

const ContactCardEditor: FC = () => {
  const { editor } = useTiptapEditor();
  const dispatch = useDispatch();
  const { node, pos } = useMemo(() => getContactCardNode(editor), [editor]);
  const form = useForm<ContactCardAttrs>({
    defaultValues: { ...node?.attrs },
  });

  const formValues = form.watch();

  useEffect(() => {
    dispatch(
      editorLeftSidebarModel.actions.setContactCardEditedData({
        contactCardEditedData: formValues,
        pos,
      })
    );

    return () => {
      dispatch(editorLeftSidebarModel.actions.setContactCardEditedData(null));
    };
  }, [formValues, dispatch, pos]);

  if (!node) {
    return null;
  }

  return (
    <Context.Provider value={{ form }}>
      <div className="h-full overflow-none flex flex-col justify-between">
        <Header />
        <div className="py-6 flex flex-col gap-y-6 flex-1 overflow-scroll">
          <UserInformation />
          <Description />
          <Contacts />
        </div>
      </div>
    </Context.Provider>
  );
};

export default ContactCardEditor;
