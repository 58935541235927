import React from 'react';
import { Modal, Button } from '../../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { callRecordingSettingsModel } from '../../model';

export const EmailMismatchModal = () => {
  const dispatch = useDispatch();
  const isMismatchModalOpen = useSelector(
    callRecordingSettingsModel.selectors.selectIsMismatchModalOpen
  );

  const handleMismatchModalClose = () => {
    dispatch(callRecordingSettingsModel.actions.setIsMismatchModalOpen(false));
  };

  const handleConnectAgain = () => {
    dispatch(callRecordingSettingsModel.actions.tryToReconnect());
  };

  return (
    <Modal
      isOpen={isMismatchModalOpen}
      onClose={handleMismatchModalClose}
      title={<h2>Email Mismatch Detected</h2>}
      actionButton={
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={handleConnectAgain}
        >
          Try again
        </Button>
      }
    >
      <p className="text-sm text-gray-600">
        It looks like the email you’re trying to authorize for calendar access
        doesn’t match the email on your Distribute account. To connect
        successfully, please use the same email address associated with your
        Distribute account.
      </p>
    </Modal>
  );
};
