import React, { useState } from 'react';
import cn from 'classnames';
import { Modal, Button, Icon } from '../../../shared/ui';
import { SelectPageForm } from './SelectPageForm';
import { SelectFolderForm } from './SelectFolderForm';
import { IconMap } from '../../../shared/sprite';

export type ChooseFolderData = {
  folderId: string;
};

export type ChoosePageData = ChooseFolderData & {
  pageId: string;
};

export type FormData = ChooseFolderData | ChoosePageData;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onChoose: (data: FormData) => void;
  loading: boolean;
};

const formId = 'insert-to-page-form';
const tabs = [
  {
    id: 'newPage',
    label: 'New page',
    icon: IconMap.BlankPageSquare,
  },
  {
    id: 'existingPage',
    label: 'Existing page',
    icon: IconMap.OnePage,
  },
];

export const ChooseFolderOrPageModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onChoose,
  loading,
}) => {
  const [currentTab, setCurrentTab] = useState('newPage');
  const modalTitle = currentTab === 'newPage' ? 'Select Folder' : 'Select Page';

  return (
    <Modal
      dialogClassName="pointer-events-auto"
      onClose={onClose}
      isOpen={isOpen}
      title={modalTitle}
      actionButton={
        <Button
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          disabled={loading}
          loading={loading}
          form={formId}
        >
          Add
        </Button>
      }
    >
      <div className="flex justify-between gap-4 text-gray-700 font-medium pb-4">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={cn(
              'flex items-center h-13 w-1/2 border p-3 rounded-lg shadow-xs outline-none gap-2',
              {
                'border-gray-200': currentTab !== tab.id,
                'border-primary-600': currentTab === tab.id,
              }
            )}
            onClick={() => setCurrentTab(tab.id)}
          >
            <span
              className={cn('rounded-md p-1', {
                'bg-gray-100 text-gray-500': currentTab !== tab.id,
                'bg-primary-50 text-primary-600': currentTab === tab.id,
              })}
            >
              <Icon glyph={tab.icon} width={20} />
            </span>
            {tab.label}
          </button>
        ))}
      </div>
      {currentTab === 'newPage' ? (
        <SelectFolderForm formId={formId} onSubmit={onChoose} />
      ) : (
        <SelectPageForm formId={formId} onSubmit={onChoose} />
      )}
    </Modal>
  );
};
