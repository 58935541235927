import { debounce, takeEvery } from 'redux-saga/effects';
import { tasksModel } from '..';

export function* tasksWorker() {
  yield debounce(
    500,
    tasksModel.actions.updateTask,
    tasksModel.sagas.updateTask
  );

  yield debounce(
    500,
    tasksModel.actions.removeTaskAssignee,
    tasksModel.sagas.removeTaskAssignee
  );

  yield takeEvery(
    tasksModel.actions.duplicateTask,
    tasksModel.sagas.duplicateTask
  );

  yield takeEvery(tasksModel.actions.deleteTask, tasksModel.sagas.deleteTask);

  yield takeEvery(
    tasksModel.actions.setTaskAssignee,
    tasksModel.sagas.setTaskAssignee
  );

  yield debounce(
    500,
    tasksModel.actions.updateTasklist,
    tasksModel.sagas.updateTasklist
  );

  yield takeEvery(
    tasksModel.actions.updateTasklistDataFromDb,
    tasksModel.sagas.updateTasklistDataFromDb
  );

  yield takeEvery(
    tasksModel.actions.reorderTasks,
    tasksModel.sagas.reorderTasks
  );

  yield takeEvery(tasksModel.actions.addTask, tasksModel.sagas.addTask);

  yield takeEvery(
    tasksModel.actions.createTasklist,
    tasksModel.sagas.createTasklist
  );

  yield takeEvery(
    tasksModel.actions.duplicateTasklist,
    tasksModel.sagas.duplicateTasklist
  );
}
