import React from 'react';
import { Modal, Button } from '../../../shared/ui';
import { SelectPageForm } from './SelectPageForm';

type Props = {
  onClose: () => void;
  onChoose: (data: ChooseFolderModalForm) => void;
  loading: boolean;
};

export type ChooseFolderModalForm = {
  folderId: string;
  pageId: string;
};

const formId = 'insert-to-page-form';

export const InsertToPageModal: React.FC<Props> = ({
  onClose,
  onChoose,
  loading,
}) => {
  const onModalClose = () => {
    if (loading) return;
    onClose();
  };

  return (
    <Modal
      dialogClassName="pointer-events-auto"
      onClose={onModalClose}
      isOpen
      title="Select Page"
      actionButton={
        <Button
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          disabled={loading}
          loading={loading}
          form={formId}
        >
          Add
        </Button>
      }
    >
      <SelectPageForm formId={formId} onSubmit={onChoose} />
    </Modal>
  );
};
