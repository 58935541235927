import React, { useCallback, useEffect, useState } from 'react';
import YPartyKitProvider from 'y-partykit/provider';
import { CollaborationProvider } from './CollaborationContext';
import { auth, COLLABORATION_SERVER_URL } from '../../../shared/config';
import { Loader } from '../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../../../features/pages';
import { environment } from '../../../environments/environment';
import { FeatureFlags } from '../../feature-flag';
import { redirectActions } from '../../history';
import { createNotification, snackbarModel } from '../../../features/snackbar';
import { authUserModel } from '../../auth-user';
import { logger } from '../../../shared/lib';
import {
  CollaborationAction,
  CollaborationEvent,
  createWSAction,
} from '@distribute/shared/yjs';
import { teamsModel } from '../../../features/teams';

export enum PartykitConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Connecting = 'connecting',
}

interface Props {
  children: React.ReactNode;
}

const MAX_RECONNECT_ATTEMPTS = 5;

export const CollaborationConnectionProvider: React.FC<Props> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isCollaborationEnabled =
    environment.featureFlags[FeatureFlags.DOCUMENT_COLLABORATION];
  const [isReconnecting, setIsReconnecting] = useState(false);

  const [provider, setProvider] = useState<YPartyKitProvider>();
  const [reconnectAttempts, setReconnectionAttempts] = useState(0);
  const [connectionStatus, setConnectionStatus] =
    useState<PartykitConnectionStatus>(PartykitConnectionStatus.Connecting);
  const [isPageSaved, setPageSaved] = useState(true);
  const [successfullyConnected, setSuccessfullyConnected] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  const createProvider = useCallback(() => {
    return new YPartyKitProvider(
      COLLABORATION_SERVER_URL,
      currentPage.id,
      undefined,
      {
        params: async () => {
          const data = {
            token: await auth.currentUser?.getIdToken(true).catch(() => ''),
            teamId: currentTeam.id.toString(),
          };
          return data;
        },
        connect: isCollaborationEnabled,
        maxBackoffTime: 2 * 60 * 1000,
        connectionId: currentUser.id,
      }
    );
  }, [currentPage.id, currentTeam.id, currentUser.id, isCollaborationEnabled]);

  const setNewProvider = useCallback(() => {
    if (provider) {
      return;
    }
    setProvider(createProvider());
  }, [createProvider, provider]);

  const resetProvider = useCallback(() => {
    if (provider) {
      provider.ws?.close();
      provider.disconnect();
      provider.destroy();
      setProvider(undefined);
      setNewProvider();
    }
  }, [provider, setNewProvider]);

  const leavePageWithMessage = useCallback(
    (type: 'error' | 'warning', message: string) => {
      provider?.disconnect();
      dispatch(redirectActions.toWorkspace());
      dispatch(
        snackbarModel.actions.addNotificationAction(
          createNotification(type, message)
        )
      );
    },
    [dispatch, provider]
  );

  const handleReconnection = useCallback(() => {
    logger.info(
      `Attempting to reconnect: ${reconnectAttempts}/${MAX_RECONNECT_ATTEMPTS}`
    );

    const isMaxAttemptsReached = reconnectAttempts >= MAX_RECONNECT_ATTEMPTS;

    if (isMaxAttemptsReached && !successfullyConnected) {
      return leavePageWithMessage(
        'error',
        'Failed to connect to a page, please try again later'
      );
    }

    if (isMaxAttemptsReached && successfullyConnected) {
      setIsReconnecting(true);
      resetProvider();
      return;
    }
    setReconnectionAttempts((prev) => prev + 1);
  }, [
    leavePageWithMessage,
    reconnectAttempts,
    resetProvider,
    successfullyConnected,
  ]);

  const handleConnectionError = useCallback(
    (error: unknown) => {
      logger.error('Connection error:', error);
      if (!isReconnecting) {
        handleReconnection();
      }
    },
    [handleReconnection, isReconnecting]
  );

  const handleStatusChange = useCallback(
    ({ status }: { status: PartykitConnectionStatus }) => {
      setConnectionStatus(status);
      if (status === PartykitConnectionStatus.Connected) {
        setReconnectionAttempts(0);
        setSuccessfullyConnected(true);
        setIsReconnecting(false);
      }
    },
    []
  );

  const handleAuthError = useCallback(
    (data: string) => {
      logger.error('Authentication error:', data);
      if (provider) {
        setPageSaved(false);
        leavePageWithMessage('error', 'Authentication error');
      }
    },
    [leavePageWithMessage, provider]
  );

  const handleUpdateError = useCallback(
    (data: string) => {
      logger.info('Page updating error:', data);
      setPageSaved(false);
      dispatch(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', data)
        )
      );
    },
    [dispatch]
  );

  const handleUpdateSuccess = useCallback((message: string) => {
    logger.info(message);
    setPageSaved(true);
  }, []);

  const handleLoadError = useCallback(
    (data: string) => {
      logger.error('Page loading error:', data);
      leavePageWithMessage('error', data);
    },
    [leavePageWithMessage]
  );

  const handlePing = useCallback(
    (data: number) => {
      const action = createWSAction({
        type: CollaborationEvent.Pong,
        payload: data,
      });
      const uint8 = new TextEncoder().encode(action);
      provider?.ws?.send(uint8);
    },
    [provider?.ws]
  );

  const handleWSMessage = useCallback(
    (event: MessageEvent) => {
      try {
        if (typeof event.data !== 'string') return;
        const { type, payload } = JSON.parse(event.data) as CollaborationAction;

        switch (type) {
          case 'ping':
            handlePing(payload);
            break;
          case 'auth-error':
            handleAuthError(payload);
            break;
          case 'update-error':
            handleUpdateError(payload);
            break;
          case 'update-success':
            handleUpdateSuccess(payload);
            break;
          case 'load-error':
            handleLoadError(payload);
            break;
        }
      } catch (error) {
        logger.error('Error processing message:', error);
      }
    },
    [
      handleAuthError,
      handleLoadError,
      handlePing,
      handleUpdateError,
      handleUpdateSuccess,
    ]
  );

  const handleSynced = useCallback(() => {
    setLoaded(true);
  }, []);

  const handleDisconnected = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    let cancel = false;
    if (!provider && !cancel) {
      setNewProvider();
    }
    return () => {
      cancel = true;
    };
  }, [connectionStatus, currentPage.id, provider, setNewProvider]);

  useEffect(() => {
    if (
      successfullyConnected &&
      connectionStatus === PartykitConnectionStatus.Disconnected
    ) {
      window.location.reload();
    }
  }, [connectionStatus, successfullyConnected]);

  useEffect(() => {
    if (!provider) return;

    provider.on('connection-error', handleConnectionError);
    provider.on('status', handleStatusChange);
    provider.on('synced', handleSynced);
    provider.on('disconnect', handleDisconnected);
    provider.ws?.addEventListener('message', handleWSMessage);

    return () => {
      provider.off('connection-error', handleConnectionError);
      provider.off('status', handleStatusChange);
      provider.off('synced', handleSynced);
      provider.off('disconnect', handleDisconnected);
      provider.ws?.removeEventListener('message', handleWSMessage);
    };
  }, [
    provider,
    handleStatusChange,
    handleConnectionError,
    handleWSMessage,
    handleSynced,
    handleDisconnected,
  ]);

  useEffect(() => {
    return () => {
      if (provider) {
        provider.ws?.close();
        provider.disconnect();
        provider.destroy();
        setProvider(undefined);
        setConnectionStatus(PartykitConnectionStatus.Disconnected);
      }
    };
  }, [provider]);

  if (
    !provider ||
    !isLoaded ||
    connectionStatus !== PartykitConnectionStatus.Connected
  ) {
    return <Loader />;
  }

  return (
    <CollaborationProvider provider={provider} isPageSaved={isPageSaved}>
      {children}
    </CollaborationProvider>
  );
};
