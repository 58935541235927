import { useFormContext } from 'react-hook-form';
import { useCreatePageStepContext } from './context';
import {
  ActiveAIStepType,
  CreatePageActiveScreenEnum,
  CreatePageFormSchemaType,
  PageCreationTypesEnum,
  PageSourceToSidebarScreenMap,
  PageTypeToSidebarScreenMap,
} from '../../config/types';
import { useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../model';
import { useEffect } from 'react';

export function useSidebarActiveScreen() {
  const pageCreationType = useSelector(
    pageCreationFlowModel.selectors.selectPageCreationType
  );
  const activeAIStep = useSelector(
    pageCreationFlowModel.selectors.selectActiveAIStep
  );
  const { setActiveScreen } = useCreatePageStepContext();
  const { watch } = useFormContext<CreatePageFormSchemaType>();
  const isBranded = watch('isBranded');
  const pageType = watch('pageType');
  const source = watch('selectedDataSource');
  const wasOutlineGenerationStarted = useSelector(
    pageCreationFlowModel.selectors.selectWasOutlineGenerationStarted
  );
  const isGeneratingOutline = useSelector(
    pageCreationFlowModel.selectors.selectIsGenerateOutlineLoading
  );

  useEffect(() => {
    const getBrandedOrGeneralPageScreen = (isBranded: boolean) =>
      isBranded
        ? CreatePageActiveScreenEnum.BrandedPage
        : CreatePageActiveScreenEnum.GeneralPage;
    if (isGeneratingOutline) {
      setActiveScreen(CreatePageActiveScreenEnum.CreateOutline);
      return;
    }
    if (
      pageCreationType === PageCreationTypesEnum.FROM_SCRATCH ||
      pageCreationType === PageCreationTypesEnum.FROM_TEMPLATE
    ) {
      setActiveScreen(getBrandedOrGeneralPageScreen(isBranded));
      return;
    }
    if (activeAIStep === ActiveAIStepType.APPEARANCE) {
      setActiveScreen(getBrandedOrGeneralPageScreen(isBranded));
      return;
    }
    if (activeAIStep === ActiveAIStepType.TYPE) {
      setActiveScreen(
        pageType
          ? PageTypeToSidebarScreenMap[pageType]
          : CreatePageActiveScreenEnum.SelectType
      );
      return;
    }
    setActiveScreen(
      activeAIStep === ActiveAIStepType.SOURCE
        ? PageSourceToSidebarScreenMap[source]
        : wasOutlineGenerationStarted
        ? CreatePageActiveScreenEnum.CreateOutline
        : CreatePageActiveScreenEnum.IntermediateStep
    );
  }, [
    activeAIStep,
    isBranded,
    isGeneratingOutline,
    pageCreationType,
    pageType,
    setActiveScreen,
    source,
    wasOutlineGenerationStarted,
  ]);
}
