import {
  Editor,
  NodeViewContent,
  NodeViewProps,
  NodeViewWrapper,
} from '@tiptap/react';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import './ResizeableFigure.css';
import classNames from 'classnames';
import { ResizeableFigureAlign } from './ResizeableFigure';
import { AlignmentDropdown } from './AlignmentDropdown';
import { hasVideoContent } from './utils';
import { FragmentWithContent } from '../MultipleColumns';

export const NodeView = (props: NodeViewProps) => {
  const { editor, node, updateAttributes, selected, getPos } = props;
  const mediaWidth =
    node.attrs['data-media-width'] ??
    (node.content as FragmentWithContent)?.content?.[0]?.attrs.width;
  const mediaHeight =
    node.attrs['data-media-height'] ??
    (node.content as FragmentWithContent)?.content?.[0]?.attrs.height;
  const align: ResizeableFigureAlign = node.attrs['data-align'];
  const ContentRef = useRef<HTMLDivElement | null>(null);
  const [isIframeVideo, setIsIframeVideo] = useState(false);
  const isDisabled = !editor.isEditable;

  useLayoutEffect(() => {
    if (!mediaWidth) {
      requestAnimationFrame(() => {
        const mediaElements = ContentRef.current?.querySelectorAll('img,video');
        if (mediaElements && mediaElements[0]) {
          const el = mediaElements[0] as HTMLElement;
          el.onload = () => {
            updateAttributes({
              'data-media-width': Math.min(
                el.offsetWidth,
                editor.view.dom.getBoundingClientRect().width - 64
              ),
              'data-media-height': Math.min(
                el.offsetHeight,
                editor.view.dom.getBoundingClientRect().height
              ),
            });
          };
        }
      });
    }
  }, [mediaWidth, updateAttributes, editor]);

  const handleHorizontalResize = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      direction: 'right' | 'left'
    ) => {
      e.stopPropagation();
      e.preventDefault();

      editor.view.dom.querySelectorAll('iframe').forEach((iframe) => {
        iframe.style.pointerEvents = 'none';
      });

      const startX = e.pageX;
      const startWidth = ContentRef.current?.getBoundingClientRect().width || 0;

      const handleMouseMove = (e: MouseEvent) => {
        const width =
          direction === 'left'
            ? startWidth - e.pageX + startX
            : startWidth + e.pageX - startX;

        const maxWidth =
          ContentRef.current?.closest('.node-column')?.getBoundingClientRect()
            .width || editor.view.dom.getBoundingClientRect().width;

        updateAttributes({
          'data-media-width': Math.max(Math.min(width, maxWidth), 100),
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        editor.view.dom.querySelectorAll('iframe').forEach((iframe) => {
          iframe.style.pointerEvents = '';
        });
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [updateAttributes, editor]
  );

  const handleResize = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      direction: 'nw' | 'ne' | 'sw' | 'se'
    ) => {
      e.stopPropagation();
      e.preventDefault();

      editor.view.dom.querySelectorAll('iframe').forEach((iframe) => {
        iframe.style.pointerEvents = 'none';
      });

      const startY = e.pageY;
      const startX = e.pageX;
      const startHeight =
        ContentRef.current?.getBoundingClientRect().height || 0;
      const startWidth = ContentRef.current?.getBoundingClientRect().width || 0;

      const handleMouseMove = (e: MouseEvent) => {
        const height =
          direction === 'nw' || direction === 'ne'
            ? startHeight - e.pageY + startY
            : startHeight + e.pageY - startY;
        const width =
          direction === 'ne' || direction === 'se'
            ? startWidth - e.pageX + startX
            : startWidth + e.pageX - startX;

        const maxWidth =
          ContentRef.current?.closest('.node-column')?.getBoundingClientRect()
            .width || editor.view.dom.getBoundingClientRect().width;

        updateAttributes({
          'data-media-height': Math.max(Math.min(height), 100),
          'data-media-width': Math.max(Math.min(width, maxWidth), 100),
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        editor.view.dom.querySelectorAll('iframe').forEach((iframe) => {
          iframe.style.pointerEvents = '';
        });
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [updateAttributes, editor]
  );

  const isVideo = ['video', 'videoRecord'].includes(
    node.firstChild?.type.name || ''
  );
  const isImage = node.firstChild?.type.name === 'image';

  const wrapperClass = classNames(
    'relative group cursor-pointer z-10 max-w-full',
    selected && 'outline-4 outline-brand-500'
  );

  const classNamesFromAttributes = props.HTMLAttributes.class;

  const nodeViewClassName = classNames(
    'node--resizeableFigure !border-0',
    selected && 'selected',
    align === 'float-left' && 'float-left mr-4',
    align === 'float-right' && 'float-right ml-4',
    classNamesFromAttributes
  );

  const flexWrapperClassName = classNames(
    'flex items-center relative',
    align === 'left' && 'justify-start mt-7 mb-7 w-full',
    align === 'center' && 'justify-center mt-7 mb-7 w-full',
    align === 'right' && 'justify-end mt-7 mb-7 w-full',
    align === 'float-left' && 'justify-start',
    align === 'float-right' && 'justify-end'
  );

  const handleElementClick = () => {
    const pos = getPos();
    editor.chain().setNodeSelection(pos).focus().run();
  };

  return (
    <NodeViewWrapper className={nodeViewClassName}>
      <figure
        data-drag-handle
        draggable="true"
        className={flexWrapperClassName}
        contentEditable={false}
      >
        <div
          className={classNames('iframe-content-wrapper group', wrapperClass)}
        >
          <div
            style={{
              width: mediaWidth ? `${mediaWidth}px` : 'auto',
              height:
                mediaHeight && !isIframeVideo && !isImage && !isVideo
                  ? `${mediaHeight}px`
                  : 'auto',
              maxWidth: '100%',
              overflow: 'hidden',
            }}
            className={classNames(
              'group-hover:outline outline-2-outline-offset-2 z-10 outline-primary-500',
              {
                outline: selected,
              }
            )}
            onClick={handleElementClick}
            ref={(el) => {
              ContentRef.current = el as HTMLDivElement;
              if (el) {
                setIsIframeVideo(hasVideoContent(el));
              }
            }}
          >
            <NodeViewContent />
          </div>
          <div
            draggable="false"
            onMouseDown={(e) => handleHorizontalResize(e, 'left')}
            className="absolute top-1.5 z-10 invisible w-2 h-[calc(100%-12px)] transition-all opacity-0 -left-1 group-hover:visible group-hover:opacity-70 bg-transparent cursor-col-resize"
          ></div>
          <div
            draggable="false"
            onMouseDown={(e) => handleHorizontalResize(e, 'right')}
            className="absolute top-1.5 z-10 invisible w-2 h-[calc(100%-12px)] transition-all opacity-0 -right-1 group-hover:visible group-hover:opacity-70 bg-transparent cursor-col-resize"
          ></div>
          <div
            className={classNames(
              'absolute w-2.5 h-2.5 bg-primary-500 -left-1 -top-1 cursor-nw-resize border border-base-white rounded-sm',
              {
                invisible: !selected || isDisabled,
                'group-hover:visible resize-rect': !isDisabled,
              }
            )}
            onMouseDown={(e) => handleResize(e, 'ne')}
          ></div>
          <div
            className={classNames(
              'absolute w-2.5 h-2.5 bg-primary-500 -right-1 -top-1 cursor-ne-resize border border-base-white rounded-sm ',
              {
                invisible: !selected || isDisabled,
                'group-hover:visible resize-rect': !isDisabled,
              }
            )}
            onMouseDown={(e) => handleResize(e, 'nw')}
          ></div>
          <div
            className={classNames(
              'absolute w-2.5 h-2.5 bg-primary-500 -left-1 -bottom-1 cursor-sw-resize border border-base-white rounded-sm ',
              {
                invisible: !selected || isDisabled,
                'group-hover:visible resize-rect': !isDisabled,
              }
            )}
            onMouseDown={(e) => handleResize(e, 'se')}
          ></div>
          <div
            className={classNames(
              'absolute w-2.5 h-2.5 bg-primary-500 -right-1 -bottom-1 cursor-se-resize border border-base-white rounded-sm',
              {
                invisible: !selected || isDisabled,
                'group-hover:visible resize-rect': !isDisabled,
              }
            )}
            onMouseDown={(e) => handleResize(e, 'sw')}
          ></div>
          <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-all align-rect">
            {!isDisabled && <AlignmentDropdown editor={editor as Editor} />}
          </div>
        </div>
      </figure>
    </NodeViewWrapper>
  );
};
