import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { Button, Modal } from '../../../../../../src/shared/ui';
import { FC } from 'react';

type Props = {
  handleReplaceTab: (template: TemplateExtended) => void;
  onClose: () => void;
  isOpen: boolean;
  template: TemplateExtended;
};

export const ReplaceTabsModal: FC<Props> = ({
  handleReplaceTab,
  onClose,
  isOpen,
  template,
}) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isShowCancelButton={false}
      isShowCloseIconInTitle
      title={
        template.isSinglePage
          ? 'Replace page content?'
          : 'Replace all tabs content?'
      }
      className="w-120"
    >
      <div className="mb-8 text-sm font-normal text-gray-600">
        {template.isSinglePage
          ? 'Using the template for the active tab will overwrite the current content. Would you like to create a new tab for this template instead?'
          : 'Applying the multi-tab template to this page will replace all existing tabs content. Are you sure you want to proceed?'}
      </div>

      <div className="flex justify-between w-full gap-3">
        <Button
          onClick={onClose}
          color="secondary"
          variant="text"
          className="w-35"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleReplaceTab(template);
          }}
          color="destructive"
          variant="text"
          className="w-35"
        >
          Replace
        </Button>
      </div>
    </Modal>
  );
};
