import { call, select, put } from 'redux-saga/effects';
import { callRecordingsApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { callRecordingSettingsModel } from '../index';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* updateTeamCallRecordingSettings({
  payload: data,
}: RT<
  typeof callRecordingSettingsModel.actions.updateTeamCallRecordingSettings
>) {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  yield put(callRecordingSettingsModel.actions.setIsTeamSettingsLoading(true));

  try {
    const response: RT<typeof callRecordingsApi.updateTeamSettings> =
      yield call(callRecordingsApi.updateTeamSettings, data, {
        teamId: team.id,
      });
    yield put(callRecordingSettingsModel.actions.setTeamSettings(response));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Team settings was successfully updated')
      )
    );
  } catch (err) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update team settings')
      )
    );
  } finally {
    yield put(
      callRecordingSettingsModel.actions.setIsTeamSettingsLoading(false)
    );
  }
}
