import { FC } from 'react';
import { Tooltip } from './Tooltip';
import { Icon } from './Icon';
import { IconMap } from '../sprite';

type Props = {
  onCopy?: () => void;
  onDownload?: () => void;
  copied?: boolean;
};

export const CopyDownload: FC<Props> = ({
  onCopy,
  onDownload,
  copied = false,
}) => {
  return (
    <div className="flex items-center border border-gray-300 rounded-md h-10 w-42 text-gray-700 hover:text-gray-800">
      <Tooltip
        isOpen={copied}
        hideArrow
        sideOffset={6}
        trigger={
          <button
            className="flex items-center gap-2 px-3.5 py-2 text-sm font-semibold"
            type="button"
            onClick={onCopy}
          >
            <Icon glyph={IconMap.Copy01} width={20} />
            Copy text
          </button>
        }
      >
        Copied!
      </Tooltip>
      <Tooltip
        hideArrow
        sideOffset={6}
        trigger={
          <button
            className="border-l border-gray-300 px-3 py-2.5"
            type="button"
            onClick={onDownload}
          >
            <Icon glyph={IconMap.Download02} width={20} />
          </button>
        }
      >
        Download as .txt
      </Tooltip>
    </div>
  );
};
