import { useSelector } from 'react-redux';
import { callRecordingsModel } from '../model';
import { callRecordingSettingsModel } from '../../call-recording-settings';
import { Loader } from '../../../shared/ui';
import { ConnectCalendarState } from './ConnectCalendarState';
import { MeetingEvents } from './MeetingEvents';
import { MeetingEmptyState } from './MeetingEmptyState';

export const MeetingEventsState = () => {
  const isLoading = useSelector(callRecordingsModel.selectors.selectIsLoading);
  const events = useSelector(callRecordingsModel.selectors.selectMeetingEvents);
  const meetingsCalendar = useSelector(
    callRecordingSettingsModel.selectors.selectMeetingCalendar
  );

  if (isLoading) {
    return (
      <div className="flex items-center flex-1">
        <Loader />
      </div>
    );
  }
  if (events.length) {
    return <MeetingEvents />;
  }

  if (meetingsCalendar) {
    return <MeetingEmptyState />;
  }

  return (
    <div className="flex items-center flex-1">
      <ConnectCalendarState size="small" />
    </div>
  );
};
