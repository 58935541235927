import { FC } from 'react';

import {
  VideoRecordStatus,
  VideoRecordStatusConvert,
} from '@distribute/shared/types';
import { checkIsRecordConvertingFailed } from '../../lib';

import { RecordFailed, RecordLoading } from '../../../../shared/ui';

type Props = {
  size?: 'sm' | 'md';
  status: VideoRecordStatus;
  statusConvert: VideoRecordStatusConvert;
};

export const RecordInProgressStatus: FC<Props> = ({
  status,
  statusConvert,
  size = 'sm',
}) => {
  const isRecordConvertingFailed = checkIsRecordConvertingFailed({
    status,
    statusConvert,
  });

  if (isRecordConvertingFailed) {
    return <RecordFailed size={size} />;
  }

  return <RecordLoading size={size} />;
};
