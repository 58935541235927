import { ClearbitCompanyInfo } from '../../shared/api';
import React from 'react';
import { Avatar, Size } from '.';

type Props = {
  companyInfo: ClearbitCompanyInfo;
  handleClick: () => void;
  size?: Size;
};

export const CompanyRecord: React.FC<Props> = ({
  companyInfo,
  handleClick,
  size,
}) => {
  return (
    <li
      className="rounded-xl p-2 flex items-center gap-4 hover:bg-gray-100 cursor-pointer transition-all overflow-hidden w-full"
      onClick={handleClick}
    >
      <Avatar
        size={size}
        src={companyInfo.logo ?? ''}
        displayName={companyInfo.name}
        theme="rect"
      />
      <p className="font-medium text-gray-900 min-w-0 flex-1 text-ellipsis overflow-hidden whitespace-nowrap">
        {companyInfo.name}
      </p>
      <p className="text-gray-600 shrink-0">
        {companyInfo.domain ?? 'unknown website'}
      </p>
    </li>
  );
};
