import { Editor } from '@tiptap/react';
import { CommandGroup } from '../../../entities/tiptap-editor/extensions/types';
import {
  UploadFile,
  getUploadFormRect,
} from '../../../entities/tiptap-editor/ui/upload-form';
import { IconMap } from '../../../shared/sprite';
import { Icon } from '../../../shared/ui';
import { buildNColumns } from '../../../entities/tiptap-editor/extensions/MultipleColumns';
import {
  FeatureFlags,
  isFeatureFlagEnabled,
} from '../../../entities/feature-flag';
import { RootState } from '../../../app';
import { AnyAction, Store } from '@reduxjs/toolkit';
import {
  focusTasklistTextarea,
  tasksModel,
} from '../../../../src/features/tasks';

export const SLASH_MENU_GROUPS: CommandGroup[] = [
  {
    label: 'AI Assistant',
    items: [
      {
        name: 'Start writing with AI...',
        description: '',
        icon: <Icon glyph={IconMap.WizardWrite} width={20} />,
        key: 'ai',
        aliases: ['ai'],
        action: (editor: Editor) => {
          editor.commands.renderAI();
        },
      },
    ],
  },
  {
    label: 'Basic',
    items: [
      {
        name: 'Heading 1',
        description: 'Big section heading.',
        icon: <Icon glyph={IconMap.Heading1} width={20} />,
        key: 'heading-1',
        aliases: ['heading1', 'h1'],
        action: (editor: Editor) => {
          const selectionFrom = editor.state.selection.from;
          const resolvedPos = editor.state.doc.resolve(selectionFrom);
          if (resolvedPos.parentOffset > 0) {
            editor.chain().focus().enter().setHeading({ level: 2 }).run();
          } else {
            editor.chain().focus().setHeading({ level: 2 }).run();
          }
        },
        actionPreview: {
          name: 'Heading 1',
          description: 'Big section heading.',
          icon: <Icon glyph={IconMap.Heading1} width={20} />,
          cover: 'heading1.png',
        },
      },
      {
        name: 'Heading 2',
        description: 'Medium section heading.',
        icon: <Icon glyph={IconMap.Heading2} width={20} />,
        key: 'heading-2',
        aliases: ['heading2', 'h2'],
        action: (editor: Editor) => {
          const selectionFrom = editor.state.selection.from;
          const resolvedPos = editor.state.doc.resolve(selectionFrom);
          if (resolvedPos.parentOffset > 0) {
            editor.chain().focus().enter().setHeading({ level: 3 }).run();
          } else {
            editor.chain().focus().setHeading({ level: 3 }).run();
          }
        },
        actionPreview: {
          name: 'Heading 2',
          description: 'Medium section heading.',
          icon: <Icon glyph={IconMap.Heading2} width={20} />,
          cover: 'heading2.png',
        },
      },
      {
        name: 'Heading 3',
        description: 'Small section heading.',
        icon: <Icon glyph={IconMap.Heading3} width={20} />,
        key: 'heading-3',
        aliases: ['heading3', 'h3'],
        action: (editor: Editor) => {
          const selectionFrom = editor.state.selection.from;
          const resolvedPos = editor.state.doc.resolve(selectionFrom);
          if (resolvedPos.parentOffset > 0) {
            editor.chain().focus().enter().setHeading({ level: 4 }).run();
          } else {
            editor.chain().focus().setHeading({ level: 4 }).run();
          }
        },
        actionPreview: {
          name: 'Heading 3',
          description: 'Small section heading.',
          icon: <Icon glyph={IconMap.Heading3} width={20} />,
          cover: 'heading3.png',
        },
      },
      {
        name: 'Table',
        description: 'Add basic table content into your page.',
        icon: <Icon glyph={IconMap.Table} width={20} />,
        key: 'table',
        aliases: ['table'],
        action: (editor: Editor) => editor.commands.renderTableSizeSelector(),
        actionPreview: {
          name: 'Table',
          description: 'Add basic table content into your page.',
          icon: <Icon glyph={IconMap.Table} width={20} />,
          cover: 'table.png',
        },
      },
      {
        name: 'Bulleted list',
        description: 'Add a simple bulleted list.',
        icon: <Icon glyph={IconMap.BulletList} width={20} />,
        key: 'bulleted-list',
        aliases: ['bulleted-list'],
        action: (editor: Editor) => {
          const selectionFrom = editor.state.selection.from;
          const resolvedPos = editor.state.doc.resolve(selectionFrom);
          if (resolvedPos.parentOffset > 0) {
            editor.chain().focus().enter().toggleBulletList().run();
          } else {
            editor.chain().focus().toggleBulletList().run();
          }
        },
        actionPreview: {
          name: 'Bulleted list',
          description: 'Add a simple bulleted list.',
          icon: <Icon glyph={IconMap.BulletList} width={20} />,
          cover: 'bulleted-list.png',
        },
      },
      {
        name: 'Numbered list',
        description: 'Add a list with numbering.',
        icon: <Icon glyph={IconMap.NumberList} width={20} />,
        key: 'numbered-list',
        aliases: ['numbered-list'],
        action: (editor: Editor) => {
          const selectionFrom = editor.state.selection.from;
          const resolvedPos = editor.state.doc.resolve(selectionFrom);
          if (resolvedPos.parentOffset > 0) {
            editor.chain().focus().enter().toggleOrderedList().run();
          } else {
            editor.chain().focus().toggleOrderedList().run();
          }
        },
        actionPreview: {
          name: 'Numbered list',
          description: 'Add a list with numbering.',
          icon: <Icon glyph={IconMap.NumberList} width={20} />,
          cover: 'numbered-list.png',
        },
      },
      {
        name: 'Toggle list',
        description: 'Toggles reveal hidden content.',
        icon: <Icon glyph={IconMap.ToggleList} width={20} />,
        key: 'toggleList',
        aliases: ['toggleList'],
        action: (editor: Editor) => {
          const selectionFrom = editor.state.selection.from;
          const resolvedPos = editor.state.doc.resolve(selectionFrom);
          if (resolvedPos.parentOffset > 0) {
            editor.chain().focus().enter().toggleToggleList().run();
          } else {
            editor.chain().focus().toggleToggleList().run();
          }
        },
        actionPreview: {
          name: 'Toggle list',
          description: 'Toggles reveal hidden content.',
          icon: <Icon glyph={IconMap.ToggleList} width={20} />,
          cover: 'toggle-list.png',
        },
      },
      {
        name: 'To-do list',
        description: 'Keep track of tasks with a to-do list.',
        icon: <Icon glyph={IconMap.CheckSquare} width={20} />,
        key: 'todo-list',
        aliases: ['todo-list', 'todo'],
        action: (editor: Editor) => editor.commands.toggleTodoList(),
        actionPreview: {
          name: 'To-do list',
          description: 'Keep track of tasks with a to-do list.',
          icon: <Icon glyph={IconMap.CheckSquare} width={20} />,
          cover: 'todo-list.png',
        },
      },
      {
        name: 'Quote',
        description: 'Add a quote text.',
        icon: <Icon glyph={IconMap.Blockquote} width={20} />,
        key: 'quote',
        aliases: ['quote'],
        action: (editor: Editor) => {
          const selectionFrom = editor.state.selection.from;
          const resolvedPos = editor.state.doc.resolve(selectionFrom);
          if (resolvedPos.parentOffset > 0) {
            editor.chain().focus().enter().toggleBlockquote().run();
          } else {
            editor.chain().focus().toggleBlockquote().run();
          }
        },
        actionPreview: {
          name: 'Quote',
          description: 'Add a quote text.',
          icon: <Icon glyph={IconMap.Blockquote} width={20} />,
          cover: 'quote.png',
        },
      },
      {
        name: 'Callout',
        description: 'Create a callout block.',
        icon: <Icon glyph={IconMap.Announcement01} width={20} />,
        key: 'callout',
        aliases: ['callout'],
        action: (editor: Editor) => {
          editor.chain().focus().insertCallout().run();
        },
        actionPreview: {
          name: 'Callout',
          description: 'Highlight important content.',
          icon: <Icon glyph={IconMap.Announcement01} width={20} />,
          cover: 'callout.png',
        },
      },
      {
        name: 'Code block',
        description: 'Capture a code snippet.',
        icon: <Icon glyph={IconMap.CodeSnippet02} width={20} />,
        key: 'code-block',
        aliases: ['code', 'code-block'],
        action: (editor: Editor) =>
          editor.chain().focus().toggleCodeBlock().run(),
        actionPreview: {
          name: 'Code block',
          description: 'Capture a code snippet.',
          icon: <Icon glyph={IconMap.CodeSnippet02} width={20} />,
          cover: 'code-block.png',
        },
      },
    ],
  },
  {
    label: 'Interactive',
    items: [
      {
        name: 'Button',
        description: 'Add interactive component.',
        icon: <Icon glyph={IconMap.ButtonClick} width={20} />,
        key: 'button',
        aliases: ['button', 'btn'],
        action: (editor: Editor) => editor.chain().focus().insertButton().run(),
        actionPreview: {
          name: 'Button',
          description: 'Add interactive component.',
          icon: <Icon glyph={IconMap.ButtonClick} width={20} />,
          cover: 'button.png',
        },
      },
    ],
  },
  {
    label: 'Media',
    items: [
      {
        name: 'File',
        description: 'Upload or embed a file.',
        icon: <Icon glyph={IconMap.File05} width={20} />,
        key: 'pdf',
        aliases: ['pdf', 'file'],
        action: (editor: Editor) => {
          const { destroyComponent } =
            editor.extensionManager.commands.renderReactComponentWithTippy(
              UploadFile,
              {
                onUploaded: (data) => {
                  destroyComponent();
                  editor.commands.setFilePreview({
                    fileName: 'untitled',
                    ...data,
                  });
                },
                onClose: () => {
                  destroyComponent();
                },
              },
              { getReferenceClientRect: () => getUploadFormRect(editor) }
            );
        },
        actionPreview: {
          name: 'File',
          description: 'Upload or embed a file.',
          icon: <Icon glyph={IconMap.File05} width={20} />,
          cover: 'file.png',
        },
      },

      {
        name: 'Image',
        description: 'Upload or embed an image.',
        icon: <Icon glyph={IconMap.Image05} width={20} />,
        key: 'image',
        aliases: ['image', 'img'],
        action: (editor: Editor) => editor.commands.renderImageUploadForm(),
        actionPreview: {
          name: 'Image',
          description: 'Upload or embed an image.',
          icon: <Icon glyph={IconMap.Image05} width={20} />,
          cover: 'image.png',
        },
      },

      {
        name: 'Video',
        description: 'Upload or embed a video.',
        icon: <Icon glyph={IconMap.PlayVideo} width={20} />,
        key: 'video',
        aliases: ['video'],
        action: (editor: Editor) => editor.commands.renderVideoUploadForm(),
        actionPreview: {
          name: 'Video',
          description: 'Upload or embed a video.',
          icon: <Icon glyph={IconMap.PlayVideo} width={20} />,
          cover: 'video.png',
        },
      },
    ],
  },
  {
    label: 'Embeds',
    items: [
      {
        name: 'Embed content',
        description: 'Add any link content to page.',
        icon: <Icon glyph={IconMap.PuzzlePiece01} width={20} />,
        key: 'embed-content',
        aliases: ['embed-content', 'embed'],
        action: (editor: Editor) =>
          editor.chain().focus().insertEmbedContent().run(),
        actionPreview: {
          name: 'Embed content',
          description: 'Add any link content to page.',
          icon: <Icon glyph={IconMap.PuzzlePiece01} width={20} />,
          cover: 'embed.png',
        },
      },
      {
        name: 'Youtube',
        description: 'Embed videos from Youtube.',
        icon: <Icon glyph={IconMap.Youtube} width={20} />,
        key: 'youtube',
        aliases: ['embed-content', 'embed'],
        action: (editor: Editor) =>
          editor.chain().focus().insertEmbedContentYoutubePicker().run(),
        actionPreview: {
          name: 'Youtube',
          description: 'Embed videos from Youtube.',
          icon: <Icon glyph={IconMap.Youtube} width={20} />,
          cover: 'youtube.png',
        },
      },
      {
        name: 'Loom',
        description: 'Embed videos from Loom.',
        icon: <Icon glyph={IconMap.Loom} width={20} />,
        key: 'loom',
        aliases: ['embed-content', 'embed'],
        action: (editor: Editor) =>
          editor.chain().focus().insertEmbedContentLoomPicker().run(),
        actionPreview: {
          name: 'Loom',
          description: 'Embed videos from Loom.',
          icon: <Icon glyph={IconMap.Loom} width={20} />,
          cover: 'loom.png',
        },
      },
      {
        name: 'Google Drive',
        description: 'Embed anything from Google Drive.',
        icon: <Icon glyph={IconMap.GoogleDrive1} width={20} />,
        key: 'google-drive',
        aliases: ['embed-content', 'embed'],
        action: (editor: Editor) =>
          editor.chain().focus().insertEmbedContentGoogleDocsPicker().run(),
        actionPreview: {
          name: 'Google Drive',
          description: 'Embed anything from Google Drive.',
          icon: <Icon glyph={IconMap.GoogleDrive1} width={20} />,
          cover: 'google-drive.png',
        },
      },

      {
        name: 'Calendly',
        description: 'Embed calendars from Calendly.',
        icon: <Icon glyph={IconMap.CalendlyLogoCurrent} width={20} />,
        key: 'calendly',
        aliases: ['embed-content', 'embed'],
        action: (editor: Editor) =>
          editor.chain().focus().insertEmbedContentCalendlyPicker().run(),
        actionPreview: {
          name: 'Calendly',
          description: 'Embed calendars from Calendly.',
          icon: <Icon glyph={IconMap.CalendlyLogoCurrent} width={20} />,
          cover: 'calendly.png',
        },
      },
    ],
  },
  {
    label: 'Layout',
    items: [
      {
        name: 'Divider',
        description: 'Create visual separation between blocks.',
        icon: <Icon glyph={IconMap.Divider} width={20} />,
        key: 'divider',
        aliases: ['divider'],
        action: (editor: Editor) => {
          editor.chain().focus().setHorizontalRule().run();
        },
        actionPreview: {
          name: 'Divider',
          description: 'Create separation between blocks.',
          icon: <Icon glyph={IconMap.Divider} width={20} />,
          cover: 'divider.png',
        },
      },
      {
        name: 'Timeline',
        description: 'Summarize your steps with deadlines.',
        icon: <Icon glyph={IconMap.Route} width={20} />,
        key: 'timeline',
        aliases: ['timeline'],
        action: (editor: Editor) => {
          const selectionFrom = editor.state.selection.from;
          const resolvedPos = editor.state.doc.resolve(selectionFrom);
          editor
            .chain()
            .focus()
            .insertTimeline()
            .focus(resolvedPos.pos + 1)
            .run();
        },
        actionPreview: {
          name: 'Timeline',
          description: 'Summarize your steps with deadlines.',
          icon: <Icon glyph={IconMap.Route} width={20} />,
          cover: 'timeline.png',
        },
      },
      {
        name: 'Tasks',
        description: 'View all tasks in one list.',
        icon: <Icon glyph={IconMap.ClipboardCheck} width={20} />,
        key: 'tasklist',
        aliases: ['tasklist'],
        action: (editor: Editor, store: Store<RootState, AnyAction>) => {
          const currentPage = store.getState().pages.currentPage;
          const currentTemplate = store.getState().templates.currentTemplate;

          const documentContentId =
            currentPage?.content.id || currentTemplate?.content.id;

          if (!documentContentId) {
            return;
          }

          store.dispatch(
            tasksModel.actions.createTasklist({
              documentContentId,
              successCallback: (id: string) => {
                const selectionFrom = editor.state.selection.from;
                const resolvedPos = editor.state.doc.resolve(selectionFrom);
                editor
                  .chain()
                  .focus(resolvedPos.pos)
                  .insertCustomTaskList(id)
                  .run();

                setTimeout(() => {
                  focusTasklistTextarea(id);
                }, 0);
              },
            })
          );
        },
        actionPreview: {
          name: 'Tasks',
          description: 'View all tasks in one list.',
          icon: <Icon glyph={IconMap.ClipboardCheck} width={20} />,
          cover: 'tasklist.png',
        },
      },
      ...(isFeatureFlagEnabled(FeatureFlags.CONTACT_CARD)
        ? [
            {
              name: 'Contact card',
              description: 'Introduce and display contact info.',
              icon: <Icon glyph={IconMap.UserBadge} width={20} />,
              key: 'contact-card',
              aliases: ['contact-card'],
              action: (editor: Editor, store: Store<RootState, AnyAction>) => {
                const selectionFrom = editor.state.selection.from;
                const resolvedPos = editor.state.doc.resolve(selectionFrom);
                editor
                  .chain()
                  .focus()
                  .insertContactCard(store)
                  .focus(resolvedPos.pos + 1)
                  .run();
              },
              actionPreview: {
                name: 'Contact card',
                description: 'Introduce and display contact info.',
                icon: <Icon glyph={IconMap.UserBadge} width={20} />,
                cover: 'contact-card.png',
              },
            },
          ]
        : []),
      {
        name: '2 columns',
        description: 'Create two columns of blocks.',
        icon: <Icon glyph={IconMap.Columns02} width={20} />,
        key: '2columns',
        aliases: ['2 columns'],
        action: (editor: Editor) =>
          editor
            .chain()
            .focus()
            .unsetColumns(false)
            .setColumns(buildNColumns(2))
            .run(),
        actionPreview: {
          name: '2 columns',
          description: 'Create two columns of blocks.',
          icon: <Icon glyph={IconMap.Columns02} width={20} />,
          cover: 'columns02.png',
        },
      },
      {
        name: '3 columns',
        description: 'Create three columns of blocks.',
        icon: <Icon glyph={IconMap.Columns03} width={20} />,
        key: '3columns',
        aliases: ['3 columns'],
        action: (editor: Editor) =>
          editor
            .chain()
            .focus()
            .unsetColumns(false)
            .setColumns(buildNColumns(3))
            .run(),
        actionPreview: {
          name: '3 columns',
          description: 'Create three columns of blocks.',
          icon: <Icon glyph={IconMap.Columns03} width={20} />,
          cover: 'columns03.png',
        },
      },
      {
        name: '4 columns',
        description: 'Create four columns of blocks.',
        icon: <Icon glyph={IconMap.Columns04} width={20} />,
        key: '4columns',
        aliases: ['4 columns'],
        action: (editor: Editor) =>
          editor
            .chain()
            .focus()
            .unsetColumns(false)
            .setColumns(buildNColumns(4))
            .run(),
        actionPreview: {
          name: '4 columns',
          description: 'Create four columns of blocks.',
          icon: <Icon glyph={IconMap.Columns04} width={20} />,
          cover: 'columns04.png',
        },
      },
    ],
  },
];
