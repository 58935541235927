import { call, put, select, takeEvery } from 'redux-saga/effects';
import { actions } from '../reducer';
import { AIError } from '../../lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { aiApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import {
  AnalyticsEvents,
  TeamFiltered,
  UserToTeam,
} from '@distribute/shared/types';
import { channel } from 'redux-saga';
import { AIResponse, AIUpdateTextAction } from '../types';
import { selectResponses } from '../selectors';
import { v4 as uuid } from 'uuid';
import { analytics } from '../../../../entities/analytics';
import { subscriptionModel } from '../../../subscription';

const aiResponseChannel = channel<AIUpdateTextAction>();

const abortControllers = new Map<string, AbortController>();

function* startCompletion({
  payload,
}: ReturnType<typeof actions.startCompletion>) {
  const { cb, resultText, ...data } = payload;
  const id = uuid();
  try {
    yield put(actions.setIsLoading(true));
    const team: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    const teamMemberData: UserToTeam = yield select(
      teamsModel.selectors.selectUserTeamMemberDataWithError
    );

    if (teamMemberData.aiMonthlyResponsesCount === 0) {
      document.body.dispatchEvent(new Event('mousedown', { bubbles: true }));
      yield put(
        subscriptionModel.actions.togglePaywall({ isShow: true, source: 'ai' })
      );

      return;
    }

    const requestData = { ...data, teamId: team.id, resultText };
    const abortController = new AbortController();

    abortControllers.set(id, abortController);

    const responses: AIResponse[] = yield select(selectResponses);
    yield put(
      actions.setResponses([
        ...responses,
        { id, request: requestData, resultText: resultText || '' },
      ])
    );

    yield call(
      aiApi.getCompletion,
      requestData,
      abortController.signal,
      (resultText) => aiResponseChannel.put({ id: id, resultText })
    );

    yield put(teamsModel.actions.decrementAIResponse());

    analytics.track(AnalyticsEvents.AI_STARTED, requestData);

    if (cb) {
      yield call(cb);
    }
  } catch (error) {
    if (error instanceof AIError) {
      yield put(actions.setError({ message: error.message, type: error.type }));
    } else {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', `Failed to request AI`)
        )
      );
    }
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function stopCompletion({
  payload: { id },
}: ReturnType<typeof actions.stopCompletion>) {
  if (abortControllers.has(id)) {
    abortControllers.get(id)?.abort();
  }
}

export function* editorAiWorker() {
  yield takeEvery(actions.startCompletion, startCompletion);
  yield takeEvery(actions.stopCompletion, stopCompletion);
  yield takeEvery(aiResponseChannel, function* (resultUpdate) {
    yield put(actions.updateResponseText(resultUpdate));
  });
}
