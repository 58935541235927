import { IconMap } from '../../../../shared/sprite';
import { Button, Icon, Tooltip } from '../../../../shared/ui';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { useTiptapEditor } from '../../../../entities/tiptap-editor';
import { PagePublishingHeaderControls } from './PagePublishingHeaderControls';
import { templatesModel } from '../../../../features/templates';
import { TemplateCreationPhase } from '@distribute/shared/types';
import { SaveTemplateModal } from '../../../../widgets/editor-sidebar/ui/components/SaveTemplateModal';
import { TemplateSettingsDropdown } from './TemplateSettingsDropdown';
import { conversionKitModel } from '../../../../features/conversion-kit';
import { useCollaboration } from '../../../../entities/collaboration';
import { PageCollaboratorsMenu } from './PageCollaboratorsMenu';

type Props = {
  isTemplateMode: boolean;
  isAnalyticsTab?: boolean;
};

export const EditorHeaderToolbar: React.FC<Props> = ({
  isTemplateMode,
  isAnalyticsTab,
}) => {
  const dispatch = useDispatch();

  const isTemplateSaved = useSelector(
    templatesModel.selectors.selectIsTemplateSavedInEditor
  );

  const isTemplateUpdating = useSelector(
    templatesModel.selectors.selectTemplateIsUpdating
  );

  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  );

  const isTemplateDraft =
    currentTemplate?.creationPhase === TemplateCreationPhase.DRAFT;

  const alertBar = useSelector(conversionKitModel.selectors.selectAlertBar);
  const popup = useSelector(conversionKitModel.selectors.selectPopUp);
  const cta = useSelector(conversionKitModel.selectors.selectCTA);
  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContent
  );
  const squeezePage = useSelector(
    conversionKitModel.selectors.selectSqueezePage
  );

  const handleUpdateTemplate = (saveAs: TemplateCreationPhase) => {
    if (!currentTemplate) return;

    dispatch(
      templatesModel.actions.setCurrentTemplate({
        ...currentTemplate,
        creationPhase: saveAs,
      })
    );
    dispatch(templatesModel.actions.updateTemplateFromEditor());

    if (alertBar) {
      dispatch(
        conversionKitModel.actions.updateAlertBarDB({
          documentContentId: currentTemplate.content.id,
        })
      );
    }

    if (popup) {
      dispatch(
        conversionKitModel.actions.updatePopUpDB({
          documentContentId: currentTemplate.content.id,
        })
      );
    }

    if (cta) {
      dispatch(
        conversionKitModel.actions.updateCtaDB({
          documentContentId: currentTemplate.content.id,
        })
      );
    }

    if (gatedContent) {
      dispatch(
        conversionKitModel.actions.updateGatedContentDB({
          documentContentId: currentTemplate.content.id,
        })
      );
    }

    if (squeezePage) {
      dispatch(
        conversionKitModel.actions.updateSqueezePageDB({
          documentContentId: currentTemplate.content.id,
        })
      );
    }

    if (saveAs === TemplateCreationPhase.READY_TO_USE) {
      dispatch(templatesModel.actions.setIsTemplateSavedInEditor(true));
    }
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
  };

  const { editor } = useTiptapEditor();

  const undo = () => {
    editor?.chain().focus().undo().run();
  };

  const redo = () => {
    editor?.chain().focus().redo().run();
  };

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const { isCollaborationEnabled } = useCollaboration();

  const isSoloMode = isTemplateMode || !isCollaborationEnabled;

  return (
    <>
      {isTemplateDraft && (
        <SaveTemplateModal
          handleUpdateTemplate={handleUpdateTemplate}
          setIsSaveModalOpen={setIsSaveModalOpen}
          isSaveModalOpen={isSaveModalOpen}
        />
      )}
      <div className="flex items-center justify-end w-full gap-4 py-2">
        {!isAnalyticsTab && (
          <>
            <div className="flex items-center">
              <Tooltip
                trigger={
                  <Button
                    variant="icon"
                    color="tertiary-black"
                    disabled={!editor?.can().undo()}
                    onClick={undo}
                    size="sm"
                  >
                    <Icon glyph={IconMap.ReverseLeft} />
                  </Button>
                }
                sideOffset={4}
              >
                <p className="px-2 py-1 text-xs font-semibold">Undo</p>
              </Tooltip>

              <Tooltip
                trigger={
                  <Button
                    variant="icon"
                    color="tertiary-black"
                    disabled={!editor?.can().redo()}
                    onClick={redo}
                    size="sm"
                  >
                    <Icon glyph={IconMap.ReverseRight} />
                  </Button>
                }
                sideOffset={4}
              >
                <p className="px-2 py-1 text-xs font-semibold">Redo</p>
              </Tooltip>
            </div>
            <div className="w-0.25 h-5.5 bg-gray-200" />
          </>
        )}

        {!isSoloMode && <PageCollaboratorsMenu />}

        {!isTemplateMode && (
          <PagePublishingHeaderControls isAnalyticsTab={isAnalyticsTab} />
        )}

        {isTemplateMode && <TemplateSettingsDropdown listStyles="mt-4" />}

        {isTemplateMode &&
          (isTemplateSaved && !isTemplateDraft ? (
            <div className="py-2 px-3 bg-primary-50 flex items-center gap-1.5 text-primary-700 text-sm font-semibold rounded-lg">
              <Icon glyph={IconMap.CheckCircle} width={20} />
              Template saved
            </div>
          ) : (
            <Button
              variant="text"
              color="primary"
              size="sm"
              className="font-semibold shadow-xs"
              onClick={() => {
                isTemplateDraft
                  ? setIsSaveModalOpen(true)
                  : handleUpdateTemplate(TemplateCreationPhase.READY_TO_USE);
              }}
              loading={isTemplateUpdating && !isTemplateDraft}
            >
              <Icon
                glyph={IconMap.ArrowCircleDown}
                width={20}
                className="mr-1.5"
              />
              {isTemplateDraft ? 'Save Template' : 'Update'}
            </Button>
          ))}
      </div>
    </>
  );
};
