import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callModel } from '../model';
import { Button, Input, Modal } from '../../../shared/ui';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

type DeleteCallItemModalForm = {
  confirmText: string;
  id: string;
};

const CONFIRMATION_TEXT = 'CONFIRM';

const validationSchema = object().shape({
  confirmText: string()
    .trim()
    .required(`Type ${CONFIRMATION_TEXT} is required`)
    .oneOf(
      [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
      `Please type ${CONFIRMATION_TEXT} to delete.`
    ),
});

type DeleteCallItemModalProps = {
  isFromList?: boolean;
};

export const DeleteCallItemModal: React.FC<DeleteCallItemModalProps> = ({
  isFromList = false,
}) => {
  const dispatch = useDispatch();
  const item = useSelector(callModel.selectors.selectDeletingCallItemWithError);
  const isLoading = useSelector(
    callModel.selectors.selectIsCallDeletingProgress
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DeleteCallItemModalForm>({
    resolver: yupResolver(validationSchema),
  });
  const handleClose = () => {
    reset();
    dispatch(callModel.actions.setDeletingCallItem(undefined));
  };
  const handleFormSubmit = () => {
    dispatch(
      callModel.actions.deleteCallItem({
        id: item.id,
        isFromList,
      })
    );
  };

  return (
    <Modal
      isOpen
      onClose={handleClose}
      title="Delete Recording"
      className="w-100"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          loading={isLoading}
        >
          Delete Forever
        </Button>
      }
    >
      <div className="text-sm font-normal text-gray-600 mb-5">
        The “{item.name}” recording and its content will be deleted permanently.
        Deleting will affect any page that was created with this recording
        before.
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mt-2">
          <Input
            autoComplete="off"
            label={`Confirm by typing ${CONFIRMATION_TEXT} below`}
            {...register('confirmText')}
            isError={!!errors.confirmText}
            messageText={errors.confirmText?.message}
            type="text"
          />
        </div>
      </form>
    </Modal>
  );
};
