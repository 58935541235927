import { useDispatch, useSelector } from 'react-redux';
import { SidebarTabLayout } from '../shared/SidebarTabLayout';
import { EditorSidebarConversions } from './EditorSidebarConversions';
import { ActiveConversionPanel, editorLeftSidebarModel } from '../../../model';
import { AlertBarPanel } from './alert-bar/AlertBarPanel';
import { GatedContentPanel } from './gated-content/GatedContentPanel';
import { PopUpPanel } from './pop-up/PopUpPanel';
import { Page } from '@distribute/shared/types';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { FC, useEffect } from 'react';
import { CtaPanel } from './cta/CtaPanel';
import { SqueezePagePanel } from './squeeze-page/SqueezePagePanel';

type Props = {
  currentPageOrTemplate: Page | TemplateExtended;
};

export const ConversionsTab: FC<Props> = ({ currentPageOrTemplate }) => {
  const dispatch = useDispatch();

  const activeConversionPanel = useSelector(
    editorLeftSidebarModel.selectors.selectActiveConversionPanel
  );

  const isAlertBarPanelOpen =
    activeConversionPanel === ActiveConversionPanel.ALERT_BAR;
  const isGatedContentPanelOpen =
    activeConversionPanel === ActiveConversionPanel.GATED_CONTENT;
  const isPopUpPanelOpen =
    activeConversionPanel === ActiveConversionPanel.POP_UP;
  const isCTAPanelOpen = activeConversionPanel === ActiveConversionPanel.CTA;
  const isSqueezePagePanelOpen =
    activeConversionPanel === ActiveConversionPanel.SQUEEZE_PAGE;

  useEffect(() => {
    return () => {
      dispatch(editorLeftSidebarModel.actions.setActiveConversionPanel(null));
    };
  }, [dispatch]);

  return (
    <div className="w-120">
      {!activeConversionPanel ? (
        <SidebarTabLayout>
          <EditorSidebarConversions />
        </SidebarTabLayout>
      ) : (
        <>
          {isAlertBarPanelOpen && <AlertBarPanel />}
          {isGatedContentPanelOpen && <GatedContentPanel />}
          {isPopUpPanelOpen && (
            <PopUpPanel currentPage={currentPageOrTemplate} />
          )}
          {isCTAPanelOpen && <CtaPanel currentPage={currentPageOrTemplate} />}
          {isSqueezePagePanelOpen && (
            <SqueezePagePanel currentPage={currentPageOrTemplate} />
          )}
        </>
      )}
    </div>
  );
};
