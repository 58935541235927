import React from 'react';
import { PageBrandingOptions } from './PageBrandingOptions';
import { CompanySelectInput } from './CompanySelectInput';
import { ClearbitCompanyInfo } from '../../../../../../shared/api';
import { Input } from '../../../../../../shared/ui';
import { useFormContext } from 'react-hook-form';
import { CreatePageFormSchemaType } from '../../../../config/types';
import { useSelector } from 'react-redux';
import { teamsModel } from '../../../../../../features/teams';
import { getBrandedPageName } from '../../../../../../shared/lib/getBrandedPageName';

export const PageAppearance: React.FC = () => {
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const { watch, setValue, register } =
    useFormContext<CreatePageFormSchemaType>();

  const isBranded = watch('isBranded');
  const brandCompany = watch('brandCompany');

  const selectCompany = (company: ClearbitCompanyInfo) => {
    setValue('brandCompany', company);
    setValue(
      'pageTitle',
      getBrandedPageName(currentTeam.brandCompanyName, company)
    );
  };

  const deleteCompany = () => {
    setValue('brandCompany', null);
    setValue('pageTitle', '');
  };

  return (
    <div className="flex flex-col gap-y-5">
      <PageBrandingOptions />
      {isBranded && (
        <CompanySelectInput
          handleDelete={deleteCompany}
          handleSelect={selectCompany}
          selectedCompany={brandCompany}
        />
      )}
      {(!isBranded || (isBranded && brandCompany)) && (
        <Input
          {...register('pageTitle')}
          type="text"
          placeholder="Untitled"
          label="Page title"
          heightSize="md"
        />
      )}
    </div>
  );
};
