export enum MeetingsVisibility {
  SHARE_ALL = 'share-all',
  SHARE_EXTERNAL = 'share-external',
  SHARE_INTERNAL = 'share-internal',
  SHARE_NONE = 'share-none',
}

export enum MeetingRecordingConsent {
  NO_NOTIFICATION = 'no-notification',
  NOTIFY_EXTERNAL = 'notify-external',
  ALWAYS_NOTIFY = 'always-notify',
}

export type TeamCallSettings = {
  id: string;
  teamId: number;
  meetingsVisibility: MeetingsVisibility;
  recordingConsent: MeetingRecordingConsent;
  limitToSingleBot: boolean;
  createdAt: Date;
  updatedAt: Date;
};
