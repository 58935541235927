import { getQueryParams } from '../../../../shared/lib';
import {
  callbackOAuthStatusQueryKey,
  CallBackOAuthStatus,
} from '@distribute/shared/api-types/call-recorder';
import { call, put, race, take } from 'redux-saga/effects';
import { callRecordingSettingsModel } from '../index';
import { history } from '../../../../entities/history';
import { connectToGoogleCalendar } from './connectToGoogleCalendar';
import { createNotification, snackbarModel } from '../../../snackbar';

const calendarsQueries: Record<string, any> = {
  [callbackOAuthStatusQueryKey]: {
    [CallBackOAuthStatus.GOOGLE_FAILED]: function* () {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', 'Failed to connect google calendar')
        )
      );
    },
    [CallBackOAuthStatus.GOOGLE_EMAIL_MISMATCH]: function* () {
      const shouldReconnect: boolean = yield call(
        proceedWithEmailMismatchStatus
      );
      if (shouldReconnect) {
        yield call(connectToGoogleCalendar);
      }
    },
  },
  // TODO: Add for Ms calendar
};

export function* watchCalendarConnectionStatus() {
  const query = getQueryParams();
  const queryKey =
    Array.from(query.keys()).find((q) => calendarsQueries[q]) ?? '';
  const queryValue = query.get(queryKey) ?? '';
  const handler = calendarsQueries[queryKey]?.[queryValue];

  if (!handler) {
    return;
  }

  yield call(handler, queryKey);
}

function* proceedWithEmailMismatchStatus() {
  yield put(callRecordingSettingsModel.actions.setIsMismatchModalOpen(true));

  const { close } = yield race({
    close: take(callRecordingSettingsModel.actions.setIsMismatchModalOpen),
    reconnect: take(callRecordingSettingsModel.actions.tryToReconnect),
  });

  if (close) {
    const { pathname } = history.location;
    yield call(history.replace.bind(null, pathname));
    return false;
  }

  return true;
}
