import Gleap from 'gleap';
import {
  Container,
  Description,
  Image,
  List,
  ListItem,
  OtherTypeWrapper,
  ProTip,
  ProTipButton,
  ProTipLink,
  Title,
} from './components';
import { IconMap } from '../../../../../shared/sprite';
import { Icon } from '../../../../../shared/ui';
import { useFormContext } from 'react-hook-form';
import { CreatePageFormSchemaType } from '../../../config/types';
import { APP_URL } from '../../../../../shared/config';

type Props = {
  isVisible: boolean;
};

export const SelectPageType: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/select-page-type.svg" />
      <Title>Select type of page</Title>
      <Description className="mb-4">
        We have created a few of the most popular page types to help you get
        started quickly.
      </Description>
      <ProTip title="Can't find page you need?">
        Use the "Other" option instead. You can then describe the page you need,
        including what sections it should have, etc. Don't want to have to do
        this every time?{' '}
        <ProTipButton handleClick={() => Gleap.openFeatureRequests()}>
          Contact us
        </ProTipButton>{' '}
        We will add it to the default distribution settings.
      </ProTip>
    </Container>
  );
};

export const BusinessCase: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/business-case-page-type.svg" />
      <Title>Business case</Title>
      <Description>
        Use page to justify and secure approval for new sales initiatives by
        highlighting objectives and benefits.
      </Description>
    </Container>
  );
};

export const CallFollowUp: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/call-follow-up-page-type.svg" />
      <Title>Call follow-up</Title>
      <Description className="mb-4">
        After your calls, use this page to summarize all the key points and
        describe the next steps.
      </Description>
      <ProTip title="Works best with Gong integration.">
        Simply connect Gong and access all of your call transcripts directly on
        the Create page with an AI interface.{' '}
        <ProTipLink
          // TODO: Change tp Team Integrations page after Salesforce integration is done
          linkHref={`${APP_URL}/team-settings/info`}
        >
          Learn more
        </ProTipLink>
      </ProTip>
    </Container>
  );
};

export const BattleCard: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/battle-card-page-type.svg" />
      <Title>Battle card</Title>
      <Description>
        Use when you need quick, concise information to handle competitive
        situations to highlight your benefits.
      </Description>
    </Container>
  );
};

export const MutualActionPlan: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/mutual-plan-page-type.svg" />
      <Title>Mutual action plan</Title>
      <Description>
        Use to outline and agree on steps, responsibilities, and timelines with
        a prospect.
      </Description>
    </Container>
  );
};

export const CustomerOnboarding: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/customer-onboarding-page-type.svg" />
      <Title>Customer onboarding</Title>
      <Description>
        Use to guide new customers through the initial setup and integration
        process of your product.
      </Description>
    </Container>
  );
};

export const QuarterlyBusinessReview: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/qbr-page-type.svg" />
      <Title>Quarterly Business Review</Title>
      <Description>
        Use to review the past quarter's performance with key clients, discuss
        challenges and set new goals.
      </Description>
    </Container>
  );
};

export const Other: React.FC<Props> = ({ isVisible }) => {
  const { setValue } = useFormContext<CreatePageFormSchemaType>();

  const handleClick = (text: string) => {
    setValue('pageTypeDescription', text);
  };

  return (
    <Container isVisible={isVisible}>
      <Title className="mt-10">Your page type</Title>
      <Description>
        Please explain the page you want to create or use some of{' '}
        <Icon
          glyph={IconMap.SparkleSmall}
          className="text-gray-600 inline-block"
        />
        <span className="font-semibold"> example prompts</span> as inspiration:
      </Description>
      <div className="flex flex-col gap-4 mt-6 overflow-y-scroll mb-10 pb-16 scrollbar-hide">
        <OtherTypeWrapper
          handleClick={handleClick}
          text="Craft a vibrant launch page for our latest software tool, highlighting sections such as: Introduction to the New Tool, Key Features and Benefits, Exclusive Launch Offers, Customer Testimonials, Detailed Pricing Plans, FAQs, and How to Get Started. Employ a tone that's energetic and confident, reassuring potential customers of the value and support they will receive."
        />
        <OtherTypeWrapper
          handleClick={handleClick}
          text="Design an elegant landing page for a luxury real estate property that includes: Property Overview, Stunning Visuals and Virtual Tour, Unique Selling Points, Neighborhood Insights, Investment Potential, Transparent Pricing Details, and a Personalized Consultation Call to Action. Maintain a sophisticated and inviting tone throughout the page."
        />
        <OtherTypeWrapper
          handleClick={handleClick}
          text="Create an enticing subscription page for our gourmet food box service with sections like: Mouth-watering Introduction, How It Works, Subscription Box Options, Customization Features, Special Introductory Offer, Real Customer Reviews, Subscription Pricing, Gift Options, and Sign-up Procedure. Use a warm and conversational tone, making readers feel like part of a food-loving community."
        />
        <OtherTypeWrapper
          handleClick={handleClick}
          text="Develop a compelling special offers page for our cybersecurity services, outlining areas such as: Special Offer Intro, Why You Need Enhanced Cybersecurity, Service Tier Comparison, Limited-Time Deals, Client Success Stories, Easy Upgrade Options, and Secure Sign-Up Process. Ensure the tone is urgent yet professional, highlighting the critical nature of cybersecurity."
        />
        <OtherTypeWrapper
          handleClick={handleClick}
          text="Assemble a persuasive membership benefits page for our professional association that includes segments like: Welcome to Our Community, Membership Levels Explained, A Comprehensive List of Member Benefits, Exclusive Member Resources, Networking Opportunities, Membership Fees, Testimonials from Current Members, and Instructions on How to Join. The tone should be inclusive, professional, and encouraging."
        />

        <img
          src="/assets/images/other-type-shadow.png"
          className="w-full max-w-130 absolute z-30 bottom-2 left-1/2 -translate-x-1/2 pointer-events-none"
          alt={''}
        />
        <img
          src="/assets/images/other-type-shadow.png"
          className="w-full max-w-130 absolute z-30 bottom-0 left-1/2 -translate-x-1/2 pointer-events-none"
          alt={''}
        />
      </div>
    </Container>
  );
};

export const TextSource: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/text-source.svg" />
      <Title>Text source</Title>
      <Description>
        Input all specific information related to the page you want to create,
        such as:
      </Description>
      <List className="mb-4">
        <ListItem>Call transcripts for follow-up messages</ListItem>
        <ListItem>Information about your company and products</ListItem>
        <ListItem>Offer details, etc.</ListItem>
      </List>
      <ProTip title="Fill your brand knowledge base">
        Store the recurring company information under{' '}
        <ProTipLink linkHref={`${APP_URL}/team-settings/brand`}>
          Brand Information
        </ProTipLink>{' '}
        in Team Settings for the AI to utilize each time a page is created.
      </ProTip>
    </Container>
  );
};

export const FileSource: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/file-source.svg" />
      <Title>File source</Title>
      <Description>
        Upload all specific information related to the page you want to create,
        such as:
      </Description>
      <List className="mb-4">
        <ListItem>Call transcripts for follow-up messages</ListItem>
        <ListItem>Information about your company and products</ListItem>
        <ListItem>Offer details, etc.</ListItem>
      </List>
      <ProTip title="Fill your brand knowledge base">
        Store the recurring company information under{' '}
        <ProTipLink linkHref={`${APP_URL}/team-settings/brand`}>
          Brand Information
        </ProTipLink>{' '}
        in Team Settings for the AI to utilize each time a page is created.
      </ProTip>
    </Container>
  );
};

export const GongSource: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/gong-call-source.svg" />
      <Title>Gong call</Title>
      <Description>
        Select person you had call(s) with to help AI create a perfect page
        according to your transcript(s).
      </Description>
      <List className="mb-4">
        <ListItem>Use all actual calls from a specific period</ListItem>
        <ListItem>
          or select specific calls from all the calls you both had
        </ListItem>
      </List>
      <ProTip title="Fill your brand knowledge base">
        Store the recurring company information under{' '}
        <ProTipLink linkHref={`${APP_URL}/team-settings/brand`}>
          Brand Information
        </ProTipLink>{' '}
        in Team Settings for the AI to utilize each time a page is created.
      </ProTip>
    </Container>
  );
};

export const LinkSource: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/link-source.svg" />
      <Title>Link source</Title>
      <Description>
        Input link to all specific information related to the page you want to
        create, such as:
      </Description>
      <List className="mb-4">
        <ListItem>Call transcripts for follow-up messages</ListItem>
        <ListItem>Information about your company and products</ListItem>
        <ListItem>Offer details, etc.</ListItem>
      </List>
      <ProTip title="Fill your brand knowledge base">
        Store the recurring company information under{' '}
        <ProTipLink linkHref={`${APP_URL}/team-settings/brand`}>
          Brand Information
        </ProTipLink>{' '}
        in Team Settings for the AI to utilize each time a page is created.
      </ProTip>
    </Container>
  );
};

export const CallRecordingSource: React.FC<Props> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <Image src="/assets/images/call-recording-source.svg" />
      <Title>Call Recording</Title>
      <Description>
        Select specific call(s) to help AI create a perfect page according to
        your transcript(s).
      </Description>
      <List className="mb-4 mt-1 text-center list-inside">
        <ListItem>
          Use actual calls you did with Distribute Call Recorder
        </ListItem>
      </List>
      <ProTip title="Fill your brand knowledge base">
        Store the recurring company information under{' '}
        <ProTipLink linkHref={`${APP_URL}/team-settings/brand`}>
          Brand Information
        </ProTipLink>{' '}
        in Team Settings for the AI to utilize each time a page is created.
      </ProTip>
    </Container>
  );
};
