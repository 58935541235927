import { Icon, SpriteGlyph } from '../../../../../../shared/ui/Icon';
import React from 'react';
import cn from 'classnames';

type Props = {
  isActive: boolean;
  title: string;
  icon: SpriteGlyph;
  handleSelect: () => void;
};

export const SelectCard: React.FC<Props> = ({
  isActive,
  title,
  icon,
  handleSelect,
}) => {
  return (
    <button
      onClick={handleSelect}
      className={cn(
        'h-13 px-3 border border-gray-200 rounded-2.5 flex grow-1 gap-2 items-center',
        { 'border-primary-600': isActive }
      )}
    >
      <div
        className={cn(
          'w-7 h-7 rounded-md bg-gray-100 flex items-center justify-center',
          { 'bg-primary-50': isActive }
        )}
      >
        <Icon
          glyph={icon}
          width={20}
          className={cn('text-gray-500', { 'text-primary-600': isActive })}
        />
      </div>
      <p className="font-medium text-gray-700">{title}</p>
    </button>
  );
};
