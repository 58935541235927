import { cn } from '@distribute/frontend/utils';

export const Title = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <h2
      className={cn('text-base font-semibold text-gray-900 mb-2', className)}
      {...props}
    >
      {children}
    </h2>
  );
};
