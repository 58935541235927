import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { EditorSidebarConversionsPanelFooter } from './EditorSidebarConversionsPanelFooter';
import { Icon, ImageInput, Input, TabsGroup } from '../../../../../shared/ui';
import { POP_UP_TABS } from '../../../lib';
import { conversionKitModel } from '../../../../../features/conversion-kit';
import { boolean, object, string } from 'yup';
import {
  ActionTypeAlertBar,
  ActionTypeCTA,
  ActionTypePopUp,
  CalendarProvider,
  Page,
} from '@distribute/shared/types';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LabelToggle } from '../../../../../shared/ui/LabelToggle';
import { IconMap } from '../../../../../shared/sprite';
import { Label } from './Label';

import cn from 'classnames';
import { BehaviorSettingsItem } from './BehaviorSettingsItem';
import { CalendarForm } from './CalendarForm';
import { PopupTitleAndDescriptionFields } from './PopupTitleAndDescriptionFields';
import {
  INVALID_LINK_MESSAGE,
  LINK_VERIFICATION_REGEX,
} from '../../../../../shared/constants';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { urlWithProtocol } from '@distribute/shared/utils';

export const TITLE_MAX_LENGTH = 60;
const BUTTON_LABEL_MAX_LENGTH = 40;

export type ConversionPopup = CalendarForm & {
  imageUrl: string;
  title: string;
  actionType: ActionTypePopUp;
  isFormName: boolean;
  isFormPhone: boolean;
  isFormMessage: boolean;
  buttonLabelFormType: string;
  buttonLabelLinkType: string;
  buttonUrl: string;
  isTriggerTime: boolean;
  triggerTimeDelay: string;
  triggerTimeVisits: string;
  isTriggerExit: boolean;
};

type Props = {
  currentPage: Page | TemplateExtended;
};

export const EditorSidebarPopUpPanel: React.FC<Props> = ({ currentPage }) => {
  const dispatch = useDispatch();
  const selectedPopupSection = useSelector(
    editorSidebarModel.selectors.selectSelectedPopupSection
  );

  const setSelectedPopupSection = (value: 'content' | 'triggers') => {
    dispatch(editorSidebarModel.actions.setSelectedPopupSection(value));
  };

  const handleClose = () => {
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
    dispatch(conversionKitModel.actions.setPopUpEdited(undefined));
    setSelectedPopupSection('content');
  };

  const popUp = useSelector(conversionKitModel.selectors.selectPopUpWithError);
  const alertBar = useSelector(
    conversionKitModel.selectors.selectAlertBarWithError
  );
  const cta = useSelector(conversionKitModel.selectors.selectCTAWithError);
  const updatePopUpIsLoading = useSelector(
    conversionKitModel.selectors.selectUpdatePopUpIsLoading
  );
  const [activeTab, setActiveTab] = useState<string>(popUp.actionType);
  const [description, setDescription] = useState(popUp.description ?? '');
  const [isImageEnabled, setImageEnabled] = useState(!!popUp.imageUrl);
  const [isDescriptionEnabled, setDescriptionEnabled] = useState(
    popUp.description !== null
  );
  const [isTitleEnabled, setIsTitleEnabled] = useState(
    popUp.actionType === ActionTypePopUp.CALENDLY ? popUp.title !== null : true
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        title: string().when([], {
          is: () =>
            isTitleEnabled &&
            (activeTab === ActionTypePopUp.FORM ||
              activeTab === ActionTypePopUp.LINK ||
              activeTab === ActionTypePopUp.CALENDLY),
          then: string().max(TITLE_MAX_LENGTH).required(),
          otherwise: string().nullable(),
        }),
        isFormName: boolean(),
        isFormPhone: boolean(),
        isFormMessage: boolean(),
        buttonLabelFormType: string().when([], {
          is: () => activeTab === ActionTypePopUp.FORM,
          then: string().max(BUTTON_LABEL_MAX_LENGTH),
          otherwise: string().nullable(),
        }),
        buttonLabelLinkType: string().when([], {
          is: () => activeTab === ActionTypePopUp.LINK,
          then: string().max(BUTTON_LABEL_MAX_LENGTH),
          otherwise: string().nullable(),
        }),
        buttonUrl: string().when([], {
          is: () => activeTab === ActionTypePopUp.LINK,
          then: string()
            .matches(LINK_VERIFICATION_REGEX, INVALID_LINK_MESSAGE)
            .transform((_, val) => urlWithProtocol(val))
            .required(),
          otherwise: string().nullable(),
        }),
        isTriggerTime: boolean(),
        triggerTimeVisits: string().when([], {
          is: () =>
            activeTab === ActionTypePopUp.FORM ||
            activeTab === ActionTypePopUp.LINK,
          then: string().required(),
          otherwise: string().nullable(),
        }),
        triggerTimeDelay: string().when([], {
          is: () =>
            activeTab === ActionTypePopUp.FORM ||
            activeTab === ActionTypePopUp.LINK,
          then: string().required(),
          otherwise: string().nullable(),
        }),
        isTriggerExit: boolean(),
        calendarSchedulingLink: string().when(
          ['calendarProvider'],
          (calendarProvider) => {
            if (activeTab !== ActionTypePopUp.CALENDLY) {
              return string().nullable();
            }

            if (calendarProvider === CalendarProvider.CHILI_PIPER) {
              return string()
                .url()
                .matches(/^https:\/\/.+\.chilipiper\.com\/book\/.*/)
                .required();
            }

            if (calendarProvider === CalendarProvider.CALENDLY) {
              return string()
                .url()
                .matches(/^https:\/\/calendly\.com\/.*/)
                .required();
            }

            return string().url().required();
          }
        ),
      }),
    [activeTab, isTitleEnabled]
  );

  const [actionType, setActionType] = useState<ActionTypePopUp>(
    popUp.actionType
  );
  const isUploading = useSelector(
    conversionKitModel.selectors.selectIsUploadImageLoading
  );
  const uploadProgress = useSelector(
    conversionKitModel.selectors.selectUploadingPopUpImageProgress
  );
  const isRemoving = useSelector(
    conversionKitModel.selectors.selectIsRemovePopUpImageLoading
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
  } = useForm<ConversionPopup>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({
        title: popUp.title ?? '',
        isFormName: popUp.isFormName,
        isFormPhone: popUp.isFormPhone,
        isFormMessage: popUp.isFormMessage,
        buttonLabelFormType: popUp.buttonLabelFormType,
        buttonLabelLinkType: popUp.buttonLabelLinkType,
        buttonUrl: popUp.buttonUrl,
        isTriggerTime: popUp.isTriggerTime,
        triggerTimeVisits: popUp.triggerTimeVisits,
        triggerTimeDelay: popUp.triggerTimeDelay,
        isTriggerExit: popUp.isTriggerExit,
        calendarProvider: popUp.calendarProvider ?? CalendarProvider.CALENDLY,
        calendarSchedulingLink: popUp.calendarSchedulingLink ?? '',
        calendarIsShowCalendlyPageDetails:
          popUp.calendarIsShowCalendlyPageDetails,
        calendarIsShowCalendlyCookieBanner:
          popUp.calendarIsShowCalendlyCookieBanner,
        calendarCalendlyTextColor: popUp.calendarCalendlyTextColor,
        calendarCalendlyButtonColor: popUp.calendarCalendlyButtonColor,
        calendarCalendlyBgColor: popUp.calendarCalendlyBgColor,
      }),
      [popUp]
    ),
  });

  const {
    title,
    buttonLabelFormType,
    buttonLabelLinkType,
    buttonUrl,
    calendarProvider,
    calendarSchedulingLink,
    calendarIsShowCalendlyPageDetails,
    calendarIsShowCalendlyCookieBanner,
    calendarCalendlyTextColor,
    calendarCalendlyButtonColor,
    calendarCalendlyBgColor,
  } = watch();

  useEffect(() => {
    switch (activeTab) {
      case ActionTypePopUp.FORM:
        setActionType(ActionTypePopUp.FORM);
        break;
      case ActionTypePopUp.LINK:
        setActionType(ActionTypePopUp.LINK);
        break;
      case ActionTypePopUp.CALENDLY:
        setActionType(ActionTypePopUp.CALENDLY);
        break;
    }
  }, [activeTab]);

  const {
    field: { value: isFormName, onChange: onChangeFullName },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormName',
  });

  const {
    field: { value: isFormPhone, onChange: onChangePhone },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormPhone',
  });

  const {
    field: { value: isFormMessage, onChange: onChangeMessage },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormMessage',
  });

  const {
    field: { value: isTriggerTime, onChange: onChangeTimeDelay },
  } = useController({
    control,
    defaultValue: false,
    name: 'isTriggerTime',
  });

  const { field: triggerTimeDelay } = useController({
    control,
    defaultValue: '0',
    name: 'triggerTimeDelay',
  });

  const { field: triggerTimeVisitsType } = useController({
    control,
    defaultValue: '0',
    name: 'triggerTimeVisits',
  });

  const {
    field: { value: isTriggerExit, onChange: onChangeExitIntent },
  } = useController({
    control,
    defaultValue: false,
    name: 'isTriggerExit',
  });

  const {
    field: { onChange: changeButtonLabelFormType },
  } = useController({
    control,
    name: 'buttonLabelFormType',
  });

  const {
    field: { onChange: changeButtonLabelLinkType },
  } = useController({
    control,
    name: 'buttonLabelLinkType',
  });

  const {
    field: { onChange: changeTitle },
  } = useController({
    control,
    name: 'title',
  });

  const handleButtonLabelChange = (value: string) => {
    changeButtonLabelFormType(value);
    changeButtonLabelLinkType(value);
  };

  useEffect(() => {
    dispatch(
      conversionKitModel.actions.setPopUpEdited({
        ...popUp,
        title: isTitleEnabled ? title : null,
        description: isDescriptionEnabled ? description : null,
        imageUrl: isImageEnabled ? popUp.imageUrl : undefined,
        actionType,
        isFormName,
        isFormPhone,
        isFormMessage,
        buttonLabelFormType,
        buttonLabelLinkType,
        buttonUrl,
        calendarProvider,
        calendarSchedulingLink,
        calendarIsShowCalendlyPageDetails,
        calendarIsShowCalendlyCookieBanner,
        calendarCalendlyTextColor,
        calendarCalendlyButtonColor,
        calendarCalendlyBgColor,
      })
    );
  }, [
    title,
    description,
    actionType,
    isFormName,
    isFormPhone,
    isFormMessage,
    buttonLabelFormType,
    buttonLabelLinkType,
    buttonUrl,
    calendarProvider,
    calendarSchedulingLink,
    calendarIsShowCalendlyPageDetails,
    calendarIsShowCalendlyCookieBanner,
    calendarCalendlyTextColor,
    calendarCalendlyButtonColor,
    calendarCalendlyBgColor,
    popUp,
    isDescriptionEnabled,
    isTitleEnabled,
    isImageEnabled,
    dispatch,
  ]);

  const onChangeTab = useCallback(
    (tab: string) => {
      clearErrors();
      setActiveTab(tab);
      setIsTitleEnabled(tab === ActionTypePopUp.CALENDLY ? !!title : true);
    },
    [clearErrors, title]
  );

  const handleFormSubmit = (data: ConversionPopup) => {
    dispatch(
      conversionKitModel.actions.updatePopUp({
        documentContentId: currentPage.content.id,
        isRemoveImage: !isImageEnabled && !!popUp.imageUrl,
        ...data,
        actionType,
        title: isTitleEnabled ? title : null,
        description: isDescriptionEnabled ? description : null,
        callback: handleClose,
        isActive: true,
        buttonLabelFormType: data.buttonLabelFormType || 'Submit',
        buttonLabelLinkType: data.buttonLabelLinkType || 'Submit',
        calendarSchedulingLink:
          activeTab === ActionTypePopUp.CALENDLY
            ? data.calendarSchedulingLink
            : undefined,
      })
    );
  };

  const onUpdateImage = useCallback(
    (image: File) => {
      dispatch(
        conversionKitModel.actions.changePopUpImage({
          documentContentId: currentPage.content.id,
          image,
        })
      );
    },
    [currentPage, dispatch]
  );

  const onRemoveImage = useCallback(() => {
    dispatch(
      conversionKitModel.actions.removePopUpImage({
        documentContentId: currentPage.content.id,
      })
    );
  }, [currentPage, dispatch]);

  return (
    <>
      <header className="h-36 bg-base-white pt-6 px-4 border-b border-gray-200">
        <div
          className="flex gap-2 text-gray-600 text-sm font-semibold cursor-pointer mb-4"
          onClick={handleClose}
        >
          <Icon glyph={IconMap.ArrowLeft} width={20} />
          <span>Back</span>
        </div>
        <h2 className="text-2xl font-display font-medium text-gray-900">
          Pop-Up
        </h2>
        <div className="flex gap-4 mt-5 text-sm font-semibold text-gray-500">
          <div
            className={cn('h-8 cursor-pointer px-1', {
              'border-b-2 border-primary-700 text-primary-700':
                selectedPopupSection === 'content',
            })}
            onClick={() => setSelectedPopupSection('content')}
          >
            Content
          </div>
          <div
            className={cn('h-8 cursor-pointer px-1', {
              'border-b-2 border-primary-700 text-primary-700':
                selectedPopupSection === 'triggers',
            })}
            onClick={() => setSelectedPopupSection('triggers')}
          >
            Triggers
          </div>
        </div>
      </header>
      <div className="overflow-y-auto h-[calc(100%-220px)]">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="py-6 px-4">
            {selectedPopupSection === 'content' && (
              <>
                <p className="text-gray-800 font-semibold mb-4">Type</p>
                <TabsGroup
                  active={activeTab}
                  setActive={onChangeTab}
                  options={POP_UP_TABS}
                  className="!w-full"
                  optionClassName="flex-1"
                />
                {activeTab !== ActionTypePopUp.CALENDLY && (
                  <>
                    <hr className="border-gray-200 my-6" />
                    <p className="text-gray-800 font-semibold mb-4">
                      Components
                    </p>
                    <LabelToggle
                      icon={IconMap.Image01}
                      text="Image"
                      checked={isImageEnabled}
                      onChange={() => setImageEnabled((prev) => !prev)}
                      className="mb-3"
                    />
                    {isImageEnabled && (
                      <ImageInput
                        imageUrl={popUp.imageUrl}
                        isUploading={isUploading}
                        uploadProgress={uploadProgress}
                        isRemoving={isRemoving}
                        onUpdateImage={onUpdateImage}
                        onRemoveImage={onRemoveImage}
                        isLabel={false}
                        fileInputStyles="h-31"
                        className="mb-6"
                      />
                    )}
                    <PopupTitleAndDescriptionFields
                      description={description}
                      title={title}
                      errors={errors}
                      isDescriptionEnabled={isDescriptionEnabled}
                      isTitleEnabled={isTitleEnabled}
                      isTitleToggleDisabled={
                        actionType !== ActionTypePopUp.CALENDLY
                      }
                      onChangeDescription={(content) => setDescription(content)}
                      onChangeDescriptionEnabled={() =>
                        setDescriptionEnabled((prev) => !prev)
                      }
                      onChangeTitleEnabled={() =>
                        setIsTitleEnabled((prev) => !prev)
                      }
                      onChangeTitle={changeTitle}
                    />
                    <hr className="border-gray-200 my-6" />
                  </>
                )}
                {activeTab === ActionTypePopUp.FORM && (
                  <>
                    <div>
                      <p className="text-gray-800 font-semibold mb-4">
                        Form Fields
                      </p>
                      <LabelToggle
                        icon={IconMap.User03}
                        text="Name"
                        checked={isFormName}
                        onChange={onChangeFullName}
                        className="mb-3"
                      />
                      <LabelToggle
                        icon={IconMap.EmailSymbol}
                        text="Email"
                        checked={true}
                        onChange={() => null}
                        disabled
                        className="mb-3"
                      />
                      <LabelToggle
                        icon={IconMap.Phone}
                        text="Phone"
                        checked={isFormPhone}
                        onChange={onChangePhone}
                        className="mb-3"
                      />
                      <LabelToggle
                        icon={IconMap.MessageDotsCircle}
                        text="Message"
                        checked={isFormMessage}
                        onChange={onChangeMessage}
                      />
                      <hr className="border-gray-200 my-6" />
                    </div>
                    <p className="text-gray-800 font-semibold mb-4">Button</p>
                    <Label
                      icon={IconMap.TypeSquare}
                      text="Label"
                      className="mb-3"
                    />
                    <Input
                      maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                      className="p-3"
                      type="text"
                      placeholder="Submit"
                      {...register('buttonLabelFormType')}
                      isError={!!errors.buttonLabelFormType}
                      value={buttonLabelFormType}
                      heightSize="md"
                      onChange={({ target: { value } }) =>
                        handleButtonLabelChange(value)
                      }
                    />
                  </>
                )}
                {activeTab === ActionTypePopUp.LINK && (
                  <>
                    <Label
                      icon={IconMap.TypeSquare}
                      text="Label"
                      className="mb-3"
                    />
                    <Input
                      maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                      className="p-3"
                      type="text"
                      placeholder="Submit"
                      {...register('buttonLabelLinkType')}
                      isError={!!errors.buttonLabelLinkType}
                      value={buttonLabelLinkType}
                      onChange={({ target: { value } }) =>
                        handleButtonLabelChange(value)
                      }
                    />
                    <Label
                      icon={IconMap.Link}
                      text="URL"
                      className="mt-4 mb-3"
                      isRequired
                    />
                    <Input
                      className="p-3"
                      type="text"
                      placeholder="e.g. https://example.com/link"
                      isError={!!errors.buttonUrl}
                      messageText={errors.buttonUrl?.message}
                      {...register('buttonUrl')}
                    />
                  </>
                )}
                {activeTab === ActionTypePopUp.CALENDLY && (
                  <CalendarForm
                    source="popup"
                    control={control}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    popupTitleAndDescriptionFields={
                      <PopupTitleAndDescriptionFields
                        description={description}
                        title={title}
                        errors={errors}
                        isDescriptionEnabled={isDescriptionEnabled}
                        isTitleEnabled={isTitleEnabled}
                        isTitleToggleDisabled={
                          actionType !== ActionTypePopUp.CALENDLY
                        }
                        onChangeDescription={(content) =>
                          setDescription(content)
                        }
                        onChangeDescriptionEnabled={() =>
                          setDescriptionEnabled((prev) => !prev)
                        }
                        onChangeTitleEnabled={() =>
                          setIsTitleEnabled((prev) => !prev)
                        }
                        onChangeTitle={changeTitle}
                      />
                    }
                  />
                )}
              </>
            )}
            {selectedPopupSection === 'triggers' && (
              <>
                {alertBar.isActive &&
                  alertBar.actionType === ActionTypeAlertBar.POPUP && (
                    <BehaviorSettingsItem
                      checked
                      isDisabled
                      content={false}
                      icon={IconMap.CursorClick02}
                      title="Alert Bar CTA"
                      text="Upon clicking the Alert Bar CTA, this pop-up will be activated for the user."
                      setChecked={() => null}
                    />
                  )}
                {cta.isActive && cta.actionType === ActionTypeCTA.POPUP && (
                  <BehaviorSettingsItem
                    checked
                    isDisabled
                    content={false}
                    icon={IconMap.CursorClick02}
                    title="CTA Section"
                    text="Upon clicking the CTA Section button, this pop-up will be activated for the user."
                    setChecked={() => null}
                  />
                )}
                <BehaviorSettingsItem
                  title="Time"
                  checked={isTriggerTime}
                  setChecked={onChangeTimeDelay}
                  text="Set your pop-up to appear after a certain amount of time has elapsed or a certain number of page views have been reached."
                  content={true}
                  icon={IconMap.ClockStopwatch}
                >
                  <Input
                    labelInsideInput="seconds"
                    labelInsideInputRightSide
                    label="Delay before opening"
                    {...triggerTimeDelay}
                    type="number"
                    messageText={
                      !errors.triggerTimeDelay
                        ? 'Set 0 to open immediately'
                        : 'This field can’t be empty'
                    }
                    min={0}
                    isError={!!errors.triggerTimeDelay}
                  />
                  <Input
                    labelInsideInput="visits"
                    labelInsideInputRightSide
                    label="Page visits before opening"
                    {...triggerTimeVisitsType}
                    type="number"
                    messageText={
                      !errors.triggerTimeVisits
                        ? 'Set 0 to show on every visit'
                        : 'This field can’t be empty'
                    }
                    min={0}
                    isError={!!errors.triggerTimeVisits}
                  />
                </BehaviorSettingsItem>
                <BehaviorSettingsItem
                  title="Exit Intent"
                  checked={isTriggerExit}
                  setChecked={onChangeExitIntent}
                  text="Set an exit pop-up to display when visitors move their mouse in a way that indicates they are about to exit the page."
                  content={false}
                  icon={IconMap.LogOut02}
                />
              </>
            )}
          </div>
          <EditorSidebarConversionsPanelFooter
            isAddBtnLoading={updatePopUpIsLoading}
            addBtnTitle={popUp.buttonLabelFormType === null ? 'Add' : 'Save'}
            onClose={handleClose}
          ></EditorSidebarConversionsPanelFooter>
        </form>
      </div>
    </>
  );
};
