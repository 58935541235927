import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import classNames from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Popover from '@radix-ui/react-popover';
import type { DocumentContentItem } from '@distribute/shared/types';
import { Icon, Toggle } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { Input } from '../../../../shared/ui';
import { useTiptapEditor } from '../../../../entities/tiptap-editor';
import { checkIsEmptyContent } from '../../../../entities/tiptap-editor';
import { useDispatch } from 'react-redux';
import { pageCreationFlowModel } from '../../../../processes/page-creation-flow';

enum TabMenuType {
  Separator = 'separator',
  MenuItem = 'menu-item',
  Checkbox = 'checkbox',
}

type TabMenuItem = {
  name: string;
  key: string;
  icon: React.ReactNode | string;
  type: TabMenuType.MenuItem;
  isHidden?: boolean;
  action?: () => void;
};

type TabMenuCheckbox = {
  name: string;
  type: TabMenuType.Checkbox;
  action: (isChecked: boolean) => void;
  key: string;
  icon: React.ReactNode;
  isHidden?: boolean;
  isChecked: boolean;
  isDisabled?: boolean;
};

type TabMenuSeparator = {
  key: string;
  type: TabMenuType.Separator;
  isHidden?: boolean;
};

type TabMenu = TabMenuItem | TabMenuCheckbox | TabMenuSeparator;

type EditorTabContentProps = {
  disabled?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  isSelected: boolean;
  tab: DocumentContentItem;
  canDelete?: boolean;
  canSetVisibility?: boolean;
  onTabSelect: (tab: DocumentContentItem, isOpen?: boolean) => void;
  onNameUpdate: (data: { id: number; name: string }) => void;
  onTabDelete: (id: number) => void;
  onTabDuplicate: (id: number) => void;
  onVisibleUpdate: (data: { id: number; isVisible: boolean }) => void;
  onLoadFromTemplate: () => void;
  onSaveAsTemplate: (id: number) => void;
  onToggleDropdown: (isOpened: boolean) => void;
  isTemplateMode: boolean;
};

export const EditorTabContent: React.FC<EditorTabContentProps> = ({
  disabled = false,
  isLoading = false,
  isSelected,
  tab,
  isOpen,
  canDelete = true,
  canSetVisibility = true,
  onTabSelect = () => null,
  onNameUpdate = () => null,
  onTabDelete = () => null,
  onTabDuplicate = () => null,
  onVisibleUpdate = () => null,
  onLoadFromTemplate = () => null,
  onSaveAsTemplate = () => null,
  onToggleDropdown,
  isTemplateMode,
}) => {
  const { editor } = useTiptapEditor();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [tabName, setTabName] = useState(tab.name ?? '');

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTabName(tab.name);
  }, [tab.name]);

  const handleTabClick = useCallback(
    (
      event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
      isOpen?: boolean
    ) => {
      onTabSelect(tab, isOpen);
    },
    [onTabSelect, tab]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTabName(e.target.value);
    },
    []
  );

  const handleBlur = useCallback(() => {
    if (tabName === tab.name) return;

    onNameUpdate({ id: tab.id, name: tabName });
  }, [tab, tabName, onNameUpdate]);

  const toggleDropdown = useCallback(
    (isOpened: boolean) => {
      if (disabled || isLoading) return;

      setDropdownOpen(isOpened);
      if (!isOpened) {
        handleBlur();
      }
      onToggleDropdown(isOpened);
    },
    [setDropdownOpen, handleBlur, onToggleDropdown, disabled, isLoading]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (e.key === 'Enter') {
        e.preventDefault();
        toggleDropdown(false);
      }
    },
    [toggleDropdown]
  );

  const handleSetTabVisible = useCallback(
    (isVisible: boolean) => {
      onVisibleUpdate({ id: tab.id, isVisible });
    },
    [onVisibleUpdate, tab]
  );

  const handleDuplicateTab = useCallback(() => {
    onTabDuplicate(tab.id);
  }, [onTabDuplicate, tab.id]);
  const handleDeleteTab = useCallback(() => {
    onTabDelete(tab.id);
  }, [onTabDelete, tab.id]);

  const handleSaveAsTemplate = useCallback(() => {
    onSaveAsTemplate(tab.id);
  }, [onSaveAsTemplate, tab.id]);

  const pageContent = editor?.getJSON();
  const hasEmptyContent = checkIsEmptyContent(pageContent);

  const dispatch = useDispatch();

  const tabMenuItems: TabMenu[] = useMemo(
    () => [
      {
        name: 'Visible for viewers',
        key: 'visible',
        icon: <Icon glyph={IconMap.Eye} width={16} height={16} />,
        type: TabMenuType.Checkbox,
        isChecked: tab.isVisible,
        action: handleSetTabVisible,
        isDisabled: !canSetVisibility && tab.isVisible,
      },
      {
        key: 'separator-1',
        type: TabMenuType.Separator,
      },
      {
        name: 'Start writing with AI',
        key: 'ai',
        icon: '../../../../assets/images/wizard-write.svg',
        type: TabMenuType.MenuItem,
        isHidden: !hasEmptyContent,
        action: () => {
          if (isTemplateMode) {
            editor?.commands.renderAI();
          } else {
            dispatch(
              pageCreationFlowModel.actions.openPageCreationFlow({
                mode: 'tab',
              })
            );
          }
        },
      },
      {
        key: 'separator-2',
        type: TabMenuType.Separator,
        isHidden: !hasEmptyContent,
      },
      {
        name: 'Save as single-tab template',
        key: 'save-template',
        icon: <Icon glyph={IconMap.TemplatesPlus} width={16} height={16} />,
        type: TabMenuType.MenuItem,
        action: handleSaveAsTemplate,
        isHidden: hasEmptyContent,
      },
      {
        name: 'Load from template',
        key: 'load-template',
        icon: <Icon glyph={IconMap.Templates} width={16} height={16} />,
        type: TabMenuType.MenuItem,
        action: onLoadFromTemplate,
      },
      {
        name: 'Duplicate',
        key: 'duplicate',
        icon: <Icon glyph={IconMap.Copy01} width={16} height={16} />,
        type: TabMenuType.MenuItem,
        action: handleDuplicateTab,
      },
      {
        name: 'Delete',
        key: 'delete',
        icon: <Icon glyph={IconMap.Trash} width={16} height={16} />,
        type: TabMenuType.MenuItem,
        action: handleDeleteTab,
        isHidden: !canDelete,
      },
    ],
    [
      tab.isVisible,
      editor,
      onLoadFromTemplate,
      handleSetTabVisible,
      handleDuplicateTab,
      handleDeleteTab,
      handleSaveAsTemplate,
      canDelete,
      hasEmptyContent,
      canSetVisibility,
      dispatch,
      isTemplateMode,
    ]
  );

  const renderMenuItem = (menuItem: TabMenu) => {
    switch (menuItem.type) {
      case TabMenuType.MenuItem:
        return (
          <div
            key={menuItem.key}
            className="group/item flex items-center gap-2 px-2.5 py-2.5 text-sm font-medium text-gray-700 hover:bg-base-black-4 hover:text-gray-700 rounded-md cursor-pointer outline-0"
            onClick={() => {
              menuItem.action?.();
              toggleDropdown(false);
            }}
          >
            {typeof menuItem.icon === 'string' ? (
              <img src={menuItem.icon} className="w-5" alt="" />
            ) : (
              menuItem.icon
            )}
            <span className="group-hover/item:text-gray-800 text-gray-700">
              {menuItem.name}
            </span>
          </div>
        );
      case TabMenuType.Checkbox:
        return (
          <div
            key={menuItem.key}
            className="flex items-center justify-between py-3 px-2.5 text-sm font-medium text-gray-700 hover:bg-base-black-4 hover:text-gray-700 rounded-md cursor-pointer outline-0"
          >
            <span className="flex items-center gap-2">
              {menuItem.icon}
              <span>{menuItem.name}</span>
            </span>
            <Toggle
              checked={menuItem.isChecked}
              onChange={menuItem.action}
              disabled={menuItem.isDisabled}
            />
          </div>
        );
      case TabMenuType.Separator:
        return (
          <DropdownMenu.Separator
            key={menuItem.key}
            className="h-px bg-gray-200 my-1"
          />
        );
      default:
        return null;
    }
  };
  const IsInvisibleIcon = useCallback(() => {
    return !tab.isVisible ? (
      <Icon glyph={IconMap.EyeCrossedBold} width={16} height={16} />
    ) : null;
  }, [tab.isVisible]);

  useEffect(() => {
    if (isOpen) {
      toggleDropdown(true);
    }
  }, [isOpen, toggleDropdown]);

  return (
    <div
      className={classNames('select-none', {
        'border-b-2 border-gray-900': isSelected,
      })}
      ref={wrapperRef}
    >
      {isSelected ? (
        <Popover.Root
          open={isDropdownOpen}
          modal={false}
          onOpenChange={toggleDropdown}
        >
          <Popover.Anchor
            onContextMenu={(e) => {
              e.preventDefault();
              toggleDropdown(true);
            }}
            onClick={() => toggleDropdown(!isDropdownOpen)}
            className={classNames(
              'flex gap-1 items-center border-0 outline-0 pb-1.5 text-gray-900 hover:text-gray-900 whitespace-nowrap'
            )}
          >
            <span>{tab.name || 'Untitled'}</span>
            <IsInvisibleIcon />
            {!disabled && (
              <Icon
                className={classNames({ 'rotate-180': isDropdownOpen })}
                glyph={IconMap.ChevronDown}
                width={16}
                height={16}
              />
            )}
          </Popover.Anchor>
          <Popover.Portal>
            <Popover.Content
              align="start"
              sideOffset={4}
              className="min-w-60 bg-base-white z-[9999] border border-gray-300 rounded-xl shadow-lg px-1.5 py-1"
              onFocusOutside={(event) => {
                event.preventDefault();
              }}
              onPointerDownOutside={(event) => {
                if (wrapperRef.current?.contains(event.target as Node)) {
                  event.preventDefault();
                }
              }}
            >
              <div className="font-normal py-3 px-2.5">
                <Input
                  autoFocus={isOpen}
                  placeholder="Untitled"
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                  type="text"
                  value={tabName}
                />
              </div>
              {tabMenuItems
                .filter((menuItem) => !menuItem.isHidden)
                .map(renderMenuItem)}
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      ) : (
        <span
          className={classNames(
            'flex items-center gap-1.5 border-0 outline-0 pb-2 hover:text-gray-900 whitespace-nowrap'
          )}
          onContextMenu={(e) => {
            e.preventDefault();
            handleTabClick(e, true);
          }}
          onClick={handleTabClick}
        >
          <span>{tab.name || 'Untitled'}</span>
          <IsInvisibleIcon />
        </span>
      )}
    </div>
  );
};
