import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetMeetingEventResponse,
  UpdateUserCallSettingsRequest,
} from '@distribute/shared/api-types/call-recorder';
import { globalActions } from '../../../app/model/actions';
import { ToggleMeetingEventAction, ManuallyJoinCallData } from './types';

type State = {
  isLoading: boolean;
  meetingEvents: GetMeetingEventResponse[];
  progressMeetingEventIds: string[];
  isJoinCallModalOpen: boolean;
  isJoinCallInProgress: boolean;
};

const initialState: State = {
  isLoading: false,
  meetingEvents: [],
  progressMeetingEventIds: [],
  isJoinCallModalOpen: false,
  isJoinCallInProgress: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'callRecordings',
  initialState,
  reducers: {
    setMeetingEvents: (
      state,
      { payload }: PayloadAction<State['meetingEvents']>
    ) => ({
      ...state,
      meetingEvents: payload,
    }),
    setProgressMeetingEventIds: (
      state,
      { payload }: PayloadAction<State['progressMeetingEventIds']>
    ) => ({
      ...state,
      progressMeetingEventIds: payload,
    }),
    setIsLoading: (state, { payload }: PayloadAction<State['isLoading']>) => ({
      ...state,
      isLoading: payload,
    }),
    setIsJoinCallOpenModal: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isJoinCallModalOpen: action.payload,
    }),
    setIsJoinCallProgress: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isJoinCallInProgress: action.payload,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  connectToGoogleCalendar: createAction(
    'callRecordings/connectToGoogleCalendar'
  ),
  updateJoinPreference: createAction<
    Partial<Pick<UpdateUserCallSettingsRequest, 'joinPreference'>>
  >('callRecordings/updateJoinPreference'),
  toggleMeetingEventRecording: createAction<ToggleMeetingEventAction>(
    'callRecordings/toggleEventRecording'
  ),
  toggleMeetingEventVisibility: createAction<ToggleMeetingEventAction>(
    'callRecordings/toggleEventVisibility'
  ),
  loadMeetingEvents: createAction('callRecordings/loadMeetingEvents'),
  addManuallyJoinCall: createAction<ManuallyJoinCallData>(
    'callRecordings/addManuallyJoinCall'
  ),
};
