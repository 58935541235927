import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { Icon, Input, TabsGroup } from '../../../../../shared/ui';
import { ActionTypeAlertBar, Page } from '@distribute/shared/types';
import { ALERT_BAR_TABS } from '../../../lib/config';
import { conversionKitModel } from '../../../../../features/conversion-kit';
import { IconMap } from '../../../../../shared/sprite';
import { object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorSidebarConversionsPanelFooter } from './EditorSidebarConversionsPanelFooter';
import { Label } from './Label';
import cn from 'classnames';
import { RichTextEditor } from '../../../../../entities/tiptap-editor';
import {
  INVALID_LINK_MESSAGE,
  LINK_VERIFICATION_REGEX,
} from '../../../../../shared/constants';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { urlWithProtocol } from '@distribute/shared/utils';

const DESCRIPTION_MAX_LENGTH = 170;
const BUTTON_LABEL_MAX_LENGTH = 40;

type AlertBarForm = {
  buttonLabelLinkType: string;
  buttonLabelPopupType: string;
  buttonUrl: string;
};

type Props = {
  currentPage: Page | TemplateExtended;
};

export const EditorSidebarAlertBarPanel: React.FC<Props> = ({
  currentPage,
}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
    dispatch(conversionKitModel.actions.setAlertBarEdited(undefined));
  };

  const alertBar = useSelector(
    conversionKitModel.selectors.selectAlertBarWithError
  );
  const updateAlertBarIsLoading = useSelector(
    conversionKitModel.selectors.selectUpdateAlertBarIsLoading
  );

  const [activeTab, setActiveTab] = useState<string>(alertBar.actionType);
  const [actionType, setActionType] = useState<ActionTypeAlertBar>(
    ActionTypeAlertBar.LINK
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        buttonLabelPopupType: string().when([], {
          is: () => activeTab === ActionTypeAlertBar.POPUP,
          then: string().max(BUTTON_LABEL_MAX_LENGTH),
          otherwise: string().nullable(),
        }),
        buttonLabelLinkType: string().when([], {
          is: () => activeTab === ActionTypeAlertBar.LINK,
          then: string().max(BUTTON_LABEL_MAX_LENGTH),
          otherwise: string().nullable(),
        }),
        buttonUrl: string().when([], {
          is: () => activeTab === ActionTypeAlertBar.LINK,
          then: string()
            .matches(LINK_VERIFICATION_REGEX, INVALID_LINK_MESSAGE)
            .transform((_, val) => urlWithProtocol(val))
            .required(),
          otherwise: string().nullable(),
        }),
      }),
    [activeTab]
  );

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    control,
  } = useForm<AlertBarForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({
        buttonLabelLinkType: alertBar.buttonLabelLinkType,
        buttonLabelPopupType: alertBar.buttonLabelPopupType,
        buttonUrl: alertBar.buttonUrl,
      }),
      [alertBar]
    ),
  });

  const { buttonLabelPopupType, buttonLabelLinkType, buttonUrl } = watch();
  const descriptionEditorRef = useRef<ElementRef<typeof RichTextEditor>>(null);
  const [description, setDescription] = useState<string>(alertBar.description);
  const [isDescriptionError, setIsDescriptionError] = useState<boolean>(false);

  const {
    field: { onChange: changeButtonLabelLinkType },
  } = useController({
    control,
    name: 'buttonLabelLinkType',
  });

  const {
    field: { onChange: changeButtonLabelPopupType },
  } = useController({
    control,
    name: 'buttonLabelPopupType',
  });

  const handleButtonLabelChange = (value: string) => {
    changeButtonLabelLinkType(value);
    changeButtonLabelPopupType(value);
  };

  useEffect(() => {
    dispatch(
      conversionKitModel.actions.setAlertBarEdited({
        ...alertBar,
        buttonLabelLinkType,
        buttonLabelPopupType,
        description,
        actionType,
        buttonUrl,
      })
    );
  }, [
    buttonLabelPopupType,
    buttonLabelLinkType,
    description,
    actionType,
    buttonUrl,
    alertBar,
    dispatch,
  ]);

  useEffect(() => {
    switch (activeTab) {
      case ActionTypeAlertBar.LINK:
        setActionType(ActionTypeAlertBar.LINK);
        break;
      case ActionTypeAlertBar.POPUP:
        setActionType(ActionTypeAlertBar.POPUP);
        break;
    }
  }, [activeTab, unregister]);

  useEffect(() => {
    if (description) {
      setIsDescriptionError(false);
    }
  }, [description]);

  const onChangeTab = (tab: string) => {
    clearErrors();
    setActiveTab(tab);
  };

  const handleFormSubmit = (data: AlertBarForm) => {
    if (!description) {
      setIsDescriptionError(true);
      descriptionEditorRef.current?.scrollIntoViewAndFocus();
      return;
    }

    dispatch(
      conversionKitModel.actions.updateAlertBar({
        documentContentId: currentPage.content.id,
        ...data,
        description,
        actionType,
        callback: handleClose,
        isActive: true,
        buttonLabelPopupType: data.buttonLabelPopupType || 'Submit',
        buttonLabelLinkType: data.buttonLabelLinkType || 'Submit',
      })
    );
  };

  return (
    <>
      <header className="h-29 bg-base-white py-6 px-4 border-b border-gray-200">
        <div
          className="flex gap-2 text-gray-600 text-sm font-semibold cursor-pointer mb-4"
          onClick={handleClose}
        >
          <Icon glyph={IconMap.ArrowLeft} width={20} />
          <span>Back</span>
        </div>
        <h2 className="text-2xl font-display font-medium text-gray-900">
          Alert Bar
        </h2>
      </header>
      <div className={cn('overflow-y-auto h-[calc(100%-192px)]')}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="py-6 px-4">
            <p className="text-gray-800 font-semibold mb-4">Type</p>
            <TabsGroup
              active={activeTab}
              setActive={onChangeTab}
              options={ALERT_BAR_TABS}
            />
            <hr className="border-gray-200 my-6" />
            <p className="text-gray-800 font-semibold mb-4">Components</p>

            <Label
              icon={IconMap.Type01}
              text="Bar Text"
              isRequired
              className="mb-3"
            />
            <RichTextEditor
              ref={descriptionEditorRef}
              content={description}
              placeholder="Add text…"
              onUpdate={(content) => setDescription(content)}
              maxLength={DESCRIPTION_MAX_LENGTH}
              isError={isDescriptionError}
            />

            <hr className="border-gray-200 my-6" />
            <p className="text-gray-800 font-semibold mb-4">Button</p>
            {activeTab === ActionTypeAlertBar.LINK && (
              <>
                <Label
                  icon={IconMap.TypeSquare}
                  text="Label"
                  isRequired
                  className="mb-3"
                />
                <Input
                  {...register('buttonLabelLinkType')}
                  className="p-3"
                  type="text"
                  placeholder="Submit"
                  maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                  isError={!!errors.buttonLabelLinkType}
                  value={buttonLabelLinkType}
                  onChange={({ target: { value } }) =>
                    handleButtonLabelChange(value)
                  }
                />

                <Label
                  icon={IconMap.Link}
                  text="URL"
                  isRequired
                  className="mt-6 mb-3"
                />
                <Input
                  {...register('buttonUrl')}
                  className="p-3"
                  type="text"
                  placeholder="e.g. https://example.com/link"
                  isError={!!errors.buttonUrl}
                  messageText={errors.buttonUrl?.message}
                />
              </>
            )}

            {activeTab === ActionTypeAlertBar.POPUP && (
              <>
                <Label
                  icon={IconMap.TypeSquare}
                  text="Label"
                  isRequired
                  className="my-3"
                />
                <Input
                  {...register('buttonLabelPopupType')}
                  className="p-3"
                  type="text"
                  placeholder="Submit"
                  maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                  isError={!!errors.buttonLabelPopupType}
                  value={buttonLabelPopupType}
                  onChange={({ target: { value } }) =>
                    handleButtonLabelChange(value)
                  }
                />
              </>
            )}
          </div>
          <EditorSidebarConversionsPanelFooter
            isAddBtnLoading={updateAlertBarIsLoading}
            addBtnTitle={alertBar.description === null ? 'Add' : 'Save'}
            onClose={handleClose}
          ></EditorSidebarConversionsPanelFooter>
        </form>
      </div>
    </>
  );
};
