import { captureException, captureMessage, Severity } from '@sentry/react';

import { parseChatResponse } from '@distribute/shared/chat';
import { logger } from '../../../shared/lib';
import { ChatResponseType } from '@distribute/shared/types';

export const trackChatResponseErrors = (response: string) => {
  const structuredResponse = parseChatResponse(response);

  structuredResponse.errors.forEach((e) => {
    logger.error('Chat response:', e);
    captureException(e, { tags: { scope: 'ChatResponse' } });
  });

  structuredResponse.warnings.forEach((e) => {
    logger.warn('Chat response:', e);
    captureMessage(e, {
      level: Severity.Warning,
      tags: { scope: 'ChatResponse' },
    });
  });

  const tabWithoutIds =
    structuredResponse.data?.filter(
      (item) => item.type === ChatResponseType.Suggestion && !item.id
    ) ?? [];

  if (tabWithoutIds.length) {
    captureMessage(`Chat Tabs without id: ${tabWithoutIds.length}`, {
      tags: { scope: 'ChatResponse' },
    });
  }
};
