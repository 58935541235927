export const deleteTaskIdQueryParam = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('taskId');
  window.history.replaceState({}, '', url.toString());
};

export const showTaskInfo = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const taskId = urlParams.get('taskId');

  if (taskId) {
    const taskModal = document.querySelector(
      `[data-id="task-modal-${taskId}"]`
    );

    if (taskModal) {
      taskModal.classList.remove('task-modal-wrapper--hidden');
    }
  }
};
