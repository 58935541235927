import { put, select } from 'redux-saga/effects';
import {
  editorRightSidebarModel,
  RightSidebarOption,
} from '../../../../features/editor-right-sidebar';
import { editorLeftSidebarModel } from '../../../../features/editor-left-sidebar';

export function* closeLeftSidebar() {
  const rightSidebarOption: RightSidebarOption = yield select(
    editorRightSidebarModel.selectors.selectSelectedOption
  );

  if (rightSidebarOption) {
    yield put(editorLeftSidebarModel.actions.setSelectedOption(null));
  }
}
