import { nanoid } from '@reduxjs/toolkit';
import { IconMap } from '../sprite';
import cn from 'classnames';
import React, {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Icon, SpriteGlyph } from './Icon';
import TextareaAutosize from 'react-textarea-autosize';
import { InfoTooltip } from './InfoTooltip';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  type: string;
  label?: string;
  needLabel?: boolean;
  value?: string;
  iconSrc?: SpriteGlyph;
  labelIcon?: SpriteGlyph;
  placeholder?: string;
  isError?: boolean;
  isMandatory?: boolean;
  messageText?: ReactNode;
  disabled?: boolean;
  isTextArea?: boolean;
  textAreaRows?: number;
  textAreaMaxRows?: number;
  info?: string;
  maxCharacters?: number;
  isCounterShow?: boolean;
  isCounterOnly?: boolean;
  labelInsideInput?: string;
  labelInsideInputRightSide?: boolean;
  className?: string;
  heightSize?: InputHeightSize;
  isBrandStyles?: boolean;
  iconRightClassNames?: string;
  onClickIconRight?: () => void;
  counterValue?: number;
  isCounterInside?: boolean;
};

type InputHeightSize = 'sm' | 'md' | 'lg';

const GAP_BETWEEN_INPUT_AND_ELEMENTS_INSIDE = 20;

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    className,
    type,
    label,
    iconSrc,
    labelIcon,
    placeholder,
    isError = false,
    isMandatory = false,
    messageText,
    disabled = false,
    onChange,
    needLabel = true,
    isTextArea,
    textAreaRows = 4,
    textAreaMaxRows,
    info,
    maxCharacters,
    isCounterShow = true,
    isCounterOnly = false,
    labelInsideInput,
    labelInsideInputRightSide = false,
    heightSize = 'lg',
    isBrandStyles,
    iconRightClassNames,
    onClickIconRight,
    counterValue,
    isCounterInside = false,
    ...rest
  },
  ref
) {
  const [inputRightPadding, setInputRightPadding] = useState<
    number | undefined
  >(undefined);
  const containerForElementsInsideInputRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!containerForElementsInsideInputRef.current) return;

    if (labelInsideInput || iconSrc || type === 'password') {
      setInputRightPadding(
        containerForElementsInsideInputRef.current.clientWidth +
          GAP_BETWEEN_INPUT_AND_ELEMENTS_INSIDE
      );
    }
  }, [labelInsideInput, iconSrc, type]);

  const onTextChangeMethod = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (maxCharacters && e.target.value.length > maxCharacters) {
      return;
    }
    if (onChange) {
      onChange(e as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const [inputType, setInputType] = useState<string>(type);

  const changeInputType = () => {
    setInputType((prevType) => (prevType === 'text' ? 'password' : 'text'));
  };
  const uniqueId = nanoid();
  const messageStyles = '!text-sm text-gray-600';

  const style = `border rounded-lg w-full text-base text-gray-900 border-gray-300
    focus:ring-0 focus:border-primary-600 focus:outline-none
    disabled:bg-gray-50 disabled:text-gray-500
    placeholder-gray-500 px-3 text-ellipsis`;

  const inputProps = {
    label,
    placeholder,
    id: uniqueId,
    onChange: onTextChangeMethod,
    ...rest,
  };

  const textAreaProps = {
    onChange: onTextChangeMethod,
    value: rest.value,
    ...rest,
  };

  const setCursorPositionToEnd = () => {
    const inputElement = document.getElementById(
      uniqueId
    ) as HTMLInputElement | null;

    if (
      inputElement &&
      inputElement.type !== 'email' &&
      inputElement.type !== 'number'
    ) {
      const valueLength = inputElement.value.length;
      inputElement.setSelectionRange(valueLength, valueLength);
    }
  };

  return (
    <div className="w-full">
      {needLabel && label && (
        <div className="flex text-s font-normal items-center mb-1.5">
          <label
            htmlFor={uniqueId}
            className="flex font-medium text-gray-700"
            onClick={setCursorPositionToEnd}
          >
            {labelIcon && (
              <Icon glyph={labelIcon} width={20} className="mr-3" />
            )}
            {label}
            {isMandatory && <span className="text-error-500 ml-0.5">*</span>}
          </label>
        </div>
      )}
      {info && (
        <div className="flex items-center mb-1 text-base">
          <div className="ml-auto">
            <InfoTooltip>{info}</InfoTooltip>
          </div>
        </div>
      )}
      <div className="relative flex gap-1.5 flex-col">
        {isTextArea ? (
          <TextareaAutosize
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onKeyDown={rest.onKeyDown}
            {...textAreaProps}
            placeholder={placeholder}
            minRows={textAreaRows}
            maxRows={textAreaMaxRows}
            disabled={disabled}
            maxLength={maxCharacters}
            className={cn(
              'resize-none placeholder-gray-500 py-2 max-h-47',
              style,
              {
                'pl-10 pr-4': iconSrc,
                '!border-error-600': isError,
                'focus:!border-error-600': isError,
              },
              className
            )}
          />
        ) : (
          <input
            {...inputProps}
            style={{
              ...inputProps.style,
              paddingRight: inputRightPadding,
            }}
            ref={ref}
            disabled={disabled}
            type={inputType}
            maxLength={maxCharacters}
            autoComplete={inputProps.autoComplete || inputType}
            className={cn(className, 'leading-10 py-2 px-3 text-base', style, {
              '!border-error-600': isError,
              'focus:!border-error-600': isError,
              'h-9': heightSize === 'sm',
              'h-10': heightSize === 'md',
              'h-11': heightSize === 'lg',
              'font-paragraph': isBrandStyles,
            })}
          />
        )}
        {(labelInsideInput || iconSrc || type === 'password') && (
          <div
            ref={containerForElementsInsideInputRef}
            className={cn('absolute top-0 right-3 flex items-center gap-2', {
              'h-9': heightSize === 'sm',
              'h-10': heightSize === 'md',
              'h-11': heightSize === 'lg',
            })}
          >
            {labelInsideInput && (
              <div
                className={cn('flex items-center h-11', {
                  'right-3.5': labelInsideInputRightSide,
                })}
              >
                <p className="text-base text-right text-gray-600">
                  {labelInsideInput}
                </p>
              </div>
            )}
            {iconSrc && (
              <button
                className={cn('flex items-center outline-none', {
                  'h-9': heightSize === 'sm',
                  'h-10': heightSize === 'md',
                  'h-11': heightSize === 'lg',
                })}
                onClick={onClickIconRight}
              >
                <Icon
                  glyph={iconSrc}
                  className={cn(
                    'focus:outline-none',
                    {
                      'w-4 h-4': heightSize === 'sm',
                      'w-5 h-5': heightSize === 'md' || heightSize === 'lg',
                    },
                    iconRightClassNames
                  )}
                />
              </button>
            )}
            {type === 'password' && (
              <div className="flex items-center h-11">
                <button
                  className="focus:outline-none group"
                  type="button"
                  onClick={changeInputType}
                >
                  <Icon
                    glyph={
                      inputType === 'password'
                        ? IconMap.Eye
                        : IconMap.CrossedEye
                    }
                    className="text-gray-400 group-focus:text-gray-700"
                  />
                </button>
              </div>
            )}
          </div>
        )}
        {messageText && (
          <p
            className={cn(messageStyles, {
              '!text-error-600': isError && !disabled,
            })}
          >
            {messageText}
          </p>
        )}
        {maxCharacters && isCounterShow && (
          <p
            className={cn('text-right text-sm text-gray-600', {
              'absolute bottom-3 right-3.5 bg-base-white pl-0.5':
                isCounterInside,
            })}
          >
            {rest.value || counterValue
              ? rest.value?.trim().length || counterValue
              : '0'}{' '}
            / {maxCharacters}
          </p>
        )}
        {isCounterOnly && isCounterShow && !maxCharacters && !!counterValue && (
          <p
            className={cn('text-right text-sm text-gray-600', {
              'absolute bottom-3 right-3.5 bg-base-white pl-0.5':
                isCounterInside,
              'bottom-9.5': isError && isCounterInside,
            })}
          >
            {rest.value || counterValue
              ? rest.value?.trim().length || counterValue
              : '0'}
          </p>
        )}
      </div>
    </div>
  );
});
