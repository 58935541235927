import { call, select, put } from 'redux-saga/effects';
import { teamsModel } from '../../../teams';
import { callApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { callModel } from '../index';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* refreshCallDetailsData() {
  try {
    const currentCall: RT<
      typeof callModel.selectors.selectCurrentCallWithError
    > = yield select(callModel.selectors.selectCurrentCallWithError);

    const {
      id: teamId,
    }: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
      yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const {
      muxAsset,
      transcripts,
      isReady,
      summaries,
    }: RT<typeof callApi.getCallDetailsData> = yield call(
      callApi.getCallDetailsData,
      currentCall.id,
      { teamId }
    );

    yield put(
      callModel.actions.setCurrentCall({
        ...currentCall,
        muxAsset: { ...currentCall.muxAsset, ...muxAsset },
        transcripts,
        summaries,
        isReady,
      })
    );
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to load call details data')
      )
    );
  }
}
