import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { mediaUploadModel } from '../../../../../media-upload';
import {
  Avatar,
  Button,
  FileInput,
  LabelToggle,
} from '../../../../../../shared/ui';
import { IconMap } from '../../../../../../shared/sprite';
import context from './context';
import cn from 'classnames';

const ImageInput: FC = () => {
  const { form } = useContext(context);
  const [id, setNewId] = useState(() => v4());
  const dispatch = useDispatch();

  const file = useSelector(mediaUploadModel.selectors.selectFile(id));

  const onChangeisPhotoBlockVisible = useCallback(
    (newState: boolean) => {
      form.setValue('isPhotoBlockVisible', newState);
    },
    [form]
  );

  const onRemovePhoto = useCallback(() => {
    form.setValue('photoUrl', '');
  }, [form]);

  const onSetPhotoUrl = useCallback(
    (photoUrl: string) => {
      form.setValue('photoUrl', photoUrl);
    },
    [form]
  );

  useEffect(() => {
    if (file?.status === 'error') {
      setNewId(v4());
    }
  }, [file?.status]);

  return (
    <div className="flex flex-col gap-y-3">
      <LabelToggle
        checked={form.watch('isPhotoBlockVisible')}
        icon={IconMap.PhotoPictureUser}
        onChange={onChangeisPhotoBlockVisible}
        text="Photo"
        iconClassName="hidden"
        textClassName="!text-base font-normal"
      />
      {form.watch('isPhotoBlockVisible') && (
        <div className="flex flex-row items-stretch gap-x-4 [&_label]:h-full">
          <div className="flex flex-col gap-y-2 items-center">
            <Avatar
              displayName={form.watch('name')}
              src={form.watch('photoUrl')}
              className="h-16 w-16 border border-light-6"
              isUserAvatar={true}
            />
            <Button
              className={cn('opacity-1', {
                'opacity-0 pointer-events-none': !form.watch('photoUrl'),
              })}
              color="link"
              variant="icon"
              size="xs"
              iconLeftWidth={20}
              iconLeftName={IconMap.Delete}
              iconBlockClassName="m-0"
              onClick={onRemovePhoto}
            />
          </div>
          <FileInput
            progress={file?.progress}
            isUploading={file?.status === 'uploading'}
            onUpload={(uploadedFile) =>
              dispatch(
                mediaUploadModel.actions.uploadFile({
                  file: uploadedFile,
                  id,
                  cb: onSetPhotoUrl,
                })
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default ImageInput;
