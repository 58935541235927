import { api } from './instance';
import {
  CallsQueryParams,
  GetPaginatedCallsResponse,
  UpdateCallRequest,
  GetCallsCountResponse,
  GetCallDetailResponse,
  CallQueryParams,
  GetGroupedCallsResponse,
  GetCallDetailDataResponse,
  GenerateCallSummaryRequest,
  GetCallSummaryResponse,
} from '@distribute/shared/api-types/call';
import { MuxAsset, MuxAssetPlayback } from '@distribute/shared/types';

export const callApi = {
  async getCalls(query: CallsQueryParams) {
    const { data: responseData } = await api.get('calls', {
      params: query,
    });
    return responseData as GetPaginatedCallsResponse;
  },

  async getGroupedCalls(query: CallQueryParams) {
    const { data: responseData } = await api.get('calls/group', {
      params: query,
    });

    return responseData as GetGroupedCallsResponse;
  },

  async getCallsCount(query: {
    teamId: number;
  }): Promise<GetCallsCountResponse> {
    const { data: responseData } = await api.get('calls/count', {
      params: query,
    });

    return responseData as GetCallsCountResponse;
  },

  async updateCallItem(
    id: string,
    data: UpdateCallRequest,
    params: { teamId: number }
  ) {
    const { data: responseData } = await api.patch(`calls/${id}`, data, {
      params,
    });
    return responseData;
  },

  async deleteItem(id: string, params: { teamId: number }) {
    await api.delete(`calls/${id}`, { params });
  },

  async getCallDetails(id: string, params: { teamId: number }) {
    const { data: responseData } = await api.get(`calls/${id}`, {
      params,
    });
    return responseData as GetCallDetailResponse;
  },

  async getCallDetailsData(id: string, params: { teamId: number }) {
    const { data: responseData } = await api.get(`calls/${id}/data`, {
      params,
    });
    return responseData as GetCallDetailDataResponse;
  },

  async generateSummary(
    id: string,
    data: GenerateCallSummaryRequest,
    params: { teamId: number }
  ) {
    const { data: responseData } = await api.post(`calls/${id}/summary`, data, {
      params,
    });
    return responseData as GetCallSummaryResponse;
  },

  async setPublicPlayback(id: string, params: { teamId: number }) {
    const { data: responseData } = await api.post(
      `calls/${id}/public`,
      {},
      {
        params,
      }
    );
    return responseData as MuxAsset & MuxAssetPlayback;
  },
};
