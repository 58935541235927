import React from 'react';
import { CreatePageStepLeftWrapper } from './components/CreatePageStepLeftWrapper';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Tooltip,
} from '../../../../../shared/ui';
import {
  Appearance,
  AppearanceTrigger,
  PageType,
  PageTypeTrigger,
  Source,
  SourceTrigger,
} from './AccordionItems';
import { cn } from '@distribute/frontend/utils';
import { useCreatePageStepContext } from '../context';
import { useDispatch, useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../../model';
import {
  ActiveAIStepType,
  CreatePageFormSchemaType,
} from '../../../config/types';
import { useFormContext } from 'react-hook-form';
import { IconMap } from '../../../../../shared/sprite';

const Footer = () => {
  const dispatch = useDispatch();

  const { isAllDone } = useCreatePageStepContext();

  const { watch } = useFormContext<CreatePageFormSchemaType>();
  const formData = watch();

  const isGeneratingOutline = useSelector(
    pageCreationFlowModel.selectors.selectIsGenerateOutlineLoading
  );

  const wasOutlineGenerationStarted = useSelector(
    pageCreationFlowModel.selectors.selectWasOutlineGenerationStarted
  );

  const isGeneratingPage = useSelector(
    pageCreationFlowModel.selectors.selectIsGeneratePageLoading
  );

  const handleGenerateOutline = () => {
    dispatch(
      pageCreationFlowModel.actions.setActiveAIStep(ActiveAIStepType.OUTLINE)
    );
    dispatch(pageCreationFlowModel.actions.generateOutline(formData));
  };

  const handleGeneratePage = () => {
    dispatch(
      pageCreationFlowModel.actions.initializePageGenerationWithAI(formData)
    );
  };

  if (wasOutlineGenerationStarted) {
    return (
      <div className="flex gap-4">
        <Button
          variant="text"
          disabled={isGeneratingOutline}
          color="secondary"
          size="xl"
          className="w-full"
          onClick={handleGenerateOutline}
        >
          Regenerate outline
        </Button>
        <Tooltip
          triggerClassNames="w-full"
          trigger={
            <Button
              variant="icon-text"
              disabled={isGeneratingOutline}
              color="primary"
              className="w-full"
              size="xl"
              iconLeftName={IconMap.Star06}
              iconLeftWidth={20}
              onClick={handleGeneratePage}
              loading={isGeneratingPage}
            >
              Generate page
            </Button>
          }
          align="end"
          sideOffset={4}
        >
          <p className="px-2 py-1 font-semibold text-xs">
            Generate page with current outline
          </p>
        </Tooltip>
      </div>
    );
  }

  return (
    <Tooltip
      className={cn({ hidden: isAllDone })}
      trigger={
        <Button
          variant="text"
          disabled={!isAllDone}
          color="primary"
          size="xl"
          className="w-full"
          loading={isGeneratingOutline}
          onClick={handleGenerateOutline}
        >
          Generate outline
        </Button>
      }
      sideOffset={4}
    >
      <p className="px-2 py-1 font-semibold text-xs">
        Please, finish all previous steps to generate page outline
      </p>
    </Tooltip>
  );
};

export const CreateWithAI: React.FC = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector(
    pageCreationFlowModel.selectors.selectActiveAIStep
  );
  const setActiveStep = (step: ActiveAIStepType | null) => {
    dispatch(pageCreationFlowModel.actions.setActiveAIStep(step));
  };

  const { isAppearanceDone, isPageTypeDone, isSourceDone } =
    useCreatePageStepContext();

  const handleValueChange = (value: string | undefined) => {
    setActiveStep((value ?? null) as ActiveAIStepType | null);
  };

  return (
    <CreatePageStepLeftWrapper footer={<Footer />}>
      <div className="flex-1 overflow-y-auto h-full">
        <Accordion
          value={activeStep?.toString()}
          onValueChange={handleValueChange}
          type="single"
          collapsible
          className="gap-4 flex flex-col"
        >
          <AccordionItem value="appearance">
            <AccordionTrigger
              className={cn({
                '[&[data-state=closed]]:py-3': isAppearanceDone,
              })}
            >
              <AppearanceTrigger
                isOpened={activeStep === ActiveAIStepType.APPEARANCE}
                isDone={isAppearanceDone}
              />
            </AccordionTrigger>
            <AccordionContent>
              <Appearance
                onNext={() => setActiveStep(ActiveAIStepType.TYPE)}
                isDone={isAppearanceDone}
              />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="type">
            <AccordionTrigger
              className={cn({
                '[&[data-state=closed]]:py-3': isPageTypeDone,
              })}
            >
              <PageTypeTrigger
                isOpened={activeStep === ActiveAIStepType.TYPE}
                isDone={isPageTypeDone}
              />
            </AccordionTrigger>
            <AccordionContent>
              <PageType
                onNext={() => setActiveStep(ActiveAIStepType.SOURCE)}
                isDone={isPageTypeDone}
              />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="source">
            <AccordionTrigger
              className={cn({
                '[&[data-state=closed]]:py-3': isSourceDone,
              })}
            >
              <SourceTrigger
                isOpened={activeStep === ActiveAIStepType.SOURCE}
                isDone={isSourceDone}
              />
            </AccordionTrigger>
            <AccordionContent>
              <Source
                onNext={() => {
                  setActiveStep(ActiveAIStepType.OUTLINE);
                }}
                isDone={isSourceDone}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </CreatePageStepLeftWrapper>
  );
};
