import { call, select, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { RT } from '../../../../shared/types';
import { ToggleMeetingEventAction } from '../types';

import { teamsModel } from '../../../teams';

import { callRecordingsModel } from '../index';
import { callRecordingsApi } from '../../../../shared/api';

import { createNotification, snackbarModel } from '../../../snackbar';

export function* updateMeetingEvent({
  payload: { eventId, type, cb },
  type: actionType,
}: PayloadAction<ToggleMeetingEventAction>) {
  const updateCalendarEventApi = {
    [callRecordingsModel.actions.toggleMeetingEventRecording.type]:
      callRecordingsApi.toggleCalendarEventRecording,
    [callRecordingsModel.actions.toggleMeetingEventVisibility.type]:
      callRecordingsApi.toggleCalendarEventVisibility,
  };
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  const progressCalendarEventIds: RT<
    typeof callRecordingsModel.selectors.selectProgressMeetingEventIds
  > = yield select(callRecordingsModel.selectors.selectProgressMeetingEventIds);
  const isInProgress = progressCalendarEventIds.includes(eventId);

  if (isInProgress) {
    return;
  }

  yield put(
    callRecordingsModel.actions.setProgressMeetingEventIds([
      ...progressCalendarEventIds,
      eventId,
    ])
  );

  const apiCall = updateCalendarEventApi[actionType];

  try {
    const updatedEvent: RT<typeof apiCall> = yield call(
      apiCall,
      eventId,
      { type },
      { teamId: team.id }
    );

    const events: RT<typeof callRecordingsModel.selectors.selectMeetingEvents> =
      yield select(callRecordingsModel.selectors.selectMeetingEvents);

    yield put(
      callRecordingsModel.actions.setMeetingEvents(
        events.map((e) => (e.id === updatedEvent.id ? updatedEvent : e))
      )
    );

    if (
      actionType ===
        callRecordingsModel.actions.toggleMeetingEventRecording.type &&
      updatedEvent.shouldRecord
    ) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification(
            'success',
            'Please wait for 30 seconds! Notetaker will join your meeting shortly.'
          )
        )
      );
    }

    cb?.();
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed update calendar event')
      )
    );
    cb?.(err);
  } finally {
    const updatedEventIds: RT<
      typeof callRecordingsModel.selectors.selectProgressMeetingEventIds
    > = yield select(
      callRecordingsModel.selectors.selectProgressMeetingEventIds
    );

    yield put(
      callRecordingsModel.actions.setProgressMeetingEventIds(
        updatedEventIds.filter((id) => id !== eventId)
      )
    );
  }
}
