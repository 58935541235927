import { call, put, select } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import type { RT } from '../../../../shared/types';
import { VideoRecordLoadingStatus } from '../types';

import { formatError, messages } from '../../config';
import { AxiosError } from 'axios';

import { createNotification, snackbarModel } from '../../../snackbar';
import { videoRecordsApi } from '../../../../shared/api';
import { actions } from '../reducer';
import { teamsModel } from '../../../teams';
import { authUserModel } from '../../../../entities/auth-user';
import { getTeamPermissions } from '../../../teams/lib';

export function* setRecordsData(setLoading = true) {
  try {
    const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
      yield select(teamsModel.selectors.selectCurrentTeamWithError);
    const members: RT<typeof teamsModel.selectors.selectCurrentTeamMembers> =
      yield select(teamsModel.selectors.selectCurrentTeamMembers);
    const currentUser: RT<typeof authUserModel.selectors.selectUserWithError> =
      yield select(authUserModel.selectors.selectUserWithError);

    const { isGuest } = getTeamPermissions(currentUser, members);

    if (isGuest) return;

    if (setLoading) {
      yield put(actions.setRecordsStatus(VideoRecordLoadingStatus.Pending));
    }
    const records: RT<typeof videoRecordsApi.getAll> = yield call(
      videoRecordsApi.getAll,
      { teamId: team.id }
    );

    yield put(actions.setRecords(records));
    yield put(actions.setRecordsStatus(VideoRecordLoadingStatus.Success));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          formatError(
            messages.records.getError,
            (e as AxiosError)?.response?.status
          )
        )
      )
    );
    yield put(actions.setRecordsStatus(VideoRecordLoadingStatus.Error));
  }
}
