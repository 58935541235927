import { useForm } from 'react-hook-form';
import { Button, Input } from '../../../../src/shared/ui';
import { Dispatch, FC, SetStateAction } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { cn } from '@distribute/frontend/utils';

type Props = {
  handleAssign: (email: string) => void;
  setIsInputAssigneeEmailOpen: Dispatch<SetStateAction<boolean>>;
};

type EmailSchema = {
  email: string;
};

const emailSchema = object().shape({
  email: string()
    .email('Please enter a valid email address, e.g., example@domain.com')
    .required('This field is required.'),
});

export const AssigneeEmailForm: FC<Props> = ({
  handleAssign,
  setIsInputAssigneeEmailOpen,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailSchema>({
    resolver: yupResolver(emailSchema),
  });

  const handleFormSubmit = (data: EmailSchema) => {
    handleAssign(data.email);
  };

  return (
    <div className="p-4 border border-gray-300 shadow-lg rounded-xl">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Input
          {...register('email')}
          isError={!!errors.email}
          messageText={errors.email?.message}
          className={cn({
            'mb-3': !errors.email,
          })}
          type="text"
        />
        <div className="flex items-center justify-between w-full">
          <Button
            type="button"
            variant="text"
            color="secondary"
            onClick={() => {
              setIsInputAssigneeEmailOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button type="submit" color="primary" variant="text">
            Assign
          </Button>
        </div>
      </form>
    </div>
  );
};
