import { FC } from 'react';
import { IconMap } from '../../../../../../src/shared/sprite';
import { Button, Icon } from '../../../../../../src/shared/ui';

type Props = {
  clearSearchQuery: () => void;
};
export const NoTemplatesFoundScreen: FC<Props> = ({ clearSearchQuery }) => {
  return (
    <div className="relative flex flex-col items-center justify-center flex-grow w-full h-full gap-2 overflow-hidden">
      <div className="z-10 flex flex-col items-center justify-center">
        <div className="border border-gray-200 rounded-[10px] p-3 shadow-xs relative w-12 h-12">
          <Icon
            glyph={IconMap.Templates}
            width={24}
            className="text-gray-700"
          />
          <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <Icon glyph={IconMap.BgSquare} />
            <div className="absolute top-0 w-full h-full bg-gradient-radial-transparent-to-white" />
          </div>
        </div>
        <div className="relative z-10 flex flex-col items-center justify-center mt-4">
          <p className="mb-1 font-semibold text-gray-900 text-md">
            No templates found
          </p>
          <p className="text-gray-600 text-sm font-normal max-w-[352px] text-center mb-6">
            We cannot find the template you need, but you can always create your
            own.
          </p>
          <Button variant="text" color="secondary" onClick={clearSearchQuery}>
            Clear search
          </Button>
        </div>
      </div>
    </div>
  );
};
