import { FC, useCallback } from 'react';
import { useRecordPermissions } from '../../../hooks';
import { recordingsModel } from '../../../model';
import { useDispatch, useSelector } from 'react-redux';
import { authUserModel } from '../../../../../entities/auth-user';
import { debounce } from 'debounce';
import { SelectableInput } from '../../../../../shared/ui';
import { DEFAULT_NAME } from '../../../config';

export const RecordTitle: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(authUserModel.selectors.selectUserWithError);
  const record = useSelector(recordingsModel.selectors.selectRecord);

  const { isCanEdit } = useRecordPermissions({
    userId: user.id,
    userPermissionToVideoRecords: record.userPermissionToVideoRecords,
    teamMembersPermission: record.teamMembersPermission,
  });

  //eslint-disable-next-line
  const handleChangeName = useCallback(
    debounce(
      (name: string) =>
        dispatch(
          recordingsModel.actions.updateRecordName({ id: record.id, name })
        ),
      300
    ),
    []
  );
  return (
    <SelectableInput
      variant="text"
      readonly={!isCanEdit}
      valueReadonly={record.name || DEFAULT_NAME}
      value={record.name}
      onChange={handleChangeName}
      placeholder="Untitled"
      tooltipText="Rename recording"
      classNames="w-full"
    />
  );
};
