import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TeamFiltered, UserToTeamFiltered } from '@distribute/shared/types';
import {
  ChangeCurrentTeamAction,
  CreateTeamAction,
  UpdateCurrentTeamCurrentMemberAction,
  UpdateCurrentTeamMemberAction,
  UpdateTeamPermissionsAction,
} from './types';
import { globalActions } from '../../../app/model/actions';

type TeamsStatus = 'never' | 'pending' | 'error' | 'success';

type State = {
  teams: TeamFiltered[];
  currentTeam?: TeamFiltered;
  currentTeamMembers: UserToTeamFiltered[];
  teamsStatus: TeamsStatus;
  isCreatingTeam: boolean;
  isShowCreateNewTeamModal: boolean;
};

const initialState: State = {
  teams: [],
  currentTeam: undefined,
  currentTeamMembers: [],
  teamsStatus: 'never',
  isCreatingTeam: false,
  isShowCreateNewTeamModal: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams: (state, { payload: teams }: PayloadAction<TeamFiltered[]>) => ({
      ...state,
      teams,
    }),
    setCurrentTeam: (
      state,
      { payload: currentTeam }: PayloadAction<TeamFiltered | undefined>
    ) => ({
      ...state,
      currentTeam,
    }),
    setCurrentTeamMembers: (
      state,
      { payload: currentTeamMembers }: PayloadAction<UserToTeamFiltered[]>
    ) => ({
      ...state,
      currentTeamMembers,
    }),
    updateCurrentTeamMember: (
      state,
      { payload }: PayloadAction<UpdateCurrentTeamMemberAction>
    ) => {
      const { userId, data } = payload;
      return {
        ...state,
        currentTeamMembers: state.currentTeamMembers.map((member) => {
          if (member.user?.id === userId) {
            return { ...member, ...data };
          }
          return member;
        }),
      };
    },
    decrementAIResponse: (state) => {
      if (
        state.currentTeam?.currentMember &&
        state.currentTeam?.currentMember.aiMonthlyResponsesCount !== null
      ) {
        state.currentTeam.currentMember.aiMonthlyResponsesCount =
          state.currentTeam.currentMember.aiMonthlyResponsesCount - 1;
      }
    },
    setTeamsStatus: (
      state,
      { payload: teamsStatus }: PayloadAction<TeamsStatus>
    ) => ({
      ...state,
      teamsStatus,
    }),
    setIsCreatingTeam: (
      state,
      { payload: isCreatingTeam }: PayloadAction<boolean>
    ) => ({
      ...state,
      isCreatingTeam,
    }),
    setIsShowCreateNewTeamModal: (
      state,
      { payload: isShowCreateNewTeamModal }: PayloadAction<boolean>
    ) => ({
      ...state,
      isShowCreateNewTeamModal,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  createTeam: createAction<CreateTeamAction>('teams/createTeam'),
  createTeamForGuest: createAction('teams/createTeamForGuest'),
  changeCurrentTeam: createAction<ChangeCurrentTeamAction>(
    'teams/changeCurrentTeam'
  ),
  updateCurrentTeamCurrentMember:
    createAction<UpdateCurrentTeamCurrentMemberAction>(
      'teamMembers/updateCurrentTeamCurrentMember'
    ),
  updateTeamPermissions: createAction<UpdateTeamPermissionsAction>(
    'teamMembers/updateTeamPermissions'
  ),
};
