import React, { PropsWithChildren } from 'react';
import { cn } from '@distribute/frontend/utils';
import { IconMap } from '../sprite';
import { Icon } from './Icon';

type Props = PropsWithChildren<{
  iconName: keyof typeof IconMap;
  selected: boolean;
  onClick: () => void;
  name: string;
}>;
export const SelectCard = ({
  iconName,
  selected,
  onClick,
  children,
  name,
}: Props) => {
  return (
    <label
      className={cn(
        'flex cursor-pointer items-center gap-2 rounded-lg border border-gray-200 p-3 hover:bg-gray-50 text-gray-700 hover:text-gray-800',
        selected && 'border-primary-600 hover:bg-primary-50'
      )}
    >
      <input
        onChange={onClick}
        checked={selected}
        type="radio"
        className="sr-only"
        name={name}
      />
      <span
        className={cn(
          'rounded-md p-1 inline-flex',
          selected ? 'bg-primary-50' : 'bg-gray-100'
        )}
      >
        <Icon
          className={cn({
            'text-primary-600': selected,
          })}
          glyph={IconMap[iconName]}
          width={20}
        />
      </span>

      {children}
    </label>
  );
};
