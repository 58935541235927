import { JSONContent } from '@tiptap/core';
import { isEqual } from 'lodash';
import { markEmptyNodes } from './markEmptyNodes';
import { addBlockSeparators, END_OF_BLOCK } from './addBlockSeparators';
import { tokenize } from './tokenize';
import { computeLCS } from './computeLCS';
import { reconstructDocument } from './reconstructDocument';
import { reorganizeTree } from './reorganizeTree';
import { normalizeDoc } from './normalizeDoc';

import { MarkedToken, Operation } from './types';
import { consolidateTextChanges } from './consolidateTextChanges';

/**
 * Mark tokens with operations by adding attributes
 *
 * @param {Operation[]} ops - Operations from computeLCS
 * @returns {Token[]} - Tokens with operations marked as attributes
 */
function markAsAttrs(ops: Operation[]): MarkedToken[] {
  return ops.map(({ op, token }) => {
    //eslint-disable-next-line
    let { character, path } = token;
    if (character === END_OF_BLOCK) {
      // remove ephemeral text node
      path = path.slice(0, -1);
    } else if (op !== 'equal') {
      // mark operation in future document structure
      const deepestNode = path.pop();
      if (deepestNode && deepestNode.type === 'text') {
        path.push({
          ...deepestNode,
          marks: [
            ...(deepestNode.marks || []),
            { type: 'diff', attrs: { op } },
          ],
        });
      } else if (deepestNode) {
        path.push({ ...deepestNode, attrs: { ...deepestNode.attrs, op } });
      }
    }
    return { ...token, op, path };
  });
}

/**
 * Compare two ProseMirror documents and return a diff document
 *
 * @param {JSONContent} docA - The first document
 * @param {JSONContent} docB - The second document
 * @param {DiffDocsOptions} [options] - Optional configuration for diff generation
 * @returns {JSONContent | null} - A diff document showing the changes or null if no differences
 */

type DiffDocsOptions = {
  /**
   * Character used to mark the end of block elements
   * This helps properly track structural changes between blocks
   * Default: '¶'
   */
  endOfBlockChar: string;

  /**
   * Determines the granularity of diffing
   * - 'char': Compare documents character by character (more detailed)
   * - 'word': Compare documents word by word (better performance, less granular)
   * Default: 'char'
   */
  splitBy: 'word' | 'char';

  /**
   * Ratio threshold that determines when to switch from character-level
   * diffing to block-level diffing for heavily modified content
   * A value between 0 and 1, where:
   * - Lower values favor character-level diffing
   * - Higher values favor block-level diffing
   * Default: null
   */
  threshold: number | null;
};

function diffDocs(
  docA: JSONContent,
  docB: JSONContent,
  options?: DiffDocsOptions
): JSONContent | null {
  const { endOfBlockChar, splitBy, threshold } = options ?? {
    endOfBlockChar: '¶',
    splitBy: 'char',
    threshold: null,
  };

  // Step 0: Deep clone the documents to avoid mutating the originals.
  // Note: consider switching to a more performant approach.
  const cloneA = JSON.parse(JSON.stringify(docA));
  const cloneB = JSON.parse(JSON.stringify(docB));

  normalizeDoc(cloneA);
  normalizeDoc(cloneB);

  // Deep compare the documents
  if (isEqual(cloneA, cloneB)) {
    return null;
  }

  // Step 1: Mark empty nodes in both documents.
  markEmptyNodes(cloneA);
  markEmptyNodes(cloneB);

  // Step 2: Insert block separators (end-of-block tokens) in non-empty nodes.
  addBlockSeparators(cloneA);
  addBlockSeparators(cloneB);

  // console.log(JSON.stringify(cloneA, null, 2));
  // console.log(JSON.stringify(cloneB, null, 2));

  // Step 3: Tokenize both preprocessed documents.
  const tokensA = tokenize(cloneA, splitBy);
  const tokensB = tokenize(cloneB, splitBy);

  // console.log(tokensA.map(v=>JSON.stringify(v)).join("\n"));
  // console.log(tokensB.map(v=>JSON.stringify(v)).join("\n"));

  // Step 4: Compute the longest common subsequence (LCS) on the tokens.
  const ops = computeLCS(tokensA, tokensB);

  // console.log(ops.map(v=>JSON.stringify(v)).join("\n"));

  // Step 5: Represent operations as additional wrapper-nodes
  // const markedChars = markAsWrappers(ops);
  const markedChars = markAsAttrs(ops);

  // console.log(markedChars.map(v=>JSON.stringify(v)).join("\n"));

  // Step 6: Reconstruct the final diff document
  const diffDoc = reorganizeTree(
    reconstructDocument(markedChars, endOfBlockChar)
  );

  // console.log(customStringify(diffDoc, (obj) => !Array.isArray(obj.content) && !Array.isArray(obj)));

  if (threshold === null) {
    return diffDoc;
  }

  return consolidateTextChanges(diffDoc, threshold);
}

const diffDocsOptions = {
  endOfBlockChar: '',
  splitBy: 'word',
  threshold: 0.55,
} as const;

export { diffDocs, diffDocsOptions };
