import { MeetingPlatform } from '@distribute/shared/types';
import { IconMap } from '../../shared/sprite';

import { SpriteGlyph } from '../../shared/ui/Icon';

export const MeetingPlatforms: Record<
  MeetingPlatform,
  { name: string; icon: SpriteGlyph }
> = {
  googleMeet: {
    name: 'On Google Meet',
    icon: IconMap.GoogleMeet,
  },
  zoom: {
    name: 'On Zoom',
    icon: IconMap.ZoomCall,
  },
  microsoftTeams: {
    name: 'On Teams',
    icon: IconMap.TeamsCall,
  },
} as const;
