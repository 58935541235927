import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { templatesModel } from '../../model';
import {
  TemplatesConfigEnum,
  TemplatesSwitcherEnum,
  switcherButtons,
} from '../../lib';

const TEMPLATES_HEADER_TEXT = {
  [TemplatesConfigEnum.RECENT]: {
    title: 'Recently Used',
    desc: 'Templates that you have recently utilized and may need to reuse in the near future.',
  },
  [TemplatesConfigEnum.TOP]: {
    title: 'Top Templates',
    desc: 'Access expertly crafted marketing resources for success.',
  },
  [TemplatesConfigEnum.PERSONAL]: {
    title: 'Personal Templates',
    desc: 'Craft templates that match your unique style.',
  },
  [TemplatesConfigEnum.TEAM]: {
    title: 'Team Templates',
    desc: 'Templates forged by your team, for your team.',
  },
  [TemplatesConfigEnum.COMMUNITY]: {
    title: 'Community Templates',
    desc: 'Collaborate and thrive with community-driven templates.',
  },
  [TemplatesConfigEnum.MEETINGS]: {
    title: 'Meetings',
    desc: 'Organize and conduct effective discussions with prospects or clients.',
  },
  [TemplatesConfigEnum.OPEN_PIPELINE]: {
    title: 'Open Pipeline',
    desc: 'Manage and track ongoing sales opportunities and progress.',
  },
  [TemplatesConfigEnum.POST_SALE]: {
    title: 'Post-Sale',
    desc: 'Nurture relationships and ensure customer satisfaction after purchase.',
  },
  [TemplatesConfigEnum.CUSTOMERS]: {
    title: 'Customers',
    desc: 'Manage and develop ongoing customer relationships and retention.',
  },
  [TemplatesConfigEnum.COACHING]: {
    title: 'Coaching',
    desc: 'Provide guidance and improvement strategies for sales teams.',
  },
  [TemplatesConfigEnum.BEST_PRACTICES]: {
    title: 'Best Practices',
    desc: 'Share successful methods and techniques for sales excellence.',
  },
  [TemplatesConfigEnum.DEAL_STRATEGY]: {
    title: 'Deal Strategy',
    desc: 'Develop plans to effectively win and close sales deals.',
  },
  [TemplatesConfigEnum.INTERNAL_UPDATES]: {
    title: 'Internal updates',
    desc: 'Templates for sharing progress, updates, and insights with your sales team and other internal stakeholders.',
  },
  [TemplatesConfigEnum.PROSPECTING]: {
    title: 'Prospecting',
    desc: 'Resources and materials to help identify and engage potential customers, including outreach templates and qualification checklists.',
  },
  [TemplatesConfigEnum.IN_DISCUSSION]: {
    title: 'In Discussion',
    desc: 'Tools for managing ongoing conversations with prospects, such as meeting agendas, follow-up templates, and proposal outlines.',
  },
  [TemplatesConfigEnum.CLOSING]: {
    title: 'Closing',
    desc: 'Templates to support the final stages of the sales process, including contract templates, pricing sheets, and objection handling guides.',
  },
  [TemplatesConfigEnum.IMPLEMENTATION]: {
    title: 'Implementation',
    desc: 'Resources for smooth customer onboarding and product implementation, including project plans and training materials.',
  },
  [TemplatesConfigEnum.CUSTOMER_SUCCESS]: {
    title: 'Customer Success',
    desc: 'Tools to ensure customer satisfaction and retention, such as check-in templates, success metrics tracking, and feedback forms.',
  },
  [TemplatesConfigEnum.UPSELL_AND_CROSS_SELL]: {
    title: 'Upsell & Cross-sell',
    desc: 'Materials to help identify and pursue opportunities for expanding business with existing customers.',
  },
  [TemplatesConfigEnum.WINBACK]: {
    title: 'Winback',
    desc: 'Templates and strategies for re-engaging former customers and reviving lost opportunities.',
  },
  [TemplatesConfigEnum.ALL]: {
    title: 'All Templates',
    desc: 'Templates that help you learn, work, and collaborate efficiently.',
  },
} as const;

type Props = {
  sortMethod: TemplatesSwitcherEnum;
  setSortMethod: Dispatch<SetStateAction<TemplatesSwitcherEnum>>;
};

export const TemplatesModalHeader: React.FC<Props> = ({
  sortMethod,
  setSortMethod,
}) => {
  const currentTemplatesFolder = useSelector(
    templatesModel.selectors.selectCurrentTemplatesFolder
  );

  const searchQuery = useSelector(templatesModel.selectors.selectSearchQuery);

  const headerText = useMemo(
    () =>
      TEMPLATES_HEADER_TEXT[
        currentTemplatesFolder as keyof typeof TEMPLATES_HEADER_TEXT
      ] ?? TEMPLATES_HEADER_TEXT[TemplatesConfigEnum.ALL],
    [currentTemplatesFolder]
  );

  return (
    <div className="flex items-start justify-between gap-8 pt-4 pb-4 pl-8 pr-4 overflow-hidden font-medium text-gray-900 shrink-0 text-display-xs font-display">
      <div className="flex items-start justify-between flex-grow gap-4 md:flex-col">
        <div className="flex flex-col">
          <p className="mb-1 font-semibold text-display-sm font-display">
            {searchQuery || headerText.title}
          </p>
          <p className="text-sm font-normal text-gray-700 font-base">
            {searchQuery
              ? 'Templates based on your search results.'
              : headerText.desc}
          </p>
        </div>

        {currentTemplatesFolder === TemplatesConfigEnum.ALL && (
          <div className="flex gap-1 p-1 ring-1 ring-gray-200 rounded-lg bg-gray-50 font-base mr-22">
            {switcherButtons.map((button) => (
              <button
                key={button.id}
                onClick={() => {
                  setSortMethod(button.id);
                }}
                className={cn(
                  'px-3 py-2 text-sm font-semibold rounded-md hover:bg-base-white hover:shadow-sm hover:text-gray-700',
                  {
                    'text-gray-500': sortMethod !== button.id,
                    'text-gray-700 bg-base-white shadow-sm':
                      sortMethod === button.id,
                  }
                )}
              >
                {button.title}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
