export const findScrollableParent = (
  element: HTMLElement | null
): HTMLElement => {
  if (!element) return document.documentElement;

  const style = window.getComputedStyle(element);
  const overflowY = style.getPropertyValue('overflow-y');

  if (overflowY === 'auto' || overflowY === 'scroll') {
    return element;
  }

  return findScrollableParent(element.parentElement);
};
