import { useState } from 'react';
import { TabsSwitcher } from '../shared';
import { SidebarTabLayout } from '../shared/SidebarTabLayout';
import { SettingsTabEnum, tabs } from './config';
import { GeneralSettingTab } from './GeneralSettingsTab';
import { AdvancedSettingsTab } from './AdvancedSettingsTab';

export const SettingsTab = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    SettingsTabEnum.GENERAL
  );

  return (
    <div className="w-120">
      <SidebarTabLayout
        headerComponent={
          <TabsSwitcher
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={(id: string) => {
              setSelectedTab(id);
            }}
          />
        }
      >
        {selectedTab === SettingsTabEnum.GENERAL && <GeneralSettingTab />}
        {selectedTab === SettingsTabEnum.ADVANCED && <AdvancedSettingsTab />}
      </SidebarTabLayout>
    </div>
  );
};
