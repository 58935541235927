import {
  NodeViewContent,
  NodeViewProps,
  NodeViewWrapper,
  Editor,
} from '@tiptap/react';
import { IconMap } from '../../../../shared/sprite';
import { Icon } from '../../../../shared/ui';
import classNames from 'classnames';

export type TodoListItemNodeType = NodeViewProps['node'] & {
  attrs: {
    checked: boolean;
  };
};

export type TodoListItemViewProps = NodeViewProps & {
  htmlContent?: string;
  editor?: Editor;
};

export const TodoListView = ({
  node,
  updateAttributes,
  htmlContent,
  editor,
}: TodoListItemViewProps) => {
  const { checked } = node.attrs;
  const isEditable = editor ? editor.isEditable : true;

  const toggleChecked = () => {
    if (!isEditable) return;
    updateAttributes?.({
      checked: !checked,
    });
  };

  return (
    <NodeViewWrapper
      data-type="todoListItem"
      className="!border-0 flex flex-row gap-3"
    >
      <label className="relative inline-block w-5 h-5 pt-1">
        <input
          type="checkbox"
          className="absolute opacity-0 w-full h-full"
          checked={checked}
          disabled={!isEditable}
          onChange={toggleChecked}
        />
        <span
          className={classNames(
            'border border-gray-300 rounded w-5 h-5 flex flex-col justify-center items-center',
            {
              'bg-primary-solid border-primary-solid': checked,
              'bg-white border-gray-300': !checked,
            }
          )}
        >
          {checked && (
            <Icon className="text-base-white w-3 h-3" glyph={IconMap.Check} />
          )}
        </span>
      </label>
      <div className="w-full relative">
        {node.firstChild?.textContent?.trim().length === 0 && (
          <div className="absolute top-0 left-0 -z-10 text-lg text-gray-500">
            To-do list
          </div>
        )}
        {htmlContent ? (
          <div
            className={classNames('text-lg', {
              checked,
              'text-gray-900': !checked,
            })}
            data-node-view-content
          >
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        ) : (
          <NodeViewContent
            className={classNames('text-lg', {
              checked,
              'text-gray-900': !checked,
            })}
          />
        )}
      </div>
    </NodeViewWrapper>
  );
};
