import { IconMap } from '../sprite';
import { Input } from './Input';
import { Icon } from './Icon';
import React, { forwardRef } from 'react';

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

export const SearchInput: React.FC<Props> = forwardRef(
  ({ placeholder = 'Search', onChange, value }, ref) => {
    const handleClear = () => {
      onChange('');
    };

    const handleSearchQueryChange = ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      onChange(value);
    };

    return (
      <div className="relative">
        <Icon
          glyph={IconMap.SearchLg}
          width={20}
          className="absolute left-3.5 top-2.5 z-10 text-gray-500"
        />
        <Input
          type="text"
          placeholder={placeholder}
          heightSize="md"
          className="!pl-10.5 !pr-8.5 !py-2.5 !text-md !text-gray-900 font-medium placeholder:font-normal shadow-xs"
          value={value}
          onChange={handleSearchQueryChange}
          id="search"
        />
        {value && (
          <Icon
            glyph={IconMap.XCircle}
            width={20}
            className="absolute right-3.5 top-2.5 z-10 text-gray-700 cursor-pointer"
            onClick={handleClear}
          />
        )}
      </div>
    );
  }
);
