import { call, takeEvery } from 'redux-saga/effects';
import { onboardingModel } from '../../../features/onboarding';

export function* onboardingWorker() {
  yield takeEvery(
    onboardingModel.actions.skipTeamOnboardingStep,
    onboardingModel.sagas.skipTeamOnboardingStep
  );
  yield takeEvery(
    onboardingModel.actions.inviteTeamMembers,
    onboardingModel.sagas.inviteTeamMembers
  );
  yield takeEvery(
    onboardingModel.actions.createTeam,
    onboardingModel.sagas.createTeam
  );
  yield takeEvery(
    onboardingModel.actions.updateUsagePlan,
    onboardingModel.sagas.updateUsagePlan
  );
  yield takeEvery(
    onboardingModel.actions.finishTeamOnboarding,
    onboardingModel.sagas.finishTeamOnboarding
  );
  yield takeEvery(
    onboardingModel.actions.finishExtensionOnboarding,
    onboardingModel.sagas.finishExtensionOnboarding
  );

  yield call(onboardingModel.sagas.getSubdomain);
}
