import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ChatBotEmpty } from './ChatBotEmpty';
import { ChatBotMessages } from './ChatBotMessages';
import { chatModel } from '../model';
import { ConversationState } from '../model/types';

export const ChatBotContent: FC = () => {
  const currentChat = useSelector(
    chatModel.selectors.selectCurrentChatWithError
  );
  const conversationState = useSelector(
    chatModel.selectors.selectConversationState
  );

  if (!currentChat) return null;

  if (currentChat.messages.length) {
    return <ChatBotMessages />;
  }

  if (conversationState === ConversationState.Streaming) {
    return <ChatBotMessages />;
  }

  return <ChatBotEmpty />;
};
