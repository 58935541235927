import React from 'react';
import { useTiptapEditor } from '../../lib';
import {
  HoveringBlockMenu,
  addButtonHoveringBlockPluginKey,
} from '../../extensions/HoveringBlock';
import { InsertAndDragButtons } from './InsertAndDragButtons';
const tags = [
  'paragraph',
  'resizeableFigure',
  'filePreview',
  'heading',
  'callout',
  'timeline',
  'customTaskList',
  'contactCard',
  'blockquote',
  'horizontalRule',
  'table',
  'listItem',
  'toggleListItem',
  'todoListItem',
  'button',
  'embedContentBlock',
  'codeBlock',
  'bulletList',
  'orderedList',
  'toggleList',
  'todoList',
  'codeBlock',
  'columnBlock',
  'column',
  'snippet',
];

export const HoverInsertAndDragMenu: React.FC = () => {
  const { editor } = useTiptapEditor();

  if (!editor) return null;

  const hoveringBlockPluginState = addButtonHoveringBlockPluginKey.getState(
    editor.state
  );
  const isHoveredBlockNodeTable =
    hoveringBlockPluginState?.hoveredBlockNode?.type.name === 'table';

  const getTooltipText = () => {
    const defaultTooltipItems = ['Drag to move', 'Click to open menu'];

    if (isHoveredBlockNodeTable)
      defaultTooltipItems.push('Click to select table');

    return (
      <div className="text-center">
        {defaultTooltipItems.map((text) => (
          <p key={text}>{text}</p>
        ))}
      </div>
    );
  };

  const handleSelect = () => {
    if (!isHoveredBlockNodeTable) {
      return;
    }

    if (hoveringBlockPluginState.hoveredBlockPosition !== null) {
      editor.commands.setSelectedTable(
        hoveringBlockPluginState.hoveredBlockPosition
      );
    }
  };

  return (
    <HoveringBlockMenu editor={editor} tags={tags} hoveringBlockWidth={80}>
      <InsertAndDragButtons
        className="-translate-y-[3px] bg-base-white-75 relative after:block after:absolute after:-right-2 after:w-2 after:h-9"
        handleSelect={handleSelect}
        dragButtonTooltip={getTooltipText()}
      />
    </HoveringBlockMenu>
  );
};
