import { PropsWithChildren } from 'react';
import { IconMap } from '../../../../../../shared/sprite';
import { Icon } from '../../../../../../shared/ui';

export type ProTipLinkProps = {
  linkHref: string;
};

export const ProTipLink = ({
  linkHref,
  children,
}: PropsWithChildren<ProTipLinkProps>) => {
  return (
    <a
      href={linkHref}
      className="inline-flex items-center gap-1 font-semibold text-primary-700 hover:text-primary-800 group !no-underline"
      target="_blank"
      rel="noreferrer"
    >
      {children}
      <Icon
        glyph={IconMap.ArrowSquareUpRight}
        width={16}
        className="text-primary-700 group-hover:text-primary-800"
      />
    </a>
  );
};
