import { takeEvery } from 'redux-saga/effects';
import { actions } from '../reducer';
import { changeLogo, deleteLogo } from './changeLogo';
import { updateTeamBrandInfo } from './updateTeamBrandInfo';

export function* teamBrandSettingsWorker() {
  yield takeEvery(actions.changeLogo, changeLogo);
  yield takeEvery(actions.deleteLogo, deleteLogo);
  yield takeEvery(actions.updateTeamBrandInfo, updateTeamBrandInfo);
}
