import classNames from 'classnames';

const secondaryActionsStyle = `text-gray-700 enabled:hover:bg-gray-25 enabled:focus:bg-gray-25 hover:text-gray-800 focus:text-gray-800
active:border-gray-100 disabled:border-gray-200 disabled:text-gray-300`;

const style = `flex items-center justify-center whitespace-nowrap rounded-lg border box-border relative leading-10 font-base disabled:cursor-not-allowed`;

const primaryStyle = `border-transparent bg-primary-700 text-base-white hover:bg-primary-800 focus:bg-primary-800
    active:bg-primary-700 disabled:bg-gray-200 disabled:border-transparent leading-10`;

const primary50Style = `border-transparent bg-primary-50 active:bg-primary-50 !text-primary-700 disabled:bg-gray-200 hover:bg-primary-100 focus:bg-primary-100 disabled:border-transparent leading-10`;

const secondaryStyle = `bg-base-white border-gray-300 ${secondaryActionsStyle}`;

const linkStyle = `bg-transparent text-primary-700 border-none hover:text-primary-800 focus:text-primary-800 disabled:text-gray-300`;

const linkDestructiveStyle = `bg-transparent text-error-700 border-none hover:text-error-800 focus:text-error-800 disabled:text-gray-300`;

const tertiaryBlackStyle = `bg-transparent text-gray-600 border-none enabled:hover:bg-gray-50 enabled:focus:bg-gray-50 disabled:text-gray-300`;

const destructiveStyle = `bg-error-600 border-error-600 text-base-white hover:bg-error-700 focus:bg-error-700 hover:border-error-700 focus:border-error-700 active:bg-error-600
    disabled:bg-error-200 disabled:border-error-200`;

const secondaryDestructiveStyle = `bg-base-white border-error-300 text-error-700 hover:bg-error-50 hover:text-error-800 focus:bg-error-50 focus:text-error-800
    disabled:text-error-300 disabled:border-error-200`;

const brandStyle = `bg-brand-700 enabled:hover:bg-brand-800 enabled:focus:bg-brand-800 active:bg-brand-700 text-brand-text font-paragraph border-transparent disabled:border-transparent leading-10`;

const linkGrayStyle = `bg-transparent !p-0 !h-auto text-gray-600 border-transparent hover:text-gray-700 focus:text-gray-700 disabled:text-gray-300`;

const primaryGradientStyle = `bg-gradient-to-r from-[#109F8F] to-[#05A867] text-base-white hover:from-[#125D56] hover:to-[#009158] disabled:from-[#D6D6D6] disabled:to-[#E5E5E5] shadow-xs border-none`;

const transparentStyle = `bg-transparent text-gray-600 border-none disabled:text-gray-300 hover:bg-gray-50`;

const transparentWithActionsStyle = `${transparentStyle} ${secondaryActionsStyle}`;

const sizeSm = `h-9 px-3.5 text-s`;
const sizeMd = `h-10 px-4 text-s`;
const sizeLg = `h-11 px-4.5 text-md`;
const sizeXl = `h-12 px-4.5 text-md`;

export type ButtonVariant = 'text' | 'icon' | 'icon-text';

export type ButtonColor =
  | 'primary'
  | 'primary-50'
  | 'brand'
  | 'secondary'
  | 'link'
  | 'link-destructive'
  | 'tertiary-black'
  | 'destructive'
  | 'secondary-destructive'
  | 'link-gray'
  | 'transparent'
  | 'transparent-with-actions'
  | 'primary-gradient';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type ButtonStylesProps = {
  className?: string;
  fontBold?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  color: ButtonColor;
};

export const buttonStyles = ({
  className,
  fontBold,
  fullWidth,
  variant,
  size,
  color,
}: ButtonStylesProps) =>
  classNames(
    style,
    {
      'font-medium': !fontBold,
      'font-bold': fontBold,
      'w-full': fullWidth,
      '!p-0': variant === 'icon',
      'w-6 min-w-6 h-6 min-h-6': variant === 'icon' && size === 'xs',
      'w-9 min-w-9 h-9 min-h-9': variant === 'icon' && size === 'sm',
      'w-10 min-w-10 h-10 min-h-10': variant === 'icon' && size === 'md',
      'w-11 min-w-11 h-11 min-h-11': variant === 'icon' && size === 'lg',
      'w-12 min-w-12 h-12 min-h-12': variant === 'icon' && size === 'xl',
      [primaryStyle]: color === 'primary',
      [primary50Style]: color === 'primary-50',
      [secondaryStyle]: color === 'secondary',
      [linkStyle]: color === 'link',
      [linkDestructiveStyle]: color === 'link-destructive',
      [tertiaryBlackStyle]: color === 'tertiary-black',
      [destructiveStyle]: color === 'destructive',
      [secondaryDestructiveStyle]: color === 'secondary-destructive',
      [brandStyle]: color === 'brand',
      [linkGrayStyle]: color === 'link-gray',
      [transparentStyle]: color === 'transparent',
      [transparentWithActionsStyle]: color === 'transparent-with-actions',
      [primaryGradientStyle]: color === 'primary-gradient',
      [sizeSm]: size === 'sm',
      [sizeMd]: size === 'md',
      [sizeLg]: size === 'lg',
      [sizeXl]: size === 'xl',
    },
    className
  );
