import { createContext, useContext } from 'react';
import * as Y from 'yjs';

type SuggestionsEditorContextType = {
  isSuggestionMode: boolean;
  diffDoc?: Y.Doc;
  onAccept: () => void;
  onReject: () => void;
};

export const SuggestionsEditorContext =
  createContext<SuggestionsEditorContextType>({
    isSuggestionMode: false,
    diffDoc: undefined,
    onAccept: () => null,
    onReject: () => null,
  });

export const useEditorSuggestion = () => {
  const context = useContext(SuggestionsEditorContext);
  if (context === null) {
    throw new Error(
      'useSuggestion must be used within a SuggestionsEditorProvider'
    );
  }
  return context;
};
