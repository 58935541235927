import { call, put } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { TasklistWithTasks } from '@distribute/shared/types';
import { tasksApi } from '../../../../../src/shared/api';
import { tasksModel } from '..';

export function* setTasklists(contentId: number) {
  try {
    const tasklistsData: Record<string, TasklistWithTasks> = yield call(
      tasksApi.getTasklistsData,
      contentId
    );

    yield put(tasksModel.actions.setTasklists(tasklistsData));
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to set tasklists')
      )
    );
  }
}
