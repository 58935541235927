import { RootState } from '../../app';

export const selectWithError =
  <T>(selector: (state: RootState) => T | undefined, selectorTarget: string) =>
  (state: RootState) => {
    const selectedState = selector(state);
    if (selectedState === undefined) {
      throw new Error(`${selectorTarget} is undefined`);
    }
    return selectedState;
  };

export const withStrictError = <T>(
  data: T | undefined | null,
  target: string
): T => {
  if (!data) {
    throw new Error(`${target} is not accessible`);
  }
  return data;
};
