import { pagesApi } from '../../../../shared/api';
import { call, put } from 'redux-saga/effects';
import { RT } from '../../../../shared/types';
import { redirect } from '../../../../entities/history';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { QUERY_PARAM_OPENED_TASK } from '../../config';

export function* redirectToPublicPage(sequenceNumber: string) {
  try {
    const publicInfo: RT<typeof pagesApi.getPagePublicInfo> = yield call(
      pagesApi.getPagePublicInfo,
      sequenceNumber
    );
    if (publicInfo.publishedPageUrl) {
      const taskId = new URL(window.location.href).searchParams.get(
        QUERY_PARAM_OPENED_TASK
      );
      const publishedPageUrl = new URL(publicInfo.publishedPageUrl);

      if (taskId) {
        publishedPageUrl.searchParams.set(QUERY_PARAM_OPENED_TASK, taskId);
      }
      window.location.href = publishedPageUrl.toString();
    } else {
      yield call(redirect.toWorkspace);
    }
  } catch (e: unknown) {
    logger.error(e);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to show page')
      )
    );

    yield call(redirect.toWorkspace);
  }
}
