import React from 'react';

import { CallSummarySalesContent } from '@distribute/shared/types';
import { RenderList } from '../../../shared/ui';

import {
  Section,
  Subsection,
  ActionItem,
  BulletList,
  NumberedList,
} from './CallSummaryComponents';

export const CallSummarySales: React.FC<{
  content: CallSummarySalesContent;
}> = ({ content }) => {
  return (
    <div className="call-summary-sales flex flex-col gap-6 text-gray-900 text-md">
      <Section title="Deal Information">
        <div className="flex flex-col gap-4">
          <Subsection title="Prospect Details">
            <BulletList
              items={[
                { label: 'Company', value: content.prospect.company },
                { label: 'Contacts', value: content.prospect.contacts },
                { label: 'Description', value: content.prospect.description },
              ]}
              renderItem={(item) => (
                <>
                  <span className="font-bold">{item.label}: </span>
                  {item.value}.
                </>
              )}
            />
          </Subsection>
          <Subsection title="Call Context">
            <p>{content.callContext}.</p>
          </Subsection>
          <Subsection title="Seats">
            <p>{content.seats}.</p>
          </Subsection>
          <Subsection title="Budget">
            <p>{content.budget}.</p>
          </Subsection>
          <Subsection title="Current State">
            <p>{content.currentState}.</p>
          </Subsection>
          <RenderList list={content.painPoints}>
            <Subsection title="Pain Points">
              <BulletList
                items={content.painPoints}
                renderItem={(text) => `${text}.`}
              />
            </Subsection>
          </RenderList>
          <RenderList list={content.specificRequirements}>
            <Subsection title="Specific Requirements">
              <BulletList
                items={content.specificRequirements}
                renderItem={(text) => `${text}.`}
              />
            </Subsection>
          </RenderList>
          <RenderList list={content.techStack}>
            <Subsection title="Tech Stack">
              <BulletList
                items={content.techStack}
                renderItem={(text) => text}
              />
            </Subsection>
          </RenderList>
          <RenderList list={content.objections}>
            <Subsection title="Objections">
              <NumberedList
                items={content.objections}
                renderItem={(obj) => (
                  <>
                    <span className="font-bold">Concern: </span>
                    {obj.objection}.
                    <ul className="list-disc pl-6">
                      <li>
                        <span className="font-bold">Status: </span>
                        {obj.status}
                      </li>
                      <li>
                        <span className="font-bold">Resolution: </span>
                        {obj.resolution}.
                      </li>
                    </ul>
                  </>
                )}
              />
            </Subsection>
          </RenderList>
          <RenderList list={content.otherSolutions}>
            <Subsection title="Competitive Landscape">
              <p className="mb-2">Other solutions being evaluated:</p>
              <BulletList
                items={content.otherSolutions}
                renderItem={(solution) => solution}
              />
            </Subsection>
          </RenderList>
          <Subsection title="Timeline">
            <p>{content.timeline}.</p>
          </Subsection>

          <Subsection title="Decision Maker">
            <NumberedList
              items={[
                { label: 'Primary', value: content.decisionMaker.primary },
                {
                  label: 'Other stakeholders',
                  value: content.decisionMaker.otherStakeholders,
                },
              ]}
              renderItem={(item) => (
                <>
                  <span className="font-bold">{item.label}: </span>
                  {item.value}
                </>
              )}
            />
          </Subsection>
          <Subsection title="Procurement Process">
            <p>{content.procurementProcess}.</p>
          </Subsection>
        </div>
      </Section>
      <RenderList list={content.nextSteps}>
        <Section title="Next Steps">
          <BulletList<string>
            items={content.nextSteps}
            renderItem={(text) => `${text}.`}
          />
        </Section>
      </RenderList>
      <RenderList
        list={[...content.questionsWeAsked, ...content.questionsTheyAsked]}
      >
        <Section title="Q&A">
          <RenderList list={content.questionsWeAsked}>
            <Subsection title="Questions We Asked">
              <NumberedList
                items={content.questionsWeAsked}
                renderItem={(q) => (
                  <>
                    <span className="font-bold">{q.question}</span>
                    <ul className="list-disc pl-6">
                      <li>{q.response}.</li>
                    </ul>
                  </>
                )}
              />
            </Subsection>
          </RenderList>
          <RenderList list={content.questionsTheyAsked}>
            <Subsection title="Questions They Asked">
              <NumberedList
                items={content.questionsTheyAsked}
                renderItem={(q) => (
                  <>
                    <span className="font-bold">{q.question}</span>
                    <ul className="list-disc pl-6">
                      <li>{q.response}.</li>
                    </ul>
                  </>
                )}
              />
            </Subsection>
          </RenderList>
        </Section>
      </RenderList>
      <RenderList list={content.actionItems}>
        <Section title="Action Items">
          <BulletList
            items={content.actionItems}
            renderItem={(item) => <ActionItem item={item} />}
          />
        </Section>
      </RenderList>
    </div>
  );
};
