import { useDispatch, useSelector } from 'react-redux';
import { templatesModel } from '../../../../templates';
import {
  DocumentContentItem,
  TemplateCategory,
  TemplateCreationPhase,
  TemplateSharingAccess,
} from '@distribute/shared/types';
import { FC, useMemo, useState } from 'react';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { TemplateGroup } from './TemplateGroup';
import { getSortedTemplates } from '../../../../../../src/pages/templates/lib';
import {
  SortState,
  templatesConfig,
  TemplatesConfigEnum,
  TemplatesTabEnum,
} from './lib';
import { Preview } from './Preview';
import { TemplateItem } from './TemplateItem';
import { SidebarTabLayout } from '../shared/SidebarTabLayout';
import { TemplatesLayoutHeader } from './TemplatesLayoutHeader';
import {
  getIsContentEmpty,
  useTiptapEditor,
} from '../../../../../../src/entities/tiptap-editor';
import { pagesModel } from '../../../../../../src/features/pages';
import { ReplaceTabsModal } from './ReplaceTabsModal';
import { useCollaboration } from '../../../../../../src/entities/collaboration';
import { TemplatesTabContext } from './context';
import { editorLeftSidebarModel } from '../../../model';
import { NoTemplatesFoundScreen } from './NoTemplatesFoundScreen';

export const TemplatesTab: FC = () => {
  const dispatch = useDispatch();
  const { editor } = useTiptapEditor();

  const currentPageOrTemplate = useSelector(
    editorLeftSidebarModel.selectors.selectCurrentPageOrTemplateWithError
  );

  const currentPageContentId = useSelector(
    pagesModel.selectors.selectCurrentContentId
  ) as number;

  const isTemplateMode = Boolean(
    useSelector(templatesModel.selectors.selectCurrentTemplate)
  );

  const currentTemplateContentId = useSelector(
    templatesModel.selectors.selectCurrentTemplateContentId
  ) as number;

  const { tabs: collaborativeTabs } = useCollaboration();

  const currentContentId = isTemplateMode
    ? currentTemplateContentId
    : currentPageContentId;

  const content = currentPageOrTemplate?.content;

  const currentTab = isTemplateMode
    ? (content?.contentItems.find(
        ({ id }) => id === currentContentId
      ) as DocumentContentItem)
    : (collaborativeTabs.find(
        (tab) => tab.id === currentContentId
      ) as DocumentContentItem);
  const [replacingTemplate, setReplacingTemplate] =
    useState<TemplateExtended | null>(null);

  const [selectedTab, setSelectedTab] = useState<string>(
    TemplatesTabEnum.SINGE_PAGE
  );

  const [templatePreview, setTemplatePreview] =
    useState<TemplateExtended | null>(null);

  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [sortMethod, setSortMethod] = useState<SortState>(
    SortState.LAST_CREATED
  );

  const templates = useSelector(
    templatesModel.selectors.selectTemplates
  ).filter((t) => t.creationPhase !== TemplateCreationPhase.DRAFT);

  const filteredTemplates = useMemo(() => {
    return templates.filter((t) =>
      selectedTab === TemplatesTabEnum.SINGE_PAGE
        ? t.isSinglePage
        : !t.isSinglePage
    );
  }, [templates, selectedTab]);

  const personalTemplates = useMemo(
    () =>
      filteredTemplates.filter(
        (el) =>
          el.sharingAccess === TemplateSharingAccess.PERSONAL &&
          el.category === null
      ),
    [filteredTemplates]
  );
  const teamTemplates = useMemo(
    () =>
      filteredTemplates.filter(
        (el) =>
          el.sharingAccess === TemplateSharingAccess.TEAM &&
          el.category === null
      ),
    [filteredTemplates]
  );

  const categoriesTemplatesMap = filteredTemplates.reduce(
    (prev: { [key in TemplateCategory]?: TemplateExtended[] }, template) => {
      if (!template.category) return prev;
      if (prev[template.category]) {
        prev[template.category]?.push(template);
      } else {
        prev[template.category] = [template];
      }
      return prev;
    },
    {}
  );

  const templatesDictionary = useMemo(
    () => ({
      [TemplatesConfigEnum.PERSONAL]: personalTemplates,
      [TemplatesConfigEnum.TEAM]: teamTemplates,
      ...categoriesTemplatesMap,
    }),
    [categoriesTemplatesMap, personalTemplates, teamTemplates]
  );

  const templateGroupsToRender = useMemo(() => {
    return templatesConfig
      .map((group) => {
        return {
          ...group,
          items: group.items
            .map((item) => {
              return {
                ...item,
                templates: getSortedTemplates(
                  templatesDictionary[item.key] || [],
                  sortMethod
                ),
              };
            })
            .filter((i) => i.templates && i.templates.length > 0),
        };
      })
      .filter((g) =>
        g.items.some((i) => i.templates && i.templates.length > 0)
      );
  }, [sortMethod, templatesDictionary]);

  // remove template.isSinglePage when will be able to use multi-tab templates
  const foundTemplates = useMemo(() => {
    return templates.filter(
      (template) =>
        new RegExp(searchQuery, 'ig').test(template.name) &&
        template.isSinglePage
    );
  }, [templates, searchQuery]);

  const handleSearchQueryChange = (value: string) => {
    setSearchQuery(value);
  };

  const handlePreviewTemplate = (template: TemplateExtended) => {
    setTemplatePreview(template);
  };

  const handleCloseTemplatePreview = () => {
    setTemplatePreview(null);
  };

  const handleChooseTemplate = () => {
    if (isTemplateMode) {
      const templateId = currentPageOrTemplate.id as number;
      dispatch(
        templatesModel.actions.updateContentItemFromTemplate({
          templateId,
          contentItemId: currentTab.id,
          cb: (template) => {
            const contentItem = template.content.contentItems.find(
              ({ id }) => id === currentTab.id
            );
            if (contentItem?.contentJson) {
              editor?.commands.setContent(contentItem.contentJson);
            }
          },
        })
      );
    } else {
      dispatch(
        pagesModel.actions.updatePageFromTemplate({
          pageId: currentPageOrTemplate.id as string,
          contentItemId: currentTab.id,
          cb: (page) => {
            const pageContent = page.content.contentItems[0]?.contentJson;
            editor?.commands.setContent(pageContent);
          },
        })
      );
    }
    setReplacingTemplate(null);
  };

  const handleReplaceTab = (template: TemplateExtended) => {
    dispatch(templatesModel.actions.setCurrentSelectedTemplate(template));
    handleChooseTemplate();
  };

  const handleUseTemplateClick = (template: TemplateExtended) => {
    const isContentEmpty = getIsContentEmpty(editor);

    if (!isContentEmpty) {
      setReplacingTemplate(template);
      return;
    }

    dispatch(templatesModel.actions.setCurrentSelectedTemplate(template));
    handleChooseTemplate();
  };

  const clearSearchQuery = () => {
    setSearchQuery('');
  };

  return (
    <TemplatesTabContext.Provider
      value={{ handlePreviewTemplate, handleUseTemplateClick }}
    >
      <div className="w-153">
        {replacingTemplate && (
          <ReplaceTabsModal
            isOpen={Boolean(replacingTemplate)}
            handleReplaceTab={handleReplaceTab}
            template={replacingTemplate}
            onClose={() => {
              setReplacingTemplate(null);
            }}
          />
        )}
        {templatePreview ? (
          <Preview
            template={templatePreview}
            handleCloseTemplatePreview={handleCloseTemplatePreview}
          />
        ) : (
          <SidebarTabLayout
            headerComponent={
              <TemplatesLayoutHeader
                isSearchInputOpen={isSearchInputOpen}
                searchQuery={searchQuery}
                setIsSearchInputOpen={setIsSearchInputOpen}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                handleSearchQueryChange={handleSearchQueryChange}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
              />
            }
          >
            {searchQuery ? (
              foundTemplates.length > 0 ? (
                <div className="grid flex-grow grid-cols-2 gap-3 overflow-x-hidden overflow-y-auto place-content-start">
                  {foundTemplates.map((template) => (
                    <TemplateItem key={template.id} template={template} />
                  ))}
                </div>
              ) : (
                <NoTemplatesFoundScreen clearSearchQuery={clearSearchQuery} />
              )
            ) : (
              <div>
                {templateGroupsToRender.map((group) => (
                  <TemplateGroup
                    key={group.groupName}
                    name={group.groupName}
                    items={group.items}
                  />
                ))}
              </div>
            )}
          </SidebarTabLayout>
        )}
      </div>
    </TemplatesTabContext.Provider>
  );
};
