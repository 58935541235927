import { call, select } from 'redux-saga/effects';
import { teamsModel } from '../../../teams';
import { callApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { logger } from '../../../../shared/lib';

export function* getCallById(id: string) {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  try {
    const callDetails: RT<typeof callApi.getCallDetails> = yield call(
      callApi.getCallDetails,
      id,
      { teamId: team.id }
    );

    return callDetails;
  } catch (err) {
    logger.error('Failed to load call details');
    throw err;
  }
}
