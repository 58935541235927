import { useFormContext } from 'react-hook-form';
import { CreatePageFormSchemaType } from '../../config/types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../model';

export function useResetOutline() {
  const [previousState, setPreviousState] = useState('');
  const { watch } = useFormContext<CreatePageFormSchemaType>();
  const dispatch = useDispatch();

  const generatedOutline = useSelector(
    pageCreationFlowModel.selectors.selectGeneratedOutline
  );

  const isBranded = watch('isBranded');
  const brandCompany = watch('brandCompany');
  const pageType = watch('pageType');
  const pageTypeDescription = watch('pageTypeDescription');
  const transcriptsFilter = watch('transcriptsFilter');
  const selectedCallerName = watch('selectedCallerName');
  const selectedTranscripts = watch('selectedTranscripts');
  const selectedDataSource = watch('selectedDataSource');
  const text = watch('text');
  const links = watch('links');
  const files = watch('files');

  const currentState = JSON.stringify({
    isBranded,
    brandCompany,
    pageType,
    pageTypeDescription,
    transcriptsFilter,
    selectedCallerName,
    selectedTranscripts,
    selectedDataSource,
    text,
    links,
    files,
  });

  useEffect(() => {
    if (!generatedOutline.length) {
      setPreviousState(currentState);
    }
  }, [currentState, generatedOutline]);

  useEffect(() => {
    if (generatedOutline.length && previousState !== currentState) {
      dispatch(pageCreationFlowModel.actions.setGeneratedOutline([]));
      dispatch(
        pageCreationFlowModel.actions.setWasOutlineGenerationStarted(false)
      );
    }
  }, [currentState, dispatch, generatedOutline, previousState]);
}
