import { put } from 'redux-saga/effects';

import { pageCreationFlowModel } from '../../../../processes/page-creation-flow';

export function* createPageFromGongTranscript(gongCallId: string) {
  yield put(
    pageCreationFlowModel.actions.openPageCreationFlow({
      gongCallTranscriptId: gongCallId,
    })
  );
  yield;
}
