import { Extension } from '@tiptap/core';
import { DropCursorOptions, dropCursor } from './DropCursorPlugin';
import { multipleColumnsDropCursor } from './MulticolumnDropCursor';
import { Editor } from '@tiptap/react';
import {
  DropBlockDirection,
  DropColumnDirection,
  DropColumnPosition,
} from './types';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    dropCursor: {
      setDropColumnTarget: (target: HTMLElement | null) => ReturnType;
      setDropBlockTarget: (target: HTMLElement | null) => ReturnType;
      setDropColumnDirection: (direction: DropColumnDirection) => ReturnType;
      setDropBlockDirection: (direction: DropBlockDirection) => ReturnType;
      setDropColumnPosition: (position: DropColumnPosition) => ReturnType;
    };
  }
}

export const DropCursor = Extension.create<DropCursorOptions>({
  name: 'dropCursor',

  addOptions() {
    return {
      color: 'currentColor',
      width: 6,
      class: 'text-primary-500 rounded-lg border-2 border-primary-100 !z-0',
    };
  },
  addStorage() {
    return {
      dropColumnTarget: null as HTMLElement | null,
      dropBlockTarget: null as HTMLElement | null,
      dropColumnDirection: null as DropColumnDirection | null,
      dropBlockDirection: null as DropBlockDirection | null,
      dropColumnPosition: null as DropColumnPosition,
    };
  },

  addProseMirrorPlugins() {
    return [
      dropCursor(this.options, this.editor as Editor),
      multipleColumnsDropCursor(this.options, this.editor as Editor),
    ];
  },

  addCommands() {
    return {
      setDropColumnTarget: (target: HTMLElement | null) => () => {
        this.storage.dropColumnTarget = target;
        return true;
      },
      setDropBlockTarget: (target: HTMLElement | null) => () => {
        this.storage.dropBlockTarget = target;
        if (target !== null) {
          document.querySelector('.column-drop-cursor')?.remove();
          this.storage.dropColumnTarget = null;
        }
        return true;
      },
      setDropColumnDirection: (direction: DropColumnDirection | null) => () => {
        this.storage.dropColumnDirection = direction;
        return true;
      },
      setDropBlockDirection: (direction: DropBlockDirection | null) => () => {
        this.storage.dropBlockDirection = direction;
        return true;
      },
      setDropColumnPosition: (position: DropColumnPosition) => () => {
        this.storage.dropColumnPosition = position;
        return true;
      },
    };
  },
});
