import { useDispatch, useSelector } from 'react-redux';
import { IconMap } from '../../../../../../shared/sprite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { pagesModel } from '../../../../../../features/pages';
import {
  Button,
  Icon,
  Input,
  Toggle,
  Tooltip,
} from '../../../../../../shared/ui';
import { getFullPageUrl, getPageGmailUrl } from '../../../../../../shared/lib';
import { teamsModel } from '../../../../../../features/teams';
import cn from 'classnames';
import {
  ActiveConversionSettingsPanel,
  SidebarStepsEnum,
  editorSidebarModel,
} from '../../../../../../features/editor-sidebar';
import { ConversionLabelToggle } from '../../ConversionLabelToggle';
import { conversionKitModel } from '../../../../../../features/conversion-kit';
import { gatedContentModel } from '../../../../../../features/gated-content-block';
import { squeezePageModel } from '../../../../../../features/squeeze-page';
import { subscribePopupModel } from '../../../../../../features/subscribe-popup';
import {
  ActionTypeAlertBar,
  ActionTypeCTA,
  ActionTypePopUp,
  subscriptionLimits,
} from '@distribute/shared/types';
import { customDomainsModel } from '../../../../../../features/custom-domains';
import { RequireEmailToViewConversionItem } from '../../../../../../widgets/editor-sidebar/ui/components/conversion-tab/RequireEmailToViewConversionItem';
import { useCurrentPlan } from '../../../../../../features/subscription/hooks';
import { useOnClickOutside } from '../../../../../../shared/hooks/useClickOutside';
import { subscriptionModel } from '../../../../../../features/subscription';
import {
  createNotification,
  snackbarModel,
} from '../../../../../../features/snackbar';
import { createPageQueryRandomValue } from '../../../../../../features/pages/lib';
import { useCollaboration } from '../../../../../../entities/collaboration';
import {
  editorLeftSidebarModel,
  LeftSidebarOptions,
} from '../../../../../../features/editor-left-sidebar';

type Props = {
  closeModal(): void;
  checkRedirectToEditorPage?(): void;
  isFolderPage?: boolean;
};

export const SharePubliclyTab: React.FC<Props> = ({
  closeModal,
  checkRedirectToEditorPage = () => {
    return;
  },
  isFolderPage,
}) => {
  const dispatch = useDispatch();

  const {
    isCollaborationEnabled,
    published,
    updatePublished,
    isViewersCanDuplicateAsTemplate:
      isCollaborationViewersCanDuplicateAsTemplate,
    updateIsViewersCanDuplicateAsTemplate:
      updateIsCollaborationViewersCanDuplicateAsTemplate,
    isDistributeBadgeRemoved: isCollaborationDistributeBadgeRemoved,
    updateIsDistributeBadgeRemoved: updateIsCollaborationDistributeBadgeRemoved,
    alertBarData,
    popUpData,
    ctaData,
    gatedContentData,
    squeezePageData,
    updateAlertBarField,
    updatePopupField,
    updateCTAField,
    updateGatedContentField,
    updateSqueezePageField,
  } = useCollaboration();

  const isSoloMode = !isCollaborationEnabled || isFolderPage;

  const { isActive: isCollaborationAlertBarActive } = alertBarData;
  const { isActive: isCollaborationPopUpActive } = popUpData;
  const { isActive: isCollaborationCTAActive } = ctaData;
  const { isActive: isCollaborationGatedContentActive } = gatedContentData;
  const { isActive: isCollaborationSqueezePageActive } = squeezePageData;

  const publishedPagesCount = useSelector(
    pagesModel.selectors.selectPublishedPagesCount
  );
  const { id: currentPlanId } = useCurrentPlan();

  const { maxPublishedPagesCount } = subscriptionLimits[currentPlanId];

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const isPublishingDisabled =
    publishedPagesCount >= maxPublishedPagesCount && !published;

  const handleUpgradeClick = () => {
    if (isPublishingDisabled) {
      dispatch(subscriptionModel.actions.setIsShowPaywallModal(true));
    }
  };

  const { domain: currentTeamDomain } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const customDomain = useSelector(
    customDomainsModel.selectors.selectVerifiedCustomDomain
  );

  const pageUrl = getFullPageUrl({
    slug: currentPage.pageSlug,
    domain: currentTeamDomain,
    customDomain,
  });

  const pageUrlBlockRef = useRef<HTMLDivElement | null>(null);

  const [isCopiedTooltip, setIsCopiedTooltip] = useState(false);

  useOnClickOutside(pageUrlBlockRef, () => {
    setIsCopiedTooltip(false);
  });

  const slugRef = useRef<HTMLInputElement>(null);

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(`${pageUrl}?${createPageQueryRandomValue()}`);
    slugRef.current?.select();

    setIsCopiedTooltip(true);
  }, [pageUrl]);

  useEffect(() => {
    dispatch(editorSidebarModel.actions.setShouldFocusSlugInput(false));
  }, [dispatch]);

  const handleOpenEditPageSlug = () => {
    dispatch(
      editorLeftSidebarModel.actions.setSelectedOption(
        LeftSidebarOptions.SETTINGS
      )
    );
    checkRedirectToEditorPage();
    closeModal();
  };

  const handleOpenAdvancedSettings = () => {
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setSelectedSidebarStep(
        SidebarStepsEnum.SETTINGS
      )
    );
    dispatch(editorSidebarModel.actions.setIsShowAdvancedSettings(true));
    checkRedirectToEditorPage();
    closeModal();
  };

  const alertBar = useSelector(
    conversionKitModel.selectors.selectAlertBarWithError
  );
  const popUp = useSelector(conversionKitModel.selectors.selectPopUpWithError);
  const cta = useSelector(conversionKitModel.selectors.selectCTAWithError);
  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContentWithError
  );
  const squeezePage = useSelector(
    conversionKitModel.selectors.selectSqueezePageWithError
  );

  const [
    isSoloViewersCanDuplicateAsTemplate,
    setIsSoloViewersCanDuplicateAsTemplate,
  ] = useState(currentPage.isViewersCanDuplicateAsTemplate);

  const [isSoloDistributeBadgeRemoved, setIsSoloDistributeBadgeRemoved] =
    useState(currentPage.isDistributeBadgeRemoved);

  const [isSoloAlertBarActive, setSoloAlertBarActive] = useState(
    alertBar.isActive
  );
  const [isSoloPopUpActive, setSoloPopUpActive] = useState(popUp.isActive);
  const [isSoloCTAActive, setSoloCTAActive] = useState(cta.isActive);

  const [isSoloGatedContentActive, setSoloGatedContentActive] = useState(
    gatedContent.isActive
  );
  const [isSoloSqueezePageActive, setSoloSqueezePageActive] = useState(
    squeezePage.isActive
  );

  const isDistributeBadgeRemoved = isSoloMode
    ? isSoloDistributeBadgeRemoved
    : isCollaborationDistributeBadgeRemoved;

  const isViewersCanDuplicateAsTemplate = isSoloMode
    ? isSoloViewersCanDuplicateAsTemplate
    : isCollaborationViewersCanDuplicateAsTemplate;

  const handleChangeIsViewersCanDuplicateAsTemplate = (value: boolean) => {
    if (isSoloMode) {
      setIsSoloViewersCanDuplicateAsTemplate(value);
      dispatch(
        pagesModel.actions.updatePage({
          pageId: currentPage.id,
          isViewersCanDuplicateAsTemplate: value,
        })
      );
    } else {
      updateIsCollaborationViewersCanDuplicateAsTemplate(value);
    }
  };

  const handleChangeIsDistributeBadgeRemoved = (value: boolean) => {
    if (isSoloMode) {
      setIsSoloDistributeBadgeRemoved(value);
      dispatch(
        pagesModel.actions.updatePage({
          pageId: currentPage.id,
          isDistributeBadgeRemoved: value,
        })
      );
    } else {
      updateIsCollaborationDistributeBadgeRemoved(value);
    }
  };

  const toggleAlertBar = () => {
    if (isSoloMode) {
      dispatch(
        conversionKitModel.actions.updateAlertBar({
          documentContentId: currentPage.content.id,
          isActive: !isSoloAlertBarActive,
        })
      );
      setSoloAlertBarActive(!isSoloAlertBarActive);
    } else {
      updateAlertBarField('isActive', !isCollaborationAlertBarActive);
    }
  };
  const togglePopUpStatus = () => {
    if (isSoloMode) {
      dispatch(
        conversionKitModel.actions.updatePopUp({
          documentContentId: currentPage.content.id,
          isActive: !isSoloPopUpActive,
        })
      );
      dispatch(subscribePopupModel.actions.setIsModalOpen(false));
      setSoloPopUpActive(!isSoloPopUpActive);
    } else {
      updatePopupField('isActive', !isCollaborationPopUpActive);
    }
  };

  const toggleCTAStatus = () => {
    if (isSoloMode) {
      dispatch(
        conversionKitModel.actions.updateCTA({
          documentContentId: currentPage.content.id,
          isActive: !isSoloCTAActive,
        })
      );
      setSoloCTAActive(!isSoloCTAActive);
    } else {
      updateCTAField('isActive', !isCollaborationCTAActive);
    }
  };
  const toggleGatedContentStatus = () => {
    if (isSoloMode) {
      dispatch(
        conversionKitModel.actions.updateGatedContent({
          documentContentId: currentPage.content.id,
          isActive: !isSoloGatedContentActive,
        })
      );
      dispatch(gatedContentModel.actions.setIsModalOpen(false));
      setSoloGatedContentActive(!isSoloGatedContentActive);
    } else {
      updateGatedContentField('isActive', !isCollaborationGatedContentActive);
    }
  };
  const toggleSqueezePageStatus = () => {
    if (isSoloMode) {
      dispatch(
        conversionKitModel.actions.updateSqueezePage({
          documentContentId: currentPage.content.id,
          isActive: !isSoloSqueezePageActive,
        })
      );
      dispatch(squeezePageModel.actions.setIsOpen(false));
      setSoloSqueezePageActive(!isSoloSqueezePageActive);
    } else {
      updateSqueezePageField('isActive', !isCollaborationSqueezePageActive);
    }
  };

  useEffect(() => {
    setSoloAlertBarActive(alertBar.isActive);
  }, [alertBar.isActive]);

  useEffect(() => {
    setSoloPopUpActive(popUp.isActive);
  }, [popUp.isActive]);

  useEffect(() => {
    setSoloCTAActive(cta.isActive);
  }, [cta.isActive]);

  useEffect(() => {
    setSoloGatedContentActive(gatedContent.isActive);
  }, [gatedContent.isActive]);

  const handleOpenSidebar = () => {
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
  };

  const handleEditAlertBar = () => {
    document.getElementById('root')?.scrollTo({ top: 0, behavior: 'smooth' });
    handleOpenSidebar();
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.ALERT_BAR
      )
    );
    checkRedirectToEditorPage();
    closeModal();
  };

  const handleEditPopUp = () => {
    handleOpenSidebar();
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.POP_UP
      )
    );
    checkRedirectToEditorPage();
    closeModal();
  };

  const handleEditPopupTriggers = () => {
    handleOpenSidebar();
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.POP_UP
      )
    );
    dispatch(editorSidebarModel.actions.setSelectedPopupSection('triggers'));
    checkRedirectToEditorPage();
    closeModal();
  };

  const handleEditCTA = () => {
    const root = document.getElementById('root');
    handleOpenSidebar();
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.CTA
      )
    );
    setTimeout(() => {
      root?.scrollTo({
        top: root.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
    checkRedirectToEditorPage();
    closeModal();
  };

  const handleEditGatedContent = () => {
    handleOpenSidebar();
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.GATED_CONTENT
      )
    );
    checkRedirectToEditorPage();
    closeModal();
  };

  const handleEditSqueezePage = () => {
    handleOpenSidebar();
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.SQUEEZE_PAGE
      )
    );
    checkRedirectToEditorPage();
    closeModal();
  };

  const [isSoloPublished, setSoloPublished] = useState(currentPage.published);

  const handleCopyPageUrl = () => {
    navigator.clipboard.writeText(pageUrl);
    dispatch(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'Shareable link has been copied to your clipboard.'
        )
      )
    );
  };

  const updatePublishedStatus = (value: boolean) => {
    if (isSoloMode) {
      dispatch(
        pagesModel.actions.changePublicStatus({
          pageId: currentPage.id,
          published: value,
          callback: () => {
            if (!value) return;
            handleCopyPageUrl();
          },
        })
      );
    } else {
      updatePublished(value);
      if (!value) return;
      handleCopyPageUrl();
    }
  };

  useEffect(() => {
    setSoloPublished(currentPage.published);
  }, [currentPage.published]);

  const handleGmailClick = () => {
    const pageUrl = getFullPageUrl({
      slug: currentPage.pageSlug,
      domain: currentTeamDomain,
      customDomain,
    });

    const pageGmailUrl = getPageGmailUrl({
      url: pageUrl,
      id: currentPage.id,
      gifImage: currentPage.gifImage?.toString() || 'null',
      title: currentPage.content.title ?? '',
    });

    window.open(pageGmailUrl, '_blank');
  };

  const isAlertBarActive = isSoloMode
    ? isSoloAlertBarActive
    : isCollaborationAlertBarActive;

  const isPopUpActive = isSoloMode
    ? isSoloPopUpActive
    : isCollaborationPopUpActive;

  const isCTAActive = isSoloMode ? isSoloCTAActive : isCollaborationCTAActive;

  const isGatedContentActive = isSoloMode
    ? isSoloGatedContentActive
    : isCollaborationGatedContentActive;

  const isSqueezePageActive = isSoloMode
    ? isSoloSqueezePageActive
    : isCollaborationSqueezePageActive;

  const isPublished = isSoloMode ? isSoloPublished : published;

  return (
    <div className="py-5">
      <div className="flex flex-col gap-6">
        <div className="p-4 border border-gray-300 rounded-xl bg-base-white relative">
          <div
            className={cn('flex gap-3 w-full justify-between items-center', {
              'mb-4': isPublished,
            })}
          >
            <div className="flex gap-3">
              <div
                className={cn(
                  'h-11 w-11 rounded-full flex items-center justify-center',
                  {
                    'bg-primary-500/[.08]': isPublished,
                    'bg-base-black-4': !isPublished,
                  }
                )}
              >
                <div
                  className={cn(
                    'h-9 w-9 rounded-full  flex items-center justify-center',
                    {
                      'text-primary-600 bg-primary-500/[.08]': isPublished,
                      'text-gray-600 bg-base-black-4': !isPublished,
                    }
                  )}
                >
                  <Icon glyph={IconMap.Globe04} width={20} />
                </div>
              </div>
              <div>
                <p className="font-semibold text-gray-900 text-md">
                  Public access
                </p>
                <p className="text-sm text-gray-600">
                  Anyone with a link can view page.
                </p>
              </div>
            </div>
            <Toggle
              checked={!!isPublished}
              onChange={(val) => {
                updatePublishedStatus(val);
              }}
              disabled={isPublishingDisabled}
            />
          </div>

          {isPublishingDisabled && (
            <button
              className="absolute top-0 left-0 w-full h-full outline-none cursor-pointer"
              onClick={handleUpgradeClick}
            />
          )}

          {isPublished && (
            <>
              <div className="flex items-center gap-2">
                <div
                  ref={pageUrlBlockRef}
                  className={cn(
                    'flex flex-grow items-center gap-3 rounded-lg mb-0.25',
                    {
                      'border border-primary-600 bg-base-white py-0.75 pr-0.75 pl-2.75':
                        isCopiedTooltip,
                      'bg-gray-100 py-1 pr-1 pl-3': !isCopiedTooltip,
                    }
                  )}
                >
                  <Tooltip
                    triggerClassNames="w-full"
                    hideArrow
                    trigger={
                      <Input
                        className="!p-0 rounded-none leading-6 truncate bg-transparent border-none h-6"
                        value={getFullPageUrl({
                          slug: currentPage.pageSlug,
                          domain: currentTeamDomain,
                          customDomain,
                        })}
                        ref={slugRef}
                        onClick={onCopy}
                        type="text"
                        readOnly
                      />
                    }
                    sideOffset={4}
                    className="!px-1 top-2"
                    isOpen={isCopiedTooltip}
                  >
                    <p className="px-2 py-1 text-xs font-semibold">Copied!</p>
                  </Tooltip>

                  <div className="flex">
                    <Tooltip
                      hideArrow
                      trigger={
                        <Button
                          variant="icon"
                          color="transparent"
                          title="Copy"
                          size="sm"
                          onClick={onCopy}
                        >
                          <Icon
                            glyph={IconMap.Copy01}
                            width={20}
                            className="text-gray-600"
                          />
                        </Button>
                      }
                      sideOffset={4}
                      className="!px-1 top-2"
                    >
                      <p className="px-2 py-1 text-xs font-semibold">Copy</p>
                    </Tooltip>
                    <Tooltip
                      hideArrow
                      trigger={
                        <Button
                          variant="icon"
                          color="transparent"
                          title="Edit page slug"
                          size="sm"
                          onClick={handleOpenEditPageSlug}
                        >
                          <Icon
                            glyph={IconMap.Edit02}
                            width={20}
                            className="text-gray-600"
                          />
                        </Button>
                      }
                      sideOffset={4}
                      className="!px-1 top-2"
                    >
                      <p className="px-2 py-1 text-xs font-semibold">Edit</p>
                    </Tooltip>
                  </div>
                </div>

                <Button
                  variant="icon-text"
                  size="md"
                  color="secondary"
                  iconLeftName={IconMap.Gmail2}
                  iconLeftWidth={20}
                  onClick={handleGmailClick}
                >
                  Copy to Gmail
                </Button>
              </div>
              <div className="flex">
                <p className="text-sm text-gray-500">
                  Change slug, tag title, meta description and more in
                </p>
                <p
                  className="pl-1 text-sm font-medium cursor-pointer text-primary-700"
                  onClick={handleOpenAdvancedSettings}
                >
                  Advanced Settings
                </p>
              </div>
            </>
          )}
        </div>

        <div>
          <p className="mb-3 text-xs font-semibold text-gray-500 uppercase">
            Access Settings
          </p>
          <div className="p-4 border border-gray-300 rounded-xl bg-base-white">
            <RequireEmailToViewConversionItem
              isShareModal
              isFolderPage={isFolderPage}
            />
          </div>
        </div>

        <div>
          <p className="mb-3 text-xs font-semibold text-gray-500 uppercase">
            Other Options
          </p>
          <div className="flex flex-col gap-3">
            <ConversionLabelToggle
              icon={IconMap.Copy07}
              text="Allow viewers duplicate page as template"
              checked={isViewersCanDuplicateAsTemplate}
              onChange={handleChangeIsViewersCanDuplicateAsTemplate}
            />
            <ConversionLabelToggle
              icon={IconMap.Star}
              text="Remove “Powered by Distribute” badge"
              checked={isDistributeBadgeRemoved}
              onChange={handleChangeIsDistributeBadgeRemoved}
              availableForPaidPlan
            />
            {alertBar.description && (
              <ConversionLabelToggle
                icon={IconMap.CursorClick02}
                text="Alert Bar"
                checked={isAlertBarActive}
                onChange={toggleAlertBar}
                handleEdit={handleEditAlertBar}
                isEditByHover
                isSetupPopupWarning={
                  alertBar.actionType === ActionTypeAlertBar.POPUP &&
                  (popUp.actionType === ActionTypePopUp.CALENDLY
                    ? !popUp.calendarSchedulingLink
                    : !popUp.title)
                }
                onWarningClick={handleEditPopUp}
              />
            )}
            {popUp.buttonLabelFormType !== null && (
              <ConversionLabelToggle
                icon={IconMap.CursorClick02}
                text="Pop-up"
                checked={isPopUpActive}
                onChange={togglePopUpStatus}
                handleEdit={handleEditPopUp}
                isEditByHover
                onWarningClick={handleEditPopupTriggers}
                isPopupWarning={
                  isPopUpActive &&
                  !popUp.isTriggerExit &&
                  !popUp.isTriggerTime &&
                  (!isAlertBarActive ||
                    alertBar.actionType !== ActionTypeAlertBar.POPUP) &&
                  (!isCTAActive || cta.actionType !== ActionTypeCTA.POPUP)
                }
              />
            )}
            {cta.buttonLabelFormType !== null && (
              <ConversionLabelToggle
                icon={IconMap.CursorClick02}
                text="CTA Section"
                checked={isCTAActive}
                onChange={toggleCTAStatus}
                handleEdit={handleEditCTA}
                isSetupPopupWarning={
                  cta.actionType === ActionTypeCTA.POPUP &&
                  (popUp.actionType === ActionTypePopUp.CALENDLY
                    ? !popUp.calendarSchedulingLink
                    : !popUp.title)
                }
                onWarningClick={handleEditPopUp}
                isEditByHover
              />
            )}
            {gatedContent.buttonLabelFormType !== null && (
              <ConversionLabelToggle
                icon={IconMap.CursorClick02}
                text="Gated Content"
                checked={isGatedContentActive}
                onChange={toggleGatedContentStatus}
                handleEdit={handleEditGatedContent}
                isEditByHover
              />
            )}
            {squeezePage.buttonLabel !== null && (
              <ConversionLabelToggle
                icon={IconMap.CursorClick02}
                text="Squeeze Page"
                checked={isSqueezePageActive}
                onChange={toggleSqueezePageStatus}
                handleEdit={handleEditSqueezePage}
                isEditByHover
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
