import { Helmet } from 'react-helmet-async';
import { Records } from '../../../features/recordings';

export const RecordingsPage = () => {
  return (
    <>
      <Helmet titleTemplate="Video Recordings - Distribute" />
      <div className="flex flex-col min-w-0 flex-grow-1">
        <Records />
      </div>
    </>
  );
};
