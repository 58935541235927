import React, { forwardRef, useCallback, useMemo } from 'react';
import { AIMenuCategory, Menu } from './Menu';
import { IconMap } from '../../../../shared/sprite';
import { AIStartedWritingFrom, EditorWritingMode } from './types';
import { AIDraftItem, AIDraftsData } from '../../config';
import { AIMenuItem } from './MenuItem';
import {
  AITone,
  AIWritingActionType,
  ContextActionType,
} from '@distribute/shared/api-types/ai';
import { useDispatch } from 'react-redux';
import { aiModel } from '../../../../features/ai';

type IProps = {
  isOpen: boolean;
  startedWritingFrom: AIStartedWritingFrom;
  handleSelectDraft: (i: AIDraftItem) => void;
  context: string;
  setAIWritingAction: (val: AIWritingActionType) => void;
  setEditorWritingMode: (val: EditorWritingMode) => void;
  searchValue: string;
  onFocus: () => void;
  updateSelectionHighlight: () => void;
  isDraft: boolean;
};

export const WritingActions = forwardRef<HTMLDivElement, IProps>(
  (
    {
      isOpen,
      startedWritingFrom,
      handleSelectDraft,
      setAIWritingAction,
      context,
      setEditorWritingMode,
      searchValue,
      onFocus,
      updateSelectionHighlight,
      isDraft = false,
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const createHandler = useCallback(
      (type: AIWritingActionType, isEditorMode = false) => {
        return () => {
          if (isEditorMode) {
            setEditorWritingMode(EditorWritingMode.Editor);
          }

          if (
            !isEditorMode &&
            startedWritingFrom === AIStartedWritingFrom.NEW_LINE
          ) {
            updateSelectionHighlight();
          }

          const cb = () => setAIWritingAction(type);

          dispatch(
            aiModel.actions.startCompletion({
              type: type as ContextActionType,
              context,
              resultText: '',
              cb,
            })
          );
        };
      },
      [context, dispatch, setAIWritingAction, setEditorWritingMode]
    );

    const onChangeTone = useCallback(
      (tone: AITone) => {
        const cb = () => setAIWritingAction(AIWritingActionType.CHANGE_TONE);

        dispatch(
          aiModel.actions.startCompletion({
            type: AIWritingActionType.CHANGE_TONE,
            context,
            tone,
            cb,
          })
        );
      },
      [context, dispatch, setAIWritingAction]
    );

    const menuItems = useMemo<AIMenuCategory[]>(() => {
      const editReviewCategory = {
        id: 'edit-or-review-selection',
        category:
          startedWritingFrom === AIStartedWritingFrom.SELECTION
            ? 'Edit or review selection'
            : 'Edit or review page',
        isSeparatedFromBottom: true,
        items: [
          {
            id: AIWritingActionType.IMPROVE_WRITING,
            label: 'Improve writing',
            icon: IconMap.MagicWand01,
            onClick: createHandler(AIWritingActionType.IMPROVE_WRITING),
          },
          {
            id: AIWritingActionType.FIX_WRITING,
            label: 'Fix spelling & grammar',
            icon: IconMap.SearchCheck,
            onClick: createHandler(AIWritingActionType.FIX_WRITING),
          },
          {
            id: AIWritingActionType.MAKE_SHORTER,
            label: 'Make shorter',
            icon: IconMap.ShortText,
            onClick: createHandler(AIWritingActionType.MAKE_SHORTER),
          },
          {
            id: AIWritingActionType.MAKE_LONGER,
            label: 'Make longer',
            icon: IconMap.LongText,
            onClick: createHandler(AIWritingActionType.MAKE_LONGER),
          },
          {
            id: AIWritingActionType.CHANGE_TONE,
            label: 'Change tone',
            icon: IconMap.Mic,
            subItems: [
              {
                id: AITone.PROFESSIONAL,
                label: 'Professional',
                onClick: () => onChangeTone(AITone.PROFESSIONAL),
              },
              {
                id: AITone.CASUAL,
                label: 'Casual',
                onClick: () => onChangeTone(AITone.CASUAL),
              },
              {
                id: AITone.STRAIGHTFORWARD,
                label: 'Straightforward',
                onClick: () => onChangeTone(AITone.STRAIGHTFORWARD),
              },
              {
                id: AITone.CONFIDENT,
                label: 'Confident',
                onClick: () => onChangeTone(AITone.CONFIDENT),
              },
              {
                id: AITone.FRIENDLY,
                label: 'Friendly',
                onClick: () => onChangeTone(AITone.FRIENDLY),
              },
            ],
          },
          {
            id: AIWritingActionType.SIMPLIFY,
            label: 'Simplify language',
            icon: IconMap.Stars03,
            onClick: createHandler(AIWritingActionType.SIMPLIFY),
          },
        ],
      };
      const generateFromCategory = {
        id: 'generate-from-selection',
        category:
          startedWritingFrom === AIStartedWritingFrom.SELECTION
            ? 'Generate from selection'
            : 'Generate from page',
        isSeparatedFromBottom: true,
        items: [
          {
            id: AIWritingActionType.SUMMARIZE,
            label: 'Summarize',
            icon: IconMap.Summary,
            onClick: createHandler(
              AIWritingActionType.SUMMARIZE,
              startedWritingFrom === AIStartedWritingFrom.NEW_LINE
            ),
          },
        ],
      };
      const writeWithAICategory = {
        id: 'write-with-Al',
        category: 'Write with Al',
        isSeparatedFromBottom:
          startedWritingFrom === AIStartedWritingFrom.NEW_LINE,
        items: [
          {
            id: AIWritingActionType.CONTINUE_WRITING,
            label: 'Continue writing',
            icon: IconMap.Edit04,
            onClick: createHandler(
              AIWritingActionType.CONTINUE_WRITING,
              startedWritingFrom === AIStartedWritingFrom.NEW_LINE
            ),
          },
        ],
      };
      const draftCategory = {
        id: AIWritingActionType.DRAFT_WRITE,
        items: AIDraftsData.map<AIMenuItem>((item) => ({
          ...item,
          icon: IconMap.EditIcon,
          onClick: () => handleSelectDraft(item),
        })),
      };

      if (isDraft) {
        return [draftCategory];
      }

      if (startedWritingFrom === AIStartedWritingFrom.SELECTION) {
        return [editReviewCategory, generateFromCategory, writeWithAICategory];
      }

      return [
        writeWithAICategory,
        generateFromCategory,
        editReviewCategory,
        {
          ...draftCategory,
          category: 'Draft with AI',
        },
      ];
    }, [
      startedWritingFrom,
      createHandler,
      onChangeTone,
      handleSelectDraft,
      isDraft,
    ]);

    return (
      <Menu
        ref={ref}
        isOpen={isOpen}
        items={menuItems}
        searchValue={searchValue}
        onFocus={onFocus}
      />
    );
  }
);
