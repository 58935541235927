import { GenerateMessageAction } from '../model/types';
import { ChatResponseType, ChatStructuredData } from '@distribute/shared/types';
import { diffDocs, diffDocsOptions } from '@distribute/shared/tiptap/json-diff';
import { JSONContentFactory } from '@distribute/shared/tiptap/json-content';
import { ParseChatStructuredData } from '@distribute/shared/chat';

type NormalizeResponseParams = {
  tabs: GenerateMessageAction['tabs'];
  data: ParseChatStructuredData[];
  stream: boolean;
};

/**
 * Normalizes the structured chat response data by:
 * 1. Filtering out suggestions for non-existing tabs
 * 2. Adding content differences for changed suggestions
 * 3. Handling edge cases like unchanged content
 */
export const normalizeResponse = ({
  tabs,
  data,
  stream,
}: NormalizeResponseParams): ChatStructuredData[] => {
  // Filter out suggestions for tabs that don't exist in our tab registry
  const filtered = data.filter((item) => {
    if (item.type !== ChatResponseType.Suggestion) {
      return true; // Keep non-suggestion items (like comments)
    }

    return !!tabs[item.id]; // Only keep suggestions with valid tab references
  });

  return filtered.map((item) => {
    // Pass through comments without modification
    if (item.type === ChatResponseType.Comment) {
      return item;
    }

    if (item.type === ChatResponseType.Suggestion) {
      if (item.changed) {
        if (stream) {
          return { ...item, contentDiff: JSONContentFactory.DEFAULT() };
        }

        // Calculate the difference between original tab content and new content
        const contentDiff = diffDocs(
          tabs[item.id].contentJson,
          item.content,
          diffDocsOptions
        );

        if (contentDiff) {
          // Return suggestion with content difference
          return { ...item, contentDiff };
        }

        // Handle case when AI returns identical content despite being marked as changed
        // Reset changed flag and provide empty diff
        return {
          ...item,
          contentDiff: JSONContentFactory.DEFAULT(),
          changed: false,
        };
      }

      // For unchanged suggestions, add empty diff but preserve other properties
      return {
        ...item,
        contentDiff: JSONContentFactory.DEFAULT(),
      };
    }

    // Default return for any future element types that might be added
    return item;
  });
};
