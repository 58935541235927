import {
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormWatch,
} from 'react-hook-form';
import { IconMap } from '../../../../../../../shared/sprite';
import {
  Button,
  Icon,
  Input,
  LoaderDots,
  Tooltip,
} from '../../../../../../../shared/ui';
import React, { ChangeEvent, ChangeEventHandler, ClipboardEvent } from 'react';

import cn from 'classnames';
import {
  CreatePageFormSchemaType,
  LinkTypeForAIStatusEnum,
} from '../../../../../../../processes/page-creation-flow/config/types';

type Props = {
  index: number;
  isAddMore: boolean;
  isDelete: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleDelete: UseFieldArrayRemove;
  handleAddMore: UseFieldArrayAppend<CreatePageFormSchemaType, 'links'>;
  watch: UseFormWatch<CreatePageFormSchemaType>;
  isError?: boolean;
  messageText?: string;
  handleParse(link: string, index: number): void;
};

export const LinkElement: React.FC<Props> = ({
  index,
  isAddMore,
  isDelete,
  handleChange,
  handleAddMore,
  handleDelete,
  watch,
  isError,
  messageText,
  handleParse,
}) => {
  const link = watch(`links.${index}`);

  const handleParseLink = (url: string) => {
    if (link.status === 'not-parsed-yet') {
      handleParse(url, index);
    }
  };

  const handleBlurLink = (e: ChangeEvent<HTMLInputElement>) => {
    handleParseLink(e.target.value);
  };

  const handlePasteLink = (e: ClipboardEvent<HTMLInputElement>) => {
    handleParseLink(e.clipboardData.getData('text'));
  };

  return (
    <div className="flex gap-2 mb-2 last:mb-0">
      <div className="grow-1 relative">
        <Input
          type="text"
          value={link.url}
          placeholder="https://"
          heightSize="md"
          onChange={handleChange}
          isError={isError}
          messageText={messageText}
          onBlur={handleBlurLink}
          onPaste={handlePasteLink}
          className={cn({ 'pr-10': link.status === 'parsing' })}
        />
        {link.status === 'parsing' && (
          <LoaderDots size="sm" className="absolute right-4 top-4.5" />
        )}
      </div>

      <div className="flex">
        {isDelete && (
          <Tooltip
            trigger={
              <Button
                variant="icon"
                color="transparent-with-actions"
                size="md"
                className="group"
                onClick={() => handleDelete(index)}
              >
                <Icon
                  glyph={IconMap.Delete}
                  width={20}
                  className="text-gray-600 group-hover:text-gray-700"
                />
              </Button>
            }
            hideArrow
            sideOffset={4}
            className="px-3 py-2 font-semibold"
          >
            Delete
          </Tooltip>
        )}
        {isAddMore && (
          <Tooltip
            trigger={
              <Button
                variant="icon"
                color="transparent-with-actions"
                size="md"
                className="group"
                onClick={() =>
                  handleAddMore({
                    url: '',
                    data: null,
                    status: LinkTypeForAIStatusEnum.NOT_PARSED_YET,
                  })
                }
              >
                <Icon
                  glyph={IconMap.PlusCircle}
                  width={20}
                  className="text-gray-600 group-hover:text-gray-700"
                />
              </Button>
            }
            hideArrow
            sideOffset={4}
            className="px-3 py-2 font-semibold"
          >
            Add one more link
          </Tooltip>
        )}
      </div>
    </div>
  );
};
