import { useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import { throttle } from 'lodash';

type UseScrollOptions = {
  scrollOnMount?: boolean;
  smooth?: boolean;
  throttleMs?: number;
};

export const useScroll = <T extends HTMLElement = HTMLDivElement>({
  scrollOnMount = true,
  throttleMs = 250,
}: UseScrollOptions = {}) => {
  const ref = useRef<T>(null);

  // Scroll to bottom function
  const scrollToBottom = useCallback((behavior: ScrollBehavior) => {
    if (!ref.current) return;

    const scrollOptions: ScrollToOptions = {
      top: ref.current.scrollHeight,
      behavior,
    };

    ref.current.scrollTo(scrollOptions);
  }, []);

  // Create throttled version of scrollToBottom
  const handleContentChange = useRef(
    throttle(
      useCallback(() => scrollToBottom('smooth'), [scrollToBottom]),
      throttleMs
    )
  ).current;

  // Handle initial scroll on mount
  useLayoutEffect(() => {
    if (scrollOnMount) {
      scrollToBottom('auto');
    }
  }, [scrollOnMount, scrollToBottom]);

  // Clean up throttled function on unmount
  useEffect(() => {
    return () => {
      handleContentChange.cancel();
    };
  }, [handleContentChange]);

  return {
    ref,
    scrollToBottom,
    handleContentChange,
  };
};
