import { FC, useMemo, useRef, useState } from 'react';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { IconMap } from '../../../../../../src/shared/sprite';
import { Button, Icon } from '../../../../../../src/shared/ui';
import { TemplateSharingAccess } from '@distribute/shared/types';
import { EditorTabsPreview } from '../../../../../../src/features/templates/ui/components';
import { useSetDocumentContentStyles } from '../../../../../../src/shared/hooks/useSetDocumentContentStyles';
import { EditorHtmlContent } from '../../../../../../src/pages/new-editor/ui/components/EditorHtmlContent';
import { tagsByCategoryDictionary } from './lib';
import { useTemplatesTabContext } from './context';

type Props = {
  template: TemplateExtended;
  handleCloseTemplatePreview: () => void;
};
export const Preview: FC<Props> = ({
  template,
  handleCloseTemplatePreview,
}) => {
  const categoryTags = useMemo(() => {
    const tags = [];

    if (template.isDistribute) {
      tags.push('Distribute Templates');
    } else {
      tags.push('Custom Templates');
    }

    if (template.category) {
      tags.push(...tagsByCategoryDictionary[template.category]);
    } else {
      tags.push(
        template.sharingAccess === TemplateSharingAccess.PERSONAL
          ? 'Personal Templates'
          : 'Team Templates'
      );
    }

    return tags.filter(Boolean);
  }, [template]);

  const { content } = template;
  const [currentTab, setCurrentTab] = useState(content.contentItems[0]);
  const htmlContent = currentTab?.htmlContent;

  const reviewRef = useRef<HTMLDivElement>(null);

  const { handleUseTemplateClick } = useTemplatesTabContext();

  const onSelectClick = () => {
    handleUseTemplateClick(template);
  };

  useSetDocumentContentStyles(content, reviewRef);

  return (
    <div className="flex-grow w-full h-full p-5 overflow-scroll border-l border-gray-300">
      <div className="flex items-center justify-between mb-5">
        <Button
          size="md"
          color="secondary"
          variant="icon"
          onClick={handleCloseTemplatePreview}
        >
          <Icon glyph={IconMap.ArrowLeft} width={20} />
        </Button>
        <Button
          size="md"
          color="primary"
          variant="text"
          onClick={onSelectClick}
        >
          Use Template
        </Button>
      </div>

      <p className="mb-1 text-2xl font-semibold text-gray-900">
        {template.name}
      </p>
      <p className="text-sm text-gray-700">{template.description}</p>
      <div className="flex flex-wrap gap-1 mt-3 mb-4">
        {categoryTags.map((tag) => (
          <div
            key={tag}
            className="px-2 py-0.75 bg-base-white rounded-md border border-gray-300 text-gray-700 text-xs font-medium"
          >
            {tag}
          </div>
        ))}
      </div>
      <div className="p-4 border border-gray-200 rounded-md">
        <div ref={reviewRef} className="overflow-x-hidden overflow-y-auto">
          <div className="w-full m-auto h-ful">
            <h1 className="mb-10 font-semibold text-gray-900 text-display-lg">
              {content.title || 'Untitled'}
            </h1>

            {!template.isSinglePage ? (
              <EditorTabsPreview
                tabs={content.contentItems}
                currentTab={currentTab}
                onChangeTab={(tab) => setCurrentTab(tab)}
              />
            ) : null}

            <div>
              <EditorHtmlContent htmlContent={htmlContent || ''} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
