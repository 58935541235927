import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetCallResponse } from '@distribute/shared/api-types/call';
import { callModel } from '../model';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  Dropdown,
  Icon,
  Avatar,
  TimeAgo,
  RecordState,
} from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import {
  checkIsMuxLoading,
  checkIsMuxReady,
  getMuxAssetImageByPolicy,
} from '@distribute/shared/utils';
import { formatMsToTimePassed } from '@distribute/shared/utils';
import { redirectActions } from '../../../entities/history';
import { authUserModel } from '../../../entities/auth-user';

type CallItemProps = {
  item: GetCallResponse;
};

export const CallItem: React.FC<CallItemProps> = ({ item }) => {
  const dispatch = useDispatch();
  const { isPrivate, userToTeam, name, createdAt, muxAsset } = item;

  const owner = userToTeam.user;
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const isOwner = currentUser.id === owner.id;

  const toggleVisibility = () => {
    if (!isPrivate) {
      dispatch(callModel.actions.setTogglingCallItem(item));
    } else {
      dispatch(
        callModel.actions.updateCallItem({
          id: item.id,
          data: { isPrivate: !isPrivate },
          isFromList: true,
        })
      );
    }
  };
  const handleEdit = () => {
    dispatch(callModel.actions.setEditingCallItem(item));
  };
  const handleDelete = () => {
    dispatch(callModel.actions.setDeletingCallItem(item));
  };

  const handleCallClick = () => {
    dispatch(
      redirectActions.toCallPage({
        id: item.id,
      })
    );
  };

  const callControls = [
    {
      label: isPrivate ? 'Make public' : 'Make private',
      id: 'public',
      iconName: IconMap.Users02,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: toggleVisibility,
    },
    {
      label: 'Rename',
      id: 'rename',
      isSeparatedFromTop: true,
      iconName: IconMap.EditIcon,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: handleEdit,
    },
    {
      label: 'Delete',
      id: 'delete',
      iconName: IconMap.Trash,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: handleDelete,
    },
  ];

  return (
    <div
      className="group/item border border-gray-300 bg-base-white rounded-lg p-3 w-1/3 sm:w-full min-w-76 max-w-86 cursor-pointer"
      onClick={handleCallClick}
    >
      <div className="h-41.5 overflow-hidden rounded-lg relative border border-base-black/[0.08] mb-4">
        {checkIsMuxReady(muxAsset) ? (
          <>
            <img
              className="object-cover block h-full w-full"
              src={getMuxAssetImageByPolicy(muxAsset)}
              alt={item.name}
            />
            <span className="absolute bottom-2 right-2 bg-base-black/70 text-base-white py-0.5 px-2.25 rounded-2xl text-sm">
              {formatMsToTimePassed(muxAsset.data.duration * 1000)}
            </span>
          </>
        ) : (
          <RecordState loading={checkIsMuxLoading(muxAsset)} />
        )}
        {isOwner && (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              listStyles="shadow-lg"
              isModalMode={false}
              triggerComponent={
                <DropdownMenu.Trigger
                  className="absolute top-2 right-2.25
                flex items-center justify-center w-10 h-10
                border border-gray-200 rounded-lg focus:outline-none hover:border-gray-300
                bg-base-white/70 hover:bg-base-white
                invisible group-hover/item:visible"
                >
                  <Icon glyph={IconMap.DotsVertical} width={20} />
                </DropdownMenu.Trigger>
              }
              items={callControls}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col gap-1.5 text-gray-700">
        {owner && (
          <div className="flex gap-1 items-center text-xs">
            <Avatar
              src={owner.photoUrl}
              displayName={owner.displayName}
              size="3xs"
            />
            by{' '}
            <span className="text-gray-900 font-semibold">
              {owner.displayName}
            </span>
          </div>
        )}
        <h3 className="font-semibold truncate">{name}</h3>
        <div className="flex items-center justify-between text-sm text-gray-600">
          <TimeAgo datetime={createdAt} locale="my" />
          {!isPrivate && (
            <p className="flex items-center gap-0.5">
              <Icon glyph={IconMap.Users01} width={16} />
              Team access
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
