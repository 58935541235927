import { useCollaboration } from '../../../entities/collaboration';
import { useDispatch } from 'react-redux';
import { pagesModel } from '../../pages';
import { DocumentContentItem } from '@distribute/shared/types';

export const useSuggestionNavigation = () => {
  const dispath = useDispatch();

  const { provider, tabs } = useCollaboration();

  const navigateTo = (tabId: number) => {
    const tab = tabs.find((t) => t.id === tabId);

    if (!tab) return;

    dispath(
      pagesModel.actions.setCurrentContentItem({
        contentItem: tab as DocumentContentItem,
      })
    );

    provider?.awareness.setLocalStateField('activeTabId', tab.id);
  };

  return { navigateTo };
};
