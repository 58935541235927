import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Avatar, Dropdown, Icon, Tooltip } from '../../../../shared/ui';
import { useCollaboration } from '../../../../entities/collaboration';
import { authUserModel } from '../../../../entities/auth-user';
import { DropdownItem } from '../../../../shared/ui/Dropdown';
import { IconMap } from '../../../../shared/sprite';
import { pagesModel } from '../../../../features/pages';
import { DocumentContentItem } from '@distribute/shared/types';
import cn from 'classnames';

const ContainerLeftOffset = -16;
const ItemWidthPlusRightGap = 42;

export const PageCollaboratorsMenu: React.FC = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const { provider, tabs } = useCollaboration();

  const [isRestItemsDropdownOpen, setRestItemsDropdownOpen] = useState(false);

  const usersMap = provider?.awareness.getStates();

  const { filteredUsers, visibleUsers, restUsers } = (() => {
    const filteredUsers = Array.from(usersMap?.values() ?? [])
      .filter(({ user }) => user?.id !== currentUser.id)
      .filter((item) => !!Object.keys(item).length);

    const [userData1, userData2, userData3, ...restUsers] = filteredUsers;

    const visibleUsers = [userData1, userData2, userData3].filter(Boolean);

    return { filteredUsers, visibleUsers, restUsers };
  })();

  if (!filteredUsers.length) return null;

  const handleScrollIntoView = (id: string, activeTabId: number) => {
    const tabContent = tabs.find((el) => el.id === activeTabId);

    if (tabContent) {
      dispatch(
        pagesModel.actions.setCurrentContentItem({
          contentItem: tabContent as DocumentContentItem,
        })
      );

      setTimeout(() => {
        const root = document.getElementById('root');
        const element = document.getElementById(id);
        const topOffset = 200;

        if (element && root) {
          const { top } = element.getBoundingClientRect();

          root.scrollTo({
            top: top - topOffset + root.scrollTop,
            behavior: 'smooth',
          });
        }
      }, 100);
    }
  };

  const allOptions: DropdownItem[] = filteredUsers.map((el) => ({
    id: el.user.id,
    label: (
      <div
        className="flex items-center justify-start gap-2 overflow-hidden p-2.5"
        onClick={() => handleScrollIntoView(el.user.id, el.user.activeTabId)}
      >
        <Avatar
          displayName={el.user.name}
          src={el.user.photoUrl}
          isUserAvatar
          size="xs"
        />
        <span className="text-sm font-medium text-gray-900">
          {el.user.name}
        </span>
        <span className="text-sm font-normal text-gray-600 overflow-hidden text-ellipsis flex-1 min-w-0">
          {el.user.email}
        </span>
      </div>
    ),
  }));

  return (
    <div className="flex items-start justify-center focus:outline-none h-9 gap-1.5">
      {visibleUsers.map((data, index) => (
        <Tooltip
          side="bottom"
          sideOffset={4}
          align="center"
          trigger={
            <div
              style={{ outlineColor: data.user.color }}
              className={cn(
                'w-9 h-9 rounded-full group hover:outline cursor-pointer outline-4  outline-offset-1 relative'
              )}
              onClick={() =>
                handleScrollIntoView(data.user.id, data.user.activeTabId)
              }
            >
              <Avatar
                displayName={data.user.name}
                src={data.user.photoUrl}
                isUserAvatar
                size="sm"
                className="border border-gray-300"
              />
            </div>
          }
        >
          <div className="p-2">
            <p className="font-semibold text-center">{data.user.name}</p>
            <p className="font-semibold text-center">{data.user.email}</p>
          </div>
        </Tooltip>
      ))}
      {!!restUsers.length && (
        <Dropdown
          listStyles="w-80"
          align="start"
          sideOffset={8}
          alignOffset={ContainerLeftOffset - ItemWidthPlusRightGap * 3}
          isOpen={isRestItemsDropdownOpen}
          setOpen={setRestItemsDropdownOpen}
          itemStyles="!p-0"
          triggerComponent={
            <DropdownMenu.Trigger className="">
              <div className="w-9 h-9 bg-gray-100 rounded-full border border-gray-300 flex items-center justify-center text-gray-500 font-semibold text-base">
                {isRestItemsDropdownOpen ? (
                  <Icon
                    glyph={IconMap.ChevronUp}
                    width={16}
                    className="text-gray-700"
                  />
                ) : (
                  `+${restUsers.length}`
                )}
              </div>
            </DropdownMenu.Trigger>
          }
          items={allOptions.slice(-restUsers.length)}
          isModalMode={false}
        />
      )}
    </div>
  );
};
