import { call, put } from 'redux-saga/effects';
import { RT } from '../../../../shared/types';
import { callRecordingsModel } from '../index';
import { callRecordingSettingsModel } from '../../../call-recording-settings';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* updateJoinPreference({
  payload: data,
}: RT<typeof callRecordingsModel.actions.updateJoinPreference>) {
  try {
    yield call(
      callRecordingSettingsModel.sagas.updateUserCallRecordingSettingsRequest,
      data
    );

    yield call(callRecordingsModel.sagas.loadMeetingEvents);
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update user settings sidebar')
      )
    );
  }
}
