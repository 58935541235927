import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import cn from 'classnames';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

interface SuggestionsControlBarProps {
  currentTabId: number;
  onAccept: () => void;
  onReject: () => void;
}

export const SuggestionsControlBar: FC<SuggestionsControlBarProps> = ({
  currentTabId,
  onAccept,
  onReject,
}) => {
  const [index, setIndex] = useState(0);
  const [elementsCount, setElementsCount] = useState(0);
  const elementsRef = useRef<Element[] | null>(null);

  useEffect(() => {
    setTimeout(() => {
      elementsRef.current = getAllDiffElements();
      setElementsCount(elementsRef.current?.length);

      if (elementsRef.current?.length) {
        selectElement(0, -1);
        setIndex(0);
      }
    }, 100);
  }, [currentTabId]);

  const selectElement = useCallback((newIndex: number, prevIndex: number) => {
    const elements = elementsRef.current;
    if (!elements || elements.length === 0) return;
    const currentElement = elements[newIndex];
    if (prevIndex >= 0) {
      const prevElement = elements[prevIndex];
      prevElement?.classList.remove('diff-selected');
    }
    currentElement?.classList.add('diff-selected');

    requestAnimationFrame(() => {
      currentElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    });
  }, []);
  const handlePrevClick = useCallback(() => {
    const newIndex = Math.max(index - 1, 0);
    selectElement(newIndex, index);
    setIndex(newIndex);
  }, [index, selectElement]);
  const handleNextClick = useCallback(() => {
    const elements = elementsRef.current;
    if (!elements) return;
    const newIndex = Math.min(index + 1, elements.length - 1);
    selectElement(newIndex, index);
    setIndex(newIndex);
  }, [index, selectElement]);

  const isPrevDisabled = index <= 0;
  const isNextDisabled = index >= elementsCount - 1;

  return (
    <div className="flex justify-center sticky bottom-6 z-50">
      <div className="flex items-center p-3 bg-gray-800 max-w-126 rounded-xl">
        {elementsCount > 0 && (
          <div className="flex items-center gap-1 pr-3 border-r border-base-white/10">
            <div className="flex items-center gap-1">
              <button
                disabled={isPrevDisabled}
                className={cn(
                  'border-0 outline-0 rounded-md text-gray-300  hover:bg-base-white/10',
                  {
                    'hover:text-base-white': !isPrevDisabled,
                  }
                )}
                onClick={handlePrevClick}
              >
                <Icon
                  glyph={IconMap.ChevronLeft}
                  width={24}
                  className="shrink-0"
                />
              </button>
              <button
                disabled={isNextDisabled}
                className={cn(
                  'border-0 outline-0 rounded-md text-gray-300  hover:bg-base-white/10',
                  {
                    'hover:text-base-white': !isNextDisabled,
                  }
                )}
                onClick={handleNextClick}
              >
                <Icon
                  glyph={IconMap.ChevronRight}
                  width={24}
                  className="shrink-0"
                />
              </button>
            </div>
            <p className="text-base-white">
              {elementsCount > 0 ? index + 1 : 0} of {elementsCount} updated
              elements
            </p>
          </div>
        )}
        <div
          className={cn('flex items-center gap-2 text-sm font-semibold', {
            'pl-3': elementsCount > 0,
          })}
        >
          <button
            onClick={() => onReject()}
            className="h-8 p-1.5 w-23.5 rounded-md shadow-sm bg-error-700 hover:bg-error-900 border border-error-800 hover:border-error-700 text-error-200 hover:text-error-100"
          >
            Reject all
          </button>
          <button
            onClick={() => onAccept()}
            className="h-8 p-1.5 w-23.5 rounded-md shadow-sm bg-primary-700 hover:bg-primary-800 text-base-white"
          >
            Accept all
          </button>
        </div>
      </div>
    </div>
  );
};

function getAllDiffElements() {
  try {
    // Get the ProseMirror container first
    const editorElement = document.querySelector('.ProseMirror');
    if (!editorElement) return [] as Element[];

    // Create a TreeWalker to traverse all elements in DOM order
    const walker = document.createTreeWalker(
      editorElement,
      NodeFilter.SHOW_ELEMENT,
      {
        acceptNode: (node: Element) => {
          const element = node;
          const hasDiffClass = element.classList.contains('diff');

          if (!hasDiffClass) return NodeFilter.FILTER_SKIP;

          const hasParentDiff =
            element.parentElement?.closest('.diff-edit, .diff-insert') !== null;
          if (hasParentDiff) return NodeFilter.FILTER_SKIP;

          return NodeFilter.FILTER_ACCEPT;
        },
      }
    );

    // Collect all elements in DOM order
    const result = [];
    let currentNode = walker.nextNode();

    while (currentNode) {
      result.push(currentNode);
      currentNode = walker.nextNode();
    }

    return result as Element[];
  } catch (error) {
    console.error('Error finding diff elements:', error);
    return [] as Element[];
  }
}
