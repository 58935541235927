import { FC } from 'react';

type Props = { text: string };

export const ChatBotMessageRequest: FC<Props> = ({ text }) => {
  return (
    <div className="self-end w-80 px-3 py-2 border rounded-lg bg-gray-100 border-base-black/[0.04]">
      <p className="text-md text-gray-900 whitespace-pre-wrap">{text}</p>
    </div>
  );
};
