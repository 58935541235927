import { FC, useMemo } from 'react';
import { ChatBotSuggestion } from './ChatBotSuggestion';
import { authUserModel } from '../../../entities/auth-user';
import { pagesModel } from '../../../features/pages';

import { useSelector } from 'react-redux';
import {
  getContentItems,
  useCollaboration,
} from '../../../entities/collaboration';
import { checkIsEmptyContent } from '../../../entities/tiptap-editor';
import { useSend } from '../hooks/use-send';
import { Suggestions } from '../config';

export const ChatBotEmpty: FC = () => {
  const { send } = useSend();
  const { provider } = useCollaboration();

  const currentContentId = useSelector(
    pagesModel.selectors.selectCurrentContentId
  );

  // Cannot use useMemo here as provider is a complex object that changes on each render
  // and the internal structure of provider is needed for getContentItems calculation
  const tabs = provider ? getContentItems(provider) : [];
  const isTabEmpty = !!tabs.find(
    (t) => t.id === currentContentId && checkIsEmptyContent(t.contentJson)
  );

  const suggestions = useMemo(() => {
    if (isTabEmpty) {
      return [
        {
          id: 'create-page-outline',
          emodji: '🖋️',
          name: 'Create page outline',
          onClick: () => send(Suggestions.Outline),
        },
        {
          id: 'brainstorm',
          emodji: '🧠',
          name: 'Brainstorm content ideas',
          onClick: () => send(Suggestions.Brainstorm),
        },
        {
          id: 'generate-page-draft',
          emodji: '📝',
          name: 'Generate page draft',
          onClick: () => send(Suggestions.Draft),
        },
      ];
    }

    return [
      {
        id: 'get-feedback',
        emodji: '🗣️',
        name: 'Get feedback on your page',
        onClick: () => send(Suggestions.Feedback),
      },
      {
        id: 'suggest-changes',
        emodji: '🖋️️',
        name: 'Suggest changes',
        onClick: () => send(Suggestions.Changes),
      },
      {
        id: 'flag-confusing-parts',
        emodji: '🚩',
        name: 'Flag confusing parts',
        onClick: () => send(Suggestions.Flag),
      },
    ];
  }, [isTabEmpty, send]);

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const message = `Hi, ${currentUser.displayName} `;

  return (
    <div className="-mr-5 pr-5 py-5 flex flex-col gap-y-5 flex-grow-1 overflow-y-auto">
      <div className="self-center flex flex-col gap-x-2 flex-grow-1 justify-center max-w-70">
        <h3 className="text-2xl font-semibold text-center">
          <span className="text-transparent bg-clip-text bg-gradient-orange-to-purple">
            {message}
          </span>
          <span role="img" aria-label="img">
            👋
          </span>
        </h3>
        <p className="text-sm text-gray-700 text-center font-normal">
          Ready to assist you with anything you need, from answering questions
          to providing edits and suggestions. Let's get started!
        </p>
      </div>
      <div className="flex flex-col gap-y-2 mt-auto">
        {suggestions.map((s) => (
          <ChatBotSuggestion
            key={s.id}
            name={s.name}
            emodji={s.emodji}
            onClick={s.onClick}
          />
        ))}
      </div>
    </div>
  );
};
