import { Tooltip } from '../../../../../../shared/ui';
import { FC } from 'react';
import { SpriteGlyph } from '../../../../../../shared/ui/Icon';
import { TabButton } from './TabButton';

export type Tab = {
  id: string;
  name: string;
  icon?: SpriteGlyph;
  disabled?: boolean;
  tooltipText?: string;
};

type Props = {
  tabs: Tab[];
  selectedTab: string;
  setSelectedTab: (id: string) => void;
};
export const TabsSwitcher: FC<Props> = ({
  tabs,
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <div className="p-1 flex gap-0.5 bg-base-black-8 rounded-xl w-full h-10">
      {tabs.map((tab) =>
        tab.tooltipText ? (
          <Tooltip
            key={tab.id}
            side="bottom"
            sideOffset={4}
            align="center"
            trigger={
              <TabButton
                tab={tab}
                handleClick={() => setSelectedTab(tab.id)}
                isSelected={selectedTab === tab.id}
              />
            }
            triggerClassNames="w-full"
          >
            <p className="font-semibold text-center">{tab.tooltipText}</p>
          </Tooltip>
        ) : (
          <TabButton
            key={tab.id}
            tab={tab}
            handleClick={() => setSelectedTab(tab.id)}
            isSelected={selectedTab === tab.id}
          />
        )
      )}
    </div>
  );
};
