import {
  CallBasicInfo,
  GetCallDetailResponse,
} from '@distribute/shared/api-types/call';
import { getCallById } from '../../../../features/call/model/sagas/getCallById';
import { all, call } from 'redux-saga/effects';
import { CallTranscripts } from '@distribute/shared/types';

export function* getCallRecordingsTranscripts(callsInfo: CallBasicInfo[]) {
  const callsData: GetCallDetailResponse[] = yield all(
    callsInfo.map((el) => call(getCallById, el.id))
  );

  return callsData.map(({ transcripts }) =>
    formatCallRecordingTranscript(transcripts)
  );
}

function formatCallRecordingTranscript(transcriptsInfo: CallTranscripts) {
  return transcriptsInfo
    .map(
      ({ speaker, words }) =>
        `${speaker}: ${words.map((el) => el.text).join(' ')}`
    )
    .join(' ');
}
