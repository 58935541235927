import { call, put, select } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';
import {
  AnalyticsEvents,
  Folder,
  GettingStartedStep,
  Page,
  Template,
} from '@distribute/shared/types';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { redirect } from '../../../../entities/history';
import { handleError } from '../../../../utils/handleError';
import { analytics } from '../../../../entities/analytics';
import { brandfetchApi } from '../../../../shared/api/axios/brandfetch';
import { RT } from '../../../../shared/types';
import { DEFAULT_RATIO } from '@distribute/shared/utils';
import { recordingsModel } from '../../../../features/recordings';
import { callModel } from '../../../../features/call';
import { getFolder } from '../../../../features/pages/model/sagas/getFolder';
import {
  setRecentTemplatesData,
  templatesModel,
} from '../../../../features/templates';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { generateColorsPalette } from '../../../../features/page-style/lib/generateColorsPalette';
import { pagesModel } from '../../../../features/pages';
import { foldersModel } from '../../../../features/folders';
import { gettingStartedModel } from '../../../../features/getting-started/model';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { GeneratedOutlineType } from '@distribute/shared/api-types/create-with-ai';
import { pageCreationFlowModel } from '..';
import {
  PageCreationDataSourcesEnum,
  PageCreationTypesEnum,
} from '../../config/types';
import { VideoRecordDetailType } from '@distribute/shared/api-types/video-record';
import { CreateOrInsertToPageFlowsEnum } from '../../../../features/call/model/types';
type Props = {
  title: string;
  brandLogo: string | null;
  brandCompanyDomain: string | null;
  folderId: string;
  folderName: string;
  isSeparateTabs?: boolean;
  generatedOutline?: GeneratedOutlineType;
  selectedDataSource: PageCreationDataSourcesEnum;
};

export function* createPage({
  folderId,
  folderName,
  title,
  brandLogo,
  brandCompanyDomain,
  isSeparateTabs,
  generatedOutline,
  selectedDataSource,
}: Props) {
  try {
    yield put(actions.setCreatePageIsLoading(true));

    const videoRecording: RT<typeof recordingsModel.selectors.selectRecord> =
      yield select(recordingsModel.selectors.selectRecord);

    const currentCall: RT<typeof callModel.selectors.selectCurrentCall> =
      yield select(callModel.selectors.selectCurrentCall);
    const callPageCreationFlow: RT<
      typeof callModel.selectors.selectPageCreationFlow
    > = yield select(callModel.selectors.selectPageCreationFlow);
    const callSummaryType: RT<
      typeof callModel.selectors.selectCurrentSummaryType
    > = yield select(callModel.selectors.selectCurrentSummaryType);

    const currentFolderId: string = yield getFolder(folderId, folderName);

    const currentTemplate: Template = yield select(
      templatesModel.selectors.selectCurrentSelectedTemplate
    );

    const color: string = yield call(
      brandfetchApi.getBrandColor,
      brandCompanyDomain
    );

    yield put(editorSidebarModel.actions.setSidebarOpen(true));
    const page: Page = yield pagesApi.createPage({
      record: Object.keys(videoRecording).length
        ? {
            prefix: videoRecording.prefix,
            playbackId: videoRecording.playbackId,
            aspectRatio:
              videoRecording?.metadata?.mux?.aspectRatio || DEFAULT_RATIO,
          }
        : undefined,
      callRecord:
        currentCall &&
        callPageCreationFlow !== CreateOrInsertToPageFlowsEnum.PAGE_WITH_AI
          ? {
              id: currentCall.id,
              playbackId: currentCall.muxAsset.playback.id,
              aspectRatio:
                currentCall.muxAsset.data.aspectRatio || DEFAULT_RATIO,
              summaryType:
                callPageCreationFlow ===
                CreateOrInsertToPageFlowsEnum.SUMMARY_AND_VIDEO
                  ? callSummaryType
                  : undefined,
            }
          : undefined,
      folderId: currentFolderId,
      title,
      templateId: currentTemplate?.id,
      brandLogo,
      brandCompanyDomain,
      brandColor: color ? generateColorsPalette(color) : undefined,
      predefinedTabs: isSeparateTabs ? generatedOutline : undefined,
    });

    if (currentTemplate) {
      yield call(setRecentTemplatesData);
    }

    const pages: Page[] = yield select(pagesModel.selectors.selectPages);
    yield put(pagesModel.actions.setPages([...pages, page]));

    const folders: Folder[] = yield select(
      foldersModel.selectors.selectFolders
    );
    const currentFolder = folders.find((i) => currentFolderId === i.id);
    yield put(foldersModel.actions.setCurrentFolder(currentFolder));
    yield call(redirect.toEditorPage, {
      sequenceNumber: page.sequenceNumber,
    });
    yield call(
      gettingStartedModel.sagas.onGettingStartedStepCompleted,
      GettingStartedStep.CREATE_PAGE
    );

    yield call(trackAnalytics, {
      page,
      selectedDataSource,
      videoRecording,
      isBranded: !!brandCompanyDomain,
      isSeparateTabs: !!isSeparateTabs,
    });

    yield put(templatesModel.actions.setCurrentSelectedTemplate(undefined));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', handleError(e, 'Failed to create the page'))
      )
    );
  } finally {
    yield put(actions.setCreatePageIsLoading(false));
  }
}

type TrackAnalyticsProps = {
  page: Page;
  selectedDataSource: PageCreationDataSourcesEnum;
  videoRecording: VideoRecordDetailType;
  isBranded: boolean;
  isSeparateTabs: boolean;
};

function* trackAnalytics({
  page,
  selectedDataSource,
  videoRecording,
  isBranded,
  isSeparateTabs,
}: TrackAnalyticsProps) {
  const pageCreationType: RT<
    typeof pageCreationFlowModel.selectors.selectPageCreationType
  > = yield select(pageCreationFlowModel.selectors.selectPageCreationType);

  const data = {
    pageId: page.id,
    title: page.content.title || 'Untitled',
    slug: page.pageSlug,
    includesVideoRecording: !!Object.keys(videoRecording).length,
    isBranded,
  };

  if (pageCreationType === PageCreationTypesEnum.FROM_SCRATCH) {
    analytics.track(AnalyticsEvents.PAGE_CREATED_FROM_SCRATCH, data);
    return;
  }

  if (pageCreationType === PageCreationTypesEnum.FROM_TEMPLATE) {
    analytics.track(AnalyticsEvents.PAGE_CREATED_FROM_TEMPLATE, data);
    return;
  }

  analytics.track(AnalyticsEvents.PAGE_CREATED_WITH_AI, {
    ...data,
    selectedDataSource,
    isSeparateTabs,
  });
}
