import { call, put, select } from 'redux-saga/effects';
import { teamsModel } from '../../../teams';
import { RT } from '../../../../shared/types';
import { chatsApi } from '../../../../shared/api/axios/chat';
import { chatModel } from '..';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* loadChat(chatId: string) {
  const currentTeam: RT<
    typeof teamsModel.selectors.selectCurrentTeamWithError
  > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

  const chat: RT<typeof chatsApi.getChat> = yield call(chatsApi.getChat, {
    id: chatId,
    teamId: currentTeam.id,
  });

  yield put(chatModel.actions.setCurrentChat(chat));
}

export function* loadChatData(entityId: string) {
  try {
    yield put(chatModel.actions.setIsChatLoading(true));

    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const chats: RT<typeof chatsApi.getChats> = yield call(chatsApi.getChats, {
      entityId,
      teamId: currentTeam.id,
    });

    if (chats.length) {
      yield put(chatModel.actions.setChats(chats));
      yield call(loadChat, chats[0].id);
    } else {
      const newChat: RT<typeof chatsApi.createChat> = yield call(
        chatsApi.createChat,
        { entityId, teamId: currentTeam.id }
      );

      yield put(chatModel.actions.setChats(chats));
      yield put(chatModel.actions.setCurrentChat(newChat));
    }
  } catch (error) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to load Ask Dante')
      )
    );
  } finally {
    yield put(chatModel.actions.setIsChatLoading(false));
  }
}
