import { FormattedGongTranscript } from '@distribute/shared/api-types/gong';
import {
  CreatePageFormSchemaType,
  PageCreationDataSourcesEnum,
  TranscriptsFilterTypesEnum,
} from './types';

export const TranscriptsFilterDateMonths = {
  [TranscriptsFilterTypesEnum.LAST_MONTH]: 1,
  [TranscriptsFilterTypesEnum.LAST_3_MONTHS]: 3,
  [TranscriptsFilterTypesEnum.LAST_6_MONTHS]: 6,
  [TranscriptsFilterTypesEnum.LAST_YEAR]: 12,
};

function getSelectedCallTranscripts(
  data: CreatePageFormSchemaType,
  transcripts: FormattedGongTranscript[]
): string[] {
  const transcriptsFilteredByCaller = transcripts.filter(
    (item) =>
      !!item.parties.find((party) => party.name === data.selectedCallerName)
  );

  if (!data.transcriptsFilter) {
    throw new Error('Transcript filter is not defined');
  }

  if (data.transcriptsFilter === TranscriptsFilterTypesEnum.ALL_AVAILABLE) {
    return transcriptsFilteredByCaller.map((item) => item.transcript);
  }

  if (data.transcriptsFilter === TranscriptsFilterTypesEnum.SPECIFIC) {
    return data.selectedTranscripts
      .map(
        (id) => transcripts.find((item) => item.metaData.id === id)?.transcript
      )
      .filter(Boolean) as string[];
  }
  if (data.transcriptsFilter === TranscriptsFilterTypesEnum.LAST_CALL) {
    return [transcriptsFilteredByCaller[0]?.transcript];
  }

  const pastDate = new Date();
  pastDate.setMonth(
    pastDate.getMonth() - TranscriptsFilterDateMonths[data.transcriptsFilter]
  );

  return transcriptsFilteredByCaller
    .filter((item) => item.metaData.started > pastDate)
    .map((item) => item.transcript);
}

export function getPageGenerationContext(
  data: CreatePageFormSchemaType,
  transcripts: FormattedGongTranscript[] | null,
  callRecordings: string[]
): string[] {
  if (data.selectedDataSource === PageCreationDataSourcesEnum.GONG) {
    return getSelectedCallTranscripts(
      data,
      transcripts as FormattedGongTranscript[]
    );
  } else if (data.selectedDataSource === PageCreationDataSourcesEnum.FILE) {
    return data.files?.map((file) => file.data) ?? [];
  } else if (data.selectedDataSource === PageCreationDataSourcesEnum.LINK) {
    return data.links?.map((link) => link.data as string) ?? [];
  } else if (data.selectedDataSource === PageCreationDataSourcesEnum.CALL) {
    return callRecordings;
  } else {
    return [data.text];
  }
}
