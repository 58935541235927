import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  CompanyRecord,
  Icon,
  Input,
  LoaderDots,
} from '../../../../../../shared/ui';
import React, { useRef, useState } from 'react';
import { clearbitModel } from '../../../../../../entities/clearbit';
import { ClearbitCompanyInfo } from '../../../../../../shared/api';
import { useOnClickOutside } from '../../../../../../shared/hooks/useClickOutside';
import { IconMap } from '../../../../../../shared/sprite';

type Props = {
  selectedCompany: ClearbitCompanyInfo | null;
  handleDelete: () => void;
  handleSelect: (company: ClearbitCompanyInfo) => void;
};

export const CompanySelectInput: React.FC<Props> = ({
  selectedCompany,
  handleDelete,
  handleSelect,
}) => {
  const dispatch = useDispatch();
  const companies = useSelector(clearbitModel.selectors.selectCompanies);
  const [isFocused, setFocused] = useState(false);

  const isLoading = useSelector(clearbitModel.selectors.selectIsLoading);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOutsideClick = () => {
    setFocused(false);
  };

  useOnClickOutside(containerRef, handleOutsideClick);

  const searchCompanies = (searchQuery: string) => {
    dispatch(clearbitModel.actions.searchCompanies({ searchQuery }));
  };

  return (
    <div ref={containerRef} className="relative group">
      <Input
        type="text"
        placeholder="Start typing company name..."
        autoComplete="off"
        label="Which company should this page be for? *"
        onChange={({ target: { value } }) => searchCompanies(value)}
        onFocus={() => setFocused(true)}
        heightSize="md"
        ref={inputRef}
      />
      {isLoading && (
        <LoaderDots size="sm" className="absolute right-4 bottom-4.5" />
      )}
      {isFocused && !!companies.length && (
        <ul className="flex flex-col gap-0.5 w-full border border-gray-300 rounded-lg max-h-72 overflow-y-scroll py-0.5 px-1.5 absolute top-17.5 shadow-lg bg-base-white z-50">
          {companies.map((company: ClearbitCompanyInfo) => (
            <CompanyRecord
              key={company.domain}
              companyInfo={company}
              handleClick={() => {
                handleSelect(company);
                setFocused(false);
              }}
              size="xs"
            />
          ))}
        </ul>
      )}
      {selectedCompany && (
        <div className="w-full h-10 border border-gray-300 rounded-lg bg-base-white absolute bottom-0 px-2 flex items-center gap-2">
          <Avatar
            src={selectedCompany.logo ?? ''}
            size="xs"
            displayName=""
            theme="rect"
          />
          <p className="text-gray-900">{selectedCompany.name}</p>
          <div className="ml-auto invisible group-hover:visible">
            <Button
              variant="icon"
              color="link-gray"
              size="xs"
              onClick={() => {
                handleDelete();
                inputRef.current?.focus();
              }}
            >
              <Icon glyph={IconMap.XClose} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
