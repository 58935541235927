import objectHash from 'hash-sum';
import { EMPTY } from './markEmptyNodes';
import { END_OF_BLOCK } from './addBlockSeparators';
import { JSONContent } from '@tiptap/core';
import { MarkedToken } from './types';

/**
 * Reconstructs a tree-like document structure from an array of token objects.
 *
 * Each token is expected to have:
 *   - character: a string (or special markers `END_OF_BLOCK` or `EMPTY`)
 *   - path: an array of objects defining the hierarchy.
 *
 * Adjustments made:
 * 1. Uses "content" (instead of "children") for nested nodes.
 * 2. Compares full path objects using objectHash.
 * 3. When opening nodes, always creates a "content" array for all nodes except the leaf.
 * 4. For END_OF_BLOCK, uses the token's path to know which node to close.
 * 5. For EMPTY tokens, opens the nodes as usual so that the leaf is added, but does not append any text.
 * 6. For text nodes (leaf nodes), appends directly to the "text" property.
 *
 * @param {Token[]} items - Array of token objects.
 * @returns {JsonContent} The reconstructed document tree.
 */
function reconstructDocument(items: MarkedToken[], char: string): JSONContent {
  // Create a dummy root node.
  const root: JSONContent = { type: 'root', content: [] };
  // openNodes holds the currently "open" nodes (with modifications).
  const openNodes: JSONContent[] = [root];
  // openTokens holds clean copies of the tokens that opened the nodes.
  // (We do not include the dummy root in openTokens.)
  const openTokens: JSONContent[] = [];
  // Track the previous tokens (a clean copy of the path) for comparison.
  let prevTokens: JSONContent[] = [];

  // Helper: Compute common prefix length between two arrays of tokens.
  const hashCache = new Map<JSONContent, string>();
  function getHash(token: JSONContent): string {
    if (hashCache.has(token)) {
      return hashCache.get(token)!;
    }
    const hash = objectHash(token);
    hashCache.set(token, hash);
    return hash;
  }

  function commonPrefixLength(
    arrA: JSONContent[],
    arrB: JSONContent[]
  ): number {
    let i = 0;
    while (
      i < arrA.length &&
      i < arrB.length &&
      getHash(arrA[i]) === getHash(arrB[i])
    ) {
      i++;
    }
    return i;
  }

  for (const item of items) {
    const currPath = item.path;
    // Create a clean copy of the current path tokens.
    const currTokens = currPath.map((token) => ({ ...token }));

    if (item.character === END_OF_BLOCK) {
      // END_OF_BLOCK: The provided path indicates which node's content is complete.
      // Pop nodes until openTokens.length equals currTokens.length,
      // then pop one more to "close" the indicated node.

      // Represent a deleted line break visually when closing a text node.
      // See "Test 12a" VS "Test 12b".
      if (item.op === 'delete') {
        const leaf = openNodes[openNodes.length - 1];
        if (leaf.type === 'text' && leaf.text !== undefined) {
          leaf.text += char;
        }
      }

      while (openTokens.length > currTokens.length) {
        openTokens.pop();
        openNodes.pop();
      }
      if (
        openTokens.length &&
        objectHash(openTokens[openTokens.length - 1]) ===
          objectHash(currTokens[currTokens.length - 1])
      ) {
        openTokens.pop();
        openNodes.pop();
      }
      // Reset prevTokens.
      prevTokens = openTokens.slice();
      continue;
    }

    // Process both regular and EMPTY tokens the same way:
    // Determine how many nodes are already open that match the current path.
    const commonLength = commonPrefixLength(prevTokens, currTokens);

    // Pop any nodes that are no longer common.
    while (openTokens.length > commonLength) {
      openTokens.pop();
      openNodes.pop();
    }

    // Open new nodes as needed.
    // For indices from commonLength to currTokens.length - 1, open a new node.
    // For all but the last node, create a "content" array.
    // For the leaf node, create a "text" property (unless this is an EMPTY token).
    for (let i = commonLength; i < currTokens.length; i++) {
      const token = currTokens[i];
      let newNode: JSONContent;
      if (i < currTokens.length - 1) {
        // For non-leaf nodes, always create a "content" array.
        newNode = { ...token, content: [] };
      } else {
        // For the leaf node, if the token is EMPTY, do not define the "text" property.
        if (item.character === EMPTY) {
          newNode = { ...token };
        } else {
          newNode = { ...token, text: '' };
        }
      }
      // Append the new node to the current open node's "content".
      const parent = openNodes[openNodes.length - 1];
      if (!parent.content) {
        parent.content = [];
      }
      parent.content.push(newNode);

      // Update our stacks.
      openNodes.push(newNode);
      openTokens.push(token);
    }

    // For regular tokens (non-EMPTY), append the character to the leaf's "text" property.
    if (item.character !== EMPTY) {
      const leaf = openNodes[openNodes.length - 1];
      if (leaf.text === undefined) {
        leaf.text = '';
      }
      leaf.text += item.character;
    }

    // Update prevTokens for the next iteration.
    prevTokens = currTokens;
  }

  // the only child should be our {type: "doc"} node
  const docNode = root.content?.[0];

  if (!docNode) {
    throw new Error('Doc node not found');
  }

  return docNode;
}

export { reconstructDocument };
