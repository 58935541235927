import { UpdateUserCallSettingsRequest } from '@distribute/shared/api-types/call-recorder';

import { call, select, put } from 'redux-saga/effects';
import { callRecordingsApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { callRecordingSettingsModel } from '../index';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';

export function* updateUserCallRecordingSettings(
  data: UpdateUserCallSettingsRequest
) {
  try {
    yield call(updateUserCallRecordingSettingsRequest, data);
  } catch (err) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update user settings')
      )
    );
  }
}

export function* updateUserCallRecordingSettingsRequest(
  data: UpdateUserCallSettingsRequest
) {
  yield put(callRecordingSettingsModel.actions.setIsUserSettingsLoading(true));

  try {
    const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
      yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const response: RT<typeof callRecordingsApi.updateUserSettings> =
      yield call(callRecordingsApi.updateUserSettings, data, {
        teamId: team.id,
      });

    yield put(callRecordingSettingsModel.actions.setUserSettings(response));
  } catch (err) {
    logger.error(err);
    throw err;
  } finally {
    yield put(
      callRecordingSettingsModel.actions.setIsUserSettingsLoading(false)
    );
  }
}
