import { IconMap } from '../../../shared/sprite';
import { Icon } from '../../../shared/ui/Icon';
import { FC } from 'react';

type Props = {
  name: string;
  emodji: string;
  onClick: () => void;
};

export const ChatBotSuggestion: FC<Props> = ({ name, emodji, onClick }) => {
  return (
    <div className="flex h-12 overflow-hidden relative w-full rounded-lg group cursor-pointer bg-gray-200 hover:bg-gradient-orange-to-purple hover:shadow-sm hover:p-0.5 p-0.375">
      <button
        type="button"
        className="flex-grow-1 rounded-md group-hover:bg-base-white bg-gray-50 flex flex-nowrap items-center gap-x-3 text-md text-gray-700 font-medium group-hover:p-2.5 p-2.625 group-hover:text-gray-900"
        onClick={onClick}
      >
        <span className="text-[20px]/[20px]">{emodji}</span>
        {name}
        <Icon
          className="ml-auto group-hover:hidden"
          width={20}
          glyph={IconMap.ArrowNarrowRight}
        ></Icon>
        <Icon
          className="ml-auto hidden group-hover:block"
          width={20}
          glyph={IconMap.ColoredArrowNarrowRight}
        ></Icon>
      </button>
    </div>
  );
};
