export enum LeftSidebarOptions {
  TEMPLATES = 'templates',
  SNIPPETS = 'snippets',
  ELEMENTS = 'elements',
  VIDEOS = 'videos',
  CALLS = 'calls',
  CONVERSIONS = 'conversions',
  NARRATION = 'narration',
  SETTINGS = 'settings',
}

export enum ActiveConversionPanel {
  ALERT_BAR = 'alertBar',
  POP_UP = 'popUp',
  CTA = 'cta',
  GATED_CONTENT = 'gatedContent',
  SQUEEZE_PAGE = 'squeezePage',
}
