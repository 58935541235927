import { useEffect, useMemo } from 'react';
import { Container, Description, Title } from './components';
import { ReactComponent as CreateWithAiIcon } from '../../../../../shared/svgr/create-with-ai-intermediate-step.svg';
import { useCreatePageStepContext } from '../context';

export const IntermediateStep = ({ isVisible }: { isVisible: boolean }) => {
  const { isAppearanceDone, isPageTypeDone, isSourceDone } =
    useCreatePageStepContext();

  const items = useMemo(() => ['appearance', 'page-type', 'source'], []);

  const enabledItems = useMemo(
    () =>
      [
        isAppearanceDone && 'appearance',
        isPageTypeDone && 'page-type',
        isSourceDone && 'source',
      ].filter(Boolean),
    [isAppearanceDone, isPageTypeDone, isSourceDone]
  );

  const createWithAIEnabled = enabledItems.length === items.length;

  useEffect(() => {
    const createWithAiIcon = document.getElementById('create-with-ai-icon');
    if (!createWithAiIcon) return;

    items.forEach((item) => {
      const enabledIcon = createWithAiIcon.querySelector(
        `[data-id="${item}-enabled-icon"]`
      ) as SVGPathElement;
      const enabledBg = createWithAiIcon.querySelector(
        `[data-id="${item}-enabled-bg"]`
      ) as SVGPathElement;

      if (!enabledIcon || !enabledBg) return;

      const isEnabled = enabledItems.includes(item);
      enabledIcon.style.display = isEnabled ? 'block' : 'none';
      enabledBg.style.fill = isEnabled ? '#fff' : 'transparent';
    });

    const elements = {
      bg1: createWithAiIcon.querySelector(
        '[data-id="create-with-ai-enabled-bg-1"]'
      ) as SVGPathElement,
      bg2: createWithAiIcon.querySelector(
        '[data-id="create-with-ai-enabled-bg-2"]'
      ) as SVGPathElement,
      text: createWithAiIcon.querySelector(
        '[data-id="create-with-ai-text"]'
      ) as SVGTextElement,
    };

    if (!elements.bg1 || !elements.bg2 || !elements.text) return;

    if (createWithAIEnabled) {
      elements.bg1.style.fill = '';
      elements.bg2.style.fill = '';
      elements.text.style.fill = 'white';
      elements.text.style.stroke = 'white';
    } else {
      elements.bg1.style.fill = 'transparent';
      elements.bg2.style.fill = 'transparent';
      elements.text.style.fill = '#BEBEBE';
      elements.text.style.stroke = '#BEBEBE';
    }
  }, [createWithAIEnabled, enabledItems, items]);

  return (
    <Container isVisible={isVisible}>
      <CreateWithAiIcon
        id="create-with-ai-icon"
        width={360}
        height={360}
        className="-mb-14"
      />
      {createWithAIEnabled ? (
        <div className="flex flex-col gap-2">
          <Title>Now you can create page outline</Title>
          <Description>
            Use the button below the form to generate the outline of your page.
            After making necessary changes, use the button to generate the page.
          </Description>
        </div>
      ) : (
        <>
          <Title>Finish all the steps to create page outline</Title>
          <Description>
            Please provide all the necessary information to create a successful
            page that meets your specific needs.
          </Description>
        </>
      )}
    </Container>
  );
};
