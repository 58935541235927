import { useDispatch, useSelector } from 'react-redux';
import { ImageInput } from '../../../../../../src/shared/ui';
import { pageSettingsModel } from '../../../../../../src/features/page-settings';
import { pagesModel } from '../../../../../../src/features/pages';
import { useCollaboration } from '../../../../../../src/entities/collaboration';
import {
  ChangeMetaDescriptionForm,
  ChangeMetaTitleForm,
  SettingItemWrapper,
} from './components';

export const AdvancedSettingsTab = () => {
  const dispatch = useDispatch();

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );
  const isUploading = useSelector(
    pageSettingsModel.selectors.selectIsUploadImageLoading
  );
  const uploadProgress = useSelector(
    pageSettingsModel.selectors.selectUploadingImageProgress
  );
  const isRemoving = useSelector(
    pageSettingsModel.selectors.selectIsRemoveImageLoading
  );

  const { seoConfigurationData, updateSeoConfigurationField } =
    useCollaboration();

  const imageUrl = seoConfigurationData.ogImage;

  const onUpdateImage = (image: File) => {
    dispatch(
      pageSettingsModel.actions.changeSeoConfigurationsImage({
        documentContentId: currentPage.content.id,
        pageId: currentPage.id,
        image,
        cb: (url) => {
          updateSeoConfigurationField('ogImage', url);
        },
      })
    );
  };

  const onRemoveImage = () => {
    dispatch(
      pageSettingsModel.actions.removeSeoConfigurationsImage({
        documentContentId: currentPage.content.id,
        pageId: currentPage.id,
        cb: () => {
          updateSeoConfigurationField('ogImage', undefined);
        },
      })
    );
  };

  return (
    <div className="flex flex-col gap-5">
      <ChangeMetaTitleForm />
      <ChangeMetaDescriptionForm />
      <SettingItemWrapper title="Open Graph Image" isHideBorder>
        <p className="mb-4 text-sm text-gray-500">
          The info that shows up when sharing content on Facebook, Twitter,
          LinkedIn, and Pinterest. Make sure your images are at least 1200px by
          630px and have a 1.91:1 aspect ratio.
        </p>

        <ImageInput
          imageUrl={imageUrl}
          isUploading={isUploading}
          uploadProgress={uploadProgress}
          isRemoving={isRemoving}
          onUpdateImage={onUpdateImage}
          onRemoveImage={onRemoveImage}
          isLabel={false}
          fileInputStyles="h-31"
        />
      </SettingItemWrapper>
    </div>
  );
};
