import { select, put, call } from 'redux-saga/effects';
import { templatesModel } from '../index';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import {
  findCustomTaskLists,
  generateContentWithNewTasklists,
} from '@distribute/shared/utils';
import { JSONContentFactory } from '@distribute/shared/tiptap/json-content';
import {
  DocumentContentItem,
  TemplateCreationPhase,
} from '@distribute/shared/types';
import { tasksApi } from '../../../../shared/api';

export function* addContentItem() {
  const currentTemplate: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  const currentContent = currentTemplate.content;
  const contentItems = currentContent.contentItems;
  const itemsCount = contentItems.length;
  const newContentItem = {
    id: 0 - itemsCount,
    name: 'New tab',
    contentJson: JSONContentFactory.DEFAULT(),
    order: contentItems.length,
    isVisible: true,
    htmlContent: '',
  };

  yield put(
    templatesModel.actions.setCurrentTemplate({
      ...currentTemplate,
      content: {
        ...currentContent,
        contentItems: [...contentItems, newContentItem],
      },
    })
  );

  yield put(
    templatesModel.actions.setCurrentTemplateContentId(newContentItem.id)
  );
  yield put(templatesModel.actions.setOpenedContentItemId(newContentItem.id));
  yield call(syncTemplate, currentTemplate);
}

export function* duplicateContentItem({ payload: id }: { payload: number }) {
  const currentTemplate: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  const currentContent = currentTemplate.content;
  const contentItems: DocumentContentItem[] = currentContent.contentItems;
  const currentItem = contentItems.find(
    (item) => item.id === id
  ) as DocumentContentItem;
  const itemsCount = contentItems.length;

  let newContentJson = currentItem.contentJson;

  const tasklists = findCustomTaskLists(currentItem.contentJson);

  if (tasklists.length > 0) {
    const taskListIds: string[] = tasklists.map(
      (tasklist) => tasklist.attrs.id
    );

    const newIds: string[] = yield call(
      tasksApi.createTasklistsByIds,
      currentTemplate.content.id,
      { ids: taskListIds }
    );

    newContentJson = generateContentWithNewTasklists(newContentJson, newIds);
  }

  const newContentItem: DocumentContentItem = {
    id: 0 - itemsCount,
    name: currentItem.name ? `Copy of ${currentItem.name}` : 'Copy of untitled',
    contentJson: newContentJson,
    order: currentItem.order + 1,
    isVisible: currentItem.isVisible,
    htmlContent: currentItem.htmlContent,
  };

  const reorderedItems: DocumentContentItem[] = [
    ...updateItemsOrder(contentItems, currentItem.order, 1),
    newContentItem,
  ];
  reorderedItems.sort((a, b) => a.order - b.order);

  yield put(
    templatesModel.actions.setCurrentTemplate({
      ...currentTemplate,
      content: {
        ...currentContent,
        contentItems: reorderedItems,
      },
    })
  );

  yield call(syncTemplate, currentTemplate);
}

export function* deleteContentItem({ payload: id }: { payload: number }) {
  const currentTemplate: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  const currentContent = currentTemplate.content;
  const contentItems: DocumentContentItem[] = currentContent.contentItems;
  const currentItem = contentItems.find(
    (item) => item.id === id
  ) as DocumentContentItem;

  const reorderedItems: DocumentContentItem[] = updateItemsOrder(
    contentItems.filter((item) => item.id !== id),
    currentItem.order,
    -1
  );

  yield put(
    templatesModel.actions.setCurrentTemplate({
      ...currentTemplate,
      content: {
        ...currentContent,
        contentItems: reorderedItems,
      },
    })
  );

  yield call(syncTemplate, currentTemplate);
}

export function* updateContentItemFromTemplate({
  payload: { contentItemId, cb },
}: ReturnType<typeof templatesModel.actions.updateContentItemFromTemplate>) {
  const currentTemplate: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  const currentSelectedTemplate: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentSelectedTemplateWithError
  );
  const currentContent = currentTemplate.content;
  const contentItems: DocumentContentItem[] = currentContent.contentItems;
  const currentSelectedContentItem =
    currentSelectedTemplate?.content?.contentItems[0];
  const updatedTemplate = {
    ...currentTemplate,
    content: {
      ...currentContent,
      contentItems: contentItems.map((item) => {
        if (item.id === contentItemId) {
          return {
            ...item,
            contentJson: currentSelectedContentItem.contentJson,
            htmlContent: currentSelectedContentItem.htmlContent,
          };
        }

        return item;
      }),
    },
  };

  yield put(templatesModel.actions.setCurrentTemplate(updatedTemplate));
  yield put(templatesModel.actions.setCurrentSelectedTemplate(undefined));
  yield put(templatesModel.actions.setIsTemplateSavedInEditor(false));
  cb?.(updatedTemplate);
}

export function* updateTemplateContentItem({
  payload: { contentItemId, data },
}: ReturnType<typeof templatesModel.actions.updateTemplateContentItem>) {
  const currentTemplate: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  const currentContent = currentTemplate.content;
  const contentItems: DocumentContentItem[] = currentContent.contentItems;

  yield put(
    templatesModel.actions.setCurrentTemplate({
      ...currentTemplate,
      content: {
        ...currentContent,
        contentItems: contentItems.map((item) => {
          if (item.id === contentItemId) {
            return {
              ...item,
              ...data,
            };
          }

          return item;
        }),
      },
    })
  );

  yield call(syncTemplate, currentTemplate);
}

export function* updateTemplateContentItemOrder({
  payload: { data },
}: ReturnType<typeof templatesModel.actions.updateTemplateContentItemOrder>) {
  const currentTemplate: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  const currentContent = currentTemplate.content;
  const contentItems: DocumentContentItem[] = currentContent.contentItems;

  yield put(
    templatesModel.actions.setCurrentTemplate({
      ...currentTemplate,
      content: {
        ...currentContent,
        contentItems: contentItems.map((item) => {
          const currentItem = data.find((d) => d.id === item.id);

          if (currentItem) {
            return {
              ...item,
              order: currentItem.order ?? item.order,
            };
          }

          return item;
        }),
      },
    })
  );

  yield call(syncTemplate, currentTemplate);
}

function updateItemsOrder(
  items: DocumentContentItem[],
  pos: number,
  correction: number
): DocumentContentItem[] {
  return items.map((item) => {
    if (item.order > pos) {
      return {
        ...item,
        order: item.order + correction,
      };
    }

    return item;
  });
}

function* syncTemplate(template: TemplateExtended) {
  if (template.creationPhase === TemplateCreationPhase.DRAFT) {
    yield put(templatesModel.actions.updateTemplateFromEditor());
  } else {
    yield put(templatesModel.actions.setIsTemplateSavedInEditor(false));
  }
}
