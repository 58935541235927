import { JSONContent } from '@tiptap/react';
import {
  CallSummaryContent,
  CallSummaryGeneralContent,
  CallSummaryType,
  CallSummarySalesContent,
} from '@distribute/shared/types';

type VideoRecordAttrs = {
  prefix: string;
  playbackId: string;
  aspectRatio: string;
};

export const JSONContentFactory = {
  DEFAULT(): JSONContent {
    return JSONContentFactory.createDoc([JSONContentFactory.createParagraph()]);
  },
  createDoc(content?: JSONContent[]): JSONContent {
    return { type: 'doc', content };
  },
  createParagraph(content?: JSONContent[]): JSONContent {
    return {
      type: 'paragraph',
      attrs: {
        textAlign: 'left',
      },
      content,
    };
  },
  createVideoRecord(attrs: VideoRecordAttrs): JSONContent {
    return {
      type: 'resizeableFigure',
      attrs: {
        'data-media-width': 1008, // max container width
        'data-media-height': 'auto',
        'data-align': 'center',
      },
      content: [
        {
          type: 'videoRecord',
          attrs,
        },
      ],
    };
  },
  createHeading(text: string, level: number): JSONContent {
    return {
      type: 'heading',
      attrs: {
        textAlign: 'left',
        level,
      },
      content: [
        {
          type: 'text',
          marks: [{ type: 'bold' }],
          text,
        },
      ],
    };
  },
  createText(text: string, marks?: Array<{ type: string }>): JSONContent {
    return {
      type: 'text',
      text,
      ...(marks && { marks }),
    };
  },
  createList(items: JSONContent[], ordered = true): JSONContent {
    return {
      type: ordered ? 'orderedList' : 'bulletList',
      attrs: ordered ? { start: 1 } : undefined,
      content: items.map((item) => ({
        type: 'listItem',
        attrs: {
          textAlign: 'left',
        },
        content: Array.isArray(item) ? item : [item],
      })),
    };
  },
};

const JSONSummaryBuilder = {
  actionItem(item: {
    text: string;
    assignedTo?: string | null;
    timeline: string;
  }): JSONContent {
    return JSONContentFactory.createParagraph([
      JSONContentFactory.createText(`${item.text}`, [{ type: 'textStyle' }]),
      ...(item.assignedTo
        ? [
            JSONContentFactory.createText(' – Assigned to: ', [
              { type: 'textStyle' },
            ]),
            JSONContentFactory.createText(item.assignedTo, [
              { type: 'textStyle' },
              { type: 'bold' },
            ]),
          ]
        : []),
      JSONContentFactory.createText(` (Due: ${item.timeline})`, [
        { type: 'textStyle' },
      ]),
    ]);
  },
  createGeneralSummary(content: CallSummaryGeneralContent): JSONContent[] {
    const documentContent: JSONContent[] = [];

    // Meeting Purpose
    documentContent.push(
      JSONContentFactory.createHeading('Meeting Purpose', 3),
      JSONContentFactory.createParagraph([
        JSONContentFactory.createText(content.meetingPurpose),
      ])
    );

    // Key Takeaways
    documentContent.push(
      JSONContentFactory.createHeading('Key Takeaways', 3),
      JSONContentFactory.createList(
        content.takeaways.map((takeaway) =>
          JSONContentFactory.createParagraph([
            JSONContentFactory.createText(`${takeaway.text}.`),
          ])
        ),
        false
      )
    );

    // Topics
    documentContent.push(JSONContentFactory.createHeading('Topics', 3));

    content.topics.forEach((topic) => {
      documentContent.push(
        JSONContentFactory.createHeading(topic.subject, 4),
        JSONContentFactory.createList(
          topic.items.map((item) =>
            JSONContentFactory.createParagraph([
              JSONContentFactory.createText(`${item.text}: `, [
                { type: 'textStyle' },
                { type: 'bold' },
              ]),
              JSONContentFactory.createText(item.details + '.', [
                { type: 'textStyle' },
              ]),
            ])
          ),
          false
        )
      );
    });

    // Next Steps
    if (content.nextSteps?.length) {
      documentContent.push(
        JSONContentFactory.createHeading('Next Steps', 3),
        JSONContentFactory.createList(
          content.nextSteps.map((step) => JSONSummaryBuilder.actionItem(step)),
          false
        )
      );
    }

    // Action Items
    if (content.actionItems?.length) {
      documentContent.push(
        JSONContentFactory.createHeading('Action Items', 3),
        JSONContentFactory.createList(
          content.actionItems.map((item) =>
            JSONSummaryBuilder.actionItem(item)
          ),
          false
        )
      );
    }

    // Add final empty paragraph
    documentContent.push(JSONContentFactory.createParagraph([]));

    return documentContent;
  },
  createSalesSummary(content: CallSummarySalesContent): JSONContent[] {
    const documentContent: JSONContent[] = [];

    // Deal Information Section
    documentContent.push(
      JSONContentFactory.createHeading('Deal Information', 2)
    );

    // Prospect Details
    documentContent.push(
      JSONContentFactory.createHeading('Prospect Details', 3),
      JSONContentFactory.createList(
        [
          JSONContentFactory.createParagraph([
            JSONContentFactory.createText('Company: ', [
              { type: 'textStyle' },
              { type: 'bold' },
            ]),
            JSONContentFactory.createText(`${content.prospect.company}.`),
          ]),
          JSONContentFactory.createParagraph([
            JSONContentFactory.createText('Contacts: ', [
              { type: 'textStyle' },
              { type: 'bold' },
            ]),
            JSONContentFactory.createText(`${content.prospect.contacts}.`),
          ]),
          JSONContentFactory.createParagraph([
            JSONContentFactory.createText('Description: ', [
              { type: 'textStyle' },
              { type: 'bold' },
            ]),
            JSONContentFactory.createText(`${content.prospect.description}.`),
          ]),
        ],
        false
      )
    );

    // Call Context
    documentContent.push(
      JSONContentFactory.createHeading('Call Context', 3),
      JSONContentFactory.createParagraph([
        JSONContentFactory.createText(content.callContext),
      ])
    );

    // Seats
    documentContent.push(
      JSONContentFactory.createHeading('Seats', 3),
      JSONContentFactory.createParagraph([
        JSONContentFactory.createText(content.seats),
      ])
    );

    // Budget
    documentContent.push(
      JSONContentFactory.createHeading('Budget', 3),
      JSONContentFactory.createParagraph([
        JSONContentFactory.createText(content.budget),
      ])
    );

    // Current State
    documentContent.push(
      JSONContentFactory.createHeading('Current State', 3),
      JSONContentFactory.createParagraph([
        JSONContentFactory.createText(content.currentState),
      ])
    );

    // Pain Points
    documentContent.push(
      JSONContentFactory.createHeading('Pain Points', 3),
      content.painPoints.length > 0
        ? JSONContentFactory.createList(
            content.painPoints.map((point) =>
              JSONContentFactory.createParagraph([
                JSONContentFactory.createText(point),
              ])
            ),
            false
          )
        : JSONContentFactory.createParagraph([])
    );

    // Specific Requirements
    documentContent.push(
      JSONContentFactory.createHeading('Specific Requirements', 3),
      content.specificRequirements.length > 0
        ? JSONContentFactory.createList(
            content.specificRequirements.map((req) =>
              JSONContentFactory.createParagraph([
                JSONContentFactory.createText(req),
              ])
            ),
            false
          )
        : JSONContentFactory.createParagraph([])
    );

    // Tech Stack
    documentContent.push(
      JSONContentFactory.createHeading('Tech Stack', 3),
      content.techStack.length > 0
        ? JSONContentFactory.createList(
            content.techStack.map((tech) =>
              JSONContentFactory.createParagraph([
                JSONContentFactory.createText(tech),
              ])
            ),
            false
          )
        : JSONContentFactory.createParagraph([])
    );

    // Objections
    documentContent.push(
      JSONContentFactory.createHeading('Objections', 3),
      content.objections.length > 0
        ? JSONContentFactory.createList(
            content.objections.map((obj) => [
              JSONContentFactory.createParagraph([
                JSONContentFactory.createText('Concern: ', [
                  { type: 'textStyle' },
                  { type: 'bold' },
                ]),
                JSONContentFactory.createText(obj.objection),
              ]),
              JSONContentFactory.createList(
                [
                  JSONContentFactory.createParagraph([
                    JSONContentFactory.createText('Status: ', [
                      { type: 'textStyle' },
                      { type: 'bold' },
                    ]),
                    JSONContentFactory.createText(obj.status),
                  ]),
                  JSONContentFactory.createParagraph([
                    JSONContentFactory.createText('Resolution: ', [
                      { type: 'textStyle' },
                      { type: 'bold' },
                    ]),
                    JSONContentFactory.createText(`${obj.resolution}.`),
                  ]),
                ],
                false
              ),
            ])
          )
        : JSONContentFactory.createParagraph([])
    );

    // Competitive Landscape
    documentContent.push(
      JSONContentFactory.createHeading('Competitive Landscape', 3),
      JSONContentFactory.createParagraph([
        JSONContentFactory.createText('Other solutions being evaluated:'),
      ]),
      content.otherSolutions.length > 0
        ? JSONContentFactory.createList(
            content.otherSolutions.map((solution) =>
              JSONContentFactory.createParagraph([
                JSONContentFactory.createText(solution),
              ])
            ),
            false
          )
        : JSONContentFactory.createParagraph([])
    );

    // Timeline
    documentContent.push(
      JSONContentFactory.createHeading('Timeline', 3),
      JSONContentFactory.createParagraph([
        JSONContentFactory.createText(content.timeline),
      ])
    );

    // Decision Maker
    documentContent.push(
      JSONContentFactory.createHeading('Decision Maker', 3),
      JSONContentFactory.createList([
        JSONContentFactory.createParagraph([
          JSONContentFactory.createText('Primary: ', [
            { type: 'textStyle' },
            { type: 'bold' },
          ]),
          JSONContentFactory.createText(content.decisionMaker.primary),
        ]),
        JSONContentFactory.createParagraph([
          JSONContentFactory.createText('Other stakeholders: ', [
            { type: 'textStyle' },
            { type: 'bold' },
          ]),
          JSONContentFactory.createText(
            content.decisionMaker.otherStakeholders
          ),
        ]),
      ])
    );

    // Procurement Process
    documentContent.push(
      JSONContentFactory.createHeading('Procurement Process', 3),
      JSONContentFactory.createParagraph([
        JSONContentFactory.createText(content.procurementProcess),
      ])
    );

    // Next Steps Section
    documentContent.push(
      JSONContentFactory.createHeading('Next Steps', 2),
      content.nextSteps.length > 0
        ? JSONContentFactory.createList(
            content.nextSteps.map((step) =>
              JSONContentFactory.createParagraph([
                JSONContentFactory.createText(`${step}.`),
              ])
            ),
            false
          )
        : JSONContentFactory.createParagraph([])
    );

    // Q&A Section
    documentContent.push(
      JSONContentFactory.createHeading('Q&A', 2),
      // Questions We Asked
      JSONContentFactory.createHeading('Questions We Asked', 3),
      content.questionsWeAsked.length > 0
        ? JSONContentFactory.createList(
            content.questionsWeAsked.map((q) => [
              JSONContentFactory.createParagraph([
                JSONContentFactory.createText(q.question, [
                  { type: 'textStyle' },
                  { type: 'bold' },
                ]),
              ]),
              JSONContentFactory.createList(
                [
                  JSONContentFactory.createParagraph([
                    JSONContentFactory.createText(`${q.response}.`),
                  ]),
                ],
                false
              ),
            ])
          )
        : JSONContentFactory.createParagraph([]),

      // Questions They Asked
      JSONContentFactory.createHeading('Questions They Asked', 3),
      content.questionsTheyAsked.length > 0
        ? JSONContentFactory.createList(
            content.questionsTheyAsked.map((q) => [
              JSONContentFactory.createParagraph([
                JSONContentFactory.createText(q.question, [
                  { type: 'textStyle' },
                  { type: 'bold' },
                ]),
              ]),
              JSONContentFactory.createList(
                [
                  JSONContentFactory.createParagraph([
                    JSONContentFactory.createText(`${q.response}.`),
                  ]),
                ],
                false
              ),
            ])
          )
        : JSONContentFactory.createParagraph([])
    );

    // Action Items Section
    documentContent.push(
      JSONContentFactory.createHeading('Action Items', 2),
      content.actionItems.length > 0
        ? JSONContentFactory.createList(
            content.actionItems.map((item) =>
              JSONSummaryBuilder.actionItem(item)
            ),
            false
          )
        : JSONContentFactory.createParagraph([])
    );

    // Add final empty paragraphs
    documentContent.push(JSONContentFactory.createParagraph([]));

    return documentContent;
  },
};

export const JSONContentBuilder = {
  videoRecord: ({ attrs }: { attrs: VideoRecordAttrs }) => {
    const content = [
      JSONContentFactory.createVideoRecord(attrs),
      JSONContentFactory.createParagraph(),
    ];

    return {
      asArray: () => content,
      asDoc: () => JSONContentFactory.createDoc(content),
    };
  },
  callSummary: (
    type: CallSummaryType,
    data: CallSummaryContent
  ): JSONContent[] => {
    switch (type) {
      case CallSummaryType.GENERAL:
        return JSONSummaryBuilder.createGeneralSummary(
          data as CallSummaryGeneralContent
        );
      case CallSummaryType.SALES:
        return JSONSummaryBuilder.createSalesSummary(
          data as CallSummarySalesContent
        );
      default:
        return [];
    }
  },
  callRecord: ({
    attrs,
    type,
    data,
  }: {
    attrs: VideoRecordAttrs;
    type?: CallSummaryType;
    data?: CallSummaryContent;
  }) => {
    if (type && data) {
      return JSONContentFactory.createDoc([
        JSONContentFactory.createVideoRecord(attrs),
        ...JSONContentBuilder.callSummary(type, data),
      ]);
    }

    return JSONContentFactory.createDoc([
      JSONContentFactory.createVideoRecord(attrs),
    ]);
  },
};
