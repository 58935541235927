import React, { useState, useMemo } from 'react';
import { SearchInput } from '../../../../../../src/shared/ui';
import { SLASH_MENU_GROUPS } from '../../../../../pages/new-editor/lib/commands';
import { SidebarTabLayout } from '../shared/SidebarTabLayout';

import { ElementItem } from './ElementItem';
import { GroupLabel } from './GroupLabel';
import { NoElementsFoundScreen } from './NoElementsFoundScreen';
import { useStore } from 'react-redux';
import { useTiptapEditor } from '../../../../../../src/entities/tiptap-editor';

// Remove AI Assistant group
const elements = SLASH_MENU_GROUPS.slice(1);

export const EditorElements: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const { editor } = useTiptapEditor();
  const store = useStore();

  const filteredElements = useMemo(() => {
    if (!searchQuery.trim()) {
      return elements;
    }

    const searchLower = searchQuery.toLowerCase();
    const matchingItems = elements.flatMap((group) =>
      group.items.filter(
        (item) =>
          item.name.toLowerCase().includes(searchLower) ||
          item.aliases.some((alias) =>
            alias.toLowerCase().includes(searchLower)
          )
      )
    );

    return matchingItems.length > 0
      ? [{ label: '', items: matchingItems }]
      : [];
  }, [searchQuery]);

  const clearSearch = () => {
    setSearchQuery('');
  };

  return (
    <SidebarTabLayout
      headerComponent={
        <div>
          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search"
          />
        </div>
      }
    >
      {filteredElements.length > 0 ? (
        filteredElements.map((group) => (
          <div key={group.label}>
            {!searchQuery && group.label && (
              <GroupLabel>{group.label}</GroupLabel>
            )}
            <ul className="list-none">
              {group.items.map((command) => (
                <ElementItem
                  key={command.key}
                  id={command.key}
                  name={command.name}
                  icon={command.icon}
                  actionPreview={command.actionPreview}
                  action={() => {
                    if (editor) {
                      command.action(editor, store);
                    }
                  }}
                />
              ))}
            </ul>
          </div>
        ))
      ) : (
        <NoElementsFoundScreen clearSearchQuery={clearSearch} />
      )}
    </SidebarTabLayout>
  );
};
