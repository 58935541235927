import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { pagesModel } from '../../../../features/pages';
import { LEFT_SIDEBAR_ID } from '../../../../features/editor-left-sidebar/ui/components/lib';

export const ActionPreviewCard = () => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const currentActionPreview = useSelector(
    pagesModel.selectors.selectCurrentActionPreview
  );
  const actionPreview = currentActionPreview?.actionPreview;

  //TODO: remove isPageEditor after sync all editors
  const isPageEditor = Boolean(
    useSelector(pagesModel.selectors.selectCurrentPage)
  );

  useEffect(() => {
    if (!elementRef.current || !currentActionPreview?.position) return;

    const leftSidebar = document.getElementById(LEFT_SIDEBAR_ID);

    const { right, top, height, scrollTop, scrollLeft } =
      currentActionPreview.position;
    const offset = elementRef.current.offsetTop;
    const halfItemHeight = height / 2; // 1/2 of the button height
    const halfCardHeight = elementRef.current.offsetHeight / 2; // 1/2 of the card height
    const topPos = top + scrollTop + halfItemHeight - halfCardHeight - 50;
    const leftPos = right + scrollLeft;
    elementRef.current.style.top = `${topPos}px`;
    elementRef.current.style.left = isPageEditor
      ? `${leftPos - (leftSidebar?.clientWidth ?? 0) - 20}px`
      : `${leftPos}px`;

    const isNotVisible =
      elementRef.current.getBoundingClientRect().bottom > window.innerHeight;

    if (isNotVisible) {
      const additionalTop =
        elementRef.current.getBoundingClientRect().bottom - window.innerHeight;
      elementRef.current.style.top = `${topPos - additionalTop}px`;
    }
  }, [currentActionPreview]);

  if (!actionPreview) return null;

  return (
    <div
      ref={elementRef}
      className="absolute bg-base-white border border-gray-200 rounded-xl shadow-xl p-3 w-70 z-[9999]"
    >
      <div className="pb-3">
        {React.isValidElement(actionPreview.cover) ? (
          actionPreview.cover
        ) : (
          <img
            src={`../../../../assets/images/actionPreviewCover/${actionPreview.cover}`}
            width="256px"
            height="164px"
          />
        )}
      </div>
      <p className="flex items-center gap-2 mb-1 text-gray-600">
        {actionPreview.icon}
        <span className="text-base font-semibold text-gray-900 line-clamp-2 max-w-51">
          {actionPreview.name}
        </span>
      </p>
      <p className="text-sm font-normal text-gray-700 line-clamp-2">
        {actionPreview.description}
      </p>
    </div>
  );
};
