import React from 'react';
import { Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { useDispatch } from 'react-redux';
import { pageCreationFlowModel } from '../model';
import { PageCreationTypesEnum } from '../config/types';

type Props = {
  icon: keyof typeof IconMap;
  name: string;
  description: string;
  handleClick: () => void;
};

export const GetStartedStepItem: React.FC<Props> = ({
  icon,
  name,
  description,
  handleClick,
}) => {
  return (
    <button
      className="text-center border shadow-xs transition cursor-pointer border-gray-200 rounded-2.5 p-6 group bg-base-white hover:border-primary-600 hover:bg-primary-50 focus:outline-none focus:border-primary-600 focus:bg-primary-50"
      onClick={handleClick}
    >
      <span className="inline-flex items-center justify-center w-7 h-7 rounded-md bg-gray-100 group-hover:bg-primary-100 group-focus:bg-primary-100">
        <Icon
          glyph={IconMap[icon]}
          width={20}
          className="text-gray-500 group-hover:text-primary-600 group-focus:text-primary-600"
        />
      </span>
      <div className="pt-4">
        <h3 className="text-gray-900 text-base font-semibold mb-0">{name}</h3>
        <p className="text-gray-600 text-sm font-normal">{description}</p>
      </div>
    </button>
  );
};

export const GetStartedStep = () => {
  const dispatch = useDispatch();

  const handleChooseStep = (type: PageCreationTypesEnum) => {
    dispatch(pageCreationFlowModel.actions.setPageCreationType(type));
  };

  return (
    <div
      className="flex items-center justify-center flex-col h-full px-4 relative"
      style={{
        background: `url('../../../assets/images/grid-background.png') no-repeat center center`,
        backgroundSize: 'auto 100%',
      }}
    >
      <h2 className="text-display-sm text-gray-900 font-semibold font-display pt-4 pb-6">
        How would you like to get started?
      </h2>
      <div className="grid grid-cols-3 gap-4 p-4 max-w-200">
        <GetStartedStepItem
          icon="BlankPageSquare"
          name="Start from scratch"
          description="Step out boldly, take a leap, and create something exquisite."
          handleClick={() =>
            handleChooseStep(PageCreationTypesEnum.FROM_SCRATCH)
          }
        />
        <GetStartedStepItem
          icon="AIStars"
          name="Create with AI"
          description="Save time and create pages faster with Al's first draft handling."
          handleClick={() => handleChooseStep(PageCreationTypesEnum.WITH_AI)}
        />
        <GetStartedStepItem
          icon="LayersThree01"
          name="Use template"
          description="Utilize one of our variety of templates to design  professional-looking page."
          handleClick={() =>
            handleChooseStep(PageCreationTypesEnum.FROM_TEMPLATE)
          }
        />
      </div>
    </div>
  );
};
