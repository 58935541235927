import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Icon, TabsGroupTopBorder } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { Page, Folder } from '@distribute/shared/types';
import { navigate } from '../../../../processes/navigation';
import {
  GETTING_STARTED_ROUTE,
  WORKSPACE_FOLDER_ROUTE,
  WORKSPACE_ROUTE,
} from '../../../../entities/history';
import { generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  QUERY_PARAM_IS_SHOW_TEMPLATES_MODAL,
  pagesModel,
  usePagePermissions,
} from '../../../../features/pages';
import { EditorHeaderToolbar } from './EditorHeaderToolbar';
import { PreviewBar } from './PreviewBar';
import cn from 'classnames';
import { useWindowWidth } from '@distribute/frontend/utils';
import { PageSettingsDropdown } from './PageSettingsDropdown';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { foldersModel } from '../../../../features/folders';
import {
  getTemplatesRoute,
  templatesModel,
} from '../../../../features/templates';
import { getQueryParam, inert } from '../../../../shared/lib';
import { checkIsGettingStartedAvailable } from '../../../../features/getting-started/lib';
import { teamsModel, useTeamPermissions } from '../../../../features/teams';
import { useUserAgentDevices } from '../../../../shared/hooks/useUserAgentDevices';
import { useCollaboration } from '../../../../entities/collaboration';
import { narrationModel } from '../../../../features/narration';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { EditorHeaderLeftSide } from './EditorHeaderLeftSide';
import { changeGleapBlockVisibility } from '../../../../entities/gleap';

export enum HeaderTabs {
  CREATE = 'create',
  ANALYTICS = 'analytics',
}

type EditorHeaderProps = {
  currentPageOrTemplate: Page | TemplateExtended;
  isTemplateMode: boolean;
  setSelectedHeaderTab: Dispatch<SetStateAction<string>>;
  selectedHeaderTab: string;
};

const tabs = [
  { id: HeaderTabs.CREATE, label: 'Create' },
  { id: HeaderTabs.ANALYTICS, label: 'Analytics' },
];

export const EditorHeader: React.FC<EditorHeaderProps> = ({
  currentPageOrTemplate,
  isTemplateMode,
  setSelectedHeaderTab,
  selectedHeaderTab,
}) => {
  const isNarrationRecordingSessionActive = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionActive
  );

  const dispatch = useDispatch();
  const [isShowTemplatesModal] = useState(
    getQueryParam(QUERY_PARAM_IS_SHOW_TEMPLATES_MODAL) === 'true'
  );

  const isEditorPreview = useSelector(
    pagesModel.selectors.selectIsEditorPreview
  );

  const { isCanEditDocumentContent } = usePagePermissions(
    isTemplateMode ? undefined : (currentPageOrTemplate as Page)
  );

  const currentFolder = useSelector(
    foldersModel.selectors.selectCurrentFolder
  ) as Folder;
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isRedirectToGettingStartedOnBack =
    isShowTemplatesModal && checkIsGettingStartedAvailable(currentTeam);

  const onBackClick = () => {
    if (isTemplateMode) {
      const to = getTemplatesRoute(currentPageOrTemplate as TemplateExtended);

      dispatch(navigate({ to }));

      dispatch(templatesModel.actions.setCurrentTemplate(undefined));
      dispatch(templatesModel.actions.setCurrentTemplateContentId(undefined));
    } else {
      dispatch(pagesModel.actions.setIsEditorPreview(false));
      changeGleapBlockVisibility('visible');
      let to = currentPageOrTemplate
        ? generatePath(WORKSPACE_FOLDER_ROUTE, {
            sequenceNumber: currentFolder.sequenceNumber,
          })
        : WORKSPACE_ROUTE;

      if (isRedirectToGettingStartedOnBack && !isMobile && !isTablet) {
        to = GETTING_STARTED_ROUTE;
      }

      dispatch(navigate({ to, isKeepQueryParams: false }));
    }
  };

  const handlePreviewExit = () => {
    dispatch(pagesModel.actions.setIsEditorPreview(false));
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
  };

  const [isRequestToEditSent, setRequestToEditSent] = useState(false);

  const handleRequestToEdit = () => {
    setRequestToEditSent(true);
    dispatch(pagesModel.actions.requestToEditPage({}));
  };

  const handleCopyPageLink = () => {
    navigator.clipboard.writeText(window.location.href);
    dispatch(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'The link has been copied to your clipboard'
        )
      )
    );
  };

  const { isMobile } = useWindowWidth();
  const { isTablet } = useUserAgentDevices();

  const { isGuest } = useTeamPermissions();

  const { isCollaborationEnabled, published } = useCollaboration();

  const isPublished = isCollaborationEnabled
    ? published
    : (currentPageOrTemplate as Page).published;

  return (
    <div className="sticky top-0 z-50" id="top-bar">
      <header
        {...inert(isNarrationRecordingSessionActive)}
        className="flex items-center justify-between h-13 px-4 pl-3 transition-all sm:py-2 bg-base-white"
      >
        <div
          className={cn('flex justify-between items-center py-2', {
            'w-full': !isEditorPreview,
            'max-w-1/3 flex-1': isEditorPreview,
            'flex-1': !isCanEditDocumentContent,
          })}
        >
          <EditorHeaderLeftSide
            isPublished={isPublished}
            isTemplateMode={isTemplateMode}
            onBackClick={onBackClick}
            currentPageOrTemplate={currentPageOrTemplate}
          />
        </div>

        {!isTemplateMode &&
          !isEditorPreview &&
          !isGuest &&
          !isMobile &&
          !isTablet && (
            <div className="flex justify-center flex-grow h-full w-full">
              <TabsGroupTopBorder
                active={selectedHeaderTab}
                setActive={(val) => {
                  setSelectedHeaderTab(val);
                }}
                options={tabs}
                className="h-full"
              />
            </div>
          )}

        {(isTemplateMode ||
          (!isEditorPreview &&
            isCanEditDocumentContent &&
            !isMobile &&
            !isTablet)) && (
          <EditorHeaderToolbar
            isAnalyticsTab={selectedHeaderTab === HeaderTabs.ANALYTICS}
            isTemplateMode={isTemplateMode}
          />
        )}

        {(isEditorPreview || !isCanEditDocumentContent) &&
          !isMobile &&
          !isTablet &&
          !isTemplateMode && (
            <>
              {!isGuest && <PreviewBar />}
              {!isTablet && isCanEditDocumentContent && (
                <div className="flex-1 py-2">
                  <Button
                    variant="text"
                    color="secondary"
                    size="md"
                    onClick={handlePreviewExit}
                    className="ml-auto text-sm font-semibold text-gray-700"
                  >
                    Exit Preview
                  </Button>
                </div>
              )}
              {!isCanEditDocumentContent && (
                <div className="flex items-center justify-end flex-1 gap-2 py-2">
                  {!isGuest && (
                    <Button
                      variant="icon"
                      color="secondary"
                      size="sm"
                      onClick={handleCopyPageLink}
                    >
                      <Icon
                        glyph={IconMap.Link}
                        width={20}
                        className="shrink-0"
                      />
                    </Button>
                  )}
                  <Button
                    variant={isRequestToEditSent ? 'text' : 'icon-text'}
                    iconLeftName={
                      isRequestToEditSent ? undefined : IconMap.Edit02
                    }
                    iconLeftWidth={20}
                    color="secondary"
                    size="sm"
                    onClick={handleRequestToEdit}
                    className="text-sm font-semibold text-gray-700"
                    disabled={isRequestToEditSent}
                  >
                    {isRequestToEditSent ? 'Request sent' : ' Ask to edit'}
                  </Button>
                </div>
              )}
            </>
          )}

        {(isMobile || isTablet) && !isTemplateMode && (
          <div className="flex items-center gap-4 py-2 pr-4 shrink-0 sm:pr-2">
            {!isMobile && <PreviewBar />}
            <PageSettingsDropdown listStyles="mt-4" align="end" />
          </div>
        )}
      </header>
      {isNarrationRecordingSessionActive && (
        <div className="absolute inset-0 cursor-not-allowed bg-base-white/50" />
      )}
    </div>
  );
};
