import { object, string } from 'yup';
import { Button, Input } from '../../../../../../shared/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCollaboration } from '../../../../../../entities/collaboration';
import { SettingItemWrapper } from './SettingItemWrapper';

const schema = object().shape({
  title: string(),
});

type FormValues = {
  title: string;
};

export const ChangeMetaTitleForm = () => {
  const { seoConfigurationData, updateSeoConfigurationField } =
    useCollaboration();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const { register, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: seoConfigurationData.title,
    },
    mode: 'onSubmit',
  });

  const titleValue = watch('title');

  const handleFormSubmit = async (data: FormValues) => {
    updateSeoConfigurationField('title', data.title);
    setIsEditFormOpen(false);
  };

  return (
    <SettingItemWrapper
      title="Title tag"
      titleButton={
        <Button
          className="h-6 !p-0"
          color={isEditFormOpen ? 'link-destructive' : 'link'}
          variant="text"
          type="button"
          onClick={() => {
            setIsEditFormOpen((prevIsFormOpen) => !prevIsFormOpen);
            isEditFormOpen && reset();
          }}
        >
          {isEditFormOpen ? 'Cancel' : 'Edit'}
        </Button>
      }
    >
      {isEditFormOpen ? (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <p className="mb-4 text-sm text-gray-500">
            Title the page. Optimal title length is approximately 55 characters.
          </p>

          <Input
            {...register('title')}
            type="text"
            maxCharacters={55}
            isCounterShow={false}
            messageText={`${titleValue.length} characters`}
          />
          <Button color="primary" variant="text" type="submit" className="mt-4">
            Save
          </Button>
        </form>
      ) : (
        <span className="text-sm text-gray-600">
          {seoConfigurationData.title || 'Untitled'}
        </span>
      )}
    </SettingItemWrapper>
  );
};
