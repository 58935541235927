import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { Switch } from '@headlessui/react';
import {
  Button,
  Section,
  RadioButton,
  Input,
  Icon,
  Tooltip,
  Link,
} from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

import { findObjectChanges } from '@distribute/shared/utils';

import { callRecordingSettingsModel } from '../../model';
import { emailNotificationOptions, joinPreferenceOptions } from '../../config';

export const UserSettingsForm = () => {
  const dispatch = useDispatch();
  const userSettings = useSelector(
    callRecordingSettingsModel.selectors
      .selectUserCallRecordingSettingsWithError
  );
  const meetingCalendar = useSelector(
    callRecordingSettingsModel.selectors.selectMeetingCalendarWithError
  );
  const zoomCredentials = useSelector(
    callRecordingSettingsModel.selectors.selectZoomCredentialsWithError
  );
  const isCalendarConnecting = useSelector(
    callRecordingSettingsModel.selectors.selectIsCalendarConnecting
  );
  const isZoomConnecting = useSelector(
    callRecordingSettingsModel.selectors.selectIsZoomConnecting
  );

  const isUserCallSettingsLoading = useSelector(
    callRecordingSettingsModel.selectors.selectIsUserCallSettingsLoading
  );

  const [botName, setBotName] = useState(userSettings.botName);
  const [showCopilotCover, setShowCopilotCover] = useState(
    userSettings.showCopilotCover
  );
  const [joinPreference, setJoinPreference] = useState(
    userSettings.joinPreference
  );
  const [emailNotification, setEmailNotification] = useState(
    userSettings.emailNotification
  );

  const hasCalendarConnected = meetingCalendar?.status === 'connected';
  const hasCalendarConnecting = meetingCalendar?.status === 'connecting';
  const isZoomConnected = !!zoomCredentials;

  const { hasChanges: hasFormChanges, changes } = findObjectChanges(
    {
      showCopilotCover: userSettings.showCopilotCover,
      joinPreference: userSettings.joinPreference,
      emailNotification: userSettings.emailNotification,
    },
    {
      showCopilotCover,
      joinPreference,
      emailNotification,
    }
  );

  const isBotNameChanged = userSettings?.botName !== botName;

  const handleSaveSettings = () => {
    dispatch(
      callRecordingSettingsModel.actions.updateUserCallRecordingSettings(
        changes
      )
    );
  };

  const handleSaveBotName = () => {
    dispatch(
      callRecordingSettingsModel.actions.updateUserCallRecordingSettings({
        botName,
      })
    );
  };

  const toggleGoogleConnection = () => {
    if (hasCalendarConnected) {
      dispatch(callRecordingSettingsModel.actions.disconnectGoogleCalendar());
    } else {
      dispatch(callRecordingSettingsModel.actions.connectToGoogleCalendar());
    }
  };

  return (
    <Section title="User settings">
      <div className="flex flex-col gap-8 text-gray-700">
        <div className="flex gap-x-6 items-start">
          <div className="w-64">
            <div className="flex flex-col">
              <p className="text-gray-700 text-sm font-medium">
                Calendar connected
              </p>
              <p className="text-gray-500 text-xs font-normal">
                Connect to have access to all features
              </p>
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <Button
              loading={isCalendarConnecting || hasCalendarConnecting}
              variant="icon-text"
              color="secondary"
              className="flex gap-3"
              iconBlockClassName=""
              iconLeftName={IconMap.Google}
              iconLeftWidth={24}
              onClick={toggleGoogleConnection}
            >
              {hasCalendarConnected ? (
                <>
                  Google Calendar connected
                  <Tooltip
                    sideOffset={6}
                    hideArrow
                    trigger={
                      <Icon
                        className="text-error-600"
                        glyph={IconMap.LinkBroken}
                        width={20}
                      />
                    }
                  >
                    Disconnect
                  </Tooltip>
                </>
              ) : (
                <>Google Calendar</>
              )}
            </Button>
            {/*<span>or</span>*/}
            {/*<Button*/}
            {/*  variant="icon-text"*/}
            {/*  color="secondary"*/}
            {/*  className="w-full"*/}
            {/*  iconLeftName={IconMap.Microsoft}*/}
            {/*>*/}
            {/*  Microsoft Outlook*/}
            {/*</Button>*/}
          </div>
        </div>
        <div className="flex gap-x-6 items-start">
          <div className="w-64">
            <div className="flex flex-col">
              <p className="text-gray-700 text-sm font-medium">
                Video conferencing tools
              </p>
              <p className="text-gray-500 text-xs font-normal">
                Connect to have best auto-recording experience.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-6 flex-grow-1 items-start">
            <div
              className={cn('flex gap-6', {
                hidden: !hasCalendarConnected && isZoomConnected,
              })}
            >
              {hasCalendarConnected && (
                <span className="flex items-center gap-3 border border-gray-300 rounded-md bg-gray-50 py-3 h-11 text-gray-500 px-4 whitespace-nowrap">
                  <Icon glyph={IconMap.GoogleMeet} width={24} />
                  Google meet connected
                </span>
              )}
              {!isZoomConnected && (
                <Button
                  loading={isZoomConnecting}
                  variant="icon-text"
                  color="secondary"
                  className="flex gap-3"
                  iconBlockClassName=""
                  iconLeftName={IconMap.ZoomCall}
                  iconLeftWidth={24}
                  onClick={() =>
                    dispatch(callRecordingSettingsModel.actions.connectZoom())
                  }
                >
                  Connect Zoom
                </Button>
              )}
            </div>
            {isZoomConnected && (
              <>
                <hr
                  className={cn('border-t border-gray-200 w-full', {
                    hidden: !hasCalendarConnected,
                  })}
                />
                <Button
                  loading={isZoomConnecting}
                  variant="icon-text"
                  color="secondary"
                  className="flex gap-3"
                  iconBlockClassName=""
                  iconLeftName={IconMap.ZoomCall}
                  iconLeftWidth={24}
                  onClick={() =>
                    dispatch(
                      callRecordingSettingsModel.actions.disconnectZoom()
                    )
                  }
                >
                  Zoom connected
                  <Tooltip
                    sideOffset={6}
                    hideArrow
                    trigger={
                      <Icon
                        className="text-error-600"
                        glyph={IconMap.LinkBroken}
                        width={20}
                      />
                    }
                  >
                    Disconnect
                  </Tooltip>
                </Button>
                <p className="text-xs font-medium text-gray-500 lg:max-w-80">
                  To disable “Recording in progress” auto notification update
                  this option in{' '}
                  <Link size="small">
                    Zoom Settings{' '}
                    <Icon
                      glyph={IconMap.ArrowSquareUpRight}
                      width={16}
                      className="inline-block align-top"
                    />
                  </Link>
                </p>
              </>
            )}
          </div>
        </div>
        <div className="flex gap-x-6 items-start">
          <div className="w-64">
            <div className="flex flex-col">
              <p className="text-gray-700 text-sm font-medium">Bot name</p>
              <p className="text-gray-500 text-xs font-normal">
                The name your Distribute co-pilot will go by when it joins
                meetings.
              </p>
            </div>
          </div>
          <div className="flex gap-3">
            <Input
              isCounterShow
              maxCharacters={40}
              type="text"
              value={botName}
              onChange={(e) => setBotName(e.target.value)}
            />
            <Button
              variant="text"
              color="primary"
              disabled={!isBotNameChanged}
              onClick={handleSaveBotName}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="flex gap-x-6 items-start">
          <div className="w-64">
            <div className="flex flex-col">
              <p className="text-gray-700 text-sm font-medium">
                Recording Notification Cover
              </p>
              <p className="text-gray-500 text-xs font-normal">
                Your Distribute co-pilot cover that will be visible on the
                meetings.
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2 flex-shrink-0">
            <Switch.Group>
              <Switch
                checked={showCopilotCover}
                onChange={setShowCopilotCover}
                className={cn(
                  'relative inline-flex h-6 w-12 items-center rounded-full',
                  {
                    'bg-gray-200': !showCopilotCover,
                    'bg-primary-600': showCopilotCover,
                  }
                )}
              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={cn(
                    'inline-block h-4 w-4 transform rounded-full bg-base-white transition',
                    {
                      'translate-x-7': showCopilotCover,
                      'translate-x-1': !showCopilotCover,
                    }
                  )}
                />
                <span
                  className={cn(
                    'text-xs font-bold inline-block transform transition',
                    {
                      'translate-x-1.5 text-gray-400': !showCopilotCover,
                      '-translate-x-2.5 text-primary-100': showCopilotCover,
                    }
                  )}
                >
                  {showCopilotCover ? 'ON' : 'OFF'}
                </span>
              </Switch>

              <Switch.Label className="inline-flex gap-1 text-sm font-medium">
                Show
                <Tooltip
                  hideArrow
                  sideOffset={10}
                  trigger={
                    <span className="border-b border-dotted border-gray-700 cursor-pointer">
                      co-pilot cover
                    </span>
                  }
                >
                  <img
                    src="../../../../assets/images/cover-call-recordings.png"
                    alt="Recordins notes"
                  />
                </Tooltip>
                on your meetings
              </Switch.Label>
            </Switch.Group>
          </div>
        </div>
        <div className="flex gap-x-6 items-start">
          <div className="w-64">
            <div className="flex flex-col">
              <p className="text-gray-700 text-sm font-medium">
                Join preferences
              </p>
              <p className="text-gray-500 text-xs font-normal">
                Which calls do you want your Distribute co-pilot to join by
                default?
              </p>
            </div>
          </div>
          <div>
            {joinPreferenceOptions.map((option) => (
              <RadioButton
                key={option.name}
                name="join-preference"
                value={option.value}
                onChange={() => setJoinPreference(option.value)}
                label={option.label}
                defaultChecked={joinPreference === option.value}
              />
            ))}
          </div>
        </div>
        <div className="flex gap-x-6 items-start">
          <div className="w-64">
            <div className="flex flex-col">
              <p className="text-gray-700 text-sm font-medium">
                Email Notifications
              </p>
              <p className="text-gray-500 text-xs font-normal">
                Send emails that meeting is ready to review and includes link to
                dashboard.
              </p>
            </div>
          </div>
          <div>
            {emailNotificationOptions.map((option) => (
              <RadioButton
                key={option.name}
                name="email-notification"
                value={option.value}
                onChange={() => setEmailNotification(option.value)}
                label={option.label}
                defaultChecked={emailNotification === option.value}
              />
            ))}
          </div>
        </div>
        <div className="py-5">
          <Button
            loading={isUserCallSettingsLoading}
            onClick={handleSaveSettings}
            disabled={!hasFormChanges}
            variant="text"
            color="primary"
            className="text-sm"
          >
            Save
          </Button>
        </div>
      </div>
    </Section>
  );
};
