import React, { ReactNode, useCallback, useRef, useState } from 'react';

import { BubbleMenuIconButton } from '../BubbleMenuIconButton';
import { IconMap } from '../../../../shared/sprite';
import { useTiptapEditor } from '../../lib';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../../../../features/pages';
import { mergeRefs } from 'react-merge-refs';
import { useEditorDragNDrop } from './useEditorDragNDrop';
import { PAGE_COVER_ELEMENT_ID } from '../../../../pages/editor/ui/components/PageCover';
import { addButtonHoveringBlockPluginKey } from '../../extensions/HoveringBlock';
import { LineActionMenu } from '../../extensions/LineActionMenu/LineActionMenu';
import { LEFT_SIDEBAR_ID } from '../../../../features/editor-left-sidebar/ui/components/lib';
import { findScrollableParent } from './lib';

export interface Props {
  handleSelect(): void;
  className?: string;
  dragButtonTooltip?: ReactNode | string;
}

export const InsertAndDragButtons = React.forwardRef<HTMLDivElement, Props>(
  ({ className, handleSelect, dragButtonTooltip }, ref) => {
    const { editor } = useTiptapEditor();
    const dispatch = useDispatch();

    //TODO: remove isPageEditor after sync all editors
    const isPageEditor = Boolean(
      useSelector(pagesModel.selectors.selectCurrentPage)
    );

    const [nodePosition, setNodePosition] = useState<number | null>(null);

    const pageCoverElement = document.getElementById(PAGE_COVER_ELEMENT_ID);
    const leftSidebar = document.getElementById(LEFT_SIDEBAR_ID);

    const toolbarRef = useRef<HTMLDivElement | null>(null);

    const handleInsert = useCallback(() => {
      const root = document.getElementById('root');
      const toolbarRect = toolbarRef.current?.getBoundingClientRect();
      const scrollableContainer = findScrollableParent(toolbarRef.current);

      const topCorrection = 120;

      const topPosition =
        (toolbarRect?.top ?? 0) +
        (scrollableContainer?.scrollTop || 0) -
        (pageCoverElement?.clientHeight ?? 0) -
        topCorrection;
      const leftPosition = isPageEditor
        ? (toolbarRect?.left ?? 0) - (leftSidebar?.clientWidth ?? 0) + 60
        : (toolbarRect?.left ?? 0) + 80;

      dispatch(pagesModel.actions.setSlashMenuPositionTop(topPosition));

      dispatch(pagesModel.actions.setSlashMenuPositionLeft(leftPosition));

      dispatch(pagesModel.actions.setSlashMenuOpen(true));

      if (!editor) return;
      const hoveringBlockPluginState = addButtonHoveringBlockPluginKey.getState(
        editor.state
      );
      const node = hoveringBlockPluginState?.hoveredBlockNode;
      const currentPos = hoveringBlockPluginState?.hoveredBlockPosition ?? 0;
      const isEmptyNode =
        node?.type.name === 'paragraph' && node?.nodeSize === 2;

      let pos = 0;

      editor.state.doc.descendants((n, p) => {
        if (currentPos === p) {
          pos = p + n.nodeSize - 1;
        }
      });
      const resolvedPos = editor.state.doc.resolve(pos);
      const isNextNodeEmpty =
        resolvedPos.nodeAfter?.type.name === 'paragraph' &&
        resolvedPos.nodeAfter?.nodeSize === 2;

      if (!isEmptyNode && !isNextNodeEmpty) {
        editor
          .chain()
          .insertContentAt(pos + 1, { type: 'paragraph' })
          .focus()
          .run();
      } else {
        editor.commands.focus(pos);
      }

      editor.chain().focus();
    }, [dispatch, editor, pageCoverElement?.clientHeight]);

    const handleDragButtonClick = useCallback(
      (event: any) => {
        handleSelect();
        if (!editor) return;
        const hoveringBlockPluginState =
          addButtonHoveringBlockPluginKey.getState(editor.state);
        const node = hoveringBlockPluginState?.hoveredBlockNode;
        const nodePos = hoveringBlockPluginState?.hoveredBlockPosition ?? null;
        if (node) {
          setNodePosition(nodePos);
          const evt = new MouseEvent('contextmenu', { ...event });
          event.target.dispatchEvent(evt);
          // Fix for button tooltip not hiding after drag button clicked
          dragButtonRef.current?.blur();
        }
      },
      [handleSelect, editor]
    );

    const handleMenuOpenChange = useCallback((open: boolean) => {
      if (!open) {
        setNodePosition(null);
      }
    }, []);

    const dragButtonRef = useRef<HTMLButtonElement | null>(null);

    useEditorDragNDrop({ dragButtonRef });

    if (!editor) return null;

    return (
      <div
        ref={mergeRefs([toolbarRef, ref])}
        className={cn('flex md:hidden', className)}
        data-drag-handle
        id="hoveringButtons"
      >
        <BubbleMenuIconButton
          iconName={IconMap.Plus}
          onClick={handleInsert}
          tooltip="Click to add below"
          size="sm"
          className="rounded-lg w-9 h-9 hover:bg-gray-50"
          hideArrow={false}
          draggable={false}
        />
        <LineActionMenu
          editor={editor}
          nodePosition={nodePosition}
          onOpenChange={handleMenuOpenChange}
        >
          <BubbleMenuIconButton
            iconName={IconMap.Drag}
            onClick={handleDragButtonClick}
            tooltip={dragButtonTooltip ?? 'Drag to move'}
            size="sm"
            className={cn('rounded-lg w-9 h-9 hover:bg-gray-50 cursor-grab', {
              '!bg-transparent': false,
            })}
            draggable
            hideArrow={false}
            delayDuration={300}
            ref={dragButtonRef}
          />
        </LineActionMenu>
      </div>
    );
  }
);
