import { PropsWithChildren } from 'react';
import { IconMap } from '../../../../../../shared/sprite';
import { Icon } from '../../../../../../shared/ui';

export type ProTipButtonProps = {
  handleClick: () => void;
};

export const ProTipButton = ({
  handleClick,
  children,
}: PropsWithChildren<ProTipButtonProps>) => {
  return (
    <button
      className="clear-all inline-flex items-center gap-1 font-semibold text-primary-700 hover:text-primary-800 group"
      onClick={handleClick}
    >
      {children}
      <Icon
        glyph={IconMap.ArrowSquareUpRight}
        width={16}
        className="text-primary-700 group-hover:text-primary-800"
      />
    </button>
  );
};
