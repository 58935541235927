import React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '@distribute/frontend/utils';

const UiTabs = TabsPrimitive.Root;

const UiTabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn('inline-flex items-center justify-center gap-4', className)}
    {...props}
  />
));
UiTabsList.displayName = TabsPrimitive.List.displayName;

const UiTabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'inline-flex items-center justify-center whitespace-nowrap rounded-2.5 p-3 text-md font-semibold shadow-xs transition-all focus-visible:outline-none border border-gray-200 focus:border-primary-600 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:border-primary-600 group',
      className
    )}
    {...props}
  />
));
UiTabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const UiTabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content ref={ref} className={className} {...props} />
));
UiTabsContent.displayName = TabsPrimitive.Content.displayName;

export { UiTabs, UiTabsList, UiTabsTrigger, UiTabsContent };
