import React, { ReactNode } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Dropdown } from '../../../../shared/ui';

export type SelectEventType = {
  hasMultiTabs: boolean;
};

type CreateTemplateButtonProps = {
  onSelect: ({ hasMultiTabs }: SelectEventType) => void;
  children: ReactNode;
  listStyles?: string;
};

export const CreateTemplateButton: React.FC<CreateTemplateButtonProps> = ({
  onSelect,
  children,
  listStyles = '',
}) => {
  const items = [
    {
      id: 'multi',
      label: <span className="font-medium">Multi-tab template</span>,
      onClick: () => onSelect({ hasMultiTabs: true }),
    },
    {
      id: 'single',
      label: <span className="font-medium">Single-tab template</span>,
      onClick: () => onSelect({ hasMultiTabs: false }),
    },
  ];

  return (
    <Dropdown
      isModalMode={false}
      listStyles={listStyles}
      items={items}
      triggerComponent={
        <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      }
    />
  );
};
