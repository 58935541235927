import { TemplateCategory } from '@distribute/shared/types';
import { IconMap } from '../../../../../../../src/shared/sprite';

export enum TemplatesConfigBasicEnum {
  PERSONAL = 'personal',
  TEAM = 'team',
}

export type TemplatesConfigEnum = TemplatesConfigBasicEnum | TemplateCategory;
export const TemplatesConfigEnum = {
  ...TemplatesConfigBasicEnum,
  ...TemplateCategory,
};

export enum TemplatesTabEnum {
  SINGE_PAGE = 'single-page',
  PLAYBOOK = 'playbook',
}

export const templatesConfig = [
  {
    groupName: 'Your Workspace',
    items: [
      {
        name: 'Personal',
        key: TemplatesConfigEnum.PERSONAL,
      },
      {
        name: 'Team',
        key: TemplatesConfigEnum.TEAM,
      },
    ],
  },
  {
    groupName: 'Use Cases',
    isCollapsed: true,
    items: [
      {
        name: 'Internal updates',
        key: TemplatesConfigEnum.INTERNAL_UPDATES,
      },
      {
        name: 'Prospecting',
        key: TemplatesConfigEnum.PROSPECTING,
      },
      {
        name: 'In Discussion',
        key: TemplatesConfigEnum.IN_DISCUSSION,
      },
      {
        name: 'Closing',
        key: TemplatesConfigEnum.CLOSING,
      },
      {
        name: 'Implementation',
        key: TemplatesConfigEnum.IMPLEMENTATION,
      },
      {
        name: 'Customer Success',
        key: TemplatesConfigEnum.CUSTOMER_SUCCESS,
      },
      {
        name: 'Upsell & Cross-sell',
        key: TemplatesConfigEnum.UPSELL_AND_CROSS_SELL,
      },
      {
        name: 'Winback',
        key: TemplatesConfigEnum.WINBACK,
      },
    ],
  },
  {
    groupName: 'Distroverse',
    items: [
      {
        name: 'Community',
        key: TemplatesConfigEnum.COMMUNITY,
      },
    ],
  },
];

export enum SortState {
  LAST_CREATED = 'last-created',
  MOST_POPULAR = 'most-popular',
  NAME = 'name',
}

export const tabs = [
  {
    id: TemplatesTabEnum.SINGE_PAGE,
    name: 'Single-tab',
    icon: IconMap.OnePage,
  },
  {
    id: TemplatesTabEnum.PLAYBOOK,
    name: 'Multi-tab',
    icon: IconMap.MultiTabPage,
    disabled: true,
    tooltipText: 'Coming soon!',
  },
];

export const tagsByCategoryDictionary = {
  community: ['Distroverse', 'Community Templates'],
  leadGeneration: ['Use Cases', 'Lead Generation'],
  salesRooms: ['Use Cases', 'Sales Rooms'],
  caseStudies: ['Use Cases', 'Case Studies'],
  socialSelling: ['Use Cases', 'Social Selling'],
  meetingFollowUp: ['Use Cases', 'Meeting Follow-up'],
  businessCases: ['Use Cases', 'Business Case'],
  onboarding: ['Use Cases', 'Onboarding'],
  events: ['Use Cases', 'Events'],
  salesAssets: ['Use Cases', 'Sales Assets'],
  leadMagnets: ['Use Cases', 'Lead Magnets'],
  salesProposal: ['Use Cases', 'Sales Proposal'],
  mutualActionPlan: ['Use Cases', 'Mutual Action Plan'],
  competitiveComparison: ['Use Cases', 'Competitive Comparison'],
  customerTestimonials: ['Use Cases', 'Customer Testimonials'],
  pricingAndPackaging: ['Use Cases', 'Pricing & Packaging'],
  productOverview: ['Use Cases', 'Product Overview'],
  serviceAndSupport: ['Use Cases', 'Service & Support'],
  preCall: ['Use Cases', 'Pre Call'],
  postDemo: ['Use Cases', 'Post Demo'],
  internalDealReview: ['Use Cases', 'Internal Deal Review'],
  postCall: ['Use Cases', 'Post Call'],
  meetings: ['External', 'Meetings'],
  openPipeline: ['External', 'Open Pipeline'],
  postSale: ['External', 'Post-Sale'],
  customers: ['External', 'Customers'],
  coaching: ['Internal', 'Coaching'],
  bestPractices: ['Internal', 'Best Practices'],
  dealStrategy: ['Internal', 'Deal Strategy'],
  internalUpdates: ['Internal updates'],
  prospecting: ['Prospecting'],
  inDiscussion: ['In Discussion'],
  closing: ['Closing'],
  implementation: ['External', 'Implementation'],
  customerSuccess: ['Customer Success'],
  upsellAndCrossSell: ['Upsell & Cross-sell'],
  winback: ['Winback'],
};
