import { useDispatch, useSelector } from 'react-redux';
import { IconMap } from '../../../shared/sprite';
import { ButtonSquare, Icon, Tooltip } from '../../../shared/ui';
import { FC } from 'react';
import { chatModel } from '../model';

export const ChatBotHeader: FC = () => {
  const dispatch = useDispatch();
  const conversationIsActive = useSelector(
    chatModel.selectors.selectConversationIsActive
  );

  const currentChat = useSelector(chatModel.selectors.selectCurrentChat);

  const handleOpenDeleteChatModal = () => {
    dispatch(chatModel.actions.setIsChatDeleteModalOpen(true));
  };

  return (
    <div className="mb-5 flex flex-nowrap justify-between items-center">
      <h3 className="text-2xl font-semibold text-gray-900">Ask Dante</h3>
      {(currentChat?.messages?.length ?? 0) >= 1 && (
        <Tooltip
          sideOffset={4}
          hideArrow
          className="py-1.5"
          trigger={
            <ButtonSquare
              variant="tertiary-gray"
              className="w-8 h-8 rounded-lg"
              disabled={conversationIsActive}
              onClick={handleOpenDeleteChatModal}
            >
              <Icon glyph={IconMap.Trash} width={18} />
            </ButtonSquare>
          }
        >
          Delete chat
        </Tooltip>
      )}
    </div>
  );
};
