export enum SettingsTabEnum {
  GENERAL = 'general-settings',
  ADVANCED = 'advanced',
}

export const tabs = [
  { id: SettingsTabEnum.GENERAL, name: 'General' },
  {
    id: SettingsTabEnum.ADVANCED,
    name: 'Advanced',
  },
];
