import {
  ButtonExt,
  CalloutExt,
  CodeBlockExt,
  ContactCardExt,
  CustomTaskListExt,
  EmbedContentBlockExt,
  EmbedContentExt,
  FilePreviewExt,
  IframeExt,
  ImageExt,
  ResizeableFigureExt,
  SnippetBlockExt,
  TimelineExt,
  TimelineItemExt,
  ToggleListExt,
  ToggleListItemContentExt,
  ToggleListItemExt,
  VideoExt,
  VideoRecordExt,
  VimeoExt,
  YoutubeExt,
} from '@distribute/shared/generate-html';

import { mergeAttributes } from '@tiptap/core';

// CustomTaskList Extension
const PreviewCustomTaskList = CustomTaskListExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Custom task list',
    ];
  },
});

// ContactCard Extension
const PreviewContactCard = ContactCardExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Contact card',
    ];
  },
});

// SnippetBlock Extension
const PreviewSnippetBlock = SnippetBlockExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Snippet block',
    ];
  },
});

// Callout Extension
const PreviewCallout = CalloutExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Callout',
    ];
  },
});

// FilePreview Extension
const PreviewFilePreview = FilePreviewExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'File preview',
    ];
  },
});

// CustomImage Extension
const PreviewCustomImage = ImageExt.extend({
  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes, 'Image'];
  },
});

// CustomVideo Extension
const PreviewCustomVideo = VideoExt.extend({
  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes, 'Video'];
  },
});

// VideoRecord Extension
const PreviewVideoRecord = VideoRecordExt.extend({
  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes, 'Video record'];
  },
});

// ResizeableFigure Extension
const PreviewResizeableFigure = ResizeableFigureExt.extend({
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes({ class: 'custom' }, HTMLAttributes), 0];
  },
});

// Iframe Extension
const PreviewIframe = IframeExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Iframe',
    ];
  },
});

// Youtube Extension
const PreviewYoutube = YoutubeExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Youtube',
    ];
  },
});

// Vimeo Extension
const PreviewVimeo = VimeoExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Vimeo',
    ];
  },
});

// Button Extension
const PreviewButton = ButtonExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Button',
    ];
  },
});

// EmbedContentBlock Extension
const PreviewEmbedContentBlock = EmbedContentBlockExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Embed content block',
    ];
  },
});

// EmbedContent Extension
const PreviewEmbedContent = EmbedContentExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Embed content',
    ];
  },
});

// CodeBlock Extension
const PreviewCodeBlock = CodeBlockExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Code block',
    ];
  },
});

// Timeline Extension
const PreviewTimeline = TimelineExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Timeline',
    ];
  },
});

// TimelineItem Extension
const PreviewTimelineItem = TimelineItemExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Timeline item',
    ];
  },
});

// ToggleList Extension
const PreviewToggleList = ToggleListExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Toggle list',
    ];
  },
});

// ToggleListItem Extension
const PreviewToggleListItem = ToggleListItemExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Toggle list item',
    ];
  },
});

// ToggleListItemContent Extension
const PreviewToggleListItemContent = ToggleListItemContentExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'custom' }, HTMLAttributes),
      'Toggle list item content',
    ];
  },
});

export const previewExtensions = [
  PreviewCustomTaskList,
  PreviewContactCard,
  PreviewSnippetBlock,
  PreviewCallout,
  PreviewFilePreview,
  PreviewCustomImage,
  PreviewCustomVideo,
  PreviewVideoRecord,
  PreviewResizeableFigure,
  PreviewIframe,
  PreviewYoutube,
  PreviewVimeo,
  PreviewButton,
  PreviewEmbedContentBlock,
  PreviewEmbedContent,
  PreviewCodeBlock,
  PreviewTimeline,
  PreviewTimelineItem,
  PreviewToggleList,
  PreviewToggleListItem,
  PreviewToggleListItemContent,
];

export const Suggestions = {
  Outline: 'Generate an outline for this page.',
  Brainstorm: 'Brainstorm content ideas for this page.',
  Draft: 'Generate page draft',
  Feedback: 'Get feedback on my page',
  Changes:
    "Can you help me make sure my writing is clear and easy to understand? I'd love suggestions and feedback. Thanks!",
  Flag: 'Flag confusing parts',
} as const;
