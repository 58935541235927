import { FC } from 'react';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { CodeBlockNodeView } from './CodeBlock.renderer';
import { CodeBlockExt } from '@distribute/shared/generate-html';

export const CodeBlock = CodeBlockExt.extend({
  addNodeView() {
    return ReactNodeViewRenderer(CodeBlockNodeView as FC);
  },
});
