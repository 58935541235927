import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import debounce from 'debounce';
import { useSelector, useDispatch } from 'react-redux';

import '../../../../entities/tiptap-editor/styles/main-editor.css';
import { IconPicker } from '../../../../features/icon-picker';
import { Input, NewEditorsPageWrapper } from '../../../../shared/ui';
import {
  CTA,
  GatedContent as GatedContentType,
  Page,
  PopUp as PopUpType,
  TemplateCreationPhase,
  BlockingMethodType,
  AlertBar,
} from '@distribute/shared/types';
import {
  pagesModel,
  SaveAsTemplateModal,
  usePagePermissions,
} from '../../../../features/pages';
import { ActionPreviewCard } from '../../../../entities/tiptap-editor/extensions/ActionPreviewCard';
import { WarningPageModal } from '../../../../features/pages/ui/WarningPageModal';

import {
  EditorInsertBottomLine,
  TiptapEditor,
  TiptapEditorConsumer,
  useTiptapEditor,
} from '../../../../entities/tiptap-editor';
import { TiptapTextBubbleMenu } from '../../../../entities/tiptap-editor/ui/basic-editor/TiptapTextBubbleMenu';
import { useConfirmWindowClose } from '../../../../shared/hooks/useConfirmWindowClose';
import { LineActionMenuProvider } from '../../../../entities/tiptap-editor/extensions/LineActionMenu/lib/LineActionMenuContext';
import { MoveAlertBar } from '../../../../entities/tiptap-editor/ui/MoveAlertBar';

import {
  NarrationBubble,
  NarrationBubbleWrapper,
  narrationModel,
} from '../../../../features/narration';

import { conversionKitModel } from '../../../../features/conversion-kit';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { templatesModel } from '../../../../features/templates';
import { TableMenu } from '../../../../entities/tiptap-editor/extensions/Table';
import { TiptapCalloutBubbleMenu } from '../../../../entities/tiptap-editor/ui/basic-editor/TiptapCalloutBubbleMenu';
import { TiptapTimelineBubbleMenu } from '../../../../entities/tiptap-editor/ui/basic-editor/TiptapTimelineBubbleMenu';
import { TiptapContactCardBubbleMenu } from '../../../../entities/tiptap-editor/ui/basic-editor/TiptapContactCardBubbleMenu';
import { HoverInsertAndDragMenu } from '../../../../entities/tiptap-editor/ui/hovering-buttons';
import { MenuListWrapper } from '../../../../entities/tiptap-editor/extensions/TriggerMenu/MenuListWrapper';
import classNames from 'classnames';
import { aiModel } from '../../../../features/ai';
import { PageCover } from './PageCover';
import { gatedContentModel } from '../../../../features/gated-content-block';
import { EmptyPageShortcuts } from './EmptyPageShortcuts';
import { PageBrandingPicker } from '../../../../features/page-branding-picker';
import { teamsModel } from '../../../../features/teams';
import { EditorTabs, TabState } from './EditorTabs';
import { DocumentContentItem } from '@distribute/shared/types';
import { useCollaboration } from '../../../../entities/collaboration';
import { EditorContentErrorModal } from './EditorContentErrorModal';
import { PageCreationFlow } from '../../../../processes/page-creation-flow';
import {
  AlertBarComponent,
  GatedContent,
  PopUp,
  CtaBanner,
  SqueezePage,
} from './conversions';
import {
  editorLeftSidebarModel,
  LeftSidebarOptions,
} from '../../../../features/editor-left-sidebar';
import { SuggestionsControlBar } from '../../../../entities/tiptap-editor/extensions/Suggestion';
import { useEditorSuggestion } from '../../../../entities/suggestions-editor';
import { chatModel } from '../../../../features/chat';

type Props = {
  currentPageOrTemplate: Page | TemplateExtended;
  isTemplateMode: boolean;
  currentTab: DocumentContentItem;
  tabs: DocumentContentItem[];
  isSupportMultiTabs?: boolean;
};

export const TiptapEditorPage: React.FC<Props> = ({
  currentPageOrTemplate,
  isTemplateMode,
  tabs = [],
  currentTab,
  isSupportMultiTabs,
}) => {
  const dispatch = useDispatch();
  const { editor } = useTiptapEditor();
  const currentPageStatus = useSelector(
    pagesModel.selectors.selectCurrentPageStatus
  );

  const alertBar = useSelector(
    conversionKitModel.selectors.selectAlertBar
  ) as AlertBar;
  const alertBarEdited = useSelector(
    conversionKitModel.selectors.selectAlertBarEdited
  );

  const cta = useSelector(conversionKitModel.selectors.selectCTA) as CTA;
  const ctaEdited = useSelector(conversionKitModel.selectors.selectCTAEdited);
  const popUp = useSelector(
    conversionKitModel.selectors.selectPopUp
  ) as PopUpType;
  const popUpEdited = useSelector(
    conversionKitModel.selectors.selectPopUpEdited
  );
  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContent
  ) as GatedContentType;
  const gatedContentEdited = useSelector(
    conversionKitModel.selectors.selectGatedContentEdited
  );

  const generatePagePromptData = useSelector(
    pagesModel.selectors.selectGeneratePagePromptData
  );

  const pagePermissions = usePagePermissions(
    isTemplateMode ? undefined : (currentPageOrTemplate as Page)
  );

  const isPageLoading = useSelector(
    pagesModel.selectors.selectDeletePageIsLoading
  );

  const openedPageContentItemId = useSelector(
    pagesModel.selectors.selectOpenedContentId
  );

  const openedTemplateContentItemId = useSelector(
    templatesModel.selectors.selectOpenedContentId
  );

  const isTemplateUpdating = useSelector(
    templatesModel.selectors.selectTemplateIsUpdating
  );

  const openedContentItemId = isTemplateMode
    ? openedTemplateContentItemId
    : openedPageContentItemId;

  const {
    provider,
    isCollaborationEnabled,
    addTab,
    updateTab,
    setTabs,
    setTabsWithContent,
    title,
    updateTitle,
    ctaData,
  } = useCollaboration();

  const { isActive: CTAIsActive } = ctaData;

  const isNarrationRecordingSessionActive = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionActive
  );

  const hasNotEmptyConversionSettings =
    ctaEdited ||
    popUpEdited ||
    gatedContentEdited ||
    cta.isActive ||
    gatedContent.isActive ||
    popUp.isActive;

  const [pageTitle, setPageTitle] = useState(
    currentPageOrTemplate.content.title
  );
  const [
    isConfirmDeleteTabContentModalOpen,
    setIsConfirmDeleteTabContentModalOpen,
  ] = useState(false);
  const [isSavePageAsTemplateModalOpen, setIsSavePageAsTemplateModalOpen] =
    useState(false);
  const saveAsTemplateIsLoading = useSelector(
    templatesModel.selectors.selectSavePageAsTemplateIsLoading
  );

  const suggestions = useSelector(
    chatModel.selectors.selectConversationSuggestions
  );
  const { isSuggestionMode, onAccept, onReject } = useEditorSuggestion();
  const suggestionTabIds = useMemo(
    () => suggestions.map(({ id }) => Number(id)),
    [suggestions]
  );

  const tabStates = useMemo(() => {
    const statesMap: Record<number, TabState> = {};
    tabs.forEach(({ id }) => {
      if (suggestionTabIds.includes(id)) {
        statesMap[id] = TabState.HIGHLIGHTED;
      } else if (isSuggestionMode) {
        statesMap[id] = TabState.DISABLED;
      } else {
        statesMap[id] = TabState.DEFAULT;
      }
    });
    return statesMap;
  }, [suggestionTabIds, tabs, isSuggestionMode]);

  const setOpenedContentItemId = useCallback(
    (id: number | undefined) => {
      if (isTemplateMode) {
        dispatch(templatesModel.actions.setOpenedContentItemId(id));
      } else {
        dispatch(pagesModel.actions.setOpenedContentItemId(id));
      }
    },
    [dispatch, isTemplateMode]
  );

  const handleOpenTemplatesModal = useCallback(
    () =>
      dispatch(
        editorLeftSidebarModel.actions.setSelectedOption(
          LeftSidebarOptions.TEMPLATES
        )
      ),
    [dispatch]
  );

  const handleCreateNewTabContent = useCallback(
    (callback: (err?: unknown) => void) => {
      if (isTemplateMode) {
        dispatch(templatesModel.actions.addContentItem());
        callback?.();
      } else {
        dispatch(
          pagesModel.actions.createPageContentItem({
            pageId: currentPageOrTemplate.id as string,
            callback: (err, newTab) => {
              callback();
              if (newTab && isCollaborationEnabled) {
                addTab(newTab);
              }
            },
          })
        );
      }
    },
    [
      dispatch,
      currentPageOrTemplate.id,
      isCollaborationEnabled,
      addTab,
      isTemplateMode,
    ]
  );

  const handleDuplicateTabContent = useCallback(
    (id: number, callback?: (err?: unknown) => void) => {
      if (isTemplateMode) {
        dispatch(templatesModel.actions.duplicateContentItem(id));
        callback?.();
      } else {
        dispatch(
          pagesModel.actions.duplicatePageContentItem({
            pageId: currentPageOrTemplate.id as string,
            contentItemId: id,
            updateContentItems: (contentItems: DocumentContentItem[]) => {
              if (isCollaborationEnabled && !isTemplateMode) {
                setTabsWithContent(contentItems);
              }
            },
            callback,
          })
        );
      }
    },
    [
      dispatch,
      currentPageOrTemplate.id,
      setTabsWithContent,
      isCollaborationEnabled,
      isTemplateMode,
    ]
  );

  const handleChangeTabContent = useCallback(
    (tabContent: DocumentContentItem, isOpen?: boolean) => {
      if (isTemplateMode) {
        dispatch(
          templatesModel.actions.setCurrentTemplateContentId(tabContent.id)
        );
      } else {
        dispatch(
          pagesModel.actions.setCurrentContentItem({ contentItem: tabContent })
        );
        if (isCollaborationEnabled && !isTemplateMode) {
          provider?.awareness.setLocalStateField('activeTabId', tabContent.id);
        }
      }
      if (isOpen) {
        setOpenedContentItemId(tabContent.id);
      }
    },
    [
      dispatch,
      setOpenedContentItemId,
      isCollaborationEnabled,
      isTemplateMode,
      provider?.awareness,
    ]
  );

  const handleOpenCloseTabsDropdown = useCallback(
    (isOpen: boolean) => {
      if (!isOpen && openedContentItemId) {
        setOpenedContentItemId(undefined);
      }
    },
    [openedContentItemId, setOpenedContentItemId]
  );

  const handleConfirmDeleteTabContent = useCallback(() => {
    const currentTabIndex = tabs.findIndex((tab) => tab.id === currentTab.id);
    const nextIndex = currentTabIndex === 0 ? 1 : 0;

    if (isTemplateMode) {
      dispatch(templatesModel.actions.deleteContentItem(currentTab.id));
      handleChangeTabContent(tabs[nextIndex]);
      setIsConfirmDeleteTabContentModalOpen(false);
    } else {
      dispatch(
        pagesModel.actions.deletePageContentItem({
          pageId: currentPageOrTemplate.id as string,
          contentItemId: currentTab.id,
          updateContentItems(contentItems) {
            if (isCollaborationEnabled && !isTemplateMode) {
              setTabsWithContent(contentItems);
            }
          },
          callback: (err?: unknown) => {
            if (!err) {
              handleChangeTabContent(tabs[nextIndex]);
            }
            setIsConfirmDeleteTabContentModalOpen(false);
          },
        })
      );
    }
  }, [
    tabs,
    dispatch,
    currentPageOrTemplate.id,
    currentTab.id,
    handleChangeTabContent,
    setTabsWithContent,
    isTemplateMode,
    isCollaborationEnabled,
  ]);

  const handleDeleteTabContent = useCallback(() => {
    setIsConfirmDeleteTabContentModalOpen(true);
  }, []);

  const handleSetTemplateContent = useCallback(() => {
    dispatch(
      editorLeftSidebarModel.actions.setSelectedOption(
        LeftSidebarOptions.TEMPLATES
      )
    );
  }, [dispatch]);

  const handleSaveAsTemplate = useCallback(() => {
    setIsSavePageAsTemplateModalOpen(true);
  }, []);

  const handleUpdateTabContent = useCallback(
    (
      tabContent: Partial<DocumentContentItem>,
      cb?: (err?: unknown) => void
    ) => {
      if (isTemplateMode) {
        dispatch(
          templatesModel.actions.updateTemplateContentItem({
            contentItemId: currentTab.id,
            data: tabContent,
            cb,
          })
        );
      } else if (isCollaborationEnabled) {
        updateTab(currentTab.id, tabContent);
      } else {
        dispatch(
          pagesModel.actions.updatePageContentItem({
            pageId: currentPageOrTemplate.id as string,
            contentItemId: currentTab.id,
            data: tabContent,
            callback: cb,
          })
        );
      }
    },
    [
      updateTab,
      currentTab.id,
      currentPageOrTemplate.id,
      dispatch,
      isCollaborationEnabled,
      isTemplateMode,
    ]
  );

  const handleUpdateTabsOrder = useCallback(
    (data: Partial<DocumentContentItem>[], cb?: (err?: unknown) => void) => {
      if (isTemplateMode) {
        dispatch(
          templatesModel.actions.updateTemplateContentItemOrder({
            data,
            callback: cb,
          })
        );
      } else if (isCollaborationEnabled) {
        setTabs(data.map(({ contentJson, ...rest }) => rest));
      } else {
        dispatch(
          pagesModel.actions.bulkUpdatePageContentItems({
            pageId: currentPageOrTemplate.id as string,
            data,
            callback: cb,
          })
        );
      }
    },
    [
      setTabs,
      isCollaborationEnabled,
      currentPageOrTemplate.id,
      dispatch,
      isTemplateMode,
    ]
  );

  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  );

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const onChangePageTitleInput = (value: string) => {
    setPageTitle(value);
    handleChangePageTitle(value);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangePageTitle = useCallback(
    debounce((title: string) => {
      if (isTemplateMode) {
        dispatch(
          templatesModel.actions.setCurrentTemplate({
            ...(currentPageOrTemplate as TemplateExtended),
            content: { ...currentPageOrTemplate.content, title },
          })
        );

        if (currentTemplate) {
          if (currentTemplate.creationPhase === TemplateCreationPhase.DRAFT) {
            dispatch(templatesModel.actions.updateTemplateFromEditor());
          } else {
            dispatch(templatesModel.actions.setIsTemplateSavedInEditor(false));
          }
        }
      } else {
        dispatch(
          pagesModel.actions.renamePage({
            title: title,
            pageId: (currentPageOrTemplate as Page).id,
          })
        );
      }
    }, 400),
    [currentPageOrTemplate.id, currentPageOrTemplate]
  );

  useConfirmWindowClose(
    isTemplateMode
      ? false
      : currentPageStatus === 'pending' || currentPageStatus === 'error',
    'changeEditorPage'
  );

  const isEditorSidebarOpen = useSelector(
    editorSidebarModel.selectors.selectIsSidebarOpen
  );

  const isAIInputRendered = useSelector(
    aiModel.selectors.selectIsAIInputRendered
  );
  const isGatedContentShows = useSelector(
    gatedContentModel.selectors.selectGatedContentIsModalOpen
  );

  const shouldFocusSlugInput = useSelector(
    editorSidebarModel.selectors.selectShouldFocusSlugInput
  );

  const titleInputRef = useRef<HTMLInputElement>(null);
  const emptyPageShortcutsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (shouldFocusSlugInput) {
      return;
    }

    if (!pageTitle) {
      titleInputRef.current?.focus();
    }
  }, [editor]);

  const isGeneratingMultiTabs = useSelector(
    aiModel.selectors.selectIsGeneratingMultiTabs
  );

  // Open AI writing for Create Page With AI
  useEffect(() => {
    if (generatePagePromptData && editor?.commands) {
      editor?.commands.focus('end');

      setTimeout(() => {
        dispatch(aiModel.actions.setIsStructuredResponse(true));
        if (generatePagePromptData.isSeparateTabs) {
          dispatch(aiModel.actions.setIsGeneratingMultiTabs(true));
        } else {
          editor.commands.renderAI();
        }

        dispatch(
          aiModel.actions.generatePageWithAI({
            ...generatePagePromptData,
            cb: () => {
              setTimeout(() => {
                dispatch(aiModel.actions.clearFlow());
              }, 1000);
            },
          })
        );
        dispatch(pagesModel.actions.setGeneratePagePromptData(null));
      }, 0);
    }
  }, [currentTeam.id, dispatch, editor, generatePagePromptData]);

  // For multi tabs generation we need to call renderAI each time we switch tabs,
  // because editor changes and previous AI writing modal disappears
  useEffect(() => {
    if (isGeneratingMultiTabs && editor) {
      setTimeout(() => {
        editor.commands.renderAI();
      }, 0);
    }
  }, [editor, isGeneratingMultiTabs]);

  useEffect(() => {
    return () => {
      dispatch(templatesModel.actions.setIsTemplateSavedInEditor(true));
    };
  }, [dispatch]);

  const isAIWriting = useSelector(aiModel.selectors.selectIsLoading);

  const isPageBranded =
    !isTemplateMode && (currentPageOrTemplate as Page).isBranded;

  const isSoloMode = isTemplateMode || !isCollaborationEnabled;

  return (
    <>
      <NewEditorsPageWrapper>
        {(popUp || popUpEdited) && (
          <PopUp
            popUp={popUpEdited ?? popUp}
            pagePermissions={pagePermissions}
          />
        )}
        <PageCreationFlow />
        <EditorContentErrorModal />
        <TiptapTextBubbleMenu />
        <TableMenu />
        <TiptapCalloutBubbleMenu />
        <TiptapTimelineBubbleMenu />
        <LineActionMenuProvider
          // menuItems={menuItems} // Temporary hide until it will be fixed in collaboration scope
          isTemplateMode={isTemplateMode}
        >
          <HoverInsertAndDragMenu />
        </LineActionMenuProvider>
        <TiptapContactCardBubbleMenu />
        {(alertBarEdited || alertBar) && (
          <AlertBarComponent
            pagePermissions={pagePermissions}
            isTemplateMode={isTemplateMode}
            alertBar={alertBarEdited ?? alertBar}
          />
        )}

        <PageCover isTemplateMode={isTemplateMode} />

        <MenuListWrapper />
        <ActionPreviewCard />
        <div
          className={classNames('flex justify-center flex-grow', {
            '-mt-8': isPageBranded,
            '-mt-6': !isPageBranded,
          })}
        >
          <div
            className={classNames(
              'w-full flex flex-nowrap max-w-212 md:px-8 sm:px-4',
              {
                'pb-[600px]':
                  isTemplateMode ||
                  (isAIInputRendered &&
                    !(isSoloMode ? cta?.isActive : CTAIsActive)),
                'pb-[max(30vh,180px)]':
                  !isTemplateMode &&
                  (!isAIInputRendered ||
                    (isSoloMode ? cta?.isActive : CTAIsActive)),
                '!pb-0': isGatedContentShows,
                'xl:pl-20 xl:pr-5': isEditorSidebarOpen,
              }
            )}
          >
            <div className="flex flex-col w-full gap-6">
              {isPageBranded ? (
                <PageBrandingPicker
                  distributeTeamLogo={currentTeam.brandLogoUrl}
                  companyLogo={
                    (currentPageOrTemplate as Page).content.brandLogo
                  }
                  isReadonly={false}
                  pageId={(currentPageOrTemplate as Page).id}
                />
              ) : (
                <IconPicker
                  currentPage={currentPageOrTemplate}
                  isTemplateEditor={isTemplateMode}
                />
              )}
              <TiptapEditorConsumer>
                {({ editor }) => (
                  <Input
                    ref={titleInputRef}
                    className="!text-display-lg font-heading !leading-tight md:text-display-md font-bold focus:shadow-transparent placeholder:text-gray-400 border-none overflow-hidden h-fit-content !p-0"
                    textAreaRows={1}
                    value={isSoloMode ? pageTitle : title}
                    isTextArea
                    placeholder="Untitled"
                    maxCharacters={100}
                    isCounterShow={false}
                    onChange={({ target: { value } }) =>
                      isSoloMode
                        ? onChangePageTitleInput(value)
                        : updateTitle(value)
                    }
                    type="text"
                    onKeyDown={(e) => {
                      if (e.key === 'ArrowDown' && !pageTitle) {
                        emptyPageShortcutsRef.current?.focus();
                      }

                      if (e.code === 'Enter') {
                        e.preventDefault();
                        if (editor) {
                          editor
                            .chain()
                            .focus()
                            .setTextSelection(0)
                            .insertContentAt(
                              0,
                              { type: 'paragraph', content: [] },
                              { updateSelection: true }
                            )
                            .run();
                        }
                      }
                    }}
                  />
                )}
              </TiptapEditorConsumer>
              <div>
                {isSupportMultiTabs ? (
                  <EditorTabs
                    isReadOnly={
                      isNarrationRecordingSessionActive ||
                      isSuggestionMode ||
                      isTemplateUpdating
                    }
                    currentTab={currentTab}
                    tabs={tabs}
                    tabStates={tabStates}
                    openedTabId={openedContentItemId}
                    onCreateNewTab={handleCreateNewTabContent}
                    onChangeTab={handleChangeTabContent}
                    onTabUpdate={handleUpdateTabContent}
                    onUpdateOrder={handleUpdateTabsOrder}
                    onTabDelete={handleDeleteTabContent}
                    onTabDuplicate={handleDuplicateTabContent}
                    onLoadFromTemplate={handleSetTemplateContent}
                    onSaveAsTemplate={handleSaveAsTemplate}
                    onToggleDropdown={handleOpenCloseTabsDropdown}
                    isTemplateMode={isTemplateMode}
                  />
                ) : null}
                <div className="">
                  {!isAIWriting &&
                    !generatePagePromptData &&
                    !hasNotEmptyConversionSettings && (
                      <EmptyPageShortcuts
                        ref={emptyPageShortcutsRef}
                        onOpenTemplatesModal={handleOpenTemplatesModal}
                        isTemplateMode={isTemplateMode}
                      />
                    )}
                  <TiptapEditor className="main-editor-wrapper" />
                  <EditorInsertBottomLine />
                </div>
              </div>
            </div>
          </div>
        </div>
        {(ctaEdited || cta) &&
          !isTemplateMode &&
          isCollaborationEnabled &&
          (!isGatedContentShows ||
            gatedContentEdited?.blockingMethod !==
              BlockingMethodType.OBSCURATION) && (
            <CtaBanner pagePermissions={pagePermissions} />
          )}
        {(gatedContentEdited || gatedContent) &&
          isGatedContentShows &&
          !isSoloMode && (
            <GatedContent gatedContent={gatedContentEdited ?? gatedContent} />
          )}
        <SqueezePage />
        <NarrationBubbleWrapper isNewEditor>
          <NarrationBubble />
        </NarrationBubbleWrapper>
        <MoveAlertBar />

        {isSuggestionMode && suggestionTabIds.includes(currentTab.id) && (
          <div className="flex justify-center sticky bottom-6 z-50">
            <SuggestionsControlBar
              onAccept={onAccept}
              onReject={onReject}
              currentTabId={currentTab.id}
            />
          </div>
        )}
      </NewEditorsPageWrapper>
      <WarningPageModal
        title="Delete page content?"
        description="Deleting this tab will permanently erase all of its content."
        buttonText="Delete"
        isLoading={isPageLoading}
        onConfirm={handleConfirmDeleteTabContent}
        isOpen={isConfirmDeleteTabContentModalOpen}
        onClose={() => setIsConfirmDeleteTabContentModalOpen(false)}
      />
      <SaveAsTemplateModal
        isSinglePageTemplate
        name={currentTab?.name}
        isOpen={isSavePageAsTemplateModalOpen}
        onClose={() => setIsSavePageAsTemplateModalOpen(false)}
        page={currentPageOrTemplate as Page}
        isLoading={saveAsTemplateIsLoading}
        contentItem={currentTab}
      />
    </>
  );
};
