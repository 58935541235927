import { cn } from '@distribute/frontend/utils';
import { IconMap } from '../../../../../src/shared/sprite';
import { Button, Icon, Tag } from '../../../../../src/shared/ui';
import { FC } from 'react';
import { TemplateStatusTag } from './TemplateStatusTag';
import { Page } from '@distribute/shared/types';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { useCollaboration } from '../../../../../src/entities/collaboration';

type Props = {
  isTemplateMode?: boolean;
  isPublished?: boolean;
  onBackClick: () => void;
  currentPageOrTemplate: Page | TemplateExtended;
};

export const EditorHeaderLeftSide: FC<Props> = ({
  isTemplateMode,
  onBackClick,
  isPublished,
}) => {
  const { title, updateTitle } = useCollaboration();

  return (
    <>
      <div
        className={cn('pr-2', {
          'border-r border-gray-200': isTemplateMode,
        })}
      >
        {isTemplateMode ? (
          <Button
            className="md:pl-2"
            size="sm"
            color="tertiary-black"
            variant="icon-text"
            onClick={onBackClick}
          >
            <span className="flex items-center gap-2">
              <Icon glyph={IconMap.ChevronLeft} width={20} />
              <span className="truncate text-s max-w-50 md:hidden">Back</span>
            </span>
          </Button>
        ) : (
          <Button
            className="md:pl-2"
            size="sm"
            color="tertiary-black"
            variant="icon"
            onClick={onBackClick}
          >
            <span className="flex items-center gap-2">
              <Icon glyph={IconMap.ChevronLeft} width={20} />
            </span>
          </Button>
        )}
      </div>
      {!isTemplateMode && (
        <>
          <div className="w-0.25 h-5.5 bg-gray-200" />
          <input
            size={title?.length > 8 ? title?.length - 3 : 8}
            placeholder="Untitled"
            value={title}
            onChange={(e) => {
              updateTitle(e.target.value);
            }}
            className="p-2 ml-1 text-sm text-gray-700 outline-none focus:border focus:rounded-md focus:border-gray-300 focus:shadow-xs placeholder:text-gray-500 max-w-76"
          />
        </>
      )}
      <div className="flex items-center flex-1 min-w-0 gap-2 pl-3">
        {!isTemplateMode && isPublished && (
          <Tag
            color="green"
            className="!py-0.25 !px-2.5 rounded-full font-medium border border-success-200"
          >
            Public
          </Tag>
        )}
        {isTemplateMode && <TemplateStatusTag />}
      </div>
    </>
  );
};
