import React from 'react';

import { CallSummaryGeneralContent } from '@distribute/shared/types';
import { RenderList } from '../../../shared/ui';
import { Section, BulletList, ActionItem } from './CallSummaryComponents';

export const CallSummaryGeneral: React.FC<{
  content: CallSummaryGeneralContent;
}> = ({ content }) => {
  return (
    <div className="flex flex-col gap-6 text-gray-900 text-md">
      <Section title="Meeting Purpose">
        <p>{content.meetingPurpose}</p>
      </Section>
      <RenderList list={content.takeaways}>
        <Section title="Key Takeaways">
          <BulletList
            items={content.takeaways}
            renderItem={(takeaway) => `${takeaway.text}.`}
          />
        </Section>
      </RenderList>
      <RenderList list={content.topics}>
        <Section title="Topics">
          <div className="flex flex-col gap-4">
            {content.topics.map((topic, idx) => (
              <div className="flex flex-col gap-2" key={idx}>
                <h3 className="font-bold text-xl">{topic.subject}</h3>
                <BulletList
                  items={topic.items}
                  renderItem={(item) => (
                    <>
                      <span className="font-bold">{`${item.text}: `}</span>
                      <span>{item.details}.</span>
                    </>
                  )}
                />
              </div>
            ))}
          </div>
        </Section>
      </RenderList>
      <RenderList list={content.nextSteps}>
        <Section title="Next Steps">
          <BulletList
            items={content.nextSteps}
            renderItem={(item) => <ActionItem item={item} />}
          />
        </Section>
      </RenderList>
      <RenderList list={content.actionItems ?? []}>
        <Section title="Action Items">
          <BulletList
            items={content.actionItems ?? []}
            renderItem={(item) => <ActionItem item={item} />}
          />
        </Section>
      </RenderList>
    </div>
  );
};
