import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { templatesModel } from '../../model';
import { TemplatesConfigEnum } from '../../lib';
import { SearchInput } from '../../../../shared/ui';

export const TemplatesSearch: React.FC = () => {
  const dispatch = useDispatch();

  const searchQuery = useSelector(templatesModel.selectors.selectSearchQuery);
  const currentFolder = useSelector(
    templatesModel.selectors.selectCurrentTemplatesFolder
  );

  const handleSearchQueryChange = (value: string) => {
    dispatch(templatesModel.actions.setSearchQuery(value));
    if (currentFolder !== TemplatesConfigEnum.ALL) {
      dispatch(
        templatesModel.actions.setCurrentTemplatesFolder(
          TemplatesConfigEnum.ALL
        )
      );
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.blur();
  }, []);

  return (
    <SearchInput
      value={searchQuery}
      onChange={handleSearchQueryChange}
      placeholder="Search templates"
    />
  );
};
