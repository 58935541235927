import { call, put } from 'redux-saga/effects';
import { fileParsingApi } from '../../../../shared/api';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { pageCreationFlowModel } from '..';

export function* parseFileTextContent({
  payload: { file, cb },
}: ReturnType<typeof pageCreationFlowModel.actions.parseFileTextContent>) {
  try {
    const text: string = yield call(fileParsingApi.parseFileTextContent, file);
    cb(text);
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to parse file ${file.name}`)
      )
    );
    cb(null);
  }
}
