import { AIErrorType } from '@distribute/shared/api-types/ai';
import { AIResponseError } from '../model/types';

const messagesDictionary = {
  [AIErrorType.MODERATION_FAILED]:
    "Sorry, your request didn't pass our moderation. AI is committed to promoting a safe, respectful environment for all users. Please revise your request.",
  [AIErrorType.TOO_LARGE_REQUEST]: 'AI request is too large',
  [AIErrorType.UNKNOWN_ERROR]: 'Failed to request AI',
} as const;

export class AIError extends Error implements AIResponseError {
  public readonly type: AIErrorType;

  constructor(type: string) {
    super();

    this.message =
      messagesDictionary[type as AIErrorType] ??
      messagesDictionary.UNKNOWN_ERROR;

    this.type = messagesDictionary[type as AIErrorType]
      ? (type as AIErrorType)
      : AIErrorType.UNKNOWN_ERROR;
  }
}
