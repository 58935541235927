import { FC } from 'react';
import { useSelector } from 'react-redux';
import { chatModel } from '../model';
import { ChatBotMessageRequest } from './ChatBotMessageRequest';
import { ChatBotMessageResponse } from './ChatBotMessageResponse';
import { ChatBotMessageLoading } from './ChatBotMessageLoading';
import { ConversationState } from '../model/types';

export const ChatBotConversation: FC = () => {
  const conversation = useSelector(
    chatModel.selectors.selectConversationWithError
  );
  const conversationState = useSelector(
    chatModel.selectors.selectConversationState
  );

  return (
    <>
      <ChatBotMessageRequest text={conversation.request.prompt} />
      {conversation.response === '' ? (
        <ChatBotMessageLoading />
      ) : (
        <ChatBotMessageResponse
          key={conversation.id}
          id={conversation.id}
          items={conversation.structuredResponse}
          loading={conversationState === ConversationState.Streaming}
        />
      )}
    </>
  );
};
