import classnames from 'classnames';

export enum Theme {
  DEFAULT = 'default',
  CHAT = 'chat',
}

type SkeletonItemProps = {
  className?: string;
  theme?: Theme;
  width?: string;
  height?: string;
};

export const SkeletonItem = ({
  className = '',
  width = 'auto',
  height = 'auto',
  theme = Theme.DEFAULT,
}: SkeletonItemProps) => {
  return (
    <div
      style={{ width, height }}
      className={classnames(['skeleton-item', `${theme}`, className])}
    />
  );
};
