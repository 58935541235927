import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';

import {
  GetCallRecorderSettingsResponse,
  UpdateTeamCallSettingsRequest,
  UpdateUserCallSettingsRequest,
} from '@distribute/shared/api-types/call-recorder';

type State = {
  teamSettings?: GetCallRecorderSettingsResponse['teamSettings'];
  userSettings?: GetCallRecorderSettingsResponse['userSettings'];
  meetingCalendar?: GetCallRecorderSettingsResponse['meetingCalendar'];
  zoomCredentials?: GetCallRecorderSettingsResponse['zoomCredentials'];
  isUserSettingsLoading: boolean;
  isTeamSettingsLoading: boolean;
  isCalendarConnecting: boolean;
  isZoomConnecting: boolean;
  isMismatchModalOpen: boolean;
};

const initialState: State = {
  teamSettings: undefined,
  userSettings: undefined,
  meetingCalendar: undefined,
  zoomCredentials: undefined,
  isUserSettingsLoading: false,
  isTeamSettingsLoading: false,
  isCalendarConnecting: false,
  isZoomConnecting: false,
  isMismatchModalOpen: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'callRecorderSettings',
  initialState,
  reducers: {
    setTeamSettings(
      state,
      action: PayloadAction<GetCallRecorderSettingsResponse['teamSettings']>
    ) {
      state.teamSettings = action.payload;
    },
    setUserSettings(
      state,
      action: PayloadAction<GetCallRecorderSettingsResponse['userSettings']>
    ) {
      state.userSettings = action.payload;
    },
    setIsUserSettingsLoading(state, action: PayloadAction<boolean>) {
      state.isUserSettingsLoading = action.payload;
    },
    setIsTeamSettingsLoading(state, action: PayloadAction<boolean>) {
      state.isTeamSettingsLoading = action.payload;
    },
    setMeetingCalendar(
      state,
      action: PayloadAction<GetCallRecorderSettingsResponse['meetingCalendar']>
    ) {
      state.meetingCalendar = action.payload;
    },
    setZoomCredentials(
      state,
      action: PayloadAction<GetCallRecorderSettingsResponse['zoomCredentials']>
    ) {
      state.zoomCredentials = action.payload;
    },
    setIsCalendarConnecting(state, action: PayloadAction<boolean>) {
      state.isCalendarConnecting = action.payload;
    },
    setIsZoomConnecting(state, action: PayloadAction<boolean>) {
      state.isZoomConnecting = action.payload;
    },
    setIsMismatchModalOpen(state, action: PayloadAction<boolean>) {
      state.isMismatchModalOpen = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  updateTeamCallRecordingSettings: createAction<UpdateTeamCallSettingsRequest>(
    'callRecorderSettings/updateTeamCallRecordingSettings'
  ),
  updateUserCallRecordingSettings: createAction<UpdateUserCallSettingsRequest>(
    'callRecorderSettings/updateUserCallRecordingSettings'
  ),
  connectToGoogleCalendar: createAction(
    'callRecorderSettings/connectToGoogleCalendar'
  ),
  disconnectGoogleCalendar: createAction(
    'callRecorderSettings/disconnectGoogleCalendar'
  ),
  connectZoom: createAction('callRecorderSettings/connectZoom'),
  disconnectZoom: createAction('callRecorderSettings/disconnectZoom'),
  connectToMsCalendar: createAction('callRecorderSettings/connectToMsCalendar'),
  disconnectMsCalendar: createAction(
    'callRecorderSettings/disconnectMsCalendar'
  ),
  tryToReconnect: createAction('callRecorderSettings/tryToReconnect'),
};
