import { useDispatch } from 'react-redux';
import { IconMap } from '../../../shared/sprite';
import { ButtonSquare, Icon } from '../../../shared/ui';
import { chatModel } from '../model';

export const ChatBotCancelStreamBlock = () => {
  const dispatch = useDispatch();

  const handleStop = () => {
    dispatch(chatModel.actions.stopGenerateMessage());
  };

  return (
    <div className="p-3 relative flex flex-col bg-gray-50 border border-gray-300 rounded-xl">
      <div className="absolute -top-9 left-1/2 -translate-x-1/2 flex flex-none items-center gap-x-1 z-10 rounded-full border border-gray-200 py-0.5 px-1.5 bg-base-white">
        <img
          src="../../../assets/images/loading-ai.png"
          alt="Chat bot loading"
          className="spin flex-shrink-0 w-2 h-2"
        />
        <span className="text-xs font-medium">Running</span>
        <button
          type="button"
          onClick={handleStop}
          className="w-4 h-4 flex items-center justify-center shrink-0 focus:outline-none text-gray-400 hover:text-gray-500"
        >
          <Icon width={12} glyph={IconMap.CrossClose} />
        </button>
      </div>
      <ButtonSquare
        variant="secondary-gray"
        className="w-8 h-8 rounded-md group hover:bg-base-white self-end"
        onClick={handleStop}
      >
        <Icon
          width={24}
          glyph={IconMap.Stop}
          className="text-error-500 group-hover:text-error-600"
        />
      </ButtonSquare>
    </div>
  );
};
