export enum CallSummaryType {
  GENERAL = 'general',
  SALES = 'sales',
  SALES_MEDDPICC = 'sales-meddpicc',
  SALES_BANT = 'sales-bant',
  SALES_SANDLER = 'sales-sanlder',
  QA = 'qa',
  DEMO = 'demo',
  CUSTOMER_SUCCESS = 'customer-success',
}
export enum CallSummaryStatus {
  PREPARING = 'preparing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

type BaseCallSummary = {
  id: string;
  callId: string;
  createdAt: Date;
  updatedAt: Date;
};

export type CallSummaryGeneralContent = {
  meetingPurpose: string;
  takeaways: { text: string; timestamp: number | null }[];
  topics: {
    subject: string;
    items: {
      text: string;
      details: string;
      timestamp: number | null;
    }[];
  }[];
  nextSteps: {
    text: string;
    assignedTo: string | null;
    timeline: string;
    timestamp: number | null;
  }[];
  actionItems: {
    text: string;
    assignedTo: string | null;
    timeline: string;
  }[];
};

export type CallSummarySalesContent = {
  prospect: {
    company: string;
    contacts: string;
    description: string;
  };
  callContext: string;
  seats: string;
  budget: string;
  currentState: string;
  painPoints: string[];
  specificRequirements: string[];
  techStack: string[];
  objections: {
    objection: string;
    status: string;
    resolution: string;
  }[];
  otherSolutions: string[];
  timeline: string;
  decisionMaker: {
    primary: string;
    otherStakeholders: string;
  };
  procurementProcess: string;
  nextSteps: string[];
  questionsWeAsked: {
    question: string;
    response: string;
  }[];
  questionsTheyAsked: {
    question: string;
    response: string;
  }[];
  actionItems: {
    text: string;
    assignedTo: string | null;
    timeline: string;
  }[];
};

export type CallSummarySalesMeddpiccContent = object;
export type CallSummarySalesBantContent = object;
export type CallSummarySalesSandlerContent = object;
export type CallSummarySalesQAContent = object;
export type CallSummarySalesDemoContent = object;
export type CallSummarySalesCustomerSuccessContent = object;

export type CallSummaryContent =
  | CallSummaryGeneralContent
  | CallSummarySalesContent
  | CallSummarySalesMeddpiccContent
  | CallSummarySalesBantContent
  | CallSummarySalesSandlerContent
  | CallSummarySalesQAContent
  | CallSummarySalesDemoContent
  | CallSummarySalesCustomerSuccessContent;

export type _CompletedCallSummary<
  T extends CallSummaryType,
  C
> = BaseCallSummary & {
  type: T;
  status: CallSummaryStatus.COMPLETED;
  content: C;
};

export type CallSummary = BaseCallSummary & {
  type: CallSummaryType;
  status: CallSummaryStatus;
  content: CallSummaryContent | Record<string, never>;
};

//prettier-ignore
export type CompletedCallSummary =
  | _CompletedCallSummary<CallSummaryType.GENERAL, CallSummaryGeneralContent>
  | _CompletedCallSummary<CallSummaryType.SALES, CallSummarySalesContent>
  | _CompletedCallSummary<CallSummaryType.SALES_MEDDPICC, CallSummarySalesMeddpiccContent>
  | _CompletedCallSummary<CallSummaryType.SALES_BANT, CallSummarySalesBantContent>
  | _CompletedCallSummary<CallSummaryType.SALES_SANDLER, CallSummarySalesSandlerContent>
  | _CompletedCallSummary<CallSummaryType.QA, CallSummarySalesQAContent>
  | _CompletedCallSummary<CallSummaryType.DEMO, CallSummarySalesDemoContent>
  | _CompletedCallSummary<CallSummaryType.CUSTOMER_SUCCESS, CallSummarySalesCustomerSuccessContent>;
