import { pagesModel, usePagePermissions } from '../../../../features/pages';
import { IconMap } from '../../../../shared/sprite';
import { Button, Icon, Tooltip } from '../../../../shared/ui';
import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { PageSettingsDropdown } from '../components/PageSettingsDropdown';
import { SharePageModal } from './share-page-modal/SharePageModal';
import { teamsModel, useTeamPermissions } from '../../../../features/teams';
import { useCollaboration } from '../../../../entities/collaboration';

import { narrationModel } from '../../../../features/narration';
import { FeatureFlag, FeatureFlags } from '../../../../entities/feature-flag';
import { subscribePopupModel } from '../../../../features/subscribe-popup';
import { gatedContentModel } from '../../../../features/gated-content-block';
import { squeezePageModel } from '../../../../features/squeeze-page';

export const PUBLISH_BUTTON_ID = 'publish-button-id';

type Props = {
  isAnalyticsTab?: boolean;
};

export const PagePublishingHeaderControls: React.FC<Props> = ({
  isAnalyticsTab,
}) => {
  const dispatch = useDispatch();

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const popupIsOpen = useSelector(narrationModel.selectors.selectPopupOpen);

  const pagePermissions = usePagePermissions(currentPage);

  const teamUsers = useSelector(teamsModel.selectors.selectCurrentTeamMembers);
  const teamUsersInviteAccepted = useMemo(
    () => teamUsers.filter((user) => !user.isWaitingForInviteAcceptance),
    [teamUsers]
  );

  const { isGuest } = useTeamPermissions();

  const handleEditorPreview = () => {
    dispatch(pagesModel.actions.setIsEditorPreview(true));
    dispatch(narrationModel.actions.setPopupOpen(false));
    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
  };

  const handleNarrationToggle = () => {
    dispatch(subscribePopupModel.actions.setIsModalOpen(false));
    dispatch(gatedContentModel.actions.setIsModalOpen(false));
    dispatch(squeezePageModel.actions.setIsOpen(false));

    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
    dispatch(pagesModel.actions.setIsEditorPreview(false));

    dispatch(narrationModel.actions.setPopupOpen(!popupIsOpen));
  };

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const isHideSharingButton = useMemo(() => {
    const isInviteMembersTabAvailable =
      pagePermissions.isCanManageAccess && teamUsersInviteAccepted.length > 1;

    if (!isInviteMembersTabAvailable && !pagePermissions.isCanPublishPage) {
      return true;
    }

    return false;
  }, [
    pagePermissions.isCanManageAccess,
    pagePermissions.isCanPublishPage,
    teamUsersInviteAccepted.length,
  ]);

  const { published, isCollaborationEnabled } = useCollaboration();

  const isPublished = isCollaborationEnabled
    ? published
    : currentPage.published;

  return (
    <div className="flex items-center justify-between gap-2">
      <SharePageModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      />
      {!isGuest && <PageSettingsDropdown listStyles="mt-4" />}

      {pagePermissions.isCanEditVideoNarration && !isAnalyticsTab && (
        <FeatureFlag feature={FeatureFlags.NARRATION}>
          <Tooltip
            trigger={
              <Button
                variant="icon"
                color="secondary"
                title="Record narration"
                size="sm"
                onClick={handleNarrationToggle}
              >
                <Icon
                  glyph={IconMap.Recording}
                  width={20}
                  className="text-gray-700"
                />
              </Button>
            }
            sideOffset={4}
          >
            <p className="px-2 py-1 font-semibold text-xs">Record narration</p>
          </Tooltip>
        </FeatureFlag>
      )}

      <Button
        variant="icon-text"
        color="secondary"
        title="Preview"
        onClick={handleEditorPreview}
        size="sm"
      >
        <Icon glyph={IconMap.Eye} width={20} className="mr-2 text-gray-700" />
        <span className="text-gray-700 font-semibold">Preview</span>
      </Button>

      {!isGuest && (
        <Button
          variant="icon-text"
          color="primary-gradient"
          title="Share"
          onClick={() => {
            setIsShareModalOpen(true);
          }}
          size="sm"
          className={cn({
            hidden: isHideSharingButton,
          })}
        >
          <Icon glyph={IconMap.Send03} width={20} className="mr-2" />
          <span className="font-semibold">Share</span>
        </Button>
      )}
    </div>
  );
};
