import { ClearbitCompanyInfo } from '../api';
import { TEAM_COMPANY_PLACEHOLDER } from '../constants';

export function getBrandedPageName(
  teamCompanyName: string | null,
  brandCompany: ClearbitCompanyInfo | null
) {
  return `${teamCompanyName || TEAM_COMPANY_PLACEHOLDER} for ${
    brandCompany?.name
  }`;
}
