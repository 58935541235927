export enum FeatureFlags {
  ZAPIER = 'zapier',
  DISTRIBUTE_TAB = 'distributeTab',
  PERSONALIZED_LINKS = 'personalizedLinks',
  SNIPPETS = 'snippets',
  HTML_CONTENT = 'html-content',
  DOCUMENT_COLLABORATION = 'document-collaboration',
  NARRATION = 'narration',
  CONTACT_CARD = 'contactCard',
  CALL_RECORDING = 'callRecording',
  NEW_EDITOR = 'newEditor',
  CHAT_BOT = 'chatBot',
}
