import { PropsWithChildren } from 'react';

type Props<T> = {
  list: T[];
};

export const RenderList = <T,>({
  children,
  list,
}: PropsWithChildren<Props<T>>) => (list.length > 0 ? <>{children}</> : null);
