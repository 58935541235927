import { select, call, put } from 'redux-saga/effects';
import { callRecordingsApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { callRecordingSettingsModel } from '../index';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* connectToGoogleCalendar() {
  yield put(callRecordingSettingsModel.actions.setIsCalendarConnecting(true));

  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  try {
    const { url }: RT<typeof callRecordingsApi.connectToGoogleCalendar> =
      yield call(callRecordingsApi.connectToGoogleCalendar, {
        teamId: team.id,
        redirectPath: `team-settings/call-recordings`, // TODO: Discuss with Natalia
      });

    window.location.href = url;
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to connect Google Calendar`)
      )
    );
  } finally {
    yield put(
      callRecordingSettingsModel.actions.setIsCalendarConnecting(false)
    );
  }
}

export function* disconnectGoogleCalendar() {
  yield put(callRecordingSettingsModel.actions.setIsCalendarConnecting(true));

  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  try {
    yield call(callRecordingsApi.disconnectGoogleCalendar, { teamId: team.id });

    yield call(callRecordingSettingsModel.sagas.loadCallRecordingSettings);
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to disconnect Google Calendar`)
      )
    );
  } finally {
    yield put(
      callRecordingSettingsModel.actions.setIsCalendarConnecting(false)
    );
  }
}
