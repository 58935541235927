import { Emoji } from 'emoji-picker-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { cn } from '@distribute/frontend/utils';
import { pagesModel } from '../../../../../../src/features/pages';
import { Button, Icon } from '../../../../../../src/shared/ui';
import { IconMap } from '../../../../../../src/shared/sprite';
import { templateBgStyles } from '../../../../../../src/features/templates';
import {
  CreatedByRow,
  TemplatePreviewRaw,
} from '../../../../../../src/features/templates/ui/components';
import { useTemplatesTabContext } from './context';

type Props = {
  template: TemplateExtended;
};

export const TemplateItem: React.FC<Props> = ({ template }) => {
  const { handlePreviewTemplate, handleUseTemplateClick } =
    useTemplatesTabContext();

  const onPreviewClick = () => {
    handlePreviewTemplate(template);
  };

  const onSelectClick = () => {
    handleUseTemplateClick(template);
  };

  const isLoading = useSelector(pagesModel.selectors.selectCreatePageIsLoading);

  return (
    <div
      className={cn(
        'p-3 border border-gray-300 cursor-pointer rounded-xl h-fit group hover:shadow-md w-70'
      )}
    >
      <div className="h-[164px] border border-light-6 rounded-xl overflow-hidden relative">
        <div className="absolute z-10 flex flex-col items-center justify-center w-full h-full gap-2 transition-all duration-100 ease-linear opacity-0 group-hover:opacity-100 sm:hidden">
          <Button
            variant="text"
            color="primary"
            className="w-45 !h-11 text-md"
            onClick={onSelectClick}
            disabled={isLoading}
          >
            Use Template
          </Button>

          <Button
            variant="text"
            color="secondary"
            className="w-45 !h-9 text-sm"
            onClick={onPreviewClick}
          >
            <Icon glyph={IconMap.Eye} width={20} className="mr-1.5" />
            Preview
          </Button>
        </div>
        <div
          className={cn(
            'w-full h-full px-6 pt-5 group-hover:blur sm:group-hover:blur-none flex justify-center',
            `${templateBgStyles[template.bgColor].bgColor}`,
            {
              'justify-center': template.isSinglePage,
              'justify-between gap-3': !template.isSinglePage,
            }
          )}
        >
          <div
            className={cn(
              'w-8 h-[calc(100%-16px)] self-end grow -ml-6 shadow-md bg-base-white/60 rounded-tr-xl',
              { hidden: template.isSinglePage }
            )}
          ></div>
          <div className="relative w-full h-full shadow-md bg-base-white rounded-t-xl max-w-[216px] min-w-[216px]">
            <TemplatePreviewRaw
              style={{
                transform: `scale(0.22, 0.22)`,
                transformOrigin: '0 0',
              }}
              template={template}
              className="!pt-0 absolute w-212 left-4 md:left-5 top-4 md:top-5 h-full !overflow-visible"
            />
          </div>
          <div
            className={cn(
              'w-8 h-[calc(100%-16px)] self-end grow -mr-6 shadow-md bg-base-white/60 rounded-tl-xl',
              { hidden: template.isSinglePage }
            )}
          ></div>
        </div>
      </div>
      <div className="relative flex items-center gap-2 mt-3 mb-1">
        <Emoji unified={template.content.icon} size={20} />
        <p className="text-base font-semibold text-gray-900 truncate">
          {template.name || 'Untitled'}
        </p>
      </div>
      <div className="flex items-center justify-between mt-2">
        <CreatedByRow template={template} />
      </div>
    </div>
  );
};
