import { Plugin } from '@tiptap/pm/state';
import { Store } from 'redux';

import { AnyAction } from 'redux';
import { RootState } from '../../../../app';
import { DropCursorStorage } from '../DropCursor/types';
import { Editor } from '@tiptap/core';
import { CommandKey } from '../types';

import { getUploadFormRect, UploadFile } from '../../ui/upload-form';
import { focusTasklistTextarea } from '../../../../features/tasks';
import { tasksModel } from '../../../../features/tasks';
import { buildNColumns } from '../MultipleColumns';

const parseElementData = (data: string) => {
  try {
    const elementData = JSON.parse(data);
    return elementData;
  } catch (error) {
    // do nothing. Data is not valid JSON
  }
};

const CommandsDictionary: Record<
  CommandKey,
  (position: number, editor: Editor, store: Store<RootState, AnyAction>) => void
> = {
  ai: () => true,
  'heading-1': (position, editor) => {
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, {
        type: 'heading',
        attrs: {
          level: 1,
        },
      })
      .run();
  },
  'heading-2': (position, editor) => {
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, {
        type: 'heading',
        attrs: {
          level: 2,
        },
      })
      .run();
  },
  'heading-3': (position, editor) => {
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, {
        type: 'heading',
        attrs: {
          level: 3,
        },
      })
      .run();
  },
  table: (position, editor) => {
    editor.chain().focus(position).renderTableSizeSelector().run();
  },
  'bulleted-list': (position, editor) =>
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, {
        type: 'bulletList',
        content: [{ type: 'listItem', content: [{ type: 'paragraph' }] }],
      })
      .run(),
  'numbered-list': (position, editor) =>
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, {
        type: 'orderedList',
        content: [{ type: 'listItem', content: [{ type: 'paragraph' }] }],
      })
      .run(),
  toggleList: (position, editor) =>
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, {
        type: 'toggleList',
        content: [{ type: 'toggleListItem', content: [{ type: 'paragraph' }] }],
      })
      .run(),
  'todo-list': (position, editor) =>
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, {
        type: 'todoList',
        content: [{ type: 'todoListItem', content: [{ type: 'paragraph' }] }],
      })
      .run(),
  quote: (position, editor) =>
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, {
        type: 'blockquote',
        content: [{ type: 'paragraph' }],
      })
      .run(),
  callout: (position, editor) =>
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, {
        type: 'callout',
        content: [{ type: 'paragraph' }],
      })
      .run(),
  'code-block': (position, editor) =>
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, {
        type: 'codeBlock',
      })
      .run(),

  button: (position, editor) =>
    editor
      .chain()
      .focus(position)
      .insertContentAt(position, { type: 'button' })
      .run(),

  pdf: (position, editor) => {
    editor
      .chain()
      .focus(position)
      .command(() => {
        const { destroyComponent } =
          editor.extensionManager.commands.renderReactComponentWithTippy(
            UploadFile,
            {
              onUploaded: (data) => {
                destroyComponent();
                editor.commands.setFilePreview({
                  fileName: 'untitled',
                  ...data,
                });
              },
              onClose: () => {
                destroyComponent();
              },
            },
            { getReferenceClientRect: () => getUploadFormRect(editor) }
          );
        return true;
      })
      .run();
  },

  image: (position, editor) =>
    editor.chain().focus(position).renderImageUploadForm().run(),
  video: (position, editor) =>
    editor.chain().focus(position).renderVideoUploadForm().run(),

  'embed-content': (position, editor) =>
    editor.chain().focus(position).insertEmbedContent().run(),
  youtube: (position, editor) =>
    editor.chain().focus(position).insertEmbedContentYoutubePicker().run(),
  loom: (position, editor) =>
    editor.chain().focus(position).insertEmbedContentLoomPicker().run(),
  'google-drive': (position, editor) =>
    editor.chain().focus(position).insertEmbedContentGoogleDocsPicker().run(),
  calendly: (position, editor) =>
    editor.chain().focus(position).insertEmbedContentCalendlyPicker().run(),
  divider: (position, editor) =>
    editor.chain().focus().setTextSelection(position).setHorizontalRule().run(),
  timeline: (position, editor) =>
    editor.chain().focus().setTextSelection(position).insertTimeline().run(),
  tasklist: (position, editor, store) => {
    const currentPage = store.getState().pages.currentPage;
    const currentTemplate = store.getState().templates.currentTemplate;

    const documentContentId =
      currentPage?.content.id || currentTemplate?.content.id;

    if (!documentContentId) {
      return;
    }

    store.dispatch(
      tasksModel.actions.createTasklist({
        documentContentId,
        successCallback: (id: string) => {
          editor.chain().focus(position).insertCustomTaskList(id).run();

          setTimeout(() => {
            focusTasklistTextarea(id);
          }, 0);
        },
      })
    );
  },
  'contact-card': (position, editor, store) =>
    editor.chain().focus(position).insertContactCard(store).run(),
  '2columns': (position, editor) =>
    editor
      .chain()
      .focus(position)
      .unsetColumns(false)
      .setColumns(buildNColumns(2))
      .run(),
  '3columns': (position, editor) =>
    editor
      .chain()
      .focus(position)
      .unsetColumns(false)
      .setColumns(buildNColumns(3))
      .run(),
  '4columns': (position, editor) =>
    editor
      .chain()
      .focus(position)
      .unsetColumns(false)
      .setColumns(buildNColumns(4))
      .run(),
};

const getInsertPosition = (editor: Editor) => {
  const { dropBlockTarget, dropBlockDirection } = editor.storage
    .dropCursor as DropCursorStorage;

  if (!dropBlockTarget || !dropBlockDirection) return;

  const pos = editor.view.posAtDOM(dropBlockTarget, 0);
  let nodeStartPos = pos;

  // If the target node is list - posAtDOM returns the position of the list item
  // so we need to get the position of the list item
  if (dropBlockTarget.tagName === 'OL' || dropBlockTarget.tagName === 'UL') {
    nodeStartPos = pos - 1;
  }

  let adjustedPosition = nodeStartPos;
  if (dropBlockDirection === 'bottom') {
    // Set position to the end of the target node if direction is bottom
    editor.state.doc.descendants((node, pos) => {
      if (nodeStartPos === pos) {
        adjustedPosition = pos + node.nodeSize;
      }
    });
  }

  return adjustedPosition;
};

export const ElementDropHandlePlugin = (
  store: Store<RootState, AnyAction>,
  editor: Editor
) =>
  new Plugin({
    props: {
      handleDOMEvents: {
        drop: (view, event) => {
          const elementData = parseElementData(
            event.dataTransfer?.getData('application/x-element') ?? ''
          );

          if (!elementData || !elementData.isElementsSidebarItem) return;

          const elementKey: CommandKey = elementData.key;

          const insertPosition = getInsertPosition(editor);
          if (!insertPosition) return;
          CommandsDictionary[elementKey](insertPosition, editor, store);
        },
      },
    },
  });
