import React, { FC, useEffect, useMemo } from 'react';

import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { squeezePageModel } from '../../../../../../../src/features/squeeze-page';
import { conversionKitModel } from '../../../../../../../src/features/conversion-kit';

import { parseHtml } from '../../../../../../../src/shared/lib/parseHtml';
import { Button, Input } from '../../../../../../../src/shared/ui';

type SqueezePageForm = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

const HTML_TAGS_REGEXP = /(<([^>]+)>)/gi;

export const SqueezePage: FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(squeezePageModel.selectors.selectIsOpen);
  const squeezePageReal = useSelector(
    conversionKitModel.selectors.selectSqueezePageWithError
  );
  const squeezePageEdited = useSelector(
    conversionKitModel.selectors.selectSqueezePageEdited
  );
  const squeezePage = squeezePageEdited ?? squeezePageReal;
  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().when([], {
          is: () => squeezePage.isFormName,
          then: string(),
          otherwise: string().nullable(),
        }),
        email: string().email().required(),
        phone: string().when([], {
          is: () => squeezePage.isFormPhone,
          then: string(),
          otherwise: string().nullable(),
        }),
      }),
    [squeezePage]
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<SqueezePageForm>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen, reset]);

  useEffect(() => {
    return () => {
      dispatch(squeezePageModel.actions.setIsOpen(false));
    };
  }, [dispatch]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="flex absolute top-0 left-0 z-20 h-[calc(100vh-52px)] bg-base-white overflow-auto p-4 w-full rounded-2xl">
      <div className="flex justify-center items-center w-full max-w-212 m-auto gap-10">
        <div className="flex flex-col gap-6 basis-102 break-words">
          <div
            className={cn(
              'text-display-md font-bold text-gray-900 text-center',
              {
                '!text-left': squeezePage.imageUrl,
              }
            )}
          >
            {squeezePage.title || 'Title'}
          </div>
          {squeezePage.description !== null && (
            <div
              className={cn('text-lg text-gray-600 text-center', {
                '!text-left': squeezePage.imageUrl,
              })}
            >
              {squeezePage.description.replace(HTML_TAGS_REGEXP, '').length
                ? parseHtml(squeezePage.description)
                : 'Description'}
            </div>
          )}
          <form
            onSubmit={handleSubmit(() => null)}
            className="flex flex-col gap-3"
          >
            {squeezePage.isFormName && (
              <Input
                {...register('name')}
                placeholder="Name"
                type="text"
                isBrandStyles
              />
            )}
            <Input
              {...register('email')}
              placeholder="Email"
              type="text"
              isError={!!errors.email}
              isBrandStyles
            />
            {squeezePage.isFormPhone && (
              <Input
                {...register('phone')}
                placeholder="Phone"
                type="text"
                isError={!!errors.phone}
                isBrandStyles
              />
            )}
            <Button
              fullWidth
              type="submit"
              color="brand"
              variant="text"
              fontBold
            >
              {squeezePage.buttonLabel || 'Submit'}
            </Button>
          </form>
        </div>
        {squeezePage.imageUrl && (
          <div className="flex basis-100 max-h-100">
            <img
              src={squeezePage.imageUrl}
              className="object-cover object-center w-full"
              alt="Squeeze page"
            />
          </div>
        )}
      </div>
    </div>
  );
};
