import { NodeViewProps, ReactNodeViewRenderer } from '@tiptap/react';
import { CustomTaskListNodeView } from './CustomTaskList.renderer';
import { CustomTaskListExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customTaskList: {
      insertCustomTaskList: (id: string) => ReturnType;
    };
  }
}
export type IProps = NodeViewProps;

export const CustomTaskList = CustomTaskListExt.extend({
  addCommands() {
    return {
      insertCustomTaskList:
        (id) =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'customTaskList',
            attrs: {
              id,
            },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomTaskListNodeView);
  },
});
