import { all, call } from 'redux-saga/effects';
import { setFoldersData } from '../../../folders';
import { setPagesData } from '../../../pages/lib';
import { setRecordsData } from '../../../recordings/model/sagas';
import { loadTeamMembers } from './loadTeamMembers';
import {
  setRecentTemplatesData,
  setTemplatesData,
} from '../../../templates/lib';
import { setTopTemplatesData } from '../../../templates/lib/setTopTemplatesData';
import { setPersonalizedCoverData } from '../../../editor-sidebar';
import {
  fetchLinkedinProfileData,
  fetchTwitterProfileData,
} from '../../../../entities/social-auth/model/sagas';
import { setCustomDomainInfo } from '../../../custom-domains';
import { setSnippetsData } from '../../../snippets/lib';
import { loadCallRecordingSettings } from '../../../call-recording-settings/model/sagas';

export function* loadTeamRelatedData() {
  yield call(loadTeamMembers);

  yield all([
    call(loadCallRecordingSettings),
    call(setFoldersData),
    call(setPagesData),
    call(setRecordsData),
    call(setTemplatesData),
    call(setRecentTemplatesData),
    call(setTopTemplatesData),
    call(setPersonalizedCoverData),
    call(fetchLinkedinProfileData),
    call(fetchTwitterProfileData),
    call(setCustomDomainInfo),
    call(setSnippetsData),
  ]);
}

export function* refreshTeamRelatedData() {
  yield call(loadTeamMembers);

  yield all([
    call(setFoldersData, false),
    call(setPagesData, false),
    call(setRecordsData, false),
    call(setTemplatesData),
    call(setRecentTemplatesData),
    call(setTopTemplatesData),
    call(setSnippetsData),
  ]);
}
