import { CreateWithAIPageType } from '@distribute/shared/api-types/create-with-ai';
import { IconMap } from '../../../shared/sprite';
import {
  boolean,
  InferType,
  object,
  string,
  mixed,
  array,
  number,
  date,
} from 'yup';
import { LINK_VERIFICATION_REGEX } from '../../../shared/constants';

export enum PageCreationFlowStepsEnum {
  GET_STARTED = 'GET_STARTED',
  TEMPLATES = 'TEMPLATES',
  TEMPLATES_PREVIEW = 'TEMPLATES_PREVIEW',
  CREATE_PAGE = 'CREATE_PAGE',
}

export enum PageCreationTypesEnum {
  FROM_TEMPLATE = 'FROM_TEMPLATE',
  FROM_SCRATCH = 'FROM_SCRATCH',
  WITH_AI = 'WITH_AI',
  TAB = 'TAB',
}

export enum PageTypeEnum {
  BRANDED = 'BRANDED',
  GENERAL = 'GENERAL',
}

export enum PageCreationDataSourcesEnum {
  TEXT = 'text',
  FILE = 'file',
  LINK = 'link',
  GONG = 'gong',
  CALL = 'call',
}

export type FileTypeForAI = {
  name: string;
  size: number;
  type: string;
  data: string;
};

export enum TranscriptsFilterTypesEnum {
  LAST_CALL = 'lastCall',
  LAST_MONTH = 'lastMonth',
  LAST_3_MONTHS = 'last3Months',
  LAST_6_MONTHS = 'last6Months',
  LAST_YEAR = 'lastYear',
  ALL_AVAILABLE = 'allAvailable',
  SPECIFIC = 'specific',
}

export enum LinkTypeForAIStatusEnum {
  NOT_PARSED_YET = 'not-parsed-yet',
  PARSING = 'parsing',
  PARSED = 'parsed',
  ERROR = 'error',
}

export const createPageFormSchema = object({
  isBranded: boolean().required(),
  pageTitle: string().required(),
  brandCompany: object({
    domain: string().nullable().default(null),
    name: string().default(''),
    logo: string().nullable().default(null),
  }).nullable(),
  pageType: mixed<CreateWithAIPageType>().oneOf(
    Object.values(CreateWithAIPageType)
  ),
  pageTypeDescription: string().when(['pageType'], (pageType) => {
    if (pageType === CreateWithAIPageType.OTHER) {
      return string().required();
    }
    return string().optional();
  }),
  transcriptsFilter: mixed<TranscriptsFilterTypesEnum>().when(
    ['selectedDataSource', 'selectedCallerName'],
    (selectedDataSource, selectedCallerName) => {
      if (
        selectedDataSource === PageCreationDataSourcesEnum.GONG &&
        selectedCallerName
      ) {
        return mixed<TranscriptsFilterTypesEnum>()
          .oneOf(Object.values(TranscriptsFilterTypesEnum))
          .required('Please select call transcripts');
      }
      return mixed<TranscriptsFilterTypesEnum>().optional();
    }
  ),
  selectedCallerName: string().when(
    ['selectedDataSource'],
    (selectedDataSource) => {
      if (selectedDataSource === PageCreationDataSourcesEnum.GONG) {
        return string().required(
          'Please enter or choose the name of the person'
        );
      }
      return string().optional();
    }
  ),
  selectedTranscripts: array()
    .default([])
    .when(
      ['selectedDataSource', 'transcriptsFilter'],
      (selectedDataSource, transcriptsFilter) => {
        if (
          selectedDataSource === PageCreationDataSourcesEnum.GONG &&
          transcriptsFilter === TranscriptsFilterTypesEnum.SPECIFIC
        ) {
          return array()
            .required()
            .min(1, 'Please select at least one call transcript');
        }
        return array().optional();
      }
    ),
  selectedDataSource: mixed<PageCreationDataSourcesEnum>()
    .oneOf(Object.values(PageCreationDataSourcesEnum))
    .required('Data source is required'),
  text: string()
    .default('')
    .when(['selectedDataSource'], (selectedDataSource) => {
      if (selectedDataSource === PageCreationDataSourcesEnum.TEXT) {
        return string().required(
          'This information is required for AI assistant.'
        );
      }
      return string().nullable();
    }),
  links: array()
    .of(
      object({
        url: string(),
        data: string().nullable(),
        status: mixed<LinkTypeForAIStatusEnum>().oneOf(
          Object.values(LinkTypeForAIStatusEnum)
        ),
      })
    )
    .when(['selectedDataSource'], (selectedDataSource) => {
      if (selectedDataSource === PageCreationDataSourcesEnum.LINK) {
        return array()
          .of(
            object({
              url: string()
                .required('Link is required')
                .matches(
                  LINK_VERIFICATION_REGEX,
                  'Provide a valid link format (e.g. https://www.example.com/)'
                ),
              data: string(),
              status: string().notOneOf(['error'], 'Failed to parse link'),
            })
          )
          .required();
      }
      return array().optional();
    }),
  files: array().when(['selectedDataSource'], (selectedDataSource) => {
    if (selectedDataSource === PageCreationDataSourcesEnum.FILE) {
      return array()
        .of(
          object({
            name: string(),
            size: number(),
            type: string(),
            data: string(),
          })
        )
        .required('Please upload file(s) with information for AI assistant.')
        .min(1, 'Please upload file(s) with information for AI assistant.')
        .max(5, 'Only 5 files are allowed');
    }
    return array().optional();
  }),
  sections: array(
    object({ title: string().min(1, 'Section title is required').default('') })
  ),
  isSeparateTabs: boolean().default(false),
  isUsingTabsAsAContext: boolean().default(false),
  selectedCallRecordings: array()
    .of(
      object({
        id: string(),
        name: string(),
        createdAt: date(),
      })
    )
    .default([]),
});

export type CreatePageFormSchemaType = InferType<typeof createPageFormSchema>;

export type StartPageCreationFlowAction = {
  mode?: 'template' | 'recording' | 'tab';
  templateId?: number;
  videoRecordingId?: string;
  gongCallTranscriptId?: string;
  callId?: string;
};

export type CreatePageAction = {
  title: string;
  brandLogo: string | null;
  brandCompanyDomain: string | null;
  selectedDataSource: PageCreationDataSourcesEnum;
  cb?(): void;
};

export type GenerateOutlineAction = CreatePageFormSchemaType & {
  existingTabsContext?: string;
  cb?(): void;
};

export type InitializePageGenerationWithAIAction = CreatePageFormSchemaType & {
  cb?(): void;
};

export type GenerateTabWithAIAction = CreatePageFormSchemaType & {
  existingTabsContext?: string;
  pageId: string;
  slug: string;
  cb?(): void;
};

export type ChosenFolderData = {
  folderId: string;
  folderName?: string;
};

export const PAGE_TYPES: {
  name: CreateWithAIPageType;
  icon: keyof typeof IconMap;
}[] = [
  { name: CreateWithAIPageType.BUSINESS_CASE, icon: 'Briefcase01' },
  { name: CreateWithAIPageType.CALL_FOLLOW_UP, icon: 'PhoneOutgoing01' },
  { name: CreateWithAIPageType.BATTLE_CARD, icon: 'FileSearch02' },
  { name: CreateWithAIPageType.MUTUAL_ACTION_PLAN, icon: 'DataFlow03' },
  { name: CreateWithAIPageType.CUSTOMER_ONBOARDING, icon: 'UserCheck01' },
  { name: CreateWithAIPageType.QBR, icon: 'ClipboardCheck' },
  { name: CreateWithAIPageType.OTHER, icon: 'TextA' },
];

export const OTHER_PLACEHOLDER = `• Main topic
• Sections you want to have
• Page voice`;

export type ParseFileAction = {
  file: File;
  cb(data: string | null): void;
};

export type ParseLinkAction = {
  link: string;
  cb(data: string | null): void;
};

export enum CreatePageActiveScreenEnum {
  BrandedPage = 'branded-page',
  GeneralPage = 'general-page',
  SelectType = 'select-type',
  BusinessCaseType = 'business-case-type',
  CallFollowUpType = 'call-follow-up-type',
  BattleCardType = 'battle-card-type',
  MutualActionPlanType = 'mutual-action-plan-type',
  CustomerOnboardingType = 'customer-onboarding-type',
  QBRType = 'qbr-type',
  OtherType = 'other-type',
  TextSource = 'text-source',
  GongCallSource = 'gong-call-source',
  FileSource = 'file-source',
  LinkSource = 'link-source',
  CallRecordingSource = 'call-recording-source',
  CreateOutline = 'create-outline',
  IntermediateStep = 'intermediate-step',
}

export const PageTypeToSidebarScreenMap = {
  [CreateWithAIPageType.BUSINESS_CASE]:
    CreatePageActiveScreenEnum.BusinessCaseType,
  [CreateWithAIPageType.CALL_FOLLOW_UP]:
    CreatePageActiveScreenEnum.CallFollowUpType,
  [CreateWithAIPageType.BATTLE_CARD]: CreatePageActiveScreenEnum.BattleCardType,
  [CreateWithAIPageType.MUTUAL_ACTION_PLAN]:
    CreatePageActiveScreenEnum.MutualActionPlanType,
  [CreateWithAIPageType.CUSTOMER_ONBOARDING]:
    CreatePageActiveScreenEnum.CustomerOnboardingType,
  [CreateWithAIPageType.QBR]: CreatePageActiveScreenEnum.QBRType,
  [CreateWithAIPageType.OTHER]: CreatePageActiveScreenEnum.OtherType,
};

export const PageSourceToSidebarScreenMap = {
  [PageCreationDataSourcesEnum.TEXT]: CreatePageActiveScreenEnum.TextSource,
  [PageCreationDataSourcesEnum.LINK]: CreatePageActiveScreenEnum.LinkSource,
  [PageCreationDataSourcesEnum.FILE]: CreatePageActiveScreenEnum.FileSource,
  [PageCreationDataSourcesEnum.GONG]: CreatePageActiveScreenEnum.GongCallSource,
  [PageCreationDataSourcesEnum.CALL]:
    CreatePageActiveScreenEnum.CallRecordingSource,
};

export enum ActiveAIStepType {
  APPEARANCE = 'appearance',
  TYPE = 'type',
  SOURCE = 'source',
  OUTLINE = 'outline',
}

export type GetCreatePageWithAIPromptProps = {
  pageType: CreateWithAIPageType;
  userName: string;
  companyName?: string;
  aboutCompany?: string;
  pageTitle?: string;
  prospectName?: string;
  pageTypeDescription?: string;
  isTranscript: boolean;
  shouldEmbed: boolean;
  context: string[];
};
