import {
  ChosenFolderData,
  PageCreationFlowStepsEnum,
  PageCreationTypesEnum,
} from '../config/types';
import { GetStartedStep } from './GetStartedStep';
import { PageCreationFlowModal } from './PageCreationFlowModal';
import {
  TemplatePreviewFull,
  TemplatesModalInner,
} from '../../../features/templates/ui';
import {
  TemplatesConfigEnum,
  templatesModel,
} from '../../../features/templates';
import { useDispatch, useSelector } from 'react-redux';
import { CreatePageStep } from './CreatePageStep/CreatePageStep';
import { pageCreationFlowModel } from '../model';
import { ChooseFolderModal } from './ChooseFolderModal';

export const PageCreationFlow = () => {
  const dispatch = useDispatch();
  const previewMode = useSelector(templatesModel.selectors.selectPreviewMode);

  const step = useSelector(pageCreationFlowModel.selectors.selectCurrentStep);

  const isChooseFolderModalOpen = useSelector(
    pageCreationFlowModel.selectors.selectChooseFolderModalOpen
  );

  const currentStep = previewMode
    ? PageCreationFlowStepsEnum.TEMPLATES_PREVIEW
    : step;

  const handleChooseTemplate = () => {
    dispatch(
      pageCreationFlowModel.actions.setCurrentStep(
        PageCreationFlowStepsEnum.CREATE_PAGE
      )
    );
    dispatch(templatesModel.actions.setPreviewMode(false));
    dispatch(
      templatesModel.actions.setCurrentTemplatesFolder(TemplatesConfigEnum.ALL)
    );
  };

  const handleCreatePageWithAI = () => {
    dispatch(
      pageCreationFlowModel.actions.setPageCreationType(
        PageCreationTypesEnum.WITH_AI
      )
    );
    dispatch(
      pageCreationFlowModel.actions.setCurrentStep(
        PageCreationFlowStepsEnum.CREATE_PAGE
      )
    );
    dispatch(templatesModel.actions.setCurrentSelectedTemplate(undefined));
  };

  const handleCloseFolderModal = () => {
    dispatch(pageCreationFlowModel.actions.closeChooseFolderModal());
  };

  const handleChooseFolderData = (data: ChosenFolderData) => {
    dispatch(pageCreationFlowModel.actions.setChosenFolderData(data));
  };

  return isChooseFolderModalOpen ? (
    <ChooseFolderModal
      isOpen={isChooseFolderModalOpen}
      onClose={handleCloseFolderModal}
      onChoose={handleChooseFolderData}
    />
  ) : (
    <PageCreationFlowModal currentStep={currentStep}>
      {currentStep === PageCreationFlowStepsEnum.GET_STARTED && (
        <GetStartedStep />
      )}
      {currentStep === PageCreationFlowStepsEnum.TEMPLATES && (
        <TemplatesModalInner
          onChoose={handleChooseTemplate}
          handleCreatePageWithAI={handleCreatePageWithAI}
        />
      )}
      {currentStep === PageCreationFlowStepsEnum.TEMPLATES_PREVIEW && (
        <TemplatePreviewFull onChoose={handleChooseTemplate} />
      )}
      {currentStep === PageCreationFlowStepsEnum.CREATE_PAGE && (
        <CreatePageStep />
      )}
    </PageCreationFlowModal>
  );
};
