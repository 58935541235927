import { Extension } from '@tiptap/core';

export const DiffExtension = Extension.create({
  name: 'diffExtension',

  addGlobalAttributes() {
    return [
      {
        types: this.extensions
          .map((extension) => extension.name)
          .filter((name) => name !== 'text'),
        attributes: {
          op: {
            default: null,
            parseHTML: (element) => element.getAttribute('op'),
            renderHTML: (attributes) => {
              if (!attributes.op) {
                return {};
              }

              return {
                op: attributes.op,
                class: `diff diff-${attributes.op}`,
              };
            },
          },
        },
      },
    ];
  },
});
