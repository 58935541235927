import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import { HTMLAttributes, useMemo, useState, useRef, useCallback } from 'react';
import { IconMap } from '../../../../shared/sprite';
import { Icon, Item } from '../../../../shared/ui';
import { Languages } from './constants';
import { useOnClickOutside } from '../../../../shared/hooks/useClickOutside';

export type LanguageSelectItem = Item<string>;
export type CodeBlockLanguageSelectProps = {
  items: LanguageSelectItem[];
  value: LanguageSelectItem['id'];
  onChange: (item: LanguageSelectItem['id']) => void;
  className?: string;
  isOpen?: boolean;
  setOpenClose?: (isOpen: boolean) => void;
  isDisabled?: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'value'>;

export const CodeBlockLanguageSelect = ({
  className,
  items,
  onChange,
  value,
  isOpen,
  setOpenClose,
  isDisabled = false,
  ...props
}: CodeBlockLanguageSelectProps) => {
  const [inputValue, setInputValue] = useState('');
  const visibleItems = useMemo(
    () =>
      inputValue
        ? items.filter((item) => new RegExp(inputValue, 'i').test(item.title))
        : items,
    [inputValue, items]
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const handleChange = (id: LanguageSelectItem['id']) => {
    setInputValue('');
    onChange(id);
    setOpenClose?.(false);
  };

  const handleOutsideClick = useCallback(() => {
    setOpenClose?.(false);
  }, [setOpenClose]);

  useOnClickOutside(containerRef, handleOutsideClick);

  return (
    <div
      ref={containerRef}
      className={classNames('relative flex-col flex text-gray-600', className)}
      {...props}
    >
      <Listbox disabled={isDisabled} value={value} onChange={handleChange}>
        <span onClick={() => (isDisabled ? null : setOpenClose?.(true))}>
          <Listbox.Button className="text-gray-500 relative w-fit-content cursror-pointer flex flex-row items-center gap-1 hover:bg-[rgba(0,0,0,0.04)] rounded-md py-0.5 px-1 border-none">
            <span className="text-xs">
              {Languages[value] ?? Languages.html}
            </span>
            <Icon glyph={IconMap.ArrowDown} width={16} height={16} />
          </Listbox.Button>
        </span>
        {isOpen ? (
          <Listbox.Options
            static
            className="flex absolute z-100 !m-0 !p-0 !list-none !pb-2 top-full translate-y-1 flex-col gap-y-2 shadow-lg max-h-50 border rounded-lg overflow-x-hidden border-gray-200 w-43.5 bg-base-white"
          >
            <div className="border-b h-[49px] sticky z-10 bg-base-white top-0 border-b-gray-200 px-3 py-4 flex w-full max-w-full flex-row gap-2">
              <div className="flex flex-row items-center">
                <Icon glyph={IconMap.SearchMd} width={16} height={16} />
              </div>
              <input
                ref={(ref) => {
                  setTimeout(() => {
                    ref?.focus();
                  });
                }}
                value={inputValue}
                type="text"
                onChange={(e) => setInputValue(e.target.value)}
                className="w-[calc(100%-20px)] outline-none max-w-full focus:outline-none"
              />
            </div>
            {!visibleItems.length && (
              <div>
                <span className="px-4 py-2.25 text-md text-ellipsis font-normal text-gray-700 flex flex-row justify-between">
                  No results
                </span>
              </div>
            )}
            {visibleItems.map((item) => (
              <Listbox.Option
                className="cursor-pointer items-center before:hidden hover:bg-gray-50 mx-1.5 rounded-md px-2.5 py-2.25 text-sm text-ellipsis font-normal text-gray-700 flex flex-row justify-between"
                key={item.id}
                value={item.id}
              >
                <span>{item.title}</span>
                {item.id === value && (
                  <Icon
                    glyph={IconMap.Check}
                    width={16}
                    height={16}
                    className="text-primary-600"
                  />
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        ) : null}
      </Listbox>
    </div>
  );
};
