import { useDispatch, useSelector } from 'react-redux';
import { editorLeftSidebarModel, LeftSidebarOptions } from '../model';
import { TemplatesTab } from './components';
import { ConversionsTab } from './components/conversions';
import { ElementsTab } from './components/elements';
import { SettingsTab } from './components/settings';
import { useEffect } from 'react';
import { narrationModel } from '../../narration';

export const EditorLeftSidebarContent = () => {
  const dispatch = useDispatch();
  const selectedOption = useSelector(
    editorLeftSidebarModel.selectors.selectSidebarOption
  );

  const currentPageOrTemplate = useSelector(
    editorLeftSidebarModel.selectors.selectCurrentPageOrTemplateWithError
  );

  useEffect(() => {
    dispatch(narrationModel.actions.setPopupOpen(false));
  }, [dispatch]);

  return (
    <>
      {selectedOption === LeftSidebarOptions.TEMPLATES && <TemplatesTab />}
      {selectedOption === LeftSidebarOptions.CONVERSIONS && (
        <ConversionsTab currentPageOrTemplate={currentPageOrTemplate} />
      )}
      {selectedOption === LeftSidebarOptions.ELEMENTS && <ElementsTab />}
      {selectedOption === LeftSidebarOptions.SETTINGS && <SettingsTab />}
    </>
  );
};
