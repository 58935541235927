import { cn } from '@distribute/frontend/utils';
import { FC, ReactNode } from 'react';

type Props = {
  headerComponent?: ReactNode;
  children: ReactNode;
};

export const SidebarTabLayout: FC<Props> = ({ headerComponent, children }) => {
  return (
    <div
      className={cn('flex flex-col w-full h-full border-l border-gray-300', {
        'pt-5': !headerComponent,
      })}
    >
      {!!headerComponent && (
        <div className="px-5 pt-5 pb-4 bg-base-white rounded-tr-2xl">
          {headerComponent}
        </div>
      )}
      <div className="flex-grow px-5 pb-4 overflow-auto">{children}</div>
    </div>
  );
};
