import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';
import { ActiveConversionPanel, LeftSidebarOptions } from './types';
import { ContactCardAttrs } from '@distribute/shared/generate-html';
type State = {
  selectedOption: LeftSidebarOptions | null;
  activeConversionPanel: ActiveConversionPanel | null;
  contactCardEditorOpen: boolean;
  contactCardEditedData: ContactCardAttrs | null;
  contactCardEditedPos: number | null;
};

const initialState: State = {
  selectedOption: null,
  activeConversionPanel: null,
  contactCardEditorOpen: false,
  contactCardEditedData: null,
  contactCardEditedPos: null,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'editorLeftSidebar',
  initialState,
  reducers: {
    setSelectedOption: (
      state,
      { payload: selectedOption }: PayloadAction<LeftSidebarOptions | null>
    ) => ({
      ...state,
      selectedOption,
    }),
    setActiveConversionPanel: (
      state,
      {
        payload: activeConversionPanel,
      }: PayloadAction<ActiveConversionPanel | null>
    ) => ({ ...state, activeConversionPanel }),
    setContactCardEditorOpen: (
      state,
      { payload: contactCardEditorOpen }: PayloadAction<boolean>
    ) => ({
      ...state,
      contactCardEditorOpen,
      selectedOption: LeftSidebarOptions.ELEMENTS,
    }),
    setContactCardEditedData: (
      state,
      {
        payload,
      }: PayloadAction<{
        contactCardEditedData: ContactCardAttrs;
        pos: number;
      } | null>
    ) => ({
      ...state,
      contactCardEditedData: payload?.contactCardEditedData ?? null,
      contactCardEditedPos: payload?.pos ?? null,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
};
