import { User, UserToTeamFiltered } from '@distribute/shared/types';
import { getUserRoles } from './getUserRoles';

export type TeamPermissions = {
  currentUserToTeam: UserToTeamFiltered | undefined;
  isOwner: boolean;
  isGuest: boolean;
  isCanEdit: boolean;
  isCanDelete: boolean;
  isCanLeave: boolean;
  isCanTransferOwnership: boolean;
  isCanManageSubscription: boolean;
  isCanManageAccess: boolean;
  isCanCreateFolders: boolean;
  isCanCreatePages: boolean;
  isCanCreateTemplates: boolean;
  isCanConnectIntegrations: boolean;
  isCanEditTeamCallSettings: boolean;
};

export const getTeamPermissions = (
  currentUser: User,
  currentTeamMembers: UserToTeamFiltered[]
): TeamPermissions => {
  const currentUserToTeam = currentTeamMembers.find(
    (item) => item.user?.id === currentUser.id
  );

  const { isOwner, isAdmin, isGuest } = getUserRoles(currentUserToTeam?.role);
  const isOwnerOrAdmin = isOwner || isAdmin;

  return {
    currentUserToTeam,
    isOwner,
    isGuest,
    isCanEdit: isOwnerOrAdmin,
    isCanDelete: isOwner,
    isCanLeave: true,
    isCanTransferOwnership: isOwner,
    isCanManageSubscription: isOwnerOrAdmin,
    isCanManageAccess: isOwnerOrAdmin,
    isCanCreateFolders: !isGuest,
    isCanCreatePages: isOwnerOrAdmin,
    isCanCreateTemplates: !isGuest,
    isCanConnectIntegrations: isOwnerOrAdmin,
    isCanEditTeamCallSettings: isOwnerOrAdmin,
  };
};
