import { FC, useMemo } from 'react';
import cn from 'classnames';

import { editorRightSidebarModel, RightSidebarOption } from '../model';
import { useDispatch, useSelector } from 'react-redux';
import { EditorSidebarButton } from '../../../shared/ui';

import { ChatBot } from '../../chat';

import { FeatureFlag, FeatureFlags } from '../../../entities/feature-flag';
import { pagesModel, usePagePermissions } from '../../pages';
import { IconMap } from '../../../shared/sprite';

import { useTeamPermissions } from '../../teams';

export const EditorRightSidebar: FC = () => {
  const dispatch = useDispatch();

  const selectedOption = useSelector(
    editorRightSidebarModel.selectors.selectSelectedOption
  );
  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const { isCanEditDocumentContent } = usePagePermissions(currentPage);
  const { isGuest } = useTeamPermissions();

  const sidebarOptions = useMemo(
    () =>
      [
        {
          id: RightSidebarOption.AI_BOT,
          icon: IconMap.AiAskDante,
          name: 'Ask Dante',
          visible: isCanEditDocumentContent && !isGuest,
        },
      ].filter((o) => o.visible),
    [isCanEditDocumentContent, isGuest]
  );

  const handleClick = (id: RightSidebarOption) => {
    dispatch(
      editorRightSidebarModel.actions.setSelectedOption(
        selectedOption === id ? null : id
      )
    );
  };

  return (
    <div
      className={cn('overflow-hidden h-[calc(100vh-68px)] flex-shrink-0', {
        hidden: !FeatureFlags.CHAT_BOT || !sidebarOptions.length,
        'rounded-2xl bg-base-white flex': !!selectedOption,
      })}
    >
      <FeatureFlag feature={FeatureFlags.CHAT_BOT}>
        {selectedOption === RightSidebarOption.AI_BOT && <ChatBot />}
        <div className="flex flex-col justify-between h-full overflow-y-scroll min-w-20">
          <div className="flex flex-col gap-2 px-2 pt-2 pb-4">
            {sidebarOptions.map((option) => (
              <EditorSidebarButton
                key={option.id}
                title={option.name}
                isSelected={option.id === selectedOption}
                icon={option.icon}
                onClick={() => handleClick(option.id)}
              />
            ))}
          </div>
        </div>
      </FeatureFlag>
    </div>
  );
};
