import { call, select, put } from 'redux-saga/effects';
import { teamsModel } from '../../../teams';
import { callApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { callModel } from '../index';
import { createNotification, snackbarModel } from '../../../snackbar';
import { refreshCallDetailsData } from './refreshCallDetailsData';

export function* generateSummary({
  payload: { type },
}: ReturnType<typeof callModel.actions.generateSummary>) {
  try {
    const currentCall: RT<
      typeof callModel.selectors.selectCurrentCallWithError
    > = yield select(callModel.selectors.selectCurrentCallWithError);

    const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
      yield select(teamsModel.selectors.selectCurrentTeamWithError);

    yield put(callModel.actions.setIsSummaryLoading(true));

    yield call(
      callApi.generateSummary,
      currentCall.id,
      { type },
      { teamId: team.id }
    );
    yield call(refreshCallDetailsData);
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to generate summary')
      )
    );
  } finally {
    yield put(callModel.actions.setIsSummaryLoading(false));
  }
}
