import { Listbox } from '@headlessui/react';
import { IconMap } from '../../../../../../../shared/sprite';
import { Icon, LoaderDots } from '../../../../../../../shared/ui';
import classNames from 'classnames';
import { MouseEvent, ReactNode, forwardRef, useMemo } from 'react';
import { SpriteGlyph } from '../../../../../../../shared/ui/Icon';
import { PageCreationDataSourcesEnum } from '../../../../../../page-creation-flow/config/types';

export type DataSourceItem<T = ReactNode> = {
  id: PageCreationDataSourcesEnum;
  title: T;
  iconName?: SpriteGlyph;
  description?: string;
  isIntegration?: boolean;
  iconImageSrc?: string;
  isConnected?: boolean;
  handleClick?(e: MouseEvent): void;
  hasConnectBtn?: boolean;
};

type Props = {
  className?: string;
  labelClassName?: string;
  value?: string;
  currentValue?: DataSourceItem;
  onChange: (id: PageCreationDataSourcesEnum) => void;
  items: DataSourceItem[];
  buttonStyles?: string;
  listStyles?: string;
  truncate?: boolean;
  isError?: boolean;
  messageText?: string;
  isLoading: boolean;
};

export const DataSourceSelect = forwardRef<HTMLButtonElement, Props>(
  (
    {
      value,
      currentValue,
      labelClassName,
      className,
      onChange,
      items,
      isError,
      messageText,
      buttonStyles,
      listStyles,
      truncate = true,
      isLoading,
    },
    ref
  ) => {
    const regularItems = useMemo(
      () => items.filter((item) => !item.isIntegration),
      [items]
    );
    const integrationItems = useMemo(
      () => items.filter((item) => item.isIntegration),
      [items]
    );
    return (
      <div className={classNames('w-full mb-1', className)}>
        <div className={classNames('w-full relative')}>
          <div className="relative w-full rounded-lg">
            <Listbox value={value} onChange={onChange}>
              {!value && (
                <p className="absolute left-3.5 top-3 text-gray-500 pointer-events-none">
                  Select
                </p>
              )}
              <Listbox.Button
                className={classNames(
                  'mb-1 rounded-lg w-full border border-gray-300 bg-base-white flex items-center justify-between py-2.5 px-3.5 focus:border-primary-600 h-11',
                  {
                    '!border-error-600': !!isError,
                  },
                  buttonStyles
                )}
                ref={ref}
              >
                <p
                  className={classNames(
                    'font-normal text-md text-gray-900 min-w-0 truncate max-w-[calc(100%-40px)] flex items-center gap-2',
                    labelClassName
                  )}
                >
                  {currentValue?.iconName && (
                    <Icon glyph={currentValue.iconName} width={20} />
                  )}
                  {currentValue?.iconImageSrc && (
                    <img
                      src={currentValue.iconImageSrc}
                      alt="Integration icon"
                      width={24}
                      className="self-start"
                    />
                  )}
                  {currentValue?.title ?? value}
                </p>
                {isLoading ? (
                  <LoaderDots size="md" />
                ) : (
                  <Icon
                    glyph={IconMap.ArrowDown}
                    width={20}
                    className="text-gray-500 ui-not-open:rotate-0 ui-open:rotate-180"
                  />
                )}
              </Listbox.Button>
              <Listbox.Options
                className={classNames(
                  'absolute z-50 w-full shadow-lg max-h-70 overflow-auto border rounded-lg border-gray-200 bg-base-white',
                  listStyles
                )}
              >
                <div className="px-1.5 py-1 flex flex-col gap-y-px">
                  {regularItems.map((item) => (
                    <Listbox.Option
                      className="cursor-pointer hover:bg-gray-50 rounded-md px-2 h-9.5 text-sm font-normal text-gray-700 flex flex-row items-center justify-between"
                      key={item.id}
                      value={item.id}
                    >
                      <div
                        className={classNames('flex items-center gap-2', {
                          'max-w-[calc(100%-40px)] truncate': truncate,
                        })}
                      >
                        {item.iconName && (
                          <Icon glyph={item.iconName} width={20} />
                        )}
                        {item.title}
                        {item.description && (
                          <span className="text-gray-500 -ml-1.5">
                            {item.description}
                          </span>
                        )}
                      </div>
                      {item.title === currentValue?.title && (
                        <Icon glyph={IconMap.GreenCheckMark} width={20} />
                      )}
                    </Listbox.Option>
                  ))}
                </div>
                <hr />
                <div className="px-1.5 py-1">
                  <p className="text-xs h-4 font-bold text-gray-500 mt-1.5 ml-2.5 mb-px uppercase">
                    Integrations
                  </p>
                  {integrationItems.map((item) => (
                    <div
                      className="cursor-pointer hover:bg-gray-50 rounded-md h-10.5 px-2.5 text-sm font-normal text-gray-700 flex flex-row justify-between items-center"
                      key={item.id}
                      onClick={item.handleClick}
                    >
                      <div
                        className={classNames('flex items-center gap-2', {
                          'max-w-[calc(100%-40px)] truncate': truncate,
                        })}
                      >
                        {item.iconImageSrc && (
                          <img
                            src={item.iconImageSrc}
                            alt="Integration icon"
                            width={24}
                            className="self-start"
                          />
                        )}
                        {item.title}
                        {item.description && (
                          <span className="text-gray-500 -ml-1.5">
                            {item.description}
                          </span>
                        )}
                      </div>
                      {item.isConnected ? (
                        <p className="text-gray-700 text-xs">Connected</p>
                      ) : (
                        item.hasConnectBtn && (
                          <div className="border border-gray-200 rounded-lg h-8 flex items-center px-3 bg-base-white text-sm font-semibold text-gray-700  hover:bg-gray-100">
                            Connect
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </Listbox.Options>
            </Listbox>
          </div>
        </div>
        {messageText && (
          <div className="mt-1.5 text-sm text-error-500">{messageText}</div>
        )}
      </div>
    );
  }
);
