import React from 'react';
import { EditorRightSidebar } from '../../../../../features/editor-right-sidebar';
import {
  ActiveConversionPanel,
  EditorLeftSidebar,
  editorLeftSidebarModel,
} from '../../../../../../src/features/editor-left-sidebar';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
};

export const CreateTab: React.FC<Props> = ({ children }) => {
  const activeConversionPanel = useSelector(
    editorLeftSidebarModel.selectors.selectActiveConversionPanel
  );

  const isHideOverflow =
    activeConversionPanel === ActiveConversionPanel.POP_UP ||
    activeConversionPanel === ActiveConversionPanel.SQUEEZE_PAGE;

  return (
    <>
      <EditorLeftSidebar />
      <div className="relative flex-grow flex flex-col overflow-hidden h-[calc(100vh-68px)]">
        <div
          className={classNames(
            'flex-grow bg-base-white rounded-2xl h-[calc(100vh-68px)]',
            {
              'overflow-hidden': isHideOverflow,
              'overflow-y-auto': !isHideOverflow,
            }
          )}
        >
          {children}
        </div>
      </div>
      <EditorRightSidebar />
    </>
  );
};
