export type User = {
  id: string;
  firebaseUid: string;
  email: string;
  displayName: string;
  photoUrl?: string;
  isAdmin: boolean;
  onboardingStatus: UserOnboardingStatus;
  lastSelectedTeamId: number | null;
  isShowPageWalkthroughModal: boolean;
  isShowMobileWalkthroughModal: boolean;
  usagePlan: UsagePlan;
  typeOfWorkForUsagePlanWork: TypeOfWorkForUsagePlanWork;
  isOwnTeamWasCreated: boolean;
  isAppsumo: boolean;
  shouldCreateOwnTeam: boolean;
  zoomCredentials: UserZoomCredentials | null;
};

export type UserZoomCredentials = {
  id: string;
  status: 'healthy' | 'unhealthy';
  createdAt: Date;
};

export type UserFiltered = {
  id: string;
  email: string;
  displayName: string;
  photoUrl?: string;
};

export enum UserOnboardingStatus {
  SUCCESSFULLY_CREATED_ACCOUNT = 'successfullyCreatedAccount',
  VERIFY_EMAIL = 'verifyEmail',
  USAGE_PLAN = 'usagePlan',
  CREATE_TEAM = 'createTeam',
  INSTALL_EXTENSION = 'installExtension',
  FINISHED = 'finished',
}

export enum UsagePlan {
  WORK = 'work',
  EDUCATION = 'education',
  PERSONAL_PROJECTS = 'personalProjects',
}

export enum TypeOfWorkForUsagePlanWork {
  SALES = 'sales',
  MARKETING = 'marketing',
  OPERATIONS = 'operations',
  CUSTOMER_SUCCESS = 'customerSuccess',
  EXECUTIVE = 'executive',
  HUMAN_RESOURCES = 'humanResources',
  OTHER = 'other',
}

export enum GettingStartedStep {
  CREATE_ACCOUNT = 'createAccount',
  DOWNLOAD_CHROME_EXTENSION = 'downloadChromeExtension',
  RECORD_FIRST_VIDEO = 'recordFirstVideo',
  CREATE_PAGE = 'createPage',
  PUBLISH_PAGE = 'publishPage',
  DISTRIBUTE_PAGE = 'distributePage',
  INVITE_TEAM_MEMBER = 'inviteTeamMember',
}
