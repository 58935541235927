import { cn } from '@distribute/frontend/utils';

const SkeletonItem = ({ height }: { height: number }) => {
  return (
    <div
      className={cn('w-full h-full bg-base-white rounded-xl overflow-hidden')}
      style={{ height }}
    >
      <div className={cn('w-full h-full bg-gray-200 animate-pulse')}></div>
    </div>
  );
};

export const Skeleton = () => {
  return (
    <div className="flex flex-col">
      <SkeletonItem height={80} />
      <div className="flex flex-col gap-3 mt-6">
        <SkeletonItem height={56} />
        <SkeletonItem height={56} />
        <SkeletonItem height={56} />
        <SkeletonItem height={56} />
        <SkeletonItem height={56} />
      </div>
    </div>
  );
};
