export enum MeetingJoinPreference {
  JOIN_ALL = 'join-all',
  JOIN_ORGANIZED = 'join-organized',
  JOIN_EXTERNAL = 'join-external',
  NO_AUTO_JOIN = 'no-auto-join',
}

export enum MeetingEmailNotification {
  ALL_PARTICIPANTS = 'all-participants',
  INTERNAL_PARTICIPANTS = 'internal-participants',
  ONLY_ME = 'only-me',
  NO_EMAIL = 'no-email',
}

export type UserCallSettings = {
  id: string;
  userToTeamId: number;
  joinPreference: MeetingJoinPreference;
  emailNotification: MeetingEmailNotification;
  botName: string;
  showCopilotCover: boolean;
  createdAt: Date;
  updatedAt: Date;
};
