import { call, select, put } from 'redux-saga/effects';
import { teamsModel } from '../../../teams';
import { callApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { callModel } from '../index';
import { navigate } from '../../../../processes/navigation';
import { CALL_RECORDINGS_ROUTE } from '../../../../entities/history';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* loadCallDetails(id: string) {
  try {
    const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
      yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const callDetails: RT<typeof callApi.getCallDetails> = yield call(
      callApi.getCallDetails,
      id,
      { teamId: team.id }
    );
    yield put(callModel.actions.setCurrentCall(callDetails));
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to load call details')
      )
    );
    yield put(navigate({ to: CALL_RECORDINGS_ROUTE }));
  }
}
