import { RootState } from '../../../app';
import { createSelector } from '@reduxjs/toolkit';
import { selectCurrentPage } from '../../pages/model/selectors';
import { selectCurrentTemplate } from '../../templates/model/selectors';
import { selectWithError } from '../../../../src/shared/lib';

export const selectSidebarOption = ({ editorLeftSidebar }: RootState) =>
  editorLeftSidebar.selectedOption;

export const selectActiveConversionPanel = ({ editorLeftSidebar }: RootState) =>
  editorLeftSidebar.activeConversionPanel;
export const selectCurrentPageOrTemplate = createSelector(
  selectCurrentPage,
  selectCurrentTemplate,
  (page, template) => page || template
);

export const selectCurrentPageOrTemplateWithError = selectWithError(
  selectCurrentPageOrTemplate,
  'currentPageOrTemplate'
);

export const selectContactCardEditorOpen = ({ editorLeftSidebar }: RootState) =>
  editorLeftSidebar.contactCardEditorOpen;

export const selectContactCardEditedData = ({ editorLeftSidebar }: RootState) =>
  editorLeftSidebar.contactCardEditedData;

export const selectContactCardEditedPos = ({ editorLeftSidebar }: RootState) =>
  editorLeftSidebar.contactCardEditedPos;
