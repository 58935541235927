import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from '../../../shared/ui';

import { ChatBotHeader } from './ChatBotHeader';
import { ChatBotContent } from './ChatBotContent';
import { ChatBotLabel } from './ChatBotLabel';
import { ChatBotInput } from './ChatBotInput';
import { ChatBotCancelStreamBlock } from './ChatBotCancelStreamBlock';
import { ChatBotApplySuggestionsBlock } from './ChatBotApplySuggestionsBlock';

import { chatModel } from '../model';
import { ConversationState } from '../model/types';
import { ChatDeleteModal } from './ChatDeleteModal';

export const ChatBot: FC = () => {
  const isChatLoading = useSelector(chatModel.selectors.selectIsChatLoading);

  const conversationState = useSelector(
    chatModel.selectors.selectConversationState
  );

  if (isChatLoading) {
    return (
      <div className="grid place-items-center w-100 p-5 border-r border-r-gray-200">
        <Loader size="sm" />
      </div>
    );
  }

  return (
    <>
      <ChatDeleteModal />
      <div className="flex flex-col w-100 p-5 border-r border-r-gray-200">
        <ChatBotHeader />
        <ChatBotContent />
        <div className="mt-auto flex flex-col gap-y-3">
          {conversationState === ConversationState.Idle && <ChatBotInput />}
          {conversationState === ConversationState.Streaming && (
            <ChatBotCancelStreamBlock />
          )}
          {conversationState === ConversationState.Suggestion && (
            <ChatBotApplySuggestionsBlock />
          )}
          <ChatBotLabel />
        </div>
      </div>
    </>
  );
};
