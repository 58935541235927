import { SkeletonItem, Theme } from '../../../shared/ui';
import { FC } from 'react';

export const ChatBotMessageLoading: FC = () => {
  return (
    <div className="flex flex-col gap-y-2">
      <SkeletonItem
        className="rounded-lg"
        height="12px"
        width="90%"
        theme={Theme.CHAT}
      />
      <SkeletonItem
        className="rounded-lg"
        height="12px"
        width="100%"
        theme={Theme.CHAT}
      />
      <SkeletonItem
        className="rounded-lg"
        height="12px"
        width="80%"
        theme={Theme.CHAT}
      />
      <SkeletonItem
        className="rounded-lg"
        height="12px"
        width="100%"
        theme={Theme.CHAT}
      />
    </div>
  );
};
