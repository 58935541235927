import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callModel } from '../model';
import { Button, Modal } from '../../../shared/ui';

type TogglePrivacyCallItemModalProps = {
  isFromList?: boolean;
};

export const TogglePrivacyCallItemModal: React.FC<
  TogglePrivacyCallItemModalProps
> = ({ isFromList = false }) => {
  const dispatch = useDispatch();
  const item = useSelector(callModel.selectors.selectTogglingCallItemWithError);
  const isLoading = useSelector(
    callModel.selectors.selectIsCallEditingProgress
  );
  const handleClose = () => {
    dispatch(callModel.actions.setTogglingCallItem(undefined));
  };

  const handleApplyClick = () => {
    dispatch(
      callModel.actions.updateCallItem({
        id: item.id,
        data: { isPrivate: !item.isPrivate },
        isFromList,
      })
    );
  };

  return (
    <Modal
      isOpen
      onClose={handleClose}
      title="Make private"
      className="w-100"
      actionButton={
        <Button
          onClick={handleApplyClick}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          loading={isLoading}
        >
          Apply
        </Button>
      }
    >
      <div className="text-sm font-normal text-gray-600">
        Are you sure you want to make this recording private? Your team members
        will no longer have access to it.
      </div>
    </Modal>
  );
};
