import React from 'react';

export const Section: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => (
  <div className="flex flex-col gap-4">
    <h2 className="font-bold text-2xl">{title}</h2>
    {children}
  </div>
);

export const Subsection: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => (
  <div>
    <h3 className="font-bold text-xl mb-2">{title}</h3>
    {children}
  </div>
);

export const BulletList = <T,>({
  items,
  renderItem,
}: {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
}) => (
  <ol className="list-disc pl-6">
    {items.map((item, idx) => (
      <li key={idx}>{renderItem(item, idx)}</li>
    ))}
  </ol>
);

export const NumberedList = <T,>({
  items,
  renderItem,
}: {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
}) => (
  <ol className="list-decimal pl-6">
    {items.map((item, idx) => (
      <li key={idx}>{renderItem(item, idx)}</li>
    ))}
  </ol>
);

type ActionItem = {
  text: string;
  assignedTo?: string | null;
  timeline?: string;
};

export const ActionItem: React.FC<{ item: ActionItem }> = ({ item }) => (
  <>
    <span>{item.text}</span>
    {item.assignedTo && (
      <>
        <span>{` – Assigned to: `}</span>
        <span className="font-bold">{item.assignedTo}</span>
      </>
    )}
    {item.timeline && <span>{` (Due: ${item.timeline})`}</span>}
  </>
);
