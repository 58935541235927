import {
  TeamCallSettings as TeamCallSettingsEntity,
  UserCallSettings as UserCallSettingsEntity,
  MeetingCalendar as MeetingCalendarEntity,
  MeetingEventType,
  MeetingCalendarEvent as MeetingCalendarEventEntity,
  MeetingManualEvent as MeetingManualEventEntity,
  UserZoomCredentials,
  MeetingCalendarPlatform,
} from '@distribute/shared/types';

export type GetMeetingCalendarEventResponse = MeetingCalendarEventEntity;
export type GetMeetingCalendarEventDataResponse =
  MeetingCalendarEventEntity['eventData'];

export type GetMeetingManualEventResponse = MeetingManualEventEntity;

// This needed to fix only the union types for Swagger
export type _GetMeetingEventResponse = {
  id: string;
  userToTeamId?: number;
  recallEventId?: string;
  recallCalendarId?: string;
  meetingCalendarId?: string;
  shouldRecord: boolean | null;
  isDeleted?: boolean;
  isPrivate: boolean | null;
  isExternal?: boolean;
  isOrganizedByUser?: boolean;
  name: string;
  platform?: MeetingCalendarPlatform;
  meetingPlatform:
    | GetMeetingCalendarEventResponse['meetingPlatform']
    | GetMeetingManualEventResponse['meetingPlatform'];
  meetingUrl:
    | GetMeetingCalendarEventResponse['meetingUrl']
    | GetMeetingManualEventResponse['meetingUrl'];
  eventData?: GetMeetingCalendarEventResponse['eventData'];
  botId?: string | null;
  type: MeetingEventType;
  startTime: Date;
  endTime: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type GetMeetingEventResponse =
  | (GetMeetingCalendarEventResponse & { type: MeetingEventType.CALENDAR })
  | (GetMeetingManualEventResponse & { type: MeetingEventType.MANUAL });

export type GetMeetingCalendarResponse = MeetingCalendarEntity;
export type GetTeamCallSettingsResponse = TeamCallSettingsEntity;
export type GetUserCallSettingsResponse = UserCallSettingsEntity;
export type GetZoomCredentialsResponse = UserZoomCredentials;

export type GetCallRecorderSettingsResponse = {
  meetingCalendar: GetMeetingCalendarResponse | null;
  teamSettings: GetTeamCallSettingsResponse;
  userSettings: GetUserCallSettingsResponse;
  zoomCredentials: GetZoomCredentialsResponse | null;
};

export type ConnectOAuthGoogleCalendarResponse = {
  url: string;
};

export type ConnectOAuthGoogleCalendarQueryParams = {
  teamId: number;
  redirectPath: string;
};

export type ConnectOAuthZoomResponse = {
  url: string;
};

export type ConnectOAuthZoomQueryParams = {
  redirectPath: string;
};

export type UpdateTeamCallSettingsRequest = Partial<
  Omit<
    TeamCallSettingsEntity,
    'id' | 'createdAt' | 'updatedAt' | 'teamId' | 'team'
  >
>;
export type UpdateUserCallSettingsRequest = Partial<
  Omit<
    UserCallSettingsEntity,
    'id' | 'createdAt' | 'updatedAt' | 'userToTeamId' | 'userToTeam'
  >
>;

export type ToggleEventRequest = { type: MeetingEventType };

export type CreateManualEventRequest = Pick<
  MeetingManualEventEntity,
  'name' | 'meetingUrl'
> & { duration: number };

export const callbackOAuthStatusQueryKey = 'oauth-status';

export enum CallBackOAuthStatus {
  GOOGLE_SUCCESS = 'google-success',
  GOOGLE_FAILED = 'google-failed',
  GOOGLE_EMAIL_MISMATCH = 'google-email-mismatch',
  ZOOM_SUCCESS = 'zoom-success',
  ZOOM_FAILED = 'zoom-failed',
}

export type JoinManuallyCallRequest = {
  name: string;
  meetingUrl: string;
  duration: number;
};
