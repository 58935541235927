import { Badge } from '../../../../../../shared/ui';

type ProTipProps = {
  title: string;
  children: React.ReactNode;
};

export const ProTip: React.FC<ProTipProps> = ({ title, children }) => {
  return (
    <div className="p-4 border border-gray-300 rounded-xl shadow-lg w-90 text-left">
      <div className="flex items-center gap-2 mb-1">
        <Badge size="sm" theme="warning" className="border border-warning-200">
          <img
            src="../assets/images/lightbulb-02.svg"
            alt="Pro tip icon"
            width={12}
            height={12}
            className="mr-0.5"
          />{' '}
          Pro tip
        </Badge>
        <h3 className="text-base font-semibold text-gray-900">{title}</h3>
      </div>

      <p className="text-sm text-gray-700">{children}</p>
    </div>
  );
};
