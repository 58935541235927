import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { templatesModel } from '..';
import { call, select, put } from 'redux-saga/effects';
import {
  DocumentContentItem,
  TemplateCreationPhase,
} from '@distribute/shared/types';
import { updateTemplateData } from './updateTemplateData';

export function* updateTemplateFromEditor() {
  const template: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  const isShowSuccessNotification =
    template.creationPhase !== TemplateCreationPhase.DRAFT;
  const { content } = template;
  const { contentItems } = content;
  let shouldUpdateItemsId = false;
  const contentItemsToUpdate: DocumentContentItem[] = [];

  contentItems.forEach((contentItem) => {
    let id: number | undefined = contentItem.id;
    if (contentItem.id < 0) {
      shouldUpdateItemsId = true;
      id = undefined;
    }
    contentItemsToUpdate.push({ ...contentItem, id } as DocumentContentItem);
  });

  yield call(updateTemplateData, {
    template: {
      ...template,
      content: {
        ...content,
        contentItems: contentItemsToUpdate,
      },
    } as TemplateExtended,
    isShowSuccessNotification,
  });

  /*
   * When we create new tab, it creates locally.
   * When template updates from Editor it saves to the server, and we need to update currentTemplateContentId, because it can be changed.
   * */
  if (shouldUpdateItemsId) {
    const templates: TemplateExtended[] = yield select(
      templatesModel.selectors.selectTemplates
    );
    const updatedTemplate = templates.find((el) => el.id === template.id);
    const selectedTemplate: TemplateExtended = yield select(
      templatesModel.selectors.selectCurrentTemplate
    );
    const currentTemplateContentId: number = yield select(
      templatesModel.selectors.selectCurrentTemplateContentId
    );

    yield put(
      templatesModel.actions.setCurrentTemplate({
        ...selectedTemplate,
        content: {
          ...selectedTemplate.content,
          contentItems: selectedTemplate.content.contentItems.map(
            (contentItem) => {
              if (contentItem.id < 0) {
                return {
                  ...contentItem,
                  id:
                    updatedTemplate?.content.contentItems.find(
                      (item) => item.order === contentItem.order
                    )?.id ?? contentItem.id,
                };
              }

              return contentItem;
            }
          ),
        },
      })
    );

    if (currentTemplateContentId < 0) {
      const oldContentItem = contentItems.find(
        (item) => item.id === currentTemplateContentId
      );
      const newContentItem = updatedTemplate?.content.contentItems.find(
        (item) => item.order === oldContentItem?.order
      );

      if (newContentItem) {
        yield put(
          templatesModel.actions.setCurrentTemplateContentId(newContentItem.id)
        );
      }

      const openedContentItemId: number = yield select(
        templatesModel.selectors.selectOpenedContentId
      );
      if (openedContentItemId === currentTemplateContentId && newContentItem) {
        yield put(
          templatesModel.actions.setOpenedContentItemId(newContentItem.id)
        );
      }
    }
  }
}
