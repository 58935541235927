import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { call, put, select } from 'redux-saga/effects';
import { chatsApi } from '../../../../shared/api/axios/chat';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import { analytics } from '../../../../entities/analytics';
import { AnalyticsEvents } from '@distribute/shared/types';

export function* upvote({
  payload: { chatId, messageId, data },
}: ReturnType<typeof actions.upvote>) {
  try {
    yield put(actions.setMessageLoading({ id: messageId, loading: true }));

    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    yield call(chatsApi.updateChatMessage, {
      id: chatId,
      messageId,
      teamId: currentTeam.id,
      data,
    });

    if (data.upvote) {
      analytics.track(AnalyticsEvents.AGENT_RESPONSE_LIKED, {
        chatId,
        messageId,
      });
    } else {
      analytics.track(AnalyticsEvents.AGENT_RESPONSE_DISLIKED, {
        chatId,
        messageId,
      });
    }

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Thanks for your feedback!')
      )
    );
    yield put(actions.updateMessage({ id: messageId, upvote: data.upvote }));
  } catch (error) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to thumb')
      )
    );
  } finally {
    yield put(actions.setMessageLoading({ id: messageId, loading: false }));
  }
}
