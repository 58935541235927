import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { IconMap } from '../../../../../shared/sprite';
import {
  Button,
  Dropdown,
  Icon,
  SearchInput,
} from '../../../../../../src/shared/ui';
import { TabsSwitcher } from '../shared';
import { SortState, tabs } from './lib';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type Props = {
  isSearchInputOpen: boolean;
  searchQuery: string;
  setIsSearchInputOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  selectedTab: string;
  handleSearchQueryChange: (value: string) => void;
  sortMethod: SortState;
  setSortMethod: Dispatch<SetStateAction<SortState>>;
};

export const TemplatesLayoutHeader: FC<Props> = ({
  selectedTab,
  setSelectedTab,
  isSearchInputOpen,
  setIsSearchInputOpen,
  searchQuery,
  handleSearchQueryChange,
  sortMethod,
  setSortMethod,
}) => {
  const mockSortDropdownItems = useMemo(() => {
    return [
      {
        label: 'Last Created',
        onClick: () => {
          setSortMethod(SortState.LAST_CREATED);
        },
        id: SortState.LAST_CREATED,
      },
      {
        label: 'Most Popular',
        onClick: () => {
          setSortMethod(SortState.MOST_POPULAR);
        },
        id: SortState.MOST_POPULAR,
      },
      {
        label: 'Name',
        onClick: () => {
          setSortMethod(SortState.NAME);
        },
        id: SortState.NAME,
      },
    ];
  }, [setSortMethod]);

  return (
    <div className="flex items-center gap-2">
      {isSearchInputOpen ? (
        <div className="flex w-full gap-2">
          <div className="relative w-full">
            <SearchInput
              value={searchQuery}
              onChange={handleSearchQueryChange}
              placeholder="Search"
            />
          </div>
          <Button
            size="md"
            color="transparent"
            variant="icon"
            onClick={() => setIsSearchInputOpen(false)}
          >
            <Icon glyph={IconMap.XClose} width={20} />
          </Button>
        </div>
      ) : (
        <>
          <TabsSwitcher
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <Button
            color="secondary"
            variant="icon"
            size="md"
            onClick={() => setIsSearchInputOpen(true)}
          >
            <Icon glyph={IconMap.SearchLg} width={20} />
          </Button>
          <Dropdown
            listStyles="shadow-lg"
            items={mockSortDropdownItems}
            currentItemId={sortMethod}
            itemStyles="min-w-37.5 sm:min-w-full"
            arrowStyle="text-gray-800"
            triggerComponent={
              <DropdownMenu.Trigger className="flex items-center justify-center w-10 h-10 border border-gray-300 rounded-md shadow-xs min-w-10">
                <Icon
                  glyph={IconMap.SwitchVertical01}
                  width={20}
                  className="text-gray-800"
                />
              </DropdownMenu.Trigger>
            }
          />
        </>
      )}
    </div>
  );
};
