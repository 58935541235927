import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Y from 'yjs';
import { TiptapTransformer } from '@hocuspocus/transformer';
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { SuggestionsEditorContext } from './SuggestionsEditorContext';
import { useCollaboration } from '../collaboration';
import { chatModel } from '../../features/chat';

import { diffDocs, diffDocsOptions } from '@distribute/shared/tiptap/json-diff';
import { getSchema } from '../../pages/new-editor/lib/getEditorSchema';
import { getMainEditorExtensions } from '../tiptap-editor/mainEditorExtensions';
import { DiffMarkExt, DiffExtension } from '@distribute/shared/generate-html';
import { merge } from '@distribute/shared/yjs';
import YPartyKitProvider from 'y-partykit/provider';
import { JSONContent } from '@tiptap/react';

type SuggestionsEditorProviderProps = {
  children: React.ReactNode;
};

export const SuggestionsEditorProvider: React.FC<
  SuggestionsEditorProviderProps
> = ({ children }) => {
  const dispatch = useDispatch();
  const [diffDoc, setDiffDoc] = useState<Y.Doc | undefined>(undefined);
  const isSuggestionMode = useSelector(
    chatModel.selectors.selectIsSuggestionMode
  );
  const suggestions = useSelector(
    chatModel.selectors.selectConversationSuggestions
  );
  const {
    isCollaborationEnabled,
    provider,
    tabs: collaborativeTabs,
  } = useCollaboration();
  const isReadyForCollaboration =
    isCollaborationEnabled &&
    provider &&
    suggestions.length > 0 &&
    isSuggestionMode;

  const setContentToFragment = (
    id: string,
    content: JSONContent,
    doc: Y.Doc,
    diff = false
  ) => {
    const tab = TiptapTransformer.toYdoc(content, id, [
      ...getMainEditorExtensions(true),
      ...(diff ? [DiffMarkExt, DiffExtension] : []),
    ]);
    const xmlFragment = doc.getXmlFragment(id);
    xmlFragment.delete(0, xmlFragment.length);
    merge(doc, tab);
  };

  const generateDocFromSuggestion = (doc: Y.Doc): Y.Doc => {
    suggestions.forEach((s) => {
      const currentContent = collaborativeTabs.find(
        (tab) => tab.id === Number(s.id)
      );

      if (currentContent && currentContent.contentJson) {
        const diffJSON = diffDocs(
          currentContent.contentJson,
          s.content,
          diffDocsOptions
        ) as JSONContent;

        // Skip if there is no diff between docs
        if (!diffJSON) return;

        const contentId = currentContent.id?.toString() as string;
        try {
          const schema = getSchema();
          const contentDoc = ProseMirrorNode.fromJSON(schema, diffJSON);
          contentDoc.check();

          setContentToFragment(contentId, diffJSON, doc, true);
        } catch (err) {
          // TODO: handle error
          console.log(err);
        }
      }
    });

    return doc;
  };

  const createDiffDoc = (provider: YPartyKitProvider): Y.Doc => {
    const tempDoc = new Y.Doc();

    copyYDocUsingUpdates(provider.doc, tempDoc);

    return generateDocFromSuggestion(tempDoc);
  };

  const updateDiffDoc = () => {
    if (diffDoc && provider) {
      copyYDocUsingUpdates(provider.doc, diffDoc);
      generateDocFromSuggestion(diffDoc);
    }
  };

  const handleAccept = () => {
    if (!provider) return;

    suggestions.forEach((suggestion) => {
      const currentContent = collaborativeTabs.find(
        (tab) => tab.id === Number(suggestion.id)
      );

      if (currentContent) {
        try {
          const currentContentId = currentContent.id?.toString() as string;
          setContentToFragment(
            currentContentId,
            suggestion.content,
            provider.doc
          );
        } catch (err) {
          // TODO: handle error
          console.log(err);
        }
      }
    });
    setDiffDoc(undefined);
    dispatch(chatModel.actions.applySuggestions());
  };
  const handleReject = () => {
    setDiffDoc(undefined);
    dispatch(chatModel.actions.applySuggestions());
  };

  useEffect(() => {
    if (isReadyForCollaboration && !diffDoc) {
      const newDoc = createDiffDoc(provider);
      setDiffDoc(newDoc);
    }
  }, [isReadyForCollaboration, diffDoc, provider]);

  useEffect(() => {
    if (isReadyForCollaboration && diffDoc) {
      updateDiffDoc();
    }
  }, [isReadyForCollaboration, collaborativeTabs, provider]);

  return (
    <SuggestionsEditorContext.Provider
      value={{
        isSuggestionMode: isSuggestionMode && !!diffDoc,
        diffDoc,
        onAccept: handleAccept,
        onReject: handleReject,
      }}
    >
      {children}
    </SuggestionsEditorContext.Provider>
  );
};

function copyYDocUsingUpdates(sourceDoc: Y.Doc, targetDoc: Y.Doc): void {
  const targetSV = Y.encodeStateVector(targetDoc);
  const update = Y.encodeStateAsUpdate(sourceDoc, targetSV);
  Y.applyUpdate(targetDoc, update);
}
