import * as sagas from './sagas';
import { reducer, actions } from './reducer';
import * as selectors from './selectors';

export const callRecordingSettingsModel = {
  sagas,
  reducer,
  actions,
  selectors,
};
