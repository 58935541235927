import { Icon, SpriteGlyph } from '../../../../../../shared/ui/Icon';
import React from 'react';
import cn from 'classnames';

type Props = {
  icon: SpriteGlyph;
  text: string;
  isRequired?: boolean;
  className?: string;
};

export const Label: React.FC<Props> = ({
  icon,
  text,
  isRequired,
  className,
}) => {
  return (
    <p className={cn('flex items-center gap-3', className)}>
      <Icon glyph={icon} width={20} />
      <span className="text-sm text-gray-700 font-medium">
        {text}
        {isRequired && <span className="text-error-500 ml-0.5">*</span>}
      </span>
    </p>
  );
};
